import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from "rxjs/index";
import { BsModalRef } from 'ngx-bootstrap';
import { VehicleMakesService } from '@app/features/system-settings/fleet-management/vehicle-makes/vehicle-makes.service';
var VehicleMakeAddEditComponent = /** @class */ (function () {
    function VehicleMakeAddEditComponent(formBuilder, makeService, bsModalRef) {
        this.formBuilder = formBuilder;
        this.makeService = makeService;
        this.bsModalRef = bsModalRef;
        this.isEdit = false;
        this.action = 'save';
        this.vehicleMakesFormData = {
            id: '',
            name: '',
        };
        this.uniqueName = false;
        this.saveDisabled = false;
        this.loading = true;
    }
    VehicleMakeAddEditComponent.prototype.ngOnInit = function () {
        this.onClose = new Subject();
        if (this.id) {
            // Set values for edit screen
            this.isEdit = true;
            this.loading = false;
            this.vehicleMakesFormData.id = this.id ? this.id : '';
            this.vehicleMakesFormData.name = this.name ? this.name : '';
        }
        this.title = this.isEdit == true ? 'Fleet.edit.vehicle.make' : 'fleet.add.new.make';
        this.buildForm();
    };
    VehicleMakeAddEditComponent.prototype.buildForm = function () {
        this.vehicleMakesForm = this.formBuilder.group({
            'id': [this.vehicleMakesFormData.id],
            'name': [this.vehicleMakesFormData.name, [Validators.required, Validators.minLength(1)]],
        });
    };
    // Check status name already exists
    VehicleMakeAddEditComponent.prototype.checkUniqueMakesName = function (value) {
        var _this = this;
        var data = {
            name: value,
        };
        if (this.isEdit) {
            data['id'] = this.id;
        }
        var checkName = false;
        if (this.vehicleMakesForm.get('name').value === value) {
            checkName = true;
        }
        else {
            checkName = false;
        }
        if (checkName) {
            this.makeService.checkMakesName(data).subscribe(function (resp) {
                _this.uniqueName = resp;
            });
        }
    };
    // vehicle make create or update
    VehicleMakeAddEditComponent.prototype.submitVehicleMake = function () {
        var _this = this;
        this.saveDisabled = true;
        this.makeService.addOrEditVehicleMakes(this.vehicleMakesForm.value)
            .subscribe(function (resp) {
            if (resp.hasOwnProperty('id')) {
                _this.uniqueName = false;
                if (_this.screen == 'addVehicle') {
                    _this.onClose.next({ makeId: resp['id'] });
                }
                else {
                    _this.onClose.next(true);
                }
                _this.bsModalRef.hide();
            }
            else {
                _this.uniqueName = true;
            }
        }, function (error) {
            console.log('err', error);
        });
    };
    Object.defineProperty(VehicleMakeAddEditComponent.prototype, "f", {
        // Getting control values for validation check
        get: function () {
            return this.vehicleMakesForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    VehicleMakeAddEditComponent.prototype.closeVehicleMakePanel = function () {
        this.bsModalRef.hide();
        this.onClose.next('close');
    };
    return VehicleMakeAddEditComponent;
}());
export { VehicleMakeAddEditComponent };
