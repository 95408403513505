import {Injectable} from '@angular/core';
import {mxgraph, mxgraphFactory} from "mxgraph-factory";
import {AuthService} from "@app/core";

const {
    mxCell,
    mxUtils
} = mxgraphFactory({
    mxLoadResources: false,
    mxLoadStylesheets: true,
});

declare var _;
declare var $;

@Injectable({
    providedIn: 'root'
})
export class WorkflowCommonService {

    constructor(
        private authService: AuthService,
    ) {
    }

    getBlocksList(type:string = 'Custom') {
        const filterBySequence = [
            'delayBlock',
            'emailBlock',
            'smsBlock',
            'notificationBlock',
            'scheduledActivityBlock',
            // 'officeTaskBlock',
        ];
        let blocks: any[] = [{
            title: 'Delay blocks',
            id: 'cs_building_blocks',
            blocks: [{
                id: 'delayBlock',
                title: 'Delay',
                visible: true,
                iconClass: 'fal fa-clock',
            },
            {
                id: 'conditionalDelayBlock',
                title: 'Field based delays',
                visible: true,
                    iconClass: 'fa-light fa-chess-clock',
            }]
        },
            {
            title: 'Branching blocks',
            id: 'cs_building_blocks',
            blocks: [{
                id: 'conditionalBlock',
                title: 'If statement',
                visible: true,
                iconClass: 'fal fa-code-branch fa-rotate-180',
            }, {
                id: 'untilConditionBlock',
                title: 'If... until',
                visible: true,
                iconClass: 'fal fa-sharp fa-solid fa-repeat-1',
            }]
        }, {
            title: 'Communication blocks',
            id: 'cs_communication_blocks',
            blocks: [{
                id: 'emailBlock',
                title: 'Email',
                visible: true,
                iconClass: 'fal fa-envelope',
            }, {
                id: 'smsBlock',
                title: 'SMS',
                visible: this.authService.hasModuleAccess('Sms'),
                iconClass: 'fa-light fa-comment-sms',
            }]
        }, {
            title: 'Actions blocks',
            id: 'cs_actions_blocks',
            blocks: [{
                id: 'officeTaskBlock',
                title: 'Office task',
                visible: true,
                iconClass: 'fal fa-list',
            }, {
                id: 'addjobBlock',
                title: 'Add job',
                visible: true,
                iconClass: 'fal fa-truck',
            }, {
                id: 'setcustomfieldvalueBlock',
                title: 'Set custom fields',
                visible: this.authService.permitted(['SetupCustomFieldsToObjects.writeaccess']) === true,
                iconClass: 'fal fa-file-pen',
            }, {
                id: 'scheduledActivityBlock',
                title: 'Scheduled activity',
                visible: this.authService.permitted(['ScheduleActivity.writeaccess']) === true,
                iconClass: 'fal fa-clipboard-list-check',
            },{
                id: 'notificationBlock',
                title: 'Notification',
                visible: true,
                iconClass: 'fa-light fa-earth-americas',
            }]
        }];

        if(type == 'Custom' || type == 'Marketplace') {
            return blocks;
        }
        else if(type.toLowerCase() == 'sequence') {
            return blocks.filter((section) => {
                section.blocks = section.blocks.filter((item) => {
                    return filterBySequence.indexOf(item.id) > -1;
                });
                return section.blocks.length > 0 ;
            });
        }
    }

    getCsTaggingList(mainObjectName:string, taggingList:any, workflowType:string = 'Custom') {
        mainObjectName = mainObjectName.toLowerCase();
        let cstagKeys = [];
        if(mainObjectName === 'job') {
            cstagKeys = ['Customers', 'jobs', 'work_address'];
        }
        else if (mainObjectName === 'invoice') {
            cstagKeys = ['Customers', 'jobs', 'work_address', 'invoice'];
        }
        else if (mainObjectName === 'diaryEvents') {
            cstagKeys = ['Customers', 'jobs', 'work_address', 'diary_events'];
        }
        else if (mainObjectName === 'certificates') {
            cstagKeys = ['Customers', 'jobs', 'work_address'];
        }
        else if(mainObjectName === 'customer'){
            cstagKeys = ['Customers', 'work_address'];
        }
        else if(mainObjectName === 'payment'){
            cstagKeys = ['Customers', 'jobs', 'work_address', 'invoice','payment'];
        }
        else if(mainObjectName === 'opportunity' && workflowType === 'Sequence') {
            cstagKeys = ['Customers', 'opportunities'];
        }
        else if(mainObjectName === 'opportunity' && workflowType === 'PipelineAutomation') {
            cstagKeys = ['Customers', 'opportunities'];
        }
        let __csTaggingList=[];
        cstagKeys.forEach( tagName => {
            let tagNameList = Object.values(taggingList[tagName]);
            __csTaggingList = __csTaggingList.concat(tagNameList);
        });
        let tagList = this.removeItem('Payment_invoice_list',__csTaggingList);

        let csTaggingList = tagList.map( item => {
            return { id: item, value: item };
        });
        return csTaggingList;
    }

    getCustomFieldsGroup(name: string) {
        let groupLists = {
            'job': ['CustomerType','JobDescription', 'InvoicesCategory'],
            'invoice': ['Invoices'],
            'diaryEvents': [],
            'certificates': []
        };
        return name != '' ? groupLists[name] : groupLists;
    }
    removeItem(value,Array){
        const index: number = Array.indexOf(value);
         let removedTag =  Array.splice(index, 1);
         return Array;
    }

    getBlockMainContainer(name: any, options:any = {}, cell:mxgraph.mxCell = null) {
        let cellValue = {};
       /* if(cell) {
            cellValue = cell.getValue();
            options['id'] = cell.getId()
        }*/
        const {blockSize, title} = options;
        let headerText = 'What do you want to happen?';
        if(options['id'] == 'cs_initializer') {
            headerText = options.title;
        }
        let viewMode = options.mxGraphService ? options.mxGraphService.options.viewMode : false;
        let mainContainer = viewMode ? $('<div></div>')
            .addClass('view-task-block cs-'+name).css({width: blockSize[name]['width'], textAlign:'center', padding:'20px', fontSize:'14px', boxSizing:'border-box', whiteSpace: 'normal'}) :
            $('<div></div>').addClass('task-block cs-'+name).css({width: blockSize[name]['width'], textAlign:'center', padding:'20px', fontSize:'14px', boxSizing:'border-box', whiteSpace: 'normal'});
        mainContainer.attr('id', 'cs-block-'+name+'-'+options['id']);
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingBottom: options['id'] == 'cs_initializer' ? 0 : 10}).text(headerText);
        let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let taskDiv = $('<div></div>').addClass('task-title');
        let imgTag = '';
        if(name == 'scheduledActivityBlock')
            imgTag = '<i class="mr-10 far fa-clipboard-list-check fa-lg" aria-hidden="true"></i>';
        else if(name == 'emailBlock')
            imgTag = '<i class="mr-10 fal fa-envelope fa-lg" aria-hidden="true"></i>';
        else if(name == 'smsBlock')
            imgTag = '<i class="mr-10 fa-light fa-comment-sms fa-lg" aria-hidden="true"></i>';
        else if(name == 'notificationBlock')
            imgTag = '<i class="mr-10 fa-light fa-earth-americas fa-lg" aria-hidden="true"></i>';
        else if(name == 'officeTaskBlock')
            imgTag = '<i class="mr-10 fal fa-list fa-lg" aria-hidden="true"></i>';
        else if(name == 'conditionalDelayBlock')
            imgTag = '<i class="mr-10 fa-light fa-chess-clock fa-lg" aria-hidden="true"></i>';
        else if(name == 'delayBlock')
            if(cellValue.hasOwnProperty('model') && cellValue['model']['type'] === 'conditional')
                imgTag = '<i class="mr-10 fa-light fa-chess-clock fa-lg" aria-hidden="true"></i>';
            else
                imgTag = '<i class="mr-10 fal fa-clock fa-lg" aria-hidden="true"></i>';
        else
            imgTag = '<i class="mr-10 far fa-file-pen fa-lg" aria-hidden="true"></i>';

        // let img = options.title == "Schedule activity" ? '<i class="mr-10 far fa-clipboard-list-check fa-lg" aria-hidden="true"></i>' : '<i class="mr-10 far fa-file-pen fa-lg" aria-hidden="true"></i>';
        if(options['id'] != 'cs_initializer') {
            let span = $('<span></span>').css({fontWeight:'bold'}).text(title);
            taskDiv.append(imgTag);
            taskDiv.append(span);
        }
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        return mainContainer;
    }


    renderInitializer(cell:any, options:any = {}){
        const value = cell instanceof mxCell ? cell.getValue() : cell;
        if(!cell['block_name']) {
            cell['block_name'] = cell['name'];
        }
        const {rules, mainObject} = value;
        const selectedMainObjectFields = options.selectedMainObjectFields;
        const operatorsDisplayText = options.getOperatorsDisplayText;
        const maxWidth = 400;
        const fixedWidth = 270;
        let data:any = {}, titleText = '';
        const containerBottom = 5;
        let mainContainer: JQuery = this.getBlockMainContainer(cell['block_name'], options);

        if(options['isScheduler']) {
            let divHead  = document.createElement('div');
            divHead.style.textAlign = 'center';
            divHead.className = 'init-block';
            divHead.style.fontWeight = 'bold';
            divHead.style.padding = '0 4px';
            mxUtils.write(divHead, 'Scheduled workflow');
            mainContainer.append(divHead);
        }
        let cnt=0;
        let textCollection:any = [];
        _.forEach(rules, (rule:any) => {
            cnt++;
            let $fieldContainer = $('<div></div>').css('padding-bottom', cnt == rules.length ? 0 : containerBottom).css('font-weight', 'bold')
                .addClass('word-break');
            let $andDiv = $('<div></div>').css('padding-bottom', containerBottom).text('and');
            let $fieldDisplay = $('<span></span>').css('padding-right', '5px');
            let $operatorDisplay = $('<span></span>').css('padding-right', '5px').css('font-weight', 'normal');
            let $valueDisplay = $('<span></span>');
            let valueDisplayText = rule['value'];
            const fieldData = selectedMainObjectFields[rule['col_name']];
            if(_.has(fieldData, 'options')) {
                let tmp = [];
                if(_.isArray(rule['value'])) {
                    _.forEach(rule['value'], (id) => {
                        const activeOption = _.find(fieldData.options, (item) => {
                            return item.id == id;
                        });
                        if(activeOption) {
                            tmp.push(activeOption['text']);
                        }
                    });
                    valueDisplayText=tmp.join(', ');
                }
                else {
                    const activeOption = _.find(fieldData.options, (item) => {
                        return item.id == rule['value'];
                    });
                    if(activeOption) {
                        valueDisplayText = activeOption['text'];
                    }
                }
            }
            const tmpDisplayText = rule['text'] + ' ' + rule['operator'] + ' ' + valueDisplayText;
            textCollection.push(tmpDisplayText.length);
            $fieldDisplay.text(rule['text']);
            $operatorDisplay.text(operatorsDisplayText[rule['operator']]);
            $valueDisplay.text(valueDisplayText);
            $fieldContainer.append($fieldDisplay).append($operatorDisplay).append($valueDisplay);
            mainContainer.append($fieldContainer);
            if(cnt !== rules.length) {
                mainContainer.append($andDiv);
            }
        });
        const maxDisplayText = _.max(textCollection);
        textCollection=[];
        mainContainer.css('width', (maxDisplayText * 8) > maxWidth ? maxWidth : fixedWidth);
        return mainContainer;
    }

    renderSetcustomfieldvalueBlock(cell:mxgraph.mxCell, options:any = {}) {
        const value = cell instanceof mxCell ? cell.getValue() : cell;
        if(!cell['block_name']) {
            cell['block_name'] = cell['name'];
        }
        let {model} = value;
        let mainContainer: JQuery = this.getBlockMainContainer(cell['block_name'], options);
        let bodyLine = $('<div></div>').addClass('text-truncate d-block').css('padding-bottom','5px');
        let fieldName = $('<span></span>').css({fontWeight:'normal'}).text(model['textField']);
        bodyLine.append(fieldName);
        let bodyLine2 = $('<div></div>').addClass('text-truncate d-block');
        let fieldValue = $('<span></span>').css({fontWeight:'bold', paddingLeft:0}).html(model['displayColumnValue']);
        bodyLine2.append(fieldValue);
        mainContainer.append(bodyLine);
        mainContainer.append(bodyLine2);
        return mainContainer;
    }

    renderDelayBlock(cell:mxgraph.mxCell, options:any = {}) {
        const value = cell instanceof mxCell ? cell.getValue() : cell;
        if(!cell['block_name']) {
            cell['block_name'] = cell['name'];
        }
        let mainContainer: JQuery = this.getBlockMainContainer(cell['block_name'], options);
        let bodyLine = $('<div></div>').addClass('text-truncate d-block').css('padding-bottom','0');
        let content = $('<span></span>').css({fontWeight:'bold', paddingLeft:0});
        let intervalUnitFormat = value['model']['interval'] === 1 ? value['model']['unit'].slice(0, -1) : value['model']['unit'];
        content.text(value['model']['interval'] + ' ' +  intervalUnitFormat);
        if(value['model']['type'] === 'conditional') {
            let eventText = value['model']['event_method'].indexOf('after') > -1 ? 'after':'before';
            let span1 = $('<span/>').css({fontWeight:'bold'}).text(value['model']['interval'] + ' ' +  intervalUnitFormat);
            let span2 = $('<span/>').css({fontWeight:'bold', padding:'0 5px'}).text( eventText );
            let span3 = $('<span/>').css({fontWeight:'bold'}).text(value['model']['fieldLabel']);
            bodyLine.append(span1).append(span2).append(span3);
        }
        else {
            bodyLine.append(content);
        }
        mainContainer.append(bodyLine);
        return mainContainer;
    }

    renderConditionalDelayBlock(cell:mxgraph.mxCell, options:any = {}) {
        const value = cell instanceof mxCell ? cell.getValue() : cell;
        if(!cell['block_name']) {
            cell['block_name'] = cell['name'];
        }
        let mainContainer: JQuery = this.getBlockMainContainer(cell['block_name'], options);
        let bodyLine = $('<div></div>').addClass('d-block').css('padding-bottom','0');
        let intervalUnitFormat = value['model']['interval'] === 1 ? value['model']['unit'].slice(0, -1) : value['model']['unit'];
        let eventName = value['model']['event_method'].indexOf('after') > -1 ? 'after':'before';
        let span1 = $('<span/>').css({fontWeight:'bold'}).text(value['model']['interval'] + ' ' +  intervalUnitFormat);
        let span2 = $('<span/>').css({fontWeight:'bold', padding:'0 5px'}).text( eventName );
        let span3 = $('<span/>').css({fontWeight:'bold'}).text(value['model']['fieldLabel']);
        bodyLine.append(span1).append(span2).append(span3);
        mainContainer.append(bodyLine);
        return mainContainer;
    }

    renderElseBlockEdge(cell:mxgraph.mxCell, options:any = {}) {
        const value = cell.getValue();
        let mainContainer = $('<div></div>').addClass('task-block else-edge-task-block').css({
            textAlign:'center',
            padding:'3px 8px',
            backgroundColor: '#fff',
            border: '1px dashed #ccc',
            fontSize:'14px'});
        const displayText = options['service'].truncateMiddle(options.service.selectedMainObjectFields[cell.value.col_name].text,30) + ' is not matched by any conditions';
        mainContainer.text(displayText) ;
        return mainContainer;
    }

    renderDummyBlock(cell:mxgraph.mxCell, options:any = {}) {
        const value = cell instanceof mxCell ? cell.getValue() : cell;
        const {blockSize, title} = options;
        let mainContainer = $('<div></div>').addClass('task-block dummy-task-block cs-d-flex align-items-center').css({
            width:blockSize.width,
            height:blockSize.height,
            textAlign:'center',
            padding:'15px',
            fontSize:'14px',
            cursor:'default'
        }).attr('id', 'block-id-'+cell.getId());
        let bodyLine = $('<div></div>').addClass('text-truncate d-block cs-flex-1').css('padding-bottom','5px').text(title);
        mainContainer.append(bodyLine);
        return mainContainer;
    }

    renderStartBlock(cell:mxgraph.mxCell, options:any = {}) {
        const {blockSize, title} = options;
        let mainContainer: JQuery = this.getBlockMainContainer(cell['block_name'], options);
    }

    renderScheduledActivityBlock(cell:any, options:any = {}) {
        const iconClassNames: Object = {
            'Call': 'fa fa-phone',
            'Email': 'fa fa-envelope',
            'Meeting': 'fas fa-calendar-alt',
            'Task': 'fa fa-list-ul'
        };
        const value = cell instanceof mxCell ? cell.getValue() : cell;
        if(!cell['block_name']) {
            cell['block_name'] = cell['name'];
        }
        let {model} = value;
        options.id = cell instanceof mxCell ? cell.getId() : '99999';
        options.title = 'Schedule activity';
        let mainContainer: JQuery = this.getBlockMainContainer(cell['block_name'], options);

        if(options.mxGraphService.workflowType == 'Sequence' && options.mxGraphService.options['mode'] === 'sequence_config') {
            if(value['model'] && value['model']['current_task_status'] && value['model']['current_task_status'] == 'skip') {
                mainContainer.css('background', '#F2F2F2');
                mainContainer.css('border', '1px solid #DADADA');
            }
            mainContainer.append(options.mxGraphService.skipOrUndoButton(cell));
        }
        let bodyLine = $('<div></div>').addClass('d-block').css('padding-bottom','5px');
        let descTitle = $('<span></span>').text('Description: ');
        let descText = $('<span></span>').addClass('word-break').css({fontWeight:'bold'}).text(model['description']);
        bodyLine.append(descTitle)
        bodyLine.append(descText);
        mainContainer.append(bodyLine);
        let bodyLine2 = $('<div></div>').addClass('d-block').css('padding-bottom','5px');
        let typeTitle = $('<span></span>').text('Activity type: ');
        let typeIcon = $('<i></i>').addClass(iconClassNames[model['activityTypeDisplay']]).css('margin-right', '10px')
        let activityTypeDisplay = $('<span></span>').css({fontWeight:'bold', marginLeft:'5px'}).text(model['activityTypeDisplay']);
        activityTypeDisplay.prepend(typeIcon);
        bodyLine2.append(typeTitle)
        bodyLine2.append(activityTypeDisplay);
        mainContainer.append(bodyLine2);
        let bodyLine3 = $('<div></div>').addClass('d-block').css('padding-bottom','5px');
        let dueDateTitle = $('<span></span>').text('Due date: ');
        let unit = model['scheduleInterval'] == 1 ? model['unit'].slice(0, -1) : model['unit'];
        let dueDateText = 'Current date +'+model['scheduleInterval'] +' '+ unit +' at ' + model['displayTime'];
        let dueDateDisplay = $('<span></span>').css({fontWeight:'bold'}).text(dueDateText);
        bodyLine3.append(dueDateTitle)
        bodyLine3.append(dueDateDisplay);
        mainContainer.append(bodyLine3);
        if(model['assigneeIds'].length) {
            let bodyLine4 = $('<div></div>').addClass('d-block').css('padding-bottom','0px');
            let assigneeTitle = $('<span></span>').text('Assign to: ');
            let assigneeText = $('<span></span>').css({fontWeight:'bold'}).text( model['assigneeDisplay'].join(', '));
            bodyLine4.append(assigneeTitle)
            bodyLine4.append(assigneeText);
            mainContainer.append(bodyLine4);
        }
        return mainContainer;
    }

    renderOfficeTaskBlock(cell:any, options:any = {}) {
        const value = cell instanceof mxCell ? cell.getValue() : cell;
        if(!cell['block_name']) {
            cell['block_name'] = cell['name'];
        }
        const {model} = value;
        let assignedTo = model['assignToDisplay'];
        let mainContainer: JQuery = this.getBlockMainContainer(cell['block_name'], options);
        let bodyLine = $('<div></div>').addClass('d-block').css('padding-bottom','5px');
        let assignTitle = $('<span></span>').text('Assigned to: ');
        let assignContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(assignedTo);
        let bodyLine2 = $('<div></div>').addClass('d-block');
        let tagTitle = $('<span></span>').text('Message: ');
        let tagContent = $('<span></span>').addClass('word-break').css({fontWeight:'bold', paddingLeft:5}).html(model['textMessage']);
        bodyLine.append(assignTitle);
        bodyLine.append(assignContent);
        bodyLine2.append(tagTitle);
        bodyLine2.append(tagContent);
        if(assignedTo) {
            mainContainer.append(bodyLine);
        }
        mainContainer.append(bodyLine2);
        return mainContainer;
    }

    renderNotificationBlock(cell:any, options:any = {}) {
        const value = cell instanceof mxCell ? cell.getValue() : cell;
        if(!cell['block_name']) {
            cell['block_name'] = cell['name'];
        }
        const {model} = value;
        let displayRoles = model['rolesDisplay'].join(', ');
        let mainContainer: JQuery = this.getBlockMainContainer(cell['block_name'], options);
        if(model['current_task_status'] && model['current_task_status'] == 'skip') {
            mainContainer.css('background', '#F2F2F2');
            mainContainer.css('border', '1px solid #DADADA');
        }
        let bodyLine = $('<div></div>').addClass('d-block').css('padding-bottom','5px');
        let assignTitle = $('<span></span>').text('Roles: ');
        let assignContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(displayRoles);
        let bodyLine2 = $('<div></div>').addClass('d-block');
        let tagTitle = $('<span></span>').text('Message: ');
        let tagContent = $('<span></span>').addClass('word-break').css({fontWeight:'bold', paddingLeft:5}).html(model['textMessage']);
        if(options.mxGraphService.workflowType == 'Sequence' && options.mxGraphService.options['mode'] === 'sequence_config') {
            mainContainer.append(options.mxGraphService.skipOrUndoButton(cell));
        }
        bodyLine.append(assignTitle);
        bodyLine.append(assignContent);
        bodyLine2.append(tagTitle);
        bodyLine2.append(tagContent);
        mainContainer.append(bodyLine);
        mainContainer.append(bodyLine2);
        return mainContainer;
    }

    renderSmsBlock(cell:any, options:any = {}) {
        const value = cell instanceof mxCell ? cell.getValue() : cell;
        if(!cell['block_name']) {
            cell['block_name'] = cell['name'];
        }
        let tagName = [];
        const {model} = value;
        _.forEach(model['toSmsTag'], (item: any) => {
            tagName.push(item.displayName);
        });
        let tagAddress = tagName.join(', ');
        let mainContainer: JQuery = this.getBlockMainContainer(cell['block_name'], options);
        let bodyLine = $('<div></div>').addClass('d-block').css('padding-bottom','5px');
        let emailToTitle = $('<span></span>').text('To: ');
        let emailToContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(tagAddress);
        let bodyLine2 = $('<div></div>').addClass('d-block');
        let subTitle = $('<span></span>').text('Title: ');
        let subContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(model['title']);
        if(options.mxGraphService.workflowType == 'Sequence' && options.mxGraphService.options['mode'] === 'sequence_config') {
            const initValue = options.mxGraphService.getInitBlockValue();
            const enabled = initValue['model'] && initValue['model']['displayContactInfo']['mobile'];
            if(enabled && model['current_task_status'] && model['current_task_status'] == 'skip') {
                mainContainer.css('background', '#F2F2F2');
                mainContainer.css('border', '1px solid #DADADA');
            }
            if(enabled) {
                mainContainer.append(options.mxGraphService.skipOrUndoButton(cell));
                emailToContent.text(initValue['model']['displayContactInfo']['mobile']);
            }
            else {
                mainContainer.css('background', '#ECC7C7');
                emailToContent.text('--not set--');
            }
            if(model['customMessage'] && typeof model['customMessage'] == 'object') {
                subTitle.text('Message: ');
                subContent.text(model['customMessage']['displayContent']);
            }
        }
        bodyLine.append(emailToTitle);
        bodyLine.append(emailToContent);
        bodyLine2.append(subTitle);
        bodyLine2.append(subContent);
        mainContainer.append(bodyLine);
        mainContainer.append(bodyLine2);
        return mainContainer;
    }

    renderEmailBlock(cell:any, options:any = {}) {
        const value = cell instanceof mxCell ? cell.getValue() : cell;
        if(!cell['block_name']) {
            cell['block_name'] = cell['name'];
        }
        // const value = cell.getValue();
        const {model} = value;
        let toTagName = [];
        _.forEach(model.toEmailTag, (item: any) => {
            toTagName.push(item.displayName);
        });
        let ccTagName = [];
        _.forEach(model.ccEmailTag, (item: any) => {
            ccTagName.push(item.displayName);
        });
        let bccTagName = [];
        _.forEach(model.bccEmailTag, (item: any) => {
            bccTagName.push(item.displayName);
        });
        let toTagAddress = toTagName.join(', ');
        let ccTagAddress = ccTagName.join(', ');
        let bccTagAddress = bccTagName.join(', ');
        let mainContainer: JQuery = this.getBlockMainContainer(cell['block_name'], options);
        let bodyLine = $('<div></div>').addClass('d-block').css('padding-bottom','5px');
        let bodyLineBcc = $('<div></div>').addClass('d-block').css('padding-bottom','5px');
        let bodyLineCc = $('<div></div>').addClass('d-block').css('padding-bottom','5px');
        let emailToTitle = $('<span></span>').text('To: ');
        let emailToContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(toTagAddress);
        let emailCcTitle = $('<span></span>').text('CC: ');
        let emailCcContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(ccTagAddress);
        let emailBccTitle = $('<span></span>').css({paddingLeft:10}).text('BCC: ');
        let emailBccContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(bccTagAddress);
        let bodyLine2 = $('<div></div>').addClass('d-block');
        let subTitle = $('<span></span>').text('Subject: ');
        let subjectMessage = model['subject'];
        if(model['customMessage'] && typeof model['customMessage'] == 'object') {
            subjectMessage = model['customMessage']['displaySubjectMessage'];
        }
        let subContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(subjectMessage);
        if(options.mxGraphService.workflowType == 'Sequence' && options.mxGraphService.options['mode'] === 'sequence_config') {
            const initValue = options.mxGraphService.getInitBlockValue();
            const enabled = initValue['model'] && initValue['model']['displayContactInfo']['emailAddress'];
            if(enabled && model['current_task_status'] && model['current_task_status'] == 'skip') {
                mainContainer.css('background', '#F2F2F2');
                mainContainer.css('border', '1px solid #DADADA');
            }
            if(enabled) {
                mainContainer.append(options.mxGraphService.skipOrUndoButton(cell));
                emailToContent.text(initValue['model']['displayContactInfo']['emailAddress']);
            }
            else {
                mainContainer.css('background', '#ECC7C7');
                emailToContent.text('--not set--');
            }
        }
        bodyLine.append(emailToTitle);
        bodyLine.append(emailToContent);
        if(ccTagName.length>0) {
            bodyLineCc.append(emailCcTitle);
            bodyLineCc.append(emailCcContent);
        }
        if(bccTagName.length>0) {
            bodyLineBcc.append(emailBccTitle);
            bodyLineBcc.append(emailBccContent);
        }
        bodyLine2.append(subTitle);
        bodyLine2.append(subContent);
        mainContainer.append(bodyLine);
        if(ccTagName.length>0){
            mainContainer.append(bodyLineCc);
        }
        if(bccTagName.length>0){
            mainContainer.append(bodyLineBcc);
        }
        mainContainer.append(bodyLine2);
        return mainContainer;
    }

    blockHeightCalculation(cell:any, options:any = {}) {
        let mainContainer, name = !cell['block_name'] ? cell['name'] : cell['block_name'];
        const callBack = "render" + this.capitalizeFirstLetter(name);
        if(this[callBack] && typeof this[callBack] == 'function') {
            mainContainer = this[callBack](cell, options);
        }
        /*if(name== 'emailBlock') {
            mainContainer = this.renderEmailBlock(cell, options);
        }
        else if(name == 'scheduledActivityBlock') {
            mainContainer = this.renderScheduledActivityBlock(cell, options);
        }
        else if(name == 'smsBlock') {
            mainContainer = this.renderSmsBlock(cell, options);
        }
        else if(name == 'notificationBlock') {
            mainContainer = this.renderNotificationBlock(cell, options);
        }
        else if(name == 'officeTaskBlock') {
            mainContainer = this.renderOfficeTaskBlock(cell, options);
        }*/
        if(!mainContainer) {
            return false;
        }
        mainContainer.appendTo('body');
        let data = {
            width: mainContainer.outerWidth(),
            height: mainContainer.outerHeight(),
            content: mainContainer
        };
        mainContainer.remove();
        return data;

    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}
