import { AfterViewInit, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Subject } from "rxjs/internal/Subject";
import { InterfaceService } from "@app/interfaces";

@Injectable({
    providedIn: 'root'
})
export class PropertyViewService extends InterfaceService {

    private CustomerDetails = new BehaviorSubject('');
    CustomerDetails$ = this.CustomerDetails.asObservable();

    private CustomerNav = new BehaviorSubject('');
    CustomerNav$ = this.CustomerNav.asObservable();

    private refreshSliderScheduleActivity = new Subject();
    refreshSliderScheduleActivity$ = this.refreshSliderScheduleActivity.asObservable();

    private refreshSliderNotes = new Subject();
    refreshSliderNotes$ = this.refreshSliderNotes.asObservable();

    private customerAppliance = new BehaviorSubject('');
    customerAppliance$ = this.customerAppliance.asObservable();

    private timelineRefresh = new BehaviorSubject('');
    timelineRefresh$ = this.timelineRefresh.asObservable();

    private timelineDownload = new BehaviorSubject('');
    timelineDownload$ = this.timelineDownload.asObservable();

    private whoIsViewing = new BehaviorSubject('');
    $whoIsViewing = this.whoIsViewing.asObservable();

    private sliderNoteDetails = new BehaviorSubject('');
    $sliderNoteDetails = this.sliderNoteDetails.asObservable();

    private canWorkWithCustomer = new BehaviorSubject(true);
    canWorkWithCustomer$ = this.canWorkWithCustomer.asObservable();

    private canNotWorkWithCustomer = new BehaviorSubject('');
    canNotWorkWithCustomer$ = this.canNotWorkWithCustomer.asObservable();

    private timelineDetails = new BehaviorSubject('');
    timelineDetails$ = this.timelineDetails.asObservable();

    private modalCloseEvent = new BehaviorSubject('');
    modalCloseEvent$ = this.modalCloseEvent.asObservable();

    private jobDetailUpdate = new BehaviorSubject('');
    jobDetailUpdate$ = this.jobDetailUpdate.asObservable();

    constructor(private http: HttpClient) {
        super();
    }

    getServiceReminder(id: number, type: string, limit: number): Observable<any> {
        let url = this.getApiUrl(`customers/${type}/${id}/reminders/service_reminder/views?limit=${limit}`);
        return this.http.get(url);
    }

    getCustomFieldQuestionAnswers(objects:string,objectTypes:string,cusTypeId:number,tablePKID?:number,propertyID?:number): Observable<any>{
        return this.http.get(this.getApiUrl(`listCustomFieldQuestionAnswers?objects=${objects}&objectTypes=${objectTypes}&objectTypeID=${cusTypeId}&propertyID=${propertyID}&tablePKID=${tablePKID}`));
    }

    setCutomerdetails(data){
        this.CustomerDetails.next(data);
        let customerNavBarDetails:any={};
        customerNavBarDetails['customerNav']=data;
        this.CustomerNav.next(customerNavBarDetails);
        this.getCanWorkWithProperty(data['canWork']);
    }

    setRefreshSliderScheduleActivity(data) {
        this.refreshSliderScheduleActivity.next(data);
    }

    setRefreshSliderNotes(data) {
        this.refreshSliderNotes.next(data);
    }

    setCustomerNav(data){
        this.CustomerNav.next(data);
    }
    beforeDelete(id?: any): Observable<any>  {
        return this.http
            .get(this.getApiUrl(`is_associated_to_job?reminderId=${id}`));
    }
    deleteServiceReminderRequest(params: any): Observable<any> {
        return this.http.post(this.getApiUrl(`delete_customer_service_reminder`),params,{responseType:'text'});
    }
    deleteCustomerContact(params: any) {
        return this.http.post(this.getApiUrl(`customer_delete_contact`), params, {
            responseType: 'text'
        });
    }

    deleteCustomerBranch(params: any) {
        return this.http.post(this.getApiUrl(`delete_customerbranch`), params, { responseType: 'text' });
    }

    deleteCustomer(params: any) {
        return this.http.post(this.getApiUrl(`delete_customer`), params, {
            responseType: 'text'
        });
    }

    checkCustomerDeleteStatus(customerId) {
        return this.http.get(this.getApiUrl(`is_customer_delete?id=${customerId}`));
    }

    deleteCustomerFile(params: any) {
        return this.http.post(this.getApiUrl(`remove_customer_file`), params, {
            responseType: 'text'
        });
    }

    getTimelineDetails(screen, tablePKId, page:any = 1, limit:any = 1,search,userId,object,fromDate,toDate,activityId,skipIds= [],scheduleList= [],filterActivityList= [],dateType='',appliedOperator = []) {
        let data = {
            page: page,
            limit: limit,
            search: search,
            userId: userId,
            object: object,
            fromDate: fromDate,
            toDate: toDate,
            activityId: activityId,
            skipIds: skipIds,
            scheduleList: scheduleList,
            filterActivityList: filterActivityList,
            dateType:dateType,
            appliedOperator: appliedOperator,
        }
        let url = this.getApiUrl(`activities/${screen}/${tablePKId}/view`);
        return this.http.post(url,data);
    }

    exportCustomerCsv(customerId: number){
        let url = this.getApiUrl(`customers/${customerId}/exportCsv`);
        return this.http.get(url);
    }

    saveNote(id: number,type: string,tablePKId: number,screen: string, data: FormData) {
        let url;
        if(screen=='jobs'){
            url = this.getApiUrl(`customers/${type}/${id}/${screen}/${tablePKId}/notes/new/timeline?csrf=false`);
        }else if(screen=='customer'){
            url = this.getApiUrl(`${type}/new_notes/${id}/timeline?csrf=false`);
        } else if (screen === 'contract') {
            url = this.getApiUrl(`${type}/new_notes/${id}/timeline?csrf=false`);
        }
        return this.http.post(url, data);
    }

    saveEditNote(data: FormData) {
        let url = this.getApiUrl(`edit_note`);
        return this.http.post(url, data, {
            responseType: 'text'
        });
    }

    deleteNote(data: FormData) {
        let url = this.getApiUrl(`customer_delete_notes`);
        return this.http.post(url, data, {
            responseType: 'text'
        });
    }

    saveCallLog(id: number,type: string,tablePKId: number,screen: string, data: FormData) {
        let url;
        if(screen=='jobs' || screen =='job'){
            let screen = 'jobs';
            url = this.getApiUrl(`customers/${type}/${id}/notes/${screen}/${tablePKId}/new_phone_call/timeline?csrf=false`);
        }else if(screen=='customer'){
            url = this.getApiUrl(`${type}/new_phone_call/${id}/timeline?csrf=false`);
        } else if(screen === 'contract') {
            url = this.getApiUrl(`${type}/new_phone_call/${id}/timeline?csrf=false`);
        }
        return this.http.post(url, data);
    }

    saveEditCallLog(data: object) {
        let url = this.getApiUrl(`edit_calllog`);
        return this.http.post(url, data, {
            responseType: 'text'
        });
    }

    saveSms(id: number,type: string,tablePKId: number,screen: string, data: FormData) {
        let url;
        if(screen=='customer'){
            url = this.getApiUrl(`${type}/new_sms_message/${id}/timeline?csrf=false`);
        }else if(screen=='jobs'){
            url = this.getApiUrl(`customers/${type}/${id}/jobs/${tablePKId}/new_sms/timeline?csrf=false`);
        } else if (screen === 'contract') {
            url = this.getApiUrl(`${type}/new_sms_message/${id}/timeline?csrf=false`);
        }
        return this.http.post(url, data);
    }

    getTimelineViewDetails(customerId) {
        let url = this.getApiUrl(`timelineViewDetails/${customerId}?tableType=2&id=${customerId}`);
        return this.http.get(url);
    }

    saveScheduledActivity(data: object) {
        let url = this.getApiUrl(`saveScheduledActivity`);
        return this.http.post(url, data);
    }

    saveEditScheduledActivity(data: object, activityId: number) {
        let url = this.getApiUrl(`rescheduleActivity/${activityId}`);
        return this.http.post(url, data);
    }

    completeOpportunityActivity(data: object) {
        let url = this.getApiUrl(`completeOpportunityActivity`);
        return this.http.post(url, data);
    }

    deleteCall(communicationId) {
        let url = this.getApiUrl(`deleteCallLog/${communicationId}`);
        return this.http.get(url, {
            responseType: 'text'
        });
    }

    deleteScheduleActivity(activityId) {
        let url = this.getApiUrl(`deleteScheduleActivity/${activityId}`);
        return this.http.get(url);
    }

    getTechnicalReferences(id: number): Observable<any> {
        let url = this.getApiUrl(`customers/${id}/customertechnicalreferences`);
        return this.http.get(url);
    }

    updateCustomerTechnicalReference(id: number,data?: object): Observable<any> {
        return this.http.post(this.getApiUrl(`customers/${id}/update_customer_technical_reference`), data);
    }

    setPrimaryContact(params: any) {
        return this.http.post(this.getApiUrl(`primary_contact`), params, {
            responseType: 'text'
        });
    }

    getUpcomingScheduledActivities(screen, tablePKId) {
        let url = this.getApiUrl(`upcoming_activities/${screen}/${tablePKId}/view`);
        return this.http.post(url,[]);
    }

    getPinnedNotes(screen, tablePKId) {
        let url = this.getApiUrl(`pinned_notes/${screen}/${tablePKId}/view`);
        return this.http.get(url);
    }
    portalShared(Id:number){
        let url = this.getApiUrl(`isPortalShared/${Id}`);
        return this.http.get(url);
    }
    customerPortalLink(id: number): Observable<any> {
        let url = this.getApiUrl(`customers/${id}/getCustomerPortalLink?checkValid=1`);
        return this.http.get(url);
    }
    ShareCustomerPortalAccess(id: number): Observable<any> {
        let url = this.getApiUrl(`customers/${id}/getCustomerPortalLink`);
        return this.http.get(url);
    }
    managePortalValue(id: number,data?: object): Observable<any>  {
        return this.http.post(this.getApiUrl(`managePortalAccess/${id}`),data);
    }
    removeCustomerPortalAccess(id: number): Observable<any> {
        let url = this.getApiUrl(`removePortalAccess/${id}`);
        return this.http.get(url);
    }
    checkStatementStatus(id:number,type:string): Observable<any>{
        let url = this.getApiUrl(`customers/${type}/${id}/check_send_statement`);
        return this.http.get(url);
    }
    saveSlaValue(id: number,slaId: number): Observable<any> {
        return this.http.post(this.getApiUrl(`get_sla_details_post/${id}/${slaId}`),'');
    }
    getSlaValue(id: number): Observable<any> {
        let url = this.getApiUrl(`get_sla_details_records/${id}`);
        return this.http.get(url);
    }
    editSlaValue(data?: object): Observable<any>{
        return this.http.post(this.getApiUrl(`edit_customer_sla`),data);
    }
    renameFiles(data: object): Observable<any> {
        return this.http.post(this.getApiUrl(`rename_file`), data);
    }
    saveSelectedNavagiation(data) {
        let url = this.getApiUrl(`userColumnOrder`)
        return this.http.post(url, data);
    }

    deleteAppliance(applianceId: any){
        return this.http.post(this.getApiUrl(`delete_appliance`), applianceId, {responseType: 'text'});
    }

    setCustomerAppliance(data: any){
        this.customerAppliance.next(data);
    }

    getCustomerAppliance(id: number, type: string){
        let url = this.getApiUrl(`customers/${type}/${id}/appliances/views`);
        return this.http.get(url);
    }

    setTimelineRefresh(data){
        this.timelineRefresh.next(data);
    }

    setSliderNoteDetails(data){
        this.sliderNoteDetails.next(data);
    }


    downloadTimeline(type,logIds,customerId){
        let data = {
            type: type,
            logIds: logIds
        }
        let url = this.getApiUrl(`${customerId}/download_timeline_excel`);
        return this.http.post(url,data);
    }

    setDownloadTimeline(type){
        this.timelineDownload.next(type);
    }

    getCustomerAssetsFilter(id: number, filterId: string): Observable<any>{
        let url = this.getApiUrl(`getCustomerAssetFilter/${id}?filter=${filterId}`);
        return this.http.get(url);
    }

    getCustomerAssetsFilterRecords(id: number, type: string, groupId: string, fieldType: string, filterId: string): Observable<any>{
        let url = this.getApiUrl(`customers/${type}/${id}/assets/${groupId}/${fieldType}/${filterId}`);
        return this.http.get(url);
    }

    updateWhoIsViewing(availableUsers) {
        this.whoIsViewing.next(availableUsers)
    }

    getUsersList(){
        let url = this.getApiUrl(`get_clientUsers`);
        return this.http.get(url);
    }

    getDoNotWorkStatus(customerid){
        let url = this.getApiUrl(`getDoNotWorkStatus/${customerid}`);
        return this.http.get(url);
    }

    getTodayScheduleActivity(id, type, screen){
        let url = this.getApiUrl(`todayScheduledActivity/${id}/${type}/${screen}`);
        return this.http.get(url);
    }

    checkIfCustomerAssetIsDeletable(customerId: number, customerType: string, assetId: string): Observable<any> {
        return this.http.get(this.getApiUrl(`customers/${customerType}/${customerId}/assets/${assetId}/is-deletable`));
    }

    deleteCustomerAsset(id: number) {
        let body = new URLSearchParams();
        body.set('asset[id]', JSON.stringify(id));
        body.set('moduleValue[moduleId]', JSON.stringify(''));
        body.set('moduleValue[jobtodos]', JSON.stringify(''));
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post(this.getApiUrl(`delete_customer_asset`), body.toString(),{ headers, responseType: 'text'});
    }

    getCanWorkWithProperty(canWork) {
        this.canWorkWithCustomer.next(canWork)
    }
    setCanNotWorkWithProperty(canNotWork) {
        this.canNotWorkWithCustomer.next(canNotWork)
    }

    setTimelineDetails(details) {
        this.timelineDetails.next(details)
    }


    checkCustomerContact(contactId,customerId){
        let url = this.getApiUrl(`Checkdeletecustomercontacts/${contactId}/${customerId}`);
        return this.http.get(url);
    }

    listingCustomerContact(customerId,contactId){
        let url = this.getApiUrl(`listCustomerContacts/${customerId}?id=${contactId}`);
        return this.http.get(url);
    }

    setModalCloseEvent(data) {
        this.modalCloseEvent.next(data)
    }

    downloadMultipleFiles(params: any){
        return this.http.post(this.getApiUrl(`download_multiple_files`), params, {
            responseType: 'text'
        });
    }

    jobSidebarUpdate(data){
        this.jobDetailUpdate.next(data);
    }
}
