import { NgModule } from '@angular/core';
// import { OpportunityModalsModule} from "@app/shared";
import {KanbanBoardComponent} from "@app/shared/kanban-board/kanban-board.component";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CsComponentsModule} from "@app/shared/cs-components/cs-components.module";
import {DndModule} from "ngx-drag-drop";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {BsDropdownModule} from "ngx-bootstrap";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {PipesModule} from "@app/pipes";
import {DirectivesModule} from "@app/directives";
import {OpportunityModalsModule} from "@app/shared/opportunity-modals/opportunity-modals.module";

@NgModule({
    declarations: [
        KanbanBoardComponent
    ],
    exports:[
        KanbanBoardComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DndModule,
        TooltipModule,
        InfiniteScrollModule,
        PipesModule,
        DirectivesModule,
        OpportunityModalsModule
    ],
    providers: []
})
export class KanbanBoardModule { }
