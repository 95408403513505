import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { SidepanelModalService } from "@app/sidepanel/services/sidepanel-modal.service";
import { TemplateViewComponent } from "@app/shared/cs-components/template-preview/template-view.component";
import { CommonDataSource } from "@app/workflow-common/services/CommonDataSource";
var TemplatePreviewComponent = /** @class */ (function () {
    function TemplatePreviewComponent(modalService) {
        this.modalService = modalService;
        this.linkTitle = 'Preview';
        this.type = 'email';
        this.disabledBtn = true;
    }
    TemplatePreviewComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.type == 'email') {
                            this.title = 'Email template preview';
                        }
                        return [4 /*yield*/, CommonDataSource.getClientDetails()];
                    case 1:
                        _a.sent();
                        this.disabledBtn = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    TemplatePreviewComponent.prototype.openPreview = function () {
        var _a = this, subject = _a.subject, message = _a.message, type = _a.type, title = _a.title;
        var initialState = {
            subject: subject,
            message: message,
            type: type,
            title: title
        };
        var bsModalRef = this.modalService.show(TemplateViewComponent, {
            initialState: initialState,
            'size': 'xl',
        });
    };
    return TemplatePreviewComponent;
}());
export { TemplatePreviewComponent };
