import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var CsToastBoxService = /** @class */ (function () {
    function CsToastBoxService() {
        this.toastControlSource = new BehaviorSubject(null);
        this.toastControl$ = this.toastControlSource.asObservable();
    }
    CsToastBoxService.prototype.show = function (message, duration) {
        if (!duration && message.length >= 100) {
            if (message.length >= 200) {
                duration = 60000; //  display message for 1 minute if duration is not defined and the message length is > 200.
            }
            else {
                duration = 30000; // display message for 30 seconds if duration is not defined and the message length is > 100 and < 200.
            }
        }
        this.toastControlSource.next({
            action: 'show',
            message: message,
            duration: duration
        });
    };
    CsToastBoxService.prototype.hide = function () {
        this.toastControlSource.next({ action: 'hide' });
    };
    CsToastBoxService.ngInjectableDef = i0.defineInjectable({ factory: function CsToastBoxService_Factory() { return new CsToastBoxService(); }, token: CsToastBoxService, providedIn: "root" });
    return CsToastBoxService;
}());
export { CsToastBoxService };
