<div class="modal-header">
    <h4 class="modal-title pull-left">{{jobBlockData['title']}}</h4>
</div>
<div class="modal-body">
    <div class="loading-wrap" *ngIf="isLoading">
        <span class="circle1"></span>
        <span class="circle2"></span>
        <span class="circle3"></span>
    </div>
    <div *ngIf="!isLoading">
        <form class="no-form-style" *ngIf="form" [formGroup]="form" novalidate>
            <div class="cs-d-flex flex-column">
                <div class="cs-helper-section bg-white cs-border cs-border--bottom">
                    <div class="cs-help-text-color p--15">{{jobBlockData['description']}}</div>
                </div>
            </div>
            <div class="item clearfix p-15" >
                <div class="control">
                    <label class="control-label required_field">Add job description</label>
                    <select class="full-width-select2 cs-select2-wfe mb-10 default-input-height"
                            (onChange)="selectedDescription($event)"
                            csSelect2
                            [select2Config]="{}"
                            [dropdownvalue]="selectedJobDescriptionInputs['jobDescriptionId']">
                        <option value="" disabled translate>Please.Choose</option>
                        <option *ngFor="let item of jobDescriptions" [value]="item.id">{{item.text}}</option>
                    </select>
                </div>
            </div>
        </form>
    </div>


</div>
<div class="modal-footer">
    <ng-container *ngIf="!isLoading else footerSection">
        <button  class="btn btn-danger btn-medium float-left" (click)="remove()" type="button">Delete</button>
        <button class="btn btn-link" (click)="closeModal()">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="!form.valid || isFormSubmitted" (click)="save()">
            <span *ngIf="!isFormSubmitted" translate>Save</span>
            <span *ngIf="isFormSubmitted" translate>Saving</span>
        </button>
    </ng-container>
</div>

<ng-template #footerSection>
    <button class="btn btn-link" [disabled]="true">Cancel</button>
    <button type="submit" class="btn btn-primary" [disabled]="true">
        <span translate>Save</span>
    </button>
</ng-template>
