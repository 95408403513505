<div class="fleet-map-view">
  <!-- View mode section -->
  <div class="map_view_mode_section" id="map_view_mode_section">

    <div *ngIf="viewMode" class="d-flex" (click)="removeMarkerPopup()">
      <!-- ================================== Office dropdown ================================================= -->
      <div class="view_mode_list dropdown">
        <span class="dropdown-toggle" id="dropdownOffice" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          <img class="icon_img" src="images/live-map/office.svg" />
          <img class="icon_img active" src="images/live-map/office-active.svg" />
          <span class="desc">Office</span>
        </span>

        <div class="view_mode_description_box dropdownOffice dropdown-menu" aria-labelledby="dropdownOffice">
          <div class="view_mode_description_box_card">
            <ul class="address_supplier_list office">
              <li>
                <span class="custom_checkbox">
                  <input type="checkbox" [checked]="officeDisplay" id="officeAddress" name="officeAddress"
                    (change)="toggleOfficeDisplay()" />
                  <label class="cursor-pointer m-0" for="officeAddress"><span class="text_item">Show
                      office</span></label>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- ================================== Home address dropdown ================================================= -->
      <div class="view_mode_list dropdown">
        <span class="dropdown-toggle" (click)="getText()" role="button" id="dropdownHomeAddress" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          <img class="icon_img" src="images/live-map/home.svg" />
          <img class="icon_img active" src="images/live-map/home-active.svg" />
          <span class="desc">Home address</span>
          <span *ngIf="userHomeCount > 0" class="badge">{{userHomeCount}}</span>
        </span>

        <div class="view_mode_description_box dropdown-menu" aria-labelledby="dropdownHomeAddress">
          <div class="view_mode_description_box_card">
            <div class="search_box">
              <div class="search-container">
                <span class="search-icon"></span>
                <input class="search-input" [(ngModel)]="homeSearchText" placeholder="Search" (keyup)="searchHome()">
              </div>
            </div>
            <ul class="address_supplier_list" *ngIf="filteredHomeAddress.length != 0">
              <li>
                <span class="custom_checkbox">
                  <input type="checkbox" id="address_show_all" name="home_address" [checked]="homeSelectAll"
                    (change)=selectAllHomeAddressList($event) />
                  <label class="cursor-pointer m-0" for="address_show_all">
                    <img class="profile_icon" src="images/profile_avatar_small.jpg" />
                    <span class="text_item">Show all</span>
                  </label>
                </span>
              </li>
              <li *ngFor=" let address of filteredHomeAddress; let i = index; ">
                <span class="custom_checkbox">
                  <input type="checkbox" [checked]="address.isSelected" value="{{address.id}}"
                    (change)="isHomeSelected($event, i, address)" name="home_address" id="{{'address_'+ i}}" />

                  <label class="cursor-pointer m-0" for="{{'address_'+ i}}">
                    <img class="profile_icon"
                      [src]="address.profilePicture && address.profilePicture != '' && address.profilePicture != 'NULL' ? address.profilePicture : 'images/profile_avatar_small.jpg'" />
                    <span class="text_item">{{address.userName}}</span>
                  </label>
                </span>
              </li>
            </ul>

            <!-- ======================= for no record ======================== -->
            <div class="view_mode_description_box_card no-record" *ngIf="filteredHomeAddress.length == 0">
              <img class="iconImg" src="images/live-map/home-gray.svg" />
              <div class="title_text">There no home address </div>
              <div>Try to save some location to see the list</div>
            </div>
          </div>
        </div>
      </div>

      <!-- ================================== Supplier dropdown ================================================= -->
      <div class="view_mode_list dropdown">
        <span class="dropdown-toggle" (click)="getText()" id="dropdownSupplier" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          <img class="icon_img" src="images/live-map/factory.svg" />
          <img class="icon_img active" src="images/live-map/factory-active.svg" />
          <span class="desc">Supplier</span>
          <span *ngIf="supplierCount > 0" class="badge">{{supplierCount}}</span>
        </span>

        <div class="view_mode_description_box dropdown-menu" aria-labelledby="dropdownSupplier">
          <div class="view_mode_description_box_card">
            <div class="search_box">
              <div class="search-container">
                <span class="search-icon"></span>
                <input class="search-input" [(ngModel)]="supplierSearchText" placeholder="Search"
                  (keyup)="searchSuppliers()">
              </div>
            </div>
            <ul class="address_supplier_list" *ngIf="filteredSupplierAddress.length != 0">
              <li>
                <span class="custom_checkbox">
                  <input type="checkbox" id="supplier_show_all" name="supplier" [checked]="allCheckboxSelectedSupplier"
                    (change)=allCheckboxSelectedSuppliers($event) />
                  <label class="cursor-pointer m-0" for="supplier_show_all">
                    <img class="profile_icon" src="images/profile_avatar_small.jpg" />
                    <span class="text_item">Show all</span>
                  </label>
                </span>
              </li>
              <li *ngFor=" let supplier of filteredSupplierAddress; let i = index; ">
                <span class="custom_checkbox">
                  <input type="checkbox" [checked]="supplier.isSelected" value="{{supplier.id}}"
                    (change)="isAllSelectedSupplier($event, i, supplier)" name="supplier" id="{{'supplier'+ i}}" />

                  <label class="cursor-pointer m-0" for="{{'supplier'+ i}}">
                    <img class="profile_icon" src="images/profile_avatar_small.jpg" />
                    <span class="text_item">{{supplier.name}}</span>
                  </label>
                </span>
              </li>
            </ul>
            <!-- ======================= for no record ======================== -->
            <div class="view_mode_description_box_card no-record" *ngIf="filteredSupplierAddress.length == 0">
              <img class="iconImg" src="images/live-map/factory-gray.svg" />
              <div class="title_text">No suppliers found</div>
              <div>Start adding suppliers to find them in the list.</div>
            </div>
          </div>
        </div>
      </div>

      <!-- ================================== Saved dropdown ================================================= -->
      <div class="view_mode_list dropdown">
        <span class="dropdown-toggle" (click)="getText()" id="dropdownSaved" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          <img class="icon_img" src="images/live-map/bookmark.svg" />
          <img class="icon_img active" src="images/live-map/bookmark-active.svg" />
          <span class="desc">Saved</span>
          <span *ngIf="savedLocationCount > 0" class="badge">{{savedLocationCount}}</span>
        </span>

        <div class="view_mode_description_box dropdown-menu" aria-labelledby="dropdownSaved">
          <div class="view_mode_description_box_card">
            <div class="search_box">
              <div class="search-container">
                <span class="search-icon"></span>
                <input class="search-input" [(ngModel)]="locationSearchText" placeholder="Search location"
                  (keyup)="searchLocation()">
              </div>
            </div>
            <ul class="address_supplier_list" *ngIf="filteredAddress.length != 0">
              <li>
                <span class="custom_checkbox">
                  <input type="checkbox" id="saved_location_show_all" name="saved_location"
                    [checked]="allCheckboxSelectedSavedLocation" (change)=checkUncheckAllAddressList($event) />
                  <label class="cursor-pointer m-0" for="saved_location_show_all">
                    <span class="text_item">Show all</span>
                  </label>
                </span>
              </li>
                <li *ngFor=" let address of filteredAddress; let i = index; ">
                <span class="custom_checkbox">
                  <input type="checkbox" [checked]="address.isSelected" value="{{address.id}}"
                         (click)="isAllSelected($event, i, address)" name="saved_location" id="{{'saveLocation_id_'+ i}}" />

                  <label class="cursor-pointer m-0" for="{{'saveLocation_id_'+ i}}">
                    <div class="map_search_result border-0">
                      <div class="card_box border-0">
                        <div class="map_search_detail">
                          <div class="d-flex justify-between align-center">
                            <p class="capitalize"><strong>{{address.name}}</strong></p>
                            <a class="saved_location_delete poiter-cursor" (click)="deleteLocation($event,address)">Delete</a>
                          </div>
                          <p>{{address.addressLine1}}</p>
                        </div>
                      </div>
                    </div>
                  </label>
                </span>
                </li>
            </ul>
            <!-- ======================= for no record ======================== -->
            <div class="view_mode_description_box_card no-record" *ngIf="filteredAddress.length == 0">
              <img class="iconImg" src="images/live-map/bookmark-gray.svg" />
              <div class="title_text">No location is saved</div>
              <div>Start saving locations to find them in the list.</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="view_mode_list" (click)="toggleViewMode()">
      <img *ngIf="!viewMode" class="icon_img" src="images/live-map/eye.svg" />
      <img *ngIf="viewMode" class="icon_img" src="images/live-map/eye-active.svg" />
    </div>
    <!-- <div class="view_mode_list">
      <img class="icon_img" src="images/live-map/tv.svg" />
    </div>-->
  </div>
</div>

