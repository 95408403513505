<div class="template-width cs-theme--{{themeName}}" [ngClass]="{'no-pointer': disabled}">
    <div id="parent-folder" class="">
        <a class="btn dropdown-toggle padding-none float-left mlr-5" [class.view_mode]="viewMode" [ngClass]="{'disabled-color': disabled || isArchived}" >
            <div class="template-object dropdown">
                <div id="userMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" class="template-dropdown">
                    <div class="template-name-show-section">
                        <span class="margin-r5 template-more-text-align template-title" placement="bottom"
                              translate
                              *ngIf="this.initialScreenLabel == this.screenLabel && placeHolder">{{ placeHolder }}</span>
                        <span class="margin-r5 template-more-text-align template-title" placement="bottom"
                              translate
                              *ngIf="(this.initialScreenLabel != this.screenLabel) || !placeHolder">{{ screenLabel }}</span>
                    </div>
                    <ng-container *ngIf="screen != 'opportunity_template'">
                        <i class="fa fa-angle-down dropdown-list-icons" aria-hidden="true"></i>
                    </ng-container>
                    <ng-container *ngIf="screen == 'opportunity_template'">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="#555555" class="down-arrow">
                            <path
                                d="M10.0001 19C9.7664 19.0005 9.53996 18.9191 9.36005 18.77C9.25879 18.6861 9.17509 18.583 9.11374 18.4666C9.05239 18.3503 9.01459 18.223 9.00251 18.092C8.99044 17.961 9.00432 17.8289 9.04337 17.7033C9.08241 17.5777 9.14585 17.4611 9.23005 17.36L13.7101 12L9.39005 6.63C9.30699 6.52771 9.24495 6.41002 9.20752 6.28368C9.17009 6.15734 9.158 6.02485 9.17194 5.89382C9.18587 5.76279 9.22557 5.63581 9.28875 5.52017C9.35192 5.40454 9.43732 5.30252 9.54005 5.22C9.64352 5.12897 9.76468 5.0603 9.89595 5.01831C10.0272 4.97632 10.1657 4.96192 10.3028 4.976C10.4399 4.99009 10.5726 5.03236 10.6926 5.10016C10.8126 5.16796 10.9173 5.25983 11.0001 5.37L15.8301 11.37C15.9771 11.5489 16.0575 11.7734 16.0575 12.005C16.0575 12.2366 15.9771 12.4611 15.8301 12.64L10.8301 18.64C10.7297 18.761 10.6023 18.8567 10.4581 18.9192C10.3139 18.9818 10.157 19.0095 10.0001 19Z"
                                fill="#555555"
                            />
                        </svg>
                    </ng-container>
                </div>
                <div class="dropdown-menu dropdown-menu-template-list quick-links large-quick-links transparent-icon" aria-labelledby="userMenuButton" id="userList">
                    <span class="dropdown-menu-arrow-user"></span>
                    <div class="template-container">
                        <div class="template-list-border template-list-height object-list email-template-container">
                            <div *ngIf="loading || templateList.length || searchValue || selectedFolder">
                                <div class="search-container">
                                    <i class="fa fa-search search-icon"></i>
                                    <div class="folder-selection" *ngIf="selectedFolder">
                                        <div class="folder-label" [ngClass]="{'dynamic-border': showFolderBorder}">In: {{selectedFolder}}</div>
                                        <!-- <div class="selected-folder-name">{{selectedFolder}}</div> -->
                                    </div>
                                    <input class="search-template" placeholder="Search folders and templates" [ngModel]="filterParams.search" (keyup)="initializeSearch($event)" />
                                    <span class="clear-input" *ngIf="searchValue || selectedFolder" (click)="clearSearch()" onclick="event.stopPropagation();"><i class="fa fa-times-circle" aria-hidden="true"></i></span>
                                </div>
                            </div>

                            <ul class="parent-div">
                                <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ templateList: templateList }"></ng-container>
                            </ul>
                            <ng-template #recursiveListTmpl let-templateList="templateList">
                                <ng-container *ngFor="let item of templateList;let last = last;">
                                    <li class="tree-list" *ngIf="!item.deleted && !loading" [ngClass]="{'last-tree':item.level >= 4 }" onclick="event.stopPropagation();">
                                        <div class="parent-class" *ngIf="item as parent">
                                            <div class="link-parent" *ngIf="item.isOpen && item.level <= 5"></div>
                                            <div class="tree-padding" [ngClass]="{'selected-tree':item.selected,'cursor-none': (freemiumAccess && item.isFreemium != '1' && item.isFolder == '0' && (screen == 'option_template' || screen == 'proposal_template'))}">
                                                <span class="selector-area template" [ngClass]="{'selected-children':item.selected,'email-template-list':item.isFolder == '0'}" (mouseenter)="findPosition($event,'template')" (mouseleave)="tooltipHide('template')">
                                                    <span [ngClass]="{'open-folder-animation':item.isOpen }" class="folder-arrow" *ngIf="item.isFolder == '1' && !searchValue && !selectedFolder" (click)="viewChild(item)">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path
                                                                d="M10.0001 19C9.7664 19.0005 9.53996 18.9191 9.36005 18.77C9.25879 18.6861 9.17509 18.583 9.11374 18.4666C9.05239 18.3503 9.01459 18.223 9.00251 18.092C8.99044 17.961 9.00432 17.8289 9.04337 17.7033C9.08241 17.5777 9.14585 17.4611 9.23005 17.36L13.7101 12L9.39005 6.63C9.30699 6.52771 9.24495 6.41002 9.20752 6.28368C9.17009 6.15734 9.158 6.02485 9.17194 5.89382C9.18587 5.76279 9.22557 5.63581 9.28875 5.52017C9.35192 5.40454 9.43732 5.30252 9.54005 5.22C9.64352 5.12897 9.76468 5.0603 9.89595 5.01831C10.0272 4.97632 10.1657 4.96192 10.3028 4.976C10.4399 4.99009 10.5726 5.03236 10.6926 5.10016C10.8126 5.16796 10.9173 5.25983 11.0001 5.37L15.8301 11.37C15.9771 11.5489 16.0575 11.7734 16.0575 12.005C16.0575 12.2366 15.9771 12.4611 15.8301 12.64L10.8301 18.64C10.7297 18.761 10.6023 18.8567 10.4581 18.9192C10.3139 18.9818 10.157 19.0095 10.0001 19Z"
                                                                fill="#979797"
                                                            />
                                                        </svg>
                                                    </span>
                                                    <span *ngIf="!item.isOpen && item.isFolder == '1'" [ngClass]="{'padding-left': searchValue || selectedFolder}" class="folder-icon-group"><i class="fa fa-folder folder-icon" aria-hidden="true"></i></span>
                                                    <span *ngIf="item.isOpen && item.isFolder == '1'" [ngClass]="{'padding-left': searchValue || selectedFolder}" class="folder-icon-group"><i class="fa fa-folder-open folder-icon" aria-hidden="true"></i></span>
                                                    <span *ngIf="item.isFolder == '0' && screen != 'option_template' && screen != 'proposal_template' && screen != 'opportunity_template'" [ngClass]="{'padding-left': searchValue || selectedFolder}" class="folder-icon-group"><i class="fa fa fa-envelope email-icon" aria-hidden="true"></i></span>
                                                    <span *ngIf="item.isFolder == '0' && (screen == 'option_template' || screen == 'proposal_template' || screen == 'opportunity_template')" [ngClass]="{'padding-left': searchValue || selectedFolder}" class="folder-icon-group"><i class="fa-solid fa-table-layout email-icon"></i></span>
                                                    <span class="folder-name" translate *ngIf="item.value" [ngClass]="{'bold-folder-name': item.selected}" (click)="selectFolder(item)">{{ item.value }}</span>
                                                    <div class="flex-display flex-property">
                                                        <div class="template-name-box">
                                                            <span class="folder-name" translate *ngIf="item.templateName" [ngClass]="{'bold-folder-name': item.selected,'no-pointer': (freemiumAccess && item.isFreemium != '1' && item.isFolder == '0' && (screen == 'option_template' || screen == 'proposal_template'))}" (click)="selectFolder(item)">{{ item.templateName }}</span>
                                                        </div>
                                                        <div *ngIf="(screen == 'option_template' || screen == 'proposal_template') && item.isFolder == '0' && item.templateName && freemiumAccess && item.isFreemium != '1'" class="proposal-bubble">
                                                            <span>Proposal+</span>
                                                        </div>
                                                    </div>
                                                   <span *ngIf="item.isFolder == '0' && showEditOption" class="edit-btn" (click)="addNewTemplate(item)">Edit</span>
                                                    <span *ngIf="item.isFolder == '0' && item.templateName && freemiumAccess && item.isFreemium != '1' && (screen == 'option_template' || screen == 'proposal_template')" class="tooltipTemplatetext">This template can't be select as it requires a Proposal+ licence.</span>
                                                </span>
                                                <div class="folder-path" *ngIf="searchValue" [ngClass]="{'padding-left': searchValue}">{{ item.displayPath }}</div>
                                            </div>

                                            <ul *ngIf="item.children?.length && item.isOpen">
                                                <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ templateList: item.children }"> </ng-container>
                                            </ul>
                                            <div *ngIf="item.loading" class="loader-container">
                                                <ul class="loader-list">
                                                    <div class="text-layout animated-background small line-1"></div>
                                                    <div class="text-layout animated-background small line-2"></div>
                                                    <div class="text-layout animated-background small line-3"></div>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ng-container>
                            </ng-template>
                            <ng-container *ngIf="loading">
                                <ul class="initial-loader-list padding-left">
                                    <div class="text-layout animated-background small line-1"></div>
                                    <div class="text-layout animated-background small line-2"></div>
                                    <div class="text-layout animated-background small line-3"></div>
                                </ul>
                            </ng-container>
                            <ng-container *ngIf="!searchValue && !templateList.length && !loading">
                                <div class="no-result-text empty-templates" *ngIf="screen != 'option_template' && screen != 'proposal_template' && screen != 'opportunity_template'">No email template available</div>
                                <div class="no-result-text empty-templates" *ngIf="screen == 'option_template'">No option template available</div>
                                <div class="no-result-text empty-templates" *ngIf="screen == 'proposal_template'">No proposal template available</div>
                                <div class="no-result-text empty-templates" *ngIf="screen == 'opportunity_template'">No opportunity template available</div>
                            </ng-container>
                            <ng-container *ngIf="searchValue && !templateList.length && !loading">
                                <div class="no-search-result">
                                    <div>
                                        <i class="fa fa-question-circle" aria-hidden="true"></i>
                                    </div>
                                    <div class="no-result-text">
                                        There are no search results
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </div>
</div>
<div *ngIf="showAddNewTemplate">
    <a class="new-template" (click)="addNewTemplate()">Add new template</a>
</div>
