import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {EmailListenerService} from "@app/shared-modal/email-modal/email-listener.service";
import {Subject, combineLatest, Subscription} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {EmailModalComponent} from "@app/shared-modal/email-modal/email-modal.component";
import {CsToastBoxService} from "@app/services";

@Component({
  selector: 'app-shared-modal',
  templateUrl: './shared-modal.component.html',
  styles: []
})
export class SharedModalComponent implements OnInit, OnDestroy {

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    subscriptions: Subscription[] = [];

    constructor(
        private _emailEventService: EmailListenerService,
        private changeDetection: ChangeDetectorRef,
        private toast: CsToastBoxService
    ) {
    }

  ngOnInit() {

      const _combine = combineLatest(
          this._emailEventService.beforeSend$,
          this._emailEventService.afterSend$,
          this._emailEventService.send$
      )
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(() => this.changeDetection.markForCheck());

      this.subscriptions.push(
          this._emailEventService.beforeSend$
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((data: any) => {
                  this.toast.show('Your email is being sent', 4000);
              })
      );

      this.subscriptions.push(
          this._emailEventService.send$
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((component: EmailModalComponent) => {
                  component.processEmail();
              })
      );
      this.subscriptions.push(_combine);
  }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

}
