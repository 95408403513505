<div class="row-fluid mainContainer">
    <div class="span12">
        <div class="row-fluid mainBody">
            <div class="span12">
                <div class="row-fluid">
                    <div class="span12 receiptsTitle">
                        <p class="receiptsTitleText" translate>Supplier.Invoice.Details</p>
                    </div> 
                </div> 

                <div class="row-fluid receiptsDetails">
                    <div class="span8 ">
                        <div class="row-fluid">
                            <div class="span12 receiptsInputDetails">
                                <div style="margin-right:24px">
                                    <label for="date">
                                        <span translate>Date</span>
                                        <span class="mandatory-fields">&nbsp;*</span>
                                    </label>
            
                                    <div class="controls scheduled-date-add date-input-field" style="margin-bottom: 0px;">
                                        <div class="input-append datepicker-append" style="margin-bottom: 0px;">
                                            <cs-datepicker
                                                [type]="'default'"
                                                [initialDateVal]="invoiceDate"
                                                [placeholder]="'--Select date--'"
                                                [isHideWeekNumbers]="true"
                                                [container]="'.datepicker-container'"
                                                [defaultValue]="invoiceDate"
                                                [isReadOnly]="true"
                                                (selectedDate)="setNewScheduledDate($event)"
                                                >
                                            </cs-datepicker>
                                        </div>
                                        <div class="datepicker-container" style="margin-bottom: 0px;"></div>
                                    </div>
                                </div>

                                <div style="margin-right:24px"  *ngIf="!isLoading" id="selectSupplier">
                                    <label for="selectedSupplier"> 
                                        <span translate>Supplier</span>
                                        <span class="mandatory-fields">&nbsp;*</span>
                                    </label>
                                    
                                    <input 
                                        id="showSupplier"
                                        [placeholder] = "supplierDropdownPlaceholder"
                                        [select2Config]="selectSuppliersList" 
                                        class="full-width-select2 selectSupplier"
                                        csSelect2
                                        (ngModelChange)="showChildModal($event)"
                                        [(ngModel)]="csSelectedSupplierId"
                                        type="text"
                                    />
                                </div>

                                <div class="input-field" style="margin-right:24px;">
                                    <label for="invoiceNumber">
                                        <span translate>Invoice.number</span>
                                        <span class="mandatory-fields">&nbsp;*</span>
                                    </label>
            
                                    <input 
                                        (keyup)="checkInvoiceNumber($event)" 
                                        type="text" 
                                        name="invoiceNumber" 
                                        [(ngModel)]="invoiceNumber" 
                                        id="invoiceNumber"
                                        style="background: white; margin-bottom:0px"/>
                                </div>

                                <div class="po-input-field">
                                    <div class="input-field">
                                        <label for="poNumber" translate>PO.Number</label>
                                        <input
                                            type="text" 
                                            name="poNumber" 
                                            [(ngModel)]="poNumber" 
                                            id="poNumber"
                                            (blur)="changePoNumber($event)"
                                            style="background: white;"/>
                                    </div>
                                    
                                    <div *ngIf="poErrorCode == 1 && poErrorMsg!=null && poErrorMsg!=''">
                                        <span style="color: #ff0000;">{{poErrorMsg}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row-fluid choice">
                            <div class="span12 putChoiceAtEnd">
                                <div style="margin-right: 16px;" translate>
                                    Switch.line.item.type
                                </div>
                                <div style="margin-right: 16px;"> 
                                    <label class="partsAndItems" translate>
                                        <input type="radio" 
                                        [value]="'selectedOption'" 
                                        name="selectedOption" 
                                        [checked] ="selectedOption === 'parts'"
                                        [disabled]="poErrorCode === 1"
                                        (change)="changeOption('parts')"/> Parts.list
                                    </label>
                                </div>
                                <div>
                                    <label class="partsAndItems" translate>
                                        <input 
                                        type="radio" 
                                        [value]="'selectedOption'" 
                                        name="selectedOption" 
                                        [checked] = "selectedOption === 'items'"
                                        (change)="changeOption('items')"/> Items.list
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="row-fluid lineItemsDetails">
                            <div class="span12">
                                <div class="row-fluid">
                                    <div class="span12 listItemsOrPartsDetails">
                                        <hot-table
                                            hotId="lineItemsDetails" 
                                            [settings]="spreadSheetSettings"
                                            [data]="spreadSheetData">
                                        </hot-table>
                                    </div>
                                </div>

                                <div class="row-fluid without-padding grand-totals">
                                    <div class="spreadsheet-totals-wrapper">
                                        <ul class="spreadsheet-totals-wrapper vertical-grand-totals below-spreadsheet above-section">
                                            <li>
                                                <div class="total-label">
                                                    <strong translate>Total.Cost.exc.VAT</strong>
                                                </div>
                                                <div class="total-value" style="text-align: right;">
                                                    <strong>{{currencySymbols}}{{calculatedNetPrice}}</strong>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="total-label">
                                                    <strong >{{taxLabel}}</strong>
                                                </div>
                                                <div class="total-value" style="text-align: right;">
                                                    <span *ngIf="allTaxItemsEqual" style="padding-right: 0px;">({{firstTaxItem}}%)</span>
                                                    <strong>{{currencySymbols}}{{calculatedVatRate}}</strong>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="total-label">
                                                    <strong translate>Total</strong>
                                                </div>
                                                <div class="total-value" style="text-align: right;">
                                                    <strong>{{currencySymbols}}{{calculatedTotalAmount}}</strong>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                    <div class="span4 invoiceFrame">
                        <iframe *ngIf="isPdf && invoicePdf!=null" [src]="invoicePdf" alt="PDF not found" class="invoicePdf"></iframe>
                        <div *ngIf="!isPdf" id="displayTiffFile" class="invoicePdf">
    
                            <p>.tiff file</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="row-fluid mainFooter">
            <div class="span12">
                <div class="modal-footer" style="padding-right: 24px;">
                    <a (click)="closeFSModal()" class="btn" translate>Cancel</a>
                    <button 
                        *ngIf="isDisabledConfirmButton" 
                        (click)="saveParseData()" 
                        class="btn btn-primary">
                        {{buttonName}}
                    </button>
                
                    <button 
                        *ngIf="!isDisabledConfirmButton" 
                        disabled 
                        class="btn btn-primary">
                        {{buttonName}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<bs-modal-backdrop class="modal-backdrop fade show" [ngClass]="{'in': openChildModal}"   *ngIf="openChildModal"></bs-modal-backdrop>

<div bsModal #actionModal="bs-modal" class="modal fade" [ngClass]="{'in': openChildModal}" id="fileUploadingModal" aria-labelledby="fileUploadingModal" aria-hidden="true">
    <div class="modal-dialog modal-sm">
        <ng-container >
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" aria-label="Close" (click)="hideChildModal()">&times;</button>
                    <h4 translate>Supplier.name.change</h4>
                </div>
                <div class="modal-body">
                    <div class="row-fluid">
                        <div class="span12" style="text-align: justify;">
                            <p *ngIf="thirdPartySupplierName!=null" translate [translateParams]="{thirdPartySupplierName: thirdPartySupplierName, csSelectedSupplierName: csSelectedSupplierName}">Our.AI.has.determined.that.your.invoice.receipt.had.as.the.supplier.name.however.your.chosen.supplier.is</p>
                            <p translate>
                                To.make.things.easier.in.the.future.Commusoft.would.like.to.remember.this.mapping.to.avoid.you.having.to.select.your.supplier.in.the.future
                            </p>
                            <p translate>
                                Please.type.in.confirm.below.or.click.cancel.if.you.do.not.want.us.to.save.this.mapping.
                            </p>
                        </div>

                        <div class="row-fluid">
                            <div class="span12 confirm_delete_wrapper">
                                <div class="input-outer-wrapper">
                                    <div class="input-prepend box-shadow input-fullwidth">
                                    <span class="add-on">
                                        <span class="ss-caution"></span>
                                    </span>
                                        <div class="input-wrapper">
                                            <input [(ngModel)]="confirmTextModel"
                                                   (input)="checkConfirmText()"
                                                   (keyup.enter)="saveCSSelectedSupplier()"
                                                   type="text" class="modalDeleteNumber"
                                                   id="confirm_delete_input"/>
                                        </div>
                                    </div>
                                    <div>
                                    <span *ngIf="inValidConfirmText"  class="control-error-message show row-fluid">
                                        <span translate [translateParams]="{Object: validationMessage}">You.need.to.type.the.word.Object</span>
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button 
                        (click)="hideChildModal()" 
                        class="btn">
                        <span translate>Close</span>
                    </button>

                    <button type="button"
                        (click)="saveCSSelectedSupplier()"
                        [disabled]='inValidConfirmText || confirmTextModel==""'
                        class="btn btn-primary"
                        translate>Confirm
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div class="fileDraggedState job-costings-scrollwrapper" [class.show]="fadeBackground">
</div>

<div class="errorMsgDiv" *ngIf="showInvoiceErrMsg">{{invoiceCreationError}}
</div> 


