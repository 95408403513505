import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject, Input,
    OnInit,
    Output,
    Renderer, Renderer2, ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';


//--
import { CsToastBoxService } from "@app/services";
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
import {fontfamilyService} from "@app/shared/services/fontfamily.service";
import {LayoutConfigurationService} from "@app/shared/layout-configuration/layout-configuration.service";
declare var jQuery: any;


@Component({
    selector: 'cs-sales-full-screen-modal',
    templateUrl: './cs-sales-full-screen-modal.component.html',
    styles: [`
    .mr-2{
        margin: 9px 0 9px 0px !important;
    }
    `],
    host: {
        "class": "full-screen-widget ppm-widget animated full-screen-hide-show"
    }
})
export class CsSalesFullScreenModalComponent implements OnInit {
    @Input() header: string;
    @Input() showFooter: boolean;
    @Input() bodyClass:string='';
    @Input() subTitle:string='';
    @Input() showSubTitleAsTemplate:boolean = false;
    @Input() showbreakdownHeader:boolean = false;
    @Input() hideCloseButton: boolean = false;
    @Input() screenName:string;
    @Output() onContentOpen: EventEmitter<any> = new EventEmitter();
    @Output() onContentClose: EventEmitter<any> = new EventEmitter();
    @ViewChild('widgetBody') widgetBody: ElementRef;
    action: string = 'save';
    ppmList: object = [];
    subscriptions: Object = {};
    constructor(
        private _hostRef: ElementRef,
        private _renderer: Renderer,
        private route: ActivatedRoute,
        private location: Location,
        private toastBox: CsToastBoxService,
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope,
        private propoalService: ProposalTemplateService,
        private router: Router,
        private fontFamilyService: fontfamilyService,
        private renderer: Renderer2,
        private tableService: LayoutConfigurationService
    ) {
    }

    ngOnInit() {
        // For modal box purpose
        this._renderer.setElementAttribute(this._hostRef.nativeElement, 'tabIndex', '-1');
        if(this.bodyClass) {
            this._renderer.setElementClass(this.widgetBody.nativeElement, this.bodyClass, true);
        }

        this.subscriptions['closeModalSubscriptions'] = this.propoalService.closeModal$.subscribe(res => {
			if (res['close']) {
				this.close();
			}
		});

        this.fontFamilyService.googleFontSendComponent$.subscribe((res: any) => {
            if(res != '' && res != null) {
                const style = this.renderer.createElement('style');
                this.renderer.appendChild(this._hostRef.nativeElement, style);
                this.renderer.setProperty(style, 'textContent', `@import url('${res}');`);
            }
        });
    }

    // Open widget
    open(shorten:boolean=true, animate=null) {
        if(animate) {
            jQuery(this._hostRef.nativeElement).appendTo("body").css('display', 'block')
        } else {
            jQuery(this._hostRef.nativeElement).appendTo("body").css('display', 'block').addClass("bounceInUp").removeClass('bounceOutDown');
        }
        // shorten the header if it is large
        this.onContentOpen.emit();
        if(shorten) {
            setTimeout(() =>{
                this.shortenHeader();
            },100);
        }
    }

    saveEditorThumbnail(){
        this.propoalService.setEditorThumbnail(true);
        this.propoalService.saveContentLibrary(true);
        setTimeout(() => {
            if(this.screenName == 'set_up_pipelines') {
                this.tableService.loadData();
            }
            this.close();
        }, 700);
    }


    // Close widget
    close() {
        if(this.screenName && this.screenName == 'optionTemplate') {
            this.propoalService.savePages(true)
            this.router.navigate([], {relativeTo: this.route, queryParams:{}})
            jQuery(this._hostRef.nativeElement).addClass('bounceOutDown').removeClass("bounceInUp");
            setTimeout(() => {
                this.onContentClose.emit();
            }, 100);
        } else {
            jQuery(this._hostRef.nativeElement).addClass('bounceOutDown').removeClass("bounceInUp");
            setTimeout(() => {
                this.onContentClose.emit();
            }, 100);
        }
    }

    ngOnDestroy() {
        // Remove content from body on destroy
        jQuery(this._hostRef.nativeElement).remove();
    }

    shortenHeader(){
        if(this.header.length > 75){
            this.header = this.header.substring(0, 75) + '..';
        }
    }

    @HostListener('click', ['$event'])
    confirmFirst(event: Event) {
        let iframe : any = document.getElementById('cs_editor');
        if(iframe) {
            let csSelected = "remove_cs_selected";
            iframe.contentWindow.postMessage(csSelected, '*');
        }
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave(event: DragEvent) {
        let iframe : any = document.getElementById('cs_editor');
        if(iframe) {
            iframe.contentWindow.postMessage("remove_helper_lines", '*');
        }
    }
}
