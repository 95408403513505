export { CsToastBoxService } from './utility-services/cs-toast-box.service';
export * from './ajs-upgraded-providers';

export { ContractTemplatesService } from './http-services/contract-templates.service';
export { SystemSettingsService } from './http-services/system-settings.service';
export { SettingsInvoiceCategoriesService } from './http-services/settings-invoice-categories.service';
export { JobsSlaService } from './http-services/jobs-sla.service';
export { SettingsPpmService } from './http-services/settings-ppm.service';
export { CustomerContractsService } from './http-services/customer-contracts.service';
export { CcWhatsIncludedPPMService } from './http-services/cc-whats-included-ppm.service';
export { CanDeactivateGuard } from './router-services/can-deactivation-guard';
export { SettingsContractCategoriesService } from "./http-services/settings-contract-categories.service";
export { ContractDataService } from './utility-services/contract-data.service';
export { CountriesService } from './utility-services/countries.service';
export { CommonService } from './http-services/common.service';
export { NotificationTemplateService } from './http-services/notification-template.service';
export { DynamicCertificatesService } from './http-services/dynamic-certificates.service';
export { PushNotificationsService } from './utility-services/push.notification.service';
export { MultipleInvoiceService } from './handsontable-services/multiple-invoice.service';
export { PriceBooksService } from './http-services/price-books.service';
export { UserVoipSettingsService } from './http-services/user-voip-settings.service';
export { AddEditCertificateService } from './http-services/add-edit-certificate.service';
export {CustomerJobTemplateService} from "@app/services/http-services/customer-job-template.service";

// export { PipelineStageService } from './handsontable-services/pipeline-stage.service';
export { ProposalTemplatePricingSectionService } from './handsontable-services/proposal-template-pricing-section.service';
export { CsVersionService } from './utility-services/cs-version.service';
export { SessionLogService } from './utility-services/session-log.service';
export { ServicesModule } from './services.module';
