import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {EmailModalComponent} from "@app/shared-modal/email-modal/email-modal.component";

@Injectable()
export class EmailListenerService {

    private _beforeSend$: Subject<any> = new Subject<any>();
    private _afterend$: Subject<any> = new Subject<any>();
    private _send$: Subject<any> = new Subject<any>();
    private _closeModal$: Subject<any> = new Subject<any>();
    constructor() { }

    get beforeSend$() {
        return this._beforeSend$.asObservable();
    }

    get afterSend$() {
        return this._afterend$.asObservable();
    }

    get send$() {
        return this._send$.asObservable();
    }

    get closeModal$() {
        return this._closeModal$.asObservable();
    }

    public send(data: any = null) {
        this._send$.next(data);
    }

    public afterSend(data: any = null) {
        this._afterend$.next(data);
    }

    beforeSend(data:any) {
        this._beforeSend$.next(data);
    }

    closeModal(data: any) {
        this._closeModal$.next(data);
    }
}
