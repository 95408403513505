export interface ChangeStageBlockModelInterface {
    stageId: number;
    stageDisplayName: string;
}

export class ChangeStageBlockModel implements ChangeStageBlockModelInterface {
    stageDisplayName: string = '';
    stageId: number = undefined;

    constructor(data:{} = undefined) {
        if(data) {
            this.stageDisplayName = data['stageDisplayName'] ? data['stageDisplayName'] : '';
            this.stageId = data['stageId'] ? parseInt(data['stageId']) : undefined;
        }
    }
}
