import { EventEmitter } from '@angular/core';
var DragAndDropDirective = /** @class */ (function () {
    function DragAndDropDirective() {
        this.fileDropped = new EventEmitter();
    }
    // Dragover listener
    DragAndDropDirective.prototype.onDragOver = function (evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = true;
    };
    // Dragleave listener
    DragAndDropDirective.prototype.onDragLeave = function (evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
    };
    // Drop listener
    DragAndDropDirective.prototype.ondrop = function (evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
        var files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
    };
    return DragAndDropDirective;
}());
export { DragAndDropDirective };
