import { Component, OnInit } from '@angular/core';
import {Subject} from "rxjs";
import {BsModalRef} from "ngx-bootstrap";

@Component({
  selector: 'app-template-view',
  templateUrl: './template-view.component.html',
})
export class TemplateViewComponent implements OnInit {

  constructor(
      public modalRef: BsModalRef,
  ) { }

    onClose: Subject<any>
    type:string;
    subject:string;
    message: string;
    title: string;

  ngOnInit() {
      this.onClose = new Subject();
  }

}
