var SendJobReportBlockModel = /** @class */ (function () {
    function SendJobReportBlockModel(data) {
        if (data === void 0) { data = undefined; }
        this.fromEmail = '';
        this.message = '';
        this.subject = '';
        this.templateId = '';
        this.templateName = '';
        this.toEmailTag = [];
        this.doAttachJobReport = true;
        if (data) {
            this.templateId = data['templateId'] ? data['templateId'] : '';
            this.toEmailTag = data['toEmailTag'] ? data['toEmailTag'] : [];
            this.fromEmail = data['fromEmail'] ? data['fromEmail'] : '';
            this.subject = data['subject'] ? data['subject'] : '';
            this.templateName = data['templateName'] ? data['templateName'] : '';
            this.message = data['message'] ? data['message'] : '';
            this.doAttachJobReport = data.hasOwnProperty('doAttachJobReport') ? data['doAttachJobReport'] : true;
        }
    }
    return SendJobReportBlockModel;
}());
export { SendJobReportBlockModel };
