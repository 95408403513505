import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { InterfaceService } from "@app/interfaces";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var VehicleMakesService = /** @class */ (function (_super) {
    tslib_1.__extends(VehicleMakesService, _super);
    function VehicleMakesService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.screenType = new BehaviorSubject('');
        _this.screenType$ = _this.screenType.asObservable();
        _this.closeModal = new BehaviorSubject('');
        _this.closeModal$ = _this.closeModal.asObservable();
        _this.saveData = new BehaviorSubject('');
        _this.saveData$ = _this.saveData.asObservable();
        return _this;
    }
    // Add/Edit vehicle make
    VehicleMakesService.prototype.addOrEditVehicleMakes = function (params) {
        return this.http.post(this.getFleetApiUrl("addOrEditVehicleMakes"), params);
    };
    // Check vehicle make name already exists
    VehicleMakesService.prototype.checkMakesName = function (params) {
        return this.http.get(this.getFleetApiUrl("is_makes_exists", params));
    };
    // Delete vehicle make
    VehicleMakesService.prototype.deleteVehicleMake = function (id, params) {
        return this.http.delete(this.getFleetApiUrl(id + "/deleteVehicleMake", params));
    };
    // Delete status
    VehicleMakesService.prototype.isDeleteVehicleMake = function (id) {
        return this.http.get(this.getFleetApiUrl(id + "/is_vehicle_make_deletable"));
    };
    VehicleMakesService.ngInjectableDef = i0.defineInjectable({ factory: function VehicleMakesService_Factory() { return new VehicleMakesService(i0.inject(i1.HttpClient)); }, token: VehicleMakesService, providedIn: "root" });
    return VehicleMakesService;
}(InterfaceService));
export { VehicleMakesService };
