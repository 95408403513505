import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from "rxjs/index";
import { BsModalRef } from 'ngx-bootstrap';
import { VehicleModelService } from '@app/features/system-settings/fleet-management/vehicle-models/services/vehicle-model.service';
var VehicleModelPanelComponent = /** @class */ (function () {
    function VehicleModelPanelComponent(fb, bsModalRef, typeService) {
        this.fb = fb;
        this.bsModalRef = bsModalRef;
        this.typeService = typeService;
        this.isEdit = false;
        this.action = 'save';
        this.showexpiryInput = true;
        this.makeList = [];
        this.vehicleModelFormData = {
            id: '',
            modelName: '',
            makes: ''
        };
        this.uniqueName = false;
        this.saveDisabled = false;
        this.loading = true;
    }
    VehicleModelPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.onClose = new Subject();
        this.typeService.getVehicleMake({ type: this.exist ? 'Edit' : 'Add' }).subscribe(function (res) {
            _this.makeList = res['vehicleMakes'];
        });
        if (this.selectedVehicleMakeId || this.selectedVehicleModalName) {
            this.vehicleModelFormData.makes = this.selectedVehicleMakeId;
            this.vehicleModelFormData.modelName = this.selectedVehicleModalName;
        }
        if (this.data) {
            // Set values for edit screen
            this.isEdit = true;
            this.loading = false;
            this.vehicleModelFormData.id = this.data.id;
            this.vehicleModelFormData.modelName = this.data.modelName;
            this.vehicleModelFormData.makes = this.data.makes.id;
        }
        this.title = this.isEdit == true ? 'Fleet.edit.vehicle.model' : 'Fleet.add.new.model';
        this.vehicleModelForm = this.fb.group({
            'id': [this.vehicleModelFormData.id ? this.vehicleModelFormData.id : ''],
            'modelName': [this.vehicleModelFormData.modelName ? this.vehicleModelFormData.modelName : '', [Validators.required, Validators.minLength(1)]],
            'makes': [this.vehicleModelFormData.makes ? this.vehicleModelFormData.makes : '', Validators.required]
        });
    };
    Object.defineProperty(VehicleModelPanelComponent.prototype, "f", {
        // Getting control values for validation check
        get: function () {
            return this.vehicleModelForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    VehicleModelPanelComponent.prototype.save = function () {
        var _this = this;
        this.saveDisabled = true;
        var formData = this.vehicleModelForm.getRawValue();
        this.typeService.addOrEditVehicleModel(formData)
            .subscribe(function (resp) {
            if (resp.hasOwnProperty('id')) {
                _this.uniqueName = false;
                var addVehicleData = {
                    makeId: formData['makes'],
                    modalId: resp['id'],
                    modalName: formData['modelName'],
                };
                _this.onClose.next(addVehicleData);
                _this.bsModalRef.hide();
            }
            else {
                _this.uniqueName = true;
            }
        }, function (error) {
            console.log('err', error);
        });
    };
    // Check vehicle type name exists
    VehicleModelPanelComponent.prototype.checkUniqueVehicleType = function (value) {
        var _this = this;
        var data = {
            modelName: value,
        };
        if (this.isEdit) {
            data['id'] = this.data.id;
        }
        var checkName = false;
        if (this.vehicleModelForm.get('modelName').value === value) {
            checkName = true;
        }
        else {
            checkName = false;
        }
        if (checkName) {
            this.typeService.checkVehicleModelName(data).subscribe(function (resp) {
                _this.uniqueName = resp;
            });
        }
    };
    VehicleModelPanelComponent.prototype.closePanel = function () {
        this.bsModalRef.hide();
        this.onClose.next('close');
    };
    return VehicleModelPanelComponent;
}());
export { VehicleModelPanelComponent };
