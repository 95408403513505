import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { InterfaceService } from "@app/interfaces";

@Injectable({
  providedIn: 'root'
})

export class VehicleMakesService extends InterfaceService {
  private screenType = new BehaviorSubject('');
  screenType$ = this.screenType.asObservable()

  private closeModal = new BehaviorSubject('');
  closeModal$ = this.closeModal.asObservable()

  private saveData = new BehaviorSubject('');
  saveData$ = this.saveData.asObservable()

  constructor(private http: HttpClient) {
      super();
  }

  // Add/Edit vehicle make
  addOrEditVehicleMakes(params?: any): Observable<any> {
      return this.http.post(this.getFleetApiUrl(`addOrEditVehicleMakes`), params);
  }

  // Check vehicle make name already exists
  checkMakesName(params?: Object): Observable<any> {
      return this.http.get(this.getFleetApiUrl(`is_makes_exists`, params));
  }

  // Delete vehicle make
  deleteVehicleMake(id: number, params?: any): Observable<any> {
      return this.http.delete(this.getFleetApiUrl(`${id}/deleteVehicleMake`,params));
  }

  // Delete status
  isDeleteVehicleMake(id): Observable<any> {
    return this.http.get(this.getFleetApiUrl(`${id}/is_vehicle_make_deletable`));
  }


}