<div class="modal-header">
    <h4 class="modal-title pull-left">{{blockData['title']}}</h4>
</div>
<div class="modal-body">
    <div class="loading-wrap" *ngIf="isLoading">
        <span class="circle1"></span>
        <span class="circle2"></span>
        <span class="circle3"></span>
    </div>
    <form class="no-form-style" *ngIf="form" [formGroup]="form" novalidate>
        <div class="cs-d-flex flex-column">
            <div class="cs-helper-section bg-white cs-border cs-border--bottom">
                <div class="cs-help-text-color p--15">{{blockData['description']}}</div>
            </div>
            <div class="cs-flex-1">
                <div class="cs-block-widget--form cs-d-flex flex-column">
                    <div class="cs-block-widget--form-control">
                        <label class="control-label required_field">Assign to:</label>
                        <div>
                            <select class="full-width-select2 cs-select2-wfe default-input-height"
                                    (onChange)="selectedUsers($event)"
                                    csSelect2
                                    [dropdownvalue]="selectedUserInputs['assignTo']"
                                    [select2Config]="{}">
                                <option value="" translate>Please.Choose</option>
                                <option *ngFor="let item of salesPersonUsers" [value]="item.id">{{item.text}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </form>
</div>
<div class="modal-footer">
    <ng-container *ngIf="!isLoading else footerSection">
        <button *ngIf="showDeleteBtn" class="btn btn-danger btn-medium float-left" (click)="remove()" type="button">Delete </button>
        <button class="btn btn-link" (click)="closeModal()">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="!form.valid || isFormSubmitted" (click)="save()" >
            <span *ngIf="!isFormSubmitted" translate>Save</span>
            <span *ngIf="isFormSubmitted" translate>Saving</span>
        </button>
    </ng-container>
</div>

<ng-template #footerSection>
    <button class="btn btn-link" (click)="closeModal()">Cancel</button>
    <button type="submit" class="btn btn-primary" [disabled]="true">
        <span translate>Save</span>
    </button>
</ng-template>


