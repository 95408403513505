import {Component, ElementRef, Input, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {mxgraph, mxgraphFactory} from "mxgraph-factory";
import {CsFullScreenModalComponent} from "@app/directives";
import { MxGraphService } from "../services/mx-graph.service";

const { mxGraph } = mxgraphFactory({
    mxLoadResources: false,
    mxLoadStylesheets: true,
});

@Component({
    selector: 'app-diagram-history',
    templateUrl: './diagram-history.component.html',
    styles: []
})
export class DiagramHistoryComponent implements OnInit, AfterViewInit {

    @Input() data:any;
    @Input() activeMenu: string = 'history';
    @Input() mainObjectsFields:any;
    @Input() csFullModal: CsFullScreenModalComponent;
    @Input() tags:any[] = [];
    @Input() userRoles:any[] = [];
    @Input() isMarketPlaceView:boolean=false;
    @Input() viewMode:boolean=false;
    @Input() usersList:any[] = [];
    @Input() scheduleActivityTypes:any[] = [];
    @Input() workflowType: string = 'Custom'
    @Input() whitelist: any = {};
    @Input() singleDagHistory:boolean = false;

    sourceXml:string=null;
    mxGraph: mxgraph.mxGraph;
    activeDagRunTasksItems:any = {};
    isEmpty:boolean = true;

    @ViewChild('mx_container') container: ElementRef<HTMLElement>;
    @ViewChild('outlineContainer') outlineContainer: ElementRef<HTMLElement>;

    constructor(
        private diagramService: MxGraphService,
    ) { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.setContainerHeight();
    }

    setActiveDagRun(item:any) {
        this.diagramService.destroyMxGraph( () => {
            this.isEmpty = false;
            this.activeDagRunTasksItems = item;
            this.diagramService.initialize(this.container, {
                xmlSource: !item['xmlsource'] ? this.data.xmlSource : item['xmlsource'],
                isScheduler: this.data.scheduler,
                outlineContainer: this.outlineContainer,
                mainObjectsFields: this.mainObjectsFields,
                viewMode: this.viewMode,
                isMarketPlaceView: this.isMarketPlaceView,
                tags: this.tags,
                userRoles: this.userRoles,
                scheduleActivityTypes: this.scheduleActivityTypes,
                usersList: this.usersList,
                workflowType: this.workflowType,
                doAutoHeightAdjustment: true
            });
            this.mxGraph = this.diagramService.getGraph();
            this.diagramService.render(item.dagSource['blockObjects']);
            setTimeout(()=>{
                this.diagramService.setHightlightCells(item);
            },10);
        });
    }

    setContainerHeight() {
        setTimeout(() => {
            const height = window.innerHeight - (this.container.nativeElement.offsetTop + 10);
            this.container.nativeElement.style.minHeight= height + 'px';
        },100);
    }

}
