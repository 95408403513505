<div class="modal-header">
    <h4 class="modal-title pull-left">{{notificationBlockData['title']}}</h4>
</div>
<div class="modal-body">
    <div class="loading-wrap" *ngIf="isLoading">
        <span class="circle1"></span>
        <span class="circle2"></span>
        <span class="circle3"></span>
    </div>
    <form class="no-form-style" *ngIf="form" [formGroup]="form" novalidate>
        <div class="cs-d-flex flex-column">
            <div class="cs-helper-section bg-white cs-border cs-border--bottom">
                <div class="cs-help-text-color p--15">{{notificationBlockData['description']}}</div>
            </div>
            <div class="cs-flex-1">
                <div class="cs-block-widget--form cs-d-flex flex-column">
                    <div class="cs-block-widget--form-control">
                        <label class="control-label required_field mr-auto">{{notificationBlockData['inputLabelText']}}:</label>
                        <div>
                            <input
                                (selected)="selectedRoles($event)"
                                id="user_roles"
                                csSelect2
                                [select2Config]="select2RolesInputConfig"
                                [dropdownvalue]="inputModel.roles"
                                class="full-width-select2 cs-select2-wfe"/>
                        </div>
                    </div>
                    <div class="cs-block-widget--form-control">
                        <label class="control-label required_field">Message:</label>
                        <div>
                            <div class="item clearfix">
                                <quill-editor
                                    formControlName="message"
                                    [modules]="editorConfig"
                                    (onContentChanged)="onChangeEditor($event)"
                                    placeholder="To use tags, type '[' and choose from the list of tags"> </quill-editor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer" *ngIf="form">
    <ng-container *ngIf="!isLoading else footerSection">
        <button *ngIf="showDeleteBtn" class="btn btn-danger btn-medium float-left" (click)="remove()" type="button">Delete </button>
        <button class="btn btn-link" (click)="closeModal()">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="!form.valid || isFormSubmitted" (click)="save()">
            <span *ngIf="!isFormSubmitted" translate>Save</span>
            <span *ngIf="isFormSubmitted" translate>Saving</span>
        </button>
    </ng-container>
</div>

<ng-template #footerSection>
    <button class="btn btn-link" [disabled]="true">Cancel</button>
    <button type="submit" class="btn btn-primary" [disabled]="true">
        <span translate>Save</span>
    </button>
</ng-template>
