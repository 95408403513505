<div class="modal-header">
    <h4 class="modal-title pull-left">{{scheduledActivityBlockData['title']}}</h4>
</div>
<div class="modal-body">
    <div class="loading-wrap" *ngIf="isLoading">
        <span class="circle1"></span>
        <span class="circle2"></span>
        <span class="circle3"></span>
    </div>
    <form class="no-form-style" *ngIf="form" [formGroup]="form" novalidate>
        <div class="cs-d-flex flex-column">
            <div class="cs-helper-section bg-white cs-border cs-border--bottom">
                <div class="cs-help-text-color p--15">{{scheduledActivityBlockData['description']}}</div>
            </div>
            <div class="cs-flex-1">
                <div class="cs-block-widget--form cs-d-flex flex-column">
                    <div class="cs-block-widget--form-control">
                        <label class="control-label required_field">Description:</label>
                        <input formControlName="description" type="text" class="w-100" />
                    </div>
                    <div class="cs-block-widget--form-control">
                        <label class="control-label">Note:</label>
                        <textarea formControlName="notes" type="text" class="w-100" rows="5"></textarea>
                    </div>
                    <div class="cs-block-widget--form-control cs-trigger">
                        <label class="control-label">Activity type:</label>
                        <div>
                            <div class="btn-group cs-schedule-weekly" btnRadioGroup formControlName="activityType">
                                <label *ngFor="let type of activityTypes"
                                       class="btn btn-primary" [btnRadio]="type.id"
                                       tabindex="0" role="button">
                                    <i class="{{iconClassNames[type?.name]}}" aria-hidden="true"></i> {{type?.name}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <hr style="float: none;" class="m-b--0">
                    <div class="cs-block-widget--form-control">
                        <label class="control-label required_field">Due date:</label>
                        <div class="cs-d-flex align-items-center">
                            <div class="control-label">Current date</div>
                            <div class="ml-10">+</div>
                            <div class="ml-10">
                                <input type="text" class="control-input" style="width: 50px; margin:0" appDigitDecimaNumber maxlength="2" formControlName="scheduleInterval"/>
                            </div>
                            <div class="ml-10">
                                <select class="full-width-select" style="width: 80px; margin: 0" formControlName="unit">
                                    <option *ngFor="let unit of units" value="{{unit}}">{{form.get('scheduleInterval').value == 1 ? unit.slice(0, -1) : unit}}</option>
                                </select>
                            </div>
                            <div class="ml-10">at</div>
                            <div class="ml-10">
                                <cs-timepicker formControlName="timeModel" [meridian]="false" (valueChanged)="onChangeTime($event)" class=""></cs-timepicker>
                            </div>
                        </div>
                    </div>
                    <hr style="float: none;" class="m-b--0">
                    <div class="cs-block-widget--form-control">
                        <label class="control-label">Assign to:</label>
                        <div>
                            <input
                                id="assign-to-input"
                                csSelect2
                                (onChange)="selectedAssignee($event)"
                                [dropdownvalue]="inputModel.assigneeIds"
                                [select2Config]="select2AssigneeConfig"
                                class="full-width-select2 cs-select2-wfe"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <ng-container *ngIf="!isLoading && form else footerSection">
        <button *ngIf="showDeleteBtn" class="btn btn-danger btn-medium float-left" (click)="remove()" type="button">Delete </button>
        <button class="btn btn-link" (click)="closeModal()">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="!form.valid || isFormSubmitted" (click)="save()">
            <span *ngIf="!isFormSubmitted" translate>Save</span>
            <span *ngIf="isFormSubmitted" translate>Saving</span>
        </button>
    </ng-container>
</div>

<ng-template #footerSection>
    <button class="btn btn-link" [disabled]="true">Cancel</button>
    <button type="submit" class="btn btn-primary" [disabled]="true">
        <span translate>Save</span>
    </button>
</ng-template>
