import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Subject } from "rxjs";
import { BsModalRef } from "ngx-bootstrap";
import { BlockModel } from "@app/workflow-common/services/DagSourceModel";
var AddTaskSidePanelComponent = /** @class */ (function () {
    function AddTaskSidePanelComponent(modalRef, confirmationBoxHelper, ajsScope) {
        this.modalRef = modalRef;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.activeBlockName = '';
        this.activeBlockObject = undefined;
        this.isEdit = false;
        this.objectName = '';
        this.stageName = '';
        this.blocksList = [];
        this.blockId = '';
        this.parentId = '';
        this.deleteMessage = 'The automation must be disabled before deleting this action. Do you want to deactivate?';
    }
    AddTaskSidePanelComponent.prototype.ngOnInit = function () {
        this.onClose = new Subject();
        this.blocksList = this.dagSourceModel.getBlocksSource().getBlockList(this.workflowType, this.objectName);
        var activeBlock = this.dagSourceModel.getBlockById(this.blockId);
        if (activeBlock) {
            this.activeBlockName = activeBlock['name'];
            this.resolveActiveBlockObject(activeBlock);
            this.isEdit = true;
        }
        else if (this.actionType === 'on_stage_change') {
            var stageChangeBlock = this.dagSourceModel.getBlocksSource().getBlockByKey('actionBlocks_changeStageBlock');
            this.activeBlockName = stageChangeBlock['name'];
            this.resolveActiveBlockObject(stageChangeBlock);
        }
    };
    AddTaskSidePanelComponent.prototype.onCloseModal = function () {
        this.modalRef.hide();
    };
    AddTaskSidePanelComponent.prototype.closeWidgetModal = function (data) {
        if (data['action'] == 'close') {
            if (this.isEdit) {
                this.modalRef.hide();
            }
            else {
                this.activeBlockObject = undefined;
                this.activeBlockName = '';
            }
        }
        else if (data['action'] == 'close_all') {
            this.modalRef.hide();
        }
        else if (data['action'] == 'save_and_close') {
            var blockData = data['data'];
            this.dagSourceModel.addTask(blockData, this.parentId);
            this.onClose.next(blockData);
        }
        else if (data['action'] == 'remove_block') {
            this.onClose.next(data);
        }
    };
    AddTaskSidePanelComponent.prototype.showBlockPanel = function (blockData) {
        // not allow multiple clicks
        if (this.activeBlockName == blockData['name']) {
            return;
        }
        this.activeBlockName = blockData['name'];
        this.resolveActiveBlockObject(blockData);
    };
    AddTaskSidePanelComponent.prototype.resolveActiveBlockObject = function (blockData) {
        var cloneBlockData = tslib_1.__assign({}, blockData);
        if (!blockData.parentId) {
            blockData.parentId = this.parentId;
        }
        this.activeBlockObject = new BlockModel(cloneBlockData);
    };
    return AddTaskSidePanelComponent;
}());
export { AddTaskSidePanelComponent };
