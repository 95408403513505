var NotificationBlockModel = /** @class */ (function () {
    function NotificationBlockModel(data) {
        if (data === void 0) { data = undefined; }
        this.assignTo = '';
        this.assignToDisplay = '';
        this.message = '';
        this.textMessage = '';
        this.roles = undefined;
        this.rolesDisplay = undefined;
        if (data) {
            this.assignTo = data['assignTo'] ? data['assignTo'] : '';
            this.assignToDisplay = data['assignToDisplay'] ? data['assignToDisplay'] : '';
            this.message = data['message'] ? data['message'] : '';
            this.textMessage = data['textMessage'] ? data['textMessage'] : '';
            this.roles = data['roles'] ? data['roles'] : undefined;
            this.rolesDisplay = data['rolesDisplay'] ? data['rolesDisplay'] : undefined;
            this.messageDelta = data['messageDelta'] ? data['messageDelta'] : undefined;
        }
    }
    return NotificationBlockModel;
}());
export { NotificationBlockModel };
