import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UploadDocumentService } from '@app/features/ai-document-parsing/services/upload-document.service';
import { CsFullScreenModalComponent } from '@app/directives';
import { TranslateService } from '@ngx-translate/core';
import { CsToastBoxService } from '@app/services';

import {
    GridApi,
    GridOptions,
    IServerSideGetRowsParams
} from 'ag-grid-community';

import 'ag-grid-enterprise';

import {
    CsAgGroupCellRendererComponent
} from "@app/shared/cs-components/cs-ag-grid/cs-ag-grid-components/cs-ag-group-cell-renderer/cs-ag-group-cell-renderer.component";

import { CapitalizePipe } from '@app/pipes';
import { ICsListViewLayoutParams } from '@app/shared/cs-components/cs-components.interface';
import { CustomTooltipRendererComponent } from '../custom-tooltip-renderer/custom-tooltip-renderer.component';
import { CcCommonService } from '@app/features/customers/customer-contracts/cc-common.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-list-parsed-data',
  templateUrl: './list-parsed-data.component.html',
  styleUrls: ['./list-parsed-data.component.css']
})
export class ListParsedDataComponent implements OnInit {

    @ViewChild(CsFullScreenModalComponent) csFullModal: CsFullScreenModalComponent;

    dataTable = new Array() // This variable is used to store data which we want to show in table grid
   
    pageNumber = 0 //This variable is used for pagination

    records = 10; // This variable is used to show number of records

    pages = 0;

    list = new Array();

    isConfirmDisabled = true //variable to make confirm link enable and disable according to response
    searchTerm: any;

    title: string = "Confirm supplier invoice"; // Title of Detail page

    selectedSupplier: any; // This will be used to pass the supplier id to details page

    openDisplayPage: boolean = false;

    // For Display Page
    forDisplaySupplierId: any;
    forDisplaySupplierName: any;
    forDisplayInvoiceDate: any;
    forDisplayJobNumber: any;
    forDisplayInvoiceNumber: any;
    forDisplayNominalCode: any;
    forDisplaySalesTax: any;
    forDisplayPoNumber: any;
    forDisplaySupplier: any;
    actionType: any;

    // For pagination
    currentShowingPageNumber: any;
    process: string;
    totalEntriesCount: any;
    pageStart: number;
    pageEnd: any;
    pageLimit: number = 10;

    // For sorting
    jobSort = "asc";
    statusSort = "asc";
    taxItems: any;
    listCodes: any;
    suppliersList=[];

    // For Ag Grid
    private gridApi: GridApi;
    public gridOptions: GridOptions;
    totalRecords: any;
    listViewParams: ICsListViewLayoutParams;
    searchItem: any;
    currentPage = 1;
    pageSize = 5;
    firstTimeSearch: boolean = false;
    regionalDateFormat: any;


    /*
        Note: 
        For Processing and Error status code: Edit link will be disable
        And for only Ready status Confirm link is enable

        For both Edit and Confirm links: Details page will open
        For Delete link ask for confirmation
    */
    constructor(
        private integrationService: UploadDocumentService,
        public translate: TranslateService,
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope,
        private toastBox: CsToastBoxService,
        private renderer: Renderer2,
        private capitalizePipe: CapitalizePipe,
        private ccCommonService: CcCommonService,
        private datePipe: DatePipe
    ) { }

    ngOnInit() {
        var format = this.ccCommonService.getRegionalDateFormat();
        format = format.replace(/D/gi, 'd');
        this.regionalDateFormat = format.replace(/Y/gi, 'y');

        
        this.integrationService.getSuppliers().subscribe((resp) => {
            this.suppliersList = resp;
        });
        this.prepareListViewParams();
        this.setGridOptions();
    }

    prepareListViewParams() {
        this.listViewParams = {
            pageSize: 5,
            titleText: this.translate.instant('Confirm.your.pending.supplier.invoices.receipts'),
            gridType: 'ag-grid',
            gridApi: this.gridApi,
            masterGridDataSource: this.getMasterGridDataSource()
        };
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
        this.listViewParams.initGrid(params.api);
    }

    getMasterGridDataSource = () => {
        const _thisRef = this;
        return {
            getRows: (params: IServerSideGetRowsParams) => {
            
                setTimeout(() => {
                    const requestParams = {
                        page: this.currentPage,
                        limit: this.pageSize
                    };

                    
                    if (requestParams.page == null ||
                        typeof requestParams.page == 'undefined' ||
                        requestParams.limit == null ||
                        typeof requestParams.limit == 'undefined'
                    ) {
                        requestParams.page = 1;
                        requestParams.limit = 5;
                    }

                    let sortModal = params.request.sortModel[0] ? params.request.sortModel[0] : null;

                    this.integrationService.getListAutomatedInvoices(
                        requestParams,
                        sortModal,
                        this.searchTerm
                    ).subscribe(resp => {
                        this.dataTable = resp['parsersDetails'];
                        this.forDisplaySalesTax = resp['taxItemList'];
                        this.forDisplayNominalCode = resp['nominalCodeList'];

                        this.totalRecords = resp.count;

                        const isPartialRecordsInPage = this.dataTable.length < requestParams.limit;
                        
                        const endPageRecordCount = isPartialRecordsInPage ? this.dataTable.length : requestParams.limit;

                        params.successCallback(resp.parsersDetails, endPageRecordCount);
                        this.showOrHideGridOverlay(this.totalRecords); // This will be used to show No data available message.
                    });
                }, 500);
            }
        };
    }

    showOrHideGridOverlay(recordCount: Number) {
        if (recordCount) {
            this.gridApi.hideOverlay();
        } else {
            this.gridApi.showNoRowsOverlay();
        }
    }

    onLayoutReady(params) {
        this.updateListViewParams(params);
    }

    updateListViewParams(params) {
        this.currentPage = params.currentPage;
        this.pageSize = params.pageSize;
        this.listViewParams = params;
    }

    search(event) {
        if (this.firstTimeSearch == false) {
            this.firstTimeSearch = true;
            this.currentPage = 1;
        }
        this.searchTerm = event.target.value;
        // Search by Purchase Order, Invoice Number, Supplier Name and Status
        this.gridApi.purgeServerSideCache();
    }

    displayList(event) {
        if (this.searchTerm == null || this.searchTerm.length == 0) {
            this.searchTerm = null;
            this.gridApi.purgeServerSideCache();
            this.firstTimeSearch = false;
        }
    }

    configure(id: any, type: any) {
        let index = this.dataTable.findIndex((value: any) => value.id == parseInt(id));
        
        if (index >= 0) {
            let item = this.dataTable[index];
            this.actionType = type;
            this.openDisplayPage = true;
            this.forDisplaySupplier = item;
            this.csFullModal.open();
        }
       
    }

    onRowDelete(id) {
        this.confirmationBoxHelper
            .getConfirmation(this.translate.instant('This row has been saved, are you sure you want to delete it?'), this.ajsScope)
            .then(() => {
                this.integrationService.deleteDocumentAIParser(id).subscribe(resp => {
                    this.toastBox.show(this.translate.instant('Record.deleted.successfully'), 2000);
                }, error => {
                    this.toastBox.show("Error while deleting record", 2000);
                });
                this.gridApi.purgeServerSideCache();
            });
    }

    fillCreatedBySection(id: any) {
        const _thisRef = this;
        let index = this.dataTable.findIndex((value: any) => value.id == parseInt(id));

        if (index >= 0) {
            let item = this.dataTable[index];
            let createdBy = item.createdBy;
            let createdByName = item.createdName

            const elementNode: HTMLElement = _thisRef.renderer.createElement('span');
            const imgElement: HTMLImageElement = _thisRef.renderer.createElement('img');
            _thisRef.renderer.setAttribute(imgElement, 'src', createdBy);
            _thisRef.renderer.addClass(imgElement, 'profileImg');

            const createdByNameElementNode: HTMLElement = _thisRef.renderer.createElement('span');
            const txtNode = _thisRef.renderer.createText(this.capitalizePipe.transform(createdByName));
            _thisRef.renderer.appendChild(createdByNameElementNode, txtNode);

            _thisRef.renderer.appendChild(elementNode, imgElement);
            _thisRef.renderer.appendChild(elementNode, createdByNameElementNode);

            return elementNode;
        } else {
            return '-';
        }
    }

    createActionSection(params) {
        const _thisRef = this;
        let index = this.dataTable.findIndex((value: any) => value.id == parseInt(params.value));

        if (index >= 0) {
            let item = this.dataTable[index];

            const elementNode: HTMLElement = _thisRef.renderer.createElement('span');

            // Confirm
            const confirmElement: HTMLAnchorElement = _thisRef.renderer.createElement('a');
            const confirmTextNode = _thisRef.renderer.createText(this.capitalizePipe.transform(this.translate.instant('Confirm')));
            _thisRef.renderer.appendChild(confirmElement, confirmTextNode);

            if (item) {
                if (item.status == 'Ready') {
                    _thisRef.renderer.addClass(
                        confirmElement,
                        'actionLinks'
                    );
                    _thisRef.renderer.listen(confirmElement, 'click', () => {
                        this.configure(params.value, 'confirm')
                    });
                } else {
                    _thisRef.renderer.setAttribute(confirmElement, 'disabled', 'disabled');
                    _thisRef.renderer.addClass(
                        confirmElement,
                        'disabled-link'
                    );
                }


                // Edit
                const editElement: HTMLAnchorElement = _thisRef.renderer.createElement('a');
                const editTextNode = _thisRef.renderer.createText(this.capitalizePipe.transform(this.translate.instant('Edit')));
                _thisRef.renderer.appendChild(editElement, editTextNode);

                if (item.status == 'Information Missing' || item.status == 'Ready') {
                    _thisRef.renderer.addClass(
                        editElement,
                        'actionLinks'
                    );
                    _thisRef.renderer.listen(editElement, 'click', () => {
                        this.configure(params.value, 'edit')
                    });
                } else {
                    _thisRef.renderer.setAttribute(confirmElement, 'disabled', 'disabled');
                    _thisRef.renderer.addClass(
                        editElement,
                        'disabled-link'
                    );
                }
                        
                // Delete
                const deleteElement: HTMLAnchorElement = _thisRef.renderer.createElement('a');
                const deleteTextNode = _thisRef.renderer.createText(this.capitalizePipe.transform(this.translate.instant('Delete')));
                _thisRef.renderer.appendChild(deleteElement, deleteTextNode);
                _thisRef.renderer.addClass(
                    deleteElement,
                    'actionLinks'
                );
                _thisRef.renderer.listen(deleteElement, 'click', () => {
                    this.onRowDelete(params.value);
                });

                _thisRef.renderer.appendChild(elementNode, confirmElement);
                _thisRef.renderer.appendChild(elementNode, editElement);
                _thisRef.renderer.appendChild(elementNode, deleteElement);

                _thisRef.renderer.addClass(elementNode, 'actionSection');

                return elementNode;
            }
        }

    }

    setGridOptions() {
        const _thisRef = this;
        this.gridOptions = {
            frameworkComponents: {
                agGroupCellRenderer: CsAgGroupCellRendererComponent,
            },
            defaultColDef: {
                sortable: true,
                unSortIcon: true,
                flex: 1,
                suppressMenu: true
            },
            columnDefs: [
                {
                    field: 'status',
                    headerName: this.translate.instant('Pat.status'),
                    sortable: true,
                    sortingOrder: ['asc', 'desc'], 
                    cellRendererFramework: CustomTooltipRendererComponent
                },
                {
                    field: 'supplierName',
                    headerName: this.translate.instant('Supplier'),
                    sortable: true,
                    sortingOrder: ['asc', 'desc'], 
                },
                {
                    field: 'jobId',
                    headerName: this.translate.instant('Job.number'),
                    sortable: true,
                    sortingOrder: ['asc', 'desc'],
                    cellRenderer: (params) => {
                        if (params.value != "" && params.value != null) {
                            return params.value;
                        } else {
                            return '-';
                        }
                    }
                },
                {
                    field: 'createdBy',
                    headerName: this.translate.instant('Created.By'),
                    sortable: true,
                    sortingOrder: ['asc', 'desc'], 
                    cellRenderer: (params) => {
                        const elementNode = this.fillCreatedBySection(params.data.id);
                        return elementNode;
                    }  
                },
                {
                    field: 'invoiceDate',
                    headerName: this.translate.instant('Date'),
                    sortable: true,
                    sortingOrder: ['asc', 'desc'], 
                    cellRenderer: (params) => {
                        if (params.data.invoiceDateObject != "" && params.data.invoiceDateObject != null) {
                            return new DatePipe('en-US').transform(params.data.invoiceDateObject.date, this.regionalDateFormat);
                        } else {
                            return '-';
                        }
                    }
                },
                {
                    field: 'nominalCodeDescription',
                    headerName: this.translate.instant('Nominal.Code'),
                    sortable: false,
                    cellRenderer: (params) => {
                        if (params.value != "" && params.value != null) {
                            return params.value;
                        } else {
                            return '-';
                        }
                    }
                },
                {
                    field: 'amount',
                    headerName: this.translate.instant('Amount'),
                    sortable: false,
                    cellRenderer: (params) => {
                        if (params.value != "" && params.value != null) {
                            return params.data.currencySymbols + params.value;
                        } else {
                            return '-';
                        }
                    }
                },
                {
                    field: 'id',
                    headerName: this.translate.instant('Actions'),
                    sortable: false,
                    cellRenderer: (params) => {
                        const elementNode = this.createActionSection(params);
                        return elementNode;
                    }
                },
            ],
            rowModelType: 'serverSide',
            pagination: true,
            paginationPageSize: _thisRef.listViewParams.pageSize || 5,
            suppressPaginationPanel: true,
            suppressContextMenu: true,
            suppressRowHoverHighlight: true,
            animateRows: true,
            suppressCellSelection: true,
            masterDetail: true,
            suppressMovableColumns: true,
            suppressHorizontalScroll: true,
            localeText: {
                noRowsToShow: this.translate.instant('No.data.found')
            }
        };
    }
    
    onShownFSModal() {
        $('body').addClass('modal-open');
    }
    
    onHiddenFSModal() {
        $('body').removeClass('modal-open');
        this.openDisplayPage = false;
        this.gridApi.purgeServerSideCache();
    }

    closeFSModal() {
        this.csFullModal.close();
    }
    
}
