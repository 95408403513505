import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DagSourceModel} from "@app/workflow-common/services/DagSourceModel";
import {SmsBlockModel} from "@app/workflow-common/services/models/SmsBlockModel";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";
import {from, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {CommonDataSource} from "@app/workflow-common/services/CommonDataSource";

@Component({
  selector: 'app-sms-block-widget',
  templateUrl: './sms-block-widget.component.html',
  styles: []
})
export class SmsBlockWidgetComponent implements OnInit {

    @Input() workflowType: string;
    @Input() objectName: string;
    @Input() blockObject;
    @Input() dagSourceModel: DagSourceModel;
    @Output() close:EventEmitter<{}> = new EventEmitter<{}>();

    form: FormGroup;

    smsBlockData:{} = {};
    isLoading: boolean = true;
    isFormSubmitted:boolean = false;
    usersList: any[] = [];

    smsTagsList: any[] = [];
    smsUsersList: any[] = [];
    smsCustomList: any[] = [];

    select2ConfigToSms:{} = {};
    selectedMobileInputs:any[] = [];

    inputModel: SmsBlockModel = undefined;
    showDeleteBtn: boolean = false;
    constructor(
        private formBuilder: FormBuilder,
        private workflowService: WorkflowhttpService,
    ) { }

    async ngOnInit() {
        this.smsBlockData = this.dagSourceModel.getBlocksSource().getBlockByKey(this.blockObject.key);
        this.smsTagsList = this.dagSourceModel.getSmsTagsByType(this.workflowType,this.objectName);

        const blockObject = {...this.blockObject};
        const modelData = blockObject['model'] ? blockObject['model'] : undefined;
        if(modelData) {
            this.showDeleteBtn=true;
        }
        this.inputModel = new SmsBlockModel(modelData);

        this.makeMobileAddressDropdownData();

        await this.getData();
        this.isLoading = false;
        this.usersList.forEach( (user) => {
            this.smsUsersList.push({
                id: parseInt(user.id),
                text: user.username,
                name: 'USERS'
            })
        });
        this.setToEmailSelect2Config();

        this.form = this.formBuilder.group({
            templateId: new FormControl(this.inputModel.templateId, [Validators.required]),
            toSmsTag: new FormControl(this.selectedMobileInputs, [Validators.required]),
            message: new FormControl(this.inputModel.message),
            title: new FormControl(this.inputModel.title),
        });
    }

    getData() {
        return new Promise( (resolve, reject) => {
            this.workflowService.getCommunicationTemplateConfig()
            .toPromise()
            .then( (resp) => {
                let data = resp.hasOwnProperty('data') ? resp['data'] : null;
                if(data) {
                    this.usersList = data.users;
                    resolve(true);
                }
            }, (err) => {
                reject(err);
                console.error(err);
            });
        });
    }

    setToEmailSelect2Config() {
        const select2Default = {
            width: '100%',
            tags: true,
            createSearchChoice: (term: any, data: any) => {
                let exists: boolean = false;
                data.forEach((idText) => {
                    if (idText.text == term)
                        exists = true;
                });
                return term.indexOf(' ') >= 0 || exists  ? null : {
                    id: term + " (Create New)",
                    text: term + " (Create New)"
                };
            },
            formatSelection: (item: any) => {
                return item.text.replace(" (Create New)", '');
            },
            formatResult: (item: any) => {
                return item.text;
            }
        };
        this.select2ConfigToSms = {...select2Default};
        this.select2ConfigToSms['data'] = this.makeGroupSmsList();
        if(this.smsBlockData['groupId'] != 'internalCommunications') {
            this.select2ConfigToSms['formatNoMatches'] = () => {
                return 'Add a recipient\'s mobile number by entering...';
            }
        }
    }

    selectedMobileAddress(event:any = null) {
        this.form.get('toSmsTag').setValue( event);
    }

    setTemplateDetails(event:any, type: string) {
        this.form.get('templateId').setValue(event['templateId']);
        this.form.get('message').setValue(event['message']);
        this.form.get('title').setValue(event['templateName']);
        this.inputModel.message = event['message'];
        this.inputModel.title = event['templateName'];
    }

    makeGroupSmsList() {
        const allSmsTag = [];
        const newItems: any[] = [];
        if(this.smsBlockData['groupId'] == 'internalCommunications') {
            newItems.push(this.smsUsersList);
        }
        else if(this.smsBlockData['groupId'] == 'externalCommunications'){
            newItems.push(this.smsTagsList);
        }

        if(this.smsCustomList.length) {
            newItems.push(this.smsCustomList);
        }
        const source = from(newItems);
        const tags = source.pipe(
            map(groupItems => {
                const _item: any = {text: '', children: []};
                let cnt: number = 0;
                groupItems.forEach( group => {
                    if (cnt === 0) {
                        _item.text = group.name;
                    }
                    _item.children.push({
                        id: group.id,
                        text: group.text
                    });
                    cnt++;
                });
                return _item;
            })
        );
        const subscribe:Subscription = tags.subscribe(val => allSmsTag.push(val));
        subscribe.unsubscribe();
        return allSmsTag;
    }

    makeMobileAddressDropdownData() {
        const inputModel: SmsBlockModel = this.inputModel;
        if(inputModel.toSmsTag && inputModel.toSmsTag.length > 0) {
            inputModel.toSmsTag.forEach( (item) => {
                if(item['type'] == 'normal') {
                    this.smsCustomList.push({
                        id: item['value'] + ' (Create New)',
                        text: item['value'],
                        name: 'CUSTOM'
                    });
                    this.selectedMobileInputs.push(item['value']+ ' (Create New)');
                }
                else {
                    this.selectedMobileInputs.push(item['value']);
                }
            });
        }
    }

    save() {
        this.isFormSubmitted=true;
        const {templateId, message, title, toSmsTag} = this.form.value;
        const blockObject = {...this.blockObject};
        this.inputModel.templateId = templateId;
        this.inputModel.message = message;
        this.inputModel.title = title;
        this.inputModel.toSmsTag = CommonDataSource.formattingSmsAddress(toSmsTag, this.smsTagsList, this.usersList);

        blockObject['model'] = this.inputModel;
        this.close.emit({
            action: 'save_and_close',
            data: blockObject
        });
    }

    closeModal() {
        this.close.emit({
            action: 'close',
            data: null
        });
    }

    remove() {
        const blockObject = {...this.blockObject};
        blockObject.model = this.inputModel;
        this.close.emit({
            action: 'remove_block',
            data: blockObject
        });
    }
}
