import { AfterViewInit, ElementRef, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { conditions } from "@app/shared/cs-components/filter-configurator-1/conditions-metadata";
import { DatePipe } from "@app/pipes";
var FilterComponent = /** @class */ (function () {
    function FilterComponent(el, datePipe, settings) {
        this.el = el;
        this.datePipe = datePipe;
        this.settings = settings;
        this.booleanOptionValues = [
            { key: true, label: 'True' },
            { key: false, label: 'False' },
        ];
        this.isPopupOpened = false;
        this.isMoreFilterPopupOpened = false;
        this.subscriptions = {};
        this.bsDateConfig = {
            showWeekNumbers: false
        };
        this.filterValueType = 'text';
        this.viewableFilters = [];
        this.moreFilters = [];
        this.conditions = conditions;
        this.availableConditions = [];
        this.selectedFilterValues = [];
        this.currentDate = new Date();
        this.screenType = '';
        this.sendSelectedField = new EventEmitter();
        this.applyFilter = new EventEmitter();
        this.deleteFilter = new EventEmitter();
        this.showCondition = true;
        var intlSettings = this.settings._csIntlSettings;
        this.format = this.datePipe.formats.find(function (f) { return f.id == parseInt(intlSettings['short_date']); });
        this.bsDateConfig.dateInputFormat = (String(this.format.format)).toUpperCase();
        this.select2Config = {
            width: '100%'
        };
        this.select2ConfigDynamicFilter = {
            width: '100%',
            minimumInputLength: 2,
        };
        this.select2ConfigDynamicMultipleFilter = {
            width: '100%',
            minimumInputLength: 2,
            multiple: true
        };
        this.select2ConfigStaticMultipleFilter = {
            width: '100%',
            multiple: true,
        };
        this.select2ConfigDynamicMultiFilter = {
            width: '100%',
            multiple: true,
            data: []
        };
        this.select2SearchLabelConfig = {
            width: '100%',
            minimumInputLength: 3,
            data: [],
            tags: true,
            createSearchChoice: function (term, data) {
                var exists = false;
                data.forEach(function (idText) {
                    if (idText.text == term)
                        exists = true;
                });
                return term == ' ' || exists ? null :
                    { id: term, text: term };
            },
            formatSelection: format,
            multiple: true
        };
        this.select2MultipleOwnFilterConfig = {
            width: '100%',
            data: [],
            tags: true,
            createSearchChoice: function (term, data) {
                var exists = false;
                data.forEach(function (idText) {
                    if (idText.text == term)
                        exists = true;
                });
                return term == ' ' || exists ? null :
                    { id: term, text: term };
            },
            dropdownCssClass: "filter-custom-dropdown",
            formatSelection: format,
            multiple: true
        };
        function format(item) {
            return item.text;
        }
        this.filterForm = new FormGroup({
            field: new FormControl('', Validators.required),
            condition: new FormControl('', Validators.required),
            value: new FormControl('', Validators.required),
        });
        this.conditions.date = this.conditions.date.filter(function (condition) { return (condition.condition !== 'nextWeek' && condition.condition !== 'nextMonth' && condition.condition !== 'nextQuarter' && condition.condition !== 'nextYear' && condition.condition !== 'anytime' && condition.condition !== 'greaterThanToday'); });
    }
    FilterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.popUpElement = this.el.nativeElement.querySelector(".filter-popup");
        this.moreFilterElement = this.el.nativeElement.querySelector(".more-filter-list");
        var that = this;
        $(document).on('click', function (event) {
            that.clickEventListner(event, that, false);
        });
        $(document).on('click', 'a', function (event) {
            that.clickEventListner(event, that, true);
        });
        this.onWidthChangeSubscription = this.widthChangeEvent.subscribe(function (data) {
            _this.appliedFilters = data ? data : _this.appliedFilters;
            _this.calculateWidthOfFilter();
            if (data) {
                _this.setSelectedFilter();
            }
        });
    };
    FilterComponent.prototype.clickEventListner = function (event, that, isWatchAnchorTag) {
        var bodyScopeToCheck = $('body.ng-scope');
        bodyScopeToCheck = bodyScopeToCheck && bodyScopeToCheck.length ? bodyScopeToCheck[0] : null;
        if (event.target == bodyScopeToCheck) {
            return;
        }
        if (!$(event.target).closest('.dropdown-menu').length && !$(event.target).hasClass('modal-open') && $(".filter-popup").is(":visible")
            && (isWatchAnchorTag || event.target['id'] == '') && !$(event.target).closest('#add-filter-dropdown').length && !event.target.hasAttribute('bsdatepickerdaydecorator')) {
            that.closeFilterPopupUI();
        }
        if (!$(event.target).closest('.dropdown-menu').length && !$(event.target).hasClass('modal-open') && $(".more-filter-list").is(":visible")
            && (isWatchAnchorTag || event.target['id'] == '') && !$(event.target).closest('#more-filter-dropdown').length && !event.target.hasAttribute('bsdatepickerdaydecorator')) {
            that.closeMoreFilterPopup();
        }
    };
    FilterComponent.prototype.ngAfterViewInit = function () {
        this.calculateWidthOfFilter();
    };
    FilterComponent.prototype.calculateWidthOfFilter = function () {
        var _this = this;
        var parentWidth = $('.filter-comp-container').width();
        var widthOfTheFilters = 0;
        this.appliedFilters.map(function (filter, index) {
            var textLengthOfTheFilter = 0;
            var text;
            if (Array.isArray(filter.valueLabel)) {
                text = filter.valueLabel.map(function (v) { return v.text; }).join(', ');
            }
            else if (filter.valueLabel.length) {
                text = filter.valueLabel;
            }
            else {
                var labels = Object.entries(Object.entries(filter.valueLabel));
                for (var _i = 0, labels_1 = labels; _i < labels_1.length; _i++) {
                    var _a = labels_1[_i], index_1 = _a[0], _b = _a[1], key = _b[0], val = _b[1];
                    if (text === undefined) {
                        text = val;
                        if (labels.length > 1) {
                            text += ', ';
                        }
                    }
                    else {
                        text += val;
                        if (index_1 < (labels.length - 1)) {
                            text += ', ';
                        }
                    }
                }
            }
            var selectedField = _this.availableFields.find(function (field) { return field.ID === filter.ID; });
            var selectedCondition = _this.conditions[filter.type].find(function (condition) { return condition.condition === filter.operator; });
            if (selectedCondition) {
                selectedCondition.replaceText = selectedCondition.condition === 'range' ? 'Is between' : selectedCondition.replaceText;
            }
            textLengthOfTheFilter += selectedField.filterInterfaceLabel.length;
            textLengthOfTheFilter += selectedCondition ? selectedCondition.replaceText.length : 0;
            textLengthOfTheFilter += text.length;
            var flof = (textLengthOfTheFilter * 8) + 40;
            _this.appliedFilters[index]['width'] = flof > 300 ? 300 : flof;
            widthOfTheFilters += _this.appliedFilters[index]['width'];
            filter.fieldLabel = selectedField.filterInterfaceLabel;
            filter.conditionLabel = selectedCondition ? selectedCondition.replaceText : '';
            filter.valueLabelText = text;
            return filter;
        });
        this.viewableFilters = [];
        this.moreFilters = [];
        var avgWidthOfFilter = widthOfTheFilters / this.appliedFilters.length;
        var fwof = widthOfTheFilters + 125;
        if (parentWidth > fwof) {
            this.viewableFilters = this.appliedFilters;
        }
        else {
            var totalFiltersBlockSize_1 = 0;
            this.appliedFilters.forEach(function (filter, index) {
                totalFiltersBlockSize_1 = totalFiltersBlockSize_1 + filter.width;
                if (totalFiltersBlockSize_1 < (parseInt(parentWidth) - 125)) {
                    _this.viewableFilters.push(filter);
                }
                else {
                    _this.moreFilters.push(filter);
                }
            });
        }
    };
    FilterComponent.prototype.clearValueInput = function (event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.filterForm.controls.value.setValue(null);
    };
    FilterComponent.prototype.addFilter = function () {
        var _this = this;
        var data = this.filterForm.value;
        var selectedCondition = this.conditions[this.selectedFilter.filterType].find(function (cond) { return cond.condition === data.condition; });
        var value;
        var valueLabel;
        switch (this.selectedFilter.filterType) {
            case 'select':
                var chosedOption = this.selectedFilter.filterOptions.find(function (opt) { return opt.id === data.value; });
                value = valueLabel = chosedOption;
                break;
            case 'staticSingleSelect':
                value = data.value;
                var sssOption = this.selectedFilter.filterOptions.find(function (opt) { return opt.id === data.value; });
                valueLabel = sssOption.text;
                data.condition = 'relative';
                break;
            case 'staticMultiSelect':
                value = data.value;
                valueLabel = {};
                data.value.forEach(function (v) {
                    var sfo = _this.selectedFilter.filterOptions.find(function (opt) { return opt.id === v; });
                    value[v] = sfo.text;
                });
                break;
            case 'dynamic_filter':
                value = data.value;
                valueLabel = this.selectedFilterValues.find(function (sfv) { return sfv.id === data.value; });
                break;
            case 'dynamic_multiple_filter':
                value = data.value;
                valueLabel = {};
                data.value.forEach(function (v) {
                    var fv = _this.selectedFilterValues.find(function (sfv) { return sfv.id == v; });
                    valueLabel[v] = fv.text;
                });
                break;
            case 'dynamicFilterWithSearch':
                if (data.condition === 'logged_in_user') {
                    value = data.condition;
                    valueLabel = selectedCondition.replaceText;
                    data.condition = 'relative';
                }
                else {
                    value = data.value;
                    valueLabel = {};
                    data.value.forEach(function (v) {
                        var fv = _this.selectedFilterValues.find(function (sfv) { return sfv.id == v; });
                        valueLabel[v] = fv.text;
                    });
                }
                break;
            case 'dynamicMultipleSelect':
                value = data.value;
                valueLabel = {};
                data.value.forEach(function (v) {
                    var fv = _this.selectedFilter.filterOptions.find(function (sfv) { return sfv.id == v; });
                    valueLabel[v] = fv.text;
                });
                break;
            case 'subStringSearch':
                value = data.value;
                valueLabel = {};
                data.value.forEach(function (v) {
                    valueLabel[v] = v;
                });
                break;
            case 'multipleOwnFilter':
                value = data.value;
                valueLabel = {};
                data.value.forEach(function (v) {
                    valueLabel[v] = v;
                });
                break;
            case 'text':
                value = data.value;
                valueLabel = { id: data.value, text: data.value };
                break;
            case 'numeric':
                value = data.value;
                valueLabel = data.value;
                break;
            case 'date':
                var dateValue_1 = {};
                var dateLabel_1;
                if (selectedCondition.showDatePicker) {
                    if (data.condition !== 'range') {
                        valueLabel = this.datePipe.transform(data.value);
                        dateValue_1['startDate'] = moment(data.value).format('YYYY-MM-DD') + ' 00:00:00';
                        value = [dateValue_1['startDate']];
                    }
                    else {
                        dateLabel_1 = {};
                        data.value.forEach(function (date, index) {
                            if (index === 0) {
                                dateLabel_1['startDate'] = _this.datePipe.transform(date);
                                dateValue_1['min'] = moment(date).format('YYYY-MM-DD') + ' 00:00:00';
                            }
                            else {
                                dateLabel_1['endDate'] = _this.datePipe.transform(date);
                                dateValue_1['max'] = moment(date).format('YYYY-MM-DD') + ' 23:59:59';
                            }
                        });
                        valueLabel = dateLabel_1.startDate + " - " + dateLabel_1.endDate;
                        value = dateValue_1;
                    }
                }
                else {
                    value = data.condition;
                    valueLabel = selectedCondition.replaceText;
                    data.condition = 'relative';
                }
                break;
            default:
                value = data.value;
                valueLabel = data.value;
                break;
        }
        this.applyFilter.emit({
            deletedCheckboxSelected: false,
            ID: data.field,
            value: value,
            valueLabel: valueLabel,
            operator: data.condition,
            type: this.selectedFilter.filterType
        });
        this.closeFilterPopupUI();
        this.calculateWidthOfFilter();
        this.selectedFilterValues = [];
        this.setSelectedFilter();
        this.showCondition = true;
    };
    FilterComponent.prototype.openFilterPopup = function () {
        this.clearForm();
        this.isPopupOpened = true;
        this.popUpElement.classList.add('popup-visible');
        if (this.screenType == 'workflow') {
            var pos = $('#add-filter-dropdown').position();
            $(this.popUpElement).css({ left: pos.left + 12, top: '60px' });
            $(this.moreFilterElement).css({ top: '60px' });
            $('.aero').addClass('aero-less');
        }
        else if (this.specificScreenType === 'driverIncidentDetails' && this.screenType === 'fleet') {
            var pos = $('#add-filter-dropdown').position();
            $(this.popUpElement).css({ position: 'fixed', left: pos.left + 12, top: '128px' });
            $(this.moreFilterElement).css({ top: '60px' });
            $('.aero').addClass('aero-less');
        }
        else {
            var pos = $('#add-filter-dropdown').position();
            $(this.popUpElement).css({ left: pos.left - 97 });
            $('.dropdown-menu.filter-popup').css({ top: pos.top + 36 });
        }
        this.closeMoreFilterPopup();
    };
    FilterComponent.prototype.closeFilterPopupUI = function () {
        if (this.screenType == 'workflow') {
            $('.aero').removeClass('aero-less');
        }
        this.isPopupOpened = false;
        this.popUpElement.classList.remove('popup-visible');
    };
    FilterComponent.prototype.openMoreFilterPopup = function () {
        this.isMoreFilterPopupOpened = true;
        this.moreFilterElement.classList.add('popup-visible');
        var pos = $('#more-filter-dropdown').position();
        $(this.moreFilterElement).css({ left: pos.left - 138 });
        this.closeFilterPopupUI();
    };
    FilterComponent.prototype.closeMoreFilterPopup = function () {
        this.isMoreFilterPopupOpened = false;
        this.moreFilterElement.classList.remove('popup-visible');
    };
    FilterComponent.prototype.clearForm = function () {
        this.filterForm.reset();
        this.selectedFilter = null;
        this.showCondition = true;
        $("#filter_field_select").select2('val', '');
        $("#filter_condition_select").select2('val', '');
        $("#dynamic_filter_type").select2('val', '');
        $("#dynamic_multiple_filter_type").select2('val', '');
        $("#static_multi_select").select2('val', '');
        $("#static_single_select").select2('val', '');
        $("#multiple_select_search").select2('val', '');
        $("#dynamic_multi_select").select2('val', '');
        $('#multiple_own_filter').select2('val', '');
        $('#s2id_dynamic_filter_type').remove();
        $('#s2id_dynamic_multiple_filter_type').remove();
        $('#s2id_static_single_select').remove();
        $('#s2id_static_multi_select').remove();
        $('#s2id_multiple_select_search').remove();
        $('#s2id_multiple_own_filter').remove();
        $('#s2id_dynamic_multi_select').remove();
    };
    FilterComponent.prototype.removeFilter = function (index, type) {
        this.deleteFilter.emit(type === 'more' ? index + this.viewableFilters.length : index);
        this.calculateWidthOfFilter();
        if (type === 'more') {
            this.closeMoreFilterPopup();
        }
        this.setSelectedFilter();
    };
    FilterComponent.prototype.onSelectingField = function (field) {
        var _this = this;
        this.selectedFilter = this.availableFields.find(function (f) { return f.ID === field; });
        if (this.selectedFilter.filterType === 'staticMultiSelect') {
            this.showCondition = true;
            this.select2ConfigStaticMultipleFilter['data'] = {
                results: this.selectedFilter.filterOptions
            };
            $('#s2id_static_single_select').remove();
        }
        else if (this.selectedFilter.filterType === 'dynamicMultipleSelect') {
            this.showCondition = true;
            this.select2ConfigDynamicMultiFilter['data'] = {
                results: this.selectedFilter.filterOptions
            };
            $('#s2id_static_single_select').remove();
        }
        else if (this.selectedFilter.filterType === 'staticSingleSelect') {
            this.showCondition = false;
            this.filterForm.controls.condition.clearValidators();
            this.filterForm.controls.condition.setErrors(null);
            $('#s2id_filter_condition_select').remove();
        }
        else if (this.selectedFilter.filterType === 'dynamicFilterWithSearch') {
            this.showCondition = true;
            this.showValueOption = false;
            $('#s2id_dynamic_multiple_filter_type').remove();
            $('#s2id_static_single_select').remove();
            setTimeout(function () { _this.showValueOption = true; }, 100);
        }
        else {
            this.showCondition = true;
            $('#s2id_static_single_select').remove();
        }
        // For opportunity specific codes
        if (this.selectedFilter.ID === 'salesperson' || this.selectedFilter.ID === 'createdBy') {
            if (!this.conditions.dynamicFilterWithSearch.find(function (con) { return con.condition === 'logged_in_user'; })) {
                this.conditions.dynamicFilterWithSearch.push({
                    condition: 'logged_in_user',
                    replaceText: 'Logged in user',
                    showValueOption: false
                });
            }
        }
        else {
            if (this.conditions.dynamicFilterWithSearch.find(function (con) { return con.condition === 'logged_in_user'; })) {
                this.conditions.dynamicFilterWithSearch.splice(2, 1);
            }
        }
        this.filterForm.controls.condition.setValue(null);
        $("#filter_condition_select").select2('val', '');
        this.filterForm.controls.value.setValue(null);
        $("#dynamic_filter_type").select2('val', '');
        $("#dynamic_multiple_filter_type").select2('val', '');
        $("#static_multi_select").select2('val', '');
        $('#static_single_select').select2('val', '');
        $("#multiple_select_search").select2('val', '');
        $("#dynamic_multi_select").select2('val', '');
        $('#s2id_multiple_select_search').remove();
        $('#s2id_multiple_own_filter').remove();
        $('#s2id_dynamic_multiple_filter_type').remove();
        $('#s2id_dynamic_multi_select').remove();
        var url;
        if (this.screenType == 'fleet') {
            url = environment.apiHost + environment.fleet + this.selectedFilter.filterDropDownService;
        }
        else {
            url = environment.apiBasePath + this.selectedFilter.filterDropDownService;
        }
        if (this.selectedFilter.filterDropDownService) {
            var url_1;
            if (this.screenType == 'fleet') {
                url_1 = environment.apiHost + environment.fleet + this.selectedFilter.filterDropDownService;
            }
            else {
                url_1 = environment.apiBasePath + this.selectedFilter.filterDropDownService;
            }
            this.select2ConfigDynamicFilter['ajax'] = this.select2ConfigDynamicMultipleFilter['ajax'] = {
                initSelection: function () { },
                url: url_1,
                data: function (searchText) {
                    if (searchText == '') {
                        return false;
                    }
                    var newQueries = {};
                    newQueries['screenType'] = _this.screenType || '';
                    newQueries['q'] = encodeURIComponent(searchText);
                    return newQueries;
                },
                results: function (data) {
                    var _a;
                    (_a = _this.selectedFilterValues).push.apply(_a, data);
                    return { results: data };
                },
            };
        }
        else {
            $('#s2id_dynamic_filter_type').remove();
            $('#s2id_dynamic_multiple_filter_type').remove();
        }
    };
    FilterComponent.prototype.onSelectMultipleValues = function (ev) {
        this.filterForm.controls.value.setValue(ev);
    };
    FilterComponent.prototype.onSelectingCondition = function (ev) {
        if (this.selectedFilter.filterType === 'date') {
            var selectedCondition = this.conditions['date'].find(function (cond) { return cond.condition === ev; });
            this.showDatePicker = selectedCondition.showDatePicker;
            if (selectedCondition.showDatePicker) {
                this.filterForm.controls.value.setValidators(Validators.required);
            }
            else {
                this.filterForm.controls.value.clearValidators();
                this.filterForm.controls.value.setErrors(null);
            }
        }
        // For oppt board specific
        if (ev === 'logged_in_user') {
            $("#dynamic_multiple_filter_type").select2('val', '');
            $('#s2id_dynamic_multiple_filter_type').remove();
            this.showValueOption = false;
            this.filterForm.controls.value.clearValidators();
            this.filterForm.controls.value.setErrors(null);
        }
        else {
            this.showValueOption = true;
            this.filterForm.controls.value.setValidators(Validators.required);
        }
    };
    FilterComponent.prototype.setSelectedFilter = function () {
        var _this = this;
        this.availableFields = this.availableFields.map(function (field) {
            var selectedFilter = _this.appliedFilters.find(function (filter) { return (field.ID === filter.ID); });
            if (selectedFilter) {
                field.isSelected = true;
            }
            else {
                field.isSelected = false;
            }
            return field;
        }).sort(function (a, b) { return a.isSelected - b.isSelected; });
    };
    return FilterComponent;
}());
export { FilterComponent };
