import { Component, OnInit, Inject } from '@angular/core';
import {Subject} from "rxjs";
import {BsModalRef} from "ngx-bootstrap";
import {BlockModel, DagSourceModel} from "@app/workflow-common/services/DagSourceModel";

@Component({
  selector: 'app-add-task-side-panel',
  templateUrl: './add-task-side-panel.component.html',
  styleUrls: ['./add-task-side-panel.component.scss']
})
export class AddTaskSidePanelComponent implements OnInit {

    public onClose: Subject<any>;
    dagSourceModel: DagSourceModel;

    activeBlockName:string = '';
    activeBlockObject: BlockModel = undefined;

    title: string;
    workflowType: string;
    isEdit: boolean = false;
    objectName: string = '';
    stageName: string = '';
    stageId: number;
    blocksList: any[] = [];
    blockId: string = '';
    parentId: string = '';
    actionType:string;
    isActive: boolean;
    deleteMessage: string = 'The automation must be disabled before deleting this action. Do you want to deactivate?';

    constructor(
      public modalRef: BsModalRef,
      @Inject('confirmationBoxHelper') private confirmationBoxHelper,
      @Inject('$scope') private ajsScope
    ) { }

    ngOnInit() {
        this.onClose = new Subject<any>();
        this.blocksList = this.dagSourceModel.getBlocksSource().getBlockList(this.workflowType, this.objectName);
        const activeBlock = this.dagSourceModel.getBlockById(this.blockId);
        if(activeBlock) {
            this.activeBlockName = activeBlock['name'];
            this.resolveActiveBlockObject(activeBlock);
            this.isEdit = true;
        }
        else if(this.actionType === 'on_stage_change') {
            const stageChangeBlock = this.dagSourceModel.getBlocksSource().getBlockByKey('actionBlocks_changeStageBlock');
            this.activeBlockName = stageChangeBlock['name'];
            this.resolveActiveBlockObject(stageChangeBlock);
        }
    }
    onCloseModal() {
        this.modalRef.hide();
    }

    closeWidgetModal(data:{}) {
        if(data['action'] == 'close') {
            if(this.isEdit) {
                this.modalRef.hide();
            }
            else {
                this.activeBlockObject = undefined;
                this.activeBlockName = '';
            }
        }
        else if(data['action'] == 'close_all') {
            this.modalRef.hide();
        }
        else if(data['action'] == 'save_and_close') {
            const blockData = data['data'];
            this.dagSourceModel.addTask(blockData, this.parentId);
            this.onClose.next(blockData);
        }
        else if(data['action'] == 'remove_block') {
            this.onClose.next(data);
        }
    }

    showBlockPanel(blockData:{}) {
        // not allow multiple clicks
        if(this.activeBlockName == blockData['name']) {
            return;
        }
        this.activeBlockName = blockData['name'];
        this.resolveActiveBlockObject(blockData);
    }

    resolveActiveBlockObject(blockData) {
        const cloneBlockData = {...blockData};
        if(!blockData.parentId) {
            blockData.parentId = this.parentId;
        }
        this.activeBlockObject = new BlockModel(cloneBlockData);
    }
}
