<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
</div>
<div class="modal-body ng-workflow-add-block">
    <div class="add-task--actions cs-d-flex flex-column">
        <div class="cs-helper-section bg-white cs-border cs-border--bottom">
            <div class="cs-help-text-color p--15" translate [translateParams]="{objectName:objectName, stageName:stageName.toLowerCase()}">Pipeline.Automation.Actions.Helper.Text</div>
        </div>
        <div class="cs-flex-1">
            <div class="cs-d-flex flex-column" *ngFor="let group of blocksList">
                <div class="p--15 cs-pl-action-group">
                    <div class="cs-pl-item--title">{{ group.title }}</div>
                </div>
                <div class="cs-pl-action-group--section p-l--15 p-r--15">
                    <div *ngFor="let block of group['blocks']" (click)="showBlockPanel(block)" class="cs-pl-action-group--item cs-d-flex cs-border cs-border--all bg-white w-100 p--15 cursor-pointer">
                        <div class="cs-pl-action-group--item-icon m-r--10">
                            <i class="{{block['iconClass']}}"></i>
                        </div>
                        <div class="cs-pl-action-group--item-content">
                            <div class="cs-d-flex flex-column">
                                <div class="cs-pl-action-group--item-content--heading m-b--5">{{ block['title'] }}</div>
                                <div class="cs-pl-action-group--item-content--description">{{ block['description'] }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-link" (click)="onCloseModal()">Close</button>
</div>

<ng-container>
    <div class="pos-abt-full wf-block-delay wf-block bg-white" [ngClass]="{'open': activeBlockName==='emailBlock'}">
        <app-email-block-widget *ngIf="activeBlockName === 'emailBlock'"
            (close)="closeWidgetModal($event)"
            [workflowType]="workflowType"
            [dagSourceModel]="dagSourceModel"
            [blockObject]="activeBlockObject"
            [objectName]="objectName">
        </app-email-block-widget>
    </div>
    <div class="pos-abt-full wf-block-delay wf-block bg-white" [ngClass]="{'open': activeBlockName==='smsBlock'}">
        <app-sms-block-widget *ngIf="activeBlockName === 'smsBlock'"
          (close)="closeWidgetModal($event)"
          [workflowType]="workflowType"
          [dagSourceModel]="dagSourceModel"
          [blockObject]="activeBlockObject"
          [objectName]="objectName">
        </app-sms-block-widget>
    </div>
    <div class="pos-abt-full wf-block-delay wf-block bg-white" [ngClass]="{'open': activeBlockName==='notificationBlock'}">
        <app-notification-block-widget *ngIf="activeBlockName === 'notificationBlock'"
            (close)="closeWidgetModal($event)"
            [workflowType]="workflowType"
            [dagSourceModel]="dagSourceModel"
            [blockObject]="activeBlockObject">
        </app-notification-block-widget>
    </div>

    <div class="pos-abt-full wf-block-delay wf-block bg-white" [ngClass]="{'open': activeBlockName==='changeStageBlock'}">
        <app-change-stage-block-widget *ngIf="activeBlockName === 'changeStageBlock'"
           (close)="closeWidgetModal($event)"
           [stageId]="stageId"
           [workflowType]="workflowType"
           [dagSourceModel]="dagSourceModel"
           [blockObject]="activeBlockObject">
        </app-change-stage-block-widget>
    </div>

    <div class="pos-abt-full wf-block-delay wf-block bg-white" [ngClass]="{'open': activeBlockName==='scheduledActivityBlock'}">
        <app-scheduled-activity-block-widget *ngIf="activeBlockName === 'scheduledActivityBlock'"
            (close)="closeWidgetModal($event)"
            [workflowType]="workflowType"
            [dagSourceModel]="dagSourceModel"
            [blockObject]="activeBlockObject">
        </app-scheduled-activity-block-widget>
    </div>

    <div class="pos-abt-full wf-block-delay wf-block bg-white" [ngClass]="{'open': activeBlockName==='sendProposalBlock'}">
        <app-send-proposal-block-widget *ngIf="activeBlockName === 'sendProposalBlock'"
            (close)="closeWidgetModal($event)"
            [workflowType]="workflowType"
            [dagSourceModel]="dagSourceModel"
            [blockObject]="activeBlockObject">
        </app-send-proposal-block-widget>
    </div>

    <div class="pos-abt-full wf-block-delay wf-block bg-white" [ngClass]="{'open': activeBlockName==='startSequenceBlock'}">
        <app-start-sequence-block-widget *ngIf="activeBlockName === 'startSequenceBlock'"
                                        (close)="closeWidgetModal($event)"
                                        [workflowType]="workflowType"
                                        [dagSourceModel]="dagSourceModel"
                                        [blockObject]="activeBlockObject">
        </app-start-sequence-block-widget>
    </div>
    <div class="pos-abt-full wf-block-delay wf-block bg-white" [ngClass]="{'open': activeBlockName==='setSalesPersonBlock'}">
        <app-set-sales-person-block-widget *ngIf="activeBlockName === 'setSalesPersonBlock'"
                                        (close)="closeWidgetModal($event)"
                                        [workflowType]="workflowType"
                                        [dagSourceModel]="dagSourceModel"
                                        [blockObject]="activeBlockObject">
        </app-set-sales-person-block-widget>
    </div>
    <div class="pos-abt-full wf-block-delay wf-block bg-white" [ngClass]="{'open': activeBlockName==='AddJobBlock'}">
        <app-add-job-block-widget *ngIf="activeBlockName === 'AddJobBlock'"
                                           (close)="closeWidgetModal($event)"
                                           [workflowType]="workflowType"
                                           [dagSourceModel]="dagSourceModel"
                                           [blockObject]="activeBlockObject">
        </app-add-job-block-widget>
    </div>
    <div class="pos-abt-full wf-block-delay wf-block bg-white" [ngClass]="{'open': activeBlockName==='sendJobReportBlock'}">
        <app-send-job-report-block-widget *ngIf="activeBlockName === 'sendJobReportBlock'"
                                           (close)="closeWidgetModal($event)"
                                           [workflowType]="workflowType"
                                           [dagSourceModel]="dagSourceModel"
                                           [blockObject]="activeBlockObject">
        </app-send-job-report-block-widget>
    </div>


</ng-container>
