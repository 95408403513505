<div class="modal-header">
    <h4 class="modal-title pull-left">{{sendProposalBlockData['title']}}</h4>
</div>
<div class="modal-body">
    <div class="loading-wrap" *ngIf="isLoading">
        <span class="circle1"></span>
        <span class="circle2"></span>
        <span class="circle3"></span>
    </div>
    <form class="no-form-style" *ngIf="form" [formGroup]="form" novalidate>
        <div class="cs-d-flex flex-column">
            <div class="cs-helper-section bg-white cs-border cs-border--bottom">
                <div class="cs-help-text-color p--15">{{sendProposalBlockData['description']}}</div>
            </div>
            <div class="cs-flex-1">
                <div class="cs-block-widget--form cs-d-flex flex-column">
                    <div class="cs-block-widget--form-control" *ngIf="isSMTPEnabled">
                        <label class="control-label required_field">From:</label>
                        <div>
                            <select class="full-width-select2 cs-select2-wfe default-input-height"
                                    formControlName="fromEmail"
                                    csSelect2
                                    [select2Config]="{}">
                                <option value="" translate>Please.Choose</option>
                                <option *ngFor="let item of fromEmailAddressesList" [value]="item.id">{{item.text}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="cs-block-widget--form-control">
                        <div class="cs-d-flex">
                            <label class="control-label required_field mr-auto">{{sendProposalBlockData['inputLabelText']}}:</label>
                        </div>
                        <div>
                            <input
                                (selected)="selectedEmailAddress($event,'to')"
                                id="email_address_to"
                                csSelect2 pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                [select2Config]="select2ConfigToEmail"
                                [dropdownvalue]="selectedEmailInputs['toEmailTag']"
                                class="full-width-select2 cs-select2-wfe"/>
                        </div>
                    </div>
                    <div class="cs-block-widget--form-control">
                        <div class="cs-d-flex">
                            <label class="control-label required_field mr-auto" translate>Email.Template:</label>
                            <app-template-preview
                                type="email"
                                [message]="inputModel.message"
                                [subject]="inputModel.subject"
                            ></app-template-preview>
                        </div>
                        <div>
                            <app-search-template [screen]="'email_template'"
                                 themeName="compact"
                                 [selectedTemplateId]="inputModel.templateId"
                                 (selectedItem)="setTemplate($event,'email')"
                                 [screenLabel]="'Email templates'"
                                 [showAddNewTemplate]="true">
                            </app-search-template>
                        </div>
                    </div>
                    <!--<hr style="float: none;" class="m-b--0">
                    <div class="cs-block-widget--form-control">
                        <label class="box-checkbox">
                            <span translate>Do.Attach.Proposal.Template</span>
                            <input type="checkbox" formControlName="doAttachProposal">
                            <span class="checkmark"></span>
                        </label>
                        <span class="help-text" translate [translateParams]="{ObjectName: dagSourceModel.mainObjectName}">Automation.Attachment.Proposal.Help.Text</span>
                    </div>
                    -->
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <ng-container *ngIf="!isLoading else footerSection">
        <button *ngIf="showDeleteBtn" class="btn btn-danger btn-medium float-left" (click)="remove()" type="button">Delete </button>
        <button class="btn btn-link" (click)="closeModal()">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="!form.valid || isFormSubmitted" (click)="save()">
            <span *ngIf="!isFormSubmitted" translate>Save</span>
            <span *ngIf="isFormSubmitted" translate>Saving</span>
        </button>
    </ng-container>
</div>

<ng-template #footerSection>
    <button class="btn btn-link" [disabled]="true">Cancel</button>
    <button type="submit" class="btn btn-primary" [disabled]="true">
        <span translate>Save</span>
    </button>
</ng-template>
