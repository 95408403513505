/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../directives/material-directives/cs-information-bar/cs-information-bar.component.ngfactory";
import * as i2 from "../../../directives/material-directives/cs-information-bar/cs-information-bar.component";
import * as i3 from "../condition-edge/condition-edge.component.ngfactory";
import * as i4 from "../condition-edge/condition-edge.component";
import * as i5 from "@angular/forms";
import * as i6 from "../../../directives/material-directives/cs-select2/cs-select2.directive";
import * as i7 from "ngx-bootstrap/popover";
import * as i8 from "ngx-bootstrap/component-loader";
import * as i9 from "ngx-bootstrap/positioning";
import * as i10 from "@angular/common";
import * as i11 from "@ngx-translate/core";
import * as i12 from "../../../shared/cs-components/search-template/search-template.component.ngfactory";
import * as i13 from "../../../shared/cs-components/search-template/search-template.component";
import * as i14 from "../../../shared/common-folder-structure/service/folder.service";
import * as i15 from "../../../sidepanel/services/sidepanel-modal.service";
import * as i16 from "../../../core/auth/auth.service";
import * as i17 from "./sms-block.component";
import * as i18 from "../../services/workflowhttp.service";
var styles_SmsBlockComponent = [];
var RenderType_SmsBlockComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SmsBlockComponent, data: {} });
export { RenderType_SmsBlockComponent as RenderType_SmsBlockComponent };
function View_SmsBlockComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "loading-wrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "circle1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "circle2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "circle3"]], null, null, null, null, null))], null, null); }
function View_SmsBlockComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "wfe-block-notification-bar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["cs-information-bar", ""]], null, null, null, i1.View_CsInformationBarComponent_0, i1.RenderType_CsInformationBarComponent)), i0.ɵdid(2, 114688, null, 0, i2.CsInformationBarComponent, [], { message: [0, "message"], icon: [1, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.warningMessage; var currVal_1 = "ss-info"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SmsBlockComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-condition-edge", [], null, null, null, i3.View_ConditionEdgeComponent_0, i3.RenderType_ConditionEdgeComponent)), i0.ɵdid(1, 114688, null, 0, i4.ConditionEdgeComponent, [], { activeField: [0, "activeField"], inputModel: [1, "inputModel"], operators: [2, "operators"], isMarketPlaceView: [3, "isMarketPlaceView"], isReadOnlyMode: [4, "isReadOnlyMode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeField; var currVal_1 = _co.inputModel; var currVal_2 = _co.operators; var currVal_3 = _co.isMarketPlaceView; var currVal_4 = _co.isReadOnlyMode; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_SmsBlockComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "control"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "label", [["class", "control-label required_field"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["To:"])), (_l()(), i0.ɵeld(3, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 6, "input", [["class", "full-width-select2 cs-select2-wfe mb-10"], ["csSelect2", ""], ["id", "additional-sms"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "selected"], [null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("selected" === en)) {
        var pd_4 = (_co.selectedSmsAddress($event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.inputModel.model.toSmsTag = $event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, null, 0, i5.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i0.ɵdid(7, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i0.ɵdid(9, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), i0.ɵdid(10, 4276224, null, 0, i6.CsSelect2Directive, [i0.ElementRef, i0.Renderer, [2, i5.NgControl]], { select2Config: [0, "select2Config"], value: [1, "value"] }, { valueEmitter: "selected" })], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.inputModel.model.toSmsTag; _ck(_v, 7, 0, currVal_7); var currVal_8 = _co.select2LabelConfig; var currVal_9 = _co.inputModel.model.toSmsTag; _ck(_v, 10, 0, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 9).ngClassValid; var currVal_5 = i0.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_SmsBlockComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "SMScontentScroll"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["style", "font-weight: bold"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Message:"])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 3, 0, currVal_0); }); }
function View_SmsBlockComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 2, "a", [["class", "btn-default btn-secondary"], ["container", "body"], ["containerClass", "SMScustomClass"], ["placement", "left"], ["style", "float:right;cursor:pointer;"]], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i7.PopoverDirective, [i7.PopoverConfig, i0.ElementRef, i0.Renderer2, i0.ViewContainerRef, i8.ComponentLoaderFactory, i9.PositioningService], { popover: [0, "popover"], placement: [1, "placement"], outsideClick: [2, "outsideClick"], container: [3, "container"], containerClass: [4, "containerClass"] }, null), (_l()(), i0.ɵted(-1, null, [" Preview template "])), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent, 9); var currVal_1 = "left"; var currVal_2 = true; var currVal_3 = "body"; var currVal_4 = "SMScustomClass"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_SmsBlockComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SmsBlockComponent_4)), i0.ɵdid(2, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 14, "div", [["class", "item form-item p-15"]], null, null, null, null, null)), i0.ɵdid(4, 278528, null, 0, i10.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(5, { "border-top": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SmsBlockComponent_5)), i0.ɵdid(7, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 9, "div", [["class", "control"]], null, null, null, null, null)), (_l()(), i0.ɵand(0, [["popTemplate", 2]], null, 0, null, View_SmsBlockComponent_6)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SmsBlockComponent_7)), i0.ɵdid(11, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 2, "label", [["class", "control-label required_field"], ["translate", ""]], null, null, null, null, null)), i0.ɵdid(13, 8536064, null, 0, i11.TranslateDirective, [i11.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["SMS.Template:"])), (_l()(), i0.ɵeld(15, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 1, "app-search-template", [["themeName", "compact"]], null, [[null, "selectedItem"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedItem" === en)) {
        var pd_0 = (_co.setTemplateDetails($event, "sms") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_SearchTemplateComponent_0, i12.RenderType_SearchTemplateComponent)), i0.ɵdid(17, 638976, null, 0, i13.SearchTemplateComponent, [i14.FolderService, i15.SidepanelModalService, i16.AuthService], { screen: [0, "screen"], showAddNewTemplate: [1, "showAddNewTemplate"], screenLabel: [2, "screenLabel"], selectedTemplate: [3, "selectedTemplate"], themeName: [4, "themeName"] }, { selectedItem: "selectedItem" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isConditional; _ck(_v, 2, 0, currVal_0); var currVal_1 = "item form-item p-15"; var currVal_2 = _ck(_v, 5, 0, _co.isConditional); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = (_co.workflowType != "Sequence"); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.message; _ck(_v, 11, 0, currVal_4); var currVal_5 = ""; _ck(_v, 13, 0, currVal_5); var currVal_6 = "sms_template"; var currVal_7 = false; var currVal_8 = _co.smsScreenLabel; var currVal_9 = _co.activeTemplateTitle; var currVal_10 = "compact"; _ck(_v, 17, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, null); }
function View_SmsBlockComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(1, 8536064, null, 0, i11.TranslateDirective, [i11.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SmsBlockComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(1, 8536064, null, 0, i11.TranslateDirective, [i11.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Saving"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SmsBlockComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["SMS settings"])), (_l()(), i0.ɵeld(3, 0, null, null, 17, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SmsBlockComponent_1)), i0.ɵdid(6, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SmsBlockComponent_2)), i0.ɵdid(8, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SmsBlockComponent_3)), i0.ɵdid(10, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 9, "div", [["class", "modal-footer"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "a", [["class", "btn"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Close"])), (_l()(), i0.ɵeld(14, 0, null, null, 1, "button", [["class", "btn btn-danger btn-medium float-left"], ["type", "button"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeCell() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Delete "])), (_l()(), i0.ɵeld(16, 0, null, null, 4, "button", [["class", "btn btn-primary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addValue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SmsBlockComponent_8)), i0.ɵdid(18, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SmsBlockComponent_9)), i0.ɵdid(20, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 6, 0, currVal_0); var currVal_1 = ((_co.status && !_co.loading) && _co.isEdit); _ck(_v, 8, 0, currVal_1); var currVal_2 = !_co.loading; _ck(_v, 10, 0, currVal_2); var currVal_6 = !_co.saving; _ck(_v, 18, 0, currVal_6); var currVal_7 = _co.saving; _ck(_v, 20, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.loading; _ck(_v, 11, 0, currVal_3); var currVal_4 = !_co.showDeleteBtn; _ck(_v, 14, 0, currVal_4); var currVal_5 = (_co.saving || _co.validateItems()); _ck(_v, 16, 0, currVal_5); }); }
export function View_SmsBlockComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cs-workflow-sms-block", [], null, null, null, View_SmsBlockComponent_0, RenderType_SmsBlockComponent)), i0.ɵdid(1, 4308992, null, 0, i17.SmsBlockComponent, [i0.ChangeDetectorRef, i18.WorkflowhttpService, "confirmationBoxHelper", "$scope"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SmsBlockComponentNgFactory = i0.ɵccf("cs-workflow-sms-block", i17.SmsBlockComponent, View_SmsBlockComponent_Host_0, { isEdit: "isEdit", inputModel: "inputModel", isConditional: "isConditional", activeField: "activeField", operators: "operators", isMarketPlaceView: "isMarketPlaceView", deleteMessage: "deleteMessage", warningMessage: "warningMessage", workflowType: "workflowType", diagramOptions: "diagramOptions", status: "status", isReadOnlyMode: "isReadOnlyMode" }, { outputModel: "outputModel", close: "close" }, []);
export { SmsBlockComponentNgFactory as SmsBlockComponentNgFactory };
