<div class="modal-header">
    <h4 class="modal-title pull-left">{{smsBlockData['title']}}</h4>
</div>
<div class="modal-body">
    <div class="loading-wrap" *ngIf="isLoading">
        <span class="circle1"></span>
        <span class="circle2"></span>
        <span class="circle3"></span>
    </div>
    <form class="no-form-style" *ngIf="form" [formGroup]="form" novalidate>
        <div class="cs-d-flex flex-column">
            <div class="cs-helper-section bg-white cs-border cs-border--bottom">
                <div class="cs-help-text-color p--15">{{smsBlockData['description']}}</div>
            </div>
            <div class="cs-flex-1">
                <div class="cs-block-widget--form cs-d-flex flex-column">
                    <div class="cs-block-widget--form-control">
                        <label class="control-label required_field mr-auto">{{smsBlockData['inputLabelText']}}:</label>
                        <div>
                            <input
                                (selected)="selectedMobileAddress($event)"
                                id="recipient_mobile_address"
                                csSelect2
                                [select2Config]="select2ConfigToSms"
                                [dropdownvalue]="selectedMobileInputs"
                                class="full-width-select2 cs-select2-wfe"/>
                        </div>
                    </div>
                    <div class="cs-block-widget--form-control">
                        <div class="cs-d-flex">
                            <label class="control-label required_field mr-auto" translate>SMS.Template:</label>
                            <app-template-preview
                                type="sms"
                                [message]="inputModel.message">
                            </app-template-preview>
                        </div>
                        <div>
                            <app-search-template [screen]="'sms_template'"
                                 themeName="compact"
                                 [screenLabel]="'SMS templates'"
                                 [showAddNewTemplate]="true"
                                 [selectedTemplateId]="inputModel.templateId"
                                 (selectedItem)="setTemplateDetails($event,'sms')">
                            </app-search-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <ng-container *ngIf="!isLoading else footerSection">
        <button *ngIf="showDeleteBtn" class="btn btn-danger btn-medium float-left" (click)="remove()" type="button">Delete </button>
        <button class="btn btn-link" (click)="closeModal()">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="!form.valid || isFormSubmitted" (click)="save()">
            <span *ngIf="!isFormSubmitted" translate>Save</span>
            <span *ngIf="isFormSubmitted" translate>Saving</span>
        </button>
    </ng-container>
</div>

<ng-template #footerSection>
    <button class="btn btn-link" [disabled]="true">Cancel</button>
    <button type="submit" class="btn btn-primary" [disabled]="true">
        <span translate>Save</span>
    </button>
</ng-template>
