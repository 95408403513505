import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { DagSourceModel } from "@app/workflow-common/services/DagSourceModel";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
import { AddJobBlockModel } from "@app/workflow-common/services/models/AddJobBlockModel";
var AddJobBlockWidgetComponent = /** @class */ (function () {
    function AddJobBlockWidgetComponent(workflowService, formBuilder) {
        this.workflowService = workflowService;
        this.formBuilder = formBuilder;
        this.close = new EventEmitter();
        this.jobBlockData = {};
        this.isLoading = false;
        this.jobDescriptions = [];
        this.isFormSubmitted = false;
        this.selectedJobDescriptionInputs = {};
    }
    AddJobBlockWidgetComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var blockObject, modelData;
            return tslib_1.__generator(this, function (_a) {
                this.jobBlockData = this.dagSourceModel.getBlocksSource().getBlockByKey(this.blockObject.key);
                blockObject = tslib_1.__assign({}, this.blockObject);
                modelData = blockObject['model'] ? blockObject['model'] : undefined;
                this.inputModel = new AddJobBlockModel(modelData);
                this.makeJobDescriptionDropdownData();
                this.resolveData();
                this.form = this.formBuilder.group({
                    jobDescriptionId: new FormControl(this.selectedJobDescriptionInputs['jobDescriptionId'], [Validators.required]),
                    displayValue: new FormControl(this.selectedJobDescriptionInputs['displayValue'])
                });
                return [2 /*return*/];
            });
        });
    };
    AddJobBlockWidgetComponent.prototype.remove = function () {
        var blockObject = tslib_1.__assign({}, this.blockObject);
        blockObject.model = this.inputModel;
        this.close.emit({
            action: 'remove_block',
            data: blockObject
        });
    };
    AddJobBlockWidgetComponent.prototype.closeModal = function () {
        this.close.emit({
            action: 'close',
            data: null
        });
    };
    AddJobBlockWidgetComponent.prototype.resolveData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        return [4 /*yield*/, this.jobDescriptionList()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddJobBlockWidgetComponent.prototype.jobDescriptionList = function () {
        var _this = this;
        var promise = new Promise(function (resolve) {
            _this.workflowService.getJobDescriptions({
                ___noPageloading: true,
                limit: 9999
            })
                .subscribe(function (resp) {
                _this.isLoading = false;
                //console.log(resp);
                var jobDescriptions = resp['jobDescriptions'];
                _this.jobDescriptions = jobDescriptions.map(function (item) {
                    return { id: item.id, text: item.description };
                });
                resolve();
            });
        });
        return promise;
    };
    AddJobBlockWidgetComponent.prototype.makeJobDescriptionDropdownData = function () {
        this.selectedJobDescriptionInputs['jobDescriptionId'] = this.inputModel.jobDescriptionId;
        if (this.inputModel.jobDescriptionId) {
            this.selectedJobDescriptionInputs['displayValue'] = this.inputModel.displayValue;
        }
    };
    AddJobBlockWidgetComponent.prototype.selectedDescription = function (event) {
        this.form.get('jobDescriptionId').setValue(event);
        if (event) {
            this.form.get('displayValue').setValue(this.getDisplayValue(event));
        }
    };
    AddJobBlockWidgetComponent.prototype.getDisplayValue = function (jobDescId) {
        var selectedUserData = this.jobDescriptions.find(function (x) { return x.id == jobDescId; });
        console.log(selectedUserData);
        return selectedUserData['text'];
    };
    AddJobBlockWidgetComponent.prototype.save = function () {
        this.isFormSubmitted = true;
        var blockObject = tslib_1.__assign({}, this.blockObject);
        blockObject.model = new AddJobBlockModel(this.form.value);
        this.close.emit({
            action: 'save_and_close',
            data: blockObject
        });
    };
    return AddJobBlockWidgetComponent;
}());
export { AddJobBlockWidgetComponent };
