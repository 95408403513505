import { Subject } from "rxjs";
var PipelineEventListenerService = /** @class */ (function () {
    function PipelineEventListenerService() {
        this.triggerEvent = new Subject();
        this.triggerEvent$ = this.triggerEvent.asObservable();
    }
    PipelineEventListenerService.prototype.dispatchEvent = function (actionName, data) {
        if (data === void 0) { data = {}; }
        data['actionName'] = actionName;
        this.triggerEvent.next(data);
    };
    PipelineEventListenerService.CONSTANTS = {
        'itemExists': 'event:itemExists',
    };
    return PipelineEventListenerService;
}());
export { PipelineEventListenerService };
