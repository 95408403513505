import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from "@app/interfaces";
import { TimelineDatePipe } from "@app/pipes/timeline-date.pipe";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../pipes/timeline-date.pipe";
var JobsTabService = /** @class */ (function (_super) {
    tslib_1.__extends(JobsTabService, _super);
    function JobsTabService(http, timelineDateTime) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.timelineDateTime = timelineDateTime;
        _this.jobsTabHistory = '';
        _this.toasterMessage = '';
        _this.getViewsJobsList = function (id, params) {
            return this.http.get(this.getJobApiUrl("get_views_job_list/" + id, params));
        };
        _this.getJobsTabViews = function () {
            return this.http.get(this.getJobApiUrl("get_jobs_tab_views"));
        };
        _this.getViewsJobCount = function (teamId, viewId) {
            if (teamId && teamId > 0) {
                if (viewId && viewId > 0) {
                    return this.http.get(this.getJobApiUrl("get_team_view_count/" + teamId + "/" + viewId));
                }
                else {
                    return this.http.get(this.getJobApiUrl("get_team_views_count/" + teamId));
                }
            }
            else {
                if (viewId && viewId > 0) {
                    return this.http.get(this.getJobApiUrl("get_view_count/" + viewId));
                }
                else {
                    return this.http.get(this.getJobApiUrl("get_views_count"));
                }
            }
        };
        return _this;
    }
    JobsTabService.prototype.getJobTeamViews = function (id) {
        return this.http.get(this.getJobApiUrl("get_job_team_views/" + id));
    };
    ;
    JobsTabService.prototype.getjobsTabHistory = function () {
        return this.jobsTabHistory;
    };
    JobsTabService.prototype.setjobsTabHistory = function (data) {
        this.jobsTabHistory = data;
    };
    JobsTabService.prototype.convertJobDateTime = function (date) {
        return this.timelineDateTime.transform(date, 'datetime');
    };
    JobsTabService.prototype.setToasterMessage = function (data) {
        this.toasterMessage = data;
    };
    JobsTabService.prototype.getToasterMessage = function () {
        return this.toasterMessage;
    };
    JobsTabService.ngInjectableDef = i0.defineInjectable({ factory: function JobsTabService_Factory() { return new JobsTabService(i0.inject(i1.HttpClient), i0.inject(i2.TimelineDatePipe)); }, token: JobsTabService, providedIn: "root" });
    return JobsTabService;
}(InterfaceService));
export { JobsTabService };
