import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from "rxjs";
import { BsModalRef } from "ngx-bootstrap";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { TriggerDatasetService } from "@app/workflow-common/services/trigger-dataset.service";
import { pairwise } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
var TriggerRuleBuilderComponent = /** @class */ (function () {
    function TriggerRuleBuilderComponent(formBuilder, triggerService, modalRef, translate) {
        this.formBuilder = formBuilder;
        this.triggerService = triggerService;
        this.modalRef = modalRef;
        this.translate = translate;
        this.isFormSubmitted = false;
        this.fieldMetadata = {};
        this.eventsList = [];
        this.activeInputs = {};
        this.operators = {};
        this.selectedColName = [];
        this.select2FieldInstance = {};
        this.select2OperatorInstance = {};
        this.hasHelpText = false;
        this.select2OptionsEvents = {
            minimumResultsForSearch: 1
        };
        this.select2OptionsFields = {};
        this.select2DataFunction = function (item) { return item.text; };
    }
    TriggerRuleBuilderComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log('trigger-panel', this);
        this.onClose = new Subject();
        this.operators = this.triggerService.getOperators();
        var formData = {
            mainObjectName: this.objectName,
            triggerEvent: '',
            rules: [],
        };
        if (this.isEdit) {
            console.log('isEdit', this.dagSourceModel);
            var _a = this.dagSourceModel, blockObjects = _a.blockObjects, eventName = _a.eventName, mainObjectName = _a.mainObjectName;
            formData['triggerEvent'] = eventName;
            formData['mainObjectName'] = mainObjectName;
            var initBlock = blockObjects[this.dagSourceModel.getInitialBlockId()];
            formData['rules'] = initBlock.rules;
            this.onSelectEvent(eventName);
        }
        this.form = this.formBuilder.group({
            mainObjectName: new FormControl(formData['mainObjectName'], [Validators.required]),
            conditions: 'AND',
            triggerEvent: new FormControl(formData['triggerEvent'], [Validators.required]),
            rules: this.formBuilder.array([]),
        });
        if (formData['rules'].length) {
            formData['rules'].forEach(function (rule) {
                _this.addRule(rule);
            });
        }
        this.form.get('rules').valueChanges
            .pipe(pairwise())
            .subscribe(function (_a) {
            var prev = _a[0], next = _a[1];
            _this.selectedColName = next.map(function (item) { return item.col_name; });
        });
        this.prepareActiveInputs();
        this.deleteConfirmModalConfig = {
            showModal: false,
            modalType: 'confirm',
            title: 'Dummy',
            actionText: 'DELETE',
            itemText: 'DELETE',
            confirmBtnText: 'DELETE',
            confirmBtnType: 'DELETE',
            closeText: 'cancel',
            confirmBxValidationMsg: this.translate.instant('confirmation.warning.text', { word: 'DELETE', message: 'remove the automation' }),
            message: { warningTxt: 'Delete.Automation.Warning.Message', helpTxtList: ['Delete.Automation.Confirmation.Message'] },
            defaultTextMessage: false,
            doAction: this.removeRuleCondition.bind(this)
        };
    };
    TriggerRuleBuilderComponent.prototype.prepareActiveInputs = function () {
        var _this = this;
        if (this.form.get('mainObjectName').value != '') {
            this.fieldMetadata['fields'].forEach(function (field) {
                _this.activeInputs[field.col_name] = {
                    input: field.input,
                    type: field.type,
                    text: field.text,
                    operators: field.operators,
                    fieldType: 'static',
                    pageType: '',
                };
                if (field.hasOwnProperty('options') && field.options.length > 0) {
                    _this.activeInputs[field.col_name]['options'] = field.options;
                }
            });
        }
    };
    Object.defineProperty(TriggerRuleBuilderComponent.prototype, "f", {
        get: function () {
            return this.form.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TriggerRuleBuilderComponent.prototype, "formRules", {
        get: function () {
            return this.form.controls.rules;
        },
        enumerable: true,
        configurable: true
    });
    TriggerRuleBuilderComponent.prototype.addRule = function (ruleItem) {
        if (ruleItem === void 0) { ruleItem = null; }
        var formRules = this.form.controls.rules;
        var rule = {
            col_name: '',
            input: '',
            operator: 'equal',
            text: '',
            type: '',
            value: '',
            fieldType: 'static',
            pageType: '',
            templateId: '',
        };
        if (ruleItem) {
            rule['col_name'] = ruleItem['col_name'];
            rule['input'] = ruleItem['input'];
            rule['operator'] = ruleItem['operator'];
            rule['text'] = ruleItem['text'];
            rule['type'] = ruleItem['type'];
            rule['value'] = ruleItem['value'];
            rule['fieldType'] = !ruleItem['fieldType'] ? 'static' : ruleItem['fieldType'];
            rule['pageType'] = !ruleItem['pageType'] ? '' : ruleItem['pageType'];
            rule['templateId'] = !ruleItem['templateId'] ? '' : ruleItem['templateId'];
        }
        formRules.push(this.formBuilder.group({
            col_name: new FormControl(rule['col_name'], [Validators.required]),
            operator: new FormControl(rule.operator, [Validators.required]),
            value: new FormControl(rule.value, [Validators.required]),
            type: new FormControl(rule.type),
            input: new FormControl(rule.input),
            text: new FormControl(rule.text),
            fieldType: new FormControl(rule.fieldType),
            pageType: new FormControl(rule.pageType),
            templateId: new FormControl(rule.templateId)
        }));
    };
    TriggerRuleBuilderComponent.prototype.setSelect2FieldValueInstance = function (instance, index, isMultiple) {
        if (isMultiple === void 0) { isMultiple = false; }
        var keyName = isMultiple ? 'field_multiple_' + index : 'field_' + index;
        this.select2FieldInstance[keyName] = instance;
    };
    TriggerRuleBuilderComponent.prototype.setSelect2OperatorInstance = function (instance, index) {
        var keyName = 'field_' + index;
        this.select2OperatorInstance[keyName] = instance;
    };
    TriggerRuleBuilderComponent.prototype.save = function () {
        this.isFormSubmitted = true;
        var formData = this.form.value;
        if (formData['triggerEvent']) {
            this.dagSourceModel.setEventName(formData['triggerEvent']);
        }
        this.dagSourceModel.addTask(formData, 0);
        console.log('save', this.form.value);
        this.onClose.next(this.dagSourceModel);
    };
    TriggerRuleBuilderComponent.prototype.onChangeField = function (value, index) {
        var keyName = 'field_' + index;
        var formRules = this.formRules;
        console.log('onChangeField', value, index, this.selectedColName);
        formRules.controls[index].get('type').setValue(this.activeInputs[value]['type']);
        formRules.controls[index].get('input').setValue(this.activeInputs[value]['input']);
        formRules.controls[index].get('text').setValue(this.activeInputs[value]['text']);
        formRules.controls[index].get('fieldType').setValue(this.activeInputs[value]['fieldType']);
        formRules.controls[index].get('pageType').setValue(this.activeInputs[value]['pageType']);
        formRules.controls[index].get('operator').setValue(this.activeInputs[value]['operators'][0]);
        if (this.select2OperatorInstance.hasOwnProperty(keyName)) {
            this.select2OperatorInstance[keyName].select2('val', this.activeInputs[value]['operators'][0]);
        }
        formRules.controls[index].get('value').setValue('');
        if (this.select2FieldInstance.hasOwnProperty(keyName)) {
            this.select2FieldInstance[keyName].select2('val', '');
        }
    };
    TriggerRuleBuilderComponent.prototype.onChangeOperator = function (value, index) {
        var keyName = 'field_' + index;
        var formRules = this.formRules;
        formRules.controls[index].get('value').setValue('');
        if (value == 'in' || value == 'not_in') {
            keyName = 'field_' + index;
        }
        else {
            keyName = 'field_multiple_' + index;
        }
        if (this.select2FieldInstance.hasOwnProperty(keyName)) {
            this.select2FieldInstance[keyName].select2('destroy');
        }
    };
    TriggerRuleBuilderComponent.prototype.onSelectMultipleSelect = function (value, index) {
        var formRules = this.formRules;
        formRules.controls[index].get('value').setValue(value);
    };
    TriggerRuleBuilderComponent.prototype.onCloseModal = function () {
        this.modalRef.hide();
    };
    TriggerRuleBuilderComponent.prototype.onCloseConfirmation = function () {
        this.deleteConfirmModalConfig.showModal = false;
    };
    TriggerRuleBuilderComponent.prototype.onBeforeRemove = function (index) {
        var nthCondition = (index + 1);
        this.deleteConfirmModalConfig.title = this.translate.instant('Delete.Trigger.Condition', { nth: nthCondition });
        this.deleteConfirmModalConfig.confirmBxValidationMsg = this.translate.instant('confirmation.warning.text', { word: 'DELETE', message: 'remove the condition ' + nthCondition });
        this.deleteConfirmModalConfig.actionArg = index;
        this.deleteConfirmModalConfig.showModal = true;
    };
    TriggerRuleBuilderComponent.prototype.removeRuleCondition = function (deletableIndex, callBackFn) {
        var formRules = this.formRules;
        formRules.removeAt(deletableIndex);
        this.deleteConfirmModalConfig.showModal = false;
        callBackFn('SUCCESS');
    };
    TriggerRuleBuilderComponent.prototype.onSelectEvent = function (event) {
        var selectedEvent = event;
        this.selectedEvent = this.eventsList.find(function (x) { return x.id == selectedEvent; });
        if (this.selectedEvent.hasOwnProperty('helpText')) {
            this.hasHelpText = true;
            this.eventHelpText = this.selectedEvent.helpText;
        }
        else {
            this.hasHelpText = false;
        }
    };
    TriggerRuleBuilderComponent.prototype.ngOnDestroy = function () {
    };
    return TriggerRuleBuilderComponent;
}());
export { TriggerRuleBuilderComponent };
