import { InjectorInstance } from "@app/app.module";
import {HttpClient} from "@angular/common/http";
import { GetApiUrl } from "@app/utils";

export class CommonDataSource {
    static userRoles:Array<any> = []
    static allTagsList: {} = undefined;
    static stagesList: any[] = [];
    static activityTypes: any[] = [];
    static userData: {} = undefined;
    static userDetail: {} = undefined;
    static emailCommunicationData:{} = undefined;
    static contactsList: any[] = [
        {id: 'customer_contact', text: 'Customer contact'},
        {id: 'opportunity_customer_contact', text: 'Opportunity contact'},
        {id: 'opportunity_site_contact', text: 'Opportunity site contact'}
    ];
    static select2EmailDefaultConfig = {
        width: '100%',
        tags: true,
        createSearchChoice: (term: any, data: any) => {
            let exists: boolean = false;
            data.forEach((idText) => {
                if (idText.text == term)
                    exists = true;
            });
            let regex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$', 'g');
            return term.indexOf(' ') >= 0 || exists || !term.match(regex) ? null : {
                id: term + " (Create New)",
                text: term + " (Create New)"
            };
        },
        formatSelection: (item: any) => {
            return item.text.replace(" (Create New)", '');
        },
        formatResult: (item: any) => {
            return item.text;
        }
    };
    static clientDetails:{} = undefined;

    static () {
        console.log('CommonDataSource initialization');
    }

    static editorCommonConfig(onselect:Function, source:Function, mentionDenotationChars = ["@", "#", "["], toolbar:boolean=false) {
        return {
            mention: {
                mentionListClass: "ql-mention-list mat-elevation-z8",
                allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                showDenotationChar: true,
                spaceAfterInsert: true,
                onSelect: onselect,
                mentionDenotationChars: mentionDenotationChars,
                source: source,
            },
            toolbar: false
        };
    }

    static getEmailCommunicationData() {
        return new Promise( (resolve, reject) => {
            if(CommonDataSource.emailCommunicationData) {
                resolve(CommonDataSource.emailCommunicationData);
            }
            else {
                const httpClient = InjectorInstance.get<HttpClient>(HttpClient);
                httpClient.get(GetApiUrl(`workflow/communication/template/config`))
                .toPromise()
                .then( (resp) => {
                    if(resp.hasOwnProperty('data')) {
                        CommonDataSource.emailCommunicationData = resp['data'];
                        resolve(resp['data']);
                    }
                    else {
                        reject({error: true});
                    }
                }, (err) => {
                    console.error(err);
                    reject({error: true});
                });
            }
        });
    }

    static getUserRoles() {
        return new Promise( (resolve, reject) => {
            if(CommonDataSource.userRoles.length > 0) {
                resolve(CommonDataSource.userRoles);
            }
            else {
                const httpClient = InjectorInstance.get<HttpClient>(HttpClient);
                httpClient.get(GetApiUrl(`listSecurityRoles`, {limit: 100}))
                    .toPromise()
                    .then( (resp) => {
                        if(resp['roles']) {
                            CommonDataSource.userRoles = resp['roles'];
                            resolve(resp);
                        }
                        else {
                            reject({error: true});
                        }
                    }, (err) => {
                        console.error(err);
                        reject({error: true});
                    });
            }
        });
    }

    static getUserData() {
        return new Promise( (resolve, reject) => {
            if(CommonDataSource.userData) {
                resolve(CommonDataSource.userData);
            }
            else {
                const httpClient = InjectorInstance.get<HttpClient>(HttpClient);
                httpClient.get(GetApiUrl(`get_clientUsers`))
                .toPromise()
                .then( (resp) => {
                    if(resp['userRecords']) {
                        CommonDataSource.userData = resp;
                        resolve(resp);
                    }
                    else {
                        reject({error: true});
                    }
                }, (err) => {
                    console.error(err);
                    reject({error: true});
                });
            }
        });
    }
    static getUserDetail() {
        return new Promise( (resolve, reject) => {
            if(CommonDataSource.userDetail) {
                resolve(CommonDataSource.userDetail);
                console.log(CommonDataSource.userDetail,'1')
            }
            else {
                const httpClient = InjectorInstance.get<HttpClient>(HttpClient);
                httpClient.get(GetApiUrl(`get_client_users`))
                .toPromise()
                .then( (resp) => {
                    if(resp) {
                        CommonDataSource.userDetail = resp;
                        console.log(CommonDataSource.userDetail,'2')
                        resolve(resp);
                    }
                    else {
                        reject({error: true});
                    }
                }, (err) => {
                    console.error(err);
                    reject({error: true});
                });
            }
        });
    }

    static formattingSmsAddress(values, smsTagsList:any[] = [], usersList:any[]=[]) {
        if(!values) {
            return undefined;
        }
        const smsMobileVal:any[] = values;
        let newItemList:any[] = [];
        smsMobileVal.forEach( (value) => {
            if(value.indexOf('t_') === 0) {
                const matchedItem = smsTagsList.find( t => t.id == value );
                if(matchedItem) {
                    let displayName = matchedItem['displayText'] ? matchedItem['displayText'] : matchedItem['text'];
                    newItemList.push({type: "tag", value, displayName, tagName: matchedItem['text']});
                }
            }
            else if(value.indexOf('(Create New)') > -1) {
                newItemList.push({type: "normal", value: value.replace(" (Create New)", ''), displayName: value.replace(" (Create New)", '')});
            }
            else if (!isNaN(value)) {
                const matchedItem = usersList.find( t => t.id == value );
                if(matchedItem) {
                    newItemList.push({type: "user", value, displayName: matchedItem.text});
                }
            }
        });
        return newItemList;
    }

    static formattingEmailAddress(value, emailTagsList:any[]=[], usersList:any[]=[]) {
        const values = value;
        if(!values) {
            return undefined;
        }
        const emailAddressVal:any[] = values;
        let newItemList:any[] = [];
        emailAddressVal.forEach( (value) => {
            if(value.indexOf('t_') === 0) {
                //tags
                const matchedItem = emailTagsList.find( t => t.id == value );
                if(matchedItem) {
                    let displayName = matchedItem['displayText'] ? matchedItem['displayText'] : matchedItem['text'];
                    newItemList.push({type: "tag", value, displayName, tagName: matchedItem['text']});
                }
            }
            else if(value.indexOf('@') > -1) {
                //custom email
                newItemList.push({type: "normal", value: value.replace(" (Create New)", ''), displayName: value.replace(" (Create New)", '')});
            }
            else if (!isNaN(value)) {
                // user email
                const matchedItem = usersList.find( t => t.id == value );
                if(matchedItem) {
                    newItemList.push({type: "user", value, displayName: matchedItem.text});
                }
            }
        });
        return newItemList;
    }

    static getClientDetails() {
        return new Promise( (resolve, reject) => {
            if(CommonDataSource.clientDetails) {
                resolve(CommonDataSource.clientDetails);
            }
            else {
                const httpClient = InjectorInstance.get<HttpClient>(HttpClient);
                httpClient.get(GetApiUrl(`get_client_details`))
                .toPromise()
                .then( (resp) => {
                    if(resp['clientDetails']) {
                        CommonDataSource.clientDetails = resp['clientDetails'];
                        resolve(resp['clientDetails']);
                    }
                    else {
                        reject({error: true});
                    }
                }, (err) => {
                    console.error(err);
                    reject({error: true});
                });
            }
        });
    }

    static makeEmailAddressDropdownData(data:Array<any>) {
        let _inputs:Array<any> = [];
        let customList: Array<any> = [];
        if(data && data.length > 0) {
            data.forEach( (item) => {
                _inputs.push(item['value']);
                if(item['value'].indexOf('@') > 0) {
                    customList.push({
                        id: item['value'],
                        text: item['value'],
                        name: 'CUSTOM'
                    })
                }
            });
        }
        return {
            inputValue: _inputs,
            customList
        };
    }
    static makeUserDropdownData(data:Array<any>) {
        let _inputs:Array<any> = [];
        let customList: Array<any> = [];
        if(data && data.length > 0) {
            data.forEach( (item) => {
                _inputs.push(item['value']);
                if(!isNaN(item['value'])) {
                    customList.push({
                        id: item['value'],
                        text: item['value'],
                        name: 'CUSTOM'
                    })
                }
            });
        }
        return {
            inputValue: _inputs,
            customList
        };
    }

    static makeSmsAddressDropdownData(data:Array<any>) {
        let _inputs:Array<any> = [];
        let customList: Array<any> = [];
        if(data && data.length > 0) {
            data.forEach( (item) => {
                _inputs.push(item['value']);
                if(item['type'] == 'normal') {
                    customList.push({
                        id: item['value'] + ' (Create New)',
                        text: item['value'],
                        name: 'CUSTOM'
                    })
                }
            });
        }
        return {
            inputValue: _inputs,
            customList
        };
    }
}
