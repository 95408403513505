import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CsToastBoxService {
  private toastControlSource: BehaviorSubject<{
    action: 'show' | 'hide';
    message?: string;
    duration?: number;
  }> = new BehaviorSubject(null);
  toastControl$ = this.toastControlSource.asObservable();

  constructor() { }

  show(message: string, duration?: number) {
      if(!duration && message.length >= 100){
          if(message.length >= 200) {
              duration = 60000; //  display message for 1 minute if duration is not defined and the message length is > 200.
          } else {
              duration = 30000;   // display message for 30 seconds if duration is not defined and the message length is > 100 and < 200.
          }
      }
    this.toastControlSource.next({
      action: 'show',
      message: message,
      duration: duration
    })
  }

  hide() {
    this.toastControlSource.next({ action: 'hide' });
  }
}
