import { Injectable, EventEmitter, OnDestroy, Inject, OnInit } from '@angular/core';
import Handsontable from 'handsontable';
import { CsToastBoxService } from "@app/services";
import { HansontableSelect2 } from '@app/directives';
import { CommonService } from '@app/shared/services/common-service';
import * as moment from 'moment';
import {ProposalTemplateService} from "@app/features/system-settings/proposal-template/services/proposal-template.service";
import {CurrencyPipe} from '@app/pipes'

declare var _: any;

@Injectable({
    providedIn: 'root'
})

export class ProposalTemplatePricingSectionService implements OnDestroy {

    spreadsheetData: Array<any> = [];
    hotData: Array<any> = [];
    savechange: EventEmitter<object> = new EventEmitter();
    hotInstances = [];
    spreadSheetSettings = {};
    spreadSheetViewData = { 'items': [] };
    hotId: any;
    partsData: Array<any> = [];
    supplierList: Array<any> = [];
    labourRates: Array<any> = [];
    selectedPricingData = {};
    check: boolean = false;
    selectedValue: Array<any> = [];
    selectedInstanceData = {};
    navchange: EventEmitter<object> = new EventEmitter();
    deletechange: EventEmitter<object> = new EventEmitter();
    deleteKey: string = ""
    partsKitSelection: boolean = false;
    selectedHotIndex: any;
    selectedHotInstance: any;
    upsellEvent: EventEmitter<object> = new EventEmitter();
    baseSettings: any;
    total_values: {}
    expenses_total_values = {};
    selectedHideInstance: any;
    originalPartColumn: Array<any> = [];
    selectedPartsData: any;
    suppliers: Array<any> = []
    partArray: Array<any> = [];
    nominalCode: Array<any> = [];
    vatRates: Array<any> = [];
    selectedTableInstance: any;
    newPartName: EventEmitter<object> = new EventEmitter();
    notShowEmptyCell: boolean = false;
    supplierDataList: Array<any> = [];
    selectedColumn: any;
    selectedRowRef: any;
    taxLabel:any;
    vatEnabled: boolean = true;
    editedDataAdded: Array<any> = [];
    // customer parts column
    partsColumn: Array<any> = [
        {
            title: 'Part',
            data: 'partsAndPricesId',
            validator: 'emptyCellValidator',
            placeholder: 'Please select',
            allowEmpty: false,
            className: 'overflow_handsontable select_icon',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                this.selectedHotInstance = instance;
                let addUpsellClass = false;
                if (value) {
                    let selectedPart = this.partArray.find((item: any) => item.id == value);
                    let partName: string = '';
                    if (selectedPart) {
                        partName = selectedPart['text'];
                        let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                        if (selectedIndex != -1) {
                            this.spreadsheetData[selectedIndex]['data'][row].partsAndPricesId = selectedPart.id;
                            let isUpsellContain = this.spreadsheetData[selectedIndex]['data'][row]['isContainUpsell'];
                            if(isUpsellContain == "1" || isUpsellContain == true){
                                addUpsellClass = true;
                            }
                        }

                    }
                    value = partName
                }

                if (row == instance.countRows() - 1) {
                    cellProperties.readOnly = true;
                    cellProperties.readOnlyCellClassName = 'calculate-class';
                    cellProperties.className = 'customer-pricing';
                    cellProperties.placeholder = "";
                }
                if (row != instance.countRows() - 1) {
                    cellProperties.readOnly = false;
                    cellProperties.readOnlyCellClassName = "";
                    cellProperties.className = "";
                    cellProperties.placeholder = "Please select";
                }
                if(addUpsellClass){
                    cellProperties.className = cellProperties.className + " highlight-upsell-row";
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            },
            editor: HansontableSelect2({
                minimumInputLength: 2,
                dropdownCssClass: 'handsontable-select',
                dropdownAutoWidth: true,
                ajax: {
                    url: 'getPartsSupplierData',
                    data: (query, page) => {
                        if (query === '') {
                            return false;
                        }
                        return { 'searchText': encodeURIComponent(query) };
                    },
                    // On search ajax response
                    results: (data, page) => {
                        let previousPartId = false;
                        if(this.selectedRowRef && typeof this.selectedRowRef == 'object') {
                            previousPartId = this.selectedRowRef.partsAndPricesId;
                        }
                        this.partsData = data['parts']
                        let selectedParts = this.spreadsheetData.find(data => data.id == this.selectedTableInstance)
                        if (Object.keys(selectedParts).length != 0) {
                            if (selectedParts.hasOwnProperty('data')) {
                                var filtered = [];
                                data.parts.forEach(function (e) {
                                    if (!selectedParts['data'].some(s => s.partsAndPricesId == e.id && s.partsAndPricesId != previousPartId)) {
                                        filtered.push(e);
                                    }
                                });
                                this.spreadSheetViewData['items'] = filtered
                            } else {
                                this.spreadSheetViewData['items'] = data.parts;
                            }
                        } else {
                            this.spreadSheetViewData['items'] = data.parts;
                        }
                        return { results: this.spreadSheetViewData['items'] };
                    },
                },
                formatSelection: function (item) {
                    return item.text;
                },
                formatNoMatches: (term) => {
                    let that = this
                    $('.select2-no-results').css({ 'cursor': 'pointer' })
                    $('.select2-input').keyup(function (e) {
                        if (e.keyCode == 13) {
                            that.notShowEmptyCell = true
                            that.emitNewPartDetails(that, term)
                        }
                    });
                    $('#select2-drop').click(function (e) {
                        that.notShowEmptyCell = true
                        that.emitNewPartDetails(that, term)
                    })
                    return "Add new part: " + term;
                }
            }),
        },
        {
            title: 'Quantity',
            data: 'quantity',
            allowEmpty: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                let selectedParts = instance.getCopyableData(row, 0)
                this.selectedPartsData = this.partArray.find((i: any) => i.id == selectedParts)
                if (this.selectedPartsData) {
                    if (value == null) {
                        value = 1
                    }
                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                    if (selectedIndex != -1) {
                        this.spreadsheetData[selectedIndex]['data'][row].quantity = value
                    }
                    if (this.selectedPartsData.suppliersData.length != 0) {
                        this.selectedPartsData.suppliersData.forEach((el) => { el.selected = false, el.priceSelected = false })
                    }
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            }
        },
        {
            title: 'Markup',
            data: 'markup',
            allowEmpty: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                let selectedParts = instance.getCopyableData(row, 0)
                let selectedPartsData = this.partArray.find((i: any) => i.id == selectedParts)
                if (selectedPartsData) {
                    if (value == null) {
                        value = selectedPartsData.markup
                    }
                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                    if (selectedIndex != -1) {
                        this.spreadsheetData[selectedIndex]['data'][row].markup = value
                    }
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                if (td.innerHTML && value != 'N/A') {
                    td.innerHTML = td.innerHTML + '%'
                }
            },
        },
        {
            title: 'General price',
            data: 'generalPrice',
            allowEmpty: true,
            readOnly: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                let showSelected = false;
                let selectedParts = instance.getCopyableData(row, 0)
                let selectedPartsData = this.partArray.find((i: any) => i.id == selectedParts)
                if (selectedPartsData) {
                    let lowestSupplierPrice, selectedSuppliers = [];
                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                    /*if (this.spreadsheetData[selectedIndex]['suppliers'].length != 0) {
                        let listSupplierArray = this.spreadsheetData[selectedIndex]['suppliers'].map(supplier => parseInt(supplier.id));
                        if (listSupplierArray) {
                            selectedSuppliers = this.selectedPartsData.suppliersData.filter(supplier => listSupplierArray.includes(supplier.supplierId))
                        }
                        if (selectedSuppliers.length != 0) {
                            lowestSupplierPrice = selectedSuppliers.reduce(function (res, obj) {
                                return (parseFloat(obj.supplierPrice) < parseFloat(res.supplierPrice)) ? obj : res;
                            });
                            let index = this.selectedPartsData.suppliersData.findIndex(data => data.supplierId == lowestSupplierPrice.supplierId)
                            if (index != -1) {
                                this.selectedPartsData.suppliersData[index].selected = true
                            }
                        }
                    }*/
                    if (value == null) {
                        value = selectedPartsData.price
                    }
                    if (value) {
                        value = parseFloat(value).toFixed(2)
                    }
                    this.spreadsheetData[selectedIndex]['data'][row].generalPrice = value;
                    if(this.spreadsheetData[selectedIndex]['data'][row]['selectedPriceCol'] == prop) {
                        cellProperties.readOnlyCellClassName = "pricing-selected-bg";
                        showSelected = true;
                    } else {
                        cellProperties.readOnlyCellClassName = "";
                        cellProperties.className = "";
                    }
                    /*if (this.spreadsheetData[selectedIndex]['data'][row].supplierId) {
                        if (selectedSuppliers.length != 0) {
                            let selectedPrice = selectedSuppliers.find(i => i.supplierId == this.spreadsheetData[selectedIndex]['data'][row].supplierId)
                            if (selectedPrice) {
                                this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = selectedPrice.supplierPrice
                            } else {
                                this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = null
                                this.spreadsheetData[selectedIndex]['data'][row].supplierId = null
                            }
                        }
                        cellProperties.readOnlyCellClassName = ""
                        cellProperties.className = ""
                    }
                    else {
                        this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = this.selectedPartsData.price
                        cellProperties.readOnlyCellClassName = "pricing-selected-bg"
                        showSelected = true
                    }*/
                    /*if (this.spreadsheetData[selectedIndex]['data'][row].hasOwnProperty('lowestPrice') && this.spreadsheetData[selectedIndex]['data'][row]['lowestPrice']) {
                        this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = this.spreadsheetData[selectedIndex]['data'][row].lowestPrice
                        this.spreadsheetData[selectedIndex]['data'][row].supplierId = this.spreadsheetData[selectedIndex]['data'][row].supplierId
                        let supplierIndex = this.selectedPartsData.suppliersData.findIndex(data => data.supplierId == this.spreadsheetData[selectedIndex]['data'][row].supplierId)
                        if (supplierIndex != -1) {
                            this.selectedPartsData.suppliersData[supplierIndex].priceSelected = true
                        }
                    } else if (lowestSupplierPrice) {
                        if (parseFloat(lowestSupplierPrice.supplierPrice) < parseFloat(this.selectedPartsData.price)) {
                            this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = lowestSupplierPrice.supplierPrice
                            this.spreadsheetData[selectedIndex]['data'][row].supplierId = lowestSupplierPrice.supplierId
                            let supplierIndex = this.selectedPartsData.suppliersData.findIndex(data => data.supplierId == lowestSupplierPrice.supplierId)
                            if (supplierIndex != -1) {
                                this.selectedPartsData.suppliersData[supplierIndex].priceSelected = true
                            }
                        } else {
                            this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = this.selectedPartsData.price
                            showSelected = true
                            cellProperties.readOnlyCellClassName = "pricing-selected-bg"
                        }
                    } else {
                        this.spreadsheetData[selectedIndex]['data'][row].supplierId = null
                        this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = this.selectedPartsData.price
                        cellProperties.readOnlyCellClassName = "pricing-selected-bg"
                        showSelected = true
                    }*/
                } else {
                    cellProperties.readOnlyCellClassName = ""
                    cellProperties.className = ""
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                if (td.innerHTML) {
                    let new_value = "£" + td.innerHTML
                    td.innerHTML = ""
                    let label: HTMLElement = document.createElement('label');
                    label.className = 'checkbox-button';
                    label.style.cursor = 'pointer';
                    let inputElement: HTMLInputElement = document.createElement('input');
                    inputElement.type = "checkbox"
                    inputElement.name = prop + row + col
                    inputElement.id = prop + row + col
                    if (showSelected) {
                        inputElement.checked = true
                    }
                    if (inputElement.checked) {
                        inputElement.disabled = true
                    }
                    inputElement.className = "checkbox-button__input"
                    Handsontable.dom.addEvent(inputElement, 'click', ($event) => {
                        this.selectPrice($event, instance, row, col);
                    });
                    label.appendChild(inputElement);
                    let content: HTMLElement = document.createElement('span');
                    content.className = "checkbox-button__control"
                    label.appendChild(content)
                    let priceValue: HTMLElement = document.createElement('span');
                    priceValue.innerText = new_value
                    label.appendChild(priceValue)
                    td.appendChild(label);
                }
            },
        },
        {
            title: 'Unit sale price',
            data: 'unitSalePrice',
            allowEmpty: true,
            type: 'numeric',
            validator: 'numericValidator',
            readOnly: false,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (value == null) {
                    value = 0;
                    let selectedParts = instance.getCopyableData(row, 0);
                    if(selectedParts) {
                        this.selectedPartsData = this.partArray.find((i: any) => i.id == selectedParts);
                        value = this.selectedPartsData.unitSalePrice;
                    }
                }
                if (value) {
                    value = parseFloat(value).toFixed(2)
                }

                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);

                if (row == instance.countRows() - 2) {
                    if (!value) {
                        value = "";
                    }
                    cellProperties.readOnlyCellClassName = "calculate-bg";
                    //Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                }

                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                if (td.innerHTML) {
                    td.innerHTML = "£" + td.innerHTML
                }
            },
        },
        {
            title: 'Total cost',
            data: 'totalCost',
            allowEmpty: true,
            type: 'numeric',
            validator: 'numericValidator',
            readOnly: true,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            if (Object.keys(this.selectedValue[row]).length != 0) {
                                if (this.selectedValue[row].hasOwnProperty('selectedPriceCol')) {
                                    let quantity = 1
                                    if (this.selectedValue[row].hasOwnProperty('quantity')) {
                                        if (this.selectedValue[row]['quantity']) {
                                            quantity = parseFloat(this.selectedValue[row]['quantity'])
                                        } else {
                                            quantity = 1
                                        }
                                    }
                                    let priceColeName = this.selectedValue[row]['selectedPriceCol'];
                                    let unitCost = this.selectedValue[row][priceColeName];
                                    let totalCalculated = 0;
                                    if (unitCost) {
                                        totalCalculated = this.getTotalCost(unitCost, quantity);
                                    } else {
                                        totalCalculated = this.getTotalCost(this.selectedValue[row]['generalPrice'], quantity);
                                    }
                                    value = this.convertAndUpdatePrice(totalCalculated);
                                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                    if (selectedIndex != -1) {
                                        this.spreadsheetData[selectedIndex]['data'][row].totalCost = value;
                                    }
                                    cellProperties.readOnlyCellClassName = "htDimmed"
                                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                            if (row == instance.countRows() - 1) {
                                let costTotal = 0;
                                for (var j = 0; j < this.selectedValue.length; j++) {
                                    let quantity = 1
                                    if (this.selectedValue[j].hasOwnProperty('quantity')) {
                                        if (parseFloat(this.selectedValue[j]['quantity'])) {
                                            quantity = parseFloat(this.selectedValue[j]['quantity'])
                                        } else {
                                            quantity = 1
                                        }
                                    }
                                    if (this.selectedValue[j].hasOwnProperty('selectedPriceCol')) {
                                        let priceColeName = this.selectedValue[j]['selectedPriceCol'];
                                        let unitCost = this.selectedValue[j][priceColeName];
                                        let tempTotal = 0;
                                        if (unitCost) {
                                            tempTotal = this.getTotalCost(unitCost, quantity);
                                        } else {
                                            tempTotal = this.getTotalCost(this.selectedValue[j]['generalPrice'], quantity);
                                        }
                                        costTotal += tempTotal;
                                    }

                                    /*if (this.selectedValue[j].hasOwnProperty('lowestPrice')) {
                                        if (this.selectedValue[j]['lowestPrice'] != null) {
                                            costTotal += parseFloat(this.selectedValue[j]['lowestPrice']) * quantity
                                        }
                                    }*/
                                }
                                value = costTotal.toFixed(2)
                                cellProperties.readOnlyCellClassName = "price-total-bg"
                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                            }
                        }
                    }
                }
                if (row == instance.countRows() - 2) {
                    if (!value) {
                        value = ""
                    }
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                } else {
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                if (td.innerHTML) {
                    td.innerHTML = "£" + td.innerHTML
                }
            },
        },
        {
            title: 'Total sale price',
            data: 'totalSalePrice',
            allowEmpty: true,
            type: 'numeric',
            validator: 'numericValidator',
            readOnly: true,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            if (Object.keys(this.selectedValue[row]).length != 0) {
                                if (this.selectedValue[row].hasOwnProperty('unitSalePrice')) {
                                    let quantity = 1
                                    if (this.selectedValue[row].hasOwnProperty('quantity')) {
                                        if (this.selectedValue[row]['quantity']) {
                                            quantity = parseFloat(this.selectedValue[row]['quantity'])
                                        } else {
                                            quantity = 1
                                        }
                                    }
                                    if (this.selectedValue[row]['unitSalePrice'] != null) {
                                        value = (parseFloat(this.selectedValue[row]['unitSalePrice']) * quantity).toFixed(2);
                                    }
                                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                    if (selectedIndex != -1) {
                                        this.spreadsheetData[selectedIndex]['data'][row].totalSalePrice = value
                                    }
                                    cellProperties.readOnlyCellClassName = "htDimmed"
                                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                            if (row == instance.countRows() - 1) {
                                let saleTotal = 0;
                                for (var j = 0; j < this.selectedValue.length; j++) {
                                    let quantity = 1
                                    if (this.selectedValue[j].hasOwnProperty('quantity')) {
                                        if (parseFloat(this.selectedValue[j]['quantity'])) {
                                            quantity = parseFloat(this.selectedValue[j]['quantity'])
                                        } else {
                                            quantity = 1
                                        }
                                    }
                                    if (this.selectedValue[j].hasOwnProperty('unitSalePrice')) {
                                        if (this.selectedValue[j]['unitSalePrice']) {
                                            saleTotal += (parseFloat(this.selectedValue[j]['unitSalePrice'])) * quantity;
                                        }
                                    }
                                }
                                value = saleTotal.toFixed(2)
                                cellProperties.readOnlyCellClassName = "price-total-bg"
                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                            }
                        }
                    }
                }
                if (row == instance.countRows() - 2) {
                    if (!value) {
                        value = ""
                    }
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                } else {
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                if (td.innerHTML) {
                    td.innerHTML = "£" + td.innerHTML
                }
            },
        },
        {
            title: 'Discount',
            data: 'discount',
            type: 'numeric',
            allowEmpty: true,
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                            if (selectedIndex != -1) {
                                if (value == null && Object.keys(this.spreadsheetData[selectedIndex]['data'][row]).length != 0) {
                                    value = "0.00"
                                }
                                if (value != null) {
                                    value = parseFloat(value).toFixed(2)
                                    this.spreadsheetData[selectedIndex]['data'][row].discount = value
                                }
                                cellProperties.readOnly = false
                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                            }
                            if (row == instance.countRows() - 1) {
                                let discount = 0;
                                for (var j = 0; j < this.selectedValue.length; j++) {
                                    if (this.selectedValue[j].hasOwnProperty('discount') && this.selectedValue[j]['discount']) {
                                        discount = (parseFloat(this.selectedValue[j]['discount']) + discount)
                                    }
                                }
                                value = discount.toFixed(2)
                                cellProperties.readOnlyCellClassName = "price-total-bg"
                                cellProperties.readOnly = true
                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                            }
                        }
                    }
                }
                if (row == instance.countRows() - 2) {
                    if (!value) {
                        value = ""
                    }
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                } else {
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                if (td.innerHTML) {
                    td.innerHTML = "£" + td.innerHTML
                }
            }
        },
        {
            title: 'Final sale price',
            data: 'finalSalePrice',
            allowEmpty: true,
            readOnly: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            if (Object.keys(this.selectedValue[row]).length != 0) {
                                if (this.selectedValue[row]['totalSalePrice']) {
                                    if (this.selectedValue[row]['discount']) {
                                        value = this.selectedValue[row]['totalSalePrice'] - this.selectedValue[row]['discount']
                                    }
                                    else {
                                        value = this.selectedValue[row]['totalSalePrice']
                                    }
                                    if (value) {
                                        value = parseFloat(value).toFixed(2)
                                    }
                                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                    if (selectedIndex != -1) {
                                        this.spreadsheetData[selectedIndex]['data'][row].finalSalePrice = value
                                    }
                                    cellProperties.readOnlyCellClassName = "htDimmed"
                                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                            if (row == instance.countRows() - 1) {
                                let saleFinalPrice = 0;
                                for (var j = 0; j < this.selectedValue.length; j++) {
                                    if (this.selectedValue[j].hasOwnProperty('finalSalePrice') && this.selectedValue[j]['finalSalePrice']) {
                                        saleFinalPrice = (parseFloat(this.selectedValue[j]['finalSalePrice']) + saleFinalPrice)
                                    }
                                }
                                value = saleFinalPrice.toFixed(2)
                                cellProperties.readOnlyCellClassName = "price-total-bg"
                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                            }
                        }
                    }
                }
                if (row == instance.countRows() - 2) {
                    if (!value) {
                        value = ""
                    }
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                } else {
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                if (td.innerHTML) {
                    td.innerHTML = "£" + td.innerHTML
                }
            },
        },
        {
            title: 'Profit',
            data: 'profit',
            allowEmpty: true,
            type: 'numeric',
            validator: 'numericValidator',
            readOnly: true,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            if (Object.keys(this.selectedValue[row]).length != 0) {
                                if (this.selectedValue[row].hasOwnProperty('finalSalePrice') && this.selectedValue[row].hasOwnProperty('totalCost')) {
                                    let quantity = 1
                                    if (this.selectedValue[row].hasOwnProperty('quantity')) {
                                        quantity = parseFloat(this.selectedValue[row]['quantity'])
                                    }
                                    if (this.selectedValue[row]['finalSalePrice'] != null && this.selectedValue[row]['totalCost'] != null) {
                                        value = (parseFloat(this.selectedValue[row]['finalSalePrice']) - parseFloat(this.selectedValue[row]['totalCost'])).toFixed(2)
                                    }
                                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                    if (selectedIndex != -1) {
                                        this.spreadsheetData[selectedIndex]['data'][row].profit = value
                                    }
                                    if (value != null){
                                        if (value > 0) {
                                            cellProperties.readOnlyCellClassName = "pricing-section-margin";
                                        } else if (value == 0) {
                                            cellProperties.readOnlyCellClassName = "calculate-bg";
                                        } else if (value < 0 || !value) {
                                            cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                                        }
                                        value = parseFloat(value).toFixed(2);
                                    }
                                    // cellProperties.readOnlyCellClassName = "htDimmed"
                                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                            if (row == instance.countRows() - 1) {
                                let profitTotal = 0;
                                for (var j = 0; j < this.selectedValue.length; j++) {
                                    if (this.selectedValue[j].hasOwnProperty('profit')) {
                                        if (this.selectedValue[j]['profit'] != null) {
                                            profitTotal += parseFloat(this.selectedValue[j]['profit'])
                                        }
                                    }
                                }
                                value = profitTotal.toFixed(2)
                                if (value != null){
                                    if (value > 0) {
                                        cellProperties.readOnlyCellClassName = "pricing-section-margin";
                                    } else if (value == 0) {
                                        cellProperties.readOnlyCellClassName = "calculate-bg";
                                    } else if (value < 0 || !value) {
                                        cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                                    }
                                    value = parseFloat(value).toFixed(2);
                                }
                                // cellProperties.readOnlyCellClassName = "price-total-bg"
                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                            }
                        }
                    }
                }
                if (row == instance.countRows() - 2) {
                    if (!value) {
                        value = ""
                    }
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                }
                /*else {
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                }*/
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                if (td.innerHTML) {
                    td.innerHTML = "£" + td.innerHTML
                }
            },
        },
        {
            title: 'Margin',
            data: 'margin',
            allowEmpty: true,
            type: 'numeric',
            validator: 'numericValidator',
            readOnly: true,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            if (Object.keys(this.selectedValue[row]).length != 0) {
                                if (this.selectedValue[row].hasOwnProperty('profit')) {
                                    if (this.selectedValue[row]['profit'] != null) {
                                        let profit = parseInt(this.selectedValue[row]['profit']);
                                        if (profit && parseFloat(this.selectedValue[row]['finalSalePrice']) > 0) {
                                            value = ((parseFloat(this.selectedValue[row]['profit']) / parseFloat(this.selectedValue[row]['finalSalePrice'])) * 100).toFixed(2);
                                        } else {
                                            value = profit.toFixed(2)
                                        }
                                        if(value < 0){
                                            value = 0
                                        }
                                        let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                        if (selectedIndex != -1) {
                                            this.spreadsheetData[selectedIndex]['data'][row].margin = value
                                        }
                                        Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                    }
                                }
                            }
                        }
                    }
                }
                if (row == instance.countRows() - 1) {
                    cellProperties.readOnlyCellClassName = "calculate-class-margin"
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                } else if (row == instance.countRows() - 2) {
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                } else if (row != instance.countRows() - 1) {
                    cellProperties.readOnlyCellClassName = ""
                    td.className = ""
                    if (value > 0) {
                        cellProperties.readOnlyCellClassName = "pricing-section-margin"
                    } else if (value == 0) {
                        cellProperties.readOnlyCellClassName = "calculate-bg"
                    } else if (value < 0) {
                        cellProperties.readOnlyCellClassName = "pricing-section-margin-loss"
                    }
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                }
                if (td.innerHTML) {
                    td.innerHTML = td.innerHTML + "%"
                }
            },
        },
        {
            title: 'Labour time',
            data: 'labourTime',
            allowEmpty: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (row == instance.countRows() - 1) {
                    cellProperties.readOnly = true;
                    cellProperties.readOnlyCellClassName = 'calculate-class-margin'
                    cellProperties.className = ""
                    cellProperties.placeholder = ""
                }
                if (row != instance.countRows() - 1) {
                    cellProperties.readOnly = false;
                    cellProperties.readOnlyCellClassName = ""
                }
                if (this.selectedPartsData) {
                    if (value == null) {
                        value = this.selectedPartsData.labourTime
                    }
                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                    if (selectedIndex != -1) {
                        this.spreadsheetData[selectedIndex]['data'][row].labourTime = value
                    }
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            },
        },
        {
            title: 'Labour rate',
            data: 'labourRatesId',
            validator: 'emptyCellValidator',
            placeholder: 'Please select',
            allowEmpty: false,
            className: 'overflow_handsontable select_icon',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (value) {
                    let selectedLabourRate = this.labourRates.find((item: any) => item.id == value);
                    let labourRate: string = '';
                    if (selectedLabourRate) {
                        labourRate = selectedLabourRate['description'];
                    }
                    value = labourRate
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            },
            editor: HansontableSelect2({
                minimumInputLength: 2,
                dropdownCssClass: 'handsontable-select',
                dropdownAutoWidth: true,
                ajax: {
                    url: 'sales/get_labour_rates',
                    data: (query, page) => {
                        if (query === '') {
                            return false;
                        }
                        return { 'searchText': encodeURIComponent(query) };
                    },
                    // On search ajax response
                    results: (data, page) => {
                        this.spreadSheetViewData['items'] = []
                        for (var i = 0; i < data.labourRates.length; i++) {
                            this.spreadSheetViewData['items'].push({ 'id': data.labourRates[i]['id'], 'text': data.labourRates[i]['description'] })
                        }
                        return { results: this.spreadSheetViewData['items'] };
                    },
                },
                formatSelection: function (item) {
                    return item.text;
                }
            }),
        },
        {
            title: 'Actions',
            readOnly: true,
            disableVisualSelection: true,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                let selectedInstance = this.spreadsheetData.find(data => data.id == instance['hotId'])
                if (selectedInstance) {
                    if (Object.keys(selectedInstance).length != 0) {
                        let selectedValue = selectedInstance['data']
                        if(!selectedValue.hasOwnProperty('isUpsell') || selectedValue[row]['isUpsell'] == "False" || selectedValue[row]['isUpsell'] == false){
                            let link: HTMLAnchorElement = document.createElement('a');
                            let type = 'add';
                            link.innerText = "Upsell";
                            if(selectedValue[row].hasOwnProperty('isContainUpsell') && selectedValue[row]['isContainUpsell'] == "1"){
                                link.innerText = "Edit";
                                type = 'edit'
                            }
                            if (selectedValue && Object.keys(selectedValue[row]).length) {
                                link.style.cursor = 'pointer';
                            } else {
                                link.style.cursor = "default";
                            }
                            if (selectedValue && Object.keys(selectedValue[row]).length) {
                                Handsontable.dom.addEvent(link, 'click', () => {
                                    this.Upsell(instance, row, col, type);
                                });
                            }
                            Handsontable.dom.empty(td);
                            td.style.borderRight = '0px solid'
                            let content: HTMLDivElement = document.createElement('div');
                            content.appendChild(link);
                            td.appendChild(content);
                        }
                        if (row == instance.countRows() - 2) {
                            td.innerHTML = '';
                        }
                    }
                }
            }
        },
        {
            title: 'Actions',
            readOnly: true,
            disableVisualSelection: false,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (row == instance.countRows() - 2) {
                    td.innerHTML = '';
                } else {
                    let link: HTMLAnchorElement = document.createElement('a');
                    link.innerText = 'Delete';
                    link.style.cursor = 'pointer';
                    Handsontable.dom.addEvent(link, 'click', () => {
                        this.deleteKey = 'parts'
                        this.onRowDelete(instance, row, this.deleteKey);
                    });
                    Handsontable.dom.empty(td);
                    let content: HTMLDivElement = document.createElement('div');
                    content.appendChild(link);
                    td.appendChild(content);
                }

            }
        }
    ];

    // Option and proposal template parts column
    systemSettingPartsColumn: Array<any> = [
        {
            title: 'Part',
            data: 'partsAndPricesId',
            validator: 'emptyCellValidator',
            placeholder: 'Please select',
            allowEmpty: false,
            className: 'overflow_handsontable select_icon',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                this.selectedHotInstance = instance
                let addUpsellClass = false;
                if (value) {
                    let selectedPart = this.partArray.find((item: any) => item.id == value);
                    let partName: string = '';
                    if (selectedPart) {
                        partName = selectedPart['text'];
                        let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                        let isUpsellContain = this.spreadsheetData[selectedIndex]['data'][row]['isContainUpsell'];
                        if (selectedIndex != -1) {
                            this.spreadsheetData[selectedIndex]['data'][row].partsAndPricesId = selectedPart.id
                            if(isUpsellContain == "1" || isUpsellContain == true){
                                addUpsellClass = true;
                            }
                        }
                    }
                    value = partName

                }
                if (row == instance.countRows()) {
                    cellProperties.readOnly = true;
                    cellProperties.readOnlyCellClassName = 'calculate-class';
                    cellProperties.className = 'customer-pricing';
                    cellProperties.placeholder = "";
                }
                if (row != instance.countRows()) {
                    cellProperties.readOnly = false;
                    cellProperties.readOnlyCellClassName = "";
                    cellProperties.className = "";
                    cellProperties.placeholder = "Please select";
                }
                if(addUpsellClass){
                    cellProperties.className = cellProperties.className + " highlight-upsell-row";
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            },
            editor: HansontableSelect2({
                minimumInputLength: 2,
                dropdownCssClass: 'handsontable-select',
                dropdownAutoWidth: true,
                ajax: {
                    url: 'getPartsSupplierData',
                    data: (query, page) => {
                        if (query === '') {
                            return false;
                        }
                        return { 'searchText': encodeURIComponent(query) };
                    },
                    // On search ajax response
                    results: (data, page) => {
                        let previousPartId = false;
                        if(this.selectedRowRef && typeof this.selectedRowRef == 'object') {
                            previousPartId = this.selectedRowRef.partsAndPricesId;
                        }
                        this.partsData = data['parts']
                        let selectedParts = this.spreadsheetData.find(data => data.id == this.selectedTableInstance)
                        if (Object.keys(selectedParts).length != 0) {
                            if (selectedParts.hasOwnProperty('data')) {
                                var filtered = [];
                                data.parts.forEach(function (e) {
                                    if (!selectedParts['data'].some(s => s.partsAndPricesId == e.id && s.partsAndPricesId != previousPartId)) {
                                        filtered.push(e);
                                    }
                                });
                                this.spreadSheetViewData['items'] = filtered
                            } else {
                                this.spreadSheetViewData['items'] = data.parts;
                            }
                        } else {
                            this.spreadSheetViewData['items'] = data.parts;
                        }
                        return { results: this.spreadSheetViewData['items'] };
                    },
                },
                formatSelection: function (item) {
                    return item.text;
                },
                formatNoMatches: (term) => {
                    let that = this
                    $('.select2-no-results').css({ 'cursor': 'pointer' })
                    $('.select2-input').keyup(function (e) {
                        if (e.keyCode == 13) {
                            that.notShowEmptyCell = true
                            that.emitNewPartDetails(that, term)
                        }
                    });
                    $('#select2-drop').click(function (e) {
                        that.notShowEmptyCell = true
                        that.emitNewPartDetails(that, term)
                    })
                    return "Add new part: " + term;
                }
            }),
        },
        {
            title: 'Quantity',
            data: 'quantity',
            allowEmpty: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                let selectedParts = instance.getCopyableData(row, 0)
                this.selectedPartsData = this.partArray.find((i: any) => i.id == selectedParts)
                if (this.selectedPartsData) {
                    if (value == null) {
                        value = 1
                    }
                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                    if (selectedIndex != -1) {
                        this.spreadsheetData[selectedIndex]['data'][row].quantity = value
                    }
                    if (this.selectedPartsData.suppliersData.length != 0) {
                        this.selectedPartsData.suppliersData.forEach((el) => { el.selected = false, el.priceSelected = false })
                    }
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            }
        },
        /*{
            title: 'Unit price',
            data: 'costPrice',
            allowEmpty: true,
            readOnly: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                let showSelected = false;
                let selectedParts = instance.getCopyableData(row, 0);
                this.selectedPartsData = this.partArray.find((i: any) => i.id == selectedParts);
                if (this.selectedPartsData) {
                    let lowestSupplierPrice, selectedSuppliers = [];
                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                    if (this.spreadsheetData[selectedIndex]['suppliers'].length != 0) {
                        let listSupplierArray = this.spreadsheetData[selectedIndex]['suppliers'].map(supplier => parseInt(supplier.id));
                        if (listSupplierArray) {
                            selectedSuppliers = this.selectedPartsData.suppliersData.filter(supplier => listSupplierArray.includes(supplier.supplierId))
                        }
                        if (selectedSuppliers.length != 0) {
                            lowestSupplierPrice = selectedSuppliers.reduce(function (res, obj) {
                                return (parseFloat(obj.supplierPrice) < parseFloat(res.supplierPrice)) ? obj : res;
                            });
                            let index = this.selectedPartsData.suppliersData.findIndex(data => data.supplierId == lowestSupplierPrice.supplierId)
                            if (index != -1) {
                                this.selectedPartsData.suppliersData[index].selected = true
                            }
                        }
                    }
                    if (value == null || value != this.selectedPartsData.price) {
                        value = this.selectedPartsData.price;
                    }
                    if (value) {
                        value = parseFloat(value).toFixed(2)
                    }
                    this.spreadsheetData[selectedIndex]['data'][row].costPrice = value;
                    if(this.spreadsheetData[selectedIndex]['data'][row]['selectedPriceCol'] == prop) {
                        cellProperties.readOnlyCellClassName = "pricing-selected-bg";
                        showSelected = true;
                    } else {
                        cellProperties.readOnlyCellClassName = "";
                        cellProperties.className = "";
                    }
                    /!*if (this.spreadsheetData[selectedIndex]['data'][row].supplierId) {
                        if (selectedSuppliers.length != 0) {
                            let selectedPrice = selectedSuppliers.find(i => i.supplierId == this.spreadsheetData[selectedIndex]['data'][row].supplierId)
                            if (selectedPrice) {
                                this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = selectedPrice.supplierPrice
                            } else {
                                this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = null
                                this.spreadsheetData[selectedIndex]['data'][row].supplierId = null
                            }
                        }
                        cellProperties.readOnlyCellClassName = ""
                        cellProperties.className = ""
                    }
                    else {
                        this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = this.selectedPartsData.price
                        cellProperties.readOnlyCellClassName = "pricing-selected-bg"
                        showSelected = true
                    }*!/
                    /!*if (this.spreadsheetData[selectedIndex]['data'][row].hasOwnProperty('lowestPrice') && this.spreadsheetData[selectedIndex]['data'][row]['lowestPrice']) {
                        this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = this.spreadsheetData[selectedIndex]['data'][row].lowestPrice
                        this.spreadsheetData[selectedIndex]['data'][row].supplierId = this.spreadsheetData[selectedIndex]['data'][row].supplierId
                        let supplierIndex = this.selectedPartsData.suppliersData.findIndex(data => data.supplierId == this.spreadsheetData[selectedIndex]['data'][row].supplierId)
                        if (supplierIndex != -1) {
                            this.selectedPartsData.suppliersData[supplierIndex].priceSelected = true
                        }
                    } else if (lowestSupplierPrice) {
                        if (parseFloat(lowestSupplierPrice.supplierPrice) < parseFloat(this.selectedPartsData.price)) {
                            this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = lowestSupplierPrice.supplierPrice
                            this.spreadsheetData[selectedIndex]['data'][row].supplierId = lowestSupplierPrice.supplierId
                            let supplierIndex = this.selectedPartsData.suppliersData.findIndex(data => data.supplierId == lowestSupplierPrice.supplierId)
                            if (supplierIndex != -1) {
                                this.selectedPartsData.suppliersData[supplierIndex].priceSelected = true
                            }
                        } else {
                            this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = this.selectedPartsData.price
                            showSelected = true
                            cellProperties.readOnlyCellClassName = "pricing-selected-bg"
                        }
                    } else {
                        this.spreadsheetData[selectedIndex]['data'][row].supplierId = null
                        this.spreadsheetData[selectedIndex]['data'][row].lowestPrice = this.selectedPartsData.price
                        cellProperties.readOnlyCellClassName = "pricing-selected-bg"
                        showSelected = true
                    }*!/
                } else {
                    cellProperties.readOnlyCellClassName = ""
                    cellProperties.className = ""
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                if (td.innerHTML) {
                    let new_value = "£" + td.innerHTML
                    td.innerHTML = ""
                    let label: HTMLElement = document.createElement('label');
                    label.className = 'checkbox-button';
                    label.style.cursor = 'pointer';
                    let inputElement: HTMLInputElement = document.createElement('input');
                    inputElement.type = "checkbox"
                    inputElement.name = prop + row + col
                    inputElement.id = prop + row + col
                    if (showSelected) {
                        inputElement.checked = true
                    }
                    if (inputElement.checked) {
                        inputElement.disabled = true
                    }
                    inputElement.className = "checkbox-button__input"
                    Handsontable.dom.addEvent(inputElement, 'click', ($event) => {
                        this.selectPrice($event, instance, row, col);
                    });
                    label.appendChild(inputElement);
                    let content: HTMLElement = document.createElement('span');
                    content.className = "checkbox-button__control"
                    label.appendChild(content)
                    let priceValue: HTMLElement = document.createElement('span');
                    priceValue.innerText = new_value
                    label.appendChild(priceValue)
                    td.appendChild(label);
                }
            },
        },
        {
            title: 'Total cost',
            data: 'totalCost',
            allowEmpty: true,
            readOnly: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) { //Totalcalulate from SS
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            if (Object.keys(this.selectedValue[row]).length != 0) {
                                if (this.selectedValue[row].hasOwnProperty('selectedPriceCol')) {
                                    let quantity = 1
                                    if (this.selectedValue[row].hasOwnProperty('quantity')) {
                                        if (this.selectedValue[row]['quantity']) {
                                            quantity = this.selectedValue[row]['quantity']
                                        } else {
                                            quantity = 1
                                        }
                                    }

                                    let priceColeName = this.selectedValue[row]['selectedPriceCol'];
                                    let unitCost = this.selectedValue[row][priceColeName];
                                    let totalCalculated = 0;
                                    if (unitCost) {
                                        totalCalculated = this.getTotalCost(unitCost, quantity);
                                    } else {
                                        totalCalculated = this.getTotalCost(this.selectedValue[row]['generalPrice'], quantity);
                                    }
                                    value = this.convertAndUpdatePrice(totalCalculated);

                                    /!*if (this.selectedValue[row]['lowestPrice'] != null) {
                                        value = (quantity * parseFloat(this.selectedValue[row]['lowestPrice'])).toFixed(2)
                                    }*!/
                                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                    if (selectedIndex != -1) {
                                        this.spreadsheetData[selectedIndex]['data'][row].totalCost = value;
                                    }
                                    cellProperties.readOnlyCellClassName = "htDimmed";
                                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                            if (row == instance.countRows() - 1) {
                                let costTotal = 0;
                                for (var j = 0; j < this.selectedValue.length; j++) {
                                    let quantity = 1
                                    if (this.selectedValue[j].hasOwnProperty('quantity')) {
                                        if (parseFloat(this.selectedValue[j]['quantity'])) {
                                            quantity = parseFloat(this.selectedValue[j]['quantity'])
                                        } else {
                                            quantity = 1
                                        }
                                    }
                                    if (this.selectedValue[j].hasOwnProperty('selectedPriceCol')) {
                                        let priceColeName = this.selectedValue[j]['selectedPriceCol'];
                                        let unitCost = this.selectedValue[j][priceColeName];
                                        let tempTotal = 0;
                                        if (unitCost) {
                                            tempTotal = this.getTotalCost(unitCost, quantity);
                                        } else {
                                            tempTotal = this.getTotalCost(this.selectedValue[j]['generalPrice'], quantity);
                                        }
                                        costTotal += tempTotal;
                                    }
                                    /!*if (this.selectedValue[j].hasOwnProperty('lowestPrice')) {
                                        if (this.selectedValue[j]['lowestPrice']) {
                                            costTotal += parseFloat(this.selectedValue[j]['lowestPrice']) * quantity
                                        }
                                    }*!/
                                }
                                value = costTotal.toFixed(2);
                                cellProperties.readOnlyCellClassName = "price-total-bg";
                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                            }
                        }
                    }
                }
                if (row == instance.countRows() - 2) {
                    if (!value) {
                        value = ""
                    }
                    cellProperties.readOnlyCellClassName = "htDimmed";
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                }
                if (td.innerHTML) {
                    td.innerHTML = "£" + td.innerHTML
                }
            },
        },*/
        {
            title: 'Labour time',
            data: 'labourTime',
            allowEmpty: true,
            type: 'numeric',
            readOnly: false,
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                cellProperties.readOnlyCellClassName = ""
                if (this.selectedPartsData) {
                    if (value == null) {
                        value = this.selectedPartsData.labourTime
                    }
                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                    if (selectedIndex != -1) {
                        this.spreadsheetData[selectedIndex]['data'][row].labourTime = value
                    }
                }
                /*if (row == instance.countRows()) {
                    cellProperties.readOnly = true;
                    cellProperties.readOnlyCellClassName = 'calculate-class-margin';
                    cellProperties.className = "";
                    cellProperties.placeholder = "";
                } else {
                    cellProperties.readOnlyCellClassName = "htDimmed";
                    cellProperties.readOnly = false;
                }
                if (row == instance.countRows() - 2) {
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                }*/
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            },
        },
        {
            title: 'Labour rate',
            data: 'labourRatesId',
            validator: 'emptyCellValidator',
            placeholder: 'Please select',
            allowEmpty: false,
            readOnly: false,
            className: 'overflow_handsontable select_icon',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (value) {
                    let selectedLabourRate = this.labourRates.find((item: any) => item.id == value);
                    let labourRate: string = '';
                    if (selectedLabourRate) {
                        labourRate = selectedLabourRate['description'];
                        if (this.spreadsheetData.length != 0) {
                            let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                            if (selectedIndex != -1) {
                                if (value) {
                                    this.spreadsheetData[selectedIndex]['data'][row].labourRatesId = selectedLabourRate['id'];
                                }
                            }
                        }
                    }
                    value = labourRate
                }

                /*if (row == instance.countRows() - 1) {
                    cellProperties.readOnly = true;
                    cellProperties.readOnlyCellClassName = 'calculate-class-margin';
                    cellProperties.className = "";
                    cellProperties.placeholder = "";
                } else {
                    cellProperties.readOnlyCellClassName = "htDimmed";
                    cellProperties.readOnly = false;
                    cellProperties.placeholder = "Please select";
                }

                if (row == instance.countRows() - 2) {
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                }*/
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            },
            editor: HansontableSelect2({
                minimumInputLength: 2,
                dropdownCssClass: 'handsontable-select',
                dropdownAutoWidth: true,
                ajax: {
                    url: 'sales/get_labour_rates',
                    data: (query, page) => {
                        if (query === '') {
                            return false;
                        }
                        return { 'searchText': encodeURIComponent(query) };
                    },
                    // On search ajax response
                    results: (data, page) => {
                        this.spreadSheetViewData['items'] = []
                        for (var i = 0; i < data.labourRates.length; i++) {
                            this.spreadSheetViewData['items'].push({ 'id': data.labourRates[i]['id'], 'text': data.labourRates[i]['description'] })
                        }
                        return { results: this.spreadSheetViewData['items'] };
                    },
                },
                formatSelection: function (item) {
                    return item.text;
                }
            }),
        },
        {
            title: 'Actions',
            readOnly: true,
            disableVisualSelection: true,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                let selectedInstance = this.spreadsheetData.find(data => data.id == instance['hotId'])
                if (selectedInstance) {
                    if (Object.keys(selectedInstance).length != 0) {
                        let selectedValue = selectedInstance['data'];
                        let link: HTMLAnchorElement = document.createElement('a');
                        let type = 'add';
                        link.innerText = "Upsell";
                        if(selectedValue[row].hasOwnProperty('isContainUpsell') && selectedValue[row]['isContainUpsell'] == "1"){
                            link.innerText = "Edit";
                            type = 'edit'
                        }
                        if (selectedValue && Object.keys(selectedValue[row]).length) {
                            link.style.cursor = 'pointer';
                        } else {
                            link.style.cursor = "default";
                        }
                        if (selectedValue && Object.keys(selectedValue[row]).length) {
                            Handsontable.dom.addEvent(link, 'click', () => {
                                this.Upsell(instance, row, col, type);
                            });
                        }
                        Handsontable.dom.empty(td);
                        td.style.borderRight = '0px solid'
                        let content: HTMLDivElement = document.createElement('div');
                        content.appendChild(link);

                            td.appendChild(content);
                        /*if (row == instance.countRows() - 1) {
                            td.innerHTML = '';
                        }*/
                    }
                }
            }
        },
        {
            title: 'Actions',
            readOnly: true,
            disableVisualSelection: false,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                let link: HTMLAnchorElement = document.createElement('a');
                link.innerText = 'Delete';
                link.style.cursor = 'pointer';
                Handsontable.dom.addEvent(link, 'click', () => {
                    this.deleteKey = 'systemSettingsParts'
                    this.onRowDelete(instance, row, this.deleteKey);
                });
                Handsontable.dom.empty(td);
                let content: HTMLDivElement = document.createElement('div');
                content.appendChild(link);
                td.appendChild(content);
            }
        }
    ];

    // customer labour column
    labourColumn: Array<any> = [
        {
            title: 'Description',
            data: 'description',
            allowEmpty: false,
            validator: 'emptyCellValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                this.selectedHideInstance = instance
                if (row == instance.countRows() - 1) {
                    cellProperties.readOnly = true
                    cellProperties.readOnlyCellClassName = 'calculate-class';
                    cellProperties.className = 'customer-pricing';
                }
                if (row != instance.countRows() - 1) {
                    cellProperties.readOnly = false;
                    cellProperties.readOnlyCellClassName = ""
                    cellProperties.className = ""
                }
                if (this.spreadsheetData.length != 0) {
                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                    if (selectedIndex != -1) {
                        if (value) {
                            this.spreadsheetData[selectedIndex]['data'][row].description = value
                        }
                    }
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            },
        },
        {
            title: 'Labour rate',
            data: 'labourRatesId',
            validator: 'emptyCellValidator',
            placeholder: 'Please select',
            allowEmpty: false,
            className: 'overflow_handsontable select_icon',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (value) {
                    let selectedLabourRate = this.labourRates.find((item: any) => item.id == value);
                    let labourRate: string = '';
                    if (selectedLabourRate) {
                        labourRate = selectedLabourRate['description'];
                        if (this.spreadsheetData.length != 0) {
                            let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                            if (selectedIndex != -1) {
                                if (value) {
                                    this.spreadsheetData[selectedIndex]['data'][row].labourRatesId = selectedLabourRate['id'];
                                }
                            }
                        }
                    }
                    value = labourRate
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            },
            editor: HansontableSelect2({
                minimumInputLength: 2,
                dropdownCssClass: 'handsontable-select',
                dropdownAutoWidth: true,
                ajax: {
                    url: 'sales/get_labour_rates',
                    data: (query, page) => {
                        if (query === '') {
                            return false;
                        }
                        return { 'searchText': encodeURIComponent(query) };
                    },
                    // On search ajax response
                    results: (data, page) => {
                        this.spreadSheetViewData['items'] = []
                        for (var i = 0; i < data.labourRates.length; i++) {
                            this.spreadSheetViewData['items'].push({ 'id': data.labourRates[i]['id'], 'text': data.labourRates[i]['description'] })
                        }
                        return { results: this.spreadSheetViewData['items'] };
                    },
                },
                formatSelection: function (item) {
                    return item.text;
                }
            }),
        },
        {
            title: 'Quantity',
            data: 'quantity',
            allowEmpty: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                    if (selectedIndex != -1) {
                        if (value == null && row != instance.countRows() - 2 && row != instance.countRows() - 1) {
                            value = 1
                        }
                        if (value != null) {
                            this.spreadsheetData[selectedIndex]['data'][row].quantity = value;
                        }
                    }
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                }
            }
        },
        {
            title: 'Cost price',
            data: 'costPrice',
            allowEmpty: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                    if (selectedIndex != -1) {
                        if ((value == null || value == "") && Object.keys(this.spreadsheetData[selectedIndex]['data'][row]).length != 0) {
                            value = "0.00"
                        }
                        if (value != null) {
                            value = parseFloat(value).toFixed(2)
                            this.spreadsheetData[selectedIndex]['data'][row].costPrice = value
                        }
                    }
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                if (td.innerHTML) {
                    td.innerHTML = "£" + td.innerHTML
                }
            }
        },
        {
            title: 'Sale price',
            data: 'salePrice',
            allowEmpty: true,
            readOnly: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                    if (selectedIndex != -1) {
                        if (this.spreadsheetData[selectedIndex]['data'].length != 0) {
                            let selectedLabourRate = this.labourRates.find(i => i.id == this.spreadsheetData[selectedIndex]['data'][row]['labourRatesId'])
                            if (selectedLabourRate) {
                                value = selectedLabourRate.basicRatePerHour
                            }
                            if (value) {
                                value = parseFloat(value).toFixed(2)
                                this.spreadsheetData[selectedIndex]['data'][row].salePrice = value
                            }
                        }
                    }
                    if (row != instance.countRows() - 2) {
                        cellProperties.readOnlyCellClassName = "htDimmed"
                        Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                }
                if (row == instance.countRows() - 2) {
                    cellProperties.readOnlyCellClassName = ""
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                }
                if (td.innerHTML) {
                    td.innerHTML = "£" + td.innerHTML
                }
            },
        },
        {
            title: 'Total cost',
            data: 'totalCost',
            allowEmpty: true,
            readOnly: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            if (Object.keys(this.selectedValue[row]).length != 0) {
                                if (this.selectedValue[row].hasOwnProperty('costPrice')) {
                                    let quantity = 1
                                    if (this.selectedValue[row].hasOwnProperty('quantity')) {
                                        if (this.selectedValue[row]['quantity']) {
                                            quantity = this.selectedValue[row]['quantity']
                                        } else {
                                            quantity = 1
                                        }
                                    }
                                    value = (quantity * parseFloat(this.selectedValue[row]['costPrice'])).toFixed(2)
                                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                    if (selectedIndex != -1) {
                                        this.spreadsheetData[selectedIndex]['data'][row].totalCost = value
                                    }
                                    cellProperties.readOnlyCellClassName = "htDimmed"
                                    //Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                            if (row == instance.countRows() - 1) {
                                let costTotal = 0;
                                for (var j = 0; j < this.selectedValue.length; j++) {
                                    let quantity = 1
                                    if (this.selectedValue[j].hasOwnProperty('quantity')) {
                                        if (parseFloat(this.selectedValue[j]['quantity'])) {
                                            quantity = parseFloat(this.selectedValue[j]['quantity'])
                                        } else {
                                            quantity = 1
                                        }
                                    }
                                    if (this.selectedValue[j].hasOwnProperty('costPrice')) {
                                        if (this.selectedValue[j]['costPrice']) {
                                            costTotal += parseFloat(this.selectedValue[j]['costPrice']) * quantity
                                        }
                                    }
                                }
                                value = costTotal.toFixed(2)
                                cellProperties.readOnlyCellClassName = "price-total-bg"
                                //Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                            }
                        }
                    }
                }

                if (row == instance.countRows() - 2 || row == instance.countRows() - 1) {
                    if (!value) {
                        value = ""
                    }
                    cellProperties.readOnlyCellClassName = "calculate-bg";
                } else {
                    cellProperties.readOnlyCellClassName = "htDimmed";
                }

                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);

                if (td.innerHTML) {
                    td.innerHTML = "£" + td.innerHTML
                }
            },
        },
        {
            title: 'Total sale price',
            data: 'totalSalePrice',
            allowEmpty: true,
            readOnly: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            if (Object.keys(this.selectedValue[row]).length != 0) {
                                if (this.selectedValue[row].hasOwnProperty('salePrice')) {
                                    let quantity = 1
                                    if (this.selectedValue[row].hasOwnProperty('quantity')) {
                                        if (this.selectedValue[row]['quantity']) {
                                            quantity = this.selectedValue[row]['quantity']
                                        } else {
                                            quantity = 1
                                        }
                                    }
                                    value = (quantity * parseFloat(this.selectedValue[row]['salePrice'])).toFixed(2)
                                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                    if (selectedIndex != -1) {
                                        this.spreadsheetData[selectedIndex]['data'][row].totalSalePrice = value
                                    }
                                    cellProperties.readOnlyCellClassName = "htDimmed"
                                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                            if (row == instance.countRows() - 1) {
                                let saleTotal = 0;
                                for (var j = 0; j < this.selectedValue.length; j++) {
                                    let quantity = 1
                                    if (this.selectedValue[j].hasOwnProperty('quantity')) {
                                        if (parseFloat(this.selectedValue[j]['quantity'])) {
                                            quantity = parseFloat(this.selectedValue[j]['quantity'])
                                        } else {
                                            quantity = 1
                                        }
                                    }
                                    if (this.selectedValue[j].hasOwnProperty('salePrice')) {
                                        if (this.selectedValue[j]['salePrice']) {
                                            saleTotal += parseFloat(this.selectedValue[j]['salePrice']) * quantity
                                        }
                                    }
                                }
                                value = saleTotal.toFixed(2)
                                cellProperties.readOnlyCellClassName = "price-total-bg"
                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                            }
                        }
                    }
                }
                if (row == instance.countRows() - 2) {
                    if (!value) {
                        value = ""
                    }
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                }
                if (td.innerHTML) {
                    td.innerHTML = "£" + td.innerHTML
                }
            },
        },
        {
            title: 'Discount',
            data: 'discount',
            allowEmpty: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                            if (selectedIndex != -1) {
                                if (value == null || !value) {
                                    value = 0;
                                }
                                value = parseFloat(value).toFixed(2)
                                this.spreadsheetData[selectedIndex]['data'][row].discount = value;
                                cellProperties.readOnly = false
                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                            }
                            if (row == instance.countRows() - 1) {
                                let discount = 0;
                                for (var j = 0; j < this.selectedValue.length; j++) {
                                    if (this.selectedValue[j].hasOwnProperty('discount') && this.selectedValue[j]['discount']) {
                                        discount = (parseFloat(this.selectedValue[j]['discount']) + discount)
                                    }
                                }
                                value = discount.toFixed(2)
                                cellProperties.readOnlyCellClassName = "price-total-bg"
                                cellProperties.readOnly = true
                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                            }
                        }
                    }
                }
                if (row == instance.countRows() - 2) {
                    if (!value) {
                        value = ""
                    }
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                } else {
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                if (td.innerHTML) {
                    td.innerHTML = "£" + td.innerHTML
                }
            }
        },
        {
            title: 'Final sale price',
            data: 'finalSalePrice',
            allowEmpty: true,
            readOnly: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            if (Object.keys(this.selectedValue[row]).length != 0) {
                                if (this.selectedValue[row]['totalSalePrice']) {
                                    if (this.selectedValue[row]['discount']) {
                                        value = this.selectedValue[row]['totalSalePrice'] - this.selectedValue[row]['discount']
                                    }
                                    else {
                                        value = this.selectedValue[row]['totalSalePrice']
                                    }
                                    if (value != null) {
                                        value = parseFloat(value).toFixed(2)
                                    }
                                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                    if (selectedIndex != -1) {
                                        this.spreadsheetData[selectedIndex]['data'][row].finalSalePrice = value
                                    }
                                    cellProperties.readOnlyCellClassName = "htDimmed"
                                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                            if (row == instance.countRows() - 1) {
                                let saleFinalPrice = 0;
                                for (var j = 0; j < this.selectedValue.length; j++) {
                                    if (this.selectedValue[j].hasOwnProperty('finalSalePrice') && this.selectedValue[j]['finalSalePrice']) {
                                        saleFinalPrice = (parseFloat(this.selectedValue[j]['finalSalePrice']) + saleFinalPrice)
                                    }
                                }
                                value = saleFinalPrice.toFixed(2)
                                cellProperties.readOnlyCellClassName = "price-total-bg"
                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                            }
                        }
                    }
                }
                if (row == instance.countRows() - 2) {
                    if (!value) {
                        value = ""
                    }
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                } else {
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                if (td.innerHTML) {
                    td.innerHTML = "£" + td.innerHTML
                }
            },
        },
        {
            title: 'Profit',
            data: 'profit',
            allowEmpty: true,
            readOnly: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            if (Object.keys(this.selectedValue[row]).length != 0) {
                                if (this.selectedValue[row].hasOwnProperty('salePrice') && this.selectedValue[row].hasOwnProperty('costPrice')) {
                                    let quantity = 1
                                    if (this.selectedValue[row].hasOwnProperty('quantity')) {
                                        if (this.selectedValue[row]['quantity']) {
                                            quantity = this.selectedValue[row]['quantity']
                                        } else {
                                            quantity = 1
                                        }
                                    }
                                    let selectedSalePrice;
                                    if (this.selectedValue[row].hasOwnProperty('finalSalePrice') && this.selectedValue[row]['finalSalePrice']) {
                                        selectedSalePrice = this.selectedValue[row]['finalSalePrice']
                                    } else {
                                        if (this.selectedValue[row].hasOwnProperty('totalSalePrice') && this.selectedValue[row]['totalSalePrice']) {
                                            selectedSalePrice = this.selectedValue[row]['totalSalePrice']
                                        }
                                    }
                                    value = (selectedSalePrice - quantity * parseFloat(this.selectedValue[row]['costPrice'])).toFixed(2)
                                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                    if (selectedIndex != -1) {
                                        this.spreadsheetData[selectedIndex]['data'][row].profit = value
                                    }
                                    if (value != null){
                                        if (value > 0) {
                                            cellProperties.readOnlyCellClassName = "pricing-section-margin";
                                        } else if (value == 0) {
                                            cellProperties.readOnlyCellClassName = "calculate-bg";
                                        } else if (value < 0 || !value) {
                                            cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                                        }
                                        value = parseFloat(value).toFixed(2);
                                    }
                                    // cellProperties.readOnlyCellClassName = "htDimmed"
                                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                            if (row == instance.countRows() - 1) {
                                let profitTotal = 0;
                                for (var j = 0; j < this.selectedValue.length; j++) {
                                    if (this.selectedValue[j].hasOwnProperty('profit') && this.selectedValue[j]['profit']) {
                                        profitTotal = (parseFloat(this.selectedValue[j]['profit']) + profitTotal)
                                    }
                                }
                                value = profitTotal.toFixed(2)
                                if (value != null){
                                    if (value > 0) {
                                        cellProperties.readOnlyCellClassName = "pricing-section-margin";
                                    } else if (value == 0) {
                                        cellProperties.readOnlyCellClassName = "calculate-bg";
                                    } else if (value < 0 || !value) {
                                        cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                                    }
                                    value = parseFloat(value).toFixed(2);
                                }
                                // cellProperties.readOnlyCellClassName = "price-total-bg"
                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                            }
                        }
                    }
                }
                if (row == instance.countRows() - 2) {
                    if (!value) {
                        value = ""
                    }
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                }
                /*else {
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                }*/
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                if (td.innerHTML) {
                    td.innerHTML = "£" + td.innerHTML
                }
            },
        },
        {
            title: 'Margin',
            data: 'margin',
            allowEmpty: true,
            validator: 'numericValidator',
            readOnly: true,
            type: 'numeric',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            if (Object.keys(this.selectedValue[row]).length != 0) {
                                if (this.selectedValue[row].hasOwnProperty('profit')) {
                                    let profitVal = this.selectedValue[row]['profit'];
                                    if (profitVal != null) {
                                        let profit = profitVal * 100
                                        if (profit) {
                                            /*let selectedSalePrice;
                                            if (this.selectedValue[row].hasOwnProperty('finalSalePrice') && this.selectedValue[row]['finalSalePrice']) {
                                                selectedSalePrice = this.selectedValue[row]['finalSalePrice']
                                            } else {
                                                if (this.selectedValue[row].hasOwnProperty('totalSalePrice') && this.selectedValue[row]['totalSalePrice']) {
                                                    selectedSalePrice = this.selectedValue[row]['totalSalePrice']
                                                }
                                            }*/
                                            value = this.getMargin(this.selectedValue[row]['profit'], this.selectedValue[row]['finalSalePrice']);//this.selectedValue[row]['profit'] * 100 / selectedSalePrice).toFixed(2)
                                        }
                                        value = this.convertAndUpdatePrice(value);
                                        let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                        if (selectedIndex != -1) {
                                            this.spreadsheetData[selectedIndex]['data'][row].margin = value
                                        }
                                        Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                    }
                                }
                            }
                        }
                    }
                }
                if (row == instance.countRows() - 1) {
                    cellProperties.readOnlyCellClassName = "calculate-class-margin"
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                } else if (row == instance.countRows() - 2) {
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                } else if (row != instance.countRows() - 1) {
                    cellProperties.readOnlyCellClassName = ""
                    td.className = ""
                    if (value > 0) {
                        cellProperties.readOnlyCellClassName = "pricing-section-margin"
                    } else if (value == 0) {
                        cellProperties.readOnlyCellClassName = "calculate-bg"
                    } else if (value < 0) {
                        cellProperties.readOnlyCellClassName = "pricing-section-margin-loss"
                    }
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                }
                if (td.innerHTML) {
                    td.innerHTML = td.innerHTML + "%"
                }
            },
        },
        {
            title: 'Actions',
            readOnly: true,
            disableVisualSelection: false,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                let link: HTMLAnchorElement = document.createElement('a');
                link.innerText = 'Delete';
                link.style.cursor = 'pointer';
                Handsontable.dom.addEvent(link, 'click', () => {
                    this.deleteKey = 'labour'
                    this.onRowDelete(instance, row, this.deleteKey);
                });
                Handsontable.dom.empty(td);
                let content: HTMLDivElement = document.createElement('div');
                content.appendChild(link);
                td.appendChild(content);
            }
        }
    ]
    // Option and proposal template labour columns
    systemSettingsLabourColum: Array<any> = [
        {
            title: 'Description',
            data: 'description',
            allowEmpty: false,
            validator: 'emptyCellValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                this.selectedHideInstance = instance;
                if (row == instance.countRows() - 1) {
                    cellProperties.readOnly = true
                    cellProperties.readOnlyCellClassName = 'calculate-class';
                    cellProperties.className = 'customer-pricing';
                }
                if (row != instance.countRows() - 1) {
                    cellProperties.readOnly = false;
                    cellProperties.readOnlyCellClassName = ""
                    cellProperties.className = ""
                }
                if (this.spreadsheetData.length != 0) {
                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                    if (selectedIndex != -1) {
                        if (value) {
                            this.spreadsheetData[selectedIndex]['data'][row].description = value
                        }
                    }
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            },
        },
        {
            title: 'Labour rate',
            data: 'labourRatesId',
            validator: 'emptyCellValidator',
            placeholder: 'Please select',
            allowEmpty: false,
            className: 'overflow_handsontable select_icon',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (value) {
                    let selectedLabourRate = this.labourRates.find((item: any) => item.id == value);
                    let labourRate: string = '';
                    if (selectedLabourRate) {
                        labourRate = selectedLabourRate['description'];
                        if (this.spreadsheetData.length != 0) {
                            let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                            if (selectedIndex != -1) {
                                if (value) {
                                    this.spreadsheetData[selectedIndex]['data'][row].labourRatesId = selectedLabourRate['id'];
                                }
                            }
                        }
                    }
                    value = labourRate
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            },
            editor: HansontableSelect2({
                minimumInputLength: 2,
                dropdownCssClass: 'handsontable-select',
                dropdownAutoWidth: true,
                ajax: {
                    url: 'sales/get_labour_rates',
                    data: (query, page) => {
                        if (query === '') {
                            return false;
                        }
                        return { 'searchText': encodeURIComponent(query) };
                    },
                    // On search ajax response
                    results: (data, page) => {
                        this.spreadSheetViewData['items'] = []
                        for (var i = 0; i < data.labourRates.length; i++) {
                            this.spreadSheetViewData['items'].push({ 'id': data.labourRates[i]['id'], 'text': data.labourRates[i]['description'] })
                        }
                        return { results: this.spreadSheetViewData['items'] };
                    },
                },
                formatSelection: function (item) {
                    return item.text;
                }
            }),
        },
        {
            title: 'Quantity',
            data: 'quantity',
            allowEmpty: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                    if (selectedIndex != -1) {
                        if (value == null && row != instance.countRows() - 2 && row != instance.countRows() - 1) {
                            value = 1
                        }
                        if(value <= 0 && (instance.getDataAtCell(row, 0) || instance.getDataAtCell(row, 0) == '')) {
                            value = 1;
                        }
                        if (value != null) {
                            this.spreadsheetData[selectedIndex]['data'][row].quantity = value;
                        }
                    }
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                }
            }
        },
        {
            title: 'Unit cost',
            data: 'costPrice',
            allowEmpty: true,
            type: 'numeric',
            readOnly: false,
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                    if (selectedIndex != -1) {
                        if ((value == null || value == "") && Object.keys(this.spreadsheetData[selectedIndex]['data'][row]).length != 0) {
                            value = "0.00"
                        }
                        if (value != null) {
                            value = parseFloat(value).toFixed(2)
                            this.spreadsheetData[selectedIndex]['data'][row].costPrice = value
                        }
                    }
                }
                if (row != instance.countRows() - 1) {
                    cellProperties.readOnlyCellClassName = "htDimmed"
                }
                if (row == instance.countRows() - 1) {
                    cellProperties.readOnlyCellClassName = ""
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                    td.className = "customer-pricing-labour-border"
                }
                if (row == instance.countRows() - 2) {
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                if (td.innerHTML) {
                    td.innerHTML = "£" + td.innerHTML
                }
            }
        },
        {
            title: 'Sale price',
            data: 'salePrice',
            allowEmpty: true,
            readOnly: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                    if (selectedIndex != -1) {
                        if (this.spreadsheetData[selectedIndex]['data'].length != 0) {
                            let selectedLabourRate = this.labourRates.find(i => i.id == this.spreadsheetData[selectedIndex]['data'][row]['labourRatesId'])
                            if (selectedLabourRate) {
                                value = selectedLabourRate.basicRatePerHour
                            }
                            if (value) {
                                value = parseFloat(value).toFixed(2)
                                this.spreadsheetData[selectedIndex]['data'][row].salePrice = value
                            }
                        }
                    }
                    if (row != instance.countRows() - 2) {
                        cellProperties.readOnlyCellClassName = "htDimmed";
                        cellProperties.className = "";
                        Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                    }
                }
                if (row == instance.countRows() - 2) {
                    cellProperties.readOnlyCellClassName = "htDimmed";
                    cellProperties.className = ""
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                }
                if (td.innerHTML) {
                    td.innerHTML = "£" + td.innerHTML
                }
            },
        },
        {
            title: 'Total cost',
            data: 'totalCost',
            allowEmpty: true,
            readOnly: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            if (Object.keys(this.selectedValue[row]).length != 0) {
                                if (this.selectedValue[row].hasOwnProperty('costPrice')) {
                                    let quantity = 1
                                    if (this.selectedValue[row].hasOwnProperty('quantity')) {
                                        if (this.selectedValue[row]['quantity']) {
                                            quantity = this.selectedValue[row]['quantity']
                                        } else {
                                            quantity = 1
                                        }
                                    }
                                    value = (quantity * parseFloat(this.selectedValue[row]['costPrice'])).toFixed(2)
                                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                    if (selectedIndex != -1) {
                                        this.spreadsheetData[selectedIndex]['data'][row].totalCost = value
                                    }
                                    cellProperties.readOnlyCellClassName = "htDimmed";
                                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                            if (row == instance.countRows() - 1) {
                                let costTotal = 0;
                                for (var j = 0; j < this.selectedValue.length; j++) {
                                    let quantity = 1
                                    if (this.selectedValue[j].hasOwnProperty('quantity')) {
                                        if (parseFloat(this.selectedValue[j]['quantity'])) {
                                            quantity = parseFloat(this.selectedValue[j]['quantity'])
                                        } else {
                                            quantity = 1
                                        }
                                    }
                                    if (this.selectedValue[j].hasOwnProperty('costPrice')) {
                                        if (this.selectedValue[j]['costPrice']) {
                                            costTotal += parseFloat(this.selectedValue[j]['costPrice']) * quantity
                                        }
                                    }
                                }
                                value = costTotal.toFixed(2)
                                cellProperties.readOnlyCellClassName = "price-total-bg";
                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                            }
                        }
                    }
                }
                if (row == instance.countRows() - 2) {
                    cellProperties.readOnlyCellClassName = "htDimmed";
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                }
                if (td.innerHTML) {
                    td.innerHTML = "£" + td.innerHTML
                }
            },
        },
        {
            title: 'Total sale price',
            data: 'totalSalePrice',
            allowEmpty: true,
            readOnly: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data'];
                            if (Object.keys(this.selectedValue[row]).length != 0) {
                                if (this.selectedValue[row].hasOwnProperty('salePrice')) {
                                    let quantity = 1;
                                    if (this.selectedValue[row].hasOwnProperty('quantity')) {
                                        if (this.selectedValue[row]['quantity']) {
                                            quantity = this.selectedValue[row]['quantity']
                                        } else {
                                            quantity = 1
                                        }
                                    }
                                    if(!this.selectedValue[row]['salePrice']){
                                        value = 0;
                                    }else{
                                        value = (quantity * parseFloat(this.selectedValue[row]['salePrice'])).toFixed(2)
                                    }
                                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                    if (selectedIndex != -1) {
                                        this.spreadsheetData[selectedIndex]['data'][row].totalSalePrice = value
                                    }
                                    cellProperties.readOnlyCellClassName = "htDimmed";
                                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                            if (row == instance.countRows() - 1) {
                                let saleTotal = 0;
                                for (var j = 0; j < this.selectedValue.length; j++) {
                                    let quantity = 1
                                    if (this.selectedValue[j].hasOwnProperty('quantity')) {
                                        if (parseFloat(this.selectedValue[j]['quantity'])) {
                                            quantity = parseFloat(this.selectedValue[j]['quantity'])
                                        } else {
                                            quantity = 1
                                        }
                                    }
                                    if (this.selectedValue[j].hasOwnProperty('salePrice')) {
                                        if (this.selectedValue[j]['salePrice']) {
                                            saleTotal += parseFloat(this.selectedValue[j]['salePrice']) * quantity
                                        }
                                    }
                                }
                                value = saleTotal.toFixed(2)
                                cellProperties.readOnlyCellClassName = "price-total-bg";
                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                            }
                        }
                    }
                }
                if (row == instance.countRows() - 2) {
                    cellProperties.readOnlyCellClassName = "htDimmed";
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                }
                if (td.innerHTML) {
                    if(!td.innerHTML.includes('£')){
                        td.innerHTML = "£" + td.innerHTML
                    }
                }
            },
        },
        {
            title: 'Profit',
            data: 'profit',
            allowEmpty: true,
            type: 'numeric',
            validator: 'numericValidator',
            readOnly: true,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            if (Object.keys(this.selectedValue[row]).length != 0) {
                                if (this.selectedValue[row].hasOwnProperty('totalSalePrice') && this.selectedValue[row].hasOwnProperty('totalCost')) {
                                    let quantity = 1
                                    if (this.selectedValue[row].hasOwnProperty('quantity')) {
                                        quantity = parseFloat(this.selectedValue[row]['quantity'])
                                    }
                                    let totalSalePrice = parseFloat(this.selectedValue[row]['totalSalePrice']);
                                    let profit = this.getProfit(totalSalePrice, parseFloat(this.selectedValue[row]['totalCost']));
                                    value = profit.toFixed(2);

                                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                    if (selectedIndex != -1) {
                                        this.spreadsheetData[selectedIndex]['data'][row].profit = value
                                    }
                                    if (value != null){
                                        if (value > 0) {
                                            cellProperties.readOnlyCellClassName = "pricing-section-margin";
                                        } else if (value == 0) {
                                            cellProperties.readOnlyCellClassName = "calculate-bg";
                                        } else if (value < 0 || !value) {
                                            cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                                        }
                                        value = parseFloat(value).toFixed(2);
                                    }
                                    // cellProperties.readOnlyCellClassName = "htDimmed";
                                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                            if (row == instance.countRows() - 1) {
                                let profitTotal = 0;
                                for (var j = 0; j < this.selectedValue.length; j++) {
                                    if (this.selectedValue[j].hasOwnProperty('profit')) {
                                        if (this.selectedValue[j]['profit'] != null) {
                                            profitTotal += parseFloat(this.selectedValue[j]['profit'])
                                        }
                                    }
                                }
                                value = profitTotal.toFixed(2)
                                if (value != null){
                                    if (value > 0) {
                                        cellProperties.readOnlyCellClassName = "pricing-section-margin";
                                    } else if (value == 0) {
                                        cellProperties.readOnlyCellClassName = "calculate-bg";
                                    } else if (value < 0 || !value) {
                                        cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                                    }
                                    value = parseFloat(value).toFixed(2);
                                }
                                // cellProperties.readOnlyCellClassName = "price-total-bg"
                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                            }
                        }
                    }
                }
                if (row == instance.countRows() - 2) {
                    if (!value) {
                        value = ""
                    }
                    cellProperties.readOnlyCellClassName = "htDimmed";
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                }
               /* else {
                    cellProperties.readOnlyCellClassName = "calculate-bg";
                }*/
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                if (td.innerHTML) {
                    td.innerHTML = "£" + td.innerHTML
                }
            },
        },
        {
            title: 'Margin',
            data: 'margin',
            allowEmpty: true,
            validator: 'numericValidator',
            readOnly: true,
            type: 'numeric',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            if (Object.keys(this.selectedValue[row]).length != 0) {
                                let quantity = this.selectedValue[row]['quantity'];
                                if(quantity < 1){
                                    quantity = 1;
                                }
                                let totalSalePrice = parseFloat(this.selectedValue[row]['totalSalePrice']);
                                let profit = this.getProfit(totalSalePrice, parseFloat(this.selectedValue[row]['totalCost']));
                                value = this.getMargin(profit, totalSalePrice);
                                /*if(!profit || profit == NaN){
                                    value = profit.toFixed(2)
                                }else{
                                    value = totalSalePrice && totalSalePrice > 0 ? (profit * 100 / this.selectedValue[row]['totalSalePrice']).toFixed(2) : -100;
                                }*/
                                let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                if (selectedIndex != -1) {
                                    this.spreadsheetData[selectedIndex]['data'][row].margin = value
                                }
                                if (value > 0) {
                                    cellProperties.readOnlyCellClassName = "pricing-section-margin"
                                } else if (value == 0) {
                                    cellProperties.readOnlyCellClassName = "calculate-bg"
                                } else if (value < 0 || !value) {
                                    cellProperties.readOnlyCellClassName = "pricing-section-margin-loss"
                                }
                                value = this.convertAndUpdatePrice(value);
                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                            }
                        }
                    }
                }
                if (row == instance.countRows() - 1) {
                    cellProperties.readOnlyCellClassName = "calculate-class-margin"
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                } else if (row == instance.countRows() - 2) {
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                } else if (row != instance.countRows() - 1) {
                    cellProperties.readOnlyCellClassName = ""
                    td.className = ""
                    if (value > 0) {
                        cellProperties.readOnlyCellClassName = "pricing-section-margin"
                    } else if (value == 0) {
                        cellProperties.readOnlyCellClassName = "calculate-bg"
                    } else if (value < 0) {
                        cellProperties.readOnlyCellClassName = "pricing-section-margin-loss"
                    }
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                }

                if (td.innerHTML) {
                    td.innerHTML = td.innerHTML + "%"
                }
            },
        },
        {
            title: 'Actions',
            readOnly: true,
            disableVisualSelection: false,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                let link: HTMLAnchorElement = document.createElement('a');
                if (row != instance.countRows() - 2 && row != instance.countRows() - 1) {
                    link.innerText = 'Delete';
                    link.style.cursor = 'pointer';
                    td.className = ""
                }
                Handsontable.dom.addEvent(link, 'click', () => {
                    this.deleteKey = 'systemSettingsLabour'
                    this.onRowDelete(instance, row, this.deleteKey);
                });
                Handsontable.dom.empty(td);
                let content: HTMLDivElement = document.createElement('div');
                if (row != instance.countRows() - 2) {
                    content.appendChild(link);
                }
                td.appendChild(content);
            }
        }
    ]
    // cusomer expenses column
    expensesColumn: Array<any> = [
        {
            title: 'Description',
            data: 'description',
            allowEmpty: false,
            validator: 'emptyCellValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (row == instance.countRows() - 1) {
                    cellProperties.readOnly = true;
                    cellProperties.readOnlyCellClassName = 'calculate-class';
                    cellProperties.className = 'customer-pricing';
                }
                if (row != instance.countRows() - 1) {
                    cellProperties.readOnly = false;
                    cellProperties.readOnlyCellClassName = ""
                    cellProperties.className = ""
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            }
        },
        // {
        //     title: 'Supplier',
        //     data: 'supplierId',
        //     validator: 'emptyCellValidator',
        //     placeholder: 'Please select',
        //     allowEmpty: false,
        //     className: 'overflow_handsontable select_icon',
        //     renderer: (instance, td, row, col, prop, value, cellProperties) => {
        //         if (value) {
        //             let selectedSupplier = this.supplierList.find((item: any) => item.id == value);
        //             let supplier: string = '';
        //             if (selectedSupplier) {
        //                 supplier = selectedSupplier['text'];
        //             }
        //             value = supplier
        //         }
        //         Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
        //     },
        //     editor: HansontableSelect2({
        //         minimumInputLength: 2,
        //         dropdownCssClass: 'handsontable-select',
        //         dropdownAutoWidth: true,
        //         ajax: {
        //             url: 'get_all_suppliers',
        //             data: (query, page) => {
        //                 if (query === '') {
        //                     return false;
        //                 }
        //                 return { 'q': encodeURIComponent(query) };
        //             },
        //             // On search ajax response
        //             results: (data, page) => {
        //                 this.spreadSheetViewData['items'] = data
        //                 return { results: this.spreadSheetViewData['items'] };
        //             },
        //         },
        //         formatSelection: function (item) {
        //             return item.text;
        //         }
        //     }),
        // },
        {
            title: 'Nominal code',
            data: 'settingsNominalCodeId',
            validator: 'emptyCellValidator',
            placeholder: 'Please select',
            allowEmpty: false,
            className: 'overflow_handsontable select_icon',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (value) {
                    let nominalCode = this.nominalCode.find((item: any) => item.id == value);
                    let nominalValue;
                    if (nominalCode) {
                        nominalValue = nominalCode['description'];
                    }
                    value = nominalValue
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            },
            editor: HansontableSelect2({
                // minimumInputLength: 0,
                dropdownCssClass: 'handsontable-select',
                dropdownAutoWidth: true,
                data: this.nominalCode,
                // ajax: {
                //     url: 'listExpensesNominalAccounts',
                //     data: (query, page) => {
                //         if (query === '') {
                //             return false;
                //         }
                //         return { 'searchText': encodeURIComponent(query) };
                //     },
                //     // On search ajax response
                //     results: (data, page) => {
                //         this.spreadSheetViewData['items'] = []
                //         for (var i = 0; i < data.data.length; i++) {
                //             this.spreadSheetViewData['items'].push({ 'id': data.data[i]['id'], 'text': data.data[i]['description'] })
                //         }
                //         return { results: this.spreadSheetViewData['items'] };
                //     },
                // },
                // formatSelection: function (item) {
                //     return item.text;
                // }
            }),
        },
        {
            title: 'Cost price',
            data: 'costPrice',
            allowEmpty: false,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId']);
                    if (selectedIndex != -1) {
                        if ((value == null || value == "" || isNaN(value)) &&
                            Object.keys(this.spreadsheetData[selectedIndex]['data'][row]).length != 0) {
                            value = "0.00"
                        }
                        if (value != null) {
                            value = parseFloat(value).toFixed(2)
                            this.spreadsheetData[selectedIndex]['data'][row].costPrice = value;
                        }
                    }

                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            if (value != null) {
                                value = parseFloat(value).toFixed(2)
                            } else {
                                value = '';
                            }
                            if (row == instance.countRows() - 1) {
                                let costTotal = 0;
                                for (var j = 0; j < this.selectedValue.length; j++) {
                                    if (this.selectedValue[j].hasOwnProperty('costPrice')) {
                                        if (this.selectedValue[j]['costPrice']) {
                                            costTotal += parseFloat(this.selectedValue[j]['costPrice'])
                                        }
                                    }
                                }
                                value = costTotal.toFixed(2)
                                cellProperties.readOnly = true;
                                cellProperties.readOnlyCellClassName = "price-total-bg"
                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                            }
                        }
                    }
                }
                if (row == instance.countRows() - 2) {
                    if (!value) {
                        value = ""
                    }
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                }
                if (row != instance.countRows() - 1) {
                    cellProperties.readOnly = false;
                    cellProperties.readOnlyCellClassName = ""
                }
                value = !isNaN(value) ? value : "";
                if (row == instance.countRows()-1  && !isNaN(value)) {
                    value = this.currencyPipe.transform(value);
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                // if (td.innerHTML) {
                //     td.innerHTML = "£" + td.innerHTML
                // }

            }
        },
        {
            title: 'Sale price',
            data: 'salePrice',
            allowEmpty: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId']);
                    if (selectedIndex != -1) {
                        if ((value == null || value == "" || isNaN(value)) &&
                            Object.keys(this.spreadsheetData[selectedIndex]['data'][row]).length != 0) {
                            value = "0.00"
                        }
                        if (value != null) {
                            value = parseFloat(value).toFixed(2)
                            this.spreadsheetData[selectedIndex]['data'][row].salePrice = value;
                        }
                    }
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            if (value != null) {
                                value = parseFloat(value).toFixed(2)
                            }
                            if (row == instance.countRows() - 1) {
                                let saleTotal = 0;
                                cellProperties.readOnly = true;
                                for (var j = 0; j < this.selectedValue.length; j++) {
                                    if (this.selectedValue[j].hasOwnProperty('salePrice')) {
                                        if (this.selectedValue[j]['salePrice']) {
                                            saleTotal += parseFloat(this.selectedValue[j]['salePrice'])
                                        }
                                    }
                                }
                                value = saleTotal.toFixed(2)
                                cellProperties.readOnlyCellClassName = "price-total-bg"
                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                            }
                        }
                    }
                }
                if (row == instance.countRows() - 2) {
                    if (!value) {
                        value = ""
                    }
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                }
                if (row != instance.countRows() - 1) {
                    cellProperties.readOnly = false;
                    cellProperties.readOnlyCellClassName = ""
                }
                value = !isNaN(value) ? value : "";
                if (row == instance.countRows()-1  && !isNaN(value)) {
                    value = this.currencyPipe.transform(value);
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                // if (td.innerHTML) {
                //     td.innerHTML = "£" + td.innerHTML
                // }
            }
        },
        {
            title: 'VAT',
            data: 'vatRate',
            validator: 'emptyCellValidator',
            placeholder: 'Please select',
            allowEmpty: false,
            className: 'overflow_handsontable select_icon',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if(value == null || value == '') {
                    value = 3;
                }
                if (value) {
                    let vatRate = this.vatRates.find((item: any) => item.id == value);
                    let nominalValue;
                    if (vatRate) {
                        nominalValue = vatRate['description'];
                    }
                    value = nominalValue
                }
                if (row == instance.countRows() - 1) {
                        value = ""
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            },
            editor: HansontableSelect2({
                dropdownCssClass: 'handsontable-select',
                dropdownAutoWidth: true,
                data: this.formatTaxItems(this.vatRates),
            }),
        },
        {
            title: 'Profit',
            data: 'profit',
            allowEmpty: true,
            readOnly: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            if (this.selectedValue && this.selectedValue.length != 0) {
                                if (Object.keys(this.selectedValue[row]).length != 0) {
                                    if (this.selectedValue[row].hasOwnProperty('salePrice') && this.selectedValue[row].hasOwnProperty('costPrice')) {
                                        let quantity = 1;
                                        let salePriceValue = parseFloat(this.selectedValue[row]['salePrice']);
                                        let costPriceValue = parseFloat(this.selectedValue[row]['costPrice']);
                                        let profitCalculated = this.getProfit(salePriceValue, costPriceValue);
                                        value = profitCalculated.toFixed(2);
                                        let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                        if (selectedIndex != -1) {
                                            this.spreadsheetData[selectedIndex]['data'][row].profit = value
                                        }
                                        if (value != null){
                                            if (value > 0) {
                                                cellProperties.readOnlyCellClassName = "pricing-section-margin";
                                            } else if (value == 0) {
                                                cellProperties.readOnlyCellClassName = "calculate-bg";
                                                cellProperties.readOnlyCellClassName = "htDimmed";
                                            } else if (value < 0 || !value) {
                                                cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                                            }
                                            value = parseFloat(value).toFixed(2);
                                        }
                                        // cellProperties.readOnlyCellClassName = "htDimmed"
                                        Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                    }
                                }
                                if (row == instance.countRows() - 1) {
                                    let profitTotal = 0;
                                    for (var j = 0; j < this.selectedValue.length; j++) {
                                        if (this.selectedValue[j].hasOwnProperty('profit')) {
                                            if (this.selectedValue[j]['profit'] != null) {
                                                profitTotal += parseFloat(this.selectedValue[j]['profit'])
                                            }
                                        }
                                    }
                                    value = !isNaN(profitTotal) ? profitTotal : 0;
                                    value = value.toFixed(2);
                                    if (value != null){
                                        if (value > 0) {
                                            cellProperties.readOnlyCellClassName = "pricing-section-margin";
                                        } else if (value == 0) {
                                            cellProperties.readOnlyCellClassName = "calculate-bg";
                                        } else if (value < 0 || !value) {
                                            cellProperties.readOnlyCellClassName = "pricing-section-margin-loss";
                                        }
                                        value = parseFloat(value).toFixed(2);
                                    }
                                    // cellProperties.readOnlyCellClassName = "price-total-bg"
                                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                }
                            }
                        }
                    }
                    if (row == instance.countRows() - 2) {
                        if (!value) {
                            value = ""
                        }
                        cellProperties.readOnlyCellClassName = "calculate-bg"
                    }
                    if (row == instance.countRows()-1  && !isNaN(value)) {
                        value = this.currencyPipe.transform(value);
                    }
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                    // if (td.innerHTML) {
                    //     td.innerHTML = "£" + td.innerHTML
                    // }
                }
            },
        },
        {
            title: 'Margin',
            data: 'margin',
            allowEmpty: true,
            readOnly: true,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            if (Object.keys(this.selectedValue[row]).length != 0) {
                                if (this.selectedValue[row].hasOwnProperty('profit')) {
                                    if (this.selectedValue[row]['profit'] != null) {
                                        value = this.getMargin(this.selectedValue[row]['profit'], this.selectedValue[row]['salePrice']);
                                        //value = (this.selectedValue[row]['profit'] * 100 / this.selectedValue[row]['salePrice']).toFixed(2)
                                        let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                        if (selectedIndex != -1) {
                                            this.spreadsheetData[selectedIndex]['data'][row].margin = value
                                        }
                                        value = this.convertAndUpdatePrice(value);
                                        Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                    }
                                }
                            }
                        }
                    }
                }
                if (row == instance.countRows() - 1) {
                    cellProperties.readOnlyCellClassName = "calculate-class-margin"
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                } else if (row == instance.countRows() - 2) {
                    if (!value) {
                        value = ""
                    }
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                } else if (row != instance.countRows() - 1) {
                    if (value != null) {
                        cellProperties.readOnlyCellClassName = ""
                        td.className = ""
                        if (value > 0) {
                            cellProperties.readOnlyCellClassName = "pricing-section-margin"
                        } else if (value == 0) {
                            cellProperties.readOnlyCellClassName = "calculate-bg"
                            cellProperties.readOnlyCellClassName = "htDimmed"
                        } else if (value < 0) {
                            cellProperties.readOnlyCellClassName = "pricing-section-margin-loss"
                        }
                    } else {
                        cellProperties.readOnlyCellClassName = "calculate-bg"
                    }
                    Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                if (td.innerHTML) {
                    td.innerHTML = td.innerHTML + "%"
                }
            },
        },
        {
            title: 'Actions',
            readOnly: true,
            disableVisualSelection: false,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if(row < instance.countRows() - 2) {
                    let link: HTMLAnchorElement = document.createElement('a');
                    link.innerText = 'Delete';
                    link.style.cursor = 'pointer';
                    Handsontable.dom.addEvent(link, 'click', () => {
                        this.deleteKey = 'expenses'
                        this.onRowDelete(instance, row, this.deleteKey);
                    });
                    Handsontable.dom.empty(td);
                    let content: HTMLDivElement = document.createElement('div');
                    content.appendChild(link);
                    td.appendChild(content);
                } else {
                    //cellProperties.className = 'calculate-class';
                    td.innerHTML = '';
                }
                // TODO : vat enable disable add extra on column hidden expansive
                if (!this.vatEnabled){
                    if(row == instance.countRows() - 1){
                        td.hidden = true;
                    }else{
                        td.hidden = false;
                    }
                }
            }
        }
    ]
    // System settings expense columns
    systemSettingsexpensesColumn: Array<any> = [
        {
            title: 'Description',
            data: 'description',
            allowEmpty: false,
            validator: 'emptyCellValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (row == instance.countRows() - 1) {
                    cellProperties.readOnly = true;
                    cellProperties.readOnlyCellClassName = 'calculate-class';
                    cellProperties.className = 'customer-pricing';
                }
                if (row != instance.countRows() - 1) {
                    cellProperties.readOnly = false;
                    cellProperties.readOnlyCellClassName = ""
                    cellProperties.className = ""
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            }
        },
        // {
        //     title: 'Supplier',
        //     data: 'supplierId',
        //     validator: 'emptyCellValidator',
        //     placeholder: 'Please select',
        //     allowEmpty: false,
        //     className: 'overflow_handsontable select_icon',
        //     renderer: (instance, td, row, col, prop, value, cellProperties) => {
        //         if (value) {
        //             let selectedSupplier = this.supplierList.find((item: any) => item.id == value);
        //             let supplier: string = '';
        //             if (selectedSupplier) {
        //                 supplier = selectedSupplier['text'];
        //             }
        //             value = supplier
        //         }
        //         Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
        //     },
        //     editor: HansontableSelect2({
        //         minimumInputLength: 2,
        //         dropdownCssClass: 'handsontable-select',
        //         dropdownAutoWidth: true,
        //         ajax: {
        //             url: 'get_all_suppliers',
        //             data: (query, page) => {
        //                 if (query === '') {
        //                     return false;
        //                 }
        //                 return { 'q': encodeURIComponent(query) };
        //             },
        //             // On search ajax response
        //             results: (data, page) => {
        //                 this.spreadSheetViewData['items'] = data
        //                 return { results: this.spreadSheetViewData['items'] };
        //             },
        //         },
        //         formatSelection: function (item) {
        //             return item.text;
        //         }
        //     }),
        // },
        {
            title: 'Nominal code',
            data: 'settingsNominalCodeId',
            validator: 'emptyCellValidator',
            placeholder: 'Please select',
            allowEmpty: false,
            className: 'overflow_handsontable select_icon',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (value) {
                    let nominalCode = this.nominalCode.find((item: any) => item.id == value);
                    let nominalValue;
                    if (nominalCode) {
                        nominalValue = nominalCode['description'];
                    }
                    value = nominalValue
                }else{
                    value = '';
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            },
            editor: HansontableSelect2({
                dropdownCssClass: 'handsontable-select',
                dropdownAutoWidth: true,
                data: this.nominalCode,
            }),
        },
        {
            title: 'Cost price',
            data: 'costPrice',
            allowEmpty: false,
            type: 'numeric',
            validator: 'numericValidator',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (this.spreadsheetData.length != 0) {
                    this.selectedInstanceData = this.spreadsheetData.find(data => data.id == instance['hotId'])
                    if (this.selectedInstanceData) {
                        if (Object.keys(this.selectedInstanceData).length != 0) {
                            this.selectedValue = this.selectedInstanceData['data']
                            if (value != null) {
                                value = parseFloat(value).toFixed(2)
                            }
                            if (row == instance.countRows() - 1) {
                                let costTotal = 0;
                                for (var j = 0; j < this.selectedValue.length; j++) {
                                    if (this.selectedValue[j].hasOwnProperty('costPrice')) {
                                        if (this.selectedValue[j]['costPrice']) {
                                            costTotal += parseFloat(this.selectedValue[j]['costPrice'])
                                        }
                                    }
                                }
                                value = costTotal.toFixed(2)
                                cellProperties.readOnly = true;
                                cellProperties.readOnlyCellClassName = "price-total-bg"
                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                            }
                        }
                    }
                }
                if (row == instance.countRows() - 2) {
                    if (!value) {
                        value = ""
                    }
                    cellProperties.readOnlyCellClassName = "calculate-bg"
                }
                if (row != instance.countRows() - 1) {
                    cellProperties.readOnly = false;
                    cellProperties.readOnlyCellClassName = ""
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                // if (td.innerHTML) {
                //     td.innerHTML = "£" + td.innerHTML
                // }
            }
        },
        {
            title: 'Actions',
            readOnly: true,
            disableVisualSelection: false,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                let link: HTMLAnchorElement = document.createElement('a');
                if (row == instance.countRows() - 1) {
                    link.style.display = 'none';
                    td.className = "customer-pricing-labour-border"
                }
                if (row != instance.countRows() - 1) {
                    link.innerText = 'Delete';
                    link.style.cursor = 'pointer';
                    td.className = ""
                }
                link.innerText = 'Delete';
                link.style.cursor = 'pointer';
                Handsontable.dom.addEvent(link, 'click', () => {
                    this.deleteKey = 'systemSettingsExpenses'
                    this.onRowDelete(instance, row, this.deleteKey);
                });
                Handsontable.dom.empty(td);
                let content: HTMLDivElement = document.createElement('div');
                content.appendChild(link);
                td.appendChild(content);
            }
        }
    ]

    constructor(private toastBox: CsToastBoxService,
        private nominalService: ProposalTemplateService,
        private commonService: CommonService,
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope,
        private currencyPipe: CurrencyPipe) {
        Handsontable.validators.registerValidator('emptyCellValidator', this.emptyCellValidator);
        Handsontable.validators.registerValidator('numericValidator', this.numericValidator);
        this.nominalService.getNominalCodeList().subscribe((data) => {
            if(data){
                this.nominalCode = data['data'];
                let searchData = [];
                this.nominalCode.forEach((data) => {
                    let entry = { id: data['id'], text: data['description'] };
                    searchData.push(entry);
                });
                this.setNominalCodeEditor(searchData);
            }
        })
    }

    viewMode: boolean = false;
    setViewMode(viewMode) {
        this.viewMode = viewMode;
    }

    emitSaveChangeEvent(obj) {
        this.savechange.emit(obj);
    }

    emitNavChangeEvent(obj) {
        this.navchange.emit(obj);
    }

    getSaveChangeEmitter() {
        return this.savechange;
    }

    getNavChangeEmitter() {
        return this.navchange;
    }

    clearData() {
        if (this.check) {
            for (var i = 0; i < this.hotInstances.length; i++) {
                const instance: any = this.hotInstances.find(instance => instance.id == this.hotInstances[i]['id'])['instance']
                Handsontable.hooks.destroy(instance)
            }
            this.hotData = []
            this.hotInstances = [];
            this.spreadsheetData = [];
            this.selectedPricingData = {};
        }
    }

    clearDeletedInstances(hotIdToDelete) {
        let selectedHotIndex = this.hotInstances.findIndex(data => data.id == hotIdToDelete);
        let spreadDataIndex = this.spreadsheetData.findIndex(data => data.id == hotIdToDelete);
        if (selectedHotIndex != -1) {
            this.hotInstances.splice(selectedHotIndex, 1)
        }
        if (spreadDataIndex != -1) {
            this.spreadsheetData.splice(spreadDataIndex, 1)
        }
    }

    getSpreadSheetData(hotId) {
        if (this.spreadsheetData.length != 0) {
            let pricingData = this.spreadsheetData.find(data => data.id == hotId)
            if (pricingData) {
                this.hotData = pricingData['data'];
            }
        }
        this.check = true;
        return this.hotData;
    }

    spreadsheetSettings() {
        return {}
    }

    // Merge existing data while adding partskit, catalogue,upsell and new parts
    mergeSpreadSheetData(hotId, data) {
        this.partsKitSelection = true;
        this.selectedHotIndex = this.spreadsheetData.findIndex(data => data.id == hotId);
        let hotInstance = this.hotInstances.find(instance => instance.id == hotId);
        let indexCountToRemove = hotId.startsWith('system-settings-pricing-section-partsId') ? 1 : 2;
        this.spreadsheetData[this.selectedHotIndex]['data'].splice.apply(this.spreadsheetData[this.selectedHotIndex]['data'], [this.spreadsheetData[this.selectedHotIndex]['data'].length - indexCountToRemove, 0].concat(data));
        if (hotId.startsWith('pricing-section-partsId')) {
            hotInstance['instance'].updateSettings({
                mergeCells: [
                    { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 5 + this.suppliers.length },
                    { row: this.hotData.length - 1, col: 11 + this.suppliers.length, rowspan: 1, colspan: 4 }
                ]
            });
        }
        if (hotId.startsWith('system-settings-pricing-section-partsId')) {
            hotInstance['instance'].updateSettings({
                /*mergeCells: [
                    { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 3 + this.suppliers.length },
                    { row: this.hotData.length - 1, col: 4 + this.suppliers.length, rowspan: 1, colspan: 4 }
                ]*/
            });
        }
        hotInstance['instance'].render()
    }

    private getSupplierPrice(partId, supplierId) {
        let supplierPrice = '';
        if(this.partArray && this.partArray.length) {
            let part = this.partArray.find(part => part.id == partId);
            if(part) {
                let suppliersData = part.suppliersData;
                if(suppliersData && suppliersData.length) {
                    let supplierData = suppliersData.find(supplier => supplier.supplierId == supplierId);
                    supplierPrice = supplierData ? parseFloat(supplierData.supplierPrice).toFixed(2) : '';
                }
            }
        }
        return supplierPrice;
    }

    private updateSupplierPrices(data) {
        if(data && data.length) {
            data.map(row => {
                let suppliers = row.suppliers && row.suppliers != '' ? JSON.parse(row.suppliers) : [];
                if(suppliers.length) {
                    for(let i = 0; i < suppliers.length; i++) {
                        row[suppliers[i].name] = this.getSupplierPrice(row.partsAndPricesId, suppliers[i].id);
                    }
                }
                return row;
            });
        }
        return data;
    }

    checkGivenSupplierCostIsLow(spreadsheetData, prop, row) {
        let suppliers = spreadsheetData.suppliers;
        let currentRow = spreadsheetData['data'][row];
        let isLowestPrice = true;
        let currentPrice = currentRow[prop] ? parseFloat(currentRow[prop]) : false;
        if(suppliers && suppliers.length > 1 && currentPrice) {
            for(let i = 0; i < suppliers.length; i++) {
                let supplierName = suppliers[i].name;
                let supplierPrice = currentRow[supplierName] ? parseFloat(currentRow[supplierName]) : false;
                if(supplierPrice && supplierName != prop) {
                    if(supplierPrice < currentPrice) {
                        isLowestPrice = false;
                        break;
                    }
                }
            }
        }
        if(!currentPrice) {
            isLowestPrice = false;
        }
        return isLowestPrice;
    }

    updateSpreadSheetData(hotId, data) {
        let instanceIndex = this.spreadsheetData.findIndex((i: any) => i.id == hotId);
        let updatedData = this.updateSupplierPrices(data);
        if (instanceIndex != -1) {
            this.spreadsheetData[instanceIndex]['data'] = updatedData;
            let hotInstance = this.hotInstances.find(instance => instance.id == hotId)
            if (hotInstance && hotInstance.hasOwnProperty('instance')) {
                hotInstance['instance'].render();
            }
        }
    }

    // Settings data in handsontable and configuration
    populateData(hotId, data, listIndex?, sectionIndex?, sectionId?, listId?, suppliers?, type?) {
        this.supplierDataList[hotId] = suppliers;
        let hotInstance = this.hotInstances.find(instance => instance.id == hotId)
        if (hotInstance) {
            const instance: any = hotInstance['instance']
            let columnsData = []
            let colwidth = []
            if (hotId.startsWith('pricing-section-partsId') || hotId.startsWith('system-settings-pricing-section-partsId')) {
                data = this.updateSupplierPrices(data);
                let readOnly = hotId.startsWith('system-settings-pricing-section-partsId');

                let suppliersList = []
                let additionalColumnWidth = []
                for (var i = 0; i < suppliers.length; i++) {
                    suppliersList.push({
                        title: suppliers[i]['name'],
                        data: suppliers[i]['name'],
                        allowEmpty: true,
                        type: 'numeric',
                        readOnly: readOnly,
                        validator: 'numericValidator',
                        /*numericFormat: {
                            pattern: '0,0.00 $'
                        },*/
                        renderer: (instance, td, row, col, prop, value, cellProperties) => {
                            cellProperties.isSupplier = true;
                            cellProperties.className = '';
                            if(readOnly){
                                value = row == instance.countRows() ? '' : 'N/A';
                                additionalColumnWidth.push('145px');
                                cellProperties.readOnlyCellClassName = '';
                                cellProperties.className = 'calculate-class-supplier';
                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                if(value == 'N/A') {
                                    td.title = 'Cost price cannot be set on templates as prices will vary from suppliers';
                                }
                            } else {
                                let supplier = this.supplierDataList[instance.hotId].find(i => i.name == prop);
                                let showSelected = false;
                                let selectedParts = instance.getCopyableData(row, 0);
                                this.selectedPartsData = this.partArray.find((i: any) => i.id == selectedParts);
                                if (this.selectedPartsData) {
                                    if (this.selectedPartsData['suppliersData'].length != 0) {
                                        let selectedSupplier = this.selectedPartsData['suppliersData'].find((i: any) => i.supplierName == prop)
                                        if (selectedSupplier) {
                                            if (value) {
                                                value = parseFloat(value).toFixed(2)
                                            } else {
                                                value = parseFloat(selectedSupplier.supplierPrice).toFixed(2)
                                            }
                                            let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                            if (selectedIndex != -1 && this.selectedColumn == prop) {
                                                this.spreadsheetData[selectedIndex]['data'][row][prop] = value;
                                                this.spreadsheetData[selectedIndex]['data'][row]['supplierEdit'] = {
                                                    "supplierCatalogId": selectedSupplier.supplierCatalogId,
                                                    "price": value,
                                                    "supplierId": supplier.id,
                                                    'selectedSupplier': prop
                                                }
                                            } else {
                                                if (this.spreadsheetData[selectedIndex]['data'][row].hasOwnProperty('supplierEdit')) {
                                                    if (this.spreadsheetData[selectedIndex]['data'][row]['supplierEdit']['selectedSupplier'] != this.selectedColumn) {
                                                        delete this.spreadsheetData[selectedIndex]['data'][row].supplierEdit;
                                                    }
                                                }
                                            }
                                            let SupplierClass;
                                            if (this.spreadsheetData[selectedIndex]['data'][row]['selectedPriceCol'] == prop) {
                                                SupplierClass = "pricing-selected-bg";
                                                showSelected = true;
                                            } else {
                                                SupplierClass = "calculate-class-supplier";
                                            }
                                            /* if (selectedSupplier.priceSelected) {
                                                 SupplierClass = "pricing-selected-bg"
                                                 showSelected = true
                                             } else {
                                                 SupplierClass = "calculate-class-supplier"
                                             }*/
                                            let isLowerPrice = false;
                                            if (selectedIndex != -1) {
                                                isLowerPrice = this.checkGivenSupplierCostIsLow(this.spreadsheetData[selectedIndex], prop, row);
                                            }
                                            if (isLowerPrice) {
                                                cellProperties.className = `ss-down-arrow`;
                                            }
                                            if (this.spreadsheetData[selectedIndex]['data'][row] && value && !this.spreadsheetData[selectedIndex]['data'][row].hasOwnProperty(prop)) {
                                                this.spreadsheetData[selectedIndex]['data'][row][prop] = value;
                                            }
                                            cellProperties.className = SupplierClass + ' ' + cellProperties.className
                                        } else {
                                            cellProperties.className = "calculate-class-supplier"
                                            if (value) {
                                                value = parseFloat(value).toFixed(2)
                                                let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                                if (selectedIndex != -1 && this.selectedColumn == prop) {
                                                    this.spreadsheetData[selectedIndex]['data'][row][prop] = value
                                                    this.spreadsheetData[selectedIndex]['data'][row]['supplierEdit'] = {
                                                        "supplierCatalogId": null,
                                                        "price": value,
                                                        "supplierId": supplier.id,
                                                        'selectedSupplier': prop
                                                    }
                                                } else {
                                                    if (this.spreadsheetData[selectedIndex]['data'][row].hasOwnProperty('supplierEdit')) {
                                                        if (this.spreadsheetData[selectedIndex]['data'][row]['supplierEdit']['selectedSupplier'] != this.selectedColumn) {
                                                            delete this.spreadsheetData[selectedIndex]['data'][row].supplierEdit;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    } else {
                                        cellProperties.className = "calculate-class-supplier"
                                        if (value) {
                                            value = parseFloat(value).toFixed(2)
                                            let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                            if (selectedIndex != -1 && this.selectedColumn == prop) {
                                                this.spreadsheetData[selectedIndex]['data'][row][prop] = value
                                                this.spreadsheetData[selectedIndex]['data'][row]['supplierEdit'] = {
                                                    "supplierId": supplier.id,
                                                    "supplierCatalogId": null,
                                                    "price": value,
                                                    'selectedSupplier': prop
                                                }
                                            } else {
                                                if (this.spreadsheetData[selectedIndex]['data'][row].hasOwnProperty('supplierEdit')) {
                                                    if (this.spreadsheetData[selectedIndex]['data'][row]['supplierEdit']['selectedSupplier'] != this.selectedColumn) {
                                                        delete this.spreadsheetData[selectedIndex]['data'][row].supplierEdit;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    cellProperties.className = "calculate-class-supplier"
                                    if (value) {
                                        value = parseFloat(value).toFixed(2)
                                        let selectedIndex = this.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                                        if (selectedIndex != -1 && this.selectedColumn == prop) {
                                            this.spreadsheetData[selectedIndex]['data'][row][prop] = value
                                            this.spreadsheetData[selectedIndex]['data'][row]['supplierEdit'] = {
                                                "supplierId": supplier.id,
                                                "supplierCatalogId": null,
                                                "price": value,
                                                'selectedSupplier': prop
                                            }
                                        } else {
                                            if (this.spreadsheetData[selectedIndex]['data'][row].hasOwnProperty('supplierEdit')) {
                                                if (this.spreadsheetData[selectedIndex]['data'][row]['supplierEdit']['selectedSupplier'] != this.selectedColumn) {
                                                    delete this.spreadsheetData[selectedIndex]['data'][row].supplierEdit;
                                                }
                                            }
                                        }
                                    }
                                }

                                if (value && value != '' && !cellProperties.hasOwnProperty('valid')) {
                                    cellProperties.valid = true;
                                }

                                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                                if (td.innerHTML) {
                                    let new_value = "£" + td.innerHTML
                                    td.innerHTML = ""
                                    let label: HTMLElement = document.createElement('label');
                                    label.className = 'checkbox-button';
                                    label.style.cursor = 'pointer';
                                    let inputElement: HTMLInputElement = document.createElement('input');
                                    inputElement.type = "checkbox"
                                    inputElement.name = prop + row + col
                                    inputElement.id = prop + row + col
                                    if (showSelected) {
                                        inputElement.checked = true
                                    }
                                    if (inputElement.checked) {
                                        inputElement.disabled = true
                                    }
                                    inputElement.className = "checkbox-button__input"
                                    Handsontable.dom.addEvent(inputElement, 'click', ($event) => {
                                        this.selectPrice($event, instance, row, col);
                                    });
                                    label.appendChild(inputElement);
                                    let content: HTMLElement = document.createElement('span');
                                    content.className = "checkbox-button__control"
                                    label.appendChild(content)
                                    let priceValue: HTMLElement = document.createElement('span');
                                    priceValue.innerText = new_value
                                    label.appendChild(priceValue)
                                    td.appendChild(label);

                                }
                                if (this.selectedPartsData) {
                                    if (this.selectedPartsData['suppliersData'].length != 0) {
                                        let selectedSupplier = this.selectedPartsData['suppliersData'].find((i: any) => i.supplierName == prop)
                                        if (selectedSupplier) {
                                            let recentUpdatedClass;
                                            let content: HTMLElement = document.createElement('span');
                                            if (selectedSupplier.lastUpdate) {
                                                recentUpdatedClass = 'latest-updated';
                                                if (selectedSupplier['lastmodifieddatetime']) {
                                                    var testDateUtc = moment.utc(selectedSupplier['lastmodifieddatetime']['date']);
                                                    var localDate = testDateUtc.local();
                                                    let date = moment(localDate).format('dddd Do MMM YYYY (H:mm)')
                                                    content.title = date;
                                                }
                                            } else {
                                                recentUpdatedClass = 'not-recent-updated'
                                            }
                                            content.className = "updated-supplier-status" + ' ' + recentUpdatedClass;
                                            td.appendChild(content);
                                        }
                                    }
                                }
                            }
                        },
                    })
                    if (hotId.startsWith('pricing-section-partsId')) {
                        additionalColumnWidth.push('145px')
                    } else if(hotId.startsWith('system-settings-pricing-section-partsId')){
                        additionalColumnWidth.push('145px')
                    }
                }
                let widthColumns;
                if (hotId.startsWith('pricing-section-partsId')) {
                    this.originalPartColumn = this.partsColumn
                    columnsData = this.partsColumn.slice(0, 4).concat(suppliersList, this.partsColumn.slice(4));
                    widthColumns = ['450px', '100px', '100px', '135px', '145px', '145px', '145px', '145px', '145px', '145px', '145px', '125px', '175px', '60px', '60px']
                }else if(hotId.startsWith('system-settings-pricing-section-partsId')){
                    this.originalPartColumn = this.systemSettingPartsColumn
                    columnsData = this.systemSettingPartsColumn.slice(0, 2).concat(suppliersList, this.systemSettingPartsColumn.slice(2));
                    widthColumns = ['450px', '100px', '145px', '175px', '60px', '60px']
                }
                if (suppliers.length != 0) {
                    if (hotId.startsWith('pricing-section-partsId')) {
                        colwidth = widthColumns.slice(0, 4).concat(additionalColumnWidth, widthColumns.slice(4));
                    }else if(hotId.startsWith('system-settings-pricing-section-partsId')){
                        colwidth = widthColumns.slice(0, 2).concat(additionalColumnWidth, widthColumns.slice(2));
                    }
                } else {
                    colwidth = widthColumns
                }
            } else if (hotId.startsWith('pricing-section-labourId')) {
                columnsData = this.labourColumn;
                colwidth = [40, 15, 12, 15, 15, 15, 20, 12, 20, 15, 10, 12]
            } else if (hotId.startsWith('pricing-section-expensesId')) {
                columnsData = this.expensesColumn;
                colwidth = ['250px', '145px', '145px', '145px', '145px', '145px', '145px', '100px'];
                /*if (!this.vatEnabled) {
                    colwidth.splice(4, 1);
                }*/
                if(!this.vatEnabled && (this.expensesColumn[4]['title'] == 'VAT' || this.expensesColumn[4]['title'] == this.taxLabel)) {
                    this.expensesColumn.splice(4, 1);                // TODO : incase we need supplier in feature enbale expensesColumn array and changed splice code to 2,1
                    colwidth.splice(4, 1);
                    this.updateCols();
                }
                //colwidth = [50, 50, 25, 25, 20, 15, 15, 15];
            } else if (hotId.startsWith('system-settings-pricing-section-labourId')) {
                columnsData = this.systemSettingsLabourColum;
                colwidth = [80, 30, 20, 25, 25, 25, 35, 20, 20, 20]
            } else if (hotId.startsWith('system-settings-pricing-section-expensesId')) {
                columnsData = this.expensesColumn;//systemSettingsexpensesColumn
                colwidth = ['250px', '145px', '145px', '145px', '145px', '145px', '145px', '105px'];//[50, 50, 25, 25, 15]
                /*if (!this.vatEnabled) {
                    colwidth.splice(4, 1);
                }*/
                if(!this.vatEnabled && (this.expensesColumn[4]['title'] == 'VAT' || this.expensesColumn[4]['title'] == this.taxLabel)) {
                    this.expensesColumn.splice(4, 1);                // TODO : incase we need supplier in feature enbale expensesColumn array and changed splice code to 2,1
                    colwidth.splice(4, 1);
                    this.updateCols();
                }
            }
            if (this.partsKitSelection) {
                this.spreadsheetData.splice(this.selectedHotIndex, 0, {
                    'id': hotId, 'data': data, 'sectionId': sectionId, 'listId': listId,
                    'sectionIndex': sectionIndex, 'listIndex': listIndex, 'suppliers': suppliers
                })
            } else {
                if (type) {
                    if (type == 'edit') {
                        let instanceIndex = this.spreadsheetData.findIndex((i: any) => i.id == hotId)
                        if (instanceIndex != -1) {
                            this.spreadsheetData[instanceIndex]['suppliers'] = suppliers
                        }
                    } else {
                        let instanceIndex = this.spreadsheetData.findIndex((i: any) => i.id == hotId);
                        let dataToUpdate = {
                            'id': hotId, 'data': data, 'sectionId': sectionId, 'listId': listId,
                            'sectionIndex': sectionIndex, 'listIndex': listIndex, 'suppliers': suppliers
                        };
                        if (instanceIndex != -1) {
                            this.spreadsheetData[instanceIndex] = dataToUpdate;
                        } else {
                            this.spreadsheetData.push(dataToUpdate);
                        }
                    }
                } else {
                    let instanceIndex = this.spreadsheetData.findIndex((i: any) => i.id == hotId);
                    let dataToUpdate = {
                        'id': hotId, 'data': data, 'sectionId': sectionId, 'listId': listId,
                        'sectionIndex': sectionIndex, 'listIndex': listIndex, 'suppliers': suppliers
                    };
                    if (instanceIndex != -1) {
                        this.spreadsheetData[instanceIndex] = dataToUpdate;
                    } else {
                        this.spreadsheetData.push(dataToUpdate);
                    }
                }
            }
            if(this.viewMode) {
                let colCount = columnsData.length;
                let lastCol = columnsData[colCount - 1];
                if(lastCol.title == 'Actions') {
                    columnsData.splice(colCount - 2);
                }
            }
            if (type == 'add' || hotId.startsWith('pricing-section-labourId') || hotId.startsWith('pricing-section-expensesId') || hotId.startsWith('system-settings-pricing-section-labourId')
                || hotId.startsWith('system-settings-pricing-section-expensesId')) {
                var component = this
                this.baseSettings = {
                    preventOverflow: 'vertical',
                    colHeaders: true,
                    // rowHeaders: function (row) {
                    //     let icon;
                    //     switch (row) {
                    //         case row: icon = '<i class="fas fa-bars" style="margin-left:-4px"></i>'; break;
                    //     }
                    //     return icon;
                    // },
                    rowHeaders: false,
                    viewportRowRenderingOffset: 1000,
                    stretchH: 'all',
                    copyPaste: false,
                    afterGetColHeader: this.afterGetColHeader,
                    tabMoves: { row: 0, col: 1 },
                    colWidths: colwidth,
                    enterMoves: { row: 0, col: 1 },
                    manualRowMove: true,
                    //manualColumnMove: true,
                    data: data,
                    columns: columnsData,
                    mergeCells: true,
                    fillHandle: {
                        // enable plugin in vertical direction and with autoInsertRow as false
                        autoInsertRow: false,
                        direction: 'vertical'
                    },
                    afterBeginEditing: function(row, column){
                        let spreadSheetData = component.getSpreadSheetData(component.selectedTableInstance);
                        component.selectedRowRef = spreadSheetData && spreadSheetData.length ? spreadSheetData[row] : false;
                        component.selectedPricingData = component.spreadsheetData.find(data => data.id == hotId);
                        if(component.selectedRowRef.id != null) {
                            let columnTitle = columnsData[column]['data'];
                            component.editedDataAdded.push({
                                'row': row,
                                'col': columnTitle,
                                'id': component.selectedRowRef.id,
                                'instance': component.selectedTableInstance,
                                'sectionIndex':component.selectedPricingData['sectionIndex'],
                                'listId':component.selectedPricingData['listId'],
                                'sectionId':component.selectedPricingData['sectionId']
                            });
                        }
                    },
                    afterOnCellCornerMouseDown: function(){
                        let lastSelectedElement = this.getSelectedLast();
                        let autoFillPlugin = this.getPlugin('autofill');
                        if(lastSelectedElement && lastSelectedElement.length > 1) {
                            let cellMetaData = this.getCellMeta(lastSelectedElement[0], lastSelectedElement[1]);
                            if((cellMetaData.prop == 'partsAndPricesId' || cellMetaData.readOnly) && autoFillPlugin.enabled) {
                                autoFillPlugin.disablePlugin();
                            } else if(!autoFillPlugin || autoFillPlugin && !autoFillPlugin.enabled) {
                                autoFillPlugin.enablePlugin();
                            }
                        }
                    },
                    afterRowMove: function (rows, fId, dId, movePossible, orderChanged) {
                        component.selectedTableInstance = this.getInstance().hotId;
                        component.reorderRow(this, rows, fId)
                    },
                    beforeOnCellMouseDown: function (col, TH, e, controller) {
                        component.selectedTableInstance = this.getInstance().hotId;
                        component.selectedColumn = this.getColHeader()[TH.col];
                        let spreadSheetData = component.getSpreadSheetData(component.selectedTableInstance);
                        component.selectedRowRef = spreadSheetData && spreadSheetData.length ? spreadSheetData[TH.row] : false;
                        if (col.target.colSpan) {
                            if (col.target.colSpan != 1) {
                                col.stopImmediatePropagation()
                            }
                        }
                        /*let autoFillPlugin = this.getPlugin('autofill');
                        if(autoFillPlugin.enabled) {
                            autoFillPlugin.enablePlugin();
                        }*/
                    },
                    beforeKeyDown: function (e) {
                        if (['Backspace', 'Enter', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'].indexOf(e.code) === -1) {
                            let row = this.getSelectedLast()[0];
                            let col = this.getSelectedLast()[1];
                            let type = this.getDataType(row, col, row, col)
                            if (type == 'numeric' && e.target.value && e.target.value != '') {
                                if (this.getInstance()['hotId'].startsWith('pricing-section-partsId')) {
                                    let selectedField = this.getColHeader()[col];
                                    let selectedFieldIndex = component.partsColumn.findIndex(i => i.title == selectedField)
                                    if (selectedFieldIndex != -1) {
                                        component.commonService.allowDecimalInput(e, 10, 2);
                                    } else {
                                        component.commonService.allowDecimalInput(e, 10, 4);
                                    }
                                } else {
                                    component.commonService.allowDecimalInput(e, 10, 2);
                                }
                            }
                        }
                        /*let autoFillPlugin = this.getPlugin('autofill');
                        if(autoFillPlugin.enabled) {
                            autoFillPlugin.enablePlugin();
                        }*/
                    }
                };
                instance.updateSettings(this.baseSettings);
                instance.render();
                this.partsKitSelection = false;
            } else {
                let instanceIndex = this.spreadsheetData.findIndex((i: any) => i.id == hotId)
                if (instanceIndex != -1) {
                    if (hotId.startsWith('pricing-section-partsId')) {
                        instance.updateSettings({
                            columns: columnsData,
                            colWidths: colwidth,
                            mergeCells: [
                                { row: this.spreadsheetData[instanceIndex]['data'].length - 1, col: 0, rowspan: 1, colspan: 5 + suppliers.length },
                                { row: this.spreadsheetData[instanceIndex]['data'].length - 1, col: 11 + suppliers.length, rowspan: 1, colspan: 4 }
                            ]
                        });
                    } else if (hotId.startsWith('system-settings-pricing-section-partsId')) {
                        instance.updateSettings({
                            columns: columnsData,
                            colWidths: colwidth,
                            /*mergeCells: [
                                { row: this.spreadsheetData[instanceIndex]['data'].length - 1, col: 0, rowspan: 1, colspan: 3 + suppliers.length },
                                { row: this.spreadsheetData[instanceIndex]['data'].length - 1, col: 4 + suppliers.length, rowspan: 1, colspan: 4 }
                            ]*/
                        });
                    }

                    if(type == 'update') {
                        this.spreadsheetData[instanceIndex]['data'] = data;
                        instance.updateSettings({
                            data: data
                        });
                    }

                    instance.render()
                }
            }
        }
    }

    // Initialising hot instance
    afterInit(hotId, hotInstance) {
        this.hotId = hotId;
        this.hotInstances.push({ 'id': this.hotId, 'instance': hotInstance });
    }

    // Action colspan and header bg setting
    afterGetColHeader = (col, TH) => {
        let className = '';
        className += 'pricing_col_header_bg';
        if (className && TH) {
            TH.className = className;
        }
        if (this.hotId.startsWith('pricing-section-partsId') || this.hotId.startsWith('system-settings-pricing-section-partsId')) {
            if (TH.textContent == 'Actions') {
                TH.colSpan = 2
            } else {
                TH.colSpan = 1
            }
        }
        if (this.hotId.startsWith('pricing-section-partsId') || this.hotId.startsWith('system-settings-pricing-section-partsId')) {
            let index = this.originalPartColumn.findIndex((i: any) => i.title == TH.textContent);
            if (index == -1) {
                TH.className = 'pricing_supplier_bg'
            }
        }
    }

    Spreadsheetdata(data) {
        if (data.hasOwnProperty('parts')) {
            this.partArray = data.parts
        }
        if (data.hasOwnProperty('suppliers')) {
            this.supplierList = data.suppliers
        }
        if (data.hasOwnProperty('labourRates')) {
            this.labourRates = data.labourRates
        }
        if (data.hasOwnProperty('vatRates')) {
            this.vatRates = data.vatRates;
            this.taxLabel = data['taxLabel'];
            this.vatEnabled = data['vatEnabled'];
          if ( this.vatEnabled ) {
                this.setTaxEditor(data.vatRates, this.taxLabel);
            } else if(this.expensesColumn[4]['title'] == 'VAT' || this.expensesColumn[4]['title'] == this.taxLabel) {
              this.expensesColumn.splice(4, 1);                // TODO : incase we need supplier in feature enbale expensesColumn array and changed splice code to 2,1
              this.updateCols();
          }
        }
        if (data.hasOwnProperty('data')) {
            this.nominalCode = data.data;
        }
    }

    // Emitting upsell data
    Upsell(instance, row, col, type = 'add') {
        let selectedInstance = this.spreadsheetData.find(data => data.id == instance['hotId'])
        if (selectedInstance) {
            if (Object.keys(selectedInstance).length != 0) {
                let selectedValue = selectedInstance['data']
                if (row == (selectedValue.length - 1)) return;
                const targetRow = selectedValue[row];
                this.emitUpsellEvent({
                    'data': targetRow, 'sectionIndex': selectedInstance['sectionIndex'],
                    'listIndex': selectedInstance['listIndex'], 'rowId': row,
                    'type' : type
                })
            }
        }
    }

    UpdateSupplierPrices(newValue, row, instance) {
        if(this.suppliers && this.suppliers.length) {
            for(let i = 0; i < this.suppliers.length; i++) {
                let supplier = this.suppliers[i];
                let supplierPrice = this.getSupplierPrice(parseInt(newValue), supplier.id);
                instance.setDataAtRowProp(row, supplier.name, supplierPrice, 'cs_internal');
            }
        }
    }

    AfterCustomerPartChanged(oldValue, newValue, row, instance) {
        if(oldValue == newValue) {
            return false;
        }

        this.UpdateSupplierPrices(newValue, row, instance);

        let part = this.partArray.find(part => part.id == newValue);
        let quantity = 1;
        if(part) {
            let unitSalePrice = parseFloat(part['unitSalePrice']);
            let totalCost = this.getTotalCost(part['price'], quantity);
            let totalSalePrice = this.getTotalCost(unitSalePrice, quantity);
            let finalSalePrice = this.getFinalSalePrice(totalSalePrice, 0);
            let profit = this.getProfit(finalSalePrice, totalCost);
            let margin = this.getMargin(profit, finalSalePrice);

            this.hotData[row]['unitSalePrice'] = this.convertAndUpdatePrice(unitSalePrice);
            this.hotData[row]['totalCost'] = this.convertAndUpdatePrice(totalCost);
            this.hotData[row]['totalSalePrice'] = this.convertAndUpdatePrice(totalSalePrice);
            this.hotData[row]['finalSalePrice'] = this.convertAndUpdatePrice(finalSalePrice);
            this.hotData[row]['profit'] = this.convertAndUpdatePrice(profit);
            this.hotData[row]['margin'] = this.convertAndUpdatePrice(margin);
            this.hotData[row]['quantity'] = this.convertAndUpdatePrice(quantity);
            this.hotData[row]['markup'] = this.convertAndUpdatePrice(part['markup']);
            this.hotData[row]['generalPrice'] = this.convertAndUpdatePrice(part['price']);
            this.hotData[row]['labourTime'] = this.convertAndUpdatePrice(part['installtime']);

            if(!this.hotData[row]['id']) {
                this.hotData[row]['selectedPriceCol'] = 'generalPrice';
            }
            /*instance.setDataAtRowProp(row, "markup", part['markup'], 'cs_internal');
                instance.setDataAtRowProp(row, "unitSalePrice", part['unitSalePrice'], 'cs_internal');
                instance.setDataAtRowProp(row, "generalPrice", part['price'], 'cs_internal');
                instance.setDataAtRowProp(row, "totalCost", totalCost.toFixed(2), 'cs_internal');
                instance.setDataAtRowProp(row, "totalSalePrice", totalSalePrice.toFixed(2), 'cs_internal');
                instance.setDataAtRowProp(row, "finalSalePrice", finalSalePrice.toFixed(2), 'cs_internal');
                instance.setDataAtRowProp(row, "profit", profit.toFixed(2), 'cs_internal');
                instance.setDataAtRowProp(row, "margin", margin.toFixed(2), 'cs_internal');
                instance.setDataAtRowProp(row, "quantity", quantity, 'cs_internal');*/
            //this.updateSpreadsheetData(instance.hotId, row, 'selectedPriceCol', 'generalPrice');
        }
    }

    AfterCustomerMarkupChanged(oldValue, newValue, row, instance) {
        if(oldValue == newValue) {
            return false;
        }
        let hotData = this.hotData[row];
        let quantity = hotData['quantity'];
        let selectedCol = hotData.hasOwnProperty('selectedPriceCol') ? hotData['selectedPriceCol'] : 'generalPrice';
        let selectedPrice = hotData[selectedCol];

        let unitSalePrice = this.getUnitSalePrice(selectedPrice, newValue);
        let totalCost = this.getTotalCost(selectedPrice, quantity);
        let totalSalePrice = this.getTotalCost(unitSalePrice, quantity);
        let finalSalePrice = this.getFinalSalePrice(totalSalePrice, hotData['discount']);
        let profit = this.getProfit(finalSalePrice, totalCost);
        let margin = this.getMargin(profit, finalSalePrice);

        this.hotData[row]['unitSalePrice'] = this.convertAndUpdatePrice(unitSalePrice);
        this.hotData[row]['totalCost'] = this.convertAndUpdatePrice(totalCost);
        this.hotData[row]['totalSalePrice'] = this.convertAndUpdatePrice(totalSalePrice);
        this.hotData[row]['finalSalePrice'] = this.convertAndUpdatePrice(finalSalePrice);
        this.hotData[row]['profit'] = this.convertAndUpdatePrice(profit);
        this.hotData[row]['margin'] = this.convertAndUpdatePrice(margin);

        instance.setDataAtRowProp(row, "unitSalePrice", unitSalePrice.toFixed(2), 'cs_internal');
        instance.setDataAtRowProp(row, "totalCost", totalCost.toFixed(2), 'cs_internal');
        instance.setDataAtRowProp(row, "totalSalePrice", totalSalePrice.toFixed(2), 'cs_internal');
        instance.setDataAtRowProp(row, "finalSalePrice", finalSalePrice.toFixed(2), 'cs_internal');
        instance.setDataAtRowProp(row, "profit", profit.toFixed(2), 'cs_internal');
        instance.setDataAtRowProp(row, "margin", margin.toFixed(2), 'cs_internal');
    }

    AfterCustomerUnitPriceChanged(oldValue, newValue, row, instance) {
        if(oldValue == newValue) {
            return false;
        }
        let hotData = this.hotData[row];
        let quantity = hotData['quantity'];
        let selectedCol = hotData.hasOwnProperty('selectedPriceCol') ? hotData['selectedPriceCol'] : 'generalPrice';
        let selectedPrice = hotData[selectedCol];

        let unitSalePrice = !isNaN(parseFloat(newValue)) ? parseFloat(newValue) : 0;
        let totalCost = this.getTotalCost(selectedPrice, quantity);
        let totalSalePrice = this.getTotalCost(unitSalePrice, quantity);
        let finalSalePrice = this.getFinalSalePrice(totalSalePrice, hotData['discount']);
        let profit = this.getProfit(finalSalePrice, totalCost);
        let margin = this.getMargin(profit, finalSalePrice);

        let markup = this.getMarkupFromUnitSalePrice(selectedPrice, unitSalePrice);
        if(markup != 'NC') {
            let markupToShow = markup == 'N/A' ? markup : parseFloat(markup).toFixed(2);
            instance.setDataAtRowProp(row, "markup", markupToShow, 'cs_internal');
        }

        this.hotData[row]['totalSalePrice'] = this.convertAndUpdatePrice(totalSalePrice);
        this.hotData[row]['finalSalePrice'] = this.convertAndUpdatePrice(finalSalePrice);
        this.hotData[row]['profit'] = this.convertAndUpdatePrice(profit);
        this.hotData[row]['margin'] = this.convertAndUpdatePrice(margin);

        /*instance.setDataAtRowProp(row, "totalSalePrice", totalSalePrice.toFixed(2), 'cs_internal');
        instance.setDataAtRowProp(row, "finalSalePrice", finalSalePrice.toFixed(2), 'cs_internal');
        instance.setDataAtRowProp(row, "profit", profit.toFixed(2), 'cs_internal');
        instance.setDataAtRowProp(row, "margin", margin.toFixed(2), 'cs_internal');*/
    }

    convertAndUpdatePrice(price) {
        // To handle null values
        if(price == null) {
            return "";
        }else if(price == 0){
            return 0.00;
        }else if(!price){
            return "";
        }

        // To handle Number / NA values
        let newPrice = parseFloat(price);
        if(isNaN(newPrice)) {
            return price;
        }

        return newPrice.toFixed(2);
    }

    AfterSettingsPartChanged(oldValue, newValue, row, instance) {
        if(oldValue == newValue) {
            return false;
        }

        this.UpdateSupplierPrices(newValue, row, instance);

        let part = this.partArray.find(part => part.id == newValue);
        if(part) {
            let unitPrice = this.convertAndUpdatePrice(part['price']);
            let totalCost = this.convertAndUpdatePrice(this.getTotalCost(part['price'], this.hotData[row]['quantity']));
            this.hotData[row]['costPrice'] = unitPrice;
            this.hotData[row]['totalCost'] = totalCost;
            this.hotData[row]['labourTime'] = this.convertAndUpdatePrice(part['installtime']);
            if(!this.hotData[row]['id']) {
                this.hotData[row]['selectedPriceCol'] = 'costPrice';
            }
            //instance.setDataAtRowProp(row, "unitPrice", part['unitPrice'], 'cs_internal');
        }
    }

    getMarkupFromUnitSalePrice(unitCost, unitSalePrice) {
        let markup = 'N/A';
        let cost = parseFloat(unitCost);
        let price = parseFloat(unitSalePrice);
        cost = isNaN(cost) ? 0 : cost;
        price = isNaN(price) ? 0 : price;

        if(cost > 0) {
            let markupCalculated = ((price - cost) / cost) * 100;
            if(markupCalculated == parseFloat(markupCalculated.toFixed(2))) {
                markup = markupCalculated.toString();
            }
        } else {
            markup = 'NC';
        }
        return markup;
    }

    getUnitSalePrice(cost, markup) {
        let unitCost = parseFloat(cost);
        let markupGiven = parseFloat(markup);
        markupGiven = isNaN(markupGiven) ? 0 : markupGiven;
        unitCost = isNaN(unitCost) ? 0 : unitCost;
        return unitCost + (unitCost * (markupGiven / 100));
    }

    getTotalCost(unitCost, quantity) {
        let cost = parseFloat(unitCost);
        let quantityConverted = parseFloat(quantity);

        cost = isNaN(cost) ? 0 : cost;
        quantityConverted = isNaN(quantityConverted) ? 0 : quantityConverted;

        return cost * quantityConverted;
    }

    getFinalSalePrice(totalSalePrice, discount) {
        let total = parseFloat(totalSalePrice);
        let discountAmount = parseFloat(discount);

        total = isNaN(total) ? 0 : total;
        discountAmount = isNaN(discountAmount) ? 0 : discountAmount;

        return total - discountAmount;
    }

    getMargin(profit, totalSalePrice) {
        let profitAmount = parseFloat(profit);
        let price = parseFloat(totalSalePrice);

        price = isNaN(price) ? 0 : price;
        profitAmount = isNaN(profitAmount) ? 0 : profitAmount;

        return price != 0 ? (profitAmount / price) * 100 : 0;
    }

    getProfit(totalSalePrice, totalCost) {
        let price = parseFloat(totalSalePrice);
        let cost = parseFloat(totalCost);

        price = isNaN(price) ? 0 : price;
        cost = isNaN(cost) ? 0 : cost;
        return price - cost;
    }

    AfterSelectedSupplierValueChanged(oldValue, newValue, row, instance, isFromSettings) {
        if(oldValue == newValue) {
            return false;
        }
        let hotData = this.hotData[row];
        let quantity = hotData['quantity'];
        if(isFromSettings) {
            let totalCost = this.getTotalCost(newValue, quantity).toFixed(2);
            //instance.setDataAtRowProp(row, "totalCost", totalCost, 'cs_internal');
            this.hotData[row]['totalCost'] = this.convertAndUpdatePrice(totalCost);
        } else {
            let unitSalePrice = this.getUnitSalePrice(newValue, hotData['markup']);
            let totalCost = this.getTotalCost(newValue, quantity);
            let totalSalePrice = this.getTotalCost(unitSalePrice, quantity);
            let finalSalePrice = this.getFinalSalePrice(totalSalePrice, hotData['discount']);
            let profit = this.getProfit(finalSalePrice, totalCost);
            let margin = this.getMargin(profit, finalSalePrice);

            this.hotData[row]['unitSalePrice'] = this.convertAndUpdatePrice(unitSalePrice);
            this.hotData[row]['totalCost'] = this.convertAndUpdatePrice(totalCost);
            this.hotData[row]['totalSalePrice'] = this.convertAndUpdatePrice(totalSalePrice);
            this.hotData[row]['finalSalePrice'] = this.convertAndUpdatePrice(finalSalePrice);
            this.hotData[row]['profit'] = this.convertAndUpdatePrice(profit);
            this.hotData[row]['margin'] = this.convertAndUpdatePrice(margin);

            /*instance.setDataAtRowProp(row, "unitSalePrice", unitSalePrice.toFixed(2), 'cs_internal');
            instance.setDataAtRowProp(row, "totalCost", totalCost.toFixed(2), 'cs_internal');
            instance.setDataAtRowProp(row, "totalSalePrice", totalSalePrice.toFixed(2), 'cs_internal');
            instance.setDataAtRowProp(row, "finalSalePrice", finalSalePrice.toFixed(2), 'cs_internal');
            instance.setDataAtRowProp(row, "profit", profit.toFixed(2), 'cs_internal');
            instance.setDataAtRowProp(row, "margin", margin.toFixed(2), 'cs_internal');*/
        }
    }

    // Invoke on changes
    afterChange(changes, source, hotId) {
        if(source == 'cs_internal') {
            return;
        }
        let instance = this.hotInstances.find(instance => instance.id == hotId);
        if (instance) {
            const current_instance: any = instance['instance'];

            if(source != 'populateFromArray' && changes && changes[0]) {
                let changeObj = changes[0];
                let row = changeObj[0];
                let oldValue = changeObj[2];
                let newValue = changeObj[3];
                if (oldValue != newValue) {
                    let i = this.spreadsheetData
                        .findIndex(data => data.id == hotId);
                    if (hotId.startsWith('pricing-section-expensesId') ||
                        hotId.startsWith('system-settings-pricing-section-expensesId')) {
                        switch (changeObj[1]) {
                            case 'supplierId':
                                if (i !== -1) {
                                    // set nominal code id on supplier selection
                                    let lineItem = this.spreadsheetData[i]['data'][row];
                                    let selectedSupplier = this.supplierList.find((item: any) => item.id == lineItem['supplierId']);
                                    if (selectedSupplier && selectedSupplier['nominalCode']) {
                                        if (selectedSupplier['nominalCode']['id']) {
                                            lineItem['settingsNominalCodeId'] = selectedSupplier['nominalCode']['id'];
                                        } else {
                                            lineItem['settingsNominalCodeId'] = null;
                                        }
                                    }
                                }
                                current_instance.render();
                        }
                    }
                }
            }

            if (this.spreadsheetData.length != 0) {
                this.selectedPricingData = this.spreadsheetData.find(data => data.id == hotId);
                if (this.selectedPricingData) {
                    if (Object.keys(this.selectedPricingData).length != 0) {
                        this.hotData = this.selectedPricingData['data']
                    }
                    if (this.selectedPricingData.hasOwnProperty('suppliers')) {
                        this.suppliers = this.selectedPricingData['suppliers']
                    }
                    // Execute on first time load
                    if (source === 'loadData') {
                        if (hotId.startsWith('pricing-section-labourId')) {
                            current_instance.alter('insert_row', this.hotData.length, 2);
                            current_instance.updateSettings({
                                mergeCells: [
                                    { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 5 },
                                    { row: this.hotData.length - 1, col: 10, rowspan: 1, colspan: 2 }
                                ],
                            });
                        }
                        if (hotId.startsWith('system-settings-pricing-section-labourId')) {
                            current_instance.alter('insert_row', this.hotData.length, 2);
                            current_instance.updateSettings({
                                mergeCells: [
                                    { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 5 },
                                    { row: this.hotData.length - 1, col: 8, rowspan: 1, colspan: 2 }
                                ],
                            });
                        }
                        if (hotId.startsWith('pricing-section-expensesId')) {
                            current_instance.alter('insert_row', this.hotData.length, 2);
                            current_instance.updateSettings({
                                mergeCells: [
                                    { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 2 },
                                    { row: this.hotData.length - 1, col: 6, rowspan: 1, colspan: 2 }
                                ]
                            });
                        }
                        if (hotId.startsWith('system-settings-pricing-section-expensesId')) {
                            current_instance.alter('insert_row', this.hotData.length, 2);
                            current_instance.updateSettings({
                                mergeCells: [
                                    { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 2 },
                                    { row: this.hotData.length - 1, col: 6, rowspan: 1, colspan: 2 }
                                ]
                                /*mergeCells: [
                                    { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 3 }
                                ]*/
                            });
                        }
                        if (hotId.startsWith('pricing-section-partsId')) {
                            current_instance.alter('insert_row', this.hotData.length, 2);
                            current_instance.updateSettings({
                                mergeCells: [
                                    { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 5 + this.suppliers.length },
                                    { row: this.hotData.length - 1, col: 11 + this.suppliers.length, rowspan: 1, colspan: 4 }
                                ]
                            });
                        }
                        if (hotId.startsWith('system-settings-pricing-section-partsId')) {
                            current_instance.alter('insert_row', this.hotData.length, 1);
                            current_instance.updateSettings({
                                /*mergeCells: [
                                    { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 3 + this.suppliers.length },
                                    { row: this.hotData.length - 1, col: 4 + this.suppliers.length, rowspan: 1, colspan: 4 }
                                ],*/
                            });
                        }
                    }

                    // Execute on edit & Execute on auto fill
                    if (source === 'edit' || source == 'Autofill.fill') {
                        //let isFromEdit = source === 'edit';
                        for(let changeIndex = 0; changeIndex < changes.length; changeIndex++) {
                            let currentChange = changes[changeIndex];
                            this.handleItemsSaveAction(currentChange, hotId, current_instance);
                        }
                    }
                }
            }
        }
    }

    handleItemsSaveAction(change, hotId, current_instance) {
        const rowId = change[0],
            colName = change[1],
            oldValue = change[2],
            currentValue = change[3];
        let isSettingPart = false;

        //last row - insert new row
        if (this.hotData.length === rowId + 2 && hotId.startsWith('pricing-section-partsId')) {
            if (this.hotData[rowId]['partsAndPricesId'] && this.hotData[rowId]['quantity']) {
                current_instance.alter('insert_row', this.hotData.length);
                current_instance.updateSettings({
                    mergeCells: [
                        { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 5 + this.suppliers.length },
                        { row: this.hotData.length - 1, col: 11 + this.suppliers.length, rowspan: 1, colspan: 4 }
                    ]
                });
            }
        }

        if(hotId.startsWith('pricing-section-partsId')) {
            if(colName == 'partsAndPricesId') {
                this.AfterCustomerPartChanged(oldValue, currentValue, rowId, current_instance);
            }
            if(colName == 'markup') {
                this.AfterCustomerMarkupChanged(oldValue, currentValue, rowId, current_instance);
            }
            if(colName == 'unitSalePrice') {
                this.AfterCustomerUnitPriceChanged(oldValue, currentValue, rowId, current_instance);
            }
        }

        if(hotId.startsWith('system-settings-pricing-section-partsId')) {
            isSettingPart = true;
            if(colName == 'partsAndPricesId') {
                this.AfterSettingsPartChanged(oldValue, currentValue, rowId, current_instance);
            }
        }

        let updatedCol = current_instance.propToCol(colName);
        let cellMetaData = current_instance.getCellMeta(rowId, updatedCol);

        if(cellMetaData.hasOwnProperty("isSupplier") && cellMetaData.hasOwnProperty('selectedUnitPrice') && cellMetaData.selectedUnitPrice) {
            this.AfterSelectedSupplierValueChanged(oldValue, currentValue, rowId, current_instance, isSettingPart);
        }

        if (this.hotData.length === rowId + 1 && hotId.startsWith('system-settings-pricing-section-partsId')) {
            if (this.hotData[rowId]['partsAndPricesId'] && this.hotData[rowId]['quantity']) {
                current_instance.alter('insert_row', this.hotData.length);
                current_instance.updateSettings({
                    /*mergeCells: [
                        { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 3 + this.suppliers.length },
                        { row: this.hotData.length - 1, col: 4 + this.suppliers.length , rowspan: 1, colspan: 4 }
                    ]*/
                });
            }
        }

        if (this.hotData.length === rowId + 2 && hotId.startsWith('pricing-section-labourId')) {
            if (this.hotData[rowId]['description'] != null && this.hotData[rowId]['description'] != '') {
                /*&& this.hotData[rowId]['labourRatesId'] != null && this.hotData[rowId]['quantity'] != null
                && this.hotData[rowId]['costPrice'] != null && this.hotData[rowId]['salePrice'] != null
                && this.hotData[rowId]['totalCost'] != null && this.hotData[rowId]['totalSalePrice'] != null
                && this.hotData[rowId]['profit'] != null && this.hotData[rowId]['margin'] != null*/
                current_instance.alter('insert_row', this.hotData.length);
                current_instance.updateSettings({
                    mergeCells: [
                        { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 5 },
                        { row: this.hotData.length - 1, col: 10, rowspan: 1, colspan: 2 }
                    ]
                });
            }
        }
        if (this.hotData.length === rowId + 2 && hotId.startsWith('system-settings-pricing-section-labourId')) {
            if (this.hotData[rowId]['description'] != null && this.hotData[rowId]['description'] != '') {
                /* && this.hotData[rowId]['labourRatesId'] != null && this.hotData[rowId]['quantity'] != null
                && this.hotData[rowId]['costPrice'] != null && this.hotData[rowId]['totalCost'] != null*/
                current_instance.alter('insert_row', this.hotData.length);
                current_instance.updateSettings({
                    mergeCells: [
                        { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 5},
                        { row: this.hotData.length - 1, col: 8, rowspan: 1, colspan: 2 }
                        // { row: this.hotData.length - 1, col: 6 + this.suppliers.length, rowspan: 1, colspan: 9 }
                    ]
                });
            }
        }

        if (this.hotData.length === rowId + 2 && hotId.startsWith('pricing-section-expensesId')) {
            if (this.hotData[rowId]['description'] != null && this.hotData[rowId]['description'] != '') {
                /* && this.hotData[rowId]['costPrice'] != null && this.hotData[rowId]['salePrice'] != null
                && this.hotData[rowId]['profit'] != null && this.hotData[rowId]['margin'] != null*/
                current_instance.alter('insert_row', this.hotData.length);
                current_instance.updateSettings({
                    mergeCells: [
                        { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 2 },
                        { row: this.hotData.length - 1, col: 6, rowspan: 1, colspan: 2 }
                    ]
                });
            }
        }
        if (this.hotData.length === rowId + 2 && hotId.startsWith('system-settings-pricing-section-expensesId')) {
            if (this.hotData[rowId]['description'] != null && this.hotData[rowId]['description'] != '') {
                // && this.hotData[rowId]['costPrice'] != null
                current_instance.alter('insert_row', this.hotData.length);
                current_instance.updateSettings({
                    /*mergeCells: [
                        { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 3 }
                    ]*/
                    mergeCells: [
                        { row: this.hotData.length - 1, col: 0, rowspan: 1, colspan: 2 },
                        { row: this.hotData.length - 1, col: 6, rowspan: 1, colspan: 2 }
                    ]
                });
            }
        }

        // auto save cutomer parts
        if (hotId.startsWith('pricing-section-partsId')) {
            if (this.hotData[rowId]['partsAndPricesId'] && this.hotData[rowId]['quantity'] && (oldValue != currentValue || change[1] == "totalCost")) {
                this.hotData[rowId].sectionIndex = this.selectedPricingData['sectionIndex']
                this.hotData[rowId].listIndex = this.selectedPricingData['listIndex']
                this.hotData[rowId].sectionId = this.selectedPricingData['sectionId']
                this.hotData[rowId].listId = this.selectedPricingData['listId']
                this.emitSaveChangeEvent({ 'items': this.hotData[rowId], 'rowId': rowId });
            }
        }

        // auto save parts(option and proposal template)
        if (hotId.startsWith('system-settings-pricing-section-partsId')) {
            // && (oldValue != currentValue)
            if (this.hotData[rowId]['partsAndPricesId'] && this.hotData[rowId]['quantity']) {
                this.hotData[rowId].sectionIndex = this.selectedPricingData['sectionIndex']
                this.hotData[rowId].listIndex = this.selectedPricingData['listIndex']
                this.hotData[rowId].sectionId = this.selectedPricingData['sectionId']
                this.hotData[rowId].listId = this.selectedPricingData['listId']
                this.emitSaveChangeEvent({ 'items': this.hotData[rowId], 'rowId': rowId });
            }
        }

        // auto save labour(option and proposal template)
        if (hotId.startsWith('pricing-section-labourId')) {
            /*&& this.hotData[rowId]['labourRatesId'] != null && this.hotData[rowId]['quantity'] != null
                && this.hotData[rowId]['costPrice'] != null && this.hotData[rowId]['salePrice'] != null
                && this.hotData[rowId]['totalCost'] != null && this.hotData[rowId]['totalSalePrice'] != null
                && this.hotData[rowId]['profit'] != null && this.hotData[rowId]['margin'] != null */
            if (this.hotData[rowId]['description'] != null && oldValue != currentValue) {
                this.hotData[rowId].sectionIndex = this.selectedPricingData['sectionIndex']
                this.hotData[rowId].sectionId = this.selectedPricingData['sectionId']
                this.hotData[rowId].listId = this.selectedPricingData['listId']
                this.emitSaveChangeEvent({ 'items': this.hotData[rowId], 'rowId': rowId });
            }
        }

        // auto save cutomer labour
        if (hotId.startsWith('system-settings-pricing-section-labourId')) {
            /*&& this.hotData[rowId]['labourRatesId'] != null && this.hotData[rowId]['quantity'] != null
                && this.hotData[rowId]['costPrice'] != null
                && this.hotData[rowId]['totalCost'] != null*/
            if (this.hotData[rowId]['description'] != null && oldValue != currentValue) {
                this.hotData[rowId].sectionIndex = this.selectedPricingData['sectionIndex']
                this.hotData[rowId].sectionId = this.selectedPricingData['sectionId']
                this.hotData[rowId].listId = this.selectedPricingData['listId']
                this.emitSaveChangeEvent({ 'items': this.hotData[rowId], 'rowId': rowId });
            }
        }

        // auto save expenses(option and proposal template)
        if (hotId.startsWith('pricing-section-expensesId')) {
            /*&& this.hotData[rowId]['costPrice'] != null && this.hotData[rowId]['salePrice'] != null
                && this.hotData[rowId]['profit'] != null && this.hotData[rowId]['margin'] != null */
            if (this.hotData[rowId]['description'] != '' && this.hotData[rowId]['description'] != null && oldValue != currentValue) {
                if(this.hotData[rowId]['vatRate'] == null || this.hotData[rowId]['vatRate'] == '') {
                    this.hotData[rowId]['vatRate'] = 3;//Todo: Default vat rate fix required
                }
                this.hotData[rowId].sectionIndex = this.selectedPricingData['sectionIndex']
                this.hotData[rowId].sectionId = this.selectedPricingData['sectionId']
                this.hotData[rowId].listId = this.selectedPricingData['listId']
                this.emitSaveChangeEvent({'items': this.hotData[rowId], 'rowId': rowId, 'colName' : colName});
            }
        }
        // Auto save customer expenses
        if (hotId.startsWith('system-settings-pricing-section-expensesId')) {
            if (this.hotData[rowId]['description'] != '' && this.hotData[rowId]['description'] != null && this.hotData[rowId]['costPrice'] != null && oldValue != currentValue) {
                if(this.hotData[rowId]['vatRate'] == null || this.hotData[rowId]['vatRate'] == '') {
                    this.hotData[rowId]['vatRate'] = 3;//Todo: Default vat rate fix required
                }
                this.hotData[rowId].sectionIndex = this.selectedPricingData['sectionIndex']
                this.hotData[rowId].sectionId = this.selectedPricingData['sectionId']
                this.hotData[rowId].listId = this.selectedPricingData['listId']
                this.emitSaveChangeEvent({'items': this.hotData[rowId], 'rowId': rowId, 'colName' : colName});
            }
        }
    }

    emptyCellValidator = (value, callback) => {
        if (value === '' && !this.notShowEmptyCell) {
            this.toastBox.show('Empty cell not allowed', 1000);
        }
        this.notShowEmptyCell = false
        callback(true);
    }

    numericValidator = (value, callback) => {
        let dataValid = true;
        if (value && value != '') {
            if (isNaN(value)) {
                dataValid = false;
                this.toastBox.show('Invalid data', 1000);
            }
        }
        callback(dataValid);
    }

    // Row delete for parts,labour and expenses
    onRowDelete(instance, row, deleteKey) {
        let that = this;
        that.confirmationBoxHelper.getConfirmation('This row has not been saved yet, are you sure you want to delete it?', that.ajsScope)
            .then(function () {
                const hotinstance: any = that.hotInstances.find(hotIns => hotIns.id == instance.hotId)['instance']
                let selectedInstance = that.spreadsheetData.find(data => data.id == instance['hotId'])
                let indexValue = that.spreadsheetData.findIndex(data => data.id == instance['hotId'])
                if (selectedInstance) {
                    if (Object.keys(selectedInstance).length != 0) {
                        if (selectedInstance.hasOwnProperty('suppliers')) {
                            that.suppliers = selectedInstance['suppliers']
                        }
                        let selectedValue = selectedInstance['data']
                        if (row == (selectedValue.length - 1)) return;
                        const targetRow = selectedValue[row];
                        targetRow['sectionIndex'] = selectedInstance['sectionIndex']
                        targetRow['listIndex'] = selectedInstance['listIndex']
                        if (that.spreadsheetData[indexValue]['data'].length != 0) {
                            //Todo: Need to find better solution instead of checking < 4
                            if (targetRow.hasOwnProperty('id') && targetRow['id'] > 0) {
                                let pricingIndex = that.spreadsheetData[indexValue]['data'].findIndex(item => item.id == targetRow['id']);
                                if(pricingIndex != -1) {
                                    that.spreadsheetData[indexValue]['data'].pop();
                                }
                            }
                        }
                        hotinstance.alter('remove_row', row);
                        hotinstance.render();
                        that.selectedPricingData = that.spreadsheetData.find(data => data.id == instance.hotId)
                        if (deleteKey == 'expenses') {
                            hotinstance.alter('insert_row', that.spreadsheetData[indexValue]['data'].length);
                            hotinstance.updateSettings({
                                mergeCells: [
                                    { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 0, rowspan: 1, colspan: 3 },
                                    { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 5, rowspan: 1, colspan: 2 }
                                ]
                            });
                        }
                        if (deleteKey == 'labour') {
                            hotinstance.alter('insert_row', that.spreadsheetData[indexValue]['data'].length);
                            hotinstance.updateSettings({
                                mergeCells: [
                                    { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 0, rowspan: 1, colspan: 5 },
                                    { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 10, rowspan: 1, colspan: 2 }
                                ]
                            });
                        }
                        if (deleteKey == "parts") {
                            hotinstance.alter('insert_row', that.spreadsheetData[indexValue]['data'].length);
                            hotinstance.updateSettings({
                                mergeCells: [
                                    { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 0, rowspan: 1, colspan: 5 + that.suppliers.length },
                                    { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 11 + that.suppliers.length, rowspan: 1, colspan: 4 }
                                ]
                            });
                        }
                        if (deleteKey == 'systemSettingsParts') {
                            //hotinstance.alter('insert_row', that.spreadsheetData[indexValue]['data'].length);
                            hotinstance.updateSettings({
                                /*mergeCells: [
                                    { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 0, rowspan: 1, colspan: 3 + that.suppliers.length },
                                    { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 4 + that.suppliers.length , rowspan: 1, colspan: 4 }
                                ],*/
                            });
                        }
                        if (deleteKey == 'systemSettingsLabour') {
                            hotinstance.alter('insert_row', that.spreadsheetData[indexValue]['data'].length);
                            hotinstance.updateSettings({
                                mergeCells: [
                                    { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 0, rowspan: 1, colspan: 5 },
                                    { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 8, rowspan: 1, colspan: 2 }
                                ],

                            });
                        }
                        if (deleteKey == 'systemSettingsExpenses') {
                            hotinstance.alter('insert_row', that.spreadsheetData[indexValue]['data'].length);
                            hotinstance.updateSettings({
                                mergeCells: [
                                    { row: that.spreadsheetData[indexValue]['data'].length - 1, col: 0, rowspan: 1, colspan: 3 }
                                ]
                            })
                        }
                        if (targetRow) {
                            that.emitdeleteChangeEvent({ 'data': targetRow, 'rowId': row })
                        }
                    }
                }
            }, function () {
                return false;
            });
    }

    emitdeleteChangeEvent(id) {
        this.deletechange.emit(id);
    }

    getdeleteChangeEmitter() {
        return this.deletechange;
    }

    emitUpsellEvent(row) {
        this.upsellEvent.emit(row)
    }

    getUpsellEvent() {
        return this.upsellEvent;
    }

    emitNewParts(details) {
        this.newPartName.emit(details)
    }

    getNewPart() {
        return this.newPartName;
    }

    // Emit data while adding new parts
    emitNewPartDetails(that, term) {
        let sectionDetails: object = {};
        let selectedSection = that.spreadsheetData.find(data => data.id == that.selectedTableInstance)
        sectionDetails['sectionIndex'] = selectedSection['sectionIndex']
        sectionDetails['listIndex'] = selectedSection['listIndex']
        sectionDetails['sectionId'] = selectedSection['sectionId']
        sectionDetails['listId'] = selectedSection['listId']
        sectionDetails['partName'] = term
        $("#select2-drop-mask").click()
        that.emitNewParts({ 'data': sectionDetails })
    }

    // Calculate price on supplier and general price selection
    calculateSelectedPrice(component, selectedTable) {
        let rowSelected = selectedTable.row;
        let selectedSupplier = component.getColHeader()[selectedTable.col];
        this.selectedPricingData = this.spreadsheetData.find(data => data.id == this.selectedTableInstance)
        let selectedInstanceIndex = this.spreadsheetData.findIndex(data => data.id == this.selectedTableInstance)
        this.selectedPartsData = this.partArray.find((i: any) => i.id == this.selectedPricingData['data'][rowSelected]['partsAndPricesId'])
        let supplierData = this.selectedPartsData.suppliersData.find((i: any) => i.supplierName == selectedSupplier)
        if (supplierData) {
            this.selectedPricingData['data'][rowSelected]['supplierId'] = supplierData.supplierId
            //this.selectedPricingData['data'][rowSelected]['lowestPrice'] = supplierData.supplierPrice
        } else {
            this.selectedPricingData['data'][rowSelected]['supplierId'] = null
            //this.selectedPricingData['data'][rowSelected]['lowestPrice'] = this.selectedPartsData.price
        }

        let selectedPriceColName = component.colToProp(selectedTable.col);
        this.selectedPricingData['data'][rowSelected]['selectedPriceCol'] = selectedPriceColName;

        let selectedPrice = parseFloat(this.selectedPricingData['data'][rowSelected][selectedPriceColName]);
        let quantity = parseFloat(this.selectedPricingData['data'][rowSelected]['quantity']);
        let totalCost = selectedPrice * quantity;

        if(this.selectedTableInstance.startsWith('pricing-section-partsId')){
            let markup = parseFloat(this.selectedPricingData['data'][rowSelected]['markup']);
            if(isNaN(markup)) {
                markup = 0;
            }
            let unitSalePrice = selectedPrice + (selectedPrice * (markup / 100));
            let totalSalePrice = unitSalePrice * quantity;
            let profit = totalSalePrice - totalCost;
            this.selectedPricingData['data'][rowSelected]['totalCost'] = totalCost.toFixed(2);
            this.selectedPricingData['data'][rowSelected]['unitSalePrice'] = unitSalePrice.toFixed(2);
            this.selectedPricingData['data'][rowSelected]['totalSalePrice'] = totalSalePrice.toFixed(2);
            this.selectedPricingData['data'][rowSelected]['profit'] = profit.toFixed(2);
            component.setDataAtRowProp(rowSelected, "totalCost", totalCost, 'cs_internal');
            component.setDataAtRowProp(rowSelected, "unitSalePrice", unitSalePrice, 'cs_internal');
            component.setDataAtRowProp(rowSelected, "totalSalePrice", totalSalePrice, 'cs_internal');
            component.setDataAtRowProp(rowSelected, "profit", profit, 'cs_internal');
        }else if(this.selectedTableInstance.startsWith('system-settings-pricing-section-partsId')){
            /*this.selectedPricingData['data'][rowSelected]['totalCost'] = totalCost.toFixed(2);
            component.setDataAtRowProp(rowSelected, "totalCost", totalCost, 'cs_internal');*/
        }

        let cellMeta = component.getCellMeta(rowSelected, selectedTable.col);

        for (var i = 0; i < component.countCols() - 1; i++) {
            let cellMeta = component.getCellMeta(0, i);
            if (cellMeta.hasOwnProperty('selectedUnitPrice') && cellMeta.selectedUnitPrice) {
                cellMeta.selectedUnitPrice = false;
                break;
            }
        }

        cellMeta.selectedUnitPrice = true;

        this.spreadsheetData[selectedInstanceIndex] = this.selectedPricingData;

        this.hotData[rowSelected].sectionIndex = this.selectedPricingData['sectionIndex'];
        this.hotData[rowSelected].listIndex = this.selectedPricingData['listIndex'];
        this.hotData[rowSelected].sectionId = this.selectedPricingData['sectionId'];
        this.hotData[rowSelected].listId = this.selectedPricingData['listId'];
        this.emitSaveChangeEvent({ 'items': this.hotData[rowSelected], 'rowId': rowSelected });
    }

    reorderRow(this, component, rows, fId) {
        var hotObj = this.spreadsheetData.find(i => i.id == this.selectedTableInstance)
        var hotIndex = this.spreadsheetData.findIndex(i => i.id == this.selectedTableInstance)
        const hotinstance: any = this.hotInstances.find(hotIns => hotIns.id == this.selectedTableInstance)['instance']
        if (hotObj.hasOwnProperty('data') && hotObj['data'] && hotObj['data'].length) {
            let value = hotObj['data'];
            for (var i = 0; i < rows.length; i++) {
                value.splice(fId, 0, value.splice(rows[i], 1)[0]);
            }
            this.spreadsheetData[hotIndex]['data'] = value;
            this.selectedPricingData['data'] = value;
            // reorderRow(value)
            hotinstance.render()
        }
    }

    // Funtion invoke on select general and supplier
    selectPrice(event, instance, row, col) {
        if (event.target.checked) {
            instance.selectCell(row, col + 1);
            this.selectedTableInstance = instance.getInstance().hotId;
            let TH = { 'col': col, 'row': row };
            let columnIndex = this.originalPartColumn.findIndex(i => i.title == instance.getColHeader()[TH.col])
            let columnTitle = this.originalPartColumn.find(i => i.title == instance.getColHeader()[TH.col])
            if ((columnIndex == -1 || columnTitle['title'] == 'General price' || columnTitle['title'] == 'Unit price') && (this.selectedTableInstance.startsWith('pricing-section-partsId') || this.selectedTableInstance.startsWith('system-settings-pricing-section-partsId'))) {
                this.calculateSelectedPrice(instance, TH);
            }
        }
    }

    setNominalCodeEditor(searchData){
        let nominalEditor = {
            title: 'Nominal code',
            data: 'settingsNominalCodeId',
            validator: 'emptyCellValidator',
            placeholder: 'Please select',
            allowEmpty: false,
            className: 'overflow_handsontable select_icon',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                if (value) {
                    let nominalCode = this.nominalCode.find((item: any) => item.id == value);
                    let nominalValue;
                    if (nominalCode) {
                        nominalValue = nominalCode['description'];
                    }
                    value = nominalValue
                }else{
                    value = '';
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            },
            editor: HansontableSelect2({
                dropdownCssClass: 'handsontable-select',
                dropdownAutoWidth: true,
                data: searchData,
            }),
        };

        //this.systemSettingsexpensesColumn.splice(1, 1, nominalEditor);  // TODO : incase we need supplier in feature enbale systemSettingsexpensesColumn array and changed splice code to 2,1
        this.expensesColumn.splice(1, 1, nominalEditor);                // TODO : incase we need supplier in feature enbale expensesColumn array and changed splice code to 2,1
        this.updateCols();
    }

    setTaxEditor(searchData,taxLabel){
        let vatEditor = {
            title: taxLabel,
            data: 'vatRate',
            validator: 'emptyCellValidator',
            placeholder: 'Please select',
            allowEmpty: false,
            className: 'overflow_handsontable select_icon',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                cellProperties.readOnly = false;
                cellProperties.placeholder = "";
                cellProperties.className = 'overflow_handsontable select_icon';
                if(!value || value == '') {
                    value = 3;
                }
                if (value && value != '') {
                    //let valueToCheck = parseFloat(value).toFixed(2);
                    let vatRate = this.vatRates.find((item: any) => item.id == value);
                    let vat;
                    if (vatRate) {
                        vat = vatRate['description'];
                    }
                    value = vat;
                }

                if (row == instance.countRows() - 1) {
                    value = "";
                    cellProperties.readOnly = true;
                    cellProperties.readOnlyCellClassName = 'calculate-class';
                    cellProperties.className = '';
                    cellProperties.placeholder = "";
                    //cellProperties.readOnlyCellClassName = "calculate-class-margin";
                } else if (row == instance.countRows() - 2) {
                    //value = "";
                    cellProperties.className = '';
                    cellProperties.readOnlyCellClassName = "calculate-bg";
                }
                Handsontable.cellTypes.text.renderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            },
            editor: HansontableSelect2({
                dropdownCssClass: 'handsontable-select',
                dropdownAutoWidth: true,
                data: this.formatTaxItems(searchData),
            }),
        };

        //this.systemSettingsexpensesColumn.splice(4, 1, vatEditor);  // TODO : incase we need supplier in feature enbale systemSettingsexpensesColumn array and changed splice code to 2,1
        this.expensesColumn.splice(4, 1, vatEditor);                // TODO : incase we need supplier in feature enbale expensesColumn array and changed splice code to 2,1
        this.updateCols();
    }

    updateCols() {
        let hotInstancesSystemSettings = this.hotInstances.filter(instance => {
            return (instance.id.startsWith('system-settings-pricing-section-expensesId'));
        });
        if(hotInstancesSystemSettings){
            hotInstancesSystemSettings.forEach(instance => {
                let core = instance.instance;
                //core.updateSettings({ columns: this.systemSettingsexpensesColumn });
                core.updateSettings({ columns: this.expensesColumn });
            });
        }
        let hotInstancesView = this.hotInstances.filter(instance => {
            return (instance.id.startsWith('pricing-section-expensesId'));
        });
        if(hotInstancesView){
            hotInstancesView.forEach(instance => {
                let core = instance.instance;
                core.updateSettings({ columns: this.expensesColumn });
            });
        }
    }

    formatTaxItems(taxData){
        let tax = [];
        const taxItems = Object.values(_.groupBy(taxData, function (obj) { return obj.taxProvinceId; }));
        taxItems.forEach((province) => {
            let taxLabel = province[0]['taxProvinceName'];
            tax.push({'text': taxLabel, 'children': province});
        });
        return tax;
    }

    clearInstances(){
        this.hotInstances = [];
    }
    clearRow(){
        this.editedDataAdded = [];
    }

    ngOnDestroy() {
    }
}
