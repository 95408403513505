import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AppValidators} from "@app/directives";
import {ConfirmModalConfig, TActionStatus, WarningModalConfig} from "@app/interfaces/common-modal-popups";

//  Todo: Here JQuery only introduced for hide backdrop here, due to the issue of `cs-modal`
//      - So, need to remove JQuery here once the `cs-modal` replaced or improved without JQuery.

declare var $;

@Component({
    selector: 'app-common-modal-popups',
    templateUrl: './common-modal-popups.component.html',
    styleUrls: ['common-modal-popups.component.scss']
})

export class CommonModalPopupsComponent implements OnInit {

    @Output() onCloseModal = new EventEmitter<'CLOSE_ICON' | 'CONFIRM_BTN' | 'CANCEL_BTN'>();
    @Input() modalConfig: WarningModalConfig | ConfirmModalConfig;

    confirmationForm: FormGroup;
    closeText = { warning: 'close', confirm: 'cancel' };

    constructor(private fb: FormBuilder) { }

    ngOnInit() {
        this.setDefaultConfig();
    }

    setDefaultConfig() {
        if (this.modalConfig) {
            this.modalConfig.closeText = this.modalConfig['closeText'] || this.closeText[this.modalConfig['modalType']];

            if (this.modalConfig['modalType'] === 'confirm') {
                this.modalConfig.operationFailedMsg = this.modalConfig.operationFailedMsg || 'failed.to.perform.operation';
                this.modalConfig.actionStatus = 'TODO';
                this.modalConfig.confirmBtnType = this.modalConfig.confirmBtnType || 'DEFAULT';
                this.confirmationForm = this.fb.group({
                    'confirmationText': ['', [Validators.required, AppValidators.confirmationTextValidator(this.modalConfig['actionText'])]]
                });
            }

            this.modalConfig['doAction'] = this.modalConfig['doAction'] || (() => {});
        }
    }

    closeModal(callFrom: 'CLOSE_ICON' | 'CONFIRM_BTN' | 'CANCEL_BTN') {

        if (this.modalConfig['modalType'] === 'confirm') {
            this.confirmationForm.reset();
            this.modalConfig['actionStatus'] = 'TODO';
        }

        this.onCloseModal.emit(callFrom);

        // Note: Here this used only for the issue of `backdrop` in `cs-modal`
        $('div.modal-backdrop').remove();
        $('body.modal-open').css('overflow', 'auto');
        // ------------------------
        this.modalConfig.showModal = false;
    }

    executeAction() {
        if (this.modalConfig['modalType'] === 'confirm' && this.confirmationForm.valid) {
            this.modalConfig['actionStatus'] = 'PROCESSING';
            this.modalConfig.doAction(this.modalConfig.actionArg, (status: TActionStatus = 'SUCCESS') => {
                this.modalConfig['actionStatus'] = status.toUpperCase();

                if (this.modalConfig['actionStatus'] === 'SUCCESS') {
                    this.closeModal('CONFIRM_BTN');
                }
            });
        }
    }
}
