
// angular '$rootScope' provider ---------------------------------------------------
export function ajsScopeProviderFactory(injector: any) {
    return injector.get('$rootScope');
}
export const ajsScopeProvider = {
    provide: '$scope',
    useFactory: ajsScopeProviderFactory,
    deps: ['$injector']
};

// '$state' service from angularJs --------------------------------------------------
export function ajsStateProviderFactory(injector: any) {
    return injector.get('$state');
}

export const ajsStateProvider = {
    provide: 'ajsState',
    useFactory: ajsStateProviderFactory,
    deps: ['$injector']
};

// 'colorSelector' service from angularJs --------------------------------------------
export function colorSelectorProviderFactory(injector: any) {
    return injector.get('colorSelector');
}

export const colorSelectorProvider = {
    provide: 'colorSelector',
    useFactory: colorSelectorProviderFactory,
    deps: ['$injector']
};

// 'confirmationBoxHelper' service from angularJs  ------------------------------------
export function confirmationBoxHelperProviderFactory(injector: any) {
    return injector.get('confirmationBoxHelper');
}

export const confirmationBoxHelperProvider = {
    provide: 'confirmationBoxHelper',
    useFactory: confirmationBoxHelperProviderFactory,
    deps: ['$injector']
};

// '$translate' service from angularJs  ------------------------------------
export function translateProviderFactory(injector: any) {
    return injector.get('$translate');
}

export const translateBoxHelperProvider = {
    provide: '$translate',
    useFactory: translateProviderFactory,
    deps: ['$injector']
};

// 'email' service from angularJs  ------------------------------------
export function emailServiceProviderFactory(injector: any) {
    return injector.get('emailService');
}

export const emailServiceProvider = {
    provide: 'emailService',
    useFactory: emailServiceProviderFactory,
    deps: ['$injector']
};

// 'redirectScreen' service from angularJs  ------------------------------------
export function redirectScreenProviderFactory(injector: any) {
    return injector.get('redirectScreen');
}

export const redirectScreenProvider = {
    provide: 'redirectScreen',
    useFactory: redirectScreenProviderFactory,
    deps: ['$injector']
};

export function ajsCompileProviderFactory(injector: any) {
    return injector.get('$compile');
}

export const ajsCompileProvider = {
    provide: '$compile',
    useFactory: ajsCompileProviderFactory,
    deps: ['$injector']
};
