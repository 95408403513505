import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { EmailListenerService } from "@app/shared-modal/email-modal/email-listener.service";
import { Subject, combineLatest } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CsToastBoxService } from "@app/services";
var SharedModalComponent = /** @class */ (function () {
    function SharedModalComponent(_emailEventService, changeDetection, toast) {
        this._emailEventService = _emailEventService;
        this.changeDetection = changeDetection;
        this.toast = toast;
        this._unsubscribeAll = new Subject();
        this.subscriptions = [];
    }
    SharedModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _combine = combineLatest(this._emailEventService.beforeSend$, this._emailEventService.afterSend$, this._emailEventService.send$)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () { return _this.changeDetection.markForCheck(); });
        this.subscriptions.push(this._emailEventService.beforeSend$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (data) {
            _this.toast.show('Your email is being sent', 4000);
        }));
        this.subscriptions.push(this._emailEventService.send$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (component) {
            component.processEmail();
        }));
        this.subscriptions.push(_combine);
    };
    SharedModalComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
    };
    return SharedModalComponent;
}());
export { SharedModalComponent };
