export interface EmailBlockModelInterface {
    templateId: string;
    toEmailTag: any[];
    ccEmailTag?: any[];
    bccEmailTag?: any[];
    fromEmail?: any;
    subject: string;
    message: string;
    templateName: string;
}

export class EmailBlockModel implements EmailBlockModelInterface {
    templateId: string = '';
    toEmailTag: any[] = [];
    ccEmailTag: any[] = [];
    bccEmailTag: any[] = [];
    fromEmail: any = '';
    message: string = '';
    subject: string = '';
    templateName: string = '';

    constructor(data:{} = undefined) {
        if(data) {
            this.templateId = data['templateId'] ? data['templateId'] : '';
            this.toEmailTag = data['toEmailTag'] ? data['toEmailTag'] : undefined;
            this.ccEmailTag = data['ccEmailTag'] ? data['ccEmailTag'] : undefined;
            this.bccEmailTag = data['bccEmailTag'] ? data['bccEmailTag'] : undefined;
            this.fromEmail = data['fromEmail'] ? data['fromEmail'] : '';
            this.subject = data['subject'] ? data['subject'] : '';
            this.templateName = data['templateName'] ? data['templateName'] : '';
            this.message = data['message'] ? data['message'] : '';
        }
    }
}
