<!--<div class="modal-header">-->
<!--    <h4 class="modal-title pull-left">{{title}}</h4>-->
<!--</div>-->
<div class="modal-body" style="top:0">
    <app-template-view-layout
        [message]="message"
        [subject]="subject"
        [type]="type"
    ></app-template-view-layout>
</div>
<div class="modal-footer">
    <button class="btn btn-link" (click)="modalRef.hide()">Cancel</button>
</div>
