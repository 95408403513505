import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterfaceService } from '@app/interfaces';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedModalService extends InterfaceService {
    private emailModalDetails = new BehaviorSubject('');
    emailModalDetails$ = this.emailModalDetails.asObservable();

    private emailSaveAction = new BehaviorSubject('');
    emailSaveAction$ = this.emailSaveAction.asObservable();

    private scheduledActivityDetails = new BehaviorSubject('');
    scheduledActivityDetails$ = this.scheduledActivityDetails.asObservable();

    private emailEmittedValue = new BehaviorSubject('');
    emailEmittedValue$ = this.emailEmittedValue.asObservable();

    private commonModalEmittedValue = new BehaviorSubject('');
    commonModalEmittedValue$ = this.commonModalEmittedValue.asObservable();

    private modalOpenStatus: any = '';
    types: object = {
        'sms': false,
        'note': false,
        'call': false,
        'files': false,
    }
    private diaryCompletedStatus: any = '';

    constructor(private http: HttpClient) {
        super();
        this.groupModalStatus();
    }

    setEmailEmittedDetails(data) {
        this.emailEmittedValue.next(data);
    }
    setCommonModalEmittedValue(data) {
        this.commonModalEmittedValue.next(data);
    }
    setEmailModalDetails(data) {
        this.emailModalDetails.next(data);
    }

    setEmailSaveAction(data) {
        this.emailSaveAction.next(data);
    }

    setScheduledActivityDetails(data) {
        this.scheduledActivityDetails.next(data);
    }

    getEmailModalDetails(url:string){
        return this.http.get(this.getApiUrl(url))
    }

    saveEmail(params, queryParams:Object = {}){
        return this.http.post(this.getApiUrl('process_email', queryParams),params);
    }

    mapQueryParams(queryObject:object){
        return Object.entries(queryObject)
         .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
         .join('&');
     }

    groupModalStatus() {
        this.modalOpenStatus = {
            'customer': JSON.parse(JSON.stringify(this.types)),
            'opportunity': JSON.parse(JSON.stringify(this.types)),
            'contract': JSON.parse(JSON.stringify(this.types)),
            'others': {
                'email': false,
                'activity': false
            }
        };
    }

    checkModalStatus() {
        let isAlreadyOpen: any = false;
        for (let screenKey of Object.keys(this.modalOpenStatus)) {
            for (let key of Object.keys(this.modalOpenStatus[screenKey])) {
                if (this.modalOpenStatus[screenKey][key]) {
                    isAlreadyOpen = key;
                }
            }
        }
        return isAlreadyOpen;
    }

    updateModalStatus(screen, type, status) {
        type = type.toLowerCase();
        if (this.modalOpenStatus.hasOwnProperty(screen) && this.modalOpenStatus[screen].hasOwnProperty(type)) {
            this.modalOpenStatus[screen][type] = status;
        }
    }

    resetModalStatus(screen) {
        if (this.modalOpenStatus.hasOwnProperty(screen)) {
            this.modalOpenStatus[screen] = JSON.parse(JSON.stringify(this.types));
        }
    }

    setDiaryCompletedStatus(data){
        this.diaryCompletedStatus = data;
    }
    getDiaryCompletedStatus(){
        return this.diaryCompletedStatus;
    }
}
