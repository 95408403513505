/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./email-modal/email-modal.component.ngfactory";
import * as i2 from "./email-modal/email-modal.component";
import * as i3 from "./service/shared-modal.service";
import * as i4 from "@angular/forms";
import * as i5 from "../features/customers/add-opportunity/service/add-opportunity.service";
import * as i6 from "../shared/common-folder-structure/service/folder.service";
import * as i7 from "../shared/cs-activity-log/cs-activity-log-event.service";
import * as i8 from "../services/utility-services/cs-toast-box.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "./email-modal/common-filter.service";
import * as i11 from "../features/customers/customer/property-view/service/property-view.service";
import * as i12 from "../services/http-services/common.service";
import * as i13 from "../features/fleet-management/add-vehicle/services/view-vehicle-service";
import * as i14 from "../shared/services/timeline.service";
import * as i15 from "../core/auth/auth.service";
import * as i16 from "./email-modal/email-listener.service";
import * as i17 from "./scheduled-activity-modal/scheduled-activity-modal.component.ngfactory";
import * as i18 from "./scheduled-activity-modal/scheduled-activity-modal.component";
import * as i19 from "../core/settings/settings.service";
import * as i20 from "./shared-modal.component";
var styles_SharedModalComponent = [];
var RenderType_SharedModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SharedModalComponent, data: {} });
export { RenderType_SharedModalComponent as RenderType_SharedModalComponent };
export function View_SharedModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-email-modal", [], null, [["", "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).clickout($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_EmailModalComponent_0, i1.RenderType_EmailModalComponent)), i0.ɵdid(1, 114688, null, 0, i2.EmailModalComponent, [i3.SharedModalService, i4.FormBuilder, "confirmationBoxHelper", "$scope", i5.AddOpportunityService, i0.NgZone, i6.FolderService, i7.CsActivityLogEventService, i8.CsToastBoxService, i9.TranslateService, i10.CommonFilterService, i11.PropertyViewService, i12.CommonService, i13.ViewVehicleService, i14.TimelineService, i15.AuthService, i16.EmailListenerService], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-scheduled-activity-modal", [], null, null, null, i17.View_ScheduledActivityModalComponent_0, i17.RenderType_ScheduledActivityModalComponent)), i0.ɵdid(4, 114688, null, 0, i18.ScheduledActivityModalComponent, [i3.SharedModalService, i4.FormBuilder, i5.AddOpportunityService, "confirmationBoxHelper", i11.PropertyViewService, i8.CsToastBoxService, "$scope", i19.SettingsService, i7.CsActivityLogEventService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); }, null); }
export function View_SharedModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-shared-modal", [], null, null, null, View_SharedModalComponent_0, RenderType_SharedModalComponent)), i0.ɵdid(1, 245760, null, 0, i20.SharedModalComponent, [i16.EmailListenerService, i0.ChangeDetectorRef, i8.CsToastBoxService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SharedModalComponentNgFactory = i0.ɵccf("app-shared-modal", i20.SharedModalComponent, View_SharedModalComponent_Host_0, {}, {}, []);
export { SharedModalComponentNgFactory as SharedModalComponentNgFactory };
