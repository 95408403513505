<div class="cs-segment-row cs-d-flex h-100 pos-rlt">
    <div class="cs-segment-wrapper h-100 segment-level-1">
        <div class="cs-segment-container h-100">
            <div class="seg-header">
                <!--<div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0">
                    <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                        <div class="ph-row align-items-center">
                            <div class="ph-col-8 mb3"></div>
                        </div>
                    </div>
                </div>-->
                <div class="seg-title-section">
                    <h4>Title Segmentation</h4>
                    <div class="seg-actions ml-auto pr-10">
                        <a href="" class="mr-5">Edit</a>
                        <a href="">Delete</a>
                    </div>
                </div>
                <div class="seg-filter-section">
                    <div class="cs-search_box cs-d-flex align-items-center">
                        <span><i class="fa-solid fa-magnifying-glass cs--placeholder-color"></i></span>
                        <input type="text" placeholder="Search" />
                    </div>
                </div>
            </div>

            <div class="seb-body cs-flex-1">
                <div class="seg-list-section">
                    <ul>
                        <li class="seg-item" (click)="showNextSegment()">
                            <div class="cs-d-flex">
                                <div class="text-truncate">All</div>
                                <!--                        <div class="seg-clone-item"><i class="fas fa-clone"></i></div>-->
                                <div class="seg-check-item"><i class="fas fa-check-circle"></i></div>
                            </div>
                        </li>
                        <li class="actives">
                            <div class="cs-d-flex">
                                <div class="text-truncate">Deposit</div>
                                <!--<div class="seg-clone-item"><i class="fas fa-clone"></i></div>
                                <div class="seg-check-item"><i class="fas fa-check-circle"></i></div>-->
                            </div>
                        </li>
                        <li>
                            <div class="cs-d-flex">
                                <div class="text-truncate">Additional</div>
                            </div>
                        </li>
                        <li class="actives">
                            <div class="cs-d-flex">
                                <div class="text-truncate">Deposit</div>
                                <div class="seg-clone-item"><i class="fas fa-clone"></i></div>
                                <div class="seg-check-item"><i class="fas fa-check-circle"></i></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="cs-segment-wrapper h-100 segment-level-2" [ngClass]=" {'show': activeSegment}">
        <div class="cs-segment-container h-100">
            <div class="seg-header">
                <div class="seg-title-section">
                    <h4>Title Segmentation</h4>
                    <div class="seg-actions ml-auto pr-10">
                        <a href="" class="mr-5">Edit</a>
                        <a href="">Delete</a>
                    </div>
                </div>
                <div class="seg-filter-section">
                    <div class="cs-search_box cs-d-flex align-items-center">
                        <span><i class="fal fa-search"></i></span>
                        <input type="text" placeholder="Search" />
                    </div>
                </div>
            </div>
            <div class="seb-body cs-flex-1">
                <div class="seg-list-section">
                    <ul>
                        <li class="seg-item" (click)="showNextSegment()">
                            <div class="cs-d-flex">
                                <div class="text-truncate">All</div>
                                <div class="seg-check-item"><i class="fas fa-check-circle"></i></div>
                            </div>
                        </li>
                        <li class="actives">
                            <div class="cs-d-flex">
                                <div class="text-truncate">Deposit</div>
                            </div>
                        </li>
                        <li>
                            <div class="cs-d-flex">
                                <div class="text-truncate">Additional</div>
                            </div>
                        </li>
                        <li class="actives">
                            <div class="cs-d-flex">
                                <div class="text-truncate">Payment Charge</div>
                                <div class="seg-clone-item"><i class="fas fa-clone"></i></div>
                                <div class="seg-check-item"><i class="fas fa-check-circle"></i></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<!--
<div class="cs-segment-row cs-d-flex h-100 pos-rlt">
    <div class="cs-segment-wrapper h-100 segment-level-1">
        <div class="cs-segment-container h-100">
            <div class="seg-header">
                <div class="seg-title-section">
                    <h4>Title Segmentation</h4>
                    <div class="seg-actions ml-auto pr-10">
                        <a href="" class="mr-5">Edit</a>
                        <a href="">Delete</a>
                    </div>
                </div>
                <div class="seg-filter-section">
                    <div class="cs-search_box cs-d-flex align-items-center">
                        <span><i class="fal fa-search"></i></span>
                        <input type="text" placeholder="Search" />
                    </div>
                </div>
            </div>

            <div class="seb-body cs-flex-1">
                <div class="seg-list-section">
                    <ul>
                        <li class="seg-item">
                            <div class="cs-d-flex">
                                <div class="text-truncate">All</div>
        &lt;!&ndash;                        <div class="seg-clone-item"><i class="fas fa-clone"></i></div>&ndash;&gt;
                                <div class="seg-check-item"><i class="fas fa-check-circle"></i></div>
                            </div>
                        </li>
                        <li class="actives">
                            <div class="cs-d-flex">
                                <div class="text-truncate">Deposit</div>
                                &lt;!&ndash;<div class="seg-clone-item"><i class="fas fa-clone"></i></div>
                                <div class="seg-check-item"><i class="fas fa-check-circle"></i></div>&ndash;&gt;
                            </div>
                        </li>
                        <li>
                            <div class="cs-d-flex">
                                <div class="text-truncate">Additional</div>
                                &lt;!&ndash;<div class="seg-clone-item"><i class="fas fa-clone"></i></div>
                                <div class="seg-check-item"><i class="fas fa-check-circle"></i></div>&ndash;&gt;
                            </div>
                        </li>
                        <li class="actives">
                            <div class="cs-d-flex">
                                <div class="text-truncate">Deposit</div>
                                <div class="seg-clone-item"><i class="fas fa-clone"></i></div>
                                <div class="seg-check-item"><i class="fas fa-check-circle"></i></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="cs-segment-wrapper h-100 segment-level-2">
        <div class="cs-segment-container h-100">
            <div class="seg-header">
                <div class="seg-title-section">
                    <h4>Title Segmentation</h4>
                    <div class="seg-actions ml-auto pr-10">
                        <a href="" class="mr-5">Edit</a>
                        <a href="">Delete</a>
                    </div>
                </div>
                <div class="seg-filter-section">
                    <div class="cs-search_box cs-d-flex align-items-center">
                        <span><i class="fal fa-search"></i></span>
                        <input type="text" placeholder="Search" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
-->
