import { ElementRef, EventEmitter, OnInit, Renderer, Renderer2, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
//--
import { CsToastBoxService } from "@app/services";
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
import { fontfamilyService } from "@app/shared/services/fontfamily.service";
import { LayoutConfigurationService } from "@app/shared/layout-configuration/layout-configuration.service";
var CsSalesFullScreenModalComponent = /** @class */ (function () {
    function CsSalesFullScreenModalComponent(_hostRef, _renderer, route, location, toastBox, confirmationBoxHelper, ajsScope, propoalService, router, fontFamilyService, renderer, tableService) {
        this._hostRef = _hostRef;
        this._renderer = _renderer;
        this.route = route;
        this.location = location;
        this.toastBox = toastBox;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.propoalService = propoalService;
        this.router = router;
        this.fontFamilyService = fontFamilyService;
        this.renderer = renderer;
        this.tableService = tableService;
        this.bodyClass = '';
        this.subTitle = '';
        this.showSubTitleAsTemplate = false;
        this.showbreakdownHeader = false;
        this.hideCloseButton = false;
        this.onContentOpen = new EventEmitter();
        this.onContentClose = new EventEmitter();
        this.action = 'save';
        this.ppmList = [];
        this.subscriptions = {};
    }
    CsSalesFullScreenModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        // For modal box purpose
        this._renderer.setElementAttribute(this._hostRef.nativeElement, 'tabIndex', '-1');
        if (this.bodyClass) {
            this._renderer.setElementClass(this.widgetBody.nativeElement, this.bodyClass, true);
        }
        this.subscriptions['closeModalSubscriptions'] = this.propoalService.closeModal$.subscribe(function (res) {
            if (res['close']) {
                _this.close();
            }
        });
        this.fontFamilyService.googleFontSendComponent$.subscribe(function (res) {
            if (res != '' && res != null) {
                var style = _this.renderer.createElement('style');
                _this.renderer.appendChild(_this._hostRef.nativeElement, style);
                _this.renderer.setProperty(style, 'textContent', "@import url('" + res + "');");
            }
        });
    };
    // Open widget
    CsSalesFullScreenModalComponent.prototype.open = function (shorten, animate) {
        var _this = this;
        if (shorten === void 0) { shorten = true; }
        if (animate === void 0) { animate = null; }
        if (animate) {
            jQuery(this._hostRef.nativeElement).appendTo("body").css('display', 'block');
        }
        else {
            jQuery(this._hostRef.nativeElement).appendTo("body").css('display', 'block').addClass("bounceInUp").removeClass('bounceOutDown');
        }
        // shorten the header if it is large
        this.onContentOpen.emit();
        if (shorten) {
            setTimeout(function () {
                _this.shortenHeader();
            }, 100);
        }
    };
    CsSalesFullScreenModalComponent.prototype.saveEditorThumbnail = function () {
        var _this = this;
        this.propoalService.setEditorThumbnail(true);
        this.propoalService.saveContentLibrary(true);
        setTimeout(function () {
            if (_this.screenName == 'set_up_pipelines') {
                _this.tableService.loadData();
            }
            _this.close();
        }, 700);
    };
    // Close widget
    CsSalesFullScreenModalComponent.prototype.close = function () {
        var _this = this;
        if (this.screenName && this.screenName == 'optionTemplate') {
            this.propoalService.savePages(true);
            this.router.navigate([], { relativeTo: this.route, queryParams: {} });
            jQuery(this._hostRef.nativeElement).addClass('bounceOutDown').removeClass("bounceInUp");
            setTimeout(function () {
                _this.onContentClose.emit();
            }, 100);
        }
        else {
            jQuery(this._hostRef.nativeElement).addClass('bounceOutDown').removeClass("bounceInUp");
            setTimeout(function () {
                _this.onContentClose.emit();
            }, 100);
        }
    };
    CsSalesFullScreenModalComponent.prototype.ngOnDestroy = function () {
        // Remove content from body on destroy
        jQuery(this._hostRef.nativeElement).remove();
    };
    CsSalesFullScreenModalComponent.prototype.shortenHeader = function () {
        if (this.header.length > 75) {
            this.header = this.header.substring(0, 75) + '..';
        }
    };
    CsSalesFullScreenModalComponent.prototype.confirmFirst = function (event) {
        var iframe = document.getElementById('cs_editor');
        if (iframe) {
            var csSelected = "remove_cs_selected";
            iframe.contentWindow.postMessage(csSelected, '*');
        }
    };
    CsSalesFullScreenModalComponent.prototype.onDragLeave = function (event) {
        var iframe = document.getElementById('cs_editor');
        if (iframe) {
            iframe.contentWindow.postMessage("remove_helper_lines", '*');
        }
    };
    return CsSalesFullScreenModalComponent;
}());
export { CsSalesFullScreenModalComponent };
