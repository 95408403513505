import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { DagSourceModel } from "@app/workflow-common/services/DagSourceModel";
import { CommonDataSource } from "@app/workflow-common/services/CommonDataSource";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { SetSalesPersonBlockModel } from "@app/workflow-common/services/models/SetSalesPersonBlockModel";
var SetSalesPersonBlockWidgetComponent = /** @class */ (function () {
    function SetSalesPersonBlockWidgetComponent(formBuilder) {
        this.formBuilder = formBuilder;
        this.close = new EventEmitter();
        this.isLoading = true;
        this.isFormSubmitted = false;
        this.selectedUserInputs = {};
        this.showDeleteBtn = false;
    }
    SetSalesPersonBlockWidgetComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var blockObject, modelData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.blockData = this.dagSourceModel.getBlocksSource().getBlockByKey(this.blockObject.key);
                        return [4 /*yield*/, CommonDataSource.getUserDetail().then(function (userData) {
                                var salesPersonUserList = [];
                                var userRecords = [];
                                userRecords['userRecords'] = userData ? userData : [];
                                userRecords['userRecords'].forEach(function (user) {
                                    var type = '';
                                    if (user.salesRoleId != null && user.salesRoleId > 10) {
                                        salesPersonUserList.push({
                                            id: user.id,
                                            text: user.text,
                                        });
                                    }
                                });
                                _this.salesPersonUsers = salesPersonUserList;
                            })];
                    case 1:
                        _a.sent();
                        blockObject = tslib_1.__assign({}, this.blockObject);
                        modelData = blockObject['model'] ? blockObject['model'] : undefined;
                        if (modelData) {
                            this.showDeleteBtn = true;
                        }
                        this.inputModel = new SetSalesPersonBlockModel(modelData);
                        this.makeEmailAddressDropdownData();
                        this.form = this.formBuilder.group({
                            assignTo: new FormControl(this.selectedUserInputs['assignTo'], [Validators.required]),
                            displayValue: new FormControl(this.selectedUserInputs['displayValue']),
                        });
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SetSalesPersonBlockWidgetComponent.prototype.closeModal = function () {
        this.close.emit({
            action: 'close',
            data: null
        });
    };
    SetSalesPersonBlockWidgetComponent.prototype.remove = function () {
        var blockObject = tslib_1.__assign({}, this.blockObject);
        blockObject.model = this.inputModel;
        this.close.emit({
            action: 'remove_block',
            data: blockObject
        });
    };
    SetSalesPersonBlockWidgetComponent.prototype.selectedUsers = function (event) {
        if (event === void 0) { event = null; }
        this.form.get('assignTo').setValue(event);
        if (event) {
            this.form.get('displayValue').setValue(this.getDisplayValue(event));
        }
    };
    // makeGroupUserList() {
    //     const allEmailTag = [];
    //     const newItems: any[] = [];
    //         newItems.push(this.salesPersonUsers);
    //         newItems.push(this.otherUsers);
    //
    //     const source = from(newItems);
    //     const tags = source.pipe(
    //         map(groupItems => {
    //             const _item: any = {text: '', children: []};
    //             let cnt: number = 0;
    //             groupItems.forEach( group => {
    //                 if (cnt === 0) {
    //                     _item.text = group.name;
    //                 }
    //                 _item.children.push({
    //                     id: group.id,
    //                     text: group.text
    //                 });
    //                 cnt++;
    //             });
    //             return _item;
    //         })
    //     );
    //     const subscribe:Subscription = tags.subscribe(val => allEmailTag.push(val));
    //     subscribe.unsubscribe();
    //     console.log(allEmailTag,'allEmailTag')
    //     return allEmailTag;
    // }
    SetSalesPersonBlockWidgetComponent.prototype.makeEmailAddressDropdownData = function () {
        this.selectedUserInputs['assignTo'] = this.inputModel.assignTo;
        if (this.inputModel.assignTo) {
            this.selectedUserInputs['displayValue'] = this.getDisplayValue(this.inputModel.assignTo);
        }
    };
    SetSalesPersonBlockWidgetComponent.prototype.save = function () {
        this.isFormSubmitted = true;
        var blockObject = tslib_1.__assign({}, this.blockObject);
        blockObject.model = new SetSalesPersonBlockModel(this.form.value);
        this.close.emit({
            action: 'save_and_close',
            data: blockObject
        });
    };
    SetSalesPersonBlockWidgetComponent.prototype.getDisplayValue = function (userId) {
        var selectedUserData = this.salesPersonUsers.find(function (x) { return x.id == userId; });
        return selectedUserData['text'];
    };
    return SetSalesPersonBlockWidgetComponent;
}());
export { SetSalesPersonBlockWidgetComponent };
