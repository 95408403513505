<div class="modal-header">
    <h4 class="modal-title pull-left">{{itemTitle}} <span translate [translateParams]="{StageName:stageName}">Rules.Setup.Of.StageName</span></h4>
</div>
<div class="modal-body cs-pl-automation cs-pipeline-automation--trigger">
    <form class="no-form-style" *ngIf="form" [formGroup]="form" novalidate>
        <div class="trigger-form cs-d-flex flex-column">
            <div class="cs-flex-1">
                <div class="cs-d-flex flex-column p-20 b-bottom">
                    <label class="control-label required_field">Choose event</label>
                    <div class="select-input-section">
                        <select csSelect2 (onChange)="onSelectEvent($event)"
                            formControlName="triggerEvent"
                            class="w-100"
                            [select2Config]="select2OptionsEvents">
                            <option value="" translate>Please.Choose</option>
                            <option *ngFor="let event of eventsList" [value]="event.id">{{event.text}}</option>
                        </select>
                    </div>
                    <div *ngIf="hasHelpText" class="cs-helper-section">
                        <div class="cs-help-text-color m-t--10">{{ eventHelpText }}</div>
                    </div>
                </div>

                <div class="trigger-rules-items cs-d-flex flex-column">
                    <div formArrayName="rules" *ngFor="let rule of formRules.controls; let idx = index">
                        <div class="trigger-rule-item cs-d-flex flex-column p-20 bg-white" [formGroupName]="idx">
                            <div class="label-section m-b--5">
                                <div class="cs-d-flex">
                                    <label class="control-label required_field mr-auto">Condition {{idx+1}}</label>
                                    <button (click)="onBeforeRemove(idx)" class="btn btn-link">Delete</button>
                                </div>
                            </div>
                            <select csSelect2
                                class="w-100 mb-10"
                                formControlName="col_name"
                                (onChange)="onChangeField($event, idx)"
                                [select2Config]="select2OptionsFields">
                                <option [disabled]="true" value="" translate>Please.Choose</option>
                                <option
                                    *ngFor="let field of fieldMetadata['fields']" [value]="field.col_name"
                                    [disabled]="selectedColName.indexOf(field.col_name) > -1"
                                >{{field.text}}</option>
                            </select>
                            <div class="innter-input-items" *ngIf="formRules.value[idx].col_name != ''">
                                <div class="cs-d-flex flex-column">
                                    <div class="d-block mb-10">
                                        <select class="input-operator"
                                            [select2Config]="select2OptionsEvents"
                                            csSelect2
                                            (onChange)="onChangeOperator($event, idx)"
                                            ($instance)="setSelect2OperatorInstance($event, idx)"
                                            formControlName="operator">
                                            <option *ngFor="let operator of activeInputs[formRules.value[idx].col_name].operators" value="{{operator}}">{{operators[operator]}}</option>
                                        </select>
                                    </div>

                                    <div class="d-block" [ngSwitch]="true">
                                        <div *ngSwitchCase="activeInputs[formRules.value[idx].col_name].input === 'select' || activeInputs[formRules.value[idx].col_name].input === 'checkboxList'">
                                            <ng-container *ngIf="!['in', 'not_in'].includes(formRules.value[idx].operator)">
                                                <select
                                                    csSelect2
                                                    ($instance)="setSelect2FieldValueInstance($event, idx, false)"
                                                    [select2Config]="select2OptionsFields"
                                                    class="input-value-select2"
                                                    formControlName="value" >
                                                    <option value="" translate>Please.Choose</option>
                                                    <option *ngFor="let option of activeInputs[formRules.value[idx].col_name].options" value="{{option.id}}">{{option.text}}</option>
                                                </select>
                                            </ng-container>
                                            <ng-container *ngIf="['in', 'not_in'].includes(formRules.value[idx].operator)">
                                                <input
                                                   ($instance)="setSelect2FieldValueInstance($event, idx, true)"
                                                   (onChange)="onSelectMultipleSelect($event, idx)"
                                                   formControlName="value"
                                                   class="full-width-select2 w-100"
                                                   id="multiple_select_{{idx}}"
                                                   csSelect2
                                                   [select2Config]="{
                                                      multiple: true,
                                                      data: {
                                                        text: select2DataFunction,
                                                        results : activeInputs[formRules.value[idx].col_name].options
                                                      }
                                                   }"/>
                                            </ng-container>
                                        </div>

                                        <div *ngSwitchCase="activeInputs[formRules.value[idx].col_name].input === 'number'">
                                            <input type="text" formControlName="value" class="control-input" appDigitDecimaNumber/>
                                        </div>
                                        <div *ngSwitchCase="activeInputs[formRules.value[idx].col_name].input === 'text'">
                                            <input type="text" formControlName="value" class="control-input"/>
                                        </div>
                                        <div *ngSwitchCase="activeInputs[formRules.value[idx].col_name].input === 'checkbox'">
                                            <input type="checkbox" formControlName="value" class="control-input"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item-line" *ngIf="(idx+1) != formRules.controls.length">
                            <span class="bar"></span>
                        </div>
                    </div>
                    <div class="add-action-btn" *ngIf="form.valid">
                        <button class="btn btn-link" (click)="addRule()" translate>Add condition</button>
                    </div>
                </div>

            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button class="btn btn-link" (click)="onCloseModal()">Close</button>
    <button type="submit" class="btn btn-primary" [disabled]="!form.valid || isFormSubmitted" (click)="save()">
        <span *ngIf="!isFormSubmitted" translate>Save</span>
        <span *ngIf="isFormSubmitted" translate>Saving</span>
    </button>
</div>

<app-common-modal-popups *ngIf="form"
    [modalConfig]="deleteConfirmModalConfig"
    (onCloseModal)="onCloseConfirmation()">
</app-common-modal-popups>
