import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable()
export class PipelineEventListenerService {

    private triggerEvent:Subject<any> = new Subject();
    triggerEvent$: Observable<any> = this.triggerEvent.asObservable();

    static CONSTANTS: Object = {
        'itemExists': 'event:itemExists',
    };
    constructor() { }

    dispatchEvent(actionName:string, data:any={}) {
        data['actionName'] = actionName;
        this.triggerEvent.next(data);
    }
}
