import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DagSourceModel} from "@app/workflow-common/services/DagSourceModel";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {SendProposalBlockModel} from "@app/workflow-common/services/models/SendProposalBlockModel";
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";
import {CommonDataSource} from "@app/workflow-common/services/CommonDataSource";
import {from, Subscription} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-send-proposal-block-widget',
  templateUrl: './send-proposal-block-widget.component.html',
  styles: []
})
export class SendProposalBlockWidgetComponent implements OnInit {

    @Input() workflowType: string;
    @Input() blockObject;
    @Input() dagSourceModel: DagSourceModel;
    @Output() close:EventEmitter<{}> = new EventEmitter<{}>();

    form: FormGroup;
    inputModel: SendProposalBlockModel;
    showDeleteBtn: boolean = false;

    isLoading: boolean = true;
    isFormSubmitted: boolean = false;
    isSMTPEnabled: boolean = false;
    fromEmailAddressesList: any[] = [];
    sendProposalBlockData:{} = {};
    usersList: any[] = [];
    emailTagsList: any[] = [];
    emailUsersList: any[] = [];
    emailCustomList: any[] = [];
    selectedEmailInputs:{} = {};
    select2ConfigToEmail:{} = {};

    constructor(
        private formBuilder: FormBuilder,
        private workflowService: WorkflowhttpService,
    ) {

    }

    async ngOnInit() {
        this.sendProposalBlockData = this.dagSourceModel.getBlocksSource().getBlockByKey(this.blockObject.key);
        this.emailTagsList = this.dagSourceModel.getEmailAddressTagsByType(this.workflowType,'opportunity');
        const blockObject = {...this.blockObject};
        const modelData = blockObject['model'] ? blockObject['model'] : undefined;
        if(modelData) {
            this.showDeleteBtn=true;
        }
        this.inputModel = new SendProposalBlockModel(modelData);

        this.makeEmailAddressDropdownData();

        await CommonDataSource.getEmailCommunicationData()
        .then( (data:any) => {
            this.isSMTPEnabled = data.smtpDetails.smtp;
            if(this.isSMTPEnabled) {
                this.fromEmailAddressesList = data.smtpDetails.smtpList;
            }
            this.usersList = data.users;
        });
        this.isLoading=false;
        this.usersList.forEach( (user) => {
            this.emailUsersList.push({
                id: parseInt(user.id),
                text: user.username,
                name: 'USERS'
            })
        })
        if(!this.isSMTPEnabled) {
            this.inputModel.fromEmail = '';
        }

        this.setToEmailSelect2Config();

        this.form = this.formBuilder.group({
            fromEmail: new FormControl(this.inputModel.fromEmail),
            templateId: new FormControl(this.inputModel.templateId, [Validators.required]),
            toEmailTag: new FormControl(this.selectedEmailInputs['toEmailTag'], [Validators.required]),
            subject: new FormControl(this.inputModel.subject),
            message: new FormControl(this.inputModel.message),
            templateName: new FormControl(this.inputModel.templateName),
            doAttachProposal: new FormControl(this.inputModel.doAttachProposal),
        });
        if(this.isSMTPEnabled) {
            this.form.get('fromEmail').setValidators(Validators.required);
        }
    }

    makeEmailAddressDropdownData() {
        this.selectedEmailInputs['toEmailTag'] = [];
        //pushing dropdown value of selected email addresses
        if(this.inputModel.toEmailTag && this.inputModel.toEmailTag.length > 0) {
            this.inputModel.toEmailTag.forEach( (item) => {
                this.selectedEmailInputs['toEmailTag'].push(item['value']);
                if(item['value'].indexOf('@') > 0) {
                    this.emailCustomList.push({
                        id: item['value'],
                        text: item['value'],
                        name: 'CUSTOM'
                    });
                }
            });
        }
    }

    setTemplate(event:any, type: string) {
        this.form.get('templateId').setValue(event['templateId']);
        this.form.get('message').setValue(event['message']);
        this.form.get('subject').setValue(event['subject']);
        this.form.get('templateName').setValue(event['templateName']);
        this.inputModel.message = event['message'];
        this.inputModel.subject = event['subject'];
        this.inputModel.templateName = event['templateName'];
    }

    setToEmailSelect2Config() {
        const select2Default = CommonDataSource.select2EmailDefaultConfig;
        this.select2ConfigToEmail = {...select2Default};
        this.select2ConfigToEmail['data'] = this.makeGroupEmailList('toAddress');
        if(this.sendProposalBlockData['groupId'] != 'internalCommunications') {
            this.select2ConfigToEmail['formatNoMatches'] = () => {
                return 'Add a recipient\'s email by entering...';
            }
        }
    }

    selectedEmailAddress(event:any = null, type:string = 'to') {
        if(type == 'to'){
            this.form.get('toEmailTag').setValue( event);
        }
    }

    makeGroupEmailList(emailType: string = 'toAddress') {
        const allEmailTag = [];
        const newItems: any[] = [];
        if(emailType == 'toAddress' && this.sendProposalBlockData['groupId'] == 'externalCommunications') {
            // newItems.push(this.emailUsersList);
            newItems.push(this.emailTagsList);
        }

        if(this.emailCustomList.length) {
            newItems.push(this.emailCustomList);
        }
        const source = from(newItems);
        const tags = source.pipe(
            map(groupItems => {
                const _item: any = {text: '', children: []};
                let cnt: number = 0;
                groupItems.forEach( group => {
                    if (cnt === 0) {
                        _item.text = group.name;
                    }
                    _item.children.push({
                        id: group.id,
                        text: group.text
                    });
                    cnt++;
                });
                return _item;
            })
        );
        const subscribe:Subscription = tags.subscribe(val => allEmailTag.push(val));
        subscribe.unsubscribe();
        return allEmailTag;
    }


    save() {
        this.isFormSubmitted = true;
        this.inputModel = new SendProposalBlockModel(this.form.value);
        const {toEmailTag} = this.form.value;
        this.inputModel.toEmailTag = CommonDataSource.formattingEmailAddress(toEmailTag, this.emailTagsList, this.usersList);
        const blockObject = {...this.blockObject};
        blockObject.model = this.inputModel;
        this.close.emit({
            action: 'save_and_close',
            data: blockObject
        })
    }

    closeModal() {
        this.close.emit({
            action: 'close',
            data: null
        });
    }

    remove() {
        const blockObject = {...this.blockObject};
        blockObject.model = this.inputModel;
        this.close.emit({
            action: 'remove_block',
            data: blockObject
        });
    }

}
