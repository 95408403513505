var AddJobBlockModel = /** @class */ (function () {
    function AddJobBlockModel(data) {
        if (data === void 0) { data = undefined; }
        this.jobDescriptionId = '';
        this.displayValue = '';
        if (data) {
            this.jobDescriptionId = data['jobDescriptionId'] ? data['jobDescriptionId'] : '';
            this.displayValue = data['displayValue'] ? data['displayValue'] : '';
        }
    }
    return AddJobBlockModel;
}());
export { AddJobBlockModel };
