import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppCommonModule } from '@app/shared';
import { AddEditFolderModalComponent } from './add-edit-folder-modal/add-edit-folder-modal.component';
import { BsModalRef, ModalModule } from 'ngx-bootstrap';
import { SmartTableModule } from '@app/smart_table/smarttable.module';
import { FolderListComponent } from './folder-list/folder-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@app/directives';
import { TranslateModule } from '@ngx-translate/core';
import { TemplateModalComponent } from './template-modal/template-modal.component';
import {
    OpportunityTemplateService
} from "@app/features/system-settings/opportunity-template/services/opportunity-template.services";
import {CsComponentsModule} from "@app/shared/cs-components/cs-components.module";

@NgModule({
  declarations: [FolderListComponent, AddEditFolderModalComponent, TemplateModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    DirectivesModule,
    TranslateModule,
    ModalModule.forRoot(),
    CsComponentsModule
  ],
  entryComponents:[
    AddEditFolderModalComponent,
    TemplateModalComponent
  ],
  exports:[
    FolderListComponent,
    TemplateModalComponent
  ],
  providers: [OpportunityTemplateService],
})
export class CommonFolderStructureModule { }
