import {TriggerDatasetService} from "@app/workflow-common/services/trigger-dataset.service";
import {EmailBlockModel} from "@app/workflow-common/services/models/EmailBlockModel";
import {Blocks} from "@app/workflow-common/services/Blocks";
import {SmsBlockModel} from "@app/workflow-common/services/models/SmsBlockModel";
import {NotificationBlockModel} from "@app/workflow-common/services/models/NotificationBlockModel";
import {ChangeStageBlockModel} from "@app/workflow-common/services/models/ChangeStageBlockModel";
import {ScheduledActivityBlockModel} from "@app/workflow-common/services/models/ScheduledActivityBlockModel";
import {SendProposalBlockModel} from "@app/workflow-common/services/models/SendProposalBlockModel";
import {StartSequenceBlockModel} from "@app/workflow-common/services/models/StartSequenceBlockModel";

export interface InitialBlockInterface {
    id: string;
    parentId: string;
    name: string;
    block_name?: string;
    conditions: string;
    rules: any[];
    mainObject?: string;
    dependentId?: string;
}
export interface BlockModelInterface {
    id?: string;
    parentId: string;
    name: string;
    isConditional: boolean;
    block_name?: string;
    blockId?: string;
    model?: {};
    key: string
}

export class InitialBlock implements  InitialBlockInterface {
    conditions: string;
    rules: any[];
    block_name: string;
    dependentId: string;
    id: string;
    mainObjectName: string;
    name: string;
    parentId: string;

    constructor(
        id,
        parentId,
        name,
        conditions,
        rules,
        mainObjectName,
        dependentId=''
    ) {
        this.id = id;
        this.parentId = parentId;
        this.name = name;
        this.conditions = conditions;
        this.rules = rules;
        this.mainObjectName = mainObjectName;
        this.dependentId = dependentId;
    }
}

export class BlockModel implements BlockModelInterface {
    block_name: string;
    id: string;
    isConditional: boolean = false;
    model: any
        | EmailBlockModel
        | SmsBlockModel
        | ChangeStageBlockModel
        | ScheduledActivityBlockModel
        | SendProposalBlockModel
        | StartSequenceBlockModel
        | NotificationBlockModel;
    name: string;
    parentId: string;
    key: string;
    blockId: string

    constructor(data?: {}) {
        if(data) {
            this.name = data['name'];
            this.parentId = data['parentId'];
            this.isConditional = data['isConditional'] ? data['isConditional'] : false;
            this.model = data['model'];
            this.key = data['key'];
            if(data['id']) {
                this.id = data['id'];
                this.blockId = this.name + '_' + this.id;
            }
            this.block_name = this.name;
        }
    }
}

export class DagSourceModel {
    dagObject: {} = {};
    blockObjects: {} = {};
    endBlocks: any[] = [];
    mainObjectName: string = '';
    eventName: string = '';

    private initialBlockId:string = 'cs_initializer';
    private rootID: number = 0;
    private nextIncrementalID: number = 1;

    private _fieldMetaData;
    private _eventsList;
    private _triggerCommonService: TriggerDatasetService;
    private _blocks: Blocks;

    constructor(data:object = undefined) {
        this._blocks = new Blocks();
        if(data) {
            this.blockObjects = data['blockObjects'];
            this.eventName = data['eventName'];
            this.mainObjectName = data['mainObjectName'];
            this._buildData();
        }
    }

    hasInitBlock() {
        return this.blockObjects.hasOwnProperty(this.initialBlockId);
    }

    hasActionBlock() {
        return this.endBlocks.length > 0;
    }

    setEventName(value:string) {
        this.eventName = value;
    }

    setMainObject(value:string) {
        this.mainObjectName = value.toLowerCase();
    }

    setFieldMetadata(data: any[]) {
        if(data.length == 0) {
            return;
        }
        let tmp = {};
        data.forEach( ( field ) => {
            tmp[field.col_name] = field
        });
        this._fieldMetaData = tmp;
    }

    getFieldMetadata() {
        return this._fieldMetaData;
    }

    setEventsList(data: any[]) {
        if(data.length == 0) {
            return;
        }
        let tmp = {};
        data.forEach( ( item ) => {
            tmp[item.id] = item
        });
        this._eventsList = tmp;
    }

    getEventsList() {
        return this._eventsList;
    }

    setTriggerCommonService(service:TriggerDatasetService) {
        this._triggerCommonService = service;
    }

    getInitialBlockId() {
        return this.initialBlockId;
    }

    getNextIncrementalID() {
        return this.nextIncrementalID;
    }

    getBlockById(id:string): null|any {
        if(!this.blockObjects.hasOwnProperty(id)) {
            return null;
        }
        return this.blockObjects[id];
    }

    getBlockByParent(parentId: string) {
        const {dagObject, blockObjects} = this;
        if(!dagObject.hasOwnProperty(parentId)) {
            return [];
        }
        const childrenBlocksIds:string[] = dagObject[parentId];
        let blocks:any[] = [];
        childrenBlocksIds.forEach( (blockId) => {
            blocks.push(blockObjects[blockId]);
        });
        return blocks;
    }

    getBlocksSource(): Blocks {
        return this._blocks;
    }

    addTask(data, parentId:any = 0) {
        this._generateNextBlockId();
        let id = this.nextIncrementalID;
        if(parentId == this.rootID) {
            const blockID = 'cs_initializer'
            const initBlock = new InitialBlock(
                'cs_initializer',
                parentId,
                'initializer',
                data['conditions'],
                data['rules'],
                this.mainObjectName,
                ''
            )
            initBlock.block_name = 'initializer';
            this.blockObjects[blockID] = initBlock;
        }
        else {
            if(!data['id']) {
                data['id'] = id;
            }
            const blockID = data['name'] + '_'+data['id'];
            data['parentId'] = parentId;
            data['blockId'] = blockID;
            const taskBlock = new BlockModel(data);
            taskBlock.block_name = data['name'];
            this.blockObjects[blockID] = taskBlock;
            console.log('addTask', this.blockObjects)
        }
        this._buildData();
    }

    removeTask(blockId:string) {
        const {blockObjects} = this;
        console.log('blockObjects', blockObjects);
        if(!blockObjects.hasOwnProperty(blockId)) {
            return false;
        }
        delete blockObjects[blockId];
        this._buildData();
        return true;
    }

    private _getBlockObjectsByKey() {
        const {blockObjects} = this;
        let _tmpBlockObjects = {};
        for( const [blockId, node] of Object.entries(blockObjects)) {
            _tmpBlockObjects[node['id']] = node;
        }
        return _tmpBlockObjects;
    }

    private _generateNextBlockId() {
        if(this.isObjectEmpty(this.blockObjects) ||  Object.keys(this.blockObjects).length == 1) {
            this.nextIncrementalID = 1;
        }
        else {
            let blockIdBag:number[] = [];
            Object.values(this.blockObjects).forEach( (block:object) => {
                if(block['id'] == this.initialBlockId) {
                    return;
                }
                blockIdBag.push(parseInt(block['id']));
            });
            let lastId = Math.max(...blockIdBag);
            this.nextIncrementalID = lastId + 1;
        }
    }
    private _buildData() {
        const blockObjects = this._getBlockObjectsByKey();
        const blockIds = Object.keys(blockObjects);
        const blocksCount = blockIds.length;
        if(blocksCount == 0) {
            return;
        }

        if(blocksCount == 1) {
            this.dagObject[this.initialBlockId] = [];
            this.endBlocks=[];
        }
        else {
            let _tmpDagObject = {};
            _tmpDagObject[this.initialBlockId] = [];
            const firstChildren = Object.values(blockObjects).filter( (item) => item['parentId'] == this.initialBlockId );
            firstChildren.forEach( (child) => {
                _tmpDagObject[this.initialBlockId].push(child['id']);
            });
            const flattened = arr => [].concat(...arr);
            const _generateEndBlocks = () => {
                if(this.isObjectEmpty(this.dagObject)) {
                    return [];
                }
                let endBlocks = [];
                let valuesOfDagObjects:any[] = Object.values(this.dagObject);
                let keysOfDagObject:any[] = Object.keys(this.dagObject);
                const flattenDags = flattened(valuesOfDagObjects);
                endBlocks = flattenDags.filter( (id) => {
                    return !keysOfDagObject.includes(id);
                });
                this.endBlocks = endBlocks;
            }
            const _generateDagObject = (blockId: string) => {
                if(_tmpDagObject[blockId].length) {
                    _tmpDagObject[blockId].forEach( (_id) => {
                        let nodes = Object.values(blockObjects).filter( (item) => item['parentId'] == _id );
                        if(nodes.length) {
                            _tmpDagObject[_id] = [];
                            nodes.forEach( (node) => {
                                _tmpDagObject[_id].push(node['id']);
                            });
                            _generateDagObject(_id);
                        }
                    })
                }
            };

            _generateDagObject(this.initialBlockId);
            let _ids: any[] = Object.keys(_tmpDagObject);
            _ids.forEach( (_id) => {
                const _blockId = _id == this.initialBlockId ? this.initialBlockId : blockObjects[_id]['name']+'_'+_id;
                this.dagObject[_blockId] = [];
                const _nodes:any[] = _tmpDagObject[_id];
                _nodes.forEach( (nodeId:any) => {
                    this.dagObject[_blockId].push(blockObjects[nodeId]['name']+'_'+nodeId);
                });
            });
            _generateEndBlocks();
            this._generateNextBlockId();
        }
    }

    getBlockDisplayMessageDelta(blockId: string = '') {
        if(!blockId) {
            return [];
        }
        const {blockObjects} = this;
        if(!blockObjects[blockId]) {
            console.info("Block does not exists...");
            return ['do something'];
        }

        const block = blockObjects[blockId];
        const blockSourceData = this.getBlocksSource().getBlockByKey(block['key']);
        let delta = blockSourceData['displayText'] ? [...blockSourceData['displayText']] : [];
        const {model} = block;
        let sourceItem:any = undefined;
        if(block['name'] === 'smsBlock') {
            sourceItem = model['toSmsTag'];
        }
        else if (block['name'] === 'emailBlock' || block['name'] === 'sendProposalBlock' || block['name'] == 'sendJobReportBlock') {
            sourceItem = model['toEmailTag'];
        }
        else if(block['name'] === 'notificationBlock') {
            sourceItem = [];
            let rolesDisplay = Array.isArray(model['rolesDisplay']) && model['rolesDisplay'].length ? model['rolesDisplay'] : [];
            rolesDisplay.forEach( (item) => {
                sourceItem.push({displayName: '"'+item+'"'});
            });
        }
        else if(block['name'] == 'changeStageBlock') {
            sourceItem = [
                '"'+model.stageDisplayName+'"'
            ];
        }
        else if(block['name'] == 'scheduledActivityBlock') {
            //Create email activity and schedule next 2 weeks at 8pm.
            let {activityTypeDisplay, displayTime, scheduleInterval, unit} = model;
            let a_or_an = activityTypeDisplay == 'Email' ? 'an' : 'a';
            let scheduleText = '';
            if(scheduleInterval == 0) {
                scheduleText = ' at ' + displayTime;
            }
            else if(scheduleInterval == 1) {
                scheduleText = ' to the next '+unit.slice(0, -1)+' at ' + displayTime;
            }
            else {
                scheduleText = ' to the next '+ scheduleInterval +' '+ unit+' at ' + displayTime;
            }
            let tmpDelta = delta[0]
                .replace('#activityTypeDisplay#', activityTypeDisplay.toLowerCase())
                .replace('#a_or_an#', a_or_an) + scheduleText;
            delta = [tmpDelta];
        }
        else if(block['name'] == 'startSequenceBlock') {
            let tmpDelta = delta[0]
                .toLowerCase()
                .replace('#templatename#', '"'+model['templateName']+'"');

            delta = [tmpDelta, ' to '];
            sourceItem = model['toEmailTag'];
        }
        else if(block['name'] == 'setSalesPersonBlock') {
            let tmpDelta = delta[0]
                .toLowerCase()
                .replace('#salesperson#', model['displayValue']);
            delta = [tmpDelta];
        }
        else if(block['name'] == 'AddJobBlock'){
            let tmpDelta = delta[0]
                .toLowerCase()
                .replace('#jobdescription#', model['displayValue']);
            delta = [tmpDelta];
        }

        if(!sourceItem) {
            return delta;
        }

        if(Array.isArray(sourceItem)) {
            let beforeLastIndex = sourceItem.length - 1;
            delta.push(' ');
            sourceItem.forEach( (lineItem, index) => {
                let cnt = index+1;
                let displayName = typeof lineItem == 'string' ? lineItem : lineItem['displayName']
                delta.push(displayName);
                if(beforeLastIndex == cnt) {
                    delta.push(' ', 'and', ' ')
                }
                else if(beforeLastIndex > 1 && cnt != (beforeLastIndex+1)) {
                    delta.push(',', ' ');
                }
            });
        }
        return delta;
    }

    getDisplayMessageDelta() {
        const {blockObjects} = this;
        const initBlock: InitialBlockInterface = blockObjects[this.initialBlockId];
        const eventsList = this.getEventsList();

        const {rules} = initBlock;
        let displayDelta: { rules:any[]; event: any[] } = {
            event: [],
            rules: []
        };
        if(this.eventName && eventsList.hasOwnProperty(this.eventName)) {
            displayDelta['event'].push(
                eventsList[this.eventName]['text'],
                'with'
            );
        }
        if(rules.length == 0) {
            return displayDelta;
        }
        const operatorsDisplayText = this._triggerCommonService.getOperatorsDisplayText();
        let beforeLastIndex = rules.length - 1;
        rules.forEach( (rule:any, index:number) => {
            let cnt = index+1;
            let display = [];
            const fieldData = this._fieldMetaData[rule['col_name']];
            let fieldDisplayName = fieldData['text'];
            let operatorDisplayName = operatorsDisplayText[rule['operator']];
            let valueDisplayText = rule['value'];
            if(fieldData.hasOwnProperty('options')) {
                if(Array.isArray(rule['value'])) {
                    let tmp = [];
                    rule['value'].forEach( (id) => {
                        const activeOption = fieldData.options.find((item) => {
                            return item.id == id;
                        });
                        if(activeOption) {
                            tmp.push(activeOption['text']);
                        }
                    });
                    valueDisplayText = tmp.join(',')
                }
                else {
                    const activeOption = fieldData.options.find((item) => {
                        return item.id == rule['value'];
                    });
                    if(activeOption) {
                        valueDisplayText = activeOption['text'];
                    }
                }
            }
            display.push(fieldDisplayName.toLowerCase(), ' ', operatorDisplayName, ' ', '"', valueDisplayText, '"');
            displayDelta['rules'].push(display);
            if(beforeLastIndex == cnt ) {
                displayDelta['rules'].push([' ','and', ' ']);
            }
            else if(beforeLastIndex > 1 && cnt != (beforeLastIndex+1)) {
                displayDelta['rules'].push([',', ' ']);
            }
        });
        return displayDelta;
    }

    getModel() {
        this._buildData();
        const {dagObject, blockObjects, endBlocks, mainObjectName, eventName} = this;
        return {
            dagObject,
            blockObjects,
            endBlocks,
            mainObjectName,
            eventName
        };
    }
    isObjectEmpty (objectName) {
        return Object.keys(objectName).length === 0
    }

    getSmsTagsByType(workflowType:string, objectName:string = 'all') {
        let smsTags = {
            'Custom' : [
                {'id' : 't_0', 'text' : '[customer_mobile]', 'name' : 'TAGS'},
                {'id' : 't_3', 'text' : '[job_contact_mobile]', 'name' : 'TAGS'},
                {'id' : 't_4', 'text' : '[invoice_address_mobile]', 'name' : 'TAGS'},
                {'id' : 't_2', 'text' : '[job_address_primary_contact_mobile]','name' : 'TAGS'},
            ],
            'Sequence': [
                {'id' : 't_0', 'text' : '[sequence_contact_mobile]', 'name' : 'TAGS'},
            ],
            'PipelineAutomation': [
                {'id': 't_0', 'text': '[opportunity_customer_contact_mobile]', 'name': 'TAGS', 'objectName': 'opportunity', displayText: 'Customer contact mobile'},
                {'id': 't_1', 'text': '[opportunity_address_mobile]', 'name': 'TAGS', 'objectName': 'opportunity', displayText: 'Opportunity mobile address'},
                {'id': 't_2', 'text': '[customer_mobile]', 'name': 'TAGS', 'objectName': 'job', displayText: 'Customer mobile number'},
                {'id': 't_3', 'text': '[job_contact_mobile]', 'name': 'TAGS', 'objectName': 'job', displayText: 'Job contact mobile number'},
                {'id': 't_4', 'text': '[job_address_primary_contact_mobile]', 'name': 'TAGS', 'objectName': 'job', displayText: 'Job primary contact mobile number'},
            ]
        };

        if(!smsTags[workflowType]) {
            return {};
        }

        let _selectedEmailTags = smsTags[workflowType];
        if(objectName === 'all') {
            return _selectedEmailTags;
        }
        return _selectedEmailTags.filter( tag => tag['objectName'] == objectName);
    }

    getEmailAddressTagsByType(workflowType:string, objectName:string = 'all') {
        let emailTags = {
            'Custom' : [
                {'id': 't_0', 'text': '[customer_email]', 'name' : 'TAGS'},
                {'id': 't_3', 'text': '[job_contact_email]', 'name' : 'TAGS'},
                {'id': 't_4', 'text': '[invoice_address_email]', 'name' : 'TAGS'},
                {'id': 't_2', 'text': '[job_address_primary_contact_email]','name' : 'TAGS'},
            ],
            'Sequence': [
                {'id': 't_0', 'text': '[sequence_contact_email]', 'name': 'TAGS'},
            ],
            'PipelineAutomation': [
                {'id': 't_0', 'text': '[opportunity_customer_contact_email]', 'name': 'TAGS', 'objectName': 'opportunity', displayText: 'Customer contact email'},
                {'id': 't_1', 'text': '[opportunity_address_email]', 'name': 'TAGS', 'objectName': 'opportunity', displayText: 'Opportunity email address'},
                {'id': 't_2', 'text': '[customer_email]', 'name': 'TAGS', 'objectName': 'job', displayText: 'Customer email address'},
                {'id': 't_3', 'text': '[job_contact_email]', 'name': 'TAGS', 'objectName': 'job', displayText: 'Job contact email address'},
                {'id': 't_4', 'text': '[job_address_primary_contact_email]', 'name': 'TAGS', 'objectName': 'job', displayText: 'Job primary contact email address'},
            ]
        };

        if(!emailTags[workflowType]) {
            return {};
        }
        let _selectedEmailTags = emailTags[workflowType];
        if(objectName === 'all') {
            return _selectedEmailTags;
        }
        return _selectedEmailTags.filter( tag => tag['objectName'] == objectName);
    }
    getDisplayDescriptionContent(actionType:any ,stageName: string, objectName:string, blockName: string, isSkipCommunication: boolean = false){
        if(!blockName) {
            return [];
        }
        const {blockObjects} = this;
        if(!blockObjects[blockName]) {
            console.warn("Block does not exists...");
            return ['do something'];
        }
        const block = blockObjects[blockName];
        const blockSourceData = this.getBlocksSource().getBlockByKey(block['key']);
        let delta;
        if(!isSkipCommunication){
            delta = blockSourceData['activityDescription'] ? [...blockSourceData['activityDescription']] : [];
        }else{
            delta = blockSourceData['skipMessage'] ? [...blockSourceData['skipMessage']] : [];
        }

        if (actionType == 1) {
            delta = delta[0]
                .replace('#actionType#', objectName + ' ' + 'moved into')
                .replace('#stageName#', stageName);
        } else if (actionType == 2) {
            delta = delta[0]
                .replace('#actionType#', objectName + ' ' + 'stage moved from')
                .replace('#stageName#', stageName);
        } else if (actionType == 4) {
            delta = delta[0]
                .replace('#actionType#', objectName + ' ' + 'was in')
                .replace('#stageName#', stageName);
        } else {
            delta = delta[0]
                .replace('#stageName#', stageName);
        }
        return delta;
    }
}
