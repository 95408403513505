<div class="cs-d-flex h-100 pos-rlt border-top">
    <ng-container *ngIf="!isLoading; else:firstLevelShimmer">
        <div class="sc-template-wrapper h-100 sc-level-1">
            <div class="cs-d-flex flex-column cs-container h-100">
                <div class="sc-header p--20" [ngClass]="{'cs-on-scrolling': csScrollBarActivated}">
                    <div class="sc-template-overall-progress cs-d-flex m-b--20">
                        <div class="sc-template-overall-progress-title" translate>Overall progress</div>
                        <div class="sc-template-overall-progress-subtle ml-auto">{{totalTasksPercentage}}%</div>
                    </div>
                    <div class="overall-progress-bar">
                        <div class="percent-bar" [style.width.%]="totalTasksPercentage"></div>
                    </div>
                </div>

                <div class="cs-flex-1 p-l--20 p-r--20 p-b--20 p-t--0 pos-rlt" [appCsScrollbar]
                     (psScrollDown)="onScrollDown()" (psYReachStart)="onScrollTopReach()">
                    <div class="sc-list-container">
                        <div class="cs-d-flex flex-column sc-single sc-menu-status-{{singlePropertyInputModel.status}}"
                             [ngClass]="{'current-active': activeTab === 'single'}">
                            <div class="">
                                <div class="cs-d-flex m-b--10 align-items-center">
                                    <div class="sc-single-left">
                                        <small>Step 1 </small>
                                        <div class="label-text" (click)="onSelectTabMenu('single')" translate>Single.Property.Communications</div>
                                    </div>
                                    <div class="sc-single-right ml-auto pos-rlt">
                                        <div [ngStyle]="getOverlayStyle()" class="current">
                                            {{singlePropCompletedTasksCount}}/{{singlePropTotalTasksCount}}</div>
                                        <round-progress [duration]="1000" [max]="singlePropTotalTasksCount"
                                                        [current]="singlePropCompletedTasksCount"></round-progress>
                                    </div>
                                </div>
                                <div [hidden]=" activeTab === 'multiple'" class="sc-divider m-b--10"></div>
                                <div [@collapse]="singleAnimationState=='out'"
                                     class="multiple-list-container cs-d-flex flex-column">
                                    <div>
                                        <div class="cs-d-flex multiple-item align-items-center" [ngClass]="{
                                            'current-active-item': currentActiveMenuName == singlePropertyInputModel.itemName,
                                            'p-b--15': singlePropertyInputModel.status == 'completed'
                                            }">
                                            <div class="multiple-item-icon m-r--10"
                                                 *ngIf="singlePropertyInputModel.status != 'completed'">
                                                <i class="fal fa-circle-chevron-right"></i>
                                            </div>
                                            <div class="multiple-item-icon m-r--10"
                                                 *ngIf="singlePropertyInputModel.status == 'completed'">
                                                <i class="fas fa-check-circle"></i>
                                            </div>
                                            <button (click)="onClickItem( singlePropertyInputModel)"
                                                    class="btn btn-link">{{singlePropertyInputModel.getName()}}</button>
                                        </div>

                                        <div class="cs-d-flex multiple-item align-items-center p-b--15 cs-fo-{{singleFollowUpInputModel.status}}"
                                             *ngFor="let singleFollowUpInputModel of singlePropertyInputModel.followUpMessage; let i = index"
                                             [ngClass]="{'current-active-item': currentActiveMenuName == singleFollowUpInputModel.itemName}">
                                            <div class="multiple-item-icon m-r--10"
                                                 *ngIf="singleFollowUpInputModel.status != 'completed'">
                                                <i class="fal fa-circle-chevron-right"></i>
                                            </div>
                                            <div class="multiple-item-icon m-r--10"
                                                 *ngIf="singleFollowUpInputModel.status == 'completed'">
                                                <i class="fas fa-check-circle"></i>
                                            </div>
                                            <button (click)="onClickItem( singleFollowUpInputModel, i)"
                                                    class="btn btn-link">{{singleFollowUpInputModel.getName()}}</button>
                                        </div>


                                        <div class="cs-d-flex multiple-item align-items-center"
                                             *ngIf="!viewMode && singlePropertyInputModel.status == 'completed'">
                                            <button (click)="addFollowUpMessage()"
                                                    [disabled]="!currentActiveInputModel.isValidInterval() || inValidInterval"
                                                    class="btn btn-link multiple-item-add"
                                                    translate>Add.New.Follow.Up</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="cs-d-flex flex-column sc-multiple sc-menu-status-{{multiplePropertyInputModel.status}}"
                             [ngClass]="{'current-active': activeTab === 'multiple'}">
                            <div class="">
                                <div class="cs-d-flex m-b--10 align-items-center">
                                    <div class="sc-single-left">
                                        <small>Step 2 </small>
                                        <div class="label-text" (click)="onSelectTabMenu('multiple')" translate>Multiple.Property.Communications</div>
                                    </div>
                                    <div class="sc-single-right ml-auto pos-rlt">
                                        <div [ngStyle]="getOverlayStyle()" class="current">
                                            {{multiplePropCompletedTasksCount}}/{{multiplePropTotalTasksCount}}</div>
                                        <round-progress [duration]="1000" [max]="multiplePropTotalTasksCount"
                                                        [current]="multiplePropCompletedTasksCount"></round-progress>
                                    </div>
                                </div>
                                <div [hidden]=" activeTab === 'single'" class="sc-divider m-b--10"></div>
                                <div [@collapse]="multipleAnimationState=='out'"
                                     class="multiple-list-container cs-d-flex flex-column">
                                    <div class="cs-flex-1">
                                        <div class="cs-d-flex multiple-item align-items-center" [ngClass]="{
                                            'current-active-item': currentActiveMenuName == multiplePropertyInputModel.itemName,
                                            'p-b--15': multiplePropertyInputModel.status == 'completed'
                                            }">
                                            <div class="multiple-item-icon m-r--10"
                                                 *ngIf="multiplePropertyInputModel.status != 'completed'">
                                                <i class="fal fa-circle-chevron-right"></i>
                                            </div>
                                            <div class="multiple-item-icon m-r--10"
                                                 *ngIf="multiplePropertyInputModel.status == 'completed'">
                                                <i class="fas fa-check-circle"></i>
                                            </div>

                                            <button (click)="onClickItem(multiplePropertyInputModel)"
                                                    class="btn btn-link">{{multiplePropertyInputModel.getName()}}</button>
                                        </div>

                                        <div class="cs-d-flex multiple-item align-items-center p-b--15"
                                             *ngFor="let multiFollowUpInputModel of multiplePropertyInputModel.followUpMessage; let i = index"
                                             [ngClass]="{'current-active-item': currentActiveMenuName == multiFollowUpInputModel.itemName}">
                                            <div class="multiple-item-icon m-r--10"
                                                 *ngIf="multiFollowUpInputModel.status != 'completed'">
                                                <i class="fal fa-circle-chevron-right"></i>
                                            </div>
                                            <div class="multiple-item-icon m-r--10"
                                                 *ngIf="multiFollowUpInputModel.status == 'completed'">
                                                <i class="fas fa-check-circle"></i>
                                            </div>
                                            <button (click)="onClickItem(multiFollowUpInputModel, i)"
                                                    class="btn btn-link">{{multiFollowUpInputModel.getName()}}</button>
                                        </div>

                                        <div class="cs-d-flex multiple-item align-items-center"
                                             *ngIf="!viewMode && multiplePropertyInputModel.status == 'completed'">
                                            <button (click)="addFollowUpMessage()"
                                                    [disabled]="!currentActiveInputModel.isValidInterval() || inValidInterval"
                                                    class="btn btn-link multiple-item-add"
                                                    translate>Add.New.Follow.Up</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!isLoadingTemplate; else:secondLevelShimmer">
        <div class="sc-template-wrapper h-100 sc-level-2">
            <div class="sc-template-form-row cs-d-flex flex-column h-100 pos-rlt">
                <div class="p--20">
                    <div class="cs-d-flex">
                        <div class="sc-template-title">
                            {{ currentActiveInputModel.getName() }}
                        </div>
                        <button *ngIf="!viewMode && currentActiveInputModel.isFollowUpItem" (click)="onShowConfirmModal()"
                                class="btn btn-link multiple-item-add ml-auto p-r--0" translate>Delete</button>
                    </div>
                </div>
                <div [hidden]="currentActiveInputModel.isFirstCommunication()">
                    <div class="cs-d-flex flex-column sc-template-form-container cs-template-chooser">
                        <div class="cs-form-title  m-b--5">
                            <label class="control-label required_field">Delay</label>
                        </div>
                        <div class="cs-form-input cs-select2-custom  m-b--5">
                            <div class="cs-input-group cs-d-flex cs-d-flex-wrap">
                                <input (ngModelChange)="onChangeIntervalTemplate( $event)"
                                       [(ngModel)]="currentActiveInputModel.interval" type="number" name="interval" min="1"
                                       max="1000" appDigitDecimaNumber [readonly]="viewMode">
                                <div class="cs-input-group-addon">
                                    <span class="input-group-text" translate>Days.after.reminder.date</span>
                                </div>
                            </div>
                            <span *ngIf="inValidInterval" class="control-error-message show p-l--0">
                                <span translate>Interval.Already.Taken.Choose.Different.One</span>
                            </span>
                            <!-- <input class="full-width-select2" type="hidden" csSelect2
                                ($instance)="setSelect2InstanceInterval($event)"
                                (ngModelChange)="onChangeIntervalTemplate( $event)"
                                [select2Config]="select2Options4Interval" [(ngModel)]="currentActiveInputModel.interval"
                                [dropdownvalue]="currentActiveInputModel.interval" /> -->
                        </div>
                    </div>
                </div>
                <div  *access="['SetupEmailTemplates.writeaccess']" >
                    <div class="cs-d-flex flex-column sc-template-form-container cs-template-chooser">
                        <div class="cs-form-title  m-b--5">
                            <label class="control-label">Email</label>
                        </div>
                        <div class="cs-form-input cs-select2-custom  m-b--5">
                            <!-- <input class="full-width-select2" type="hidden"
                               csSelect2
                               (ngModelChange)="onChangeEmailTemplate( $event)"
                               [select2Config]="select2Options4Email"
                               [(ngModel)]="currentActiveInputModel.messages['email'].templateId"
                               [dropdownvalue]="currentActiveInputModel.messages['email'].templateId" /> -->
                            <app-search-template [screen]="'email_template'" [screenLabel]="emailScreenLabel"
                                                 [showAddNewTemplate]="!viewMode" [selectedTemplate]="selectedTemplateTitles['email']"
                                                 [viewMode]="viewMode"
                                (selectedItem)="setTemplateDetails($event,'email')"></app-search-template>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="smsModulePermission">
                    <div *access="['SetupSMSTemplates.writeaccess']">
                        <div *ngIf="activeTab == 'single'">
                            <div class="cs-d-flex flex-column sc-template-form-container cs-template-chooser">
                                <div class="cs-form-title  m-b--5">
                                    <label class="control-label">SMS</label>
                                </div>
                                <div class="cs-form-input cs-select2-custom  m-b--5">
                                    <!-- <input class="full-width-select2" type="hidden"
                                           csSelect2
                                           (ngModelChange)="onChangeSmsTemplate( $event)"
                                           [select2Config]="select2Options4Sms"
                                           [(ngModel)]="currentActiveInputModel.messages['sms'].templateId"
                                           [dropdownvalue]="currentActiveInputModel.messages['sms'].templateId" /> -->
                                    <app-search-template [screen]="'sms_template'" [screenLabel]="smsScreenLabel"
                                                         [showAddNewTemplate]="!viewMode"
                                                         [selectedTemplate]="selectedTemplateTitles['sms']"
                                                         [viewMode]="viewMode"
                                                         (selectedItem)="setTemplateDetails($event,'sms')"></app-search-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div  *access="['SetupLetterTemplates.writeaccess']">
                    <div class="cs-d-flex flex-column sc-template-form-container cs-template-chooser">
                        <div class="cs-form-title  m-b--5">
                            <label class="control-label">Letter</label>
                        </div>
                        <div class="cs-form-input cs-select2-custom  m-b--5">
                            <app-search-template [screen]="'letter_template'" [screenLabel]="letterScreenLabel"
                                                 [showAddNewTemplate]="!viewMode" [selectedTemplate]="selectedTemplateTitles['letter']"
                                                 [viewMode]="viewMode"
                                                 (selectedItem)="setTemplateDetails($event,'letter')"></app-search-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<ng-container *ngIf="currentActiveInputModel">
    <div class="modal fade" bsModal #actionModal="bs-modal" [config]="{backdrop:false}" tabindex="-1" role="dialog"
         aria-labelledby="dialog-static-name">
        <div class="modal-dialog modal-sm">
            <ng-container>
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" aria-label="Close"
                                (click)="onHiddenModal()">&times;</button>
                        <h4 translate>Delete.FollowUp.Title</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row-fluid">
                            <div class="span12" *ngIf="showConfirmModel">
                                <p translate [translateParams]="{followUpName: currentActiveInputModel.name }">
                                    FollowUp.Delete.Message</p>
                            </div>
                        </div>
                        <div class="row-fluid">
                            <div class="span12 confirm_delete_wrapper">
                                <div class="input-outer-wrapper">
                                    <div class="input-prepend box-shadow input-fullwidth">
                                        <span class="add-on">
                                            <span class="ss-caution"></span>
                                        </span>
                                        <div class="input-wrapper">
                                            <input [(ngModel)]="deleteConfirmTextInput" (keyup)="checkConfirmText()"
                                                   (keyup.enter)="deleteAction()" type="text" class="modalDeleteNumber"
                                                   id="confirm_delete_input" />
                                        </div>
                                    </div>
                                    <div>
                                        <span *ngIf="inValidConfirmText" class="control-error-message show row-fluid">
                                            <span translate [translateParams]="{Object: validationMessage}">
                                                You.need.to.type.the.word.Object</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button (click)="onHiddenModal()" class="btn" aria-hidden="true" id="closeModal"><span
                            translate>Close</span></button>
                        <button type="button" class="btn btn-danger"
                                [disabled]='inValidConfirmText || deleteConfirmTextInput==""' [ngClass]="{
                            'loading': confirmLoading
                            }" (click)="deleteAction()" translate>
                            {{ buttonText }}
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="modal-backdrop cs-backdrop fade" [ngClass]="{'in': showConfirmModel==true}"></div>
</ng-container>

<ng-template #firstLevelShimmer>
    <div class="sc-template-wrapper h-100 sc-level-1">
        <div class="cs-d-flex flex-column cs-container h-100">
            <div class="sc-header p--20">
                <div class="sc-template-overall-progress cs-d-flex m-b--20">
                    <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0"
                         [style.width]="'280px'">
                        <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                            <div class="ph-row" [style.align-items]="'center'">
                                <div class="ph-col-6 m-b--3"></div>
                                <div class="ph-col-4 empty m-b--3"></div>
                                <div class="ph-col-2 sm m-b--3"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="overall-progress-bar">
                    <div class="percent-bar" style="width: 0%; background-color: #EBEBEB"></div>
                </div>
            </div>

            <div class="cs-flex-1 p-l--20 p-r--20 p-b--20 p-t--0">
                <div class="sc-list-container">
                    <div class="cs-d-flex flex-column sc-single current-active">
                        <div class="">
                            <div class="cs-d-flex m-b--10 align-items-center">
                                <div class="sc-single-left cs-flex-1">
                                    <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0">
                                        <div class="ph-col-12 height-100" [style.padding-left]="0"
                                             [style.padding-right]="0">
                                            <div class="ph-row" [style.align-items]="'center'">
                                                <div class="ph-col-2 sm m-b--5"></div>
                                                <div class="ph-col-10 sm empty m-b--5"></div>
                                                <div class="ph-col-10"></div>
                                                <div class="ph-col-2 empty"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sc-single-right ml-auto pos-rlt">
                                    <div [ngStyle]="getOverlayStyle()" class="current" [style.opacity]="'0.6'">0/0</div>
                                    <round-progress max="2" current="1" color="#EBEBEB"></round-progress>
                                </div>
                            </div>
                            <div class="sc-divider m-b--10"></div>
                            <div class="multiple-list-container cs-d-flex flex-column">
                                <div>
                                    <div class="cs-d-flex multiple-item align-items-center p-b--15">
                                        <div class="multiple-item-icon m-r--10" style="color:#EBEBEB"><i
                                            class="fas fa-circle"></i></div>
                                        <div class="cs-flex-1">
                                            <div class="ph-item height-100" [style.padding-left]="0"
                                                 [style.padding-right]="0">
                                                <div class="ph-col-12 height-100" [style.padding-left]="0"
                                                     [style.padding-right]="0">
                                                    <div class="ph-row" [style.align-items]="'center'">
                                                        <div class="ph-col-10"></div>
                                                        <div class="ph-col-2 empty"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cs-d-flex multiple-item align-items-center">
                                        <div class="cs-flex-1">
                                            <div class="multiple-item-add ph-item height-100" [style.padding-left]="0"
                                                 [style.padding-right]="0" [style.margin-left]="'36px'">
                                                <div class="ph-col-12 height-100" [style.padding-left]="0"
                                                     [style.padding-right]="0">
                                                    <div class="ph-row" [style.align-items]="'center'">
                                                        <div class="ph-col-8 sm"></div>
                                                        <div class="ph-col-4 sm empty"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="cs-d-flex sc-multiple">
                        <div class="sc-single-left cs-flex-1">
                            <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0">
                                <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                                    <div class="ph-row" [style.align-items]="'center'">
                                        <div class="ph-col-2 sm m-b--5"></div>
                                        <div class="ph-col-10 sm empty m-b--5"></div>
                                        <div class="ph-col-10"></div>
                                        <div class="ph-col-2 empty"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sc-single-right ml-auto pos-rlt">
                            <div [ngStyle]="getOverlayStyle()" class="current" [style.opacity]="'0.6'">0/0</div>
                            <round-progress max="2" current="1" color="#EBEBEB"></round-progress>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #secondLevelShimmer>
    <div class="sc-template-wrapper h-100 sc-level-2">
        <div class="sc-template-form-row cs-d-flex flex-column h-100 pos-rlt">
            <div class="p--20">
                <div class="sc-template-title">
                    <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0">
                        <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                            <div class="ph-row" [style.align-items]="'center'">
                                <div class="ph-col-8 big m-b--3"></div>
                                <div class="ph-col-4 big empty m-b--3"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngFor="let item of [1,2,3];" class="p-l--20 p-r--20 p-b--20">
                <div class="cs-d-flex flex-column cs-template-form-container p-b--0">
                    <div class="cs-form-title m-b--5">
                        <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0">
                            <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                                <div class="ph-row">
                                    <div class="ph-col-4 m-b--3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cs-form-input m-b--5">
                        <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0">
                            <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                                <div class="ph-row">
                                    <div class="ph-col-12 big m-b--3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0">
                            <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                                <div class="ph-row">
                                    <div class="ph-col-6 sm m-b--3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
