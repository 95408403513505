import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from '@app/interfaces';
import { SettingsService } from '@app/core/settings/settings.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../core/settings/settings.service";
var TrackerService = /** @class */ (function (_super) {
    tslib_1.__extends(TrackerService, _super);
    function TrackerService(http, appService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.appService = appService;
        return _this;
    }
    TrackerService.prototype.getTrackerData = function () {
        return this.appService.getTrackerData();
    };
    TrackerService.prototype.createSession = function () {
        this.trackerData = this.getTrackerData();
        var sessionUrl = this.trackerData['gps_api'] + '/session';
        var c = atob(this.trackerData['gps_api_key']).split(':');
        var headersToSet = {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Accept': 'application/json',
            'Original-Request': '',
        };
        var data = 'email=' + c[0] + '&password=' + c[1];
        var options = {
            headers: headersToSet,
            withCredentials: true,
            gpsOverride: true
        };
        return this.http.post(sessionUrl, data, options);
    };
    TrackerService.prototype.getSocket = function () {
        var _this = this;
        this.createSession().subscribe(function (result) {
            _this.handshake();
            return _this.socket;
        });
    };
    TrackerService.prototype.handshake = function () {
        var self = this;
        try {
            var socket_1 = new WebSocket(this.trackerData['gps_api_socket']);
            socket_1.onopen = function (event) {
                console.log("Gps socket opened");
                self.socket = socket_1;
            };
            socket_1.onclose = function (event) {
                self.socket = null;
                console.log("Gps socket closed");
            };
            socket_1.onerror = function (event) {
                console.log("Error");
            };
        }
        catch (e) {
            console.log('');
        }
    };
    TrackerService.ngInjectableDef = i0.defineInjectable({ factory: function TrackerService_Factory() { return new TrackerService(i0.inject(i1.HttpClient), i0.inject(i2.SettingsService)); }, token: TrackerService, providedIn: "root" });
    return TrackerService;
}(InterfaceService));
export { TrackerService };
