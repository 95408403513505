<div class="modal-header">
    <h4 class="modal-title pull-left">{{changeStageBlockData['title']}}</h4>
</div>
<div class="modal-body">
    <form class="no-form-style" *ngIf="form" [formGroup]="form" novalidate>
        <div class="cs-d-flex flex-column">
            <div class="cs-helper-section bg-white cs-border cs-border--bottom">
                <div class="cs-help-text-color p--15">{{changeStageBlockData['description']}}</div>
            </div>
            <div class="cs-flex-1">
                <div class="cs-block-widget--form cs-d-flex flex-column">
                    <div class="cs-block-widget--form-control">
                        <label class="control-label required_field">Select the stage:</label>
                        <div>
                            <select class="full-width-select2 cs-select2-wfe default-input-height"
                                    formControlName="stageId"
                                    csSelect2
                                    [dropdownvalue]="inputModel.stageId"
                                    [select2Config]="stageSelect2Config">
                                <option value="" translate>Please.Choose</option>
                                <option *ngFor="let item of stageLists" [value]="item.id">{{item.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer" *ngIf="form">
    <ng-container *ngIf="!isLoading else footerSection">
        <button *ngIf="showDeleteBtn" class="btn btn-danger btn-medium float-left" (click)="remove()" type="button">Delete </button>
        <button class="btn btn-link" (click)="closeModal()">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="!form.valid || isFormSubmitted" (click)="save()">
            <span *ngIf="!isFormSubmitted" translate>Save</span>
            <span *ngIf="isFormSubmitted" translate>Saving</span>
        </button>
    </ng-container>
</div>

<ng-template #footerSection>
    <button class="btn btn-link" [disabled]="true">Cancel</button>
    <button type="submit" class="btn btn-primary" [disabled]="true">
        <span translate>Save</span>
    </button>
</ng-template>
