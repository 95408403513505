import { environment } from '../../environments/environment';
export { environment } from '../../environments/environment';

export function GetV5CoreApiUrl(path: string, params?: object) {
    const v5CorePrefix = environment.microserviceApiV5Core;
    let url = environment.apiHost + v5CorePrefix + path;
    if (params instanceof Object) {
        url += '?';

        Object.entries(params).forEach(([param, value]) => {
            url += `${encodeURIComponent(param)}=${encodeURIComponent(value)}&`;
        });
    }
    return url;
}
