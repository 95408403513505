import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from "@app/interfaces";
import {TimelineDatePipe} from "@app/pipes/timeline-date.pipe";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})


export class JobsTabService extends InterfaceService{
    private jobsTabHistory: any = '';
    private toasterMessage: any = '';

    constructor(private http: HttpClient,
                private timelineDateTime: TimelineDatePipe) {
        super();
    }

    getJobTeamViews(id) {
        return this.http.get(this.getJobApiUrl(`get_job_team_views/${id}`));
    };

    getViewsJobsList = function (id,params) {
        return this.http.get(this.getJobApiUrl(`get_views_job_list/${id}`,params));
    };

    getjobsTabHistory(){
        return this.jobsTabHistory;
    }

    setjobsTabHistory(data){
        this.jobsTabHistory = data;
    }

    getJobsTabViews = function () {
        return this.http.get(this.getJobApiUrl(`get_jobs_tab_views`));
    };

    convertJobDateTime(date) {
        return this.timelineDateTime.transform(date, 'datetime');
    }

    setToasterMessage(data){
        this.toasterMessage = data;
    }
    getToasterMessage(){
        return this.toasterMessage;
    }

    getViewsJobCount = function (teamId?: any, viewId?: any): Observable<any> {
        if (teamId && teamId > 0) {
            if (viewId && viewId > 0) {
                return this.http.get(this.getJobApiUrl(`get_team_view_count/${teamId}/${viewId}`));
            } else {
                return this.http.get(this.getJobApiUrl(`get_team_views_count/${teamId}`));
            }
        } else {
            if (viewId && viewId > 0) {
                return this.http.get(this.getJobApiUrl(`get_view_count/${viewId}`));
            } else {
                return this.http.get(this.getJobApiUrl(`get_views_count`));
            }
        }
    };
}
