import { OnInit } from '@angular/core';
import { ViewVehicleService } from "@app/features/fleet-management/add-vehicle/services/view-vehicle-service";
var FleetMapViewComponent = /** @class */ (function () {
    function FleetMapViewComponent(vehicleService) {
        this.vehicleService = vehicleService;
        this.viewMode = false;
        this.officeDisplay = true;
        this.displayViewModeDropdown = '';
        this.exapandVehicleDetailCard = false;
        this.allCheckboxSelected = false;
        this.homeSearchText = '';
        this.supplierSearchText = '';
        this.homeSelectAll = false;
        this.savedLocations = [];
        this.suppliersLocations = [];
        this.locationSearchText = '';
        this.homeAddress = [];
        this.filteredHomeAddress = []; //home location
        this.addressList = []; //save location
        this.filteredAddress = [];
        this.filteredSupplierAddress = [];
        this.supplierLocationList = []; //supplier location
        this.supplierCount = 0;
        this.userHomeCount = 0;
        this.savedLocationCount = 0;
        this.allCheckboxSelectedSavedLocation = false;
        this.isDeleteInProgress = false;
    }
    FleetMapViewComponent.prototype.ngOnInit = function () {
        this.getMapPinData();
    };
    FleetMapViewComponent.prototype.ngOnChanges = function (Changes) {
        if (Changes.liveMapInstance.currentValue) {
            this.map = Changes.liveMapInstance.currentValue;
        }
    };
    FleetMapViewComponent.prototype.toggleViewMode = function () {
        this.viewMode = !this.viewMode;
    };
    FleetMapViewComponent.prototype.toggleOfficeDisplay = function () {
        this.officeDisplay = !this.officeDisplay;
        this.addOfficeLocation(this.officeDisplay);
    };
    FleetMapViewComponent.prototype.searchHome = function () {
        var query = this.homeSearchText.toLowerCase();
        this.filteredHomeAddress = this.homeAddress.filter(function (address) {
            return address.userName.toLowerCase().includes(query);
        });
        this.homeSelectAll = this.filteredHomeAddress.every(function (item) { return item.isSelected == true; });
    };
    FleetMapViewComponent.prototype.searchSuppliers = function () {
        var query = this.supplierSearchText.toLowerCase();
        this.filteredSupplierAddress = this.supplierLocationList.filter(function (supplier) {
            return supplier.name.toLowerCase().includes(query);
        });
        this.allCheckboxSelectedSupplier = this.filteredSupplierAddress.every(function (item) { return item.isSelected == true; });
    };
    FleetMapViewComponent.prototype.getText = function () {
        this.homeSearchText = '';
        this.supplierSearchText = '';
        this.locationSearchText = '';
        this.searchLocation();
        this.searchHome();
        this.searchSuppliers();
    };
    FleetMapViewComponent.prototype.selectAllHomeAddressList = function (event) {
        var _this = this;
        this.homeSelectAll = !this.homeSelectAll;
        this.homeLayer = L.featureGroup();
        this.filteredHomeAddress.forEach(function (item) {
            item.isSelected = event.target.checked;
            _this.toggleMarker(item, 'home', event.target.checked, _this.homeAddress, _this.homeLayer);
        });
        this.homeLayer.addTo(this.map);
    };
    FleetMapViewComponent.prototype.isHomeSelected = function (evt, index, val) {
        this.filteredHomeAddress[index].isSelected = evt.target.checked;
        this.homeSelectAll = this.filteredHomeAddress.every(function (item) { return item.isSelected == true; });
        this.homeLayer = L.featureGroup();
        this.toggleMarker(val, 'home', evt.target.checked, this.homeAddress, this.homeLayer);
        this.homeLayer.addTo(this.map);
    };
    FleetMapViewComponent.prototype.checkUncheckAllAddressList = function (event) {
        var _this = this;
        this.allCheckboxSelectedSavedLocation = !this.allCheckboxSelectedSavedLocation;
        this.locationLayer = L.featureGroup();
        this.filteredAddress.forEach(function (item) {
            item.isSelected = event.target.checked;
            _this.toggleMarker(item, item.locationIcon, event.target.checked, _this.savedLocations, _this.locationLayer, 'saved-location-background');
        });
        this.locationLayer.addTo(this.map);
    };
    FleetMapViewComponent.prototype.isAllSelected = function (evt, index, val) {
        if (this.isDeleteInProgress) {
            return;
        }
        this.filteredAddress[index].isSelected = evt.target.checked;
        this.allCheckboxSelectedSavedLocation = this.filteredAddress.every(function (item) { return item.isSelected == true; });
        this.locationLayer = L.featureGroup();
        this.toggleMarker(val, val.locationIcon, evt.target.checked, this.savedLocations, this.locationLayer, 'saved-location-background');
        this.locationLayer.addTo(this.map);
    };
    FleetMapViewComponent.prototype.allCheckboxSelectedSuppliers = function (event) {
        var _this = this;
        this.allCheckboxSelectedSupplier = !this.allCheckboxSelectedSupplier;
        this.supplierIconLayer = L.featureGroup();
        this.filteredSupplierAddress.forEach(function (item) {
            item.isSelected = event.target.checked;
            _this.toggleMarker(item, 'supplier', event.target.checked, _this.suppliersLocations, _this.supplierIconLayer);
        });
        this.supplierIconLayer.addTo(this.map);
    };
    FleetMapViewComponent.prototype.isAllSelectedSupplier = function (evt, index, val) {
        this.filteredSupplierAddress[index].isSelected = evt.target.checked;
        this.allCheckboxSelectedSupplier = this.filteredSupplierAddress.every(function (item) { return item.isSelected == true; });
        this.supplierIconLayer = L.featureGroup();
        this.toggleMarker(val, 'supplier', evt.target.checked, this.suppliersLocations, this.supplierIconLayer);
        this.supplierIconLayer.addTo(this.map);
    };
    FleetMapViewComponent.prototype.deleteLocation = function (event, address) {
        var _this = this;
        this.isDeleteInProgress = true;
        event.stopPropagation();
        this.vehicleService.deleteLocation(address.id).subscribe(function (res) {
            if (res) {
                _this.getMapPinData();
                _this.isDeleteInProgress = false;
            }
        });
    };
    FleetMapViewComponent.prototype.getMapPinData = function () {
        var _this = this;
        this.vehicleService.getMapPinDatas().subscribe(function (result) {
            if (result) {
                _this.addressList = result['savedLocations'];
                _this.filteredAddress = result['savedLocations'];
                _this.officeLatLon = result['officeAddress']; //office location
                _this.filteredHomeAddress = result['homeAddress'];
                _this.homeAddress = result['homeAddress'];
                _this.supplierLocationList = result['supplierLocations'];
                _this.filteredSupplierAddress = result['supplierLocations']; //supplier location
                _this.savedLocationCount = _this.addressList.length;
                _this.supplierCount = _this.supplierLocationList.length;
                _this.userHomeCount = _this.homeAddress.length;
                _this.addOfficeLocation(true);
            }
        });
    };
    FleetMapViewComponent.prototype.addOfficeLocation = function (value) {
        if (value) {
            if (this.officeLatLon && this.officeLatLon.length) {
                this.officeLayerMap = L.featureGroup();
                var branchIcon = {
                    icon: L.divIcon({
                        className: 'customer-marker',
                        html: "<div class=\"profile-pointer\"><div class=\"livemap-icon office\"></div></div>",
                        iconSize: [30, 42],
                        iconAnchor: [15, 42]
                    })
                };
                L.marker([this.officeLatLon[0], this.officeLatLon[1]], branchIcon).addTo(this.officeLayerMap);
                if (this.map) {
                    this.officeLayerMap.addTo(this.map);
                }
                else {
                    var self_1 = this;
                    setTimeout(function () {
                        self_1.officeLayerMap.addTo(this.map);
                    }, 1000);
                }
            }
        }
        else {
            if (this.officeLayerMap) {
                this.officeLayerMap.remove();
            }
        }
    };
    FleetMapViewComponent.prototype.searchLocation = function () {
        var query = this.locationSearchText.toLowerCase();
        this.filteredAddress = this.addressList.filter(function (address) {
            return address.name.toLowerCase().includes(query);
        });
        this.allCheckboxSelectedSavedLocation = this.filteredAddress.every(function (item) { return item.isSelected == true; });
    };
    FleetMapViewComponent.prototype.toggleMarker = function (item, iconClass, isChecked, markerList, layerGroup, dynamicClass) {
        if (dynamicClass === void 0) { dynamicClass = ''; }
        var engineerName = '';
        if (iconClass == 'home') {
            engineerName = "" + item['userName'];
        }
        var userIcon = {
            icon: L.divIcon({
                className: 'customer-marker',
                html: "<div class=\"profile-pointer\">\n                    <div class=\"livemap-icon " + dynamicClass + " " + iconClass + "\"></div></div>",
                iconSize: [30, 42],
                iconAnchor: [15, 42]
            })
        };
        if (isChecked) {
            if (!markerList[item.userName || item.name || item.name]) {
                markerList[item.userName || item.name || item.name] = L.marker([item.latitude, item.longitude], userIcon).addTo(layerGroup);
                markerList[item.userName || item.name || item.name].bindTooltip(item.userName || item.name || item.name, { direction: 'top', className: 'custom-tooltip-user-alignment' }).openTooltip();
                markerList[item.userName || item.name || item.name].on('mouseover', function () {
                    this.openTooltip();
                });
                markerList[item.userName || item.name || item.name].on('mouseout', function () {
                    this.closeTooltip();
                });
            }
        }
        else {
            if (markerList[item.userName || item.name || item.name]) {
                markerList[item.userName || item.name || item.name].remove();
                markerList[item.userName || item.name || item.name] = undefined;
            }
        }
    };
    FleetMapViewComponent.prototype.removeMarkerPopup = function () {
        if (this.map._popup && this.map._popup.isOpen()) {
            this.map._popup.remove();
            this.map._panes.overlayPane.remove();
        }
    };
    return FleetMapViewComponent;
}());
export { FleetMapViewComponent };
