import { Injectable } from '@angular/core';
import { InterfaceService } from '@app/interfaces';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import {Subject} from "rxjs/internal/Subject";

@Injectable({ providedIn: 'root' })

export class AddOpportunityService extends InterfaceService {

    private reloadListData = new BehaviorSubject('');
    reloadListData$ = this.reloadListData.asObservable();

    private completeActivity = new BehaviorSubject('');
    completeActivity$ = this.completeActivity.asObservable();

    private viewModeObserver = new BehaviorSubject('');
    viewModeObserver$ = this.viewModeObserver.asObservable();

    private filterArray = new BehaviorSubject('');
    filterArray$ = this.filterArray.asObservable();

    private searchActivity = new BehaviorSubject('');
    searchActivity$ = this.searchActivity.asObservable();

    private filterUser = new BehaviorSubject('');
    filterUser$ = this.filterUser.asObservable();

    private completeData = new BehaviorSubject('');
    completeData$ = this.completeData.asObservable();

    private opportunitydetails = new BehaviorSubject(null);
    $opportunitydetails = this.opportunitydetails.asObservable();

    private refreshData = new BehaviorSubject('');
    $refreshData = this.refreshData.asObservable();

    private refreshActivityList = new BehaviorSubject('');
    $refreshActivityList = this.refreshActivityList.asObservable();

    private refreshActivityProposal = new BehaviorSubject('');
    $refreshActivityProposal = this.refreshActivityProposal.asObservable();

    private whoIsViewing = new BehaviorSubject('');
    $whoIsViewing = this.whoIsViewing.asObservable();

    private editDetails = new BehaviorSubject('');
    $editDetails = this.editDetails.asObservable();

    private handsonColumnFilter = new BehaviorSubject('');
    $handsonColumnFilter = this.handsonColumnFilter.asObservable();

    private deleteActivityData = new BehaviorSubject('');
    $deleteActivityData = this.deleteActivityData.asObservable();

    private proposalCreated = new BehaviorSubject(false);
    $proposalCreated = this.proposalCreated.asObservable();

    private attachedFiles = new BehaviorSubject('');
    $attachedFiles = this.attachedFiles.asObservable();

    private opportunitySchedule = new BehaviorSubject('');
    $opportunitySchedule = this.opportunitySchedule.asObservable();

    private opportunityNote = new BehaviorSubject('');
    $opportunityNote = this.opportunityNote.asObservable();

    private opportunityStatus = new BehaviorSubject('');
    $opportunityStatus = this.opportunityStatus.asObservable();

    private nextActivity = new BehaviorSubject('');
    nextActivity$ = this.nextActivity.asObservable();

    private opportunityEmailModal = new BehaviorSubject(false);
    opportunityEmailModal$ = this.opportunityEmailModal.asObservable();

    private timelineActivities= new BehaviorSubject('');
    $timelineActivities = this.timelineActivities.asObservable();

    private emailDraft= new BehaviorSubject('');
    $emailDraft = this.emailDraft.asObservable();

    private filterPreferences = new BehaviorSubject('');
    $filterPreferences = this.filterPreferences.asObservable();

    private reloadTimelineData = new BehaviorSubject('');
    reloadTimelineData$ = this.reloadTimelineData.asObservable();

    private invoiceData = new BehaviorSubject('');
    invoiceData$ = this.invoiceData.asObservable();

    private googleFontCollection = new BehaviorSubject(null);
    googleFontCollection$ = this.googleFontCollection.asObservable();



    constructor(private http: HttpClient) {
        super();
    }
    getDropdownData(customerId,type,id) {
        return this.http.get(this.getApiUrl(`sales/getOpportunityAddData/${customerId}?type=${type}&id=${id}`))
    }

    addOpportunity(data: Object, files, customerId): Observable<any> {
        if (typeof files !== 'undefined') {
            const formData: any = new FormData();
            if (files != undefined) {
                for (var i = 0; i < files.length; i++) {
                    formData.append("file" + i, files[i]);
                }
            }
            for (var key in data) {
                // check if the property/key is defined in the object itself, not in parent
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }
            return this.http.post(this.getApiUrl(`sales/addOpportunity/${customerId}`), formData);
        }
    }

    editOpportunity(data: Object, files, opportunityId): Observable<any> {
        if (typeof files !== 'undefined') {
            const formData: any = new FormData();
            if (files != undefined) {
                for (var i = 0; i < files.length; i++) {
                    formData.append("file" + i, files[i]);
                }
            }
            for (var key in data) {
                // check if the property/key is defined in the object itself, not in parent
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }
            return this.http.post(this.getApiUrl(`sales/editOpportunity/${opportunityId}`), formData);
        }
    }

    // get opportunity data
    getOpportunityData(id) {
        let url = this.getApiUrl(`sales/getOpportunityDetails/${id}`)
        return this.http.get(url);
    }

    getCustomerDetails(id) {
        let url = this.getApiUrl(`customers/customer/${id}/view/property/view?page=1&limit=10`)
        return this.http.get(url);
    }

    getPreloadCustomer(id) {
        let url = this.getApiUrl(`sales/getPreloadCustomer/${id}`)
        return this.http.get(url);
    }

    getOpportunityRelatedData(templateId) {
        let url = this.getApiUrl(`sales/getOpportunityTemplateData/${templateId}`)
        return this.http.get(url);
    }

    getEmailTemplates() {
        let url = this.getApiUrl(`listSettingsEmailTemplates?limit=1000&page=1`)
        return this.http.get(url);
    }

    listEmailTemplates(propertyId, opportunityId=null) {
        let url = this.getApiUrl(`sales/getEmailTemplates?propertyId=${propertyId}&opportunityId=${opportunityId}`)
        return this.http.get(url);
    }

    getattachments(oppId,opportunityId=null) {
        let url = this.getApiUrl(`getAttatchedFiles/${oppId}?limit=1000&page=1&opportunityId=${opportunityId}`)
        return this.http.get(url);
    }

    getListActivityType() {
        let url = this.getApiUrl(`sales/listActivityType`)
        return this.http.get(url);
    }

    createScheduledActivity(data, urls) {
        let url = this.getApiUrl(urls)
        return this.http.post(url, data);
    }

    createOpportunityEmail(data, id, customerId = null) {
        let url = this.getApiUrl(`sales/opportunityActivityEmail/${id}?customerId=${customerId}`)
        return this.http.post(url, data);
    }

    createOpportunitySMS(data, id) {
        let url = this.getApiUrl(`sales/opportunityActivitySMS/${id}`)
        return this.http.post(url, data);
    }

    createOpportunityCall(data, urls) {
        let url = this.getApiUrl(urls)
        return this.http.post(url, data);
    }

    createOpportunityNote(data, id) {
        let url = this.getApiUrl(`sales/opportunity_new_note/${id}`)
        return this.http.post(url, data);
    }

    getCustomerEmailAddress(customerId,type) {
        let url = this.getApiUrl(`get_customer_emailaddresses?id=${customerId}&type=${type}`)
        return this.http.get(url);
    }

    listActivity(oppId) {
        let url = this.getApiUrl(`sales/getActivityTimeline/${oppId}`)
        return this.http.get(url);
    }

    getCustomerSmsAddress(customerId) {
        let url = this.getApiUrl(`sales/getSMSContacts/${customerId}`)
        return this.http.get(url);
    }

    getAssignee() {
        let url = this.getApiUrl(`sales/listActivityAssignee`)
        return this.http.get(url);
    }

    getCustomerContact(customerId) {
        let url = this.getApiUrl(`sales/getPhoneCallContacts/${customerId}`)
        return this.http.get(url);
    }

    createContact(customerId: number,  data: Object): Observable<any> {
        return this.http.post(this.getApiUrl(`customers/${customerId}/addNewContacts`), JSON.stringify(data));
    }

    setRefreshActivityList(data) {
        this.reloadListData.next(data)
    }

    setRefreshActivityProposal(data){
        this.refreshActivityProposal.next(data);
    }

    loadActivityList(data) {
        this.refreshActivityList.next(data)
    }

    refreshCompleteActivity(data) {
        this.refreshData.next(data)
    }

    setCompleteActivityDetails(data) {
        this.completeActivity.next(data)
    }

    setViewModeObserver(data) {
        this.viewModeObserver.next(data)
    }

    setFilterArray(data) {
        this.filterArray.next(data)
    }

    setSearchActivity(data) {
        this.searchActivity.next(data)
    }

    setFilterUserArray(data) {
        this.filterUser.next(data)
    }

    getSmsTemplates() {
        let url = this.getApiUrl(`listSettingsSmsTemplates?limit=1000&page=1`)
        return this.http.get(url);
    }

    setGoogleFontCollection(data:any) {
        this.googleFontCollection.next(data)
    }
    listSmsTemplates(propertyId, opportunityId=null) {
        let url = this.getApiUrl(`sales/getSmsTemplates?propertyId=${propertyId}&opportunityId=${opportunityId}`)
        return this.http.get(url);
    }

    saveFiles(oppId, files, shareOnMobile, deleteIds?, id?) {
        const formData: any = new FormData();
        if (files != undefined) {
            for (var i = 0; i < files.length; i++) {
                formData.append("file" + i, files[i]);
            }
        }
        formData.append("shareOnMobile", shareOnMobile)
        if (deleteIds) {
            formData.append("deleteIds", deleteIds)
        }
        if (id) {
            formData.append("id", id)
        }
        let url = this.getApiUrl(`sales/upload_opportunity_activity_attach_file/${oppId}`)
        return this.http.post(url, formData);
    }

    setCompleteData(data) {
        this.completeData.next(data);
    }

    saveSelectedNvagiation(data) {
        let url = this.getApiUrl(`userColumnOrder`)
        return this.http.post(url, data);
    }
    getFromEmailAddress() {
        let url = this.getApiUrl(`get_from_emails`)
        return this.http.get(url);
    }
    addProposalopportunity(id, regenerateOption, cheapPrice, isFreemium) {
        let url = this.getApiUrl(`sales/add_opportunity_proposal_pages/${id}?regenerate=${regenerateOption}&cheapPrice=${cheapPrice}&isFreemium=${isFreemium}`)
        return this.http.get(url)
    }

    getProposalPriceRange(id) {
        let url = this.getApiUrl(`sales/getProposalPriceRange/${id}`)
        return this.http.get(url)
    }

    getOpportunityPages(id, proposalId) {
        let url = this.getApiUrl(`sales/get_opportunity_pages/${id}?opportunityProposalId=${proposalId}`)
        return this.http.get(url)
    }

    generatePdfFiles(id,data) {
        let url = this.getApiUrl(`sales/generate_pdf/${id}`)
        return this.http.post(url, data)
    }

    addNewPage(id, data) {
        let url = this.getApiUrl(`sales/add_opportunity_page/${id}`)
        return this.http.post(url, data)
    }
    editOpportunityPage(id, data) {
        let url = this.getApiUrl(`sales/edit_opportunity_page/${id}`)
        return this.http.post(url, data)
    }
    setOpportunityId(id) {
        this.opportunitydetails.next(id)
    }

    updateWhoIsViewing(availableUsers) {
        this.whoIsViewing.next(availableUsers)
    }

    deleteActivity(activityId) {
        let url = this.getApiUrl(`sales/deleteActivity/${activityId}`)
        return this.http.delete(url)
    }

    deleteAttachFiles(id, opportunityId=null) {
        let url = this.getApiUrl(`sales/deleteActivityAttachedFile/${id}?opportunityId=${opportunityId}`)
        return this.http.delete(url)
    }

    editNotes(data) {
        let url = this.getApiUrl(`sales/edit_opportunity_note`)
        return this.http.post(url, data)
    }

    setEditDetails(data) {
        this.editDetails.next(data)
    }

    setHandsonColumnFilter(data) {
        this.handsonColumnFilter.next(data)
    }

    setDeleteActivity(data) {
        this.deleteActivityData.next(data)
    }

    completeTask(activityId) {
        let url = this.getApiUrl(`sales/opportunityActivityTask/${activityId}`)
        return this.http.get(url)
    }

    completeScheduleActivity(data) {
        let url = this.getApiUrl(`sales/completeOpportunityActivity`)
        return this.http.post(url, data)
    }

    undoScheduleActivity(data) {
        let url = this.getApiUrl(`sales/undoOpportunityActivity`)
        return this.http.post(url, data)
    }

    deleteOpportunity(opportunityId) {
        let url = this.getApiUrl(`sales/deleteOpportunity`)
        return this.http.post(url, opportunityId)
    }

    cloneOpportunity(opportunityId) {
        let url = this.getApiUrl(`sales/cloneOpportunity/${opportunityId}`)
        return this.http.get(url)
    }

    getOpportunityReasons(type) {
        let url = this.getApiUrl(`getOpportunityReasons?type=${type}`)
        return this.http.get(url)
    }

    rejectProposal(opportunityId, rejectReasonId) {
        let url = this.getApiUrl(`sales/rejectProposal/${opportunityId}?rejectReasonId=${rejectReasonId}`)
        return this.http.get(url)
    }

    cancelProposal(opportunityId, cancelReasonId) {
        let url = this.getApiUrl(`sales/cancelProposal/${opportunityId}?cancelReasonId=${cancelReasonId}`)
        return this.http.get(url)
    }

    getProposalSectionData(id, fromCloseModal, opportunityId) {
        let param = fromCloseModal ? 'yes' : 'no';
        return this.http.get(this.getApiUrl(`sales/getProposalSectionData/${id}`) + '?fromCloseModal=' + param + '&opportunityId=' + opportunityId)
    }

    getFinancialBreakdown(id) {
        return this.http.get(this.getApiUrl(`sales/getFinancialBreakdown/${id}`))
    }

    getProposalPdf(id, type) {
        return this.http.get(this.getApiUrl(`getProposalTemplatePDF/${id}/${type}`))
    }

    getProposalPdfPath(id){
        return this.http.get(this.getApiUrl(`getProposalPdfPath/${id}`))
    }

    getAssetsList(propertyId, page, searchText) {
        return this.http.get(this.getApiUrl(`get_asset_widget_details?propertyId=${propertyId}&screenType=job&page=${page}&searchText=${searchText}`))
    }

    completeMeeting(activityId) {
        let url = this.getApiUrl(`sales/opportunityActivityMeeting/${activityId}`)
        return this.http.get(url)
    }
    getPartOptions(opportunityProposalId) {
        let url = this.getApiUrl(`sales/getPartOptions/${opportunityProposalId}`)
        return this.http.get(url)
    }
    getUpsellOptions(data) {
        return this.http.post(this.getApiUrl(`sales/getUpsellOptions`), data)
    }
    getContactList(customerId) {
        let url = this.getApiUrl(`customers/customer_list/${customerId}/contacts/view?page=1&limit=150`)
        return this.http.get(url)
    }
    getContactDetails(opportunityId) {
        let url = this.getApiUrl(`sales/getContactDetails/${opportunityId}`)
        return this.http.get(url)
    }
    acceptRejectOpportunity(data) {
        let url = this.getApiUrl(`sales/AcceptRejectOpportunity`)
        return this.http.post(url, data)
    }

    sendProposalEmailToCustomer(data, opportunityId) {
        let url = this.getApiUrl(`sales/sendProposalEmailToCustomer/${opportunityId}`)
        return this.http.post(url, data);
    }

    sendProposalSMSToCustomer(data, opportunityId) {
        let url = this.getApiUrl(`sales/sendProposalSMSToCustomer/${opportunityId}`)
        return this.http.post(url, data);
    }

    printProposalToCustomer(opportunityId) {
        let url = this.getApiUrl(`sales/printProposalToCustomer/${opportunityId}`)
        return this.http.get(url);
    }

    getCustomerTitles() {
        let url = this.getApiUrl(`listTitles?page=1&limit=1000`)
        return this.http.get(url)
    }

    setProposalStatus(status: boolean) {
        this.proposalCreated.next(status)
    }

    updateOpportunityStatus(opportunityId, stageId) {
        let url = this.getApiUrl(`sales/updateOpportunitiesStatus/${opportunityId}?stageId=${stageId}`)
        return this.http.get(url)
    }

    updateOpportunityExpireStatus(id) {
        let url = this.getApiUrl(`sales/updateOpportunityExpireStatus/${id}`)
        return this.http.get(url)
    }

    updateProposalVersion(id) {
        let url = this.getApiUrl(`sales/updateProposalVersion/${id}`)
        return this.http.get(url)
    }

    updateProposalPricingTaxOption(data) {
        let url = this.getApiUrl(`sales/updateProposalPricingTaxOption`)
        return this.http.post(url, data)
    }

    setFileData(data) {
        this.attachedFiles.next(data)
    }

    setReschedule(data) {
        this.opportunitySchedule.next(data)
    }

    setOpportunityNote(data) {
        this.opportunityNote.next(data)
    }

    setOpportunityStatus(data) {
        this.opportunityStatus.next(data)
    }

    getScheduledEmail(oppid, activityType) {
        let url = this.getApiUrl(`sales/checkTodayEmailActivity/${oppid}?activityType=${activityType}`);
        return this.http.get(url);
    }

    getAcceptProposalData(opportunityId: Number, proposalVersion) {
        let url = this.getApiUrl(`sales/getAcceptProposalData/${opportunityId}?proposalVersion=${proposalVersion}`);
        return this.http.get(url);
    }

    addOrEditAcceptProposal(data) {
        let url = this.getApiUrl(`sales/addOrEditAcceptProposal`)
        return this.http.post(url, data)
    }

    getOpportuntiyAssets(opportunityId: Number, customerId: Number) {
        let url = this.getApiUrl(`sales/getOpportunityAssets/${opportunityId}?propertyId=${customerId}`);
        return this.http.get(url);
    }

    getOpportunityInvoice(data) {
        return this.http.post(this.getApiUrl(`sales/getOpportunityInvoiceScheduleExists`), data)
    }

    getTodayScheduleActivity(opportunityId, type) {
        let url = this.getApiUrl(`sales/checkTodayActivity/${opportunityId}?type=${type}`);
        return this.http.get(url);
    }

    saveActivity(oppurtunityId) {
        let url = this.getApiUrl(`saveactivity/${oppurtunityId}`);
        return this.http.get(url);
    }

    getDefaultEmailFooter(){
        let url = this.getApiUrl(`get_default_email_footer`);
        return this.http.get(url);
    }

    getConfirmations(templateId, menuType: string, customerId=null, opportunityId=null): Observable<any> {
        return this.http.get(this.getApiUrl(`getOppurtunityConfirmations/${templateId}?type=${menuType}&customerId=${customerId}&opportunityId=${opportunityId}`));
    }

    setNextActivity(data) {
        this.nextActivity.next(data)
    }

    setOpportunityEmailModal(data) {
        this.opportunityEmailModal.next(data)
    }

    setActivities(data) {
        this.timelineActivities.next(data)
    }

    setEmailDraft(data){
        this.emailDraft.next(data)
    }

    setfilterPreferences(data){
        this.filterPreferences.next(data);
    }
    /**
     *
     * @param params
     * @returns {Observable<Object>}
     */
    getLivePriceFromAPI(params) : Observable<Object>{
        return this.http.get(this.getApiUrl(`get_integration_parts_prices`), {params : params});
    }

    getfinanceTemplateDetails(opportunityId: any, optionId: any,total: any) {
        return this.http.get(this.getApiUrl(`sales/financeTemplate/${opportunityId}?optionId=${optionId}&total=${total}`));
    }
    getProposalSummary(opportunityId){
        return this.http.get(this.getApiUrl(`sales/${opportunityId}/proposalSummary`));

    }
    getTributeData(opportunityId){
        return this.http.get(this.getApiUrl(`sales/${opportunityId}/getTributeData`));
    }
    updateContactDetails(opportunityId,contactData){
        return this.http.post(this.getApiUrl(`sales/${opportunityId}/updateOpportunityDetails`), contactData,);
    }
    getPartUpsell(selectedUpsellId:any){
        return this.http.get(this.getApiUrl(`sales/partUpsell?partUpSellId=${selectedUpsellId}`));
    }
    setRefreshTimelineList(data) {
        this.reloadTimelineData.next(data)
    }
    addAdvancedStylingTemplate(data) {
        let url = this.getApiUrl(`sales/addAdvancedStylingTemplate`)
        return this.http.post(url, data)
    }
    getAdvancedStylingTemplate() {
        let url = this.getApiUrl(`sales/getAdvancedStylingTemplate`);
        return this.http.get(url);
    }
    setinvoiceData(data){
        this.invoiceData.next(data)
    }

    updatePageNumbers(screen: string, primaryId: number, pages, proposalId) {
        let url = this.getApiUrl(`sales/update_page_numbers/${screen}/${primaryId}?opportunityProposalId=${proposalId}`)
        return this.http.post(url, {pages: pages})
    }

    //Todo: This need to be moved to relavant services
    getTimelineContacts(id: number,type: string,tablePKId: number,screen: string){
        let url;
        if(screen == 'customer'){
            url = this.getApiUrl(`${type}/new_phone_call/${id}/timeline?isCommonRoute=true`);
        }else if(screen=='jobs' || screen=='job'){
            url = this.getApiUrl(`customers/${type}/${id}/notes/jobs/${tablePKId}/new_phone_call/timeline?isCommonRoute=true`);
        } else if (screen === 'contract') {
            url = this.getApiUrl(`customers/${type}/${id}/notes/jobs/${tablePKId}/new_phone_call/timeline?isCommonRoute=true`);
        }
        return this.http.get(url);
    }

    private attachedFilesList = new BehaviorSubject('');
    $attachedFilesList = this.attachedFilesList.asObservable();

    setAttachedFilesList(data){
        this.attachedFilesList.next(data);
    }

    processEmail(data) {
        let url = this.getApiUrl(`process_email`);
        return this.http.post(url, data);
    }

    getSmsContacts(customerId){
        let url = this.getApiUrl(`customer/new_sms_message/${customerId}/timeline?isCommonRoute=true`);
        return this.http.get(url);
    }

    saveSelectedNavagiation(data) {
        let url = this.getApiUrl(`userColumnOrder`)
        return this.http.post(url, data);
    }

    completeScheduledActivity(data:object){
        let url = this.getApiUrl(`completeScheduledActivity`);
        return this.http.post(url,data);
    }
    deleteAcceptProposalData(opportunityId) {
        let url = this.getApiUrl(`sales/deleteAcceptProposalData/${opportunityId}`)
        return this.http.delete(url);
    }
    fetchAndPersistGoogleFontLinks(type='',data :any,) {
        let url = this.getApiUrl(`sales/storeGoogleFontLinks`);
        if (type == 'POST'){
            return this.http.post(url,data);
        }else{
            return this.http.get(url)
        }
    }

    deleteOpportunityProposal(opportunityId) {
        let url = this.getApiUrl(`sales/deleteOpportunityProposal/${opportunityId}`);
        return this.http.delete(url);
    }
    saveSupplierProductCode(data: object): Observable<any> {
        return this.http.post(this.getApiUrl(`parts_and_prices/supplier_prices/addOrEdit`),'supplierprice:' + JSON.stringify(data));
    }
    updatePartSupplierPrices(id, proposalId) {
        return this.http.get(this.getApiUrl(`sales/updatePartSupplierPrices/${id}?opportunityProposalId=${proposalId}`));
    }

    getOpportunityTagsList(params: object) {
        return this.http.get(this.getApiUrl('sales/get_labels', params));
    }
    getAssetData(assetId) {
        let url = this.getApiUrl(`sales/getAssets/${assetId}`)
        return this.http.get(url);
    }
    getCustomerFiles(propertyId) {
        let url = this.getApiUrl(`sales/getCustomerFiles/${propertyId}`)
        return this.http.get(url);
    }
    updateContactData(contactData){
        return this.http.post(this.getApiUrl(`sales/updateContactDetails`), contactData,);
    }

    getVehicleTimeline(vehicleId:number){
        return this.http.get(this.getFleetApiUrl(`/${vehicleId}/getPhoneCallContacts`));
    }

    createVehicleSMS(data) {
        let url = this.getFleetApiUrl(`addVehicleActivitySMS`)
        return this.http.post(url, data);
    }
}
