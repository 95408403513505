import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import * as jQuery from 'jquery';

declare var $;
@Injectable({
    providedIn: 'root'
})
export class MicroserviceHelperService {

    private readonly apiBase: string;
    private readonly v5ApiBase: string;
    private readonly token_storage_key: string;
    private readonly refresh_interval_storage_key: string;

    constructor() {
        this.token_storage_key = 'cmsmsapt';
        this.refresh_interval_storage_key = 'cmsmsapt:interval';
        this.apiBase = environment.apiHost;
        this.v5ApiBase = this.apiBase + '/v5/';
        this.ajaxInterceptor();
    }

    private ajaxInterceptor() {
        const that = this;
        $.ajaxPrefilter(function (options) {
            if(that.isInterceptableRequest(options.url)) {
                if(!options.hasOwnProperty('headers')) {
                    options['headers'] = {};
                }
                options['headers']['v5Token'] = that.getToken();
                options['headers']['X-CS-INTERCEPTOR'] = 'YES';
            }
        });
    }

    public isReplaceableEndpointUrl(requestEndpointUrl: string): boolean {
        return (
            (requestEndpointUrl.indexOf('@contract-v5') !== -1)
            || (requestEndpointUrl.indexOf('@reporting-v5') !== -1)
        );
    }

    public getReplaceableEndpointReplacementUrl(requestEndpointUrl: string): string {

        if (!(requestEndpointUrl === null
            || requestEndpointUrl === undefined
            || (typeof requestEndpointUrl === 'string' && requestEndpointUrl.trim().length === 0)
        )) {
            if (requestEndpointUrl.indexOf('@contract-v5') !== -1) {
                const v5ApiContractBase = this.v5ApiBase + 'contract';
                requestEndpointUrl = requestEndpointUrl.replace('@contract-v5', v5ApiContractBase);
            } else if (requestEndpointUrl.indexOf('@reporting-v5') !== -1) {
                const v5ApiReportingBase = this.v5ApiBase + 'reporting';
                requestEndpointUrl = requestEndpointUrl.replace('@reporting-v5', v5ApiReportingBase);
            }
        }
        return requestEndpointUrl;
    }

    public isInterceptableRequest(requestUrl: string): boolean {
        return (requestUrl.indexOf(this.v5ApiBase) !== -1);
    }

    public clearToken() {
        this.clearTokenInterval();
        localStorage.removeItem(this.token_storage_key);
        // window.sessionStorage.clear();
    }

    public setToken(token: string) {
        localStorage.removeItem(this.token_storage_key);
        if (typeof token === 'string' && token.trim().length > 0) {
            localStorage.setItem(this.token_storage_key, token);
        }
    }

    public getToken(): string | null {
        return localStorage.getItem(this.token_storage_key);
    }

    public setTokenInterval(interval: string) {
        localStorage.setItem(this.refresh_interval_storage_key, String(interval));
    }

    public getTokenInterval(): number | null {
        const value = localStorage.getItem(this.refresh_interval_storage_key);
        return value ? parseInt(value) : null;
    }

    public clearTokenInterval() {
        localStorage.removeItem(this.refresh_interval_storage_key);
        // window.sessionStorage.clear();
    }

    public isTokenExist(): boolean {
        const token = this.getToken();
        return !(token === null
            || token === undefined
            || (typeof token === 'string' && token.trim().length === 0)
        );
    }

    public getTokenHeaders() {
        const headers = [];
        const token = this.getToken();

        // Content Type
        /*headers.push({
            name: 'Content-type',
            value: 'application/json'
        });*/

        // v5Token
        headers.push({
            name: 'v5Token',
            value: `${token}`
        });

        // Authorization
        // headers.push({
        //     name: 'Authorization',
        //     value: `Bearer ${token}`
        // });

        // Custom Header
        headers.push({
            name: 'X-CS-INTERCEPTOR',
            value: 'YES'
        });

        return headers;
    }

}
