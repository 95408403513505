import { ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { mxgraphFactory } from "mxgraph-factory";
import { CsFullScreenModalComponent } from "@app/directives";
import { MxGraphService } from "../services/mx-graph.service";
var mxGraph = mxgraphFactory({
    mxLoadResources: false,
    mxLoadStylesheets: true,
}).mxGraph;
var DiagramHistoryComponent = /** @class */ (function () {
    function DiagramHistoryComponent(diagramService) {
        this.diagramService = diagramService;
        this.activeMenu = 'history';
        this.tags = [];
        this.userRoles = [];
        this.isMarketPlaceView = false;
        this.viewMode = false;
        this.usersList = [];
        this.scheduleActivityTypes = [];
        this.workflowType = 'Custom';
        this.whitelist = {};
        this.singleDagHistory = false;
        this.sourceXml = null;
        this.activeDagRunTasksItems = {};
        this.isEmpty = true;
    }
    DiagramHistoryComponent.prototype.ngOnInit = function () {
    };
    DiagramHistoryComponent.prototype.ngAfterViewInit = function () {
        this.setContainerHeight();
    };
    DiagramHistoryComponent.prototype.setActiveDagRun = function (item) {
        var _this = this;
        this.diagramService.destroyMxGraph(function () {
            _this.isEmpty = false;
            _this.activeDagRunTasksItems = item;
            _this.diagramService.initialize(_this.container, {
                xmlSource: !item['xmlsource'] ? _this.data.xmlSource : item['xmlsource'],
                isScheduler: _this.data.scheduler,
                outlineContainer: _this.outlineContainer,
                mainObjectsFields: _this.mainObjectsFields,
                viewMode: _this.viewMode,
                isMarketPlaceView: _this.isMarketPlaceView,
                tags: _this.tags,
                userRoles: _this.userRoles,
                scheduleActivityTypes: _this.scheduleActivityTypes,
                usersList: _this.usersList,
                workflowType: _this.workflowType,
                doAutoHeightAdjustment: true
            });
            _this.mxGraph = _this.diagramService.getGraph();
            _this.diagramService.render(item.dagSource['blockObjects']);
            setTimeout(function () {
                _this.diagramService.setHightlightCells(item);
            }, 10);
        });
    };
    DiagramHistoryComponent.prototype.setContainerHeight = function () {
        var _this = this;
        setTimeout(function () {
            var height = window.innerHeight - (_this.container.nativeElement.offsetTop + 10);
            _this.container.nativeElement.style.minHeight = height + 'px';
        }, 100);
    };
    return DiagramHistoryComponent;
}());
export { DiagramHistoryComponent };
