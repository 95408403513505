import { OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap";
import { Subject } from "rxjs/index";
import { FleetLiveMapService } from "@app/features/fleet-management/fleet-live-map/fleet-live-map.service";
var FleetLiveMapSidepanelComponent = /** @class */ (function () {
    function FleetLiveMapSidepanelComponent(bsModalRef, httpService) {
        this.bsModalRef = bsModalRef;
        this.httpService = httpService;
        this.title = '';
        this.selectedIcon = '';
        this.locationName = '';
        this.uploadedIconsListUrls = [];
        this.addressLine = '';
    }
    FleetLiveMapSidepanelComponent.prototype.ngOnInit = function () {
        this.onClose = new Subject();
    };
    FleetLiveMapSidepanelComponent.prototype.setSelectedIcon = function (iconData) {
        var _this = this;
        this.selectedIconType = null;
        setTimeout(function () {
            if (iconData.category === 'FA') {
                _this.selectedIconType = iconData.category;
                _this.selectedIcon = iconData.icon.className;
                _this.iconName = iconData.icon.label;
            }
        }, 200);
    };
    FleetLiveMapSidepanelComponent.prototype.removeIcon = function () {
        this.selectedIcon = '';
        this.iconName = '';
        // this.formData.controls['viewIcon'].setValue('');
    };
    FleetLiveMapSidepanelComponent.prototype.stopEventPropagation = function (eve) {
        eve.stopPropagation();
    };
    FleetLiveMapSidepanelComponent.prototype.save = function () {
        var _this = this;
        Object.values(this.addressDetails['address']).forEach(function (el) {
            if (!el.includes(['city', 'country', 'postcode', 'country_code'])) {
                _this.addressLine = (_this.addressLine != '' ? _this.addressLine + ',' : '') + el;
            }
        });
        var data = {
            latitude: this.addressDetails['lat'],
            longitude: this.addressDetails['lon'],
            name: this.locationName,
            addressLine1: this.addressLine,
            town: this.addressDetails['address']['city'],
            country: this.addressDetails['address']['country'],
            postcode: this.addressDetails['address']['postcode'],
            locationIcon: this.selectedIcon
        };
        this.httpService.saveCurrentSelectedLocation(data).subscribe(function (data) {
            if (data) {
                _this.closePanel();
            }
        });
    };
    FleetLiveMapSidepanelComponent.prototype.closePanel = function () {
        this.bsModalRef.hide();
        this.onClose.next('close');
    };
    return FleetLiveMapSidepanelComponent;
}());
export { FleetLiveMapSidepanelComponent };
