export interface SetSalesPersonBlockModelInterface{
    assignTo: string ;
    displayValue: string;
}
export class SetSalesPersonBlockModel implements SetSalesPersonBlockModelInterface{
    assignTo: string = '';
    displayValue: string = '';

    constructor(data:{} = undefined) {
        if(data){
            this.assignTo = data['assignTo'] ? data['assignTo'] : '';
            this.displayValue = data['displayValue'] ? data['displayValue'] : '';
        }
    }

}
