import {Injectable, Inject, forwardRef} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "@app/core/auth/auth.service";
import { HybridFactory } from '@app/core/hybrid-factory';
import {CanDeactivateGuard} from "@app/services";
import {SettingsService} from "@app/core";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthService,
        @Inject(forwardRef(() => SettingsService)) private settingsService
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {

        const url = state.url;
        const isJobsPipelines = url.includes('jobs_pipelines');
        const isSalesPipelines = url.includes('set_up_pipelines');

        const clonedData = { ...next.data };

        // Update featurePermissions based on the URL
        if (clonedData.hasOwnProperty('featurePermissions') && clonedData['featurePermissions'] instanceof Array) {
            if (isJobsPipelines) {
                clonedData['featurePermissions'] = ['SetupJobPipelines.readaccess'];
            } else if (isSalesPipelines) {
                clonedData['featurePermissions'] = ['SetupPipelines.readaccess'];
            }
        }

        // Checks is user authenticated
        if (!this.authService.authenticated()) {
            HybridFactory.navigate('/logout')
            return false;
        }

        // Checks user's feature access permissions
        if (clonedData.hasOwnProperty('featurePermissions') && clonedData['featurePermissions'] instanceof Array) {
            let validationResult: string[][] | true = this.authService.permitted(clonedData['featurePermissions']);
            if (validationResult !== true) {

                HybridFactory.navigate('/logout', validationResult.length ? { errorMessage: `You don't have permission to ${validationResult[0][1].replace('access', '')} ${validationResult[0][0]}` } : {})
                return false;
            };
        }

        // Check if its a AngularJS view but its come here from a bookmark
        // if ('clientsconfig' in this.settingsService.getAllPermissions()['accessDetails'] &&
        //     'NewPropertyView' in this.settingsService.getAllPermissions()['accessDetails'].clientsconfig
        // ) {
        //     let matchedCustomerRegexp = new RegExp('^/customers/(customer|customer_list|work_address)/([0-9]+)/view_v2');
        //     let matches = state.url.match(matchedCustomerRegexp);
        //     if(matches){
        //         HybridFactory.navigate('/customers/' + matches[1] + '/' + matches[2] + '/view_v2/property/view');
        //         event.preventDefault();
        //         return;
        //     }
        //
        // }

        return true;
    }

}
