import * as tslib_1 from "tslib";
import { HttpClient } from "@angular/common/http";
import { InterfaceService } from "@app/interfaces";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FleetLiveMapService = /** @class */ (function (_super) {
    tslib_1.__extends(FleetLiveMapService, _super);
    function FleetLiveMapService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    FleetLiveMapService.prototype.saveCurrentSelectedLocation = function (data) {
        return this.http.post(this.getFleetApiUrl("addSavedLocations"), data);
    };
    FleetLiveMapService.ngInjectableDef = i0.defineInjectable({ factory: function FleetLiveMapService_Factory() { return new FleetLiveMapService(i0.inject(i1.HttpClient)); }, token: FleetLiveMapService, providedIn: "root" });
    return FleetLiveMapService;
}(InterfaceService));
export { FleetLiveMapService };
