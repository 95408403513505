import {Component, Input, OnInit} from '@angular/core';
import {CommonDataSource} from "@app/workflow-common/services/CommonDataSource";

@Component({
  selector: 'app-template-view-layout',
  templateUrl: './template-view-layout.component.html',
  styleUrls: ['./template-view-layout.component.scss']
})
export class TemplateViewLayoutComponent implements OnInit {

    @Input() emptyMessage:string = 'You must select a template before the preview can be displayed';
    @Input() message:string = '';
    @Input() subject:string = '';
    @Input() type: string = 'email'
    @Input() showType:boolean = false;

    showEmptyMessage:boolean = true;
    select2Options: any = {
        minimumResultsForSearch: -1
    };
    loadingMessage:boolean = true;
    previewModel: string = 'email';
    currentViewPort: string | 'desktop'|'mobile' = 'desktop';

    viewOptions:{email, sms, letter} = {
        email: [{id:'email', text: 'Email'}],
        sms: [{id:'sms', text: 'SMS'}],
        letter: [{id:'letter', text: 'Letter'}],
    };
    dropDownItems: any[] = [];
    randomMobileNumber: number = 0;
    clientDetails:{} = undefined;

    constructor() { }

    async ngOnInit() {
        this.showEmptyMessage = !this.message;
        if (this.type) {
            this.previewModel = this.type;
        }
        this.randomMobileNumber = this.generatePhoneNumber();
        this.dropDownItems = this.viewOptions[this.type];
        if(this.type == 'sms') {
            this.currentViewPort = 'mobile';
        }
        await CommonDataSource.getClientDetails()
        .then((client:{}) => {
            this.clientDetails = client;
        });
        this.loadingMessage=false;

    }

    onChangePreviewOption(value) {
        if(value == 'sms' || value == 'letter')
            this.currentViewPort = 'mobile';
        else if(value == 'email')
            this.currentViewPort = 'desktop';
        this.reloadMessage();
    }

    changeViewPort(view: string) {
        if(view === this.currentViewPort)
            return;
        this.currentViewPort = view;
        this.reloadMessage();
    }

    reloadMessage() {
        this.loadingMessage=true;
        setTimeout(() => {
            this.loadingMessage=false;
        }, 600);
    }

    generatePhoneNumber() {
        return Math.floor(Math.random() * 9000000000) + 1000000000;
    }

}
