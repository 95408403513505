import { Subject } from "rxjs";
var EmailListenerService = /** @class */ (function () {
    function EmailListenerService() {
        this._beforeSend$ = new Subject();
        this._afterend$ = new Subject();
        this._send$ = new Subject();
        this._closeModal$ = new Subject();
    }
    Object.defineProperty(EmailListenerService.prototype, "beforeSend$", {
        get: function () {
            return this._beforeSend$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmailListenerService.prototype, "afterSend$", {
        get: function () {
            return this._afterend$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmailListenerService.prototype, "send$", {
        get: function () {
            return this._send$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmailListenerService.prototype, "closeModal$", {
        get: function () {
            return this._closeModal$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    EmailListenerService.prototype.send = function (data) {
        if (data === void 0) { data = null; }
        this._send$.next(data);
    };
    EmailListenerService.prototype.afterSend = function (data) {
        if (data === void 0) { data = null; }
        this._afterend$.next(data);
    };
    EmailListenerService.prototype.beforeSend = function (data) {
        this._beforeSend$.next(data);
    };
    EmailListenerService.prototype.closeModal = function (data) {
        this._closeModal$.next(data);
    };
    return EmailListenerService;
}());
export { EmailListenerService };
