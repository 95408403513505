import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { InterfaceService } from '@app/interfaces';
import { Observable, Subscription } from 'rxjs';

@Injectable()
export class IntegrationsService extends InterfaceService {

    loadingStepEmitter = new EventEmitter();
    subsVar: Subscription;
    continueButtonEmitter = new EventEmitter();
    deleteIntegration = new EventEmitter();
    hideSidePanel = new EventEmitter();
    clearSearchBoxEmitter = new EventEmitter();
    continueButtonStatusEmitter = new EventEmitter();
    SFG20connectionMethodEvent = new EventEmitter();


    constructor(private http: HttpClient) {
        super();
    }

    hideSidePanelFunction() {
        this.hideSidePanel.emit(true)
    }

    loadingStep(data: boolean) {
        this.loadingStepEmitter.emit(data)
    }

    sendContinueButtonAction(data: any) {

        this.continueButtonEmitter.emit(data)
    }

    continueButtonStatus(status: boolean) {
        this.continueButtonStatusEmitter.emit(status)
    }

    clearMainSearchBox() {

        this.clearSearchBoxEmitter.emit(true)
    }

    callDeleteIntegration(name: Object) {
        this.deleteIntegration.emit(name)
    }

    sendSFG20ConnectionMethod(name: string) {
        this.SFG20connectionMethodEvent.emit(name)
    }

    id: string;
    connectCalendar(params?: any): Observable<any> {
        return this.http
            .post(this.getApiUrl(`oauthCalendar`), { calendar: params });
    }

    setupCalendar(params?: any): Observable<any> {

        let body = new URLSearchParams();
        body.set('code', params.code);
        body.set('state', params.state);
        body.set('session_state', params.session_state);
        body.set('calendarType', params.calendarType);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl(`setupCalendarIntegation`), body.toString(), options)
    }

    getCalendarAccountDetails(): Observable<any> {
        return this.http
            .get(this.getApiUrl(`getCalendarAccountDetails`));
    }

    checkCalendarAccountExist(params?: Object): Observable<any> {
        return this.http
            .get(this.getApiUrl(`checkCalendarAccountExist`, params));
    }

    disconnectCalendar(params?: string): Observable<any> {

        let body = new URLSearchParams();
        body.set('calendar', params);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('checkCalendarAccountExist'), body.toString(), options);
    }

    generateZapierToken(params?: string) {
        let body = new URLSearchParams();
        body.set('application', params);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('integrations/createAuthToken'), body.toString(), options);
    }

    getZappierToken(params?: string) {
        let body = new URLSearchParams();
        body.set('application', params);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('integrations/getAuthToken'), body.toString(), options);
    }

    getAllIntegrationsdata(screen?:string): Observable<any> {
        if( screen == "Settings" ) {
	        return this.http
            .get(this.getApiUrl(`getAllSettingsDetails?screen=${screen}`));
        } else {
 	 return this.http
            .get(this.getApiUrl(`getAllIntegrationsDetails?screen=${screen}`));
        }
    }

    getZapierAccountDetails(params: string): Observable<any> {
        let body = new URLSearchParams();
        body.set('application', params);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('integrations/getZapierAccountDetails'), body.toString(), options);
    }

    disconnectZapier(params: string): Observable<any> {
        let body = new URLSearchParams();
        // console.log("params is :" + params);
        body.set('status', params);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('integrations/disconnectZapierIntegration'), body.toString(), options);
    }

    checkWolseleySetupExists(params: any): Observable<any> {
        let body = new URLSearchParams();
        // console.log("params is :" + params);
        // body.set('accountNumber', params);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .get(this.getApiUrl(`checkWolseleySetupExist/${params.accountNumber}?type=${params.integrationName}`), options);
    }

    getOpportunityrelatedinfo(): Observable<any> {
        let body = new URLSearchParams();
        
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .get(this.getApiUrl(`getOpportunityInfo`), options);
    }


    getlist_templates(): Observable<any> {
        let body = new URLSearchParams();
        body.set('parentId', '');
        body.set('search', '');
        body.set('screen', 'opportunity_template');
        body.set('type', '');

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
        .post(this.getApiUrl('list_templates'), body.toString(), options);
    }

    
    searchResults(params?: string): Observable<any> {
        let body = new URLSearchParams();
        // console.log("params is :" + params);
        body.set('page', '');
        body.set('limit', '');
        body.set('pageTab', '');
        body.set('q', params);
        body.set('type', 'suppliers');

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('searchResults'), body.toString(), options);
    }

    getSupplierBranches(params: string, thirdparty: string): Observable<any> {
        // console.log("params is :" + params);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .get(this.getApiUrl(`getSupplierBranches/${params}?page=1&limit=5&thirdparty=` + thirdparty + `&searchText=`), options);
    }

    setupWolseleyAccount(params: any, branchesData: any): Observable<any> {
        let body = new URLSearchParams();
        // console.log("params is :" + params);
        body.set('wolseley[thirdpartyname]', params.integrationName);
        body.set('wolseley[supplierId]', params.id);
        body.set('wolseley[accountNo]', params.accountNumber);
        body.set('wolseley[userName]', params.userName);
        body.set('wolseley[pwd]', params.pwd);
        body.set('wolseley[postcode]', params.postcode);
        body.set('wolseley[telephone]', params.telephone);

        for (let branch in branchesData) {
            body.set(`branchesData[${branch}]`, branchesData[branch]);

        }

        console.log(body.toString());

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('setupWolseleyAccount'), body.toString(), options);
    }

    SetupSpruceAccount(params: any): Observable<any> {
        let body = new URLSearchParams();
        body.set('spruce[opportunityTemplate]', params.opportunityTemplate);
        body.set('spruce[reportingLine]', params.reportingLine);
        body.set('spruce[salesPersons]', params.salesPersons);
        body.set('spruce[advertisingtype]', params.advertisingtype);
        body.set('spruce[pipeline]', params.pipeline);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('setupSpruceAccount'), body.toString(), options);
    }

 
    getSaleIntegrationsAccountData(params?: Object): Observable<any> {
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .get(this.getApiUrl(`getSaleIntegrationsAccountData`), options);
    }

    updateSpruceAccount(params: any): Observable<any> {
        let body = new URLSearchParams();
        body.set('spruce[opportunityTemplate]', params.opportunityTemplate);
        body.set('spruce[reportingLine]', params.reportingLine);
        body.set('spruce[salesPersons]', params.salesPersons);
        body.set('spruce[advertisingtype]', params.advertisingtype);
        body.set('spruce[pipeline]', params.pipeline);
        body.set('spruce[accountId]', params.id);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('updateSpruceAccount'), body.toString(), options);
    }

    

    getWolseleyBranches(params?: string, thirdparty?: string): Observable<any> {
        // console.log("params is :" + params);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .get(this.getApiUrl(`getWolseleyBranches?thirdparty=${thirdparty}&searchText=&supplierId=${params}`), options);
    }

    getWolseleyAccountData(params?: string): Observable<any> {
        // console.log("params is :" + params);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .get(this.getApiUrl(`getWolseleyAccountData?page=1&limit=5&searchText=`), options);
    }

    updateWolseleyDetails(params: any, accountNumber: string, otherParams: any): Observable<any> {
        let body = new URLSearchParams();
        // console.log("params is :" + params);
        body.set('wolseley[thirdpartyname]', params['thirdpartyName']);
        body.set('wolseley[supplierId]', params['supplierId']);
        body.set('wolseley[status]', params['status']);
        body.set('wolseley[id]', params['id']);
        body.set('wolseley[accountNo]', accountNumber);
        //alert(JSON.stringify(otherParams));
        if (otherParams) {
            body.set('wolseley[userName]', otherParams['userName']);
            body.set('wolseley[pwd]', otherParams['pwd']);
            body.set('wolseley[postcode]', otherParams['postcode']);
            body.set('wolseley[telephone]', otherParams['telephone']);
        }

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('updateWolseleyDetails'), body.toString(), options);
    }

    deleteWolseleyAccount(params?: string): Observable<any> {
        let body = new URLSearchParams();
        // console.log("params is :" + params);
        body.set('id', params);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('deleteWolseleyAccount'), body.toString(), options);
    }

    setSupplierId(id) {
        this.id = id;
        console.log('in set method: id is ' + this.id)
    }

    getSupplierId() {
        console.log('in get method: id is ' + this.id)
        return this.id;
    }

    searchJdpResults(params?: string): Observable<any> {
        let body = new URLSearchParams();
        // console.log("params is :" + params);
        body.set('q', 'properties:' + params);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('searchJdpResults'), body.toString(), options);
    }

    checkSetupExists(params?: string): Observable<any> {
        // console.log("params is :" + params);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .get(this.getApiUrl(`checkSetupExist/${params}?type=jdp`), options);
    }

    setupRRS(params?: any): Observable<any> {
        let body = new URLSearchParams();
        // console.log("params is :" + params);
        body.set('jdp[rrsname]', params.rrsname);
        body.set('jdp[apitoken]', params.apiToken);
        body.set('jdp[property]', params.propertyId);
        body.set('jdp[port]', params.port);
        body.set('jdp[sftp]', params.sftp);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('setupRRS'), body.toString(), options);
    }

    getRRSData(params?: string): Observable<any> {
        // console.log("params is :" + params);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .get(this.getApiUrl(`getRRSData?page1&limit=5`), options);
    }

    deleteRRS(params?: any): Observable<any> {
        let body = new URLSearchParams();
        // console.log("params is :" + params);

        body.set('jdp[rrsId]', params.rrsId);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('deleteRRS'), body.toString(), options);
    }

    connectPipedrive(params?: any): Observable<any> {
        return this.http
            .get(this.getApiUrl(`getPipedriveConfig?newUI=1`));
    }

    connectHubspot(params?: any): Observable<any> {
        return this.http
            .get(this.getApiUrl(`getHubspotConfig?newUI=1`));
    }

    getFields(type: string, accountId: string): Observable<any> {
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        return this.http.get(this.getApiUrl(`getFieldMapping?type=` + type + `&crmAccId=` + accountId + `&page=1` + `&limit=5&searchText=`, options));
        //.get(this.getApiUrl(`getFieldMapping?type=pipedrive&crmAccId=`+params.accountId+`&page=1`+`&limit=5&searchText=`+ encodeURIComponent(params.search)), options);
    }

    checkCRMAccountExist(params?: Object): Observable<any> {
        return this.http
            .get(this.getApiUrl(`getCRMAccountDetails`));
    }
    cancelIntegration(params?: any): Observable<any> {
        let body = new URLSearchParams();
        // console.log("params is :" + params);
        body.set('confirmText', 'CANCEL' + undefined);
        body.set('moduleValue[moduleId]', undefined);
        body.set('moduleValue[jobtodos]', undefined);
        body.set('UI', 'newUI')

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('cancel_integration?type=MailChimp'), body.toString(), options);
    }
    mailchimpConnect(params?: any): Observable<any> {
        let body = new URLSearchParams();
        // console.log("params is :" + params);
        body.set('APIkey', params);
        body.set('UI', 'newUI')

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('mailchimp_connect'), body.toString(), options);
    }

    checkMailChimpAccountExists(params?: string): Observable<any> {
        // console.log("params is :" + params);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .get(this.getApiUrl(`integrations/checkMailChimpAccountExists`), options);
    }

    generateWebhookUrl(params?: any): Observable<any> {
        let body = new URLSearchParams();
        // console.log("params is :" + params);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('naturalform/createwebhooks'), body.toString(), options);
    }

    getWebHooks(params?: any): Observable<any> {
        let body = new URLSearchParams();
        // console.log("params is :" + params);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('naturalform/webhooks'), body.toString(), options);
    }

    changeCalendarStatus(params: any, type: string): Observable<any> {
        let body = new URLSearchParams();
        // console.log("params is :" + params);

        body.set('status', params);
        body.set('type', type)

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('changeCalendarStatus'), body.toString(), options);
    }

    getCalendarUsers(type: string, search: string): Observable<any> {

        return this.http
            .get(this.getApiUrl('getCalendarAccountLists?type=' + type + "&search=" + search));
    }

    setupPipedriveAccount(details: any, accountId: string) {
        let body = new URLSearchParams();
        // console.log("params is :" + JSON.stringify(details));

        body.set('preferencesDetails', JSON.stringify(details));
        body.set('accountId', accountId);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        //alert(this.getApiUrl('setupPipedriveAccount'));
        return this.http.post(this.getApiUrl('setupPipedriveAccount'), body.toString(), options);
    }

    setupHubspotAccount(details: any, accountId: string) {
        let body = new URLSearchParams();
        // console.log("params is :" + JSON.stringify(details));

        body.set('preferencesDetails', JSON.stringify(details));
        body.set('accountId', accountId);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        //alert(this.getApiUrl('setupPipedriveAccount'));
        return this.http.post(this.getApiUrl('setupHubspotAccount'), body.toString(), options);
    }

    saveFieldMapping(accountId: string, details: any, rowToDelete: any, showManage: string) {
        let body = new URLSearchParams();
        // console.log("params is :" + JSON.stringify(details));

        body.set('fieldsData', JSON.stringify(details));
        body.set('crmAccountId', accountId);
        body.set('rowToDelete', JSON.stringify(rowToDelete));
        body.set('isManage', showManage);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        //alert(this.getApiUrl('setupPipedriveAccount'));
        return this.http.post(this.getApiUrl('saveFieldsMapping'), body.toString(), options);
    }

    deleteCRMAccount(type: string): Observable<any> {
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        if (type == 'pipedrive') {
            return this.http.post(this.getApiUrl('deletePipedriveAccount'), '', options);
        } else {
            return this.http.post(this.getApiUrl('deleteHubspotAccount'), '', options);
        }
    }

    addNaturalformTemplate(templateName: string, templateId: string) {
        let body = new URLSearchParams();
        //// console.log("params is :" + JSON.stringify(details));

        body.set('customcertificate[description]', templateId);
        body.set('customcertificate[name]', templateName);
        body.set('customcertificate[type]', 'Naturalforms');

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        //alert(this.getApiUrl('setupPipedriveAccount'));
        return this.http.post(this.getApiUrl('naturalform/addtemplates'), body.toString(), options);

    }

    getNaturalFormTemplate(param?: object): Observable<any> {

        return this.http
            .get(this.getApiUrl('naturalform/gettemplates', param));
    }

    editNaturalformTemplate(id: string, templateName: string, templateId: string) {
        let body = new URLSearchParams();
        //// console.log("params is :" + JSON.stringify(details));

        body.set('customcertificate[description]', templateId);
        body.set('customcertificate[name]', templateName);
        body.set('customcertificate[id]', id);
        body.set('customcertificate[type]', 'Naturalforms');
        body.set('customcertificate[ui]', 'new');

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        //alert(this.getApiUrl('setupPipedriveAccount'));
        return this.http.put(this.getApiUrl('naturalform/edittemplates'), body.toString(), options);

    }

    deleteNaturalFormTemplate(id: string) {

        let body = new URLSearchParams();
        //// console.log("params is :" + JSON.stringify(details));

        body.set('customcertificate[id]', id);
        body.set('customcertificate[ui]', 'new');

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        //alert(this.getApiUrl('setupPipedriveAccount'));
        return this.http.post(this.getApiUrl('naturalform/deletetemplates'), body.toString(), options);
    }

    disconnectNaturalForm() {
        let body = new URLSearchParams();
        body.set('action', 'disconnect');
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http.post(this.getApiUrl('naturalform/disconnect'), body.toString(), options);
    }
    searchIntegration(param?: string, screen?: string): Observable<any> {
        if( screen == 'Settings' ) {
           return this.http
		   .get(this.getApiUrl('getAllSettingsDetails', { searchText: param }));
        } else {
	        return this.http
		   .get(this.getApiUrl('getAllIntegrationsDetails', { searchText: param }));
        }
    }

    getCreditCard(): Observable<any> {
        return this.http
            .get(this.getApiUrl('credit_card_details', { newUI: 1 }));
    }

    callStripeLocations(): Observable<any> {
        return this.http
            .get(this.getApiUrl('get_stripe_locations', { newUI: 1 }));
    }

    getCreditCardDetails() {

        return this.http
            .get<any>(this.getApiUrl('credit_card_details',{ newUI: 1 })).toPromise()
    }

    getPaymentMethod(): Observable<any> {
        return this.http
            .get(this.getApiUrl('listPaymentMethods', { newUI: 1 }));
    }

    updateCreditCardPaymentMethod(paymentMethod: any) {
        let body = new URLSearchParams();
        
        body.set('paymentMethod', JSON.stringify(paymentMethod));
        
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http.put(this.getApiUrl('add_creditcard_paymentmethods'), body.toString(), options);

    }

    updateNominalCodeStripeMethod(nominalcodes: any) {
        let body = new URLSearchParams();
 
            body.set('nominalcodes', JSON.stringify(nominalcodes));
        
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http.put(this.getApiUrl('add_nominal_code_stripe'), body.toString(), options);

    }

    updateMotoPaymentStripeMethod(motopaymentMethod) {
        let body = new URLSearchParams();
 
            body.set('motopaymentMethod', JSON.stringify(motopaymentMethod));
        
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http.put(this.getApiUrl('add_motopayment_stripe'), body.toString(), options);

    }
    
    getalreadySelectedPaymentMethod(paymentMethodId:any): Observable<any> {
        if(paymentMethodId){
        return this.http
            .get(this.getApiUrl('checkPaymentMethod', { newUI: 1 ,paymentMethodID:paymentMethodId}));
        }
    }

    deleteCreditCardIntegration(type: string): Observable<any> {
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        let body = new URLSearchParams();

        body.set('confirmText', 'CANCEL');

        return this.http.post(this.getApiUrl('cancel_credit_card?mode=' + type + "&newUI=1"), body.toString(), options);

    }

    connectWorldpay(clientKey: string, serviceKey: string) {
        let body = new URLSearchParams();

        body.set('clientKey', clientKey);
        body.set('serviceKey', serviceKey);
        body.set('newUI', "1");

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http.post(this.getApiUrl('worldpay_connect'), body.toString(), options);

    }

    connectBasys(apiKey: string, validate: string) {

        let body = new URLSearchParams();

        body.set('apiKey', apiKey);
        body.set('action', validate);
        body.set('newUI', "1");

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http.post(this.getApiUrl('basys_connect'), body.toString(), options);

    }

    disconnectJDPAccount(params?: string): Observable<any> {
        let body = new URLSearchParams();
        body.set('rrsId', params);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('deleteRRS'), body.toString(), options);
    }

    updateRRS(params?: any): Observable<any> {
        let body = new URLSearchParams();

        body.set('jdp[rrsname]', params.rrsname);
        body.set('jdp[apitoken]', params.apiToken);
        body.set('jdp[property]', params.propertyId);
        body.set('jdp[rrsId]', params.rrsId);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl('updateRRS'), body.toString(), options);
    }

    savePreferences(params?): Observable<any> {

        let body = new URLSearchParams();

        body.set('value', params.value);
        body.set('key', params.key);
        body.set('secret', params.secret);

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .post(this.getApiUrl(`saveSFG20Method`), body.toString(), options);
    }

    addTemplate(id?: string, sync: boolean = false): Observable<any> {

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .get(this.getApiUrl(`addTemplate/${id}?sync=${sync}`), options);
    }

    getTemplates(query?: string): Observable<any> {

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        query = !query ? '' : query
        return this.http
            .get(this.getApiUrl(`system_settings/list-ppm?searchText=${query}&type=SFG20`), options);
    }

    getSFG20Status(): Observable<any> {

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .get(this.getApiUrl(`getStatus`), options);
    }

    isPPMDelete(id: number): Observable<any> {

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .get(this.getApiUrl(`ppm/${id}/is-ppm-delete`), options);
    }

    deletePPM(id: number): Observable<any> {

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .delete(this.getApiUrl(`ppm/${id}/delete`), options);
    }

    syncAllPPM(): Observable<any> {

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .get(this.getApiUrl(`syncAllPPM`), options);
    }

    processFiles(fileNames: string[]): Observable<any> {

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        let body = new URLSearchParams();

        let parsedFiles = JSON.stringify(fileNames)

        body.set('fileNames', parsedFiles);

        return this.http
            .post(this.getApiUrl(`processXMLFiles`), body.toString(), options);
    }

    disconnectSFG20integration(): Observable<any> {

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .get(this.getApiUrl(`disconnectSFG20Integration`), options);
    }
    listSFG20Files(): Observable<any> {

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .get(this.getApiUrl(`listFiles`), options);
    }

    deleteSFG20XMLFile(id: number): Observable<any> {

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .get(this.getApiUrl(`deleteXMLFile/${id}`), options);
    }

    getSchedulesData(): Observable<any> {

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http
            .get(this.getApiUrl(`getScheduleData`), options);
    }

    sendFeedbackToSlackChannel(feedbackData: object) {
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        let body = new URLSearchParams();

        body.set('feedback', feedbackData['feedback']);
        body.set('uiScreen', feedbackData['uiScreen']);

        return this.http
            .post(this.getApiUrl(`sendFeedbackData`), body.toString(), options);
    }

    deleteDirectDebitIntegration(type: string): Observable<any> {
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        let body = new URLSearchParams();

        body.set('confirmText', 'CANCEL');

        return this.http.post(this.getApiUrl('gocardless_disconnect?mode=' + type + "&newUI=1"), body.toString(), options);

    }
    
    updateGocardless(creditor: any, type:string) {
        
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        let body = new URLSearchParams();
        if( type == "creditor") {
            body.set('creditor', creditor);
        } else if(type == "payment"){
            
            body.set('payment', JSON.stringify(creditor));
        } else {
            body.set('nominalcode', JSON.stringify(creditor));
        }

        return this.http.post(this.getApiUrl('gocardless_update'), body.toString(), options);
    }
    
    connectHitachi(clientKey: string, serviceKey: string, branchId: string) {
        let body = new URLSearchParams();

        body.set('clientKey', clientKey);
        body.set('secretKey', serviceKey);
        body.set('branchId', branchId);
        body.set('newUI', "1");

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http.post(this.getApiUrl('hitachi_connect'), body.toString(), options);

    }
    
    connectHitachiDetails(retailerName: string, tradingName: string, privacyPolicy: string,clientKey: string, serviceKey: string, branchId: string) {
        let body = new URLSearchParams();

        body.set('retailerName', retailerName);
        body.set('tradingName', tradingName);
        body.set('privacyPolicy', privacyPolicy);
        body.set('clientKey', clientKey);
        body.set('secretKey', serviceKey);
        body.set('branchId', branchId);
        body.set('newUI', "1");

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http.post(this.getApiUrl('hitachi_connectadditionalinfo'), body.toString(), options);

    }

    updateHitachiDetails(clientKey: string, serviceKey: string, branchId: string) {
        let body = new URLSearchParams();

        body.set('clientKey', clientKey);
        body.set('secretKey', serviceKey);
        body.set('branchId', branchId);
        body.set('newUI', "1");

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http.post(this.getApiUrl('hitachi_connect'), body.toString(), options);

    }
    
    deleteHitachiIntegration(type: string): Observable<any> {
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        let body = new URLSearchParams();

        body.set('confirmText', 'CANCEL');

        return this.http.post(this.getApiUrl('hitachi_disconnect?mode=' + type + "&newUI=1"), body.toString(), options);

    }

    deleteSpruceIntegration(params?: string): Observable<any> {
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        let body = new URLSearchParams();
        console.log("params is :" + params);
        body.set('id', params);
        body.set('confirmText', 'CANCEL');

        return this.http.post(this.getApiUrl('deleteSpruceAccount'), body.toString(), options);

    }

    addRepresentativeDetails(retailerName: string, tradingName: string, privacyPolicy: string,clientKey: string, serviceKey: string, branchId: string, representativeDetails: string) {
        let body = new URLSearchParams();

        body.set('retailerName', retailerName);
        body.set('tradingName', tradingName);
        body.set('privacyPolicy', privacyPolicy);
        body.set('clientKey', clientKey);
        body.set('secretKey', serviceKey);
        body.set('branchId', branchId);
        body.set('representativeDetails', representativeDetails);
        body.set('newUI', "1");

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        return this.http.post(this.getApiUrl('hitachi_addRepresentative'), body.toString(), options);
    }

}
