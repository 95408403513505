/**
 * Created by santha on 3/26/14.
 */

function HandleDurationModal($scope, $modalInstance) {
    $scope = $scope.$parent;

    $scope.saveDuration = function() {
        if(this.convertToNormalEvent || ($scope.toConvertOrResize && !$scope.timeDiffArray.length)) {
            $scope.eventToEdit.isSpecialEvent = false;
        }
        $scope.eventToEdit.actualTime = this.actualTime;
        $scope.updateEvent($scope.eventToEdit);
        $scope.eventToEdit = {};
        $scope.close();
    }

    $scope.close = function () {
        $scope.durationModalOpened = false;
        if($scope.toConvertOrResize) {
            $scope.toConvertOrResize = false;
            $scope.updateEvents();
        }
        $modalInstance.close();
    }
}

function DiaryCtrl($scope, prefix, $http, diaryObj, canLoad, $q, $state, $timeout, $rootScope, $compile, addDiaryEvent, uiCalendarConfig, csPopUpPosition, filterUsersOnDiary, $modal, warningModal, $window, $translate, toastBox, skills_data, filterSkillsOnDiary, $csMapper, diaryEventLockOption, colorSelector, $diaryMapScope, confirmationBoxHelper, checkEngineerShift, $filter, $location) {
    var today = new Date();
    $scope.operational = false;

    if (localStorage.getItem('operational_diary_event') !== undefined && localStorage.getItem('operational_diary_event') !== null) {
        $scope.operational = angular.fromJson(localStorage.getItem('operational_diary_event'));
        localStorage.setItem('operational_diary_event', null); // reset the job ID so that next time we create a diary event, it's not stuck on the same job ID
    }
    $scope.selectable = diaryObj.selectable;
    $scope.editable = diaryObj.editable;
    $scope.resources = diaryObj.users;
    $scope.overlapping = diaryObj.overlapping;
    $scope.unAssignedUsers = diaryObj.unAssignedUsers;
    $scope.estimateTemplates = diaryObj.estimateTemplates;
    $scope.invoiceCategories = diaryObj.invoiceCategories;
    $scope.showPostCodeLookUp = diaryObj.showPostCodeLookUp;
    $scope.titles = diaryObj.titles;
    $scope.landlords = diaryObj.landlords;
    $scope.advertisingTypes = diaryObj.advertisingTypes;
    $scope.customerTypes = diaryObj.customerTypes;
    $scope.jobAccessMethods = diaryObj.jobAccessMethods;
    $scope.estimateAccessMethods = diaryObj.estimateAccessMethods;
    $scope.jobDescriptions = diaryObj.jobDescriptions;
    $scope.defaultDate = moment(diaryObj.defaultDate);
    $scope.userGroups = diaryObj.userGroups;
    $scope.currentView = diaryObj.view;
    $scope.currentColorScheme = diaryObj.colorScheme;
    $scope.estimateJobDetails = diaryObj.estimateJobDetails;
    $scope.allUsers = $scope.users = $scope.resources;
    $rootScope.moduleType = diaryObj.moduleAccess;
    $scope.copyDescription = diaryObj.copyDescription;
    $scope.copyEngineerNote = diaryObj.copyEngineerNote;
    $scope.invoiceAccess = diaryObj.invoiceAccess;
    $scope.intelligent_scheduling_permissions = diaryObj.intelligent_scheduling_permissions;
    $scope.diaryTimeFormat = diaryObj.diaryTimeFormat;
    //$scope.userShift = diaryObj.userShift;
    $scope.diaryLayout = diaryObj.diaryLayout;
    $scope.resourceId = '';
    $scope.allEvents = [];
    $scope.currentEvents = [];
    $scope.eventToUndo = {};
    $scope.viewStart = moment(today);
    $scope.viewEnd = moment(today);
    $scope.showSticky = false;
    $scope.showRevert = false;
    $scope.stickyMessage = '';
    $scope.events = [];
    $scope.eventSources = [$scope.events];
    $scope.timeDiffArray = [];
    $scope.selectedUserGroups = [];
    $scope.isFromFilter = false;
    $scope.todayStr = moment()._d.toDateString();
    //This is to handle the event loading
    $scope.prevNextClicked = false;
    $scope.nextEvents = [];
    $scope.prevEvents = [];
    $scope.show_event_pop_up = false;
    $scope.eventDragStarted = false;
    $scope.selectedUserIdAsArray = [];
    $scope.densityValue = diaryObj.densityValue;
    $scope.weeklyFormat = diaryObj.weeklyFormat;

    $scope.bookingAppointmentForOutstandingJob = false;
    $scope.milestones = [];
    $scope.doubleClicked = false;
    $scope.selectedDiaryEventId = false;

    // Skills related
    $scope.skillsModulesAccess = diaryObj.skillsModulesAccess;
    $scope.skills_data = skills_data;
    $scope.selectedSkills = [];
    $scope.jobSkills = [];

    // Map view related
    $scope.showUserMarkerPopup = false;
    $scope.userMarkerPopupData = {
        userDetails: {},
        positionDetails: {}
    };
    $scope.pastJobsSetting = diaryObj.pastJobsSetting;

    if($scope.currentView == 'agendaResource') {
        $scope.currentView = 'agendaDay'
    }
    // set calendar time format
    $scope.smallTimeFormat = 'h(:mm)a'
    if($scope.diaryTimeFormat == 1){
        $scope.smallTimeFormat = 'HH:mm'
    }

    // Job scheduling
    $scope.jobDurationSettings = diaryObj.jobDurationSettings;
    // lock events related
    $scope.lock_events_options = diaryObj.lock_events_options;

    // service windows
    $scope.service_windows = diaryObj.service_windows;
    // related to applying skill filters
    $scope.save_state = true;
    $scope.appointmentLengthHour = diaryObj.appointmentLengthHour;
    $scope.appointmentLengthMinute = diaryObj.appointmentLengthMinute;
    $scope.appointmentDuration = diaryObj.timetocomplete;
    $scope.checkCustomFieldsPermission=diaryObj.checkCustomFieldsPermission;

    if(diaryObj.hasOwnProperty('skills')) {
        var jobSkills = $scope.jobSkills = diaryObj.skills;
        if(jobSkills.length) {
            _.each($scope.skills_data, function (value) {
                var selectedObj = _.where(jobSkills, {id: value.id});
                value.checked = false;
                value.shown_on_diary = false;
                if (selectedObj.length) {
                    value.checked = true;
                    value.shown_on_diary = true;
                    $scope.selectedSkills.push(value);
                }
            });
        }
    } else if($scope.skills_data && $scope.skills_data.length) {
        _.each($scope.skills_data, function (value) {
            value.checked = false;
            value.shown_on_diary = false;
        });
    }

    if($state.params.diaryId > 0) {
        $scope.selectedDiaryEventId = $state.params.diaryId;
    }

    //$scope.moduleType = $rootScope.moduleType;
    JobDiaryEventCommonCtrl.call(this, $scope, $rootScope, $state, $compile, warningModal);

    $scope.resetSkillsData = function() {
        if($scope.skills_data && $scope.skills_data.length) {
            _.each($scope.skills_data, function (value) {
                value.checked = false;
                value.shown_on_diary = false;
            });
            $scope.selectedSkills = [];
        }
    }

    $scope.checkUserSkillAssociation = function() {
        if($scope.selectedSkills && $scope.selectedSkills.length) {
            var usersWithSkills = [];
            var usersOnDairy = _.where($scope.users, { shown_on_diary: true });
            _.each(usersOnDairy, function (value) {
                var userAssociatedSkill = value.skills;
                for(var index = 0, len = userAssociatedSkill.length; index < len; index++) {
                    var tempSkill = userAssociatedSkill[index];
                    var userSkill = _.where($scope.selectedSkills, { id: tempSkill.id });
                    if(userSkill && userSkill.length && usersWithSkills.indexOf(value.id) == -1) {
                        usersWithSkills.push(value.id);
                    }
                }
            });
            if(usersOnDairy.length != usersWithSkills.length) {
                $scope.resetSkillsData();
            }
        }
    }

    $scope.updateAllUsersWithSelection = function() {
        angular.forEach($scope.allUsers, function (value, key) {
            var userId = value.id;
            var userObj = _.where($scope.users, { id: userId });
            value.shown_on_diary = false;
            value.checked = false;
            if(userObj && userObj.length) {
                if(userObj[0].checked) {
                    value.shown_on_diary = true;
                    value.checked = true;
                }
            }
        });
    }

    $scope.changeResources = function() {
        $scope.users_to_show_on_dairy = updateShifts(_.where($scope.users, { shown_on_diary: true }));
        $scope.setSelectedUserIdArray();

        // maintains the view
        var view = uiCalendarConfig.calendars.csCalendar.fullCalendar('getView');
        $scope.uiConfig.calendar.defaultView = view.name;
        $scope.uiConfig.calendar.defaultDate = uiCalendarConfig.calendars.csCalendar.fullCalendar('getDate').local();
        if(!$scope.$$phase) {
            $scope.$apply(function() {
                $scope.uiConfig.calendar.resources = $scope.users_to_show_on_dairy;
            });
        }else {
            $scope.uiConfig.calendar.resources = $scope.users_to_show_on_dairy;
        }
        resizeCalendar();
        $scope.checkUserSkillAssociation();
    }

    $scope.checkUsersForSkills = function() {
        var selectedSkills = $scope.selectedSkills;
        if(selectedSkills && selectedSkills.length) {
            var usersWithSkills = [];
            _.each($scope.users, function (value) {
                var userAssociatedSkill = value.skills;
                for(var index = 0, len = userAssociatedSkill.length; index < len; index++) {
                    var tempSkill = userAssociatedSkill[index];
                    var userSkill = _.where(selectedSkills, { id: tempSkill.id });
                    if(userSkill && userSkill.length && usersWithSkills.indexOf(value.id) == -1) {
                        usersWithSkills.push(value.id);
                    }
                }
            });

            if(usersWithSkills.length) {
                _.each($scope.users, function (user) {
                    if(usersWithSkills.indexOf(user.id) != -1) {
                        user.checked = true;
                        user.shown_on_diary = true;
                    } else {
                        user.checked = false;
                        user.shown_on_diary = false;
                    }
                });
                $scope.users_to_show_on_dairy = updateShifts(_.where($scope.users, { shown_on_diary: true }));
            }
        }
    }

    //This function to handle the re-ordered user objects
    $scope.initializeUnAssignedUsers = function(){
        var selectedUsers = diaryObj.selectedUsers;
        var newResources = [];
        var newUsers = [];
        _.each($scope.unAssignedUsers, function (user) {
            var selectedObj = _.where(selectedUsers, {id: user.id});
            if (!selectedObj.length) {
                newUsers.push(user);
            }
        });
        if (selectedUsers && selectedUsers.length) {
            _.each(selectedUsers, function (userObj) {
                var userObject = _.where($scope.unAssignedUsers, {id: userObj.id});
                if (userObject.length) {
                    userObject[0].shown_on_diary = userObj.checked;
                    newResources.push(userObject[0]);
                }
            });
        }
        if (newUsers.length) {
            _.each(newUsers, function (user) {
                newResources.push(user);
            });
        }
        $scope.allUsers = $scope.users = $scope.resources = newResources;
    }

    //This function to handle the re-ordered user objects
    $scope.initializeUsers = function(){
        var selectedUsers = diaryObj.selectedUsers;
        var newResources = [];
        var newUsers = [];
        _.each($scope.resources, function (user) {
            var selectedObj = _.where(selectedUsers, {id: user.id});
            if (!selectedObj.length) {
                newUsers.push(user);
            }
        });
        if (selectedUsers && selectedUsers.length) {
            _.each(selectedUsers, function (userObj) {
                var userObject = _.where($scope.resources, {id: userObj.id});
                if (userObject.length) {
                    userObject[0].shown_on_diary = userObj.checked;
                    newResources.push(userObject[0]);
                }
            });
        }
        if (newUsers.length) {
            _.each(newUsers, function (user) {
                newResources.push(user);
            });
        }
        $scope.allUsers = $scope.users = $scope.resources = newResources;
    }

    $scope.updateResources = function(userGroups, initial) {
        var users = [];
        var usersForSidePanel = [];
        var selectedUsers = diaryObj.selectedUsers;
        if(userGroups && userGroups.length) {
            var tickedUserGroups= _.where(userGroups, { checked: true });
            $scope.selectedUserGroups = [];
            angular.forEach(tickedUserGroups, function (value, key) {
                $scope.selectedUserGroups.push(value.id);
            });
            if(typeof initial != 'undefined' && initial){
                var selectedUserIds = [];
                angular.forEach(selectedUsers, function (value, key) {
                    var userInAllUsers = _.where($scope.allUsers, { id: value.id });
                    selectedUserIds.push(value.id);
                    if(userInAllUsers && userInAllUsers.length){
                        if(value.checked == true) {
                            userInAllUsers[0].checked = true;
                            userInAllUsers[0].shown_on_diary = true;
                            users.push(userInAllUsers[0]);
                        } else {
                            userInAllUsers[0].checked = false;
                            userInAllUsers[0].shown_on_diary = false;
                        }
                        usersForSidePanel.push(userInAllUsers[0]);
                    }
                });
                angular.forEach($scope.allUsers, function (value, key) {
                    var userGroupId = value.userGroup;
                    if($scope.selectedUserGroups.includes(userGroupId) && !selectedUserIds.includes(value.id) ){
                        value.checked = false;
                        value.shown_on_diary = false;
                        usersForSidePanel.push(value);
                    }
                });
            } else {
                var alreadyCheckedGroupIds = [];
                angular.forEach($scope.users, function (value, key) {
                    var userGroupId = value.userGroup;
                    if($scope.selectedUserGroups.includes(userGroupId)) {
                        if(!alreadyCheckedGroupIds.includes(userGroupId)){
                            alreadyCheckedGroupIds.push(userGroupId);
                        }
                        var userInAllUsers = _.where($scope.allUsers, { id: value.id });
                        if(userInAllUsers && userInAllUsers.length){
                            if(value.checked == true) {
                                userInAllUsers[0].checked = true;
                                userInAllUsers[0].shown_on_diary = true;
                                users.push(userInAllUsers[0]);
                            }
                            usersForSidePanel.push(userInAllUsers[0]);
                        }
                    }

                });
                //add users from the newly ticked group
                angular.forEach(tickedUserGroups, function (userGroup, key) {
                    var userGroupId = userGroup.id;
                    var usersInGroup = _.where($scope.allUsers, { userGroup: userGroupId });
                    if(alreadyCheckedGroupIds.includes(userGroupId)){
                        angular.forEach(usersInGroup, function (user, key) {
                            var userInTheList= _.where(usersForSidePanel, { id: user.id });
                            if(!userInTheList){ // add new users
                                user.shown_on_diary = false;
                                user.checked = false;
                                usersForSidePanel.push(user);
                            }
                        });
                    } else {
                        angular.forEach(usersInGroup, function (user, key) {
                            user.shown_on_diary = true;
                            user.checked = true;
                            users.push(user);
                            usersForSidePanel.push(user);
                        });
                    }
                });
            }

            if($scope.unAssignedUsers && $scope.unAssignedUsers.length) {
                angular.forEach($scope.unAssignedUsers, function (value, key) {
                    usersForSidePanel.push(value);
                    var selectedObj = _.where(selectedUsers, {id: value.id});
                    var isUserSelected = false;
                    if(selectedObj.length) {
                        isUserSelected = selectedObj[0].checked;
                    }
                    if((value.shown_on_diary && !selectedObj) || isUserSelected) {
                        users.push(value);
                    } else {
                        value.shown_on_diary = false;
                    }
                });
            }
        } else {
            $scope.initializeUnAssignedUsers();
            angular.forEach($scope.allUsers, function (value, key) {
                usersForSidePanel.push(value);
                if(value.shown_on_diary == true) {
                    users.push(value);
                }
            });
            //usersForSidePanel = users = $scope.unAssignedUsers;
        }
        $scope.users = usersForSidePanel;
        $scope.users_to_show_on_dairy = updateShifts(users);
    }

    function resizingShipftHighlights() {
      var height = $scope.densityValue;
      $("head style#height").remove();
      $("head").append('<style id="height"> .fc-time-grid .fc-slats td {height: ' + height + ';}</style>');
    }

    function updateShifts(users){
        resizingShipftHighlights();
        if($scope.currentView == 'timelineDay' || $scope.currentView == 'agendaDay' || $scope.currentView == 'diaryMap' ){
            angular.forEach(users, function (value, key) {
                if(value['businessHoursDetails'] != null ){
                    var businessArray = []
                    angular.forEach(value['businessHoursDetails'], function (value, internal_key) {
                        var hour_details = value;
                        if(value.hasOwnProperty('end_time')) {
                            hour_details['end'] = value['end_time'];
                            delete hour_details['end_time'];
                        }
                        hour_details['dow'] = [parseInt(value['dow'])];
                        businessArray.push(hour_details);
                    });
                    users[key]['businessHours'] = businessArray;
                }

            });
        }
        else {
            angular.forEach(users, function (value, key) {
                users[key]['businessHours'] = null
            });
        }
        return users;
    }

    $scope.userDetailArray = [];
    $scope.setSelectedUserIdArray = function() {
        var userDetailArray = [];
        $scope.selectedUserIdAsArray = [];
        _.each($scope.users, function(user, key){
            userDetailArray.push({id: user.id, checked: user.shown_on_diary, position: key});
            if(user.shown_on_diary) {
                $scope.selectedUserIdAsArray.push(user.id);
            }
        });
        $scope.userDetailArray = userDetailArray;
    }

    $scope.initializeUsers();
    $scope.updateResources($scope.userGroups, true);
    $scope.setSelectedUserIdArray();
    $scope.checkUsersForSkills();

    // handle the height of the calendar
    function handleFullCalendarHeight() {
        return (window.innerHeight - 160);
    }

    function resizeCalendar() {
        $('#cs-calendar').fullCalendar('option', 'contentHeight', handleFullCalendarHeight());
    }

    $(window).on('resize', resizeCalendar);

    var engineerAppearInRow = ($scope.diaryLayout && $scope.diaryLayout === 'ENGINEER_APPEAR_IN_ROW');

    var diaryViews = ['agendaDay', 'agendaWeek', 'twoWeek', 'month'];
    var todaylable;
    var slotDuration = '00:30:00';
    if (engineerAppearInRow) {
        diaryViews = ['timelineDay', 'timelineWeek', 'twoWeek', 'month'];
        if($scope.currentView === 'timelineDay' || $scope.currentView === 'agendaDay') {
            $scope.currentView = 'timelineDay';
            slotDuration = '00:30:00';
        } else if ($scope.currentView == 'timelineWeek' || $scope.currentView == 'agendaWeek') {
            $scope.currentView = 'timelineWeek';
            slotDuration = '24:00:00';
        }
    } else {
        if($scope.currentView === 'timelineDay' || $scope.currentView === 'agendaDay') {
            $scope.currentView = 'agendaDay';
            slotDuration = '00:30:00';
        } else if ($scope.currentView == 'timelineWeek' || $scope.currentView == 'agendaWeek') {
            $scope.currentView = 'agendaWeek';
            slotDuration = '00:30:00';
        }
    }
    diaryViews.unshift('diaryMap');
    // Creating new custom diary view for map
    GenerateFcMapView($scope, $compile);

    if($state.current.name == 'loggedin.diary_from_job_estimate' || $state.current.name == 'loggedin.diary_from_milestone') {
        var propertyId = $scope.estimateJobDetails.property.id;
        if($scope.intelligent_scheduling_permissions && $scope.intelligent_scheduling_permissions.read) {
            diaryViews.unshift('suggestedAppointment');
        }
        $scope.save_state = false;
    }
    SchedulingCtrl.call(this, $scope, $http, $q, prefix, $csMapper, canLoad, $scope.jobSkills, $state, $scope.estimateJobDetails, $scope.copyDescription, $scope.users_to_show_on_dairy, $rootScope, $window, colorSelector, csPopUpPosition, $scope.appointmentLengthHour, $scope.appointmentLengthMinute, $scope.appointmentDuration);
    // Choosing default view

    if(diaryViews.indexOf($scope.currentView) < 0) $scope.currentView = diaryViews[0];

    if($scope.estimateJobDetails && $scope.currentView === 'diaryMap'){
        $scope.defaultDate = moment();
    }
    // if ($scope.defaultDate.isSame(moment(), 'day')) {
    //     label_format = '[Today(]dddd Do MMMM YYYY[)]';
    // }
    // else {
    //     label_format = 'dddd Do MMMM YYYY';
    // } 
    $scope.uiConfig = {
        calendar: {
            schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
            header: {
                left: 'title',
                center: '',
                right: diaryViews.join(',')
            },
            contentHeight: handleFullCalendarHeight(),
            columnHeaderText: function(moment) {
                var headFormat = {
                    month: 'dddd',
                    agendaWeek: 'ddd ' + $scope.weeklyFormat,
                    agendaDay: 'dddd',
                    twoWeek: "dddd",
                    suggestedAppointment: 'dddd',
                    diaryMap: 'dddd'

                };
                return moment.format(headFormat[$scope.currentView])
            },
            buttonText: {
                timelineDay: "Daily",
                timelineWeek: "Weekly",
                agendaDay: "Daily",
                agendaWeek: "Weekly",
                twoWeek: $translate('two.weekly'),
                // list: "List",
                month: "Monthly",
                suggestedAppointment: 'Suggested appointment',
                diaryMap: 'Map'
            },
            views: {
                twoWeek: {
                    type: 'basic',
                    titleFormat: 'Do MMMM YYYY',
                    duration: { weeks: 2},
                    rows: 2
                },
                timelineWeek: {
                    type: 'timeline',
                    titleFormat: 'Do MMMM YYYY',
                    slotLabelFormat: [
                        'ddd ' + $scope.weeklyFormat      // lower level of text
                    ],
                    slotDuration: { days: 1 }
                },
                agendaWeek: {
                    titleFormat: 'Do MMMM YYYY',
                    slotDuration: '00:30:00'
                },
                timelineDay: {
                    titleFormat: 'dddd Do MMMM YYYY',
                    slotDuration: '00:30:00'
                },
                agendaDay: {
                    titleFormat: 'dddd Do MMMM YYYY',
                    slotDuration: '00:30:00',
                },
                suggestedAppointment: {
                    titleFormat: 'dddd Do MMMM YYYY',
                },
                diaryMap: {
                    titleFormat: 'dddd Do MMMM YYYY',
                }
            },
            scrollTime: '08:00:00',
            smallTimeFormat: $scope.smallTimeFormat, // 24 or 12 time format related changes
            slotWidth: "100",
            displayEventEnd: true,
            forceEventDuration: true,
            defaultTimedEventDuration: "01:00:00",
            eventLimit:true,
            defaultDate: $scope.defaultDate,
            handleWindowResize: true,
            selectable: $scope.selectable,
            selectHelper: true,
            selectConstraint:{
                start: '00:00',
                end: '24:00',
            },
            eventConstraint: {
                start: '00:00',
                end: '24:00'
            },
            unselectCancel: ['div.in-view', '.confirmation-showing'],
            dropAccept: '.fc-view-container',
            editable: $scope.editable,
            eventResourceEditable: true,
            defaultView: $scope.currentView,
            resources: $scope.users_to_show_on_dairy,
            fixedWeekCount: false,
            resourceLabelText: 'Users',
            resourceAreaWidth: '15%',
            unselectAuto: false,
            select: function(start, end, event, view, resource, allDay) {
                var viewName = $scope.currentView;
                if(allDay === true){
                    allDay = 'fullday';
                }

                if(viewName == 'twoWeek' || viewName == 'month') {
                    allDay = 'fullday';
                }
                if(!resource){
                    var resource_id = false;
                }
                else {
                    var resource_id = parseInt(resource.id)
                }
                $scope.onSelect(start, end, allDay, resource_id);
            },
            viewRender: function(view, element) {
                // Updating resources on view change
                if ($scope.currentView !== view.name && $scope.uiConfig.calendar.blankViews.indexOf(view.name) < 0) {
                    // maintains the view
                    $scope.uiConfig.calendar.defaultView = view.name;
                    $scope.uiConfig.calendar.defaultDate = uiCalendarConfig.calendars.csCalendar.fullCalendar('getDate').local();
                    $scope.currentView = view.name;
                    $scope.uiConfig.calendar.resources = updateShifts($scope.users_to_show_on_dairy);
                    $scope.uiConfig.calendar.contentHeight = handleFullCalendarHeight();
                }
                update_calendar_toolbar_text();
                if(view.name == 'suggestedAppointment') {
                    $scope.loadScheduleDetails();
                }
                if($scope.currentView != view.name){
                    $scope.save_state = true;
                }
                $scope.currentView = view.name;
                $scope.viewStart = moment(view.start.format('YYYY-MM-DD HH:mm:ss'));
                $scope.viewEnd = moment(view.end.format('YYYY-MM-DD HH:mm:ss'));
                $scope.startDate = view.intervalStart;
                if($scope.prevNextClicked && $scope.prevNextClicked != 'today' && $scope.nextEvents !== false && $scope.prevEvents !== false) {
                    $scope.loadNextOrPrevEvents(view, $scope.prevNextClicked);
                    $scope.prevNextClicked = false;
                } else {
                    $scope.loadAllEvents();
                    //Todo : This will be removed later, added for testing
                    if($scope.prevNextClicked) {
                        // console.log('clicked multiple times / clicked before the event is loading');
                    }
                }
                if($scope.currentView != 'suggestedAppointment' && $scope.save_state) {
                    $scope.saveState();
                }
                if(view.name === 'diaryMap') {
                    $scope.broadcastEstimateJobDetailsFromPrevious();
                }
                $(element).find('tr').css('height', $scope.densityValue);

                resizeCalendar();
            },
            eventClick: function(calEvent, jsEvent, view) {
            },
            droppable: true,
            drop: function(date, jsEvent, ui, resourceId, allDay) {
                if($scope.currentView == 'twoWeek' || $scope.currentView == 'month') {
                    allDay = 'fullday';
                }
                $scope.handleEventDrop(date, jsEvent, parseInt(resourceId), allDay);
            },
            eventDrop: function(event) {
                $scope.eventDragStarted = false;
                $scope.handleMultipleDaySelection(event);
            },
            eventResizeStart: function(event, coords) {
                $scope.eventDragStarted = true;
            },
            eventResizeStop: function(event) {
                $scope.eventDragStarted = false;
                $scope.show_event_pop_up = false;
            },
            eventDragStart: function(event) {
                $scope.eventDragStarted = true;
            },
            eventDragStop: function(event) {
                $scope.eventDragStarted = false;
                $scope.show_event_pop_up = false;
            },
            eventResize: function(event) {
                var newEvent = $scope.convertEvent(event);
                if(newEvent.isSpecialEvent) {
                    var timeDiff = moment(newEvent.end).diff(moment(newEvent.start))/1000/60;
                    if(timeDiff <= newEvent.actualTime) {
                        $scope.handleSpecialEventResize(newEvent);
                        return false;
                    }
                }
                $scope.updateEvent(newEvent);
            },
            eventRender: function (event, element, view) {
                var contentObj = csDiaryHelpers.getEventHtml(view, event);
                var content = contentObj.html;

                if (view.name == 'timelineDay' || view.name == 'timelineWeek') {
                    // Add the popup attributes
                    angular.forEach(contentObj.popup, function(val, key) {
                        element.attr(key, val);
                    });
                }

                var classNames = $(content).attr('class');
                if (view.name == 'twoWeek' || view.name == 'month') {

                } else {
                    element.addClass(' ' + classNames);
                }
                content = $(content).removeClass(classNames);
                element.html(content);
                if (view.name == 'twoWeek' || view.name == 'month') {
                    $('div:first', element).addClass(' cs-event-no-background ' + classNames);
                }
                $compile(element)($scope);
            },
            eventAllow: function(start, event, end, resource) {
                if(event.status.status === 'cancelled' || event.status.status === 'rejected'|| event.status.status === 'no_access' || event.status.status === 'aborted' || event.status.status === 'left'){
                    return false;
                }else{
                    return true; // or false
                }
            },
            resourceRender: function(resourceObj, labelTds, bodyTds) {
            },
            eventAfterAllRender: function (view) {
                if($scope.currentView == 'timelineDay' || $scope.currentView == 'timelineWeek'){
                    $('.fc-scroller').scrollTop(0)
                }
            },
            blankViews: ['suggestedAppointment', 'diaryMap']
        }
    }
    $scope.handleMultipleDaySelection = function(event) {
        var selectionStart = moment(event.start.format());
        var selectionEnd = moment(event.end.format());
        //var eventToCheck = _.findWhere($scope.events, { id: event.id });
        var eventStart = selectionStart.clone();//eventToCheck.start;
        var startOfDate = moment(eventStart).startOf('day');
        var endOfDate = moment(eventStart).add(1, 'day').startOf('day');
        if(!event.allDay && ((selectionStart < startOfDate && selectionEnd >= startOfDate) || (selectionEnd > endOfDate && selectionStart <= endOfDate))) {
            warningModal.show('Diary.multiple.day.warning.message', 'Diary.event', 'diary_multiple_day_message');
            $scope.updateEvents();
        } else {
            var newEvent = $scope.convertEvent(event);
            $scope.updateEvent(newEvent);
        }
    }

    $scope.handleEventDrop = function handleEventDrop(start, element, resourceId, allDay) {
        allDay = false;   // Raja: May9 hack
        resourceId = parseInt(resourceId);
        var eventInfo = $(element.target).data('event');
        if(eventInfo){
            // $scope.$broadcast('removeOutstandingJob', {eventInfo: eventInfo});
            var end = start.clone().add(1, 'h');
            if(allDay) {
                var dateTimeObj = $scope.getEventStartEndValues(start, allDay);
                start = dateTimeObj.start;
                end = dateTimeObj.end;
            }

            $scope.start = start;
            $scope.end = end;
            $scope.allDay = allDay;
            $scope.resourceId = resourceId;
            $scope.user = _.where($scope.users, { id: resourceId })[0];

            $http.get(prefix + '/diary/'+ eventInfo.recordType +'/'+ eventInfo.id +'/get_data')
                .then(function(response) {
                    $scope.bookingAppointmentForOutstandingJob = true;
                    $scope.estimateJobDetails = response.data.estimateJobDetails;
                    $scope.milestones = response.data.milestones;
                    $scope.copyDescription = response.data.copyDescription;
                    $scope.copyEngineerNote = response.data.copyEngineerNote;
                    $scope.$broadcast('diary:set_previous_page_values', $scope.estimateJobDetails, false);
                    $scope.setTimeDifferenceArray(start, end, allDay);
                    addDiaryEvent.init($scope);
                })
        }
    }

    $scope.getPopUpData = function getPopUpData($event, eventId) {
        $scope.pop_up_data =  _.where($scope.events, { id: eventId })[0];
        csPopUpPosition.init($event);
    }

    $scope.eventDoubleClick = function eventDoubleClick(eventId) {
        $scope.doubleClicked = true;
        var event =  _.where($scope.events, { id: eventId })[0];
        var redirectDetails = event.redirectDetails;
        var primaryId, milestoneId;
        if(event.type == 'estimate') {
            primaryId = redirectDetails.estimateId;
        } 
        else if(event.type == 'opportunity') {
            primaryId = redirectDetails.opportunityId;
            milestoneId = redirectDetails.milestoneId;

        }
        else if(event.type == 'jobs_tab') {
            primaryId = redirectDetails.jobId;
            milestoneId = redirectDetails.milestoneId;
        }
        else {
            primaryId = redirectDetails.jobId;
            milestoneId = redirectDetails.milestoneId;
        }
        $scope.handleRedirect(redirectDetails.type, primaryId, redirectDetails.customerId, redirectDetails.customerType, milestoneId);
    }
    $scope.eventSingleClick = function eventSingleClick(eventId) {
        var new_diary_event_panel_open = document.querySelector('#side-panel.add-new-diary-event') !== null;
        $timeout(function () {
            if(!$scope.doubleClicked && !$scope.durationModalOpened && !new_diary_event_panel_open) {
                var event = _.findWhere($scope.events, { id: eventId });
                if(event) {
                    var primaryId;
                    if(event.type =='job' || event.type == 'jobs_tab'){
                        primaryId = event.redirectDetails.jobId;
                    }else if(event.type=='opportunity'){
                        primaryId = event.redirectDetails.opportunityId;
                        event.redirectDetails['currentpath'] = $location.path();
                    }else{
                        primaryId = event.redirectDetails.estimateId;
                    }
                    // var primaryId = (event.type == 'job') ? event.redirectDetails.jobId : event.redirectDetails.estimateId;
                    $rootScope.diaryEventViewData = {id: event.redirectDetails.customerId, screenType: event.type, primaryId: primaryId};
                    $scope.handleViewDiaryEvent(eventId);
                }
            }
        }, 500);
    }

    $scope.durationModalOpened = false;
    $scope.changeSpecialEventDuration = function changeSpecialEventDuration(eventId) {
        $scope.toConvertOrResize = false;
        var event = $scope.eventToEdit =  _.where($scope.events, { id: eventId })[0];
        $scope.setTimeDifferenceArray(event.start, event.end, event.allDay);
        $scope.actualTime = event.actualTime;
        $scope.durationModalOpened = true;
        //Todo: Modal & its related functions will be removed once Callum made all the changes in special event edit design.
        $modal.open({
            templateUrl: 'template/diary/diary-modal.html',
            scope: $scope,
            controller: HandleDurationModal,
            windowClass: 'modal sidebarHelper'
        })
    }

    $scope.toConvertOrResize = false;
    $scope.handleSpecialEventResize = function handleSpecialEventResize(newEvent) {
        $scope.eventToEdit = newEvent;
        $scope.toConvertOrResize = true;
        $scope.setTimeDifferenceArray(newEvent.start, newEvent.end, newEvent.allDay);
        if($scope.timeDiffArray.length) {
            $scope.actualTime = $scope.timeDiffArray[$scope.timeDiffArray.length-1].value;
        }
        $scope.durationModalOpened = true;
        $modal.open({
            templateUrl: 'template/diary/diary-modal.html',
            scope: $scope,
            controller: HandleDurationModal,
            windowClass: 'modal sidebarHelper'
        })
    }

    $scope.handleRedirect = function handleRedirect(type, primaryId, propertyId, propertyType, milestoneId) {
        var featureName = 'Estimates';

        if(type == 'project_job' || type == 'job' || type == 'additional_work' || type == 'jobs_tab') {
            featureName = 'Jobs';
        } else if(type == 'opportunity') {
            featureName = 'Opportunities';//Todo: we need to change this once the sales permissions is done - Santha
        }

        var hasPermission = $rootScope.hasPermission(features[featureName], 'readaccess');

        if(!hasPermission) {
            var warningMessage = 'You.dont.have.permission.to.view.' + featureName;
            var title = 'View.'+featureName;
            var warningId = 'diary_no_access_to_view' + featureName;
            warningModal.show(warningMessage, title, warningId);
            return false;
        }

        if ($scope.operational) {
            $state.transitionTo('loggedin.reporting.operational', {category: $scope.operational.category, subcategory: $scope.operational.subcategory, type: 'available'});
        }
        else{
            if(type == 'estimate') {
                $state.transitionTo('loggedin.customer_list.view.estimate_details.survey', { id: propertyId, estimateId: primaryId, type: propertyType });
            } else if(type == 'project_estimate') {
                $state.transitionTo('loggedin.customer_list.view.project_estimate_details.survey', { id: propertyId, estimateId: primaryId, type: propertyType });
            } else if(type == 'job' || type == 'additional_work') {
                $state.transitionTo('loggedin.customer_list.view.job.details', { id: propertyId, jobId: primaryId, type: propertyType });
            } else if(type == 'project_job') {
                $state.transitionTo('loggedin.customer_list.view.job.visits.view', { id: propertyId, jobId: primaryId, type: propertyType, milestoneId: milestoneId });
            } else if(type == 'opportunity') {
                $location.path(`customers/customer/${propertyId}/opportunity/${primaryId}/view`);
            }
            else if(type == 'jobs_tab') {
                $location.path(`jobs`);
                $rootScope.NgEmailService.setDiaryCompletedStatus('diary');
            }
        }
    }
    
    $scope.onSelect = function(start, end, allDay, resourceId, bookingAppointmentForOutstandingJob = false) {
        $scope.start = start;
        $scope.end = end;
        $scope.allDay = allDay;
        $scope.resourceId = resourceId;
        $scope.user = _.where($scope.users, { id: resourceId })[0];
        $scope.milestones = [];
        $scope.bookingAppointmentForOutstandingJob = bookingAppointmentForOutstandingJob;
        var openSidePanel = false;

        if($scope.currentView == 'agendaDay' || $scope.currentView == 'timelineDay' || $scope.currentView == 'diaryMap'){
            var startDate = moment(start).format('YYYY-MM-DD HH:mm:ss');
            if(allDay) {
                var dateTimeObj = $scope.getEventStartEndValues(start, allDay);
                var startDate = dateTimeObj.start.format('YYYY-MM-DD HH:mm:ss');
                var endDate = dateTimeObj.end.format('YYYY-MM-DD HH:mm:ss');
            } else {
                var endDate = moment(end).format('YYYY-MM-DD HH:mm:ss');
            }
            var shiftModule = ($rootScope.moduleDetails!='undefined') ? (($rootScope.moduleDetails.Shifts) !='undefined' ? $rootScope.moduleDetails.Shifts : 0 ) : 0;
            var continue_overtime = checkEngineerShift.checkShift(startDate, endDate, resourceId, shiftModule, allDay);
            continue_overtime.then(function (re) {
                if (re == 'overlappingshift') {
                    $rootScope.$broadcast('sidepanel:close');
                    openSidePanel = false;
                    warningModal.show('The.shift.with.engineer.have.any.pattern.selected.time', 'Diary.event', 'diary_shift_message');
                    uiCalendarConfig.calendars.csCalendar.fullCalendar('unselect');
                }else{
                    openSidePanel = true;
                    if(!$scope.overlapping && ($scope.currentView == 'agendaDay' || $scope.currentView == 'timelineDay')) {
                        var isOverlapping = $scope.validateDiaryEvent($scope.user.id);
                        if(isOverlapping) {
                            $rootScope.$broadcast('sidepanel:close');
                            openSidePanel = false;
                            warningModal.show('Diary.event.overlapping.error.message', 'Diary.event', 'diary_overlapping_message_');
                            uiCalendarConfig.calendars.csCalendar.fullCalendar('unselect');
                        }
                    }

                    if(openSidePanel) {
                        //This will be used when we start to add the special events
                        $scope.setTimeDifferenceArray(start, end, allDay);
                        addDiaryEvent.init($scope);
                    }
                }
            })
        }else{
            openSidePanel = true;
            if(!$scope.overlapping && ($scope.currentView == 'agendaDay' || $scope.currentView == 'timelineDay')) {
                var isOverlapping = $scope.validateDiaryEvent($scope.user.id);
                if(isOverlapping) {
                    $rootScope.$broadcast('sidepanel:close');
                    openSidePanel = false;
                    warningModal.show('Diary.event.overlapping.error.message', 'Diary.event', 'diary_overlapping_message');
                    uiCalendarConfig.calendars.csCalendar.fullCalendar('unselect');
                }
            }

            if(openSidePanel) {
                //This will be used when we start to add the special events
                $scope.setTimeDifferenceArray(start, end, allDay);
                addDiaryEvent.init($scope);
            }
        }

    }

    $scope.setTimeDifferenceArray = function (start, end, allDay) {
        $scope.timeDiffArray = [];
        if(!allDay) {
            var timeDiff = moment(end).diff(moment(start))/1000/60;
            //var slotMinutes = moment.duration($scope.uiConfig.calendar.slotDuration)._data.minutes;
            var slotDuration = '24:00:00'
            if($scope.currentView === 'agendaDay' || $scope.currentView === 'timelineDay' || $scope.currentView === 'agendaWeek' || $scope.currentView === 'diaryMap' || $scope.currentView === 'suggestedAppointment'){
                slotDuration = '00:30:00'
            }
            var slotMinutes = moment.duration(slotDuration)._data.minutes;
            if(timeDiff > slotMinutes) {
                for(var count = 1; count < timeDiff/slotMinutes; count++) {
                    var duration = count*slotMinutes;
                    var durationStr = '';
                    var hours = Math.floor(duration / 60);
                    var minutes = duration % 60;
                    if(hours > 0) {
                        durationStr += hours + ' hour';
                    }
                    if(minutes > 0) {
                        durationStr += (durationStr != '') ? ' ' : '';
                        durationStr += minutes + ' mins';
                    }
                    $scope.timeDiffArray.push({'value' : duration, 'description':durationStr});
                }
            }
        }
    }

    $scope.$on('diary_event:created', function(e, response) {
        var diary_event = response.event === 'bulk_insert'? 'bulk_insert': response.event[0];

        if (diary_event == 'bulk_insert') {
            $scope.loadAllEvents();

            // Update outstanding jobs count
            $rootScope.$broadcast('refresh:outstanding-jobs');
        } else {
            var event = $scope.convertEvent(diary_event);
            //uiCalendarConfig.calendars.csCalendar.fullCalendar('renderEvent', event, true);
            $scope.events.push(event);
            $scope.currentEvents.push(event);
            $scope.updateEvents();
            $scope.publishEvent('event:diary-event-created', {event: event});

            // Update outstanding jobs count
            if (('estimate', 'job', 'opportunity').indexOf(diary_event.type) >= 0) $rootScope.$broadcast('refresh:outstanding-jobs');
            if (
                $scope.estimateJobDetails
                && $scope.estimateJobDetails.type === diary_event.type
                && $scope.estimateJobDetails.id == diary_event.redirectDetails[diary_event.type + 'Id']
            ) {
                $scope.estimateJobDetails = null;
            }
        }

        uiCalendarConfig.calendars.csCalendar.fullCalendar('unselect');
        if($state.current.name == 'loggedin.diary_from_job_estimate' || $state.current.name == 'loggedin.diary_from_milestone') {
            var propertyId, primaryId, milestoneId;
            if(diary_event == 'bulk_insert') {
                primaryId = $scope.estimateJobDetails.id;
                propertyId = $scope.estimateJobDetails.property.id;
            } else {
                var redirectDetails = diary_event.redirectDetails;
                if(event.type == 'estimate') {
                    primaryId = redirectDetails.estimateId;
                }
                else if(event.type == 'opportunity') {
                    primaryId = redirectDetails.opportunityId;
                    milestoneId = redirectDetails.milestoneId;
                }
                else {
                    primaryId = redirectDetails.jobId;
                    milestoneId = redirectDetails.milestoneId;
                }
                propertyId = redirectDetails.customerId;
            }

            var propertyType = (response.propertyType == 'work address') ? 'work_address' : response.propertyType;
            $scope.handleRedirect($state.params.type, primaryId, propertyId, propertyType, milestoneId);
        }
    });

    var canceler = null;
    $scope.loadingAllEvents = false; // To restrict the next and prev click when loading all events
    $scope.loadAllEvents = function() {
        var name = $scope.currentView;

        if(name == 'suggestedAppointment') {
            $scope.initMap();
            return false;
        }

        //If view changed by filter modifications we don't need to load events
        if($scope.isFromFilter) {
            $scope.setSelectedUserIdArray();
            $scope.isFromFilter = false;
            //$scope.updateEvents();
            //return;
        }

        if(!$scope.selectedUserIdAsArray.length) {
            $scope.setSelectedUserIdArray();
        }

        // if(name == 'list') {
        // 	return;
        // }

        var start = moment($scope.viewStart).format('YYYY-MM-DD');
        var end = moment($scope.viewEnd).format('YYYY-MM-DD');
        var nextDates = $scope.getStartEndDate(name, start, 'next');
        $scope.nextStart = nextDates.startDate;
        $scope.nextEnd = nextDates.endDate;
        var prevDates = $scope.getStartEndDate(name, start, 'prev');
        $scope.prevEnd = prevDates.endDate;
        $scope.prevStart = prevDates.startDate;
        var dataToSend = 'current=' + start + '|' + end + '&next=' + $scope.nextStart + '|' + $scope.nextEnd + '&prev=' + $scope.prevStart + '|' + $scope.prevEnd + '&users=' + $scope.selectedUserIdAsArray.toString();

        canLoad.setLoadValue(false);
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();

        $('#loading-indicator').show();
        $scope.loadingAllEvents = true;

        $scope.loadingEvents = true;
        $http.get(prefix + '/get_diary_events/all?' + dataToSend, {timeout: canceler.promise}).success(function (data) {
            $scope.events = data.events;
            $scope.nextEvents = data.nextEvents;
            $scope.prevEvents = data.prevEvents;
            $scope.updateEvents();
            $scope.loadingAllEvents = false;
            $scope.loadingEvents = false;
        })
    }

    var getPartialEventsCanceler = null;
    $scope.loadNextOrPrevEvents = function(view, buttonClicked) {
        $scope.selectedDiaryEventId = false;
        var start = moment(view.start);
        var end = moment(view.end);
        var nameToSet = buttonClicked + 'Events';
        var startDateToSet = buttonClicked + 'Start';
        var endDateToSet = buttonClicked + 'End';

        if(buttonClicked == 'next') {
            $scope.prevEvents = $scope.events;
            $scope.events = $scope.nextEvents;
            $scope.prevStart = $scope.viewStart;
            $scope.prevEnd = $scope.viewEnd;
        } else {
            $scope.nextEvents = $scope.events;
            $scope.events = $scope.prevEvents;
            $scope.nextStart = $scope.viewStart;
            $scope.nextEnd = $scope.viewEnd;
        }

        var dates = $scope.getStartEndDate(view.name, start, buttonClicked);
        start = dates.startDate;
        end = dates.endDate;

        $scope.updateEvents();
        //This is to handle the failure case, if the user clicks next/prev before the event is loaded, the above logic won't work
        $scope[nameToSet] = false;

        $scope[startDateToSet] = start;
        $scope[endDateToSet] = end;

        var start = moment(start).format('YYYY-MM-DD');
        var end = moment(end).format('YYYY-MM-DD');
        var dataToSend = 'fromdate=' + start + '&todate=' + end + '&users=' + $scope.selectedUserIdAsArray.toString();

        canLoad.setLoadValue(false);
        if (getPartialEventsCanceler) {
            getPartialEventsCanceler.resolve();
        }
        getPartialEventsCanceler = $q.defer();

        $scope.loadingEvents = true;
        $http.get(prefix + '/get_diary_events/next_prev?' + dataToSend, {timeout: getPartialEventsCanceler.promise}).success(function (data) {
            if($scope[nameToSet] === false) {
                $scope[nameToSet] = data.events;
            } else {
                console.log('Events already loaded');
            }
            $scope.loadingEvents = false;
        })
    }

    $scope.refreshCurrentEvents = function() {
        var start = moment($scope.viewStart).format('YYYY-MM-DD');
        var end = moment($scope.viewEnd).format('YYYY-MM-DD');
        var dataToSend = 'fromdate=' + start + '&todate=' + end + '&users=' + $scope.selectedUserIdAsArray.toString();
        $scope.loadingEvents = true;
        $http.get(prefix + '/get_diary_events/next_prev?' + dataToSend).success(function (data) {
            if(data.events) {
                $scope.events = data.events;
                $scope.updateEvents();
            }
            $scope.loadingEvents = false;
        })
    }

    $scope.getStartEndDate = function(viewName, dateObj, option) {
        var stringToAdd = '';
        var numberToAdd = 1;
        var valueToMultiple = (option == 'next') ? 1 : -1;
        var newEndDate = false;
        var newStartDate = false;
        if(viewName == 'agendaDay' || viewName == 'timelineDay') {
            stringToAdd = 'days';
            numberToAdd = 1 * valueToMultiple;
        } else if(viewName == 'agendaWeek' || viewName == 'timelineWeek') {
            stringToAdd = 'weeks';
            numberToAdd = 1 * valueToMultiple;
        } else if(viewName == 'twoWeek') {
            stringToAdd = 'weeks';
            numberToAdd = 2 * valueToMultiple;
        } else if(viewName == 'month') {
            stringToAdd = 'months';
            numberToAdd = 1 * valueToMultiple;
            var addMonth = moment($scope.startDate).add(numberToAdd, stringToAdd);
            var startOfMonth = addMonth.clone().startOf('month');
            newStartDate = startOfMonth.clone().startOf('week').format('YYYY-MM-DD');
            var endOfMonth = addMonth.clone().endOf('month');
            newEndDate = endOfMonth.clone().endOf('week').add(1, 'day').format('YYYY-MM-DD');
        } else if(viewName == 'diaryMap') {
            stringToAdd = 'days';
            numberToAdd = 1 * valueToMultiple;
        } else {
            return false;
        }
        var newStartDate = (!newStartDate) ? moment(dateObj).add(numberToAdd, stringToAdd).format('YYYY-MM-DD') : newStartDate;
        var newEndDate = (!newEndDate) ? moment(newStartDate).add(Math.abs(numberToAdd), stringToAdd).format('YYYY-MM-DD') : newEndDate;
        return {startDate: newStartDate, endDate: newEndDate};
    }

    $scope.handleUpdateEvent = function(event) {
        var statusObj = event.status;
        var showUndo = true;
        var oldEventId = event.id;
        var oldResourceId = event.resourceId;

        var oldEvent = _.findWhere($scope.events, { id: oldEventId });
        if(oldEvent) {
            oldResourceId = oldEvent.resourceId;
        }

        if(!$scope.overlapping) {
            var isOverlapping = $scope.validateDiaryEvent(event.resourceId, event);
            if(isOverlapping) {
                warningModal.show('Diary.event.overlapping.error.message', 'Diary.event', 'diary_overlapping_message');
                $scope.updateEvents();
                return false;
            }
        }

        //No undo option for following scenario
        //Todo : In this case we need to warn the user that we are going to delete the previous one creating new event
        if(statusObj && (statusObj.status == 'accepted' || statusObj.status == 'travel') || oldResourceId != event.resourceId) {
            showUndo = false;
        } else {
            $scope.setUndoEvent(event.id);
        }

        event.description = event.title;
        event.event_start = moment(event.start).format('YYYY-MM-DD HH:mm:ss');
        if(event.allDay) {
            var dateTimeObj = $scope.getEventStartEndValues(event.start, event.allDay);
            event.event_start = dateTimeObj.start.format('YYYY-MM-DD HH:mm:ss');
            event.event_end = dateTimeObj.end.format('YYYY-MM-DD HH:mm:ss');
        } else {
            event.event_end = moment(event.end).format('YYYY-MM-DD HH:mm:ss');
        }

        var event_start = event.event_start;
        var event_end = event.event_end;
        var engineerId = event.resourceId;
        var confirmation_message = 'This event falls outside of the working hours and into overtime. Please confirm';
        var shiftModule = (event.moduleDetails!='undefined') ? ((event.moduleDetails.Shifts) !='undefined' ? event.moduleDetails.Shifts : 0 ) : 0;
        var continue_overtime = checkEngineerShift.checkShift(event_start, event_end, engineerId, shiftModule, event.allDay);
        continue_overtime.then(function (re) {
            if (re == 'overtime' || re == 'nobreaktime' || re == 'overtime_nobreak') {
                if(re == 'nobreaktime'){
                    confirmation_message = 'This event falls into engineer break time. Please confirm';
                }else if(re == 'overtime_nobreak'){
                    confirmation_message = 'This event falls into engineer break time and outside of the working hours and into overtime. Please confirm';
                }
                confirmationBoxHelper.getConfirmation(confirmation_message, $scope)
                    .then(function () {
                            /**
                             * Todo Event lock by datetime logic
                             */
                            let check_interval = $scope.eventHasSameInverval(oldEvent,event.event_start,event.event_end)
                            if(event.lock_event == diaryEventLockOption.lock_by_datetime && !check_interval ){ // lock event 2 is locked by datetime
                                warningModal.show('Diary.event_lock.datetime.error', 'Diary event', 'diary_lock_event_datetime_mismatch_message');
                                $scope.updateEvents();
                                return false;
                            }
                            /**
                             * Todo Event lock by service window logic
                             */

                            else if(event.lock_event == diaryEventLockOption.lock_by_service_window){ // 4 is event locked service windows
                                let check_service_window_overlapped = $scope.check_service_window_overlapped(oldEvent, event);
                                if (check_service_window_overlapped) {
                                    $scope.updateEventBackend(event,showUndo,oldEventId);
                                }
                                else {
                                    warningModal.show('Diary.event_lock.service_window.error', 'Diary event', 'diary_lock_event_service_window_mismatch_message');
                                    $scope.updateEvents();
                                    return false;
                                }
                            }
                            else{
                                $scope.updateEventBackend(event,showUndo,oldEventId);

                            }
                        },
                        function () {
                            $scope.updateEvents();
                        });
            }else if(re == 'overlappingshift'){
                warningModal.show('The.shift.with.engineer.have.any.pattern.selected.time', 'Diary.event', 'diary_overlapping_message');
                $scope.updateEvents();
                return false;
            }
            else{
                $scope.updateEventBackend(event,showUndo,oldEventId);
            }

        })

    };
    /**
     * Todo perform edit operations in backend
     * @param event
     * @param showUndo
     * @param oldEventId
     */
    $scope.updateEventBackend = function(event, showUndo, oldEventId){
        event.revertedEvent = false;
        if($scope[oldEventId] != true){
            $scope[oldEventId] = true;
            $http.post(prefix + '/update_diary_event', 'data='+encodeURIComponent(JSON.stringify(event))).
            success(function (data, status) {
                if (status == 200 && data.error == false) {
                    $scope[oldEventId] = false;
                    var event = data.event;
                    $scope.stickyMessage = data.message;
                    $scope.handleSticky(10000);
                    if(!showUndo) {
                        $scope.events.push(event);
                        $scope.publishEvent('event:diary-event-deleted', {id: oldEventId});
                        $scope.publishEvent('event:diary-event-created', {event: event});
                        $scope.deleteEvent('events', oldEventId);
                        $scope.deleteEvent('nextEvents', oldEventId);
                        $scope.deleteEvent('prevEvents', oldEventId);
                    } else {
                        $scope.showRevert = true;
                        $scope.modifyEvents(event, 'events');
                        $scope.publishEvent('event:diary-event-updated', { event: event});
                    }
                    if($scope.currentView == 'month') {
                        $scope.handleDiaryEventEdit(event);
                    }
                } else if(data.error) {
                    if(data.message == 'Event has been locked to this date and time'){
                        warningModal.show('Diary.event_lock.datetime.error', 'Diary event', 'diary_lock_event_datetime_mismatch_message');
                    }else if(data.message == 'Diary.event.overlapping.error.message'){
                        warningModal.show(data.message, 'Diary.event', 'diary_overlapping_message');
                    }else{
                        warningModal.show(data.message, 'Diary.event', 'diary_error_message');
                    }
                    $scope.updateEvents();
                    $scope[oldEventId] = false;
                }
            });
        }

    };
    /**
     * check event time changed or not
     */
    $scope.eventHasSameInverval = function(oldEvent, start, end){
        let event_value_changed = true;
        let is_start_date_same = moment(start).isSame(oldEvent.start);
        let is_end_date_same = moment(end).isSame(oldEvent.end);
        if(!is_start_date_same || !is_end_date_same){
            event_value_changed = false;
        }
        return event_value_changed;
    }
    /**
     * check service window overlapped
     */
    $scope.check_service_window_overlapped = function(oldEvent,newEvent){
        let old_service_window_id = find_service_window(oldEvent, true);
        let new_service_window_id = find_service_window(newEvent, false);
        return !(old_service_window_id != new_service_window_id);
    }
    /**
     * Todo Function find service windows id
     */
    function find_service_window(event_details, event_type_flag){ //  event type falg is whether its old event or new event
        let service_window_id = null;
        let event_details_time = null;
        let event_start_time = null;
        if(event_type_flag){
            event_details_time = event_details.time;
            event_start_time = moment(event_details.start)
        }
        else {
            event_details_time = 0;
            if (event_details['is_special_event'] || event_details['isSpecialEvent']) {
                event_details_time = 0;
            }
            else if (event_details['allDay']){
                let allDay = event_details['allDay'];
                event_details_time = 1;
                if (allDay == 'am') {
                    event_details_time = 2;
                }
                else if (allDay == 'pm') {
                    event_details_time = 3;
                }
            }
            event_start_time = moment(event_details.event_start)
        }
        if(event_details_time == 0 ){
            $.each($scope.service_windows, function(index, value){
                let current_date = moment().format('YYYY-MM-DD');
                let service_window_startTime = moment(current_date+' '+ moment(value['startTime']['date']).format('HH:mm:ss'));
                let service_window_endTime = moment(current_date+' '+ moment(value['endTime']['date']).format('HH:mm:ss'));
                let current_date_time = moment(current_date+' '+ moment(event_start_time).format('HH:mm:ss'));
                if((current_date_time <= service_window_endTime && current_date_time >= service_window_startTime)){
                    service_window_id = value.id
                    return false;
                }
                else {
                    return true;
                }
            })
        }
        else {
            if(event_details_time == 1){ // 1 is event full day
                service_window_id = _.findWhere($scope.service_windows, { 'allDayEvent': true }).id;
            }
            else if(event_details_time == 2){ // 2 is morning event
                service_window_id = _.findWhere($scope.service_windows, { 'amEvent': true }).id;
            }
            else if(event_details_time == 3){ // 3 is afternoon event
                service_window_id = _.findWhere($scope.service_windows, { 'pmEvent': true }).id;
            }
        }

        return service_window_id;
    }

    $scope.updateEvent = function(event) {
        var oldEventId = event.id;
        var oldResourceId = event.resourceId;

        var oldEvent = _.findWhere($scope.events, { id: oldEventId });
        if(oldEvent) {
            oldResourceId = oldEvent.resourceId;
        }
        event.moduleDetails = $rootScope.moduleDetails;
        /**
         * Todo lock event by Engineer logic
         */

        if(oldResourceId != event.resourceId && event.lock_event == diaryEventLockOption.lock_by_engineer){ // lock event 1 is locked by engineer
            warningModal.show('Diary.event_lock.engineer.error', 'Diary event', 'diary_lock_event_engineer_mismatch_message');
            $scope.updateEvents();
            return false;
        }
        else if(event.type == 'job' && $scope.skills_data && $scope.skills_data.length && oldResourceId != event.resourceId) {
            $http.get(prefix + '/diary/validate_user_skill/'+ event.resourceId +'/'+ event.type +'/'+ event.redirectDetails.jobId)
                .then(function(response) {
                    var engineerHasSkill = response.data.userHasSkill;
                    if(!engineerHasSkill) {
                        warningModal.show('Engineer.skill.match.error', 'Diary.event', 'diary_skill_mismatch_message');
                        $scope.updateEvents();
                        return false;
                    } else {
                        $scope.handleUpdateEvent(event);
                    }
                });
        } else {
            $scope.handleUpdateEvent(event);
        }
    }

    $scope.revertUpdatedEvent = function() {
        $scope.closeSticky();
        var event = $scope.eventToUndo;
        var newEvent = $scope.convertEvent(event);
        newEvent.revertedEvent = true;
        var event_start = newEvent.event_start;
        var event_end = newEvent.event_end;
        var engineerId = newEvent.resourceId;
        var confirmation_message = 'This event falls outside of the working hours and into overtime. Please confirm';
        var shiftModule = ($rootScope.moduleDetails!='undefined') ? (($rootScope.moduleDetails.Shifts) !='undefined' ? $rootScope.moduleDetails.Shifts : 0 ) : 0;
        var continue_overtime = checkEngineerShift.checkShift(event_start, event_end, engineerId,shiftModule,newEvent.allDay);
        continue_overtime.then(function (re) {
            if (re == 'overtime' || re == 'nobreaktime' || re == 'overtime_nobreak') {
                if(re == 'nobreaktime'){
                    confirmation_message = 'This event falls into engineer break time. Please confirm';
                }else if(re == 'overtime_nobreak'){
                    confirmation_message = 'This event falls into engineer break time and outside of the working hours and into overtime. Please confirm';
                }
                confirmationBoxHelper.getConfirmation(confirmation_message, $scope)
                    .then(function () {
                            $scope.confirmRevertEvent(this,newEvent,event);
                        },
                        function () {
                            $scope.updateEvents();
                        });
            }else if(re == 'overlappingshift'){
                warningModal.show('The.shift.with.engineer.have.any.pattern.selected.time', 'Diary.event', 'diary_overlapping_message');
                $scope.updateEvents();
            }else{
                $scope.confirmRevertEvent(this,newEvent,event);
            }

        })
    }

    $scope.confirmRevertEvent = function confirmRevertEvent(self,newEvent,event){
        $http.post(prefix + '/update_diary_event', 'data='+encodeURIComponent(JSON.stringify(newEvent))).
        success(function (data, status) {
            if (status == 200) {
                $scope.eventToUndo = {};
                $scope.modifyEvents(event, 'events');
                $scope.publishEvent('event:diary-event-updated', { event: $scope.convertEvent(event) });
                if($scope.currentView == 'month') {
                    $scope.handleDiaryEventEdit(event);
                }
            }
        });
    }


    $scope.handleSticky = function(time) {
        $scope.showSticky = true;
        $timeout(function () {
            $scope.closeSticky();
        }, time);
    }

    $scope.closeSticky = function() {
        $scope.showRevert = false;
        $scope.showSticky = false;
    }

    $scope.setUndoEvent = function(diaryId) {
        for(var index = 0; index < $scope.events.length; index++) {
            if($scope.events[index].id == diaryId) {
                $scope.eventToUndo = $scope.events[index];
                break;
            }
        }
    }

    $scope.updateEvents = function() {
        var events = [];
        angular.forEach($scope.events, function (value, key) {
            var resourceId = value.resourceId;
            var userObj = _.where($scope.users_to_show_on_dairy, { id: resourceId });
            if(userObj.length) {
                value.eventColor = ($scope.currentColorScheme == 'job') ? value.jobColor : value.userColor;
                if(!$scope.editable) {
                    value.editable = false;
                    value.durationEditable = false;
                }
                /**
                 * Todo event lock status Text
                 */
                let lock_event_option = _.where($scope.lock_events_options, { id: value.lock_event })[0]
                if(lock_event_option){
                    value['event_lock_status'] = _.where($scope.lock_events_options, { id: value.lock_event })[0]['option']
                    if(value.lock_event == diaryEventLockOption.no_lock){
                        value['event_lock_class'] = 'diary-lock-icon-show'
                    }
                    else {
                        value['event_lock_class'] = ''
                    }
                }
                else{
                    value['event_lock_status'] = '';
                    value['event_lock_class'] = 'diary-lock-icon-show'
                }

                /**
                 * Todo Lock the event if locked by engineer and datetime
                 */
                if(value.lock_event == 3){
                    value.editable = false;
                    value.durationEditable = false;
                }
                // set endtime
                if(value.allDay == 'fullday') {
                    var end =moment(value.start).clone().endOf('day');
                    value.end = end;
                }
                events.push(value);
            }
        });

        uiCalendarConfig.calendars.csCalendar.fullCalendar('removeEvents');
        if($scope.currentView == 'diaryMap') {
            /*'1' => 'Only last job', '2' => 'No past jobs', '3' => 'Show past jobs'*/
            if($scope.pastJobsSetting && $scope.pastJobsSetting > 0) {
                if($scope.pastJobsSetting != 3) {
                    var userEvents = $scope.groupUsersEvents(events);
                    $scope.getEventsToDisplayInMap(userEvents);
                    events = angular.copy($scope.eventsAfterDiarySetting);
                }
            }
        }
        $scope.currentEvents = events;
        if(events.length) {
            uiCalendarConfig.calendars.csCalendar.fullCalendar('addEventSource', events);
        }
    }

    $scope.modifyEvents = function(newEvent, eventArrayName) {
        for(var index = 0; index < $scope[eventArrayName].length; index++) {
            if($scope[eventArrayName][index].id == newEvent.id) {
                $scope[eventArrayName][index] = newEvent;
                break;
            }
        }

        if(eventArrayName == 'events') {
            $scope.updateEvents();
        }
    }

    $scope.filterUsers = function(userGroups) {
        $scope.isFromFilter = true;
        $scope.save_state = true;
        $scope.updateResources(userGroups);
        $scope.checkUsersForSkills();

        //Todo: Need to test this clearly this is to loading diary without changing the view and date
        var view = uiCalendarConfig.calendars.csCalendar.fullCalendar('getView');
        $scope.uiConfig.calendar.defaultView = view.name;
        $scope.uiConfig.calendar.defaultDate = uiCalendarConfig.calendars.csCalendar.fullCalendar('getDate').local();
        $scope.updateCalendarResources($scope.users_to_show_on_dairy);

        $scope.checkUserSkillAssociation();
        // Reset Suggested appointment Skills
        if($scope.currentView == 'suggestedAppointment'){
            if($scope.hasOwnProperty('mapInitialised') && $scope.users_to_show_on_dairy.length < 1){
                $scope.mapInitialised = false;
            }
            reset_skills_suggested_appointment()
            $scope.loadScheduleDetails();
        }
    };

    // Reset skills of Suggested Appointments
    function reset_skills_suggested_appointment(){
        if($scope.currentView == 'suggestedAppointment' && $scope.hasOwnProperty('selectedSkills')){
            let suggestion_appointment = angular.element('#suggestion_appointment').scope();
            let diary_right_bar = angular.element('#diary-right-bar').scope();
            var skill_need_reset = false;
            $.each($scope.users_to_show_on_dairy, function (key, value) {
                if(value.skills.length == 0){
                    skill_need_reset = true;
                    return false
                }
                else {
                    $.each(value.skills, function(index, temp_skill){
                        let temp_skill_matched = _.where($scope.jobSkills, { id:  temp_skill.id });
                        if(temp_skill_matched.length == 0 ){
                            skill_need_reset = true;
                            return false;
                        }
                        else{
                            return true;
                        }
                    });
                    return true
                }
            });
            if(skill_need_reset){
                suggestion_appointment.selectedSkills = [];
            }
            diary_right_bar.users_to_show_on_dairy = $scope.users_to_show_on_dairy;
        }
    }

    $scope.publishEvent = function(text, event) {
        var messageFrom = {
            userId: $rootScope.userId,
            username: $rootScope.username,
            channelId: 'commusoft_' + $rootScope.clientId + '_' + $rootScope.userId
        };
        pubnub.publish({
            channel: 'commusoft_' + $rootScope.clientId,
            message: {
                text: 'diary',
                action: text,
                event: event,
                from: messageFrom,
                channelId: 'commusoft_' + $rootScope.clientId
            }
        });
    }

    $scope.convertEvent = function(event) {
        var end = event.end;
        var editable = true;
        if(!$scope.editable) {
            editable = false;
        }
        if(event.allDay == 'fullday' && end == null) {
            end = moment(event.start).clone().add(1, 'day').startOf('day');
        }
        var newEvent = {
            id: event.id,
            title: event.title,
            engineerNotes: event.engineerNotes,
            start: event.start,
            event_start: event.start,
            end: end,
            event_end: end,
            allDay: event.allDay,
            resourceId: event.resourceId,
            isSpecialEvent: event.isSpecialEvent,
            actualTime: event.actualTime,
            duration: event.duration,
            status: event.status,
            type: event.type,
            userColor: event.userColor,
            jobColor: event.jobColor,
            typeChar: event.typeChar,
            userProfilePicture: event.userProfilePicture,
            userName: event.userName,
            otherDetails: event.otherDetails,
            editable: editable,
            durationEditable: event.durationEditable,
            grayedOut: event.grayedOut,
            redirectDetails: event.redirectDetails,
            lock_event:event.lock_event
        };
        return newEvent;
    }

    $scope.checkAndUpdateEvents = function(eventToCheck, dateIsAvailable, diaryEvent){
        var diaryObject = _.where($scope[eventToCheck], { id: diaryEvent.id });
        var diaryIdExist = diaryObject.length;
        var eventArrayModified = false;
        if(dateIsAvailable && diaryIdExist) {
            $scope.modifyEvents(diaryEvent, eventToCheck);
        } else if(!dateIsAvailable && diaryIdExist){
            var index = _.findIndex($scope[eventToCheck], { id: diaryEvent.id });
            $scope[eventToCheck].splice(index, 1);
            eventArrayModified = true;
            console.log('event removed' + diaryEvent.id);
        } else if(dateIsAvailable && !diaryIdExist){
            $scope[eventToCheck].push(diaryEvent);
            eventArrayModified = true;
            console.log('event added to' + eventToCheck + ' id ' + diaryEvent.id);
        }

        if(eventArrayModified && eventToCheck == 'events') {
            $scope.modifyEvents(diaryEvent, eventToCheck);
        }
    }

    $scope.deleteEvent = function(eventToCheck, diaryId){
        var diaryObject = _.where($scope[eventToCheck], { id: diaryId });
        if(diaryObject.length) {
            $rootScope.$broadcast('refresh:outstanding-jobs');

            var index = _.findIndex($scope[eventToCheck], { id: diaryId });
            $scope[eventToCheck].splice(index, 1);

            if(eventToCheck == 'events') {
                $scope.updateEvents();
            }
        }
    }

    $scope.handleDiaryEventEdit = function(diaryEvent) {
        var eventStart = moment(diaryEvent.start);
        var currentDateCheck = eventStart <= moment($scope.viewEnd) && eventStart >= moment($scope.viewStart);
        var nextDateCheck = eventStart <= moment($scope.nextEnd) && eventStart >= moment($scope.nextStart);
        var prevDateCheck = eventStart <= moment($scope.prevEnd) && eventStart >= moment($scope.prevStart);
        $scope.checkAndUpdateEvents('events', currentDateCheck, diaryEvent);
        $scope.checkAndUpdateEvents('nextEvents', nextDateCheck, diaryEvent);
        $scope.checkAndUpdateEvents('prevEvents', prevDateCheck, diaryEvent);
    }

    $scope.$on("event:diary-event-created", function (event, data) {
        var diaryEvent = data.event;

        if (('estimate', 'job').indexOf(diaryEvent.type) >= 0) $rootScope.$broadcast('refresh:outstanding-jobs');

        if (diaryEvent == 'bulk_insert') {
            $scope.loadAllEvents();
            return;
        }

        var eventStart = moment(diaryEvent.start);
        if(eventStart <= moment($scope.viewEnd) && eventStart >= moment($scope.viewStart)) {
            $scope.events.push(diaryEvent);
            $scope.updateEvents();
        }
        if(eventStart <= moment($scope.nextEnd) && eventStart >= moment($scope.nextStart)) {
            $scope.nextEvents.push(diaryEvent);
        }
        if(eventStart <= moment($scope.prevEnd) && eventStart >= moment($scope.prevStart)) {
            $scope.prevEvents.push(diaryEvent);
        }
    });

    $scope.$on("event:diary-event-updated", function (event, data) {
        var diaryEvent = data.event;
        $scope.handleDiaryEventEdit(diaryEvent);
    });

    $scope.$on("event:diary-event-deleted", function (event, data) {
        var diaryId = parseInt(data.id);
        $scope.deleteEvent('events', diaryId);
        $scope.deleteEvent('nextEvents', diaryId);
        $scope.deleteEvent('prevEvents', diaryId);

        $rootScope.$broadcast('refresh:outstanding-jobs');
    });

    $scope.$on("event:diary_event_status_changed", function (event, data) {
        var diaryId = parseInt(data.diaryId);
        var status = data.status;
        var statusUpdated = data.statusUpdated;
        var event = _.where($scope.events, { id: diaryId });
        var nextEvent = _.where($scope.nextEvents, { id: diaryId });
        var prevEvent = _.where($scope.prevEvents, { id: diaryId });
        if(event.length) {
            event[0].status = status;
            event[0].statusUpdated = statusUpdated;
            $scope.updateEvents();
        } else if(nextEvent.length) {
            nextEvent[0].status = status;
            nextEvent[0].statusUpdated = statusUpdated;
        } else if(prevEvent.length) {
            prevEvent[0].status = status;
            prevEvent[0].statusUpdated = statusUpdated;
        }

        if (('estimate', 'job').indexOf(event[0].type) >= 0 && ['rejected', 'aborted', 'no_access', 'cancelled'].indexOf(status.status) >= 0) $rootScope.$broadcast('refresh:outstanding-jobs');
    });

    $rootScope.$on('sidepanel:add_new_diary_event:closed', function(ev){
        uiCalendarConfig.calendars.csCalendar.fullCalendar('unselect');
        if($scope.bookingAppointmentForOutstandingJob) {
            $scope.bookingAppointmentForOutstandingJob = false;
            $scope.$broadcast('diary:set_previous_page_values', false)
        }
    });

    $scope.$on('event:refresh_diary_events', function(ev, msg){
        $scope.refreshCurrentEvents();
        if(msg && (msg.action === 'delete' || msg.action === 'cancel')) {
            $rootScope.$broadcast('refresh:outstanding-jobs');
        }
    });

    $scope.buildEvent = function(title, resourceId, start, end, actualTime, isSpecialEvent, allDay) {
        var resource = $scope.getResource(resourceId);
        var event = {
            title: title,
            start: start,
            end: end,
            allDay: allDay,
            resourceId: resourceId,
            isSpecialEvent: isSpecialEvent,
            actualTime: actualTime,
            color: resource.backgroundColor,
            borderColor: resource.borderColor
        };
        return event;
    }

    $scope.getResource = function(id) {
        var resource = {};
        for(var i = 0; i < $scope.resources.length; i++) {
            var resourceObj = $scope.resources[i];
            if(resourceObj.id == id) {
                resource = resourceObj;
                break;
            }
        }
        return resource;
    }

    $scope.saveState = function() {
        $scope.selectedUserIdAsArray = [];
        $scope.setSelectedUserIdArray();
        var columnValue = {date: moment($scope.startDate).format('YYYY-MM-DD'), colorScheme: $scope.currentColorScheme,
            view: $scope.currentView, userGroups: $scope.selectedUserGroups.toString(), users: _.toArray($scope.userDetailArray),
            viewStart:$scope.viewStart.format('YYYY-MM-DD'), viewEnd:$scope.viewEnd.format('YYYY-MM-DD')};
        $http.post(prefix + '/userColumnOrder', 'columnValue='+JSON.stringify(columnValue) + '&screenName=diary').
        success(function (data, status) {

        });
    }

    //Todo: Need to check with the back-end
    $scope.validateDiaryEvent = function(engineerId, updatedEvent) {
        var overlapping = false;
        var events = _.where($scope.events, { resourceId: engineerId });
        if(events.length) {
            var bypassStatusArray = ['rejected', 'no_access', 'aborted', 'cancelled', 'left', 'completed'];
            var selectionStart,selectionEnd,eventId;
            if(!updatedEvent) {
                if($scope.allDay) {
                    return false;
                }
                selectionStart = moment($scope.start.format());
                selectionEnd = moment($scope.end.format());
                eventId = null;
            } else {
                eventId = updatedEvent.id;
                selectionStart = moment(updatedEvent.start.format());
                if(updatedEvent.allDay || updatedEvent.isSpecialEvent) {
                    return false;
                    /*var dateTimeObj = $scope.getEventStartEndValues(selectionStart, updatedEvent.allDay);
                    selectionStart = dateTimeObj.start;
                    selectionEnd = dateTimeObj.end;*/
                } else {
                    selectionEnd = moment(updatedEvent.end.format());
                }
            }

            for(var index = 0; index < events.length; index++) {
                var event = events[index];
                var doNotCheck = event.allDay || event.isSpecialEvent;
                if(bypassStatusArray.indexOf(event.status.status) == -1 && event.id != eventId && !doNotCheck) {
                    var eventStart = moment(event.start);
                    var eventEnd = moment(event.end);
                    overlapping = $scope.isOverlapping(selectionStart, selectionEnd, eventStart, eventEnd) || $scope.isOverlapping(eventStart, eventEnd, selectionStart, selectionEnd);
                    if (overlapping) {
                        break;
                    }
                }
            }
        }
        return overlapping;
    }

    $scope.isOverlapping = function(start, end, startToCheck, endToCheck) {
        return (start >= startToCheck && start < endToCheck) || (end > startToCheck && end <= endToCheck);
    }

    $scope.changeColourScheme = function(scheme) {
        if($scope.currentColorScheme != scheme) {
            $scope.currentColorScheme = scheme;
            $scope.updateEvents();
            $scope.saveState();
        }
    }

    $scope.getEventStartEndValues = function(start, allDay) {
        var startOfDay = start.clone().startOf('day');
        var startDateTime = (allDay == 'pm') ? startOfDay.clone().add(12, 'h') : startOfDay;
        var timeToAdd = (allDay == 'fullday') ? 24 : 12;
        var endDateTime = startDateTime.clone().add(timeToAdd, 'h');
        return {start: startDateTime, end: endDateTime};
    }

    $scope.show_user_group_suggestion = false;

    $scope.showUserGroupPopSuggestionUp = function showUserGroupPopSuggestionUp($event) {
        $scope.show_user_group_suggestion = true;
        csPopUpPosition.init($event);
    }

    $scope.hideUserGroupPopSuggestionUp = function hideUserGroupPopSuggestionUp() {
        $scope.show_user_group_suggestion = false;
    }

    $scope.handleUsersFilterOnDiary = function handleUsersFilterOnDiary() {
        let diary_right_bar = angular.element('#diary-right-bar').scope();
        if($scope.currentView == 'suggestedAppointment' && diary_right_bar && diary_right_bar.hasOwnProperty('users_to_show_on_dairy')) {
            let right_bar_users = diary_right_bar.users_to_show_on_dairy
            let users_loopPromises = [];
            angular.forEach($scope.users,function (users, index) {
                let deferred = $q.defer();
                let user_matched = _.where(right_bar_users, { id: users.id });
                if(user_matched.length){
                    users.checked = true;
                    users.shown_on_diary = true
                }
                else {
                    users.checked = false;
                    users.shown_on_diary = false;
                }
                users_loopPromises.push(deferred.promise);
                $scope.users[index] = users;
                deferred.resolve();
            });
            $q.all(users_loopPromises).then(function () {
                filterUsersOnDiary.init($scope.currentView, $scope.users, diary_right_bar.users_to_show_on_dairy);
            });
        }
        else{
            filterUsersOnDiary.init($scope.currentView, $scope.users, $scope.users_to_show_on_diary);
        }
    }

    $rootScope.$on('users_on_diary:updated', function(e, users_list) {
        $scope.save_state = true;
        $scope.users = users_list;
        $scope.changeResources();
        $scope.updateAllUsersWithSelection();

        // Reset Suggested appointment Skills
        reset_skills_suggested_appointment()
    });

    $scope.handleSkillsOnDiary = function handleUsersFilterOnDiary() {
        filterSkillsOnDiary.init($scope.currentView, $scope.skills_data, $scope.users);
    }

    $rootScope.$on('skills_on_diary:updated', function(e, data) {
        $scope.users = data.users;
        $scope.save_state = false;
        $scope.selectedSkills = data.selectedSkills;
        $scope.skills_data = data.skills;
        $scope.users_to_show_on_dairy = updateShifts(_.where($scope.users, { shown_on_diary: true }));
        $scope.setSelectedUserIdArray();
        $scope.updateAllUsersWithSelection();

        // maintains the view
        var view = uiCalendarConfig.calendars.csCalendar.fullCalendar('getView');
        $scope.uiConfig.calendar.defaultView = view.name;
        $scope.uiConfig.calendar.defaultDate = uiCalendarConfig.calendars.csCalendar.fullCalendar('getDate').local();

        if(!$scope.$$phase) {
            $scope.$apply(function() {
                $scope.updateCalendarResources($scope.users_to_show_on_dairy);
            });
        }else {
            $scope.uiConfig.calendar.resources = $scope.users_to_show_on_dairy;
        }

        // todo: resize doesn't work when users are filtered and then browser height is resized and then users are filtered again
        resizeCalendar();
    });

    $scope.printJobSheet = function(type){
        if($scope.currentEvents && $scope.currentEvents.length) {
            var negativeDiaryStatusText = ['rejected', 'no_access', 'aborted', 'cancelled'];
            var jobEvents = _.reject(_.where($scope.currentEvents, {type: 'job'}),function(eve){ return negativeDiaryStatusText.indexOf(eve.status.status) > 0});
            var estimateEvents = _.reject(_.where($scope.currentEvents, {type: 'estimate'}),function(eve){ return negativeDiaryStatusText.indexOf(eve.status.status) > 0});
            var opportunityEvents = _.reject(_.where($scope.currentEvents, {type: 'opportunity'}),function(eve){ return negativeDiaryStatusText.indexOf(eve.status.status) > 0});
            if(!$scope.selectedUserIdAsArray.length) {
                $scope.setSelectedUserIdArray();
            }
            var start = moment($scope.viewStart).format('YYYY-MM-DD');
            var end = moment($scope.viewEnd).format('YYYY-MM-DD');

            if(type == 'job_sheet'){
                var urlToOpen = prefix + '/diary/job_sheet/print?'+'current=' + start + '|' + end + '&users=' + $scope.selectedUserIdAsArray.toString();
            }else if(type == 'summary'){
                urlToOpen = prefix + '/diary/job_summary_sheets?'+'current=' + start + '|' + end + '&users=' + $scope.selectedUserIdAsArray.toString();
            }
            if((estimateEvents.length + jobEvents.length + opportunityEvents.length) > 10){
                $('#loading-indicator').show();
                $scope.flashMessage = $translate('Report.print.message');
                $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
                $http.post(urlToOpen).
                success(function (data, status) {
                   if(status == 200) {
                        $('#loading-indicator').hide();
                    }
                });
            }
            else if (estimateEvents.length || jobEvents.length || opportunityEvents.length) {
                $window.open(urlToOpen, '_blank');
                return false;
            }
        }else{
            warningModal.show('Diary.job.sheet.warning.message', 'Print.job.sheets', 'diary_job_sheet_message');
        }
    }

    $scope.$on('tabCustomer:successMessage', function (event, data) {
        $rootScope.successMessage = data;
        toastBox.show($rootScope.successMessage, 5000);

    });

    $scope.downloadJobSheetWarningModal = function(){
        if($scope.currentEvents && $scope.currentEvents.length) {
            var jobEvents = _.where($scope.currentEvents, {type: 'job'});
            var estimateEvents = _.where($scope.currentEvents, {type: 'estimate'});
            if (estimateEvents.length || jobEvents.length) {
                var link = document.createElement("a");
                link.download = 'diary_events.xls';
                link.target = '_self';
                link.href = prefix + '/diary/events/download';
                link.click();
                return false;
            }
        }
        warningModal.show('Diary.job.sheet.download.warning.message', 'Download.job.sheets', 'diary_download_job_sheet_message');
    }

    /************************ Added for map usage ******************************* */
    // Broadcasting estimate/job details for booking appointment from estimate/job screen
    $scope.broadcastEstimateJobDetailsFromPrevious = function () {

        if ($scope.estimateJobDetails) {
            if ($diaryMapScope.diaryMapRenderObservable.stages.indexOf('INITIATED') >= 0) $diaryMapScope.handleOutstandingJobSelection($scope.estimateJobDetails, 'FROM_PREVIOUS');
            // Wait for map initialization
            else $diaryMapScope.diaryMapRenderObservable.promise.then(null, null, function (message) {
                if (message === 'INITIATED') $diaryMapScope.handleOutstandingJobSelection($scope.estimateJobDetails, 'FROM_PREVIOUS');
            });
        }
    };

    $scope.groupUsersEvents = function (currentEvents) {
        var uE = {};

        currentEvents.forEach(function (de) {
            if (!uE[de.resourceId]) uE[de.resourceId] = [de];
            else uE[de.resourceId].push(de);
        });

        angular.forEach(uE, function (events, uId) {
            uE[uId] = events.sort(function (a, b) {
                var aSt = a.statusUpdated ? moment(a.statusUpdated, 'ddd Do MMM YYYY HH:mm A') : moment('0'),
                    bSt = b.statusUpdated ? moment(b.statusUpdated, 'ddd Do MMM YYYY HH:mm A') : moment('0');

                if (aSt.isSame(bSt) || aSt.isAfter(bSt)) return -1;
                else if (aSt.isBefore(bSt)) return 1;
                else return 0;
            });
        });

        $diaryMapScope.usersEvents = uE;
        return uE;
    };

    var completedStatus = 'no_access,aborted,left,cancelled,completed'.split(',');
    $scope.eventsAfterDiarySetting = [];
    /**
     * To get last successful job for an Engineer
     * @param events
     * @returns {Array}
     */
    $scope.getLastJob = function(events) {
        var foundLastJob = false;
        if(events && events.length) {
            for(var i = events.length-1; i >= 0; i--) {
                var status = events[i].status.status;
                if(completedStatus.indexOf(status) != -1 && !foundLastJob) {
                    $scope.eventsAfterDiarySetting.push(events[i]);
                    foundLastJob = true;
                }
                if(completedStatus.indexOf(status) == -1) {
                    $scope.eventsAfterDiarySetting.push(events[i]);
                }
            }
        }
    }

    /**
     * To Remove past event from the given list
     * @param events
     * @returns {Array}
     */
    $scope.removePastJobs = function(events) {
        if(events && events.length) {
            for(var i = 0; i < events.length; i++) {
                var status = events[i].status.status;
                if(completedStatus.indexOf(status) == -1) {
                    $scope.eventsAfterDiarySetting.push(events[i]);
                }
            }
        }
    }

    /**
     * To get event list resource wise based on past job setting
     * @param userEvents
     * @returns {*}
     */
    $scope.getEventsToDisplayInMap = function(userEvents) {
        $scope.eventsAfterDiarySetting = [];

        if(!_.isEmpty(userEvents)) {
            for (var resourceId in userEvents) {
                var userEvent = userEvents[resourceId];
                if($scope.pastJobsSetting == 2) {
                    $scope.removePastJobs(userEvent);
                } else {
                    $scope.getLastJob(userEvent);
                }
            }
        }
    }

    $scope.$watch('currentEvents', function (newValue, oldValue) {
        // if (JSON.stringify(newValue) !== JSON.stringify(oldValue))
        $scope.groupUsersEvents(newValue);

        if ($diaryMapScope.diaryMapRenderObservable.stages.indexOf('INITIATED') >= 0) $scope.$broadcast('currentEventsChanged', newValue);
        // Wait for map initialization
        else $diaryMapScope.diaryMapRenderObservable.promise.then(null, null, function (message) {
            if(message === 'INITIATED') $scope.$broadcast('currentEventsChanged', newValue);
        });

    });

    $scope.updateCalendarResources = function (newResources) {
        if ($scope.uiConfig.calendar.blankViews.indexOf($('#cs-calendar').fullCalendar('getView').name) < 0)
            $scope.uiConfig.calendar.resources = newResources;
        else {
            $scope.loadAllEvents();
            $scope.saveState();
        }
    };

    $scope.$on('dmJobEstimateMarker:singleClick', function (event, eventId) {
        $scope.show_event_pop_up = false;
        $scope.eventSingleClick(eventId);
    });

    $scope.$on('dmJobEstimateMarker:doubleClick', function (event, eventId) {
        $scope.show_event_pop_up = false;
        $scope.eventDoubleClick(eventId);
    });

    $scope.$on('dmJobEstimateMarker:eventEnter', function (event, data) {
        $scope.show_event_pop_up = true;
        $scope.getPopUpData(data[0], data[1]);
    });

    $scope.$on('dmJobEstimateMarker:eventLeave', function (event, data) {
        $scope.show_event_pop_up = false;
    });

    $scope.$on('dmUserMarker:enter', function (event, data){
        $scope.userMarkerPopupData = data[1];
        csPopUpPosition.init(data[0]);
        $timeout(function () {
            $scope.showUserMarkerPopup = true;
        });
    });

    $scope.$on('dmUserMarker:leave', function (event, data){
        $scope.showUserMarkerPopup = false;
    });

    $scope.$on('dmUserMarker:singleClick', function (event, userId) {
        $scope.showUserMarkerPopup = false;
    });

    $scope.$on('event:job-created', function (event, data) {
        $rootScope.$broadcast('refresh:outstanding-jobs');
    });

    $scope.$on('event:job-updated', function (event, data) {
        if(!data.event_booked) $rootScope.$broadcast('refresh:outstanding-jobs');
    });

    $scope.$on('event:job-deleted', function (event, data) {
        data = data.event;
        $rootScope.$broadcast('refresh:outstanding-jobs');
    });

    // From add diary event panel
    $scope.$on('removeOutstandingJob', function (e, message) {
        var eventInfo = message.eventInfo;

        $rootScope.$broadcast('refresh:outstanding-jobs');
    });
    // Function to update calendar toolbar text
    function update_calendar_toolbar_text() {
        if(Object.keys(uiCalendarConfig.calendars).length > 0 && ($scope.currentView == 'diaryMap' || $scope.currentView == 'agendaDay' || $scope.currentView == 'timelineDay')) {
            var calendarDate = uiCalendarConfig.calendars.csCalendar.fullCalendar('getDate');
            if (calendarDate && moment.isMoment(calendarDate) && $scope.todayStr == calendarDate.local()._d.toDateString()) {
                var label = $('.fc-left h2').text()
                $('.fc-left h2').text('Today('+label+')')
            }
            else if(!calendarDate || !moment.isMoment(calendarDate)){
                if ($scope.todayStr == $scope.defaultDate._d.toDateString()) {
                    var label = $('.fc-left h2').text()
                    $('.fc-left h2').text('Today('+label+')')
                }
            }
        }
        else if(Object.keys(uiCalendarConfig.calendars).length == 0 && ($scope.currentView == 'diaryMap' || $scope.currentView == 'agendaDay' || $scope.currentView == 'timelineDay')){
            if ($scope.todayStr == $scope.defaultDate._d.toDateString()) {
                var label = $('.fc-left h2').text()
                $('.fc-left h2').text('Today('+label+')')
            }
        }

    }

    /** ****************************************************************** */
}

var DiaryResolver = {
    diaryObj: function ($q, $http, $stateParams, $state, PubNub, $rootScope, cacheBust, prefix, adminPrefix) {
        if (typeof(this.templateUrl) == 'string') {
            return;
        }
        var deferred = $q.defer();
        $http.get(this.templateUrl($stateParams)).success(function (data, status, headersObj) {
            var headers = headersObj();
            SetRootScopeValues($rootScope, headers, PubNub, cacheBust, $http, prefix, adminPrefix, $state);
            if (data.location && data.location == 'logout') {
                $rootScope.$broadcast('chat:unregister');
                $state.transitionTo('home');
            } else if (data.location && data.location.endsWith("/")) {
                //$state.transitionTo('home');
            } else {
                deferred.resolve(data);
            }
        });
        return deferred.promise;
    }
}


function DiaryDateTimeInputs($scope, $timeout) {
    $scope.tryCount = 0;
    $scope.setDatePickerValue = function() {
        if(!$scope.dateUpdated) {
            $scope.$broadcast('event:change_default_selected_date', { date: { date: $scope.startDateTime.toDate() } });
            $timeout(function () {
                if($scope.tryCount >= 20) {
                    console.log('There is some issue in loading the date picker view');
                }
                if(!$scope.dateUpdated && $scope.tryCount < 20) {
                    $scope.tryCount ++;
                    $scope.setDatePickerValue();
                }
            }, 100);
        }
    }
    $scope.initializeValues = function(option) {
        $scope.tryCount = 0;
        $scope.setJobTime = false;
        $scope.jobEndDateError = false;
        $scope.startDateTime = (option == 'format') ? moment($scope.start.format()) : $scope.start;
        $scope.endDateTime = (option == 'format') ? moment($scope.end.format()) : $scope.end;
        if (option == 'setDuration'){
            $scope.durationWarning = false;
            $scope.setJobTime = true;
            var durations = parseInt($scope.diary_ctrl_scope.jobDurationSettings.jobDuration);
            $scope.jobStartDateTime = moment().add(15 - (moment().minute() % 15), "minutes");
            $scope.demodate = $scope.jobStartDateTime;
            $scope.jobEndDateTime = moment($scope.demodate).add(durations, "minutes");

        }
        $scope.isAllDay = ($scope.all_day && $scope.all_day != false);
        $scope.allDayValue = ($scope.all_day) ? $scope.all_day : 'specific';
        $scope.dateTimeError = false;
        $scope.endDateError = false;
        $scope.serviceWindowError = false;
        $scope.dateError = false;
        $scope.diaryDate = $scope.startDateTime;
        $scope.selectedDate = $scope.startDateTime;
        $scope.dateUpdated = false;

        var namesToChange = ['allDayValue', 'endDateTime', 'startDateTime', 'endDateError', 'dateTimeError', 'dateError', 'disableStartDateTime', 'serviceWindowError'];

        if($scope.isAllDay){
            $scope.changeDateTime($scope.isAllDay);
        }

        for(var index = 0; index < namesToChange.length; index ++) {
            var name = namesToChange[index];
            $scope.updateChildValues(name, $scope[name]);
        }

        if($scope.disableStartDateTime) {
            $scope.$broadcast('disableStartDateTime', {name: 'startDateTime', value: true});
        }

        $scope.setDatePickerValue();
    }

    $scope.$on("datepicker:datepicker_selected", function (event, message) {
        if(message.name == 'diaryDate') {
            $scope.selectedDate = moment(message.date);
            $scope.changeStartEndDates('startDateTime', moment(message.date));
            $scope.changeStartEndDates('endDateTime', moment(message.date));
            $scope.changeStartEndDates('jobStartDateTime', moment(message.date));
            $scope.changeStartEndDates('jobEndDateTime', moment(message.date));
            $scope.$broadcast('event:change-date-value', {date: moment(message.date)});
        }
    });

    $scope.$on("datepicker:date_updated", function () {
        //Todo: This needs to be fixed later, this is to solve the date update issue because it is taking some time to compile
        if($scope.tryCount > 1) {
            $scope.dateUpdated = true;
        }
    });

    $scope.$on("datepicker:handle_diary_validation", function () {
        $scope.handleDiaryValidation();
    });

    $scope.changeStartEndDates = function(name, selectedDate) {
        var temp = moment($scope[name]);
        selectedDate = (name === 'endDateTime' && temp.format('HH-mm-ss') == '00-00-00' ) ?  moment(selectedDate).add(1,'day') : selectedDate ;
        $scope[name] = selectedDate.clone().hours(temp.hours()).minutes(temp.minutes());
        $scope.updateChildValues(name, $scope[name]);
    }

    $scope.changeDateTime = function(isAllDay) {
        $scope.isAllDay = isAllDay;
        if(isAllDay) {
            let type_event = 3;
            if($scope.allDayValue == 'fullday') {
                type_event = 1;
                $scope.startDateTime = moment($scope.startDateTime).startOf('day');
                $scope.endDateTime = moment($scope.startDateTime).add(1, 'day');
            } else if($scope.allDayValue == 'am') {
                type_event = 2;
                $scope.startDateTime = moment($scope.startDateTime).startOf('day');
                $scope.endDateTime = moment($scope.startDateTime).add(12, 'hours');
            } else {
                $scope.startDateTime = moment($scope.startDateTime).startOf('day').add(12, 'hours');
                $scope.endDateTime = moment($scope.startDateTime).add(12, 'hours');
            }
            $scope.dateTimeError = false;
            if($scope.eventisLocked && $scope.eventisLocked== 4){
                let old_service_window_id = $scope.service_windows_details.id;
                let event_details = {time:type_event}
                let new_service_window_id = findServiceWindowId(event_details);
                if(old_service_window_id == new_service_window_id){
                    $scope.serviceWindowError = false;
                }
                else {
                    $scope.serviceWindowError = true;
                }
            }
        } else {
            if($scope.all_day) {
                $scope.startDateTime = moment($scope.startDateTime).startOf('day').add(8, 'hours');
                $scope.endDateTime = moment($scope.startDateTime).add(1, 'hour');
            } else {
                $scope.startDateTime = moment($scope.start);
                $scope.endDateTime = moment($scope.end);
            }
            $scope.handleDiaryValidation();
        }
        $scope.updateChildValues('serviceWindowError',  $scope.serviceWindowError);
        $scope.updateChildValues('dateTimeError',  $scope.dateTimeError);
        $scope.updateChildValues('startDateTime', $scope.startDateTime);
        $scope.updateChildValues('endDateTime', $scope.endDateTime);
        $scope.special_event_durations = $scope.timeDiffArrayData = getTimeDifferenceArrayData($scope.startDateTime, $scope.endDateTime, $scope.isAllDay);
    }

    $scope.changeEventDateTime = function(name, value) {
        if(!value) {
            $scope.updateChildValues(name, $scope[name]);
            return;
        }

        $scope[name] = moment(value);
        var adjustedValue = $scope.handleTimeChange(name, value);
        $scope.handleDiaryValidation();
    }
    $scope.handleDiaryValidation = function() {
        var startDateTime = moment($scope.startDateTime);
        var endDateTime = (moment($scope.endDateTime).format('HH-mm') == '00-00') ? moment($scope.endDateTime).subtract(1, 'minutes') : moment($scope.endDateTime);

        if(endDateTime.format('YYYY-MM-DD') != startDateTime.format('YYYY-MM-DD') || endDateTime <= startDateTime) {
            $scope.dateTimeError = true;
            $scope.serviceWindowError = false;
            if(endDateTime <= startDateTime) {
                $scope.updateChildValues('endDateError', true);
                $scope.updateChildValues('serviceWindowError', false);
                $scope.updateChildValues('dateError', false);
            } else {
                $scope.updateChildValues('endDateError', false);
                $scope.updateChildValues('serviceWindowError', false);
                $scope.updateChildValues('dateError', true);
            }
        }
        else if($scope.eventisLocked && $scope.eventisLocked== 4){ // 4 is lock by service window

            $scope.dateTimeError = false;
            /*
            Todo locked by service window validations
            */
            let event_details = {'start':startDateTime, 'time':0};
            let old_service_window_id = $scope.service_windows_details.id;
            let new_service_window_id = findServiceWindowId(event_details);
            if(old_service_window_id == new_service_window_id){
                $scope.updateChildValues('serviceWindowError', false);
                $scope.serviceWindowError = false;
                $scope.updateChildValues('endDateError', false);
                $scope.updateChildValues('dateError', false);
            }
            else {
                $scope.serviceWindowError = true;
                $scope.updateChildValues('serviceWindowError', true);
                $scope.updateChildValues('endDateError', false);
                $scope.updateChildValues('dateError', false);
            }
        }
        else {
            $scope.dateTimeError = false;
            $scope.serviceWindowError = false;
            $scope.updateChildValues('serviceWindowError', false);
            $scope.updateChildValues('endDateError', false);
            $scope.updateChildValues('dateError', false);
            $scope.special_event_durations = $scope.timeDiffArrayData = getTimeDifferenceArrayData(startDateTime, endDateTime, $scope.isAllDay);
            $scope.handleSpecialEventValues();
        }
        var diff = parseInt(moment.duration(endDateTime.diff(startDateTime)).asMinutes())+1;
        if($scope.jobDurationTime != undefined)
            $scope.durationWarning = ( $scope.jobDurationTime != diff) ? true : false ;
        if($scope.jobDurationSettings != undefined){
            diff--;
            $scope.durationWarning = ($scope.jobDurationSettings.jobDuration != diff) ? true : false;
        }
        if($scope.diary_ctrl_scope != undefined) {
            var timeDiff = parseInt(moment.duration($scope.jobEndDateTime.diff($scope.jobStartDateTime)).asMinutes());
            if ($scope.diary_ctrl_scope.jobDurationSettings != null) {
                $scope.jobEndDateError =  ($scope.jobEndDateTime <= $scope.jobStartDateTime) ? true : false ;
                $scope.durationWarning = ($scope.diary_ctrl_scope.jobDurationSettings.jobDuration != timeDiff ) ? true : false ;
            }
        }


    }

    $scope.updateParentValues = function(name, value) {
        $scope[name] = value;
        if(name == 'allDayValue') {
            if(value == 'specific') {
                $scope.isAllDay = false;
            } else {
                $scope.isAllDay = true;
            }
            $scope.changeDateTime($scope.isAllDay);
            $scope.handleSpecialEventValues();
        }
    }

    $scope.updateChildValues = function(name, value) {
        var dateTimeScope = angular.element('#diary_date_time_inputs').scope();
        if(dateTimeScope) {
            dateTimeScope[name] = value;
        }
    }

    $scope.handleTimeChange = function(name, date) {
        var temp = moment(date),tempStartDate = moment($scope.startDateTime).startOf('day');
        if(name === 'endDateTime')
        {
            if(temp.format("HH-mm") === "00-00"){
                $scope.endDateTime = tempStartDate.add(1,'day');
            }else{
                $scope.endDateTime = tempStartDate.clone().hours(temp.hours()).minutes(temp.minutes());
            }
        }
        return moment($scope.selectedDate).clone().hours(temp.hours()).minutes(temp.minutes());
    };
    // Todo function handle find service windows for edit screen
    function findServiceWindowId(event_details) {
        let service_window_id = null;
        let event_details_time = event_details.time;
        if(event_details_time == 0){
            $.each($scope.service_windows, function(index, value){
                let current_date = moment().format('YYYY-MM-DD');
                let service_window_startTime = moment(current_date+' '+ moment(value['startTime']['date']).format('HH:mm:ss'));
                let service_window_endTime = moment(current_date+' '+ moment(value['endTime']['date']).format('HH:mm:ss'));
                let current_date_time = moment(current_date+' '+ event_details.start.format('HH:mm:ss'));
                if((current_date_time <= service_window_endTime && current_date_time >= service_window_startTime)){
                    service_window_id = value.id
                    return false;
                }
                else {
                    return true;
                }
            })
        }
        else{
            if(event_details_time == 1){ // 1 is event full day
                service_window_id = _.findWhere($scope.service_windows, { 'allDayEvent': true }).id;
            }
            else if(event_details_time == 2){ // 2 is morning event
                service_window_id = _.findWhere($scope.service_windows, { 'amEvent': true }).id;
            }
            else if(event_details_time == 3){ // 3 is afternoon event
                service_window_id = _.findWhere($scope.service_windows, { 'pmEvent': true }).id;
            }
        }

        return service_window_id;
    }
}

function getTimeDifferenceArrayData(start, end, allDay) {
    var timeDiffArrayData = [];
    if(!allDay) {
        var timeDiff = moment(end).diff(moment(start))/1000/60;
        var slotMinutes = 30;
        if(timeDiff > slotMinutes) {
            for(var count = 1; count < timeDiff/slotMinutes; count++) {
                var duration = count*slotMinutes;
                var durationStr = '';
                var hours = Math.floor(duration / 60);
                var minutes = duration % 60;
                if(hours > 0) {
                    durationStr += hours + ' hour';
                }
                if(minutes > 0) {
                    durationStr += (durationStr != '') ? ' ' : '';
                    durationStr += minutes + ' mins';
                }
                timeDiffArrayData.push({'value' : duration, 'description':durationStr});
            }
        }
    }
    return timeDiffArrayData;
}
