import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';

//--
// Utilities
import {
    ajsScopeProvider,
    ajsStateProvider,
    colorSelectorProvider,
    confirmationBoxHelperProvider,
    translateBoxHelperProvider,
    emailServiceProvider,
    redirectScreenProvider,
    ajsCompileProvider
} from './ajs-upgraded-providers';
import { CsToastBoxService } from './utility-services/cs-toast-box.service';
import { ContractDataService } from './utility-services/contract-data.service';
// import { CountriesService } from "@app/services/utility-services/countries.service";

// Http
import { ContractTemplatesService } from './http-services/contract-templates.service';
import { SystemSettingsService } from './http-services/system-settings.service';
import { SettingsInvoiceCategoriesService } from './http-services/settings-invoice-categories.service';
import { JobsSlaService } from './http-services/jobs-sla.service'
import { SettingsPpmService } from './http-services/settings-ppm.service'
import { CustomerContractsService } from './http-services/customer-contracts.service'
import { CcWhatsIncludedPPMService } from './http-services/cc-whats-included-ppm.service';
import { CommonService } from "@app/services/http-services/common.service";
import { CustomerInvoicesService } from './http-services/customer-invoices.service';
import {AddEditCertificateService} from "@app/services/http-services/add-edit-certificate.service";
import {TrackerService} from "@app/services/http-services/tracker-service";

// HandsontableService
import { CustomerInvoiceService } from './handsontable-services/customer-invoice.service';
import { CsVersionService } from './utility-services/cs-version.service';
import {CurrencyPipe} from "@app/pipes";
import {SessionLogService} from "@app/services/utility-services/session-log.service";

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    // AngularJs services
    ajsScopeProvider,
    ajsStateProvider,
    colorSelectorProvider,
    confirmationBoxHelperProvider,
    translateBoxHelperProvider,
    emailServiceProvider,
      redirectScreenProvider,
      ajsCompileProvider,

    // Angular services
    // Utilities
    CsToastBoxService,
    ContractDataService,
   // CountriesService,

    // Http
    ContractTemplatesService,
    SystemSettingsService,
    SettingsInvoiceCategoriesService,
    JobsSlaService,
    SettingsPpmService,
    CustomerContractsService,
    CcWhatsIncludedPPMService,
    CommonService,
    CustomerInvoicesService,

    // Handsontable service
    CustomerInvoiceService,
    AddEditCertificateService,
      CurrencyPipe,
      CsVersionService,
    SessionLogService,
    TrackerService
  ],
  exports: [
  ]
})
export class ServicesModule { }
