import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from "@app/interfaces";
import * as moment from "moment";
import {TimelineDatePipe} from '@app/pipes/timeline-date.pipe';
import {JobsTimelineData} from '@app/interfaces/jobs-tab';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TimelineService extends InterfaceService {

    private triggerEvent = new BehaviorSubject(null);
    triggerEvent$ = this.triggerEvent.asObservable();

    private vehicleSearchActivity = new BehaviorSubject('');
    vehicleSearchActivity$ = this.vehicleSearchActivity.asObservable();

    private JobsTimelineData: JobsTimelineData;

    private reloadTimelineListData = new BehaviorSubject('');
    reloadTimelineListData$ = this.reloadTimelineListData.asObservable();

    fileTypeGroups: any = {
        image: ['jpg', 'png', 'gif', 'jpeg','PNG','JPEG','JPG','GIF'],
        pdf: ['pdf'],
        csv: ['csv'],
        word_document: ['doc', 'docx'],
        excel: ['xls', 'xlsx', 'ods', 'xlr'],
        audio: ['mp3', 'mpa', 'ogg', 'mid', 'midi'],
        video: ['avi', 'flv', 'mov', 'mp4', 'mpg', 'mpeg'],
        compressed: ['7z', 'rar', 'zip', 'tar.gz'],
        power_point: ['odp', 'pps', 'ppt', 'pptx'],
    };

    constructor(private http: HttpClient,
                private timelineDateTime: TimelineDatePipe) {
        super();
    }

    getTimelineData(screen, primaryId, filters: object, portalAccess) {
        return this.http.get(this.getApiUrl(`shared/getTimelineData/${screen}/${primaryId}?portalAccess=${portalAccess}`), filters);
    }

    checkTimeLineHeaderDate(date) {
        let value = moment(date).calendar(null, {
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]'
        });
        if (value == 'Today' || value == 'Tomorrow' || value == 'Yesterday') {
            return value
        } else {
            return null
        }
    }

    convertTimeLineHeaderDate(date) {
        return this.timelineDateTime.transform(date, 'date' );
    }

    convertTimeLineDateTime(date) {
        return this.timelineDateTime.transform(date, 'datetime' );
    }

    getDateDifference(date) {
        let now = moment(new Date());
        let fromDate = moment(date);
        let duration = moment.duration(fromDate.diff(now));
        return duration.humanize(true);
    }

    diaryDateTimeRange(fromDateTime, toDateTime, allDay, isSpecialEvent, duration) {
        fromDateTime = moment.utc(fromDateTime);
        toDateTime = moment.utc(toDateTime);
        let value = '';
        if(allDay === false) {
            if(isSpecialEvent) {
                value = this.timelineDateTime.transform(fromDateTime, 'date' ) + ' ' + duration + ' (' + this.timelineDateTime.transform(fromDateTime, 'diarytime' ) + ' - ' + this.timelineDateTime.transform(toDateTime,  'diarytime' ) + ')';
            } else {
                value = this.timelineDateTime.transform(fromDateTime, 'date' ) + ' (' + this.timelineDateTime.transform(fromDateTime, 'diarytime' ) + ' - ' + this.timelineDateTime.transform(toDateTime, 'diarytime' ) + ')';
            }
        } else {
            value = this.timelineDateTime.transform(fromDateTime, 'date' ) + ' ' + allDay;
        }
        return value;
    }

    getFileGroup(fileType) {
        let fileGroup = 'unknown';
        fileType = fileType.toLowerCase(); // Convert fileType to lowercase
        for (let key in this.fileTypeGroups) {
            if(this.fileTypeGroups.hasOwnProperty(key)) {
                let value = this.fileTypeGroups[key];
                if(value.indexOf(fileType) != -1) {
                    fileGroup = key;
                    break;
                }
            }
        }
        return fileGroup;
    }

    convertTimeLineScheduledDateTime(date) {
        date = moment.utc(date)
        return this.timelineDateTime.transform(date, 'datetime' );
    }

    getJobData(): JobsTimelineData {
        return this.JobsTimelineData;
    }

    setJobData(data: JobsTimelineData) {
        this.JobsTimelineData = data;
    }

    getVehicleTimeline(id) {
        return this.http.get(this.getFleetApiUrl(`${id}` + `/getVehicleTimeline`));
    }

    loadVehicleTimelineData() {
        this.triggerEvent.next(true);
    }

    timelineSearchActivity(data){
        this.vehicleSearchActivity.next(data)
    }


    saveFilesData(jobId, files, shareOnMobile, deleteIds?, id?) {
        const formData: any = new FormData();
        if (files != undefined) {
            for (var i = 0; i < files.length; i++) {
                formData.append("file" + i, files[i]);
            }
        }
        formData.append("shareOnMobile", shareOnMobile)
        if (deleteIds) {
            formData.append("deleteIds", deleteIds)
        }
        if (id) {
            formData.append("id", id)
        }
        let url = this.getApiUrl(`upload_job_files/${jobId}`)
        return this.http.post(url, formData);
    }

    getJobStatusDetailsNew(jobId:number): Observable<any>{
        return this.http.get(this.getApiUrl(`job_status_details/${jobId}`));
    }

    setRefreshTimelineActivityList(data) {
        this.reloadTimelineListData.next(data)
    }

}
