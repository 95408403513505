import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { InterfaceService } from "@app/interfaces";
import { environment } from "../../../environments/environment";
var WorkflowhttpService = /** @class */ (function (_super) {
    tslib_1.__extends(WorkflowhttpService, _super);
    function WorkflowhttpService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.workflowEventTrigger = new Subject();
        _this.workflowEventTrigger$ = _this.workflowEventTrigger.asObservable();
        _this.apiBasePath = environment.apiHost + environment.apiBasePath;
        _this.updateCustomHeader = new Subject();
        _this.updateCustomHeader$ = _this.updateCustomHeader.asObservable();
        _this.sequenceViewTrigger = new Subject();
        _this.sequenceViewTrigger$ = _this.sequenceViewTrigger.asObservable();
        return _this;
    }
    WorkflowhttpService.prototype.triggerSequenceView = function (content) {
        if (content === void 0) { content = {}; }
        this.sequenceViewTrigger.next(content);
    };
    WorkflowhttpService.prototype.destroyWorkflowEventTrigger = function () {
        this.workflowEventTrigger.next();
        this.workflowEventTrigger.unsubscribe();
    };
    WorkflowhttpService.prototype.triggerEvent = function (content) {
        this.workflowEventTrigger.next(content);
    };
    WorkflowhttpService.prototype.triggerCustomHeaderEvent = function (content) {
        this.updateCustomHeader.next(content);
    };
    WorkflowhttpService.prototype.getWorkflowMarketplaceMetadata = function (params) {
        return this.http
            .get(this.getApiUrl("system_settings/workflow_marketplace", params));
    };
    WorkflowhttpService.prototype.enableDisabledAction = function (params, actionType) {
        return this.http
            .post(this.getApiUrl("system_settings/workflow_marketplace/" + params['workflowMarketplace']['id'] + "/" + actionType), params);
    };
    WorkflowhttpService.prototype.getObjectFields = function (params, id) {
        var queryParams = {};
        if (id) {
            queryParams['id'] = id;
        }
        return this.http
            .get(this.getApiUrl("system_settings/workflow_setup/fields/" + params['objectName'], queryParams));
    };
    WorkflowhttpService.prototype.getWorkflowSetupMetadata = function (params) {
        return this.http
            .get(this.getApiUrl("system_settings/workflow_setup", params));
    };
    WorkflowhttpService.prototype.addEditWorkflowSetup = function (data, id) {
        if (id === 0) {
            return this.http.post(this.getApiUrl("system_settings/workflow_setup/add"), JSON.stringify(data));
        }
        else {
            return this.http.post(this.getApiUrl("system_settings/workflow_setup/edit/" + id), JSON.stringify(data));
        }
    };
    WorkflowhttpService.prototype.updateXml = function (data, id) {
        return this.http.post(this.getApiUrl("system_settings/workflow_setup/update/xml/" + id), JSON.stringify(data));
    };
    WorkflowhttpService.prototype.begainDelete = function (id, type) {
        return this.http
            .get(this.getApiUrl("system_settings/workflow_setup/begin-delete/" + id + "?type=" + type));
    };
    WorkflowhttpService.prototype.deleteWorkflowSetupRequest = function (id, params) {
        return this.http
            .post(this.getApiUrl("system_settings/workflow_setup/delete/" + id), params);
    };
    WorkflowhttpService.prototype.updateStatus = function (id, params) {
        return this.http
            .post(this.getApiUrl("system_settings/workflow_setup/update-status/" + id), params);
    };
    WorkflowhttpService.prototype.getDagrunsList = function (id, params) {
        return this.http.get(this.getApiUrl("system_settings/workflow_setup/" + id + "/dagruns", params));
    };
    WorkflowhttpService.prototype.getDagrunTasksList = function (id) {
        return this.http.get(this.getApiUrl("system_settings/workflow_setup/dagrun/" + id + "/tasks/history"));
    };
    WorkflowhttpService.prototype.getUsersList = function () {
        return this.http.get(this.getApiUrl("get_users?___noPageloading=true"));
    };
    WorkflowhttpService.prototype.getHashTagsList = function () {
        return this.http.get(this.getApiUrl("get_hashTags?___noPageloading=true"));
    };
    WorkflowhttpService.prototype.getEmailTemplate = function (params) {
        return this.http.get(this.getApiUrl("listSettingsEmailTemplates", params));
    };
    WorkflowhttpService.prototype.getCommunicationEmailDetails = function (id) {
        return this.http.get(this.getApiUrl("getCommunicationEmailDetails/" + id));
    };
    WorkflowhttpService.prototype.getJobDescriptions = function (params) {
        return this.http.get(this.getApiUrl("list_JobDescriptions", params));
    };
    WorkflowhttpService.prototype.getSMSTemplate = function (params) {
        return this.http.get(this.getApiUrl("listSettingsSmsTemplates", params));
    };
    WorkflowhttpService.prototype.getScheduledDagrunsList = function (id, runId, objectName, params) {
        return this.http.get(this.getApiUrl("system_settings/workflow_setup/" + id + "/scheduled_dagruns/" + runId + "/" + objectName, params));
    };
    WorkflowhttpService.prototype.actionTestRun = function (id, params) {
        return this.http
            .post(this.getApiUrl("system_settings/workflow_setup/" + id + "/scheduled/testrun"), params);
    };
    WorkflowhttpService.prototype.getWorkflowSetupSequenceMetadata = function (params) {
        return this.http
            .get(this.getApiUrl("system_settings/workflow_setup_sequence", params));
    };
    WorkflowhttpService.prototype.addEditWorkflowSequenceSetup = function (data, id) {
        if (id === 0) {
            return this.http.post(this.getApiUrl("system_settings/workflow_sequence/add"), JSON.stringify(data));
        }
        else {
            return this.http.post(this.getApiUrl("system_settings/workflow_sequence/edit/" + id), JSON.stringify(data));
        }
    };
    WorkflowhttpService.prototype.getWorkflowSetupSequencesList = function (params) {
        return this.http
            .get(this.getApiUrl("system_settings/workflow_sequences", params));
    };
    WorkflowhttpService.prototype.getCustomContactsList = function (id, type, params) {
        return this.http
            .get(this.getApiUrl("customers/" + type + "/" + id + "/contacts/view", params));
    };
    WorkflowhttpService.prototype.getEmailSmsTemplatesByIds = function (params) {
        return this.http
            .get(this.getApiUrl("system_settings/emailsms_templates", params));
    };
    WorkflowhttpService.prototype.postAddContactEmail = function (data, contactId) {
        return this.http.post(this.getApiUrl("workflow/sequence/add_email/" + contactId), JSON.stringify(data));
    };
    WorkflowhttpService.prototype.postAddContactMobile = function (data, contactId) {
        return this.http.post(this.getApiUrl("workflow/sequence/add_mobile/" + contactId), JSON.stringify(data));
    };
    WorkflowhttpService.prototype.postSendSequenceRequest = function (data, objectName, objectId) {
        return this.http.post(this.apiBasePath + "workflow/send_sequence/" + objectName + "/" + objectId, data);
    };
    WorkflowhttpService.prototype.posSequenceDagRunCreateRequest = function (data, objectName, objectId) {
        return this.http.post(this.apiBasePath + "workflow/dagrun_sequence/" + objectName + "/" + objectId, data);
    };
    WorkflowhttpService.prototype.getSequenceRuns = function (objectName, objectId) {
        return this.http.get(this.apiBasePath + "workflow/dagrun_sequence/" + objectName + "/" + objectId + "/list");
    };
    WorkflowhttpService.prototype.postStopSequenceRequest = function (data, objectId) {
        return this.http.post(this.apiBasePath + "workflow/stop/sequence/" + objectId, data);
    };
    WorkflowhttpService.prototype.getDagrunnerStatus = function (objectId) {
        return this.http.get(this.apiBasePath + "workflow/" + objectId + "/has_active_dagrun_status");
    };
    WorkflowhttpService.prototype.getTemplateById = function (templateId) {
        return this.http.get(this.apiBasePath + "communication/settings/template/" + templateId);
    };
    WorkflowhttpService.prototype.getCommunicationTemplateConfig = function () {
        return this.http.get(this.apiBasePath + "workflow/communication/template/config");
    };
    WorkflowhttpService.prototype.postExitConditionRequest = function (data, objectName, objectId) {
        return this.http.post(this.apiBasePath + "workflow/" + objectName + "/" + objectId + "/add_exit_condition", data);
    };
    WorkflowhttpService.prototype.getExitConditions = function (objectName, objectId) {
        return this.http.get(this.apiBasePath + "workflow/" + objectName + "/" + objectId + "/get_exit_condition");
    };
    WorkflowhttpService.prototype.deleteExitConditions = function (id, objectName, objectId) {
        return this.http.delete(this.apiBasePath + "workflow/" + objectName + "/" + objectId + "/delete_exit_condition/" + id);
    };
    WorkflowhttpService.prototype.workflowAuditRecords = function (id, type, data) {
        return this.http.post(this.apiBasePath + "workflow/audit_records/" + type + "/" + id, data);
    };
    WorkflowhttpService.prototype.getAuditDagsource = function (id) {
        // return this.http.get(this.getApiUrl(`workflow/audit_records/${id}/dagsource`));
        return this.http.get(this.apiBasePath + "workflow/audit_records/" + id);
    };
    WorkflowhttpService.prototype.getAllPipelineAutomation = function (data, objectName) {
        return this.http.post(this.apiBasePath + "workflow/settings/pipeline-automation/" + objectName + "/all", data);
    };
    WorkflowhttpService.prototype.savePipelineAutomation = function (data, objectName) {
        return this.http.post(this.apiBasePath + "workflow/settings/pipeline-automation/" + objectName + "/save", data);
    };
    WorkflowhttpService.prototype.deletePipelineAutomationItem = function (id, objectName) {
        return this.http.delete(this.apiBasePath + "workflow/settings/pipeline-automation/" + objectName + "/" + id + "/delete");
    };
    WorkflowhttpService.prototype.updatePipelineAutomation = function (data, objectName) {
        return this.http.post(this.apiBasePath + "workflow/settings/pipeline-automation/" + objectName + "/update", data);
    };
    WorkflowhttpService.prototype.updateStatusPipelineAutomation = function (data, objectName, id) {
        return this.http.post(this.apiBasePath + "workflow/settings/pipeline-automation/" + objectName + "/" + id + "/update_status", data);
    };
    return WorkflowhttpService;
}(InterfaceService));
export { WorkflowhttpService };
