<form novalidate="" class="fleet-live-map-sidepannel">
    <div class="modal-header">
        <h4 class="modal-title pull-left" translate="">Save location</h4>
    </div>
    <div class="modal-body">
        <div class="body-content-section name-block">
            <div class="row-fluid">
                <div class="span12">
                    <div class="control-group clearfix"><label class="control-label required_field"
                            translate="">Location name</label>
                        <div class="controls">
                            <input appdebouncetimer="" [(ngModel)]="locationName" class="control-input" name="locationName" type="text">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row-fluid">
                <div class="span12">
                    <div class="control-group clearfix"><label class="control-label required_field"
                            translate="">Icon</label>

                        <div class="btn-group d-flex" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                            <a href="javascript: void(0)" id="button-basic" dropdownToggle
                               class="btn btn-primary dropdown-toggle icon-select label-padding-0" *ngIf="!selectedIcon" translate>
                                Click.Select.Icon
                            </a>

                            <div class="selected-icon d-flex gap-16-pxl" *ngIf="selectedIcon">
                                <div class="preview-icon">
                                    <div class="d-flex" *ngIf="selectedIconType">
                                        <img *ngIf="selectedIconType === 'image'" [src]="selectedIcon" alt="">
                                        <i *ngIf="selectedIconType === 'FA'" [class]="'icon fa fas '+selectedIcon"></i>
                                    </div>
                                </div>
                                <div class="action-wrapper">
                                    <a href="javascript: void(0)" id="button-basic" dropdownToggle
                                        class="btn btn-primary dropdown-toggle button-space icon-select" translate>
                                        Change.Icon
                                    </a>
                                    <a href="javascript: void(0)" id="remove-icon"
                                        class="btn btn-primary icon-select remove-icon" translate (click)="removeIcon()">
                                        Remove.Icon
                                    </a>
                                </div>
                            </div>

                            
                            <div id="dropdown-list-icon" class="dropdown-menu" (click)="stopEventPropagation($event)">
                                <app-icon-list
                                    [style.visibility]="dropdown.isOpen ? 'visible' : 'hidden'"
                                    [dropdownInstance]='dropdown'
                                    [defaultIconsUrlList]="defaultIconsList"
                                    [uploadedIconsUrlList]="uploadedIconsListUrls"
                                    (selectedIcon)="setSelectedIcon($event)">
                                </app-icon-list>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <a class="btn" (click)="closePanel()">Cancel</a>
        <button class="btn btn-primary" [disabled]="locationName == '' || selectedIcon == ''" (click)="save()"><span translate="">Save</span></button>
    </div>
</form>
