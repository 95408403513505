var ChangeStageBlockModel = /** @class */ (function () {
    function ChangeStageBlockModel(data) {
        if (data === void 0) { data = undefined; }
        this.stageDisplayName = '';
        this.stageId = undefined;
        if (data) {
            this.stageDisplayName = data['stageDisplayName'] ? data['stageDisplayName'] : '';
            this.stageId = data['stageId'] ? parseInt(data['stageId']) : undefined;
        }
    }
    return ChangeStageBlockModel;
}());
export { ChangeStageBlockModel };
