var SmsBlockModel = /** @class */ (function () {
    function SmsBlockModel(data) {
        if (data === void 0) { data = undefined; }
        if (data) {
            this.templateId = data['templateId'] ? data['templateId'] : '';
            this.toSmsTag = data['toSmsTag'] ? data['toSmsTag'] : undefined;
            this.title = data['title'] ? data['title'] : '';
            this.message = data['message'] ? data['message'] : '';
        }
    }
    return SmsBlockModel;
}());
export { SmsBlockModel };
