import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { DagSourceModel } from "@app/workflow-common/services/DagSourceModel";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { NotificationBlockModel } from "@app/workflow-common/services/models/NotificationBlockModel";
import { CommonDataSource } from "@app/workflow-common/services/CommonDataSource";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
import { WorkflowCommonService } from "@app/workflow-common/services/workflow.common.service";
import { QuillEditorComponent } from "ngx-quill";
import "quill-mention";
var NotificationBlockWidgetComponent = /** @class */ (function () {
    function NotificationBlockWidgetComponent(formBuilder, workflowService, commonService) {
        this.formBuilder = formBuilder;
        this.workflowService = workflowService;
        this.commonService = commonService;
        this.close = new EventEmitter();
        this.notificationBlockData = {};
        this.isLoading = true;
        this.isFormSubmitted = false;
        this.userData = [];
        this.hashTagsData = [];
        this.csTaggingList = [];
        this.showDeleteBtn = false;
        this.inputModel = undefined;
    }
    NotificationBlockWidgetComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var blockObject, modelData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.notificationBlockData = this.dagSourceModel.getBlocksSource().getBlockByKey(this.blockObject.key);
                        blockObject = tslib_1.__assign({}, this.blockObject);
                        modelData = blockObject['model'] ? blockObject['model'] : undefined;
                        if (modelData) {
                            this.showDeleteBtn = true;
                        }
                        this.inputModel = new NotificationBlockModel(modelData);
                        this.userRolesData = CommonDataSource.userRoles;
                        return [4 /*yield*/, this.getUser()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getHashTags()];
                    case 2:
                        _a.sent();
                        this.isLoading = false;
                        this.setSelect2RolesInputConfig();
                        this.setEditorConfig();
                        this.csTaggingList = this.commonService.getCsTaggingList(this.dagSourceModel.mainObjectName, CommonDataSource.allTagsList, this.workflowType);
                        this.form = this.formBuilder.group({
                            roles: new FormControl(this.inputModel.roles, [Validators.required]),
                            message: new FormControl(this.inputModel.message, [Validators.required]),
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    NotificationBlockWidgetComponent.prototype.onChangeEditor = function (event) {
        if (!event.html) {
            this.inputModel.messageDelta = '';
            this.assignedUser = '';
            this.inputModel.message = '';
            return;
        }
        var delta = event.content;
        //this.inputModel.messageDelta = JSON.stringify(delta);
        //this.inputModel.plainContent = this.inputModel.message.replace(/(<([^>]+)>)/gi, "");
    };
    NotificationBlockWidgetComponent.prototype.getUser = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.workflowService.getUsersList()
                .subscribe(function (resp) {
                _this.userData = resp['users'].map(function (item) {
                    return { id: item.id, value: item.username, username: item.username };
                });
                resolve();
            });
        });
    };
    NotificationBlockWidgetComponent.prototype.getHashTags = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.workflowService.getHashTagsList()
                .subscribe(function (resp) {
                _this.hashTagsData = resp['hashtags'].map(function (item) {
                    return { id: item.id, value: item.description };
                });
                resolve();
            });
        });
    };
    NotificationBlockWidgetComponent.prototype.setSelect2RolesInputConfig = function () {
        var select2DisplayFormat = function (item) {
            return item.text;
        };
        this.select2RolesInputConfig = {
            width: '100%',
            data: this.userRolesData,
            tags: true,
            formatSelection: select2DisplayFormat,
            formatResult: select2DisplayFormat,
            multiple: true
        };
    };
    NotificationBlockWidgetComponent.prototype.setEditorConfig = function () {
        var _this = this;
        var mentionDenotationChars = [];
        this.editorConfig = CommonDataSource.editorCommonConfig(function (item, insertItem) {
            if (item.denotationChar === '[') {
                item['value'] = item['value'] + ']';
            }
            insertItem(item);
            if (item.denotationChar === '@') {
                _this.assignedUser = item.id;
            }
        }, function (searchTerm, renderList, mentionChar) {
            var values = [];
            if (mentionChar === '#') {
                values = _this.hashTagsData;
            }
            if (mentionChar === '[') {
                values = _this.csTaggingList;
            }
            else if (mentionChar === '@') {
                values = _this.assignedUser ? [] : _this.userData;
            }
            if (searchTerm.length === 0) {
                renderList(values, searchTerm);
            }
            else {
                var matches = [];
                for (var i = 0; i < values.length; i++)
                    if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
                        matches.push(values[i]);
                renderList(matches, searchTerm);
            }
        }, ["["], false);
    };
    NotificationBlockWidgetComponent.prototype.selectedRoles = function (event) {
        this.form.get('roles').setValue(event);
    };
    NotificationBlockWidgetComponent.prototype.setAssignedTo = function () {
        var _this = this;
        if (!this.assignedUser) {
            return;
        }
        var selectedUser = this.userData.find(function (user) { return user.id == _this.assignedUser; });
        if (selectedUser) {
            this.inputModel.assignToDisplay = selectedUser['username'];
            this.inputModel.assignTo = this.assignedUser;
        }
    };
    NotificationBlockWidgetComponent.prototype.setDisplayRoles = function () {
        var _this = this;
        if (!this.inputModel.roles) {
            return;
        }
        var selectedRoles = this.userRolesData.filter(function (role) { return _this.inputModel.roles.includes("" + role['id']); });
        var displayText = [];
        if (selectedRoles.length) {
            selectedRoles.forEach(function (item) {
                displayText.push(item['text']);
            });
        }
        this.inputModel.rolesDisplay = displayText;
    };
    NotificationBlockWidgetComponent.prototype.save = function () {
        var _a = this.form.value, roles = _a.roles, message = _a.message;
        this.isFormSubmitted = true;
        this.inputModel.roles = roles;
        this.inputModel.message = message;
        this.inputModel.textMessage = this.inputModel.message.replace(/(<([^>]+)>)/gi, "");
        this.setAssignedTo();
        this.setDisplayRoles();
        var blockObject = tslib_1.__assign({}, this.blockObject);
        blockObject['model'] = this.inputModel;
        this.close.emit({
            action: 'save_and_close',
            data: blockObject
        });
    };
    NotificationBlockWidgetComponent.prototype.closeModal = function () {
        this.close.emit({
            action: 'close',
            data: null
        });
    };
    NotificationBlockWidgetComponent.prototype.remove = function () {
        var blockObject = tslib_1.__assign({}, this.blockObject);
        blockObject.model = this.inputModel;
        this.close.emit({
            action: 'remove_block',
            data: blockObject
        });
    };
    return NotificationBlockWidgetComponent;
}());
export { NotificationBlockWidgetComponent };
