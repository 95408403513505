import { environment } from '../../environments/environment';
export { environment } from '../../environments/environment';
export function getFleetApiUrl(path, params) {
    var fleet = environment.fleet;
    var url = environment.apiHost + fleet + path;
    if (params instanceof Object) {
        url += '?';
        Object.entries(params).forEach(function (_a) {
            var param = _a[0], value = _a[1];
            url += encodeURIComponent(param) + "=" + encodeURIComponent(value) + "&";
        });
    }
    return url;
}
