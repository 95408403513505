import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceReminderActionComponent } from './service-reminder-action/service-reminder-action.component';
import { ScContainerComponent } from './container/sc-container.component';
import { ScLayoutComponent } from './layout/sc-layout.component';
import { ScTemplateViewComponent } from './layout/template-view/sc-template-view.component';
import { ScTemplateFormComponent } from './layout/template-form/sc-template-form.component';
import {EventDispatcherService} from "@app/shared/service-reminder-communication/services/event-dispatcher.service";
import {RoundProgressModule} from "@app/shared/round-progress/round-progress.module";
import {DirectivesModule} from "@app/directives";
import {FormsModule} from "@angular/forms";
import {ScHttpServiceService} from "@app/shared/service-reminder-communication/services/sc-http-service.service";
import { PipesModule } from "@app/pipes";
import {
    CS_SCROLLBAR_CONFIG,
    CsScrollbarConfigInterface
} from "@app/directives/cs-scrollbar/cs-scrollbar-config-interface";
import {AppCommonModule} from "@app/shared";
import {ModalModule, ButtonsModule, BsDatepickerModule} from "ngx-bootstrap";
import { CommonFolderStructureModule } from '../common-folder-structure/common-folder-structure.module';
import {TranslateModule} from "@ngx-translate/core";
import {CsComponentsModule} from "@app/shared/cs-components/cs-components.module";

const DEFAULT_CS_SCROLLBAR_CONFIG: CsScrollbarConfigInterface = {
    wheelPropagation: true,
    wheelSpeed: 0.2,
    suppressScrollX: true,
    suppressScrollY: false,
    swipeEasing: true,
};

@NgModule({
    declarations: [
        ServiceReminderActionComponent,
        ScContainerComponent,
        ScLayoutComponent,
        ScTemplateViewComponent,
        ScTemplateFormComponent,
    ],
    providers: [
        EventDispatcherService,
        ScHttpServiceService,
        {
            provide: CS_SCROLLBAR_CONFIG,
            useValue: DEFAULT_CS_SCROLLBAR_CONFIG
        }
    ],
    exports: [
        ServiceReminderActionComponent,
        DirectivesModule,
    ],
    imports: [
        CommonModule,
        RoundProgressModule,
        DirectivesModule,
        FormsModule,
        PipesModule,
        // AppCommonModule,
        ModalModule,
        CommonFolderStructureModule,
        TranslateModule,
        CsComponentsModule
    ],
    entryComponents: [
        ScContainerComponent,
    ]
})
export class ServiceReminderCommunicationModule { }
