<ng-container *ngIf="modalConfig">
    <cs-modal
        [ngClass]="{ 'warning-modal' : modalConfig.modalType === 'warning'}"
        [show]="modalConfig['showModal']">
        <!--    Warning Modal-->
        <div *ngIf="modalConfig['modalType'] === 'warning'" class="warning-modal-wrapper">
            <div class="modal-header">
                <button type="button" class="close" (click)="closeModal('CLOSE_ICON')">×</button>
                <h4 class="" translate>{{ modalConfig['title'] }}</h4>
            </div>

            <div class="modal-body">
                <div class="row-fluid">
                  <span class="warning-icon-wrap">
                      <span class="ss-alert icon">&nbsp;</span>
                  </span>
                </div>
                <div class="row-fluid">
                    <div class="message-content">
                        <div [innerHTML]="modalConfig['message']['warningTxt']" translate></div>
                        <div *ngIf="modalConfig['message']['helpTxtList']" class="helpTxtWrapper">
                            <div *ngFor="let helpTxt of modalConfig['message']['helpTxtList']" translate>{{ helpTxt }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button class="btn" (click)="closeModal('CANCEL_BTN')" translate>{{ modalConfig['closeText'] }}</button>
            </div>
        </div>

        <!--    Confirm Modal-->
        <div *ngIf="modalConfig['modalType'] === 'confirm'" class="confirmModalWrapper">
            <div class="modal-header">
                <button type="button" class="close" (click)="closeModal('CLOSE_ICON')">×</button>
                <h4 class="" translate>{{ modalConfig['title'] }}</h4>
            </div>

            <div class="modal-body">
                <div class="row-fluid">
                    <p *ngIf="modalConfig['message'] && modalConfig['message']['warningTxt']" translate>{{modalConfig['message']['warningTxt']}}</p>
                    <div *ngIf="modalConfig['message'] && modalConfig['message']['helpTxtList']" class="helpTxtWrapper">
                        <p *ngFor="let helpTxt of modalConfig['message']['helpTxtList']" translate>{{ helpTxt }}</p>
                    </div>
                    <p *ngIf="modalConfig['defaultTextMessage']">To confirm you wish to {{modalConfig['actionText']}} this {{modalConfig['itemText']}}, please type '<b>{{modalConfig['actionText']}}</b>' in the box below and click {{modalConfig['confirmBtnText']}}.</p>
                </div>

                <div class="input-prepend box-shadow input-fullwidth row-fluid">
                    <span class="add-on" *ngIf="modalConfig.confirmBtnType === 'DELETE' || modalConfig.confirmBtnType === 'ARCHIVE'"><span class="ss-caution"></span></span>
                    <div class="input-wrapper" [formGroup]="confirmationForm">
                        <input
                            type="text"
                            formControlName="confirmationText"
                            (keyup.enter)="confirmationForm.valid? executeAction(): null"/>
                    </div>
                </div>

                <validation-error
                    [formGroup]="confirmationForm"
                    controlName="confirmationText"
                    showOnDirty="true">
                    <div class="row-fluid">
<!--                        <span [translateParams]="modalConfig.confirmBxValidationMsg" translate>confirmation.warning.text</span>-->
                        <span translate>{{modalConfig.confirmBxValidationMsg}}</span>
                    </div>
                </validation-error>
            </div>

            <div class="modal-footer">
                <div class="action-wrapper">
                    <div class="control-error-message">
                        <span *ngIf="modalConfig.actionStatus === 'FAILED'" translate>{{modalConfig.operationFailedMsg}}</span>
                    </div>

                    <div class="action-controls">
                        <button
                            class="btn"
                            (click)="closeModal('CANCEL_BTN')"
                            translate>
                            {{ modalConfig['closeText'] }}
                        </button>

                        <button
                            type="button"
                            class="btn"
                            [disabled]="confirmationForm.invalid || modalConfig.actionStatus === 'PROCESSING'"
                            [ngClass]="{
                            'loading': modalConfig.actionStatus === 'PROCESSING',
                            'btn-primary': modalConfig.confirmBtnType === 'DEFAULT',
                            'btn-warning': modalConfig.confirmBtnType === 'ARCHIVE',
                            'btn-danger': modalConfig.confirmBtnType === 'DELETE'
                            }"
                            (click)="executeAction()"
                            translate>
                            {{ modalConfig['confirmBtnText'] | capitalize }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </cs-modal>
</ng-container>
