export const environment = {
    production: true,
    envName: 'prod_us',
    apiHost: 'https://app.commusoft.us',
    apiBasePath: '/frontend_prod_us.php/',
    apiPrefixPath: '/frontend_prod_us.php',
    adminPrefix: '/adminwebservice_dev.php',
    webservicePrefix: '/webservice_prod_us.php',
    advBookingPortalHost: 'https://portals.commusoft.us/website',
    HandsontableLicenseKey: '03af8-73b53-c8d50-f4d0d-c504f',
    pubNubDetails: {
        'subscribeKey': 'sub-c-a0aa0d88-2f7c-11e5-8fa4-02ee2ddab7fe',
        'publishKey': 'pub-c-3667b777-7a55-40a2-b8ab-4c503795b2c3'
    },
    mapDetails: {
        'mapLocation': [41.479027, -101.9238672],
        'mapZoom': 5
    },
    microserviceApiContract: '/v5/contract/',
    microserviceApiReporting: '/v5/reporting/',
    microserviceApiJob: '/v5/job/',
    fleet: '/v5/fleet/',
    microserviceApiV5Core: '/v5/core/',
    agGridEnterpriseLicenseKey: 'Using_this_{AG_Grid}_Enterprise_key_{AG-058524}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Commusoft_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Commusoft}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Commusoft}_need_to_be_licensed___{Commusoft}_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{14_May_2025}____[v3]_[01]_MTc0NzE3NzIwMDAwMA==419011400f939b23edeb0db16511b912'
};
