import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
var MicroserviceHelperService = /** @class */ (function () {
    function MicroserviceHelperService() {
        this.token_storage_key = 'cmsmsapt';
        this.refresh_interval_storage_key = 'cmsmsapt:interval';
        this.apiBase = environment.apiHost;
        this.v5ApiBase = this.apiBase + '/v5/';
        this.ajaxInterceptor();
    }
    MicroserviceHelperService.prototype.ajaxInterceptor = function () {
        var that = this;
        $.ajaxPrefilter(function (options) {
            if (that.isInterceptableRequest(options.url)) {
                if (!options.hasOwnProperty('headers')) {
                    options['headers'] = {};
                }
                options['headers']['v5Token'] = that.getToken();
                options['headers']['X-CS-INTERCEPTOR'] = 'YES';
            }
        });
    };
    MicroserviceHelperService.prototype.isReplaceableEndpointUrl = function (requestEndpointUrl) {
        return ((requestEndpointUrl.indexOf('@contract-v5') !== -1)
            || (requestEndpointUrl.indexOf('@reporting-v5') !== -1));
    };
    MicroserviceHelperService.prototype.getReplaceableEndpointReplacementUrl = function (requestEndpointUrl) {
        if (!(requestEndpointUrl === null
            || requestEndpointUrl === undefined
            || (typeof requestEndpointUrl === 'string' && requestEndpointUrl.trim().length === 0))) {
            if (requestEndpointUrl.indexOf('@contract-v5') !== -1) {
                var v5ApiContractBase = this.v5ApiBase + 'contract';
                requestEndpointUrl = requestEndpointUrl.replace('@contract-v5', v5ApiContractBase);
            }
            else if (requestEndpointUrl.indexOf('@reporting-v5') !== -1) {
                var v5ApiReportingBase = this.v5ApiBase + 'reporting';
                requestEndpointUrl = requestEndpointUrl.replace('@reporting-v5', v5ApiReportingBase);
            }
        }
        return requestEndpointUrl;
    };
    MicroserviceHelperService.prototype.isInterceptableRequest = function (requestUrl) {
        return (requestUrl.indexOf(this.v5ApiBase) !== -1);
    };
    MicroserviceHelperService.prototype.clearToken = function () {
        this.clearTokenInterval();
        localStorage.removeItem(this.token_storage_key);
        // window.sessionStorage.clear();
    };
    MicroserviceHelperService.prototype.setToken = function (token) {
        localStorage.removeItem(this.token_storage_key);
        if (typeof token === 'string' && token.trim().length > 0) {
            localStorage.setItem(this.token_storage_key, token);
        }
    };
    MicroserviceHelperService.prototype.getToken = function () {
        return localStorage.getItem(this.token_storage_key);
    };
    MicroserviceHelperService.prototype.setTokenInterval = function (interval) {
        localStorage.setItem(this.refresh_interval_storage_key, String(interval));
    };
    MicroserviceHelperService.prototype.getTokenInterval = function () {
        var value = localStorage.getItem(this.refresh_interval_storage_key);
        return value ? parseInt(value) : null;
    };
    MicroserviceHelperService.prototype.clearTokenInterval = function () {
        localStorage.removeItem(this.refresh_interval_storage_key);
        // window.sessionStorage.clear();
    };
    MicroserviceHelperService.prototype.isTokenExist = function () {
        var token = this.getToken();
        return !(token === null
            || token === undefined
            || (typeof token === 'string' && token.trim().length === 0));
    };
    MicroserviceHelperService.prototype.getTokenHeaders = function () {
        var headers = [];
        var token = this.getToken();
        // Content Type
        /*headers.push({
            name: 'Content-type',
            value: 'application/json'
        });*/
        // v5Token
        headers.push({
            name: 'v5Token',
            value: "" + token
        });
        // Authorization
        // headers.push({
        //     name: 'Authorization',
        //     value: `Bearer ${token}`
        // });
        // Custom Header
        headers.push({
            name: 'X-CS-INTERCEPTOR',
            value: 'YES'
        });
        return headers;
    };
    MicroserviceHelperService.ngInjectableDef = i0.defineInjectable({ factory: function MicroserviceHelperService_Factory() { return new MicroserviceHelperService(); }, token: MicroserviceHelperService, providedIn: "root" });
    return MicroserviceHelperService;
}());
export { MicroserviceHelperService };
