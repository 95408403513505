import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import { InterfaceService } from '@app/interfaces';
import {Subject} from "rxjs/internal/Subject";

@Injectable({
    providedIn: 'root'
})

export class CustomerJobTemplateService extends InterfaceService {
    private CustomerJobNavData = new BehaviorSubject('');
    CustomerJobNavData$ = this.CustomerJobNavData.asObservable();

    private whoIsViewing = new BehaviorSubject('');
    $whoIsViewing = this.whoIsViewing.asObservable();

    constructor(
        private http: HttpClient
    ) { super(); }
    addJobNew(type: string, customerId: number,  data: Object): Observable<any> {
        return this.http.post(this.getApiUrl(`customers/${type}/${customerId}/job/new_jobs`), JSON.stringify(data));
    }

    editJob(type: string, customerId: number, jobId: number,  data: Object): Observable<any> {
        return this.http.post(this.getApiUrl(`customers/${type}/${customerId}/jobs/${jobId}/update`), JSON.stringify(data));
    }

    createContact(customerId: number,  data: Object): Observable<any> {
        return this.http.post(this.getApiUrl(`customers/${customerId}/create_contact_add`), JSON.stringify(data));
    }

    getSkills(jobId?:number,params?: any): Observable<any> {
        return this.http.get(this.getApiUrl(`search_skills_description?jobId=${jobId}`, params));
    }
    getSearchSkills(jobDescId:number): Observable<any> {
        return this.http.get(this.getApiUrl(`get_assigned_skills?type=job_description&primaryId=${jobDescId}`));
    }
    getDefaultJobDescriptions(jobDescId:number): Observable<any> {
        return this.http.get(this.getApiUrl(`default_job_values/${jobDescId}`));
    }

    getJobDescriptionPricingItem(jobDescId, params?: object){
        return this.http.get(this.getApiUrl(`getJobDescriptionPricingItem/${jobDescId}`, params));
    }

    getAssets(propertyId:number, assetId:number, jobId:number): Observable<any> {
        return this.http.get(this.getApiUrl(`get_job_asset_details?propertyId=${propertyId}&jobId=${jobId}&assetId=${assetId}`));
    }
    getContactPerference(contactId:number): Observable<any>{
        return this.http.get(this.getApiUrl(`diary_jobservice_preference_validation?contactID=${contactId}`));
    }

    getJDCustomTemplates(action:string,jobDescId:number,tablePKID?:number,propertyID?:number): Observable<any>{
        return this.http.get(this.getApiUrl(`listCustomConfigTemplates?screenMode=addCustomTemplates&action=${action}&objects=Jobs&objectTypes=JobDescription&objectTypeID=${jobDescId}&propertyID=${propertyID}&tablePKID=${tablePKID}`));
    }

    getInvoiceCatCustomTemplates(action:string,invCatId:number,tablePKID?:number,propertyID?:number): Observable<any>{
        return this.http.get(this.getApiUrl(`listCustomConfigTemplates?screenMode=addCustomTemplates&action=${action}&objects=Jobs&objectTypes=InvoicesCategory&objectTypeID=${invCatId}&propertyID=${propertyID}&tablePKID=${tablePKID}`));
    }

    checkInvCatCustomTemplatesAVl(invCatId:number,tablePKID?:number,propertyID?:number): Observable<any>{
        return this.http.get(this.getApiUrl(`checkPropertyCustomTemplatesAnsAvl?screenMode=addCustomTemplates&objects=Jobs&objectTypes=InvoicesCategory&objectTypeID=${invCatId}&propertyID=${propertyID}&tablePKID=${tablePKID}`));
    }

    checkJobDescCustomTemplatesAVl(jobDescId:number,tablePKID?:number,propertyID?:number): Observable<any>{
        return this.http.get(this.getApiUrl(`checkPropertyCustomTemplatesAnsAvl?screenMode=addCustomTemplates&objects=Jobs&objectTypes=JobDescription&objectTypeID=${jobDescId}&propertyID=${propertyID}&tablePKID=${tablePKID}`));
    }

    confirmDeleteCustomTemplatesAnswer(params?: any): Observable<any>{
        return this.http.post(this.getApiUrl(`confirmDeleteCustomTemplatesAnswer`),  JSON.stringify(params));
    }

    getJobCertificatesUserColumns(params?: any): Observable<any>{
        return this.http.post(this.getApiUrl(`job_certificates_user_columns`),  JSON.stringify(params));
    }

    deleteJobCertificate(data): Observable<any> {
        return this.http.post(this.getApiUrl(`delete_job_view_certificate`),data)
    }

    getCustomerJobNavDetails(id: number, type: string,jobId: number): Observable<any> {
        let url = this.getApiUrl(`customers/${type}/${id}/job/${jobId}/views`);
        return this.http.get(url);
    }

    setCustomerJobNavData(data) {
        this.CustomerJobNavData.next(data);
    }

    getJobInvoices(id: number, type: string,jobId: number,pageNum: number,limit: number): Observable<any> {
        let url = this.getApiUrl(`customers/${type}/${id}/jobs/${jobId}/invoices/views?pageNumInvoices=${pageNum}&limitInvoices=${limit}`);
        return this.http.get(url);
    }

    getJobInvoicesList(id: number, type: string,jobId: number,pageNum: number,limit: number): Observable<any> {
        let url = this.getApiUrl(`customers/${type}/${id}/jobs/${jobId}/invoices/list?pageNumInvoices=${pageNum}&limitInvoices=${limit}`);
        return this.http.get(url);
    }

    getJobDraftInvoicesList(id: number, type: string,jobId: number,pageNum: number,limit: number): Observable<any> {
        let url = this.getApiUrl(`customers/${type}/${id}/jobs/${jobId}/draft_invoices/list?pageNumDraftInvoices=${pageNum}&limitDraftInvoices=${limit}`);
        return this.http.get(url);
    }

    updateWhoIsViewing(availableUsers) {
        this.whoIsViewing.next(availableUsers)
    }

    getPipelineStagesList (pipelineId) {
        let url = this.getApiUrl(`getPipelineStagesList/${pipelineId}`);
        return this.http.get(url);
    }

    getTeamList (teamId) {
        let url = this.getApiUrl(`getTeamList/${teamId}`);
        return this.http.get(url);
    }

    getTeamMembersList = function (teamId) {
        return this.http.post(this.getApiUrl(`get_team_members/${teamId}`));
    };
}
