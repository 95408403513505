import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DagSourceModel} from "@app/workflow-common/services/DagSourceModel";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {EmailBlockModel} from "@app/workflow-common/services/models/EmailBlockModel";
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";
import {from, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {CommonDataService} from "@app/services/http-services/common-data.service";
import {CommonDataSource} from "@app/workflow-common/services/CommonDataSource";

@Component({
  selector: 'app-email-block-widget',
  templateUrl: './email-block-widget.component.html',
  styles: []
})
export class EmailBlockWidgetComponent implements OnInit {

    @Input() workflowType: string;
    @Input() blockObject;
    @Input() dagSourceModel: DagSourceModel;
    @Input() objectName: string;
    @Output() close:EventEmitter<{}> = new EventEmitter<{}>();

    form: FormGroup;

    isLoading: boolean = true;
    isSMTPEnabled: boolean = false;
    fromEmailAddressesList: any[] = [];
    emailBlockData:{} = {};
    select2ConfigToEmail:{} = {};
    select2ConfigOtherEmail:{} = {};
    usersList: any[] = [];
    emailTagsList: any[] = [];
    emailUsersList: any[] = [];
    emailCustomList: any[] = [];

    showCC:boolean = false;
    showBCC:boolean = false;
    isFormSubmitted:boolean = false;

    selectedEmailInputs:{} = {};
    inputModel: EmailBlockModel = undefined;
    showDeleteBtn: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private workflowService: WorkflowhttpService,
    ) { }

    async ngOnInit() {
        this.emailBlockData = this.dagSourceModel.getBlocksSource().getBlockByKey(this.blockObject.key);
        this.emailTagsList = this.dagSourceModel.getEmailAddressTagsByType(this.workflowType,this.objectName);

        const blockObject = {...this.blockObject};
        const modelData = blockObject['model'] ? blockObject['model'] : undefined;
        if(modelData) {
            this.showDeleteBtn=true;
        }
        this.inputModel = new EmailBlockModel(modelData);

        this.makeEmailAddressDropdownData();

        await this.getData();

        this.isLoading=false;
        this.usersList.forEach( (user) => {
            this.emailUsersList.push({
                id: parseInt(user.id),
                text: user.username,
                name: 'USERS'
            })
        })
        if(!this.isSMTPEnabled) {
            this.inputModel.fromEmail = '';
        }

        this.setToEmailSelect2Config();

        this.form = this.formBuilder.group({
            fromEmail: new FormControl(this.inputModel.fromEmail),
            templateId: new FormControl(this.inputModel.templateId, [Validators.required]),
            toEmailTag: new FormControl(this.selectedEmailInputs['toEmailTag'], [Validators.required]),
            ccEmailTag: new FormControl(this.selectedEmailInputs['ccEmailTag']),
            bccEmailTag: new FormControl(this.selectedEmailInputs['bccEmailTag']),
            subject: new FormControl(this.inputModel.subject),
            message: new FormControl(this.inputModel.message),
            templateName: new FormControl(this.inputModel.templateName),
        });

        if(this.isSMTPEnabled) {
            this.form.get('fromEmail').setValidators(Validators.required);
        }
    }

    getData() {
        return new Promise( (resolve, reject) => {
            this.workflowService.getCommunicationTemplateConfig()
                .toPromise()
                .then( (resp) => {
                    let data = resp.hasOwnProperty('data') ? resp['data'] : null;
                    if(data) {
                        this.isSMTPEnabled = data.smtpDetails.smtp;
                        if(this.isSMTPEnabled) {
                            this.fromEmailAddressesList = data.smtpDetails.smtpList;
                        }
                        this.usersList = data.users;
                        resolve(true);
                    }
                }, (err) => {
                    reject(err);
                    console.error(err);
                });
        });
    }

    setToEmailSelect2Config() {
        const select2Default = CommonDataSource.select2EmailDefaultConfig;
        this.select2ConfigToEmail = {...select2Default};
        this.select2ConfigToEmail['data'] = this.makeGroupEmailList('toAddress');
        if(this.emailBlockData['groupId'] != 'internalCommunications') {
            this.select2ConfigToEmail['formatNoMatches'] = () => {
                return 'Add a recipient\'s email by entering...';
            }
        }
        this.select2ConfigOtherEmail = {...select2Default};
        this.select2ConfigOtherEmail['data'] = this.makeGroupEmailList('ccAddress');
    }

    makeGroupEmailList(emailType: string = 'toAddress') {
        const allEmailTag = [];
        const newItems: any[] = [];
        if(emailType == 'toAddress' && this.emailBlockData['groupId'] == 'internalCommunications') {
            newItems.push(this.emailUsersList);
        }
        else if(emailType == 'toAddress' && this.emailBlockData['groupId'] == 'externalCommunications'){
            newItems.push(this.emailTagsList);
        }
        else if (emailType == 'ccAddress' || emailType == 'bccAddress') {
            newItems.push(this.emailUsersList);
        }

        if(this.emailCustomList.length) {
            newItems.push(this.emailCustomList);
        }

        const source = from(newItems);
        const tags = source.pipe(
            map(groupItems => {
                const _item: any = {text: '', children: []};
                let cnt: number = 0;
                groupItems.forEach( group => {
                    if (cnt === 0) {
                        _item.text = group.name;
                    }
                    _item.children.push({
                        id: group.id,
                        text: group.text
                    });
                    cnt++;
                });
                return _item;
            })
        );
        const subscribe:Subscription = tags.subscribe(val => allEmailTag.push(val));
        subscribe.unsubscribe();
        return allEmailTag;
    }

    setTemplateDetails(event:any, type: string) {
        this.form.get('templateId').setValue(event['templateId']);
        this.form.get('message').setValue(event['message']);
        this.form.get('subject').setValue(event['subject']);
        this.form.get('templateName').setValue(event['templateName']);
        this.inputModel.subject = event['subject'];
        this.inputModel.message = event['message'];
        this.inputModel.templateName = event['templateName'];
    }

    selectedEmailAddress(event:any = null, type:string = 'to') {
        if(type == 'to'){
            this.form.get('toEmailTag').setValue( event);
        }else if (type == 'cc'){
            this.form.get('ccEmailTag').setValue( event);
        }else{
            this.form.get('bccEmailTag').setValue( event);
        }
    }

    save() {
        this.isFormSubmitted=true;
        const blockObject = {...this.blockObject};
        const {toEmailTag, ccEmailTag, bccEmailTag} = this.form.value;
        blockObject.model = new EmailBlockModel(this.form.value);
        blockObject.model.toEmailTag = CommonDataSource.formattingEmailAddress(toEmailTag, this.emailTagsList, this.usersList);
        blockObject.model.ccEmailTag = CommonDataSource.formattingEmailAddress(ccEmailTag, this.emailTagsList, this.usersList);
        blockObject.model.bccEmailTag = CommonDataSource.formattingEmailAddress(bccEmailTag, this.emailTagsList, this.usersList);
        this.close.emit({
            action: 'save_and_close',
            data: blockObject
        })
    }

    closeModal() {
        this.close.emit({
            action: 'close',
            data: null
        });
    }

    remove() {
        const blockObject = {...this.blockObject};
        blockObject.model = this.inputModel;
        this.close.emit({
            action: 'remove_block',
            data: blockObject
        });
    }

    makeEmailAddressDropdownData() {
        const inputModel: EmailBlockModel = this.inputModel;

        const _toEmailTag = CommonDataSource.makeEmailAddressDropdownData(inputModel.toEmailTag);
        this.selectedEmailInputs['toEmailTag'] = _toEmailTag['inputValue'];
        if(_toEmailTag['customList'].length) {
            this.emailCustomList = _toEmailTag['customList'];
        }

        const _ccEmailTag = CommonDataSource.makeEmailAddressDropdownData(inputModel.ccEmailTag);
        this.selectedEmailInputs['ccEmailTag'] = _ccEmailTag['inputValue'];
        if(_ccEmailTag['customList'].length) {
            this.emailCustomList = _ccEmailTag['customList'];
        }

        const _bccEmailTag = CommonDataSource.makeEmailAddressDropdownData(inputModel.bccEmailTag);
        this.selectedEmailInputs['bccEmailTag'] = _bccEmailTag['inputValue'];
        if(_bccEmailTag['customList'].length) {
            this.emailCustomList = _bccEmailTag['customList'];
        }
    }
}
