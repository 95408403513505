import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonFolderStructureModule } from '@app/shared/common-folder-structure/common-folder-structure.module';
import { SharedModalComponent } from './shared-modal.component';
import { ScheduledActivityModalComponent } from './scheduled-activity-modal/scheduled-activity-modal.component';
import { EmailModalComponent } from './email-modal/email-modal.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DirectivesModule} from "@app/directives";
import {TranslateModule} from "@ngx-translate/core";
import { ViewVehicleService } from '@app/features/fleet-management/add-vehicle/services/view-vehicle-service';
import {EmailListenerService} from "@app/shared-modal/email-modal/email-listener.service";
import {CsComponentsModule} from "@app/shared/cs-components/cs-components.module";


@NgModule({
  declarations: [
    EmailModalComponent,
    SharedModalComponent,
    ScheduledActivityModalComponent,
  ],
    imports: [
        CommonModule,
        CommonFolderStructureModule,
        FormsModule,
        DirectivesModule,
        ReactiveFormsModule,
        TranslateModule,
        CsComponentsModule
    ],
  exports:[
    EmailModalComponent,
    SharedModalComponent,
    ScheduledActivityModalComponent
  ],
  providers : [
      ViewVehicleService,
      EmailListenerService,
  ]
})
export class SharedModalModule { }
