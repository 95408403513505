import {Component, EventEmitter, Input, OnInit, ViewChild, Output, HostListener} from '@angular/core';
import {ViewVehicleService} from "@app/features/fleet-management/add-vehicle/services/view-vehicle-service";


declare let L;
declare var google;
declare var moment;

@Component({
    selector: 'app-fleet-map-view',
    templateUrl: './fleet-map-view.component.html',
    styleUrls: ['./fleet-map-view.component.scss'],
})
export class FleetMapViewComponent implements OnInit {

    viewMode: boolean = false;
    officeDisplay:boolean = true;
    displayViewModeDropdown: string = '';
    exapandVehicleDetailCard: boolean = false;
    allCheckboxSelected: boolean = false;
    posTopViewMode: string;
    posLeftViewMode: string;
    homeSearchText = '';
    supplierSearchText = '';
    homeSelectAll: boolean = false;
    homeLayer: any;
    officeLayerMap: any;
    savedLocations: any = [];
    suppliersLocations: any = [];
    locationLayer: any;
    supplierIconLayer: any;
    locationSearchText = '';
    map: any;
    officeLatLon:any;

    homeAddress: Array<any> = [];
    filteredHomeAddress = []; //home location

    addressList = []; //save location
    filteredAddress = [];
    filteredSupplierAddress = [];

    supplierLocationList = []; //supplier location

    @Input() liveMapInstance: any;
    permenantLayer: any;
    allCheckboxSelectedSupplier: any;
    supplierCount = 0;
    userHomeCount = 0;
    savedLocationCount = 0;
    allCheckboxSelectedSavedLocation: boolean = false;
    isDeleteInProgress:boolean = false;



    constructor(
        private vehicleService: ViewVehicleService,
    ) {
    }

    ngOnInit() {
        this.getMapPinData();
    }

    ngOnChanges(Changes) {
        if (Changes.liveMapInstance.currentValue) {
            this.map = Changes.liveMapInstance.currentValue;
        }
    }

    toggleViewMode() {
        this.viewMode = !this.viewMode;
    }

    toggleOfficeDisplay() {
        this.officeDisplay = !this.officeDisplay;
        this.addOfficeLocation(this.officeDisplay);
    }

    searchHome() {
        const query = this.homeSearchText.toLowerCase();
        this.filteredHomeAddress = this.homeAddress.filter(address =>
            address.userName.toLowerCase().includes(query)
        );
        this.homeSelectAll = this.filteredHomeAddress.every(item => item.isSelected == true);
    }

    searchSuppliers() {
        const query = this.supplierSearchText.toLowerCase();
        this.filteredSupplierAddress = this.supplierLocationList.filter(supplier =>
            supplier.name.toLowerCase().includes(query)
        );
        this.allCheckboxSelectedSupplier = this.filteredSupplierAddress.every(item => item.isSelected == true);
    }

    getText(){
        this.homeSearchText = '';
        this.supplierSearchText = '';
        this.locationSearchText = '';
        this.searchLocation();
        this.searchHome();
        this.searchSuppliers();
    }


    selectAllHomeAddressList(event) { //mani
        this.homeSelectAll = !this.homeSelectAll;
        this.homeLayer = L.featureGroup();

        this.filteredHomeAddress.forEach((item) => {
            item.isSelected = event.target.checked;
            this.toggleMarker(item, 'home', event.target.checked, this.homeAddress, this.homeLayer);
        });

        this.homeLayer.addTo(this.map);
    }

    isHomeSelected(evt, index, val) {  //mani
        this.filteredHomeAddress[index].isSelected = evt.target.checked;
        this.homeSelectAll = this.filteredHomeAddress.every(item => item.isSelected == true);

        this.homeLayer = L.featureGroup();
        this.toggleMarker(val, 'home', evt.target.checked, this.homeAddress, this.homeLayer);

        this.homeLayer.addTo(this.map);
    }


    checkUncheckAllAddressList(event) {
        this.allCheckboxSelectedSavedLocation = !this.allCheckboxSelectedSavedLocation;
        this.locationLayer = L.featureGroup();

        this.filteredAddress.forEach((item) => {
            item.isSelected = event.target.checked;
            this.toggleMarker(item, item.locationIcon, event.target.checked, this.savedLocations, this.locationLayer,'saved-location-background');
        });

        this.locationLayer.addTo(this.map);
    }


    isAllSelected(evt, index, val) {
        if(this.isDeleteInProgress){
            return;
        }
        this.filteredAddress[index].isSelected = evt.target.checked;
        this.allCheckboxSelectedSavedLocation = this.filteredAddress.every(item => item.isSelected == true);

        this.locationLayer = L.featureGroup();
        this.toggleMarker(val, val.locationIcon, evt.target.checked, this.savedLocations, this.locationLayer,'saved-location-background');

        this.locationLayer.addTo(this.map);
    }

    allCheckboxSelectedSuppliers(event) {
        this.allCheckboxSelectedSupplier = !this.allCheckboxSelectedSupplier;
        this.supplierIconLayer = L.featureGroup();

        this.filteredSupplierAddress.forEach((item) => {
            item.isSelected = event.target.checked;
            this.toggleMarker(item, 'supplier', event.target.checked, this.suppliersLocations, this.supplierIconLayer);
        });

        this.supplierIconLayer.addTo(this.map);
    }

    isAllSelectedSupplier(evt, index, val) {
        this.filteredSupplierAddress[index].isSelected = evt.target.checked;
        this.allCheckboxSelectedSupplier = this.filteredSupplierAddress.every(item => item.isSelected == true);

        this.supplierIconLayer = L.featureGroup();
        this.toggleMarker(val, 'supplier', evt.target.checked, this.suppliersLocations, this.supplierIconLayer);

        this.supplierIconLayer.addTo(this.map);
    }



    deleteLocation(event:MouseEvent,address) {
        this.isDeleteInProgress = true;
        event.stopPropagation();
        this.vehicleService.deleteLocation(address.id).subscribe(res => {
            if(res){
                this.getMapPinData();
                this.isDeleteInProgress = false;
            }
            
            
        })
    }



    getMapPinData() {
        this.vehicleService.getMapPinDatas().subscribe((result: any) => {
           if(result){
               this.addressList = result['savedLocations'];
               this.filteredAddress = result['savedLocations'];
               this.officeLatLon = result['officeAddress']; //office location
               this.filteredHomeAddress = result['homeAddress'];
               this.homeAddress = result['homeAddress'];

               this.supplierLocationList = result['supplierLocations'];
               this.filteredSupplierAddress = result['supplierLocations']; //supplier location

               this.savedLocationCount = this.addressList.length;
               this.supplierCount = this.supplierLocationList.length;
               this.userHomeCount = this.homeAddress.length;

               this.addOfficeLocation(true);
           }
        });
    }

    addOfficeLocation(value){
        if(value){
            if(this.officeLatLon && this.officeLatLon.length) {
                this.officeLayerMap = L.featureGroup();
                let branchIcon = {
                    icon: L.divIcon({
                        className: 'customer-marker',
                        html: `<div class="profile-pointer"><div class="livemap-icon office"></div></div>`,
                        iconSize: [30, 42],
                        iconAnchor: [15, 42]
                    })
                };
                L.marker([this.officeLatLon[0], this.officeLatLon[1]], branchIcon).addTo(this.officeLayerMap);
                if(this.map) {
                    this.officeLayerMap.addTo(this.map);
                } else {
                    let self = this;
                    setTimeout(function (){
                        self.officeLayerMap.addTo(this.map);
                    },1000);
                }
            }
        }else{
            if(this.officeLayerMap) {
                this.officeLayerMap.remove();
            }
        }
    }

    searchLocation() {
        const query = this.locationSearchText.toLowerCase();
        this.filteredAddress = this.addressList.filter(address =>
            address.name.toLowerCase().includes(query)
        );
        this.allCheckboxSelectedSavedLocation = this.filteredAddress.every(item => item.isSelected == true);
    }

    toggleMarker(item, iconClass, isChecked, markerList, layerGroup,dynamicClass='') {
        let engineerName = '';
        if(iconClass == 'home'){
            engineerName = `${item['userName']}`
        }
        let userIcon = {
            icon: L.divIcon({
                className: 'customer-marker',
                html: `<div class="profile-pointer">
                    <div class="livemap-icon ${dynamicClass} ${iconClass}"></div></div>`, //<div class="enginner-name">${engineerName}</div> if you need to add name please use this html
                iconSize: [30, 42],
                iconAnchor: [15, 42]
            })
        };

        if (isChecked) {
            if (!markerList[item.userName || item.name || item.name]) {
                markerList[item.userName || item.name || item.name] = L.marker([item.latitude, item.longitude], userIcon).addTo(layerGroup);
                markerList[item.userName || item.name || item.name].bindTooltip(item.userName || item.name || item.name,{direction: 'top', className: 'custom-tooltip-user-alignment'}).openTooltip();
                markerList[item.userName || item.name || item.name].on('mouseover', function () {
                    this.openTooltip();
                });
                markerList[item.userName || item.name || item.name].on('mouseout', function () {
                    this.closeTooltip();
                });
            }
        } else {
            if (markerList[item.userName || item.name || item.name]) {
                markerList[item.userName || item.name || item.name].remove();
                markerList[item.userName || item.name || item.name] = undefined;
            }
        }
    }


    removeMarkerPopup(){
        if (this.map._popup && this.map._popup.isOpen()) {
            this.map._popup.remove();
            this.map._panes.overlayPane.remove();
        }
    }

}
