import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { DagSourceModel } from "@app/workflow-common/services/DagSourceModel";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ChangeStageBlockModel } from "@app/workflow-common/services/models/ChangeStageBlockModel";
import { CommonDataSource } from "@app/workflow-common/services/CommonDataSource";
var ChangeStageBlockWidgetComponent = /** @class */ (function () {
    function ChangeStageBlockWidgetComponent(formBuilder) {
        this.formBuilder = formBuilder;
        this.close = new EventEmitter();
        this.inputModel = undefined;
        this.showDeleteBtn = false;
        this.changeStageBlockData = {};
        this.isLoading = false;
        this.isFormSubmitted = false;
        this.stageLists = [];
        this.stageSelect2Config = undefined;
    }
    ChangeStageBlockWidgetComponent.prototype.ngOnInit = function () {
        this.changeStageBlockData = this.dagSourceModel.getBlocksSource().getBlockByKey(this.blockObject.key);
        var blockObject = tslib_1.__assign({}, this.blockObject);
        this.setStageSelect2Config();
        var modelData = blockObject['model'] ? blockObject['model'] : undefined;
        if (modelData) {
            this.showDeleteBtn = true;
        }
        this.inputModel = new ChangeStageBlockModel(modelData);
        this.form = this.formBuilder.group({
            stageId: new FormControl(this.inputModel.stageId, [Validators.required]),
        });
    };
    ChangeStageBlockWidgetComponent.prototype.setStageSelect2Config = function () {
        var _this = this;
        this.stageLists = CommonDataSource.stagesList.filter(function (stage) { return stage.id != _this.stageId; });
        this.stageSelect2Config = {
            minimumResultsForSearch: -1
        };
    };
    ChangeStageBlockWidgetComponent.prototype.save = function () {
        this.isFormSubmitted = true;
        var stageId = this.form.value.stageId;
        this.inputModel.stageId = stageId;
        var selectedItem = this.stageLists.find(function (stage) { return stage.id == stageId; });
        if (selectedItem) {
            this.inputModel.stageDisplayName = selectedItem['name'];
        }
        var blockObject = tslib_1.__assign({}, this.blockObject);
        blockObject['model'] = this.inputModel;
        this.close.emit({
            action: 'save_and_close',
            data: blockObject
        });
    };
    ChangeStageBlockWidgetComponent.prototype.closeModal = function () {
        this.close.emit({
            action: 'close_all',
            data: null
        });
    };
    ChangeStageBlockWidgetComponent.prototype.remove = function () {
        var blockObject = tslib_1.__assign({}, this.blockObject);
        blockObject.model = this.inputModel;
        this.close.emit({
            action: 'remove_block',
            data: blockObject
        });
    };
    return ChangeStageBlockWidgetComponent;
}());
export { ChangeStageBlockWidgetComponent };
