var features = {
	'Basiccustomerreports': 1,
	'Servicereminders': 3,
	'communicationsServiceReminder': 617,
	'ServiceReminderPayment': 696,
	'Servicereminderreports': 4,
	'Customernotesandcommunications': 10,
	'Customerlogin': 13,
	'Appliances': 14,
	'Jobs': 15,
	'Jobnotesandcommunications': 17,
	'Customerinvoicing': 21,
	'Customerinvoicetotals': 22,
	'Viewallusersdiaries': 27,
	'Diary': 28,
	'Certificates': 32,
	'Usersattendance': 33,
	'Customersandworkaddresses': 34,
	'Sendtextmessages': 36,
	'Customerpayments': 38,
	'Estimates': 41,
	'Supplierinvoices': 43,
	'Suppliers': 45,
	'Supplierpayments': 47,
	'Setupnominalaccounts': 49,
	'Stocknotifications': 59,
	'Setuprolemanagement': 69,
	'Setupcustomertitles': 70,
	'Setupservicereminders': 71,
	'Setupadvertising': 73,
	'Setupjobdescriptions': 74,
	'Setupinvoicebreakdowncategories': 75,
	'Setuppaymentmethods': 77,
	'Setupmodels': 80,
	'Setupestimatetemplates': 81,
	'Commusoftinvoices': 85,
	'Setupengineerqualifications': 90,
	'Backups': 91,
	'Setupjobsheetdesign': 94,
	'Transferconvertandmergecustomers': 97,
	'Customerstatement': 98,
	'Purchaseorder': 104,
	'Setupcreditordays': 105,
	'Transferandadjuststocklevels': 118,
	'Stocksearch': 119,
	'Invoiceaddresses': 120,
	'Setuplocations': 122,
	'Postcodelookup': 123,
	'Setupappliancegroups': 128,
	'Creditsnote': 145,
	'Jobattachedfiles': 151,
	'Setupdiary': 153,
	'Setupinvoicenote': 155,
	'Holidayyearendprocess': 156,
	'Filesnotifications': 157,
	'Setupcustomerloginrolebasedaccess': 167,
	'Setupaccountinginterface': 176,
	'Processcreditcardpayment': 180,
	'Audits': 181,
	'Setupminiappforwebsite': 211,
	'Sendprintandpost': 214,
	'Completejobasfreeofcharge': 217,
	'Completejobasaborted': 218,
	'Setuptechnicalreference': 222,
	'Pricingitems': 223,
	'Setuptypeoftelephonenumbers': 224,
	'Setupfirstcustomeraccountnumber': 225,
	'Setupcustomertypes': 226,
	'Setuptypesofbuildings': 227,
	'Setupcustomersmstemplates': 228,
	'Setupfirstestimatenumber': 229,
	'Setupestimatenote': 230,
	'Setupestimatedefaultemailmessage': 231,
	'Setupfirstjobnumber': 232,
	'Setupjobsheettemplate': 233,
	'Setupengineersmstemplate': 234,
	'Setupengineeremailjobsheetmessage': 235,
	'Setupjobsummarydesign': 236,
	'Setupfreeofchargereasons': 237,
	'Setupabortreasons': 238,
	'Setupcustomerjobconfirmation': 239,
	'Setupworkaddressjobreminder': 240,
	'Setupthecertificateemailmessage': 241,
	'Setupthecertificateprintandpostletter': 242,
	'Configurethewayinvoicenumbersaregenerated': 243,
	'Setuptheinvoicetemplatedesign': 244,
	'Setupdefaultinvoiceemailmessage': 246,
	'Setupinvoicecategories': 257,
	'Setupinvoicetermsandconditions': 258,
	'Setupaftersalescommunication': 259,
	'Setupdebtchasingcommunication': 260,
	'Setuppartsandprices': 261,
	'Setupstocklocations': 262,
	'Setupappliancemake': 263,
	'Setupindustries': 264,
	'Setupusergroups': 265,
	'Setupestimatearriveonsitequestions': 266,
	'Setupfueltypes': 267,
	'Setupcompletejobquestions': 268,
	'Setupworkaddressestimateconfirmation': 269,
	'Setupcustomerestimateconfirmation': 270,
	'Setupestimatearrivesignaturemessage': 271,
	'Setupestimateleavesignaturemessage': 272,
	'Setupcompletesignaturemessage': 273,
	'Setupleavejobquestions': 274,
	'Setupnominalgroups': 275,
	'Setupappliancetypes': 276,
	'Setuppartscategories': 277,
	'Customisenoaccessmessageforjobs': 278,
	'Setupengineerontheirwaysmsforestimates': 279,
	'Setupsub-categories': 280,
	'Setupengineerestimatesheetemailmessage': 281,
	'Setupworkaddressjobconfirmation': 282,
	'Setuprecallreason': 284,
	'Setupthestatementemailmessage': 285,
	'Vehiclesparameters': 286,
	'Setupengineerjobsheetsms': 287,
	'Customiseinvoiceitemcategories': 288,
	'Setupengineerestimatesms': 289,
	'Setupcustomcertificate': 290,
	'Setupworkaddressestimatereminder': 291,
	'Onlineestimateportal': 292,
	'Vatandcompanynumbers': 293,
	'Companydetails': 294,
	'Cispercentage': 295,
	'Chequesmadepayable': 296,
	'Contract': 297,
	'Bankdetails': 298,
	'Apikeys': 299,
	'Users': 300,
	'Setupemail': 301,
	'Setuppurchaseorderemailmessage': 302,
	'Suppliersattachedfiles': 303,
	'Customerattachedfiles': 304,
	'Userattachedfiles': 305,
	'Usernotesandcommunications': 306,
	'Suppliercontacts': 307,
	'Supplierbranches': 308,
	'Suppliernotesandcommunications': 309,
	'Customercontacts': 311,
	'Mobilesettings': 312,
	'Userreminders': 313,
	'Useremergencycontacts': 314,
	'Setuplogocertificate': 317,
	'Setupletterhead': 318,
	'Importingdata': 319,
	'Customerbranches': 324,
	'Customerinvoicesetup': 326,
	'Setuptermsandconditionforestimate': 327,
	'Advancedcustomerreporting': 328,
	'Customerreminders': 329,
	'Customerappliance': 330,
	'Invoiceaddressnotesandcommunications': 331,
	'Serviceremindersduein30dayslist': 332,
	'Invoiceaddressreminders': 333,
	'Workaddressremindersdue': 334,
	'Estimatenotesandcommunications': 335,
	'Viewallcustomersandworkaddresses': 336,
	'Servicereminderstatement': 337,
	'Customerestimatereminders': 338,
	'Servicereminderphonecalllist': 339,
	'Estimatesattachedfiles': 340,
	'Estimatetodos': 342,
	'Setupstockinventorysystem': 343,
	'Estimatebudgetcost': 344,
	'Projects': 346,
	'Purchaseorderattachedfiles': 347,
	'Automaticstatementssetup': 348,
	'Automaticdebtchasingsetup': 349,
	'Setupdefaultoptionsforaftersalescare': 350,
	'Setupstockwastagereason': 352,
	'Setupestimaterejectreasons': 353,
	'Suppliercreditnoteallocation': 356,
	'Setupestimateworkaddressaccesscommunications': 357,
	'Setupjobworkaddressaccesscommunications': 358,
	'Purchaseordernotesandcommunications': 359,
	'Notificationpreferences': 360,
	'AutomaticPostSalesCommunication': 361,
	'Estimatedetails': 362,
	'Estimatesendtocustomer': 363,
	'Estimateacceptreject': 364,
	'Supplierinvoiceattachedfiles': 365,
	'Suppliercreditnoteattachedfiles': 366,
	'Jobreminders': 367,
	'Creditnotenotesandcommunications': 368,
	'Stockhistory': 369,
	'Stockreservelist': 371,
	'Supplierstockinformation': 372,
	'Abilitytomarkstockasarrived': 373,
	'Supplierinvoicenotesandcommunications': 374,
	'Basicservicereminderreports': 375,
	'Basicsupplierreports': 377,
	'Advancedsupplierreports': 378,
	'Basicjobreports': 379,
	'Advancedjobreports': 380,
	'Basiccertificatereports': 381,
	'Advancedcertificatereports': 382,
	'Basicestimatereports': 383,
	'Advancedestimatereports': 384,
	'Basicpartsreports': 385,
	'Advancedpartsreports': 386,
	'Advancedprojectreports': 387,
	'Basicdiaryreports': 388,
	'Advanceddiaryreports': 389,
	'Basicsalesreports': 390,
	'Advancedsalesreports': 391,
	'Basicpurchaseorderreports': 392,
	'Advancedpurchaseorderreports': 393,
	'Basicexpensereports': 394,
	'Advancedexpensereports': 395,
	'Advancedprofitreports': 396,
	'Basicprofitreports': 442,
	'Advancedstockcontrolreports': 397,
	'Advancedusersreports': 398,
	'Basicusersreports': 443,
	'Basicdebtorsreports': 400,
	'Basiccreditorsreports': 401,
	'Setuponholddescriptions': 405,
	'Jobsplacedonhold': 406,
	'Setupbrandingcolours': 407,
	'Jobreservelist': 408,
	'Estimatereservelist': 409,
	'ChangeCommusoftplans': 410,
	'Purchasemorestorage': 411,
	'Setupjobarriveonsitequestions': 412,
	'Setupjobarrivesignaturemessage': 413,
	'Customisenoaccessmessageforestimates': 415,
	'Setupleaveestimatequestions': 416,
	'Recalljob': 417,
	'Jobtodos': 418,
	'Allocatetodostodiaryevents': 419,
	'Setupengineerontheirwayforjobs': 420,
	'Vehicle': 421,
	'Setupjobleavesignaturemessage': 422,
	'Setupemailsignaturemessage': 248,
	'Setupcreditcard': 424,
	'Statementpreferences': 427,
	'Travelcosts': 428,
	'CompletedJobsandNegativeDiarystatusNotification': 249,
	'Setuptravelrate': 423,
	'Dailysubcontractors': 429,
	'Jobparts': 430,
	'Customercreditnote': 432,
	'Setupjobreportemailmessage': 437,
	'Setupjobreportcustomisation': 436,
	'DiaryTimeTracking': 441,
	'Search': 440,
	'Jobreport': 426,
	'OfficeTasks': 444,
	'Setupserviceplan': 445,
	'Serviceplan': 446,
	'Setupthedefaultlistforcertificatedropdowns': 447,
	'Basicofficetaskreports': 448,
	'Jobinvoicenotesandcommunications': 449,
	'Setupmailchimpintegration': 451,
	'Setuppartkit': 456,
	'Setupnaturalformintegration': 452,
	'Setupdocparserintegration': 453,
	'Supplierpartsquotationemailmessage': 455,
	'Setuppaymentoncompletionmessage': 457,
	'Setuppaymentreceiptemailmessage': 459,
	'Bccemails': 460,
	'UPRNIntegration': 461,
	'Setupvat': 458,
	'Estimateparts': 462,
	'DiaryServiceWindows': 463,
	'SetupSkills': 464,
	'VOIPIntegration': 529,
	'OutlookIntegration': 466,
	'GmailIntegration': 467,
	'Setupjdpintegration': 473,
	'exportCustomerData': 468,
	'Viewengineerslocation': 469,
	'Vehiclereports': 470,
	'Setuptrackingdevice': 471,
	'Setupengineerprofileportal': 475,
	'LockDiaryEvents': 477,
	'SetupPartsReorder': 472,
	'TvGpsView': 480,
	'Setuppartcollectionworkflow': 478,
	'IntelligentScheduling': 481,
	'Setuphidecontactnumber': 482,
	'SetupEngineerShift': 476,
	'SetupJobDuration': 483,
	'SetupDeferredPayment': 484,
	'EngineerPartsPickup': 485,
	'WarehouseApp': 486,
	'PickingList': 487,
	'EstimateDisclaimerMessage': 489,
	'Setupzapierintegration': 474,
	'CommunicationPreference': 493,
	'StockReturnsWorkflow': 488,
	'StockReturnsSupplierPickup': 490,
	'SupplierPartsReminderEmailMessage': 491,
	'SupplierPartsUndeliveredNotification': 492,
	'SetupSalesforceIntegration': 479,
	'SetupTaxSettings': 511,
	'SetupTaxComponents': 512,
	'SetupTaxRates': 513,
	'CalendarIntegration': 498,
	'ManageCalendarIntegration': 499,
	'SetPoNumber': 514,
	'SetupStateProvinces': 516,
	'Outstandingjobsreport': 517,
	'customerAsset': 494,
	'setupAssetGroupAndTypes': 495,
	'setupAssetDropDownList': 496,
	'setupAssetFromConfiguration': 497,
	'SetupSLA': 506,
	'SLACustomerAndContract': 508,
	'ViewSLAReport': 509,
	'SetupPublicHolidayGroups': 515,
	'ViewContractReport': 520,
	'SetupSLAEmailTemplate': 521,
	'PurchaseTrackers': 518,
	'SetupServiceReminderBookingPortal': 526,
	'CustomiseInvoiceTitles': 525,
	'SetupDynamicCertificates': 528,
	'MultipleJobInvoicing': 524,
	'labourRates': 531,
	'priceBooks': 532,
	'ManageLicenses': 547,
	'ManageUserLicenses': 546,
	'SetupContractRenewal': 530,
	'consolidatedInvoiceCreditNote': 533,
	'JobDescriptionLabourRates': 543,
	'SetupDefaultJobValues': 534,
	'SetupCreditCardCharges': 535,
	'SetupJobQuestions': 536,
	'SetupAdvancedCommunicationTemplates': 537,
	'SetupJobArriveQuestions': 538,
	'SetupJobReport': 539,
	'SetupJobDescriptionArriveQuestions': 540,
	'SetupJobDescriptionJobReport': 541,
	'SetupJobDescriptionPriceBooks': 542,
	'SetupJobDescriptionPricingItems': 544,
	'SetupAutomaticInvoiceRules': 545,
	'SetupWebsiteBookingPortal': 548,
	'ChatIntegration': 510,
	'ChatBasicSettings': 522,
	'ChatAdvanceSettings': 523,
	'UKIndustryNumbers': 549,
	'Customerportal': 550,
	'AddEditCustomCertificates': 551,
	'SupplierPoIntegration': 552,
	'importEmails': 606,
	'Setupdefaultcustomerloginemailmessage': 283,
	'EnableTimecard': 602,
	'TimecardApproval': 603,
	'DailyTimecardReport': 604,
	'SummaryTimecardReport': 605,
	'StockAudits': 607,
	'SetupManageCustomFields': 609,
	'SetupCustomFieldsToObjects': 610,
	'SetupFirstOpportunityNumber': 650,
	'SetupPipelines': 651,
	'SetupOpportunityTemplates': 652,
	'SetupOpportunityCustomFields': 653,
	'SetupAutoExpireForOpportunitiesAndProposals': 654,
	'SetupProposalTemplates': 655,
	'AdvancedStyling': 656,
	'AdvancedProposalEditor': 657,
	'SetupOptionTemplates': 658,
	'Addons': 659,
	'DependantAddons': 660,
	'Finance': 661,
	'Upsell': 662,
	'SetupReportingCategories': 663,
	'SetupEmailTemplates': 664,
	'SetupSMSTemplates': 665,
	'SetupSequenceTemplates': 666,
	'AddUploadMediaPortalTagInTemplates': 667,
	'AddViewMediaPortalTagInTemplates': 668,
	'SetupOpportunityRejectReasons': 669,
	'SetupOpportunityCancelledReasons': 670,
	'SetupAcceptanceTemplate': 671,
	'SetupFinance': 672,
	'SetupMobileArriveAndSurveyTemplates': 673,
	'OpportunityDashboard': 674,
	'Opportunities': 675,
	'Variations': 676,
	'Recommendations': 677,
	'SendEmail': 678,
	'SendSMS': 679,
	'ScheduleActivity': 680,
	'RunThroughScheduledActivities': 681,
	'AttachFile': 682,
	'AddDiaryEvent': 683,
	'AddNote': 684,
	'StartSequence': 685,
	'Proposals': 686,
	'DisplayFinancialBreakdown': 687,
	'SendProposalToCustomer': 688,
	'SetupSavedColours': 689,
	'SetupCustomFonts': 690,
	'SetupBrandedComponents': 691,
	'BasicDashboards': 692,
	'BasicReports': 693,
	'PPMIntegration': 611,
	'GoogleAddressSearch': 612,
	'CommunicationsEngineer': 615,
	'Engineerdetails': 616,
	'SetupJobbookingPortal': 618,
	'CommunicationsJobbooking': 619,
	'SetupInvoicePortal': 620,
	'CommunicationsInvoice': 621,
	'InvoiceDetails': 622,
	'SetupStatementPortal': 623,
	'CommunicationsStatement': 624,
	'StatementDetails': 625,
	'SetupRebookingPortal': 626,
	'CommunicationsRebooking': 627,
	'TermsAndConditions': 628,
	'SetupAfterSalesPortal': 629,
	'CommunicationsAfterSales': 630,
	'WorkflowMarketplace': 613,
	'WorkflowSetup': 614,
	'ContractSequences': 697,
	'SalesSequences': 698,
	'Smsname': 439,
	'RegionalSettings': 694,
	'CustomerViewTemplate': 695,
	'Gocardlessinstantpay': 699,
	'SetupGoCardlessIntegration': 700,
	'DefaultEditorStyles': 701,
	'CustomTags': 702,
	'NovunaIntegration': 703,
	'SetupLetterTemplates': 704,
	'SetupGrants': 705,
	'Grants': 706,
	'SetupContentLibrary': 714,
	'SetupSupplierQuotationEmailTemplates': 715,
	'SetupOpportunityViews': 718,
	'JobDashboard': 721,
	'SetupJobViews': 722,
	'SetupJobTeams': 723,
	'SetupJobPipelines': 724,
	'JobPipelines': 726,
	'SetupSpruceIntegration': 727,
	'TaptoPayMethod': 728,
	'SetupSalesPipelineAutomation': 730,
	'SetupJobPipelineAutomation': 731,
	'SetupProposalPortal': 749,
	'SetupContractTemplates': 500,
	'SetupPPM': 501,
	'ContractTypes': 502,
	'SetupCustomerContracts': 503,
	'SetupContractTemplateLabourTravelParts': 504,
	'SetupCustomerContractLabourTravelParts': 505,
	'SetupJobsForPpmEvents': 507,
	'ContractTemplateCategories': 519,
	'Rules': 707,
	'ServiceJobs': 708,
	'RecurringCustomerInvoicing': 709,
	'PaymentCollection': 710,
	'AutomaticCommunication': 711,
	'Notifications': 712,
	'Bolt-ons': 713,
	'Setupvehiclestatus': 733,
	'Setupvehicletype': 734,
	'Setupvehiclemodels': 735,
	'Setupvehiclemakes': 736,
	'Addvehicles': 737,
	'Setupvehiclecheckforms': 738,
	'Setupvehiclechecktemplate': 739,
	'Maintenance': 740,
	'Setupvehiclereminder': 741,
	'Setupengineerdepartuenotification': 742,
	'Setupengineerlatearrivalnotification': 743,
	'Setupvehicleoutofhours': 744,
	'Listvehicles': 745,
	'Trips': 746,
	'Fleetfiles': 747,
	'Geofence': 748
}