var EmailBlockModel = /** @class */ (function () {
    function EmailBlockModel(data) {
        if (data === void 0) { data = undefined; }
        this.templateId = '';
        this.toEmailTag = [];
        this.ccEmailTag = [];
        this.bccEmailTag = [];
        this.fromEmail = '';
        this.message = '';
        this.subject = '';
        this.templateName = '';
        if (data) {
            this.templateId = data['templateId'] ? data['templateId'] : '';
            this.toEmailTag = data['toEmailTag'] ? data['toEmailTag'] : undefined;
            this.ccEmailTag = data['ccEmailTag'] ? data['ccEmailTag'] : undefined;
            this.bccEmailTag = data['bccEmailTag'] ? data['bccEmailTag'] : undefined;
            this.fromEmail = data['fromEmail'] ? data['fromEmail'] : '';
            this.subject = data['subject'] ? data['subject'] : '';
            this.templateName = data['templateName'] ? data['templateName'] : '';
            this.message = data['message'] ? data['message'] : '';
        }
    }
    return EmailBlockModel;
}());
export { EmailBlockModel };
