<form *ngIf="vehicleModelForm" [formGroup]="vehicleModelForm" (submit)="save()">
	<!-- Header -->
    <div class="modal-header">
		<h4 class="modal-title pull-left" translate>{{title}}</h4>
	</div>
	<!-- Content -->
	<div class="modal-body">
		
		<div class="body-content-section">
			<div class="row-fluid">
				<div class="span12">

					<!-- Make -->
					<div class="type-group clearfix">
						<label class="type-label required_field" translate>Make</label>
						<div class="controls">
							<select formControlName="makes">
								<option value="" [disabled]="true" translate>select.option</option>
								<option *ngFor="let make of makeList" value="{{make?.id}}">
									{{make?.text}}</option>
							</select>
						</div>
					</div>

					<!-- Vehicle Model Name -->
					<div class="type-group clearfix" >
						<label class="type-label required_field" translate>Model</label>
						<div class="controls">
							<input type="text" class="control-input test" formControlName="modelName"
							(valueChanged)="checkUniqueVehicleType($event)" inputTrim appDebounceTimer>
						</div>
						<div *ngIf="f.modelName.invalid && (f.modelName.dirty || f.modelName.touched)">
							<span *ngIf="f.modelName.errors.required" class="control-error-message show">
								<span translate>Fleet.Please.enter.vehicle.model.name</span>
							</span>
						</div>
                        <span *ngIf="uniqueName && f.modelName.valid" class="control-error-message show">
							<span translate>Fleet.This.vehicle.model.name.already.exists</span>
						</span>
					</div>

				</div>
			</div>
		</div>
	</div>

	<!-- Footer -->
	<div class="modal-footer">
		<a class="btn model-cancel-text" (click)="closePanel()" translate>Cancel</a>
		
		<button class="btn btn-primary model-save-btn" [disabled] ="!vehicleModelForm.valid || action == 'saving' || saveDisabled ">
			<span [hidden]="action == 'saving'" translate>Save</span>
			<span [hidden]="action != 'saving'" translate>Saving</span>
		</button>
	</div>
</form>
