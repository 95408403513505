import { EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
var IconListComponent = /** @class */ (function () {
    function IconListComponent(translate) {
        this.translate = translate;
        this.uploadedIconsUrlList = [];
        this.defaultIconsUrlList = [];
        this.uploadFileLimit = {
            maxSize: 1024,
            maxWidth: 30,
            maxHeight: 30,
            sizeWarnMsg: this.translate.instant('Upload.icon.max.size.warn'),
            dimensionWarnMsg: this.translate.instant('Upload.icon.max.dimension.warn')
        };
        this.selectedIcon = new EventEmitter();
        this.uploadWarning = new EventEmitter();
        this.iconFile = {};
        this.iconFilteredList = [];
        this.uploadWarningText = '';
    }
    IconListComponent.prototype.ngOnInit = function () {
        this.searchText = '';
        this.iconFilteredList = this.defaultIconsUrlList.map(function (icon) {
            icon.hidden = false;
            return icon;
        });
    };
    IconListComponent.prototype.filterIcons = function () {
        var _this = this;
        this.iconFilteredList = this.defaultIconsUrlList.map(function (icon) {
            if (!!icon.keywords.find(function (kw) { return kw.includes(_this.searchText.toLowerCase()); }) || icon.label.includes(_this.searchText.toLowerCase()) || icon.name.includes(_this.searchText.toLowerCase())) {
                icon.hidden = false;
            }
            else {
                icon.hidden = true;
            }
            return icon;
        });
    };
    IconListComponent.prototype.selectIcon = function (icon, category) {
        this.selectedIcon.emit({ icon: icon, category: category });
        this.dropdownInstance.hide();
    };
    IconListComponent.prototype.uploadIcon = function (icon) {
        var _this = this;
        var file = icon[0];
        var imageSrc;
        if (file) {
            var reader_1 = new FileReader();
            reader_1.onload = function (e) {
                imageSrc = reader_1.result;
                var img = new Image();
                img.src = imageSrc;
                if (Object.keys(_this.iconFile).length) {
                    img.onload = function () {
                        if (img.width > _this.uploadFileLimit['maxWidth'] || img.height > _this.uploadFileLimit['maxHeight']) {
                            _this.iconFile = {};
                            _this.uploadWarningText = _this.uploadFileLimit['dimensionWarnMsg'];
                        }
                        else {
                            _this.selectedIcon.emit({ icon: { url: imageSrc, name: file.name.split('.')[0] }, category: 'image' });
                            _this.dropdownInstance.hide();
                            _this.iconFile = {};
                        }
                    };
                }
            };
            reader_1.readAsDataURL(file);
        }
    };
    //  drag and drop
    IconListComponent.prototype.onFileDropped = function (files) {
        this.prepareFilesList(files);
    };
    IconListComponent.prototype.fileBrowseHandler = function (files) {
        this.prepareFilesList(files);
    };
    // deleteFile(index: number) {
    //   this.files.splice(index, 1);
    // }
    IconListComponent.prototype.uploadFilesSimulator = function (index, files) {
        var _this = this;
        setTimeout(function () {
            if (index === Object.keys(_this.iconFile).length) {
                return;
            }
            else {
                var progressInterval_1 = setInterval(function () {
                    if (_this.iconFile.progress === 100) {
                        clearInterval(progressInterval_1);
                        setTimeout(function () {
                            _this.uploadIcon(files);
                        }, 500);
                    }
                    else {
                        _this.iconFile.progress += 1;
                    }
                }, 1);
            }
        }, 500);
    };
    IconListComponent.prototype.prepareFilesList = function (files) {
        this.uploadWarningText = '';
        for (var _i = 0, files_1 = files; _i < files_1.length; _i++) {
            var item = files_1[_i];
            item.progress = 0;
            this.iconFile = item;
        }
        var fileSize = this.formatBytes(this.iconFile.size, 1); // size in KB
        this.uploadFilesSimulator(0, files);
        if (fileSize <= this.uploadFileLimit['maxSize']) {
            this.uploadFilesSimulator(0, files);
        }
        else {
            this.uploadWarningText = this.uploadFileLimit['sizeWarnMsg'];
            this.iconFile = {};
        }
    };
    IconListComponent.prototype.formatBytes = function (bytes, decimals) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        var k = 1000;
        var dm = decimals <= 0 ? 0 : decimals || 2;
        return parseFloat((bytes / k).toFixed(dm));
    };
    return IconListComponent;
}());
export { IconListComponent };
