export const KB_Datas = {
    "United Kingdom": {
        "domainName": "https://help.commusoft.com/en/",
        "supportEmail": "support@commusoft.co.uk",
        "datas": {
            "uploadSupportedFilesUrl": "articles/216574-which-file-formats-are-supported-within-commusoft",
            "searchPath": "search?utf8=%E2%9C%93&query=",
            "featureRequest": "requests/new",
            "importProperty": "articles/360021177658-Import-customers",
            "partsImport": "articles/224258868",
            "license": "articles/360014768017"
        }
    },
    "USA": {
        "domainName": "https://help.commusoft.com/en/",
        "supportEmail": "support@commusoft.us",
        "datas": {
            "uploadSupportedFilesUrl": "articles/216574-which-file-formats-are-supported-within-commusoft",
            "searchPath": "search?utf8=%E2%9C%93&query=",
            "featureRequest": "requests/new",
            "importProperty": "articles/360021177658-Import-customers",
            "partsImport": "articles/224258868",
            "license": "articles/360014768017"
        }
    }
};
