import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { InterfaceService } from "@app/interfaces";
var VehicleModelService = /** @class */ (function (_super) {
    tslib_1.__extends(VehicleModelService, _super);
    function VehicleModelService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.screenType = new BehaviorSubject('');
        _this.screenType$ = _this.screenType.asObservable();
        _this.closeModal = new BehaviorSubject('');
        _this.closeModal$ = _this.closeModal.asObservable();
        _this.saveData = new BehaviorSubject('');
        _this.saveData$ = _this.saveData.asObservable();
        return _this;
    }
    // Add/Edit vehicle model
    VehicleModelService.prototype.addOrEditVehicleModel = function (data) {
        return this.http.post(this.getFleetApiUrl("/addOrEditVehicleModels"), data);
    };
    // Delete vehicle model
    VehicleModelService.prototype.deleteVehicleModel = function (id, params) {
        return this.http.delete(this.getFleetApiUrl("/" + id + "/deleteVehicleModel", params));
    };
    // Check vehiclemodel already exists
    VehicleModelService.prototype.checkVehicleModelName = function (params) {
        return this.http.get(this.getFleetApiUrl("/is_model_exists", params));
    };
    VehicleModelService.prototype.reorderList = function (data, type) {
        return this.http.put(this.getFleetApiUrl("/reorderVehicleModels"), data);
    };
    VehicleModelService.prototype.getVehicleMake = function (data) {
        return this.http.get(this.getFleetApiUrl('/getVehicleMakesDropdown'), data);
    };
    VehicleModelService.prototype.getModelsFilter = function () {
        return this.http.get(this.getFleetApiUrl("/getVehicleModelsFilters"));
    };
    VehicleModelService.prototype.checkVehicleModel = function (id) {
        return this.http.get(this.getFleetApiUrl("/" + id + "/is_vehicle_model_deletable"));
    };
    return VehicleModelService;
}(InterfaceService));
export { VehicleModelService };
