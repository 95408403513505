import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { InterfaceService } from '@app/interfaces';
import { SettingsService } from '@app/core/settings/settings.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrackerService extends InterfaceService {
  socket: any;
  trackerData: any;
  constructor(private http: HttpClient, private appService: SettingsService) {
    super();
  }

  getTrackerData() {
    return this.appService.getTrackerData();
  }

  createSession() {
    this.trackerData = this.getTrackerData();
    let sessionUrl = this.trackerData['gps_api'] + '/session';
    let c = atob(this.trackerData['gps_api_key']).split(':');
    let headersToSet = {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Accept': 'application/json',
      'Original-Request': '',
    };

    let data = 'email=' + c[0] + '&password=' + c[1];
    let options = {
      headers: headersToSet,
      withCredentials: true,
      gpsOverride: true
    };

    return this.http.post(sessionUrl, data, options);
  }

  getSocket() {
    this.createSession().subscribe(result => {
      this.handshake();
      return this.socket;
    });
  }

  handshake() {
    let self = this;
    try {

      let socket = new WebSocket(this.trackerData['gps_api_socket']);

      socket.onopen = function (event) {
        console.log("Gps socket opened");
        self.socket = socket;
      };

      socket.onclose = function (event) {
        self.socket = null;
        console.log("Gps socket closed");
      };

      socket.onerror = function (event) {
        console.log("Error")
      };

    } catch (e) {
      console.log('')
    }
  }
}
