var SendProposalBlockModelInterface = /** @class */ (function () {
    function SendProposalBlockModelInterface() {
    }
    return SendProposalBlockModelInterface;
}());
export { SendProposalBlockModelInterface };
var SendProposalBlockModel = /** @class */ (function () {
    function SendProposalBlockModel(data) {
        if (data === void 0) { data = undefined; }
        this.fromEmail = '';
        this.message = '';
        this.subject = '';
        this.templateId = '';
        this.templateName = '';
        this.toEmailTag = [];
        this.doAttachProposal = true;
        if (data) {
            this.templateId = data['templateId'] ? data['templateId'] : '';
            this.toEmailTag = data['toEmailTag'] ? data['toEmailTag'] : [];
            this.fromEmail = data['fromEmail'] ? data['fromEmail'] : '';
            this.subject = data['subject'] ? data['subject'] : '';
            this.templateName = data['templateName'] ? data['templateName'] : '';
            this.message = data['message'] ? data['message'] : '';
            this.doAttachProposal = data.hasOwnProperty('doAttachProposal') ? data['doAttachProposal'] : true;
        }
    }
    return SendProposalBlockModel;
}());
export { SendProposalBlockModel };
