<div class="modal-header">
    <div class="modal-title pull-left" translate>{{title}}</div>
</div>
<div class="modal-body ng-partsandprices email-template-modal">
    <div class="help_section help-section-bottom" *ngIf="screenType === 'email_template' || screenType === 'letter_template' || screenType === 'sms_template'">
        <div class="help-text" translate>
            {{helpText}}
        </div>
    </div>
    <div class="body-content-section">
        <div class="row-fluid">
            <div class="span12">
                <form class="template-modal" *ngIf='templateForm' [formGroup]='templateForm'>
                    <div class="row-fluid ">
                        <div class="span12">
                            <div class="control-group" *ngIf="page == 'add'">
                                <label class="control-label required_field" translate>Folder</label>
                                <div class="controls" style="height: 30px">
                                    <app-search-template [type]="type" [screen]="screenType" [screenLabel]="screenLabel"
                                                         [isFolderSelectable]="isFolderSelectable" [treeData]="treeData"
                                                         [placeHolder]="''"
                                                         (selectedItem)="selectedFolder($event)">
                                    </app-search-template>
                                </div>
                            </div>
                            <!-- template name -->
                            <div class="control-group">
                                <label class="control-label required_field" translate>Name</label>
                                <div class="controls">
                                    <input type="text" class="control-input template-name" (keyup)="checkForm()" formControlName='templateName'
                                           maxlength="150" appDebounceTimer appAutoFocus (valueChanged)="checkUniqueName($event)">
                                    <div
                                        *ngIf="f.templateName.invalid && (f.templateName.dirty || f.templateName.touched)">
                                        <span *ngIf="f.templateName.errors.required" class="control-error-message show">
                                            <span translate>Please.enter.valid.template.name</span>
                                        </span>
                                    </div>
                                    <span *ngIf="uniqueName && f.templateName.valid" class="control-error-message show">
                                        <span translate>template.exists</span>
                                    </span>
                                </div>
                            </div>
                            <ng-container *ngIf="screenType === 'email_template' || screenType === 'letter_template'">
                                <!-- Subject -->
                                <div class="control-group" *ngIf="showSubject">
                                    <label class="control-label required_field" translate>Subject</label>
                                    <div class="controls">
                                        <quill-editor-custom [tagsList]="tagsList" [form]="templateForm"
                                                             [lableName]="'subject'"></quill-editor-custom>
                                    </div>
                                </div>

                                <!-- Message -->
                                <div class="control-group" *ngIf="screenType != 'content_library'">
                                    <label class="control-label required_field" translate>Message</label>
                                    <div class="controls message-height quill-custom-h-300 email-message">
                                        <quill-editor-custom [tagsList]="tagsList" [form]="templateForm"
                                                             [lableName]="'message'"></quill-editor-custom>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="screenType === 'call_note_template'">
                                <div class="control-group">
                                    <label class="control-label required_field" translate>Message</label>
                                    <div class="controls message-height quill-custom-h-300 email-message">
                                        <quill-editor-custom [tagsList]="tagsList" [form]="templateForm"
                                                             [lableName]="'message'" ></quill-editor-custom>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="screenType === 'sms_template'">
                                <div class="control-group">
                                    <label class="control-label required_field" translate>Message</label>
                                    <div class="controls message-height quill-custom-h-300 email-message">
                                        <quill-editor-custom [tagsList]="tagsList" [form]="templateForm"
                                                             [lableName]="'sms_message'"></quill-editor-custom>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="screenType == 'option_template'">
                                <div class="control-group">
                                    <label class="control-label" translate>Customer.displaying.name</label>
                                    <div class="controls">
                                        <input type="text" class="control-input template-name" (keyup)="checkForm()" formControlName="customerOptionName"
                                               maxlength="150" appDebounceTimer appAutoFocus>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="screenType == 'opportunity_template'">
                                <div  *ngIf="jobDescriptionAccess"  class="control-group clearfix" style="margin-bottom: 8px !important;">
                                    <label class="control-label required_field" translate>Job.description</label>
                                    <div class="controls description-padding select2-form-container">
                                        <select csSelect2 [select2Config]="{width: '100%'}" tabindex="-1" formControlName="jobDescription">
                                            <option value="" translate>Please.Choose</option>
                                            <option *ngFor="let job of jobDescriptionList" value="{{job?.id}}">
                                                {{job?.description}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div  *access="['SetupAutoExpireForOpportunitiesAndProposals.writeaccess']" class="control-group template-label-bottom clearfix" style="margin-bottom: 13px !important;" [ngClass]="expiryDays[2]?.checked && !expiryDayDisable ? '': 'template-label-padding'">
                                    <label class="control-label" translate>
                                        How.many.days.until.the.opportunity.expires?
                                    </label>
                                    <div class="expiry-block">
                                        <div class="expiry-checkbox-div">
                                            <input type="checkbox" [checked]="expiryDays[0]?.checked" (click)="checkboxChecked(0)" id="expiryOption0" name="expiryOption" value="0">
                                            <label for="expiryOption0" translate [translateParams]="{Object: dynamicDayValue}">
                                                Opportunity.expires.after.n.days.of.last.communication.if.no.proposal.is.added</label><br>
                                        </div>
                                        <div class="control-group clearfix" id="expiry-position" *ngIf="expiryDays[0]?.checked">
                                            <div class="controls span6" id="expiry">
                                                <ng-container>
                                                    <div class="input-holder">
                                                        <input type="text" class="control-input" [value]="expiryDays[0]?.value" onkeypress="return /[0-9]/i.test(event.key)" maxlength="10" (input)="validateNumber($event,0)">
                                                    </div>
                                                    <span *ngIf="expiryDays[0]?.invalid" class="control-error-message invalid-days show">
                                                        <span translate>Please.enter.valid.days</span>
										            </span>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="expiry-checkbox-div">
                                            <input type="checkbox" [checked]="expiryDays[1]?.checked" (click)="checkboxChecked(1)" id="expiryOption1" name="expiryOption">
                                            <label for="expiryOption1" translate [translateParams]="{Object: dynamicDayValue}">
                                                Proposal.expires.after.n.days.of.being.sent</label><br>
                                        </div>
                                        <div class="control-group clearfix" id="expiry-position" *ngIf="expiryDays[1]?.checked">
                                            <div class="controls span6" id="expiry">
                                                <ng-container>
                                                    <div class="input-holder">
                                                        <input type="text" class="control-input" [value]="expiryDays[1]?.value"   onkeypress="return /[0-9]/i.test(event.key)" maxlength="10" (input)="validateNumber($event,1)">
                                                    </div>
                                                    <span *ngIf="expiryDays[1]?.invalid" class="control-error-message invalid-days show">
                                                        <span translate>Please.enter.valid.days</span>
										            </span>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="expiry-checkbox-div">
                                            <input  type="checkbox" [disabled]="expiryDayDisable" [checked]="expiryDays[2]?.checked " (click)="checkboxChecked(2)" id="expiryOption2" name="expiryOption" value="2">
                                            <label for="expiryOption2" translate [translateParams]="{Object: dynamicDayValue}">
                                                Opportunity.expires.n.days.since.last.communication.after.proposal.expires</label><br>
                                        </div>
                                        <div class="control-group clearfix " id="expiry-position" *ngIf="expiryDays[2]?.checked && !expiryDayDisable">
                                            <div class="controls span6" id="expiry">
                                                <ng-container>
                                                    <div class="input-holder">
                                                        <input type="text" class="control-input" [value]="expiryDays[2]?.value"   onkeypress="return /[0-9]/i.test(event.key)" maxlength="10" (input)="validateNumber($event,2)">
                                                    </div>
                                                    <span *ngIf="expiryDays[2]?.invalid" class="control-error-message invalid-days show">
                                                        <span translate>Please.enter.valid.days</span>
										            </span>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Proposal -->
                                <div class="control-group clearfix" style="margin-bottom: 0 !important;">
                                    <label class="control-label required_field" translate>Cover page templates</label>
                                    <ng-container>
                                        <app-search-template [screen]="'proposal_template'" [screenLabel]="'Cover page templates'" [disabled]="disabled"
                                                             [showAddNewTemplate]="false" [selectedTemplateId]="templateForm.get('proposal').value"
                                                             (selectedItem)="templateSelected($event)"></app-search-template>
                                    </ng-container>
                                    <!--                                    <div class="proposal-margin-bottom controls">-->
                                    <!--                                        <select csSelect2 [select2Config]="{width: '100%'}" tabindex="-1" formControlName="proposal">-->
                                    <!--                                            <option value="" [disabled]="true" translate="">Please.Choose</option>-->
                                    <!--                                            <option *ngFor="let proposal of proposalList" [disabled]="!proposal.isFreemium && isFreemium" value="{{proposal.id}}">-->
                                    <!--                                                {{proposal.templateName}}</option>-->
                                    <!--                                        </select>-->
                                    <!--                                    </div>-->
                                </div>

                                <!-- Pipeline -->
                                <div class="control-group template-label-bottom clearfix" style="margin-bottom: 0 !important;" *ngIf="pipelineReadAccess">
                                    <label class="control-label required_field" translate>pipeline</label>
                                    <div class="template-label-padding  controls">
                                        <select csSelect2 [select2Config]="{width: '100%'}" tabindex="-1" formControlName="pipeline">
                                            <option value="" [disabled]="true" translate="">Please.Choose</option>
                                            <option *ngFor="let pipeline of pipelineList" value="{{pipeline.id}}">
                                                {{pipeline.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </ng-container>

                            <!--                            <div class="control-group display-flex" *ngIf="screenType == 'content_library'">-->
                            <!--&lt;!&ndash;                                <label class="control-label required_field" translate>Is content page?</label>&ndash;&gt;-->
                            <!--                                <div class="controls message-height email-message box-width">-->
                            <!--                                    <input type="checkbox" name="pageTypes" [checked]="contentPage" class="radio" (click)="onlyOne('content-page')">-->
                            <!--                                    <span class="control-label checkbox-label required_field" translate>Is content page?</span>-->
                            <!--                                </div>-->
                            <!--                                <div class="controls message-height email-message box-width">-->
                            <!--                                    <input type="checkbox" name="pageTypes" [checked]="coverPage" class="radio" (click)="onlyOne('cover-page')">-->
                            <!--                                    <span class="control-label checkbox-label required_field" translate>Is cover page?</span>-->
                            <!--                                </div>-->
                            <!--                            </div>-->
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <a (click)="bsModalRef.hide()" class="btn" href="javascript:void(0)">Cancel</a>
    <button (click)="saveTemplate()" [ngClass]="{'disabled loading': this.action == 'saving'}"
            [disabled]='this.action == "saving" || !this.templateForm.valid || uniqueName || saveDisabled'
            class="btn btn-primary loading_btn save_btn">
        <span *ngIf="this.action == 'save'" translate>Save</span>
        <span *ngIf="this.action == 'saving'" translate>Saving</span>
    </button>
</div>
