<div class="icn-wrapper">
    <tabset>
        <tab id="select_icon" heading="Select icon">
            <div class="select-icn-container slim-scroll thin">
                <div class="search-box">
                    <span style="padding: 7px 4px 7px 8px; height: 16px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path
                                d="M13.8067 12.86L11.54 10.6C12.2713 9.66831 12.6681 8.51777 12.6667 7.33334C12.6667 6.2785 12.3539 5.24736 11.7678 4.37029C11.1818 3.49323 10.3489 2.80965 9.37431 2.40598C8.39978 2.00231 7.32742 1.89669 6.29285 2.10248C5.25829 2.30827 4.30798 2.81622 3.5621 3.5621C2.81622 4.30798 2.30827 5.25829 2.10248 6.29285C1.89669 7.32742 2.00231 8.39978 2.40598 9.37431C2.80965 10.3489 3.49323 11.1818 4.37029 11.7678C5.24736 12.3539 6.2785 12.6667 7.33334 12.6667C8.51777 12.6681 9.66831 12.2713 10.6 11.54L12.86 13.8067C12.922 13.8692 12.9957 13.9188 13.077 13.9526C13.1582 13.9864 13.2453 14.0039 13.3333 14.0039C13.4213 14.0039 13.5085 13.9864 13.5897 13.9526C13.671 13.9188 13.7447 13.8692 13.8067 13.8067C13.8692 13.7447 13.9188 13.671 13.9526 13.5897C13.9864 13.5085 14.0039 13.4213 14.0039 13.3333C14.0039 13.2453 13.9864 13.1582 13.9526 13.077C13.9188 12.9957 13.8692 12.922 13.8067 12.86ZM3.33334 7.33334C3.33334 6.54221 3.56793 5.76885 4.00746 5.11106C4.44698 4.45326 5.0717 3.94057 5.8026 3.63782C6.53351 3.33507 7.33777 3.25585 8.1137 3.41019C8.88962 3.56454 9.60235 3.9455 10.1618 4.50491C10.7212 5.06432 11.1021 5.77705 11.2565 6.55297C11.4108 7.3289 11.3316 8.13317 11.0289 8.86407C10.7261 9.59497 10.2134 10.2197 9.55562 10.6592C8.89782 11.0987 8.12446 11.3333 7.33334 11.3333C6.27247 11.3333 5.25505 10.9119 4.50491 10.1618C3.75476 9.41162 3.33334 8.3942 3.33334 7.33334Z"
                                fill="#8E8E8E" />
                        </svg>
                    </span>
                    <input class="search-input" style="width: 83%;" placeholder="Search icon here" [(ngModel)]="searchText" (keyup)="filterIcons()" />
                    <span style="height: 16px;" data-clear-input (click)="searchText = ''; filterIcons()"
                        *ngIf="searchText">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path
                                d="M8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15ZM5.78516 5.78516C6.04219 5.52812 6.45781 5.52812 6.71211 5.78516L7.99727 7.07031L9.28242 5.78516C9.53945 5.52812 9.95508 5.52812 10.2094 5.78516C10.4637 6.04219 10.4664 6.45781 10.2094 6.71211L8.92422 7.99727L10.2094 9.28242C10.4664 9.53945 10.4664 9.95508 10.2094 10.2094C9.95234 10.4637 9.53672 10.4664 9.28242 10.2094L7.99727 8.92422L6.71211 10.2094C6.45508 10.4664 6.03945 10.4664 5.78516 10.2094C5.53086 9.95234 5.52812 9.53672 5.78516 9.28242L7.07031 7.99727L5.78516 6.71211C5.52812 6.45508 5.52812 6.03945 5.78516 5.78516Z"
                                fill="#8E8E8E" />
                        </svg>
                    </span>
                </div>

                <div class="list-wrapper">
                    <div class="list-icon d-flex flex-wrap" *ngIf="iconFilteredList['length']; else no_result">
                        <div class="icon" *ngFor="let icon of iconFilteredList" [style.display]="icon.hidden ? 'none' : 'flex'" (click)="selectIcon(icon, 'FA')">
                            <!-- <img src="{{icon}}" alt=""> -->
                            <i [class]="'fas '+icon.className"></i>
                        </div>
                    </div>

                    <ng-template #no_result>
                        <div class="no-result-wrapper">
                            <div class="no-result-icon">
                                <img src="../angularES/src/assets/images/no_result.svg" alt="">
                            </div>

                            <div class="no-result-text" translate>No.search.result</div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </tab>
        <tab id="upload_icon" heading="Uploaded icon">
            <div class="upload-icn-container">
                <!--drag and drop-->
                <div class="drag-and-drop">
                    <div class="container" [ngClass]="!iconFile['name'] ? uploadWarningText ? 'warning-border' : 'primary-border' : 'transparent-border'" appDragAndDrop (fileDropped)="onFileDropped($event)">
                        <div class="overlay-text-inner" *ngIf="!iconFile['name']">
                            <input type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files)" accept="image/*"/>
                            <div class="upload-icon">
                                <img src="../angularES/src/assets/images/upload_icon.svg" alt="">
                            </div>
                            <div class="upload-icon-text" translate>Upload.icon.text</div>
                            <div class="upload-format-text" *ngIf="!uploadWarningText" translate>Upload.icon.formats</div>
                            <div class="upload-format-text warning-text" *ngIf="uploadWarningText" translate>{{uploadWarningText}}.</div>
                        </div>

                        <div class="files-list" *ngIf="iconFile['name']">
                            <div class="single-file">
                                <div class="info">
                                    <div class="progress-wrapper">
                                        <div class="upload-icon">
                                            <img src="../angularES/src/assets/images/upload_icon.svg" alt="">
                                        </div>
                                        <div class="progress-percentage">
                                            Uploading&nbsp;{{ iconFile && iconFile['progress'] ? iconFile.progress : 0 }}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--            end-->
                <p class="help-text" translate>Con.Icon.Pixel.Size<br>Con.Icon.File.Size</p>

                <div class="list-wrapper" *ngIf="uploadedIconsUrlList['length']">
                    <div class="list-icon d-flex flex-wrap">
                        <div class="icon" *ngFor="let icon of uploadedIconsUrlList" (click)="selectIcon(icon, 'image')">
                            <img src="{{icon}}" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </tab>
    </tabset>
</div>
