import { OnInit, ElementRef, EventEmitter, Renderer } from '@angular/core';
import { NgControl } from "@angular/forms";
var CsSelect2Directive = /** @class */ (function () {
    function CsSelect2Directive(_hostRef, _renderer, control) {
        this._hostRef = _hostRef;
        this._renderer = _renderer;
        this.control = control;
        this.placeholder = '-- Please choose --';
        this.value = '';
        this.valueEmitter = new EventEmitter();
        this.opened = new EventEmitter();
        this.$instance = new EventEmitter();
        this.onChange = new EventEmitter();
        this.choiceSelected = new EventEmitter();
    }
    CsSelect2Directive.prototype.ngOnInit = function () {
        // console.log(this.value);
        this.select2Config['placeholder'] = this.placeholder;
    };
    CsSelect2Directive.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.control && this.value === '') {
            this.value = this.control.control.value;
        }
        this.select2 = $(this._hostRef.nativeElement)
            .select2(this.select2Config)
            .select2('val', this.value);
        this.select2.on('select2-selected', function (event) {
            _this.valueEmitter.emit(event.val);
            if (_this.control) {
                _this.control.control.setValue(event.val);
            }
        });
        this.select2.on('change', function (event) {
            _this.valueEmitter.emit(event.val);
            _this.onChange.emit(event.val);
        });
        this.select2.on('select2-open', function (event) {
            _this.opened.emit(true);
            if (_this.control) {
                _this.control.control.markAsTouched();
            }
        });
        this.select2.on('choice-selected', function (event, s2Container, selectedEl) {
            var existingSelectedEl = s2Container.find('.s2-choice-active');
            var jQSelectedEl = $(selectedEl);
            var selectedData = $(selectedEl).data('select2-data');
            if (existingSelectedEl && selectedEl && (existingSelectedEl[0] !== $(selectedEl)[0])) {
                existingSelectedEl.removeClass('s2-choice-active');
            }
            $(selectedEl).addClass('s2-choice-active');
            _this.choiceSelected.emit({ data: selectedData, jQEl: jQSelectedEl });
        });
        this.$instance.emit(this.select2);
    };
    return CsSelect2Directive;
}());
export { CsSelect2Directive };
