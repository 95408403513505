import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import {Observable, Subject} from 'rxjs';
import { FolderService } from '../service/folder.service';

@Component({
    selector: 'app-add-edit-folder-modal',
    templateUrl: './add-edit-folder-modal.component.html',
    styleUrls: ['./add-edit-folder-modal.component.scss']
})
export class AddEditFolderModalComponent implements OnInit {
    public onClose: Subject<boolean>;
    title: string;
    name: string;
    folderId: number;
    form: FormGroup;
    action: string = '';
    url:string;
    editId:any = '';
    @ViewChild('saveBtn') saveBtn: ElementRef;
    uniqueName:boolean = false;
    screen: string;
    addOn:any;
    tableType: number;
    addOrEditMethod?: (arg: any) => Observable<any> | null;
    checkIsFolderExistsMethod?: (arg: any) => Observable<any> | null;

    constructor(
        private formBuilder: FormBuilder,
        private httpService: FolderService,
        public bsModalRef: BsModalRef,
    ) {
    }


    get f() {
        return this.form.controls;
    }
    ngOnInit() {
        this.onClose = new Subject();
        this.form = this.formBuilder.group({
            'name': [this.name, [Validators.required, Validators.minLength(1)]],
        });
        if(this.name != ''){
            this.form.controls['name'].setValue(this.name);
        }
    }

    checkUniqueName(value) {
        if(this.screen == 'content_library' || this.screen === 'custom_forms'){
            let text = value
            let data = {
                title: text,
                screen: this.screen,
                isFolder: 1,
            }
            let checkName = false;
            if (this.editId) {
                if (this.name == value) {
                    checkName = false;
                } else {
                    checkName = true;
                }
            } else {
                checkName = true;
            }
            if (checkName) {
                if (this.checkIsFolderExistsMethod) {
                    data['tableType'] = this.tableType;
                    this.checkIsFolderExistsMethod(data).subscribe(res => this.uniqueName = res);

                    /*
                    Note: This has been implemented for avoiding the code duplication & patching as below.
                     - So, once this common-logic replaced for all, then the below code should be removed.
                     */

                    return;
                }
                this.httpService.checkUniqueFolderName(data).subscribe((resp: any) => {
                    this.uniqueName = resp
                })
            }
        }
    }

    save() {
        if (!this.form.valid) {
            this.action = '';
            return false;
        }
        this.action = 'saving';
        let folderDetails:any = {
            folderName:this.form.controls['name'].value,
            folderId:this.folderId,
            editId:this.editId
        };
        if(this.addOn) {
            folderDetails['addOn'] = this.addOn;
        }

        if (this.addOrEditMethod) {
            folderDetails['tableType'] = this.tableType;
            this.addOrEditMethod(folderDetails).subscribe(resp => {
                this.action = '';
                this.onClose.next(resp);
                this.bsModalRef.hide();
            }, error => {
                this.action = '';
                console.log('err', error);
            });

            /*
            Note: This has been implemented for avoiding the code duplication & patching as below.
             - So, once this common-logic replaced for all, then the below code should be removed.
             */

            return;
        }

        if(this.screen == 'vehicleForms'){
            this.httpService.postApiV5Call(this.url, folderDetails)
                .subscribe((resp: any) => {
                    this.action = '';
                    this.onClose.next(resp);
                    this.bsModalRef.hide();
                }, error => {
                    this.action = '';
                    console.log('err', error);
                });
        }else {
            this.httpService.postApiCall(this.url, folderDetails)
                .subscribe((resp: any) => {
                    this.action = '';
                    this.onClose.next(resp);
                    this.bsModalRef.hide();
                }, error => {
                    this.action = '';
                    console.log('err', error);
                });
        }
    }
}
