import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-icon-list',
    templateUrl: './icon-list.component.html',
    styleUrls: ['icon-list.component.scss']
})
export class IconListComponent implements OnInit {

    @Input() dropdownInstance;
    @Input() uploadedIconsUrlList = [];
    @Input() defaultIconsUrlList = [];
    @Input() uploadFileLimit = {
                maxSize: 1024,  // KB
                maxWidth: 30,   // px
                maxHeight: 30,   // px
                sizeWarnMsg: this.translate.instant('Upload.icon.max.size.warn'),
                dimensionWarnMsg: this.translate.instant('Upload.icon.max.dimension.warn')
            };
    @Output() selectedIcon = new EventEmitter<any>();
    @Output() uploadWarning = new EventEmitter<any>();

    iconFile: any = {};
    searchText: string;
    iconFilteredList = [];
    uploadWarningText = '';

    constructor(private translate: TranslateService) {
    }

    ngOnInit() {
        this.searchText = '';
        this.iconFilteredList = this.defaultIconsUrlList.map((icon) => {
            icon.hidden = false;
            return icon;
        });
    }

    filterIcons() {
        this.iconFilteredList = this.defaultIconsUrlList.map(icon => {
            if(!!icon.keywords.find((kw) => kw.includes(this.searchText.toLowerCase())) || icon.label.includes(this.searchText.toLowerCase()) || icon.name.includes(this.searchText.toLowerCase())){
                icon.hidden = false;
            } else {
                icon.hidden = true;
            }
            return icon;
        });
    }

    selectIcon(icon, category) {
        this.selectedIcon.emit({icon, category});
        this.dropdownInstance.hide();
    }

    uploadIcon(icon) {
        let file = icon[0];

        let imageSrc;

        if (file) {
            const reader = new FileReader();
            reader.onload = e => {
                imageSrc = reader.result;
                const img = new Image();
                img.src = imageSrc;

                if (Object.keys(this.iconFile).length) {
                    img.onload = () => {
                        if (img.width > this.uploadFileLimit['maxWidth'] || img.height > this.uploadFileLimit['maxHeight']) {
                            this.iconFile = {};
                            this.uploadWarningText = this.uploadFileLimit['dimensionWarnMsg'];
                        } else {
                            this.selectedIcon.emit({icon: { url: imageSrc, name:file.name.split('.')[0]}, category: 'image'});
                            this.dropdownInstance.hide();
                            this.iconFile = {};
                        }
                    };
                }
            };
            reader.readAsDataURL(file);
        }
    }


//  drag and drop
    onFileDropped(files) {
        this.prepareFilesList(files);
    }

    fileBrowseHandler(files) {
        this.prepareFilesList(files);
    }

    // deleteFile(index: number) {
    //   this.files.splice(index, 1);
    // }

    uploadFilesSimulator(index: number, files) {
        setTimeout(() => {
            if (index === Object.keys(this.iconFile).length) {
                return;
            } else {
                const progressInterval = setInterval(() => {
                    if (this.iconFile.progress === 100) {
                        clearInterval(progressInterval);
                        setTimeout(() => {
                            this.uploadIcon(files);
                        }, 500);
                    } else {
                        this.iconFile.progress += 1;
                    }
                }, 1);
            }
        }, 500);
    }

    prepareFilesList(files) {
        this.uploadWarningText = '';

        for (const item of files) {
            item.progress = 0;
            this.iconFile = item;
        }

        const fileSize = this.formatBytes(this.iconFile.size, 1);    // size in KB

        this.uploadFilesSimulator(0, files);

        if (fileSize <= this.uploadFileLimit['maxSize']) {
            this.uploadFilesSimulator(0, files);
        } else {
            this.uploadWarningText = this.uploadFileLimit['sizeWarnMsg'];
            this.iconFile = {};
        }
    }

    formatBytes(bytes, decimals) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1000;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        return parseFloat((bytes / k).toFixed(dm));
    }
}
