import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// --
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccessDirective } from './access.directive';
import { ValidationErrorComponent, ValidationCustomErrorMessageComponent } from './form-validators/validation-error.component';
import { CsPaginationMigratedDirective } from './material-directives/cs-pagination-migrated.directive';
import { CsSidepanelComponent } from './material-directives/cs-sidepanel/cs-sidepanel.component';
import { CsModalComponent } from './material-directives/cs-modal/cs-modal.component';
import { ModuleAccessDirective  } from './module-access.directive';
import {TranslateModule} from '@ngx-translate/core';

// Input directives
import { DebounceTimerDirective } from './input-directives/debounce-timer.directive';
import { CurrencyInputDirective } from './input-directives/currency-input.directive';
import { CsTimepickerComponent } from './input-directives/cs-timepicker/cs-timepicker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CsToastBoxComponent } from './material-directives/cs-toast-box/cs-toast-box.component';
import { CsInfiniteScrollComponent } from './material-directives/cs-infinite-scroll/cs-infinite-scroll.component';
import { CsInfiniteScrollLineItemDirective } from './material-directives/cs-infinite-scroll/cs-infinite-scroll-line-item.directive';
import { LifecycleCallbacksDirective } from './functional-directives/lifecycle-callbacks.directive';
import { CsSelect2Directive } from './material-directives/cs-select2/cs-select2.directive';
import { CsTaggingComponent } from './material-directives/cs-tagging/cs-tagging.component';
import { PipesModule } from '@app/pipes';
import { AssetWidgetModalMigratedDirective } from '@app/directives/material-directives/asset-widget-modal-migrated.directive';
import { CsPagingoptionComponent } from './material-directives/cs-pagingoption/cs-pagingoption.component';
import { TableActionsDropdownComponent } from './material-directives/table-actions-dropdown/table-actions-dropdown.component';
import {CsPdfdownloadoptionMigratedDirective} from './material-directives/cs-pdfdownloadoption-migrated.directive';
import { TinymceComponent } from './tinymce/tinymce.component';
import { CsConfirmationModalComponent } from './material-directives/cs-confirmation-modal/cs-confirmation-modal.component';
import { CsFullScreenModalComponent } from "@app/directives/material-directives/cs-full-screen-modal/cs-full-screen-modal.component";
import { CsSalesFullScreenModalComponent } from '@app/directives/material-directives/cs-sales-full-screen-modal/cs-sales-full-screen-modal.component'
import { DigitDecimalInputDirective } from "@app/directives/input-directives/digit-decimal-input.directive";
import {ReactComponentDirective} from "@app/directives/material-directives/react-component.directive";
import { CheckValueExistsDirective } from './input-directives/check-value-exists.directive';
// tslint:disable-next-line:max-line-length
import { ServiceReminderDateChangeComponent } from './custom-directives/service-reminder-date-change/service-reminder-date-change.component';
import { CsInformationBarComponent } from './material-directives/cs-information-bar/cs-information-bar.component';
import { AutoFocusDirective } from './input-directives/auto-focus.directive';
import { CsSignatureGeneratorComponent } from './material-directives/cs-signature-generator/cs-signature-generator.component';
import { CsPhonewidgetComponent } from './input-directives/cs-phonewidget/cs-phonewidget.component';
import { CsCustomLoaderComponent } from './material-directives/cs-custom-loader/cs-custom-loader.component';
import { ColorPalatteComponent } from './color-palatte/color-palatte.component';
import { NgColorModule } from 'ng-color';

import { IntegrationsService } from '@app/features/company-settings/integrations/services/integrations.service';
import { CsToggleuserpreferenceComponent } from './input-directives/cs-toggleuserpreference/cs-toggleuserpreference.component';
import { CsHeaderDirective } from './material-directives/cs-full-screen-modal/cs-header.directive';
import { QuillEditorCustomComponent } from './quill-editor-custom/quill-editor-custom.component';
import { QuillModule } from 'ngx-quill';
import {CsTaggingService} from "@app/directives/material-directives/cs-tagging/cs-tagging.service";
import {
    CsSliderComponent
} from "@app/directives/input-directives/cs-slider/cs-slider.component";
import {
    ScheduledActivitiesComponent
} from "@app/directives/input-directives/cs-slider/scheduled-activities/scheduled-activities";
import {
    PinnedAssetsComponent
} from "@app/directives/input-directives/cs-slider/pinned-assets/pinned-assets";
import { PinnedNotesComponent } from './input-directives/cs-slider/pinned-notes/pinned-notes.component';
import {NoScrollDirective} from "@app/directives/no-scroll.directive";
import {CsDatepickerComponent} from "@app/directives/input-directives/cs-datepicker/cs-datepicker.component";
import {getDatepickerConfig} from "@app/directives/input-directives/bs-datepicker.config";
import { CsModalAssetComponent } from './material-directives/cs-modal-asset/cs-modal-asset.component';
import {CsScrollbarModule} from "@app/directives/cs-scrollbar/cs-scrollbar.module";
import {
    CsModalContractCancelComponent
} from '@app/directives/material-directives/cs-modal-contract-cancel/cs-modal-contract-cancel.component';
import { BsDatepickerConfig, BsDatepickerModule, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import {InputTrimDirective} from "@app/directives/input-trim.directive";

@NgModule({
    declarations: [
        ValidationErrorComponent,
        ValidationCustomErrorMessageComponent,

        // Functional
        AccessDirective,
        LifecycleCallbacksDirective,
        ModuleAccessDirective,


        // Materials
        CsPaginationMigratedDirective,
        CsSidepanelComponent,
        CsModalComponent,
        CsToastBoxComponent,
        CsModalComponent,
        CsInfiniteScrollComponent,
        CsInfiniteScrollLineItemDirective,
        CsSelect2Directive,
        AssetWidgetModalMigratedDirective,
        ReactComponentDirective,
        CsPagingoptionComponent,
        TableActionsDropdownComponent,
        CsPdfdownloadoptionMigratedDirective,

        // Input directives
        DebounceTimerDirective,
        CurrencyInputDirective,
        CsTimepickerComponent,
        CsTaggingComponent,
        TinymceComponent,
        CsConfirmationModalComponent,
        CsFullScreenModalComponent,
        DigitDecimalInputDirective,
        CheckValueExistsDirective,
        ServiceReminderDateChangeComponent,
        CsInformationBarComponent,
        AutoFocusDirective,
        CsSignatureGeneratorComponent,
        CsToggleuserpreferenceComponent,
        CsPhonewidgetComponent,
        CsCustomLoaderComponent,
        CsSalesFullScreenModalComponent,
        ColorPalatteComponent,
        CsHeaderDirective,
        CsToggleuserpreferenceComponent,
        CsSliderComponent,
        ScheduledActivitiesComponent,
        PinnedAssetsComponent,
        PinnedNotesComponent,
        CsDatepickerComponent,
        CsSalesFullScreenModalComponent,
        ColorPalatteComponent,
        CsModalAssetComponent,
        CsModalContractCancelComponent,
        QuillEditorCustomComponent,
        NoScrollDirective,
        InputTrimDirective
    ],
    imports: [
        CommonModule,
        TooltipModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        TranslateModule,
        BsDatepickerModule,
        NgColorModule,
        QuillModule,
        CsScrollbarModule,
    ],
    providers: [IntegrationsService, { provide: BsDatepickerConfig, useFactory: getDatepickerConfig }, CsTaggingService],
    entryComponents: [
        CsSignatureGeneratorComponent
    ],
    exports: [
        ValidationErrorComponent,
        ValidationCustomErrorMessageComponent,

        // Functional
        AccessDirective,
        LifecycleCallbacksDirective,
        ModuleAccessDirective,


        // Materials
        CsPaginationMigratedDirective,
        CsSidepanelComponent,
        CsModalComponent,
        CsToastBoxComponent,
        CsModalComponent,
        CsInfiniteScrollComponent,
        CsInfiniteScrollLineItemDirective,
        CsSelect2Directive,
        AssetWidgetModalMigratedDirective,
        ReactComponentDirective,
        CsPagingoptionComponent,
        TableActionsDropdownComponent,
        CsPdfdownloadoptionMigratedDirective,
        CsConfirmationModalComponent,

        // Input directives
        DebounceTimerDirective,
        CurrencyInputDirective,
        CsTimepickerComponent,
        CsTaggingComponent,
        TinymceComponent,
        CsFullScreenModalComponent,
        DigitDecimalInputDirective,
        CheckValueExistsDirective,
        ServiceReminderDateChangeComponent,
        CsInformationBarComponent,
        AutoFocusDirective,
        CsSignatureGeneratorComponent,
        CsToggleuserpreferenceComponent,
        CsPhonewidgetComponent,
        CsCustomLoaderComponent,
        CsToggleuserpreferenceComponent,
        CsSliderComponent,
        ScheduledActivitiesComponent,
        PinnedAssetsComponent,
        CsSalesFullScreenModalComponent,
        ColorPalatteComponent,
        CsModalAssetComponent,
        CsModalContractCancelComponent,
        CsScrollbarModule,
        BsDatepickerModule,
        QuillEditorCustomComponent,
        CsDatepickerComponent,
        NoScrollDirective,
        InputTrimDirective
    ],
})
export class DirectivesModule {
}
