import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { CommonDataSource } from "@app/workflow-common/services/CommonDataSource";
var TemplateViewLayoutComponent = /** @class */ (function () {
    function TemplateViewLayoutComponent() {
        this.emptyMessage = 'You must select a template before the preview can be displayed';
        this.message = '';
        this.subject = '';
        this.type = 'email';
        this.showType = false;
        this.showEmptyMessage = true;
        this.select2Options = {
            minimumResultsForSearch: -1
        };
        this.loadingMessage = true;
        this.previewModel = 'email';
        this.currentViewPort = 'desktop';
        this.viewOptions = {
            email: [{ id: 'email', text: 'Email' }],
            sms: [{ id: 'sms', text: 'SMS' }],
            letter: [{ id: 'letter', text: 'Letter' }],
        };
        this.dropDownItems = [];
        this.randomMobileNumber = 0;
        this.clientDetails = undefined;
    }
    TemplateViewLayoutComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.showEmptyMessage = !this.message;
                        if (this.type) {
                            this.previewModel = this.type;
                        }
                        this.randomMobileNumber = this.generatePhoneNumber();
                        this.dropDownItems = this.viewOptions[this.type];
                        if (this.type == 'sms') {
                            this.currentViewPort = 'mobile';
                        }
                        return [4 /*yield*/, CommonDataSource.getClientDetails()
                                .then(function (client) {
                                _this.clientDetails = client;
                            })];
                    case 1:
                        _a.sent();
                        this.loadingMessage = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    TemplateViewLayoutComponent.prototype.onChangePreviewOption = function (value) {
        if (value == 'sms' || value == 'letter')
            this.currentViewPort = 'mobile';
        else if (value == 'email')
            this.currentViewPort = 'desktop';
        this.reloadMessage();
    };
    TemplateViewLayoutComponent.prototype.changeViewPort = function (view) {
        if (view === this.currentViewPort)
            return;
        this.currentViewPort = view;
        this.reloadMessage();
    };
    TemplateViewLayoutComponent.prototype.reloadMessage = function () {
        var _this = this;
        this.loadingMessage = true;
        setTimeout(function () {
            _this.loadingMessage = false;
        }, 600);
    };
    TemplateViewLayoutComponent.prototype.generatePhoneNumber = function () {
        return Math.floor(Math.random() * 9000000000) + 1000000000;
    };
    return TemplateViewLayoutComponent;
}());
export { TemplateViewLayoutComponent };
