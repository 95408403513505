import * as tslib_1 from "tslib";
import { ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ModalContainerComponent } from "ngx-bootstrap/modal";
import { SidepanelOptions } from "@app/sidepanel/services/sidepanel-options";
import { TranslateService } from '@ngx-translate/core';
import { SidepanelService } from "@app/sidepanel/services/sidepanel.service";
// noinspection JSAnnotator
var SidepanelComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SidepanelComponent, _super);
    function SidepanelComponent(options, element, confirmationBoxHelper, ajsScope, translate, sidepanelService, renderer) {
        var _this = _super.call(this, options, element, renderer) || this;
        _this.element = element;
        _this.confirmationBoxHelper = confirmationBoxHelper;
        _this.ajsScope = ajsScope;
        _this.translate = translate;
        _this.sidepanelService = sidepanelService;
        _this.renderer = renderer;
        _this.clickStartedInContent = false;
        _this.modalscount = 0;
        return _this;
    }
    SidepanelComponent.prototype.ngOnInit = function () {
        this.modalscount = this.bsModalService.getModalsCount();
        if (this.modalscount == 2) {
            var parentInstance = this.bsModalService['loaders'][this.modalscount - 2].instance;
            var childInstance = this.bsModalService['loaders'][this.modalscount - 1].instance;
            var parentElem = parentInstance.element.nativeElement;
            var childElem = childInstance.element.nativeElement;
            var offset_1 = (childElem.offsetWidth / 2);
            if (parentInstance.config.size == 'sm' && childInstance.config.size == 'xl') {
                offset_1 = (childElem.offsetWidth - parentElem.offsetWidth / 2) + 140;
            }
            var parentElemStyle_1 = parentElem.style;
            setTimeout(function () {
                parentElemStyle_1.transform = 'translate3d(-' + offset_1 + 'px, 0, 0)';
            }, 100);
        }
        _super.prototype.ngOnInit.call(this);
        if (this.config.size != '')
            this.renderer.addClass(this.element.nativeElement, this.config.size);
        if (this.bsModalService.getModalsCount()) {
            this.renderer.addClass(this.element.nativeElement, 'modal-level-' + this.bsModalService.getModalsCount());
        }
    };
    SidepanelComponent.prototype.onClickStarted = function (event) {
        var _this = this;
        this.clickStartedInContent = event.target !== this.element.nativeElement;
        if (event.target.tagName === 'BS-MODAL-BACKDROP' || event.target.tagName === 'CS-MODAL-BACKDROP') {
            if (this.config.closeConfirmation) {
                this.confirmationBoxHelper
                    .getConfirmation(this.translate.instant('Are.you.sure.you.want.to.close.side.panel?'), this.ajsScope)
                    .then(function () {
                    if ($('app-table-formatter-options').length > 0) {
                        var msg = { type: 'refreshTable', id: true };
                        window.top.postMessage(msg, '*');
                    }
                    $('#cancel-styles').trigger('click');
                    _this.hide();
                    // emit modal hide event
                    _this.sidepanelService.OpportunityModalClose();
                    _this.sidepanelService.closePagePropertiesSidePanel();
                    _this.sidepanelService.onCloseSidePanelModal();
                });
            }
            else {
                $('#cancel-styles').trigger('click');
                this.hide();
                // emit modal hide event
                this.sidepanelService.OpportunityModalClose();
            }
        }
    };
    SidepanelComponent.prototype.onEsc = function (event) {
        var _this = this;
        if (event.keyCode === 27 || event.key === 'Escape') {
            event.preventDefault();
        }
        if (this.isShown) {
            this.confirmationBoxHelper
                .getConfirmation(this.translate.instant('Are.you.sure.you.want.to.close.side.panel?'), this.ajsScope)
                .then(function () {
                _this.hide();
                // emit modal hide event
                _this.sidepanelService.OpportunityModalClose();
            });
        }
    };
    return SidepanelComponent;
}(ModalContainerComponent));
export { SidepanelComponent };
