import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {InterfaceService} from "@app/interfaces";

@Injectable({
  providedIn: 'root'
})
export class FleetLiveMapService extends InterfaceService{

  constructor(private http: HttpClient) {
      super();
  }

    saveCurrentSelectedLocation(data:Object): Observable<any> {
        return this.http.post(this.getFleetApiUrl(`addSavedLocations`),data);
    }

}
