import {NgModule} from '@angular/core';
import { CommonModule } from "@angular/common";
import {CommunicationActionComponent} from "@app/shared/communication-mapping/action/communication-action.component";
import { CommunicationContainerComponent } from './container/communication-container.component';
import { CommunicationLayoutComponent } from './layouts/communication-layout.component';
import { CommunicationSegmentComponent } from './layouts/segments/communication-segment.component';
import { CommunicationTemplateViewComponent } from './layouts/templates/communication-template-view.component';
import { TemplateFormComponent } from './layouts/template-form/template-form.component';
import { DirectivesModule } from "@app/directives";
import { FormsModule } from "@angular/forms";
import { CommunicationHttpService } from "@app/shared/communication-mapping/services/communication-http.service";
import { CommunicationDispatcherService } from "@app/shared/communication-mapping/services/communication-dispatcher.service";
import { AddCommunicationComponent } from './layouts/add-communication/add-communication.component';
import { PipesModule } from "@app/pipes";
/*import { HtmlEditorComponent } from './html-editor/html-editor.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';*/
import {CommunicationHelperService} from "@app/shared/communication-mapping/services/communication-helper.service";
import { CommonFolderStructureModule } from '../common-folder-structure/common-folder-structure.module';
import {TranslateModule} from "@ngx-translate/core";
import {CsComponentsModule} from "@app/shared/cs-components/cs-components.module";

@NgModule({
    declarations: [
        CommunicationActionComponent,
        CommunicationContainerComponent,
        CommunicationLayoutComponent,
        CommunicationSegmentComponent,
        CommunicationTemplateViewComponent,
        TemplateFormComponent,
        AddCommunicationComponent,
        // HtmlEditorComponent,
    ],
    imports: [
        CommonModule,
        DirectivesModule,
        FormsModule,
        PipesModule,
        CommonFolderStructureModule,
        TranslateModule,
        /*FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),*/
        CsComponentsModule
    ],
    providers: [
        CommunicationHttpService,
        CommunicationDispatcherService,
        CommunicationHelperService
    ],
    exports: [
        CommunicationActionComponent,
        CommunicationContainerComponent,
        CommunicationLayoutComponent,
        // HtmlEditorComponent,
    ],
    entryComponents: [
        CommunicationContainerComponent,
        AddCommunicationComponent
    ]
})
export class CommunicationMappingModule {}
