import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from '@app/interfaces';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SharedModalService = /** @class */ (function (_super) {
    tslib_1.__extends(SharedModalService, _super);
    function SharedModalService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.emailModalDetails = new BehaviorSubject('');
        _this.emailModalDetails$ = _this.emailModalDetails.asObservable();
        _this.emailSaveAction = new BehaviorSubject('');
        _this.emailSaveAction$ = _this.emailSaveAction.asObservable();
        _this.scheduledActivityDetails = new BehaviorSubject('');
        _this.scheduledActivityDetails$ = _this.scheduledActivityDetails.asObservable();
        _this.emailEmittedValue = new BehaviorSubject('');
        _this.emailEmittedValue$ = _this.emailEmittedValue.asObservable();
        _this.commonModalEmittedValue = new BehaviorSubject('');
        _this.commonModalEmittedValue$ = _this.commonModalEmittedValue.asObservable();
        _this.modalOpenStatus = '';
        _this.types = {
            'sms': false,
            'note': false,
            'call': false,
            'files': false,
        };
        _this.diaryCompletedStatus = '';
        _this.groupModalStatus();
        return _this;
    }
    SharedModalService.prototype.setEmailEmittedDetails = function (data) {
        this.emailEmittedValue.next(data);
    };
    SharedModalService.prototype.setCommonModalEmittedValue = function (data) {
        this.commonModalEmittedValue.next(data);
    };
    SharedModalService.prototype.setEmailModalDetails = function (data) {
        this.emailModalDetails.next(data);
    };
    SharedModalService.prototype.setEmailSaveAction = function (data) {
        this.emailSaveAction.next(data);
    };
    SharedModalService.prototype.setScheduledActivityDetails = function (data) {
        this.scheduledActivityDetails.next(data);
    };
    SharedModalService.prototype.getEmailModalDetails = function (url) {
        return this.http.get(this.getApiUrl(url));
    };
    SharedModalService.prototype.saveEmail = function (params, queryParams) {
        if (queryParams === void 0) { queryParams = {}; }
        return this.http.post(this.getApiUrl('process_email', queryParams), params);
    };
    SharedModalService.prototype.mapQueryParams = function (queryObject) {
        return Object.entries(queryObject)
            .map(function (_a) {
            var key = _a[0], value = _a[1];
            return encodeURIComponent(key) + "=" + encodeURIComponent(value);
        })
            .join('&');
    };
    SharedModalService.prototype.groupModalStatus = function () {
        this.modalOpenStatus = {
            'customer': JSON.parse(JSON.stringify(this.types)),
            'opportunity': JSON.parse(JSON.stringify(this.types)),
            'contract': JSON.parse(JSON.stringify(this.types)),
            'others': {
                'email': false,
                'activity': false
            }
        };
    };
    SharedModalService.prototype.checkModalStatus = function () {
        var isAlreadyOpen = false;
        for (var _i = 0, _a = Object.keys(this.modalOpenStatus); _i < _a.length; _i++) {
            var screenKey = _a[_i];
            for (var _b = 0, _c = Object.keys(this.modalOpenStatus[screenKey]); _b < _c.length; _b++) {
                var key = _c[_b];
                if (this.modalOpenStatus[screenKey][key]) {
                    isAlreadyOpen = key;
                }
            }
        }
        return isAlreadyOpen;
    };
    SharedModalService.prototype.updateModalStatus = function (screen, type, status) {
        type = type.toLowerCase();
        if (this.modalOpenStatus.hasOwnProperty(screen) && this.modalOpenStatus[screen].hasOwnProperty(type)) {
            this.modalOpenStatus[screen][type] = status;
        }
    };
    SharedModalService.prototype.resetModalStatus = function (screen) {
        if (this.modalOpenStatus.hasOwnProperty(screen)) {
            this.modalOpenStatus[screen] = JSON.parse(JSON.stringify(this.types));
        }
    };
    SharedModalService.prototype.setDiaryCompletedStatus = function (data) {
        this.diaryCompletedStatus = data;
    };
    SharedModalService.prototype.getDiaryCompletedStatus = function () {
        return this.diaryCompletedStatus;
    };
    SharedModalService.ngInjectableDef = i0.defineInjectable({ factory: function SharedModalService_Factory() { return new SharedModalService(i0.inject(i1.HttpClient)); }, token: SharedModalService, providedIn: "root" });
    return SharedModalService;
}(InterfaceService));
export { SharedModalService };
