<form *ngIf="vehicleMakesForm" class="make-form-container" [formGroup]="vehicleMakesForm"
    (submit)="submitVehicleMake()">
    <!-- Header -->
    <div class="modal-header">
        <h4 class="modal-title pull-left" translate>{{title}}</h4>
    </div>
    <!-- Content -->
    <div class="modal-body">
        <div class="body-content-section">
            <div class="row-fluid">
                <div class="span12">

                    <!-- Make -->
                    <div class="control-group clearfix">
                        <label class="control-label required_field" translate>fleet.vehicle.make</label>
                        <div class="controls">
                            <input type="text" class="control-input make-input" formControlName="name"
                                (valueChanged)="checkUniqueMakesName($event)" inputTrim appDebounceTimer>
                        </div>
                        <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)">
                            <span *ngIf="f.name.errors.required" class="control-error-message show">
                                <span translate>fleet.please.enter.valid.make</span>
                            </span>
                        </div>
                        <span *ngIf="uniqueName && f.name.valid" class="control-error-message show">
                            <span translate>fleet.this.make.already.exists</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Footer -->
    <div class="modal-footer">
        <a class="btn make-cancel-text" (click)="closeVehicleMakePanel()" translate>Cancel</a>
        <button class="btn btn-primary make-save-btn"
            [disabled]="!vehicleMakesForm.valid || action == 'saving' || saveDisabled || uniqueName ">
            <span [hidden]="action == 'saving'" translate>Save</span>
            <span [hidden]="action != 'saving'" translate>Saving</span>
        </button>
    </div>
</form>