import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterfaceService } from '@app/interfaces';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UploadDocumentService extends InterfaceService {

    filesCollection : Subject<any> = new Subject<any>()

    constructor(private http: HttpClient) {
        super();
    }

    sendFilesToUpload(files:any){
        this.filesCollection.next(files)
    }

    getSuppliers(searchText: string = ''): Observable<any> {
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        let url = '';
        if (searchText == '') {
            url = 'get_all_suppliers';
        } else {
            url = 'get_all_suppliers?q=' + searchText;
        }
        return this.http
            .get(this.getApiUrl(url));
    }

    postDocParserDocument(params?) {
        const formData: FormData = new FormData();
        for (let i = 0; i < params.files.length; i++) {
            formData.append('file[' + i + ']', params.files[i]);
        }

        formData.append('supplier[supplierid]', params.supplierid);
        formData.append('supplier[jobid]', params.jobid);

        return this.http.post(this.getApiUrl(`postDocParserDocuments`), formData);
    }

    postDocumentAIParser(params?) {
        const formData: FormData = new FormData();
        for (let i = 0; i < params.files.length; i++) {
            formData.append('file[' + i + ']', params.files[i]);
        }
        formData.append('supplier[jobid]', params.jobid);
        formData.append('supplier[supplierid]', params.supplierid);
        formData.append('supplier[isUploadedViaSuppliersPage]', params.isUploadedViaSuppliersPage);

        return this.http.post(this.getApiUrl(`postDocumentAIParser`), formData);
    }
    
    getPostURL() {
        return this.getApiUrl(`postDocParserDocuments`);
    }

    getListAutomatedInvoices(requestParams?: any, sortModal?: any, searchTerm?: any): Observable<any> {
        let query = 'page=' + requestParams.page + '&limit=' + requestParams.limit;
    
        if (searchTerm != null && sortModal != null) {
            let sortByValue = sortModal.colId;
            let sortByOrder = sortModal.sort;

            let url = 'listAutomatedParseData?searchText=' + searchTerm + '&' + 'sortByValue=' + sortByValue + '&sortByOrder=' + sortByOrder + '&' + query;
            
            return this.http.get(this.getApiUrl(url));
        } else if (sortModal != null) {
            let sortByValue = sortModal.colId;
            let sortByOrder = sortModal.sort;

            let url = 'listAutomatedParseData?sortByValue=' + sortByValue + '&sortByOrder=' + sortByOrder + '&' + query;

            return this.http.get(this.getApiUrl(url));
        } else if (searchTerm != null) {
            let url = 'listAutomatedParseData?searchText=' + searchTerm + '&' + query;
            return this.http.get(this.getApiUrl(url));
        } else {
            return this.http.get(this.getApiUrl('listAutomatedParseData?' + query));
        }
    }

    getAutomatedParseData(id, poNumber, supplierId): Observable<any> {
        let url = '';

        if (poNumber == "" || poNumber == null || typeof poNumber == 'undefined') {
            poNumber = 0;
        } 

        if (supplierId == "" || supplierId == null || typeof supplierId == 'undefined') {
            supplierId = 0;
        }

        url = 'getAutomatedParseData/' + id + '?poNumber=' + poNumber + '&supplierId=' + supplierId;
        
        return this.http.get(this.getApiUrl(url));
    }

    deleteAIParseDetails(id): Observable<any> {
        let url = 'deleteAIParseDetails/' + id;
        return this.http.delete(this.getApiUrl(url));
    }

    saveAutomatedParseData(data: any, id: any): Observable<any> {
        let url = 'saveAutomatedParseData/' + id;
        return this.http.put(this.getApiUrl(url), data);
    }

    deleteDocumentAIParser(id): Observable<any> {
        let url = 'deleteDocumentAIParser/' + id;
        return this.http.delete(this.getApiUrl(url));
    }

    saveAutomatedDataMapping(data): Observable<any> {
        let url = 'saveAutomatedDataMapping';
        return this.http.put(this.getApiUrl(url), data);
    }

    convertParsedDataToInvoice(id:any, part:any): Observable<any> {
        let url = 'convertParsedDataToInvoice/' + id;
        return this.http.post(this.getApiUrl(url + '?part=' + part), null);
    }

    selectPartsOrItems(data): Observable<any> {
        let query = 'supplierId=' + data.supplierId + '&poNumber=' + data.poNumber + '&jobId=' + data.jobId + '&invoiceNumber=' + data.invoiceNumber + '&parsedDataId=' + data.parsedDataId;
        return this.http.get(this.getApiUrl('selectPartsOrItems?' + query));
    }

    checkSupplierNominalCode(supplierId): Observable<any> {
        return this.http.get(this.getApiUrl('checkSupplierNominalCode?supplierId=' + supplierId));
    }

    getDocparserTemplate(): Observable<any> {
        return this.http.get(this.getApiUrl('listDocParsers'));
    }

    updateDocparserTemplate(supplierId, docparserTemplateId): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('supplier[id]', supplierId);
        formData.append('supplier[docparserTemplateId]', docparserTemplateId);

        return this.http.post(this.getApiUrl(`updateDocparserTemplate`), formData);
    }
}
