import { OnInit, Renderer2 } from '@angular/core';
import { UploadDocumentService } from '@app/features/ai-document-parsing/services/upload-document.service';
import { CsFullScreenModalComponent } from '@app/directives';
import { TranslateService } from '@ngx-translate/core';
import { CsToastBoxService } from '@app/services';
import 'ag-grid-enterprise';
import { CsAgGroupCellRendererComponent } from "@app/shared/cs-components/cs-ag-grid/cs-ag-grid-components/cs-ag-group-cell-renderer/cs-ag-group-cell-renderer.component";
import { CapitalizePipe } from '@app/pipes';
import { CustomTooltipRendererComponent } from '../custom-tooltip-renderer/custom-tooltip-renderer.component';
import { CcCommonService } from '@app/features/customers/customer-contracts/cc-common.service';
import { DatePipe } from '@angular/common';
var ListParsedDataComponent = /** @class */ (function () {
    /*
        Note:
        For Processing and Error status code: Edit link will be disable
        And for only Ready status Confirm link is enable

        For both Edit and Confirm links: Details page will open
        For Delete link ask for confirmation
    */
    function ListParsedDataComponent(integrationService, translate, confirmationBoxHelper, ajsScope, toastBox, renderer, capitalizePipe, ccCommonService, datePipe) {
        var _this = this;
        this.integrationService = integrationService;
        this.translate = translate;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.toastBox = toastBox;
        this.renderer = renderer;
        this.capitalizePipe = capitalizePipe;
        this.ccCommonService = ccCommonService;
        this.datePipe = datePipe;
        this.dataTable = new Array(); // This variable is used to store data which we want to show in table grid
        this.pageNumber = 0; //This variable is used for pagination
        this.records = 10; // This variable is used to show number of records
        this.pages = 0;
        this.list = new Array();
        this.isConfirmDisabled = true; //variable to make confirm link enable and disable according to response
        this.title = "Confirm supplier invoice"; // Title of Detail page
        this.openDisplayPage = false;
        this.pageLimit = 10;
        // For sorting
        this.jobSort = "asc";
        this.statusSort = "asc";
        this.suppliersList = [];
        this.currentPage = 1;
        this.pageSize = 5;
        this.firstTimeSearch = false;
        this.getMasterGridDataSource = function () {
            var _thisRef = _this;
            return {
                getRows: function (params) {
                    setTimeout(function () {
                        var requestParams = {
                            page: _this.currentPage,
                            limit: _this.pageSize
                        };
                        if (requestParams.page == null ||
                            typeof requestParams.page == 'undefined' ||
                            requestParams.limit == null ||
                            typeof requestParams.limit == 'undefined') {
                            requestParams.page = 1;
                            requestParams.limit = 5;
                        }
                        var sortModal = params.request.sortModel[0] ? params.request.sortModel[0] : null;
                        _this.integrationService.getListAutomatedInvoices(requestParams, sortModal, _this.searchTerm).subscribe(function (resp) {
                            _this.dataTable = resp['parsersDetails'];
                            _this.forDisplaySalesTax = resp['taxItemList'];
                            _this.forDisplayNominalCode = resp['nominalCodeList'];
                            _this.totalRecords = resp.count;
                            var isPartialRecordsInPage = _this.dataTable.length < requestParams.limit;
                            var endPageRecordCount = isPartialRecordsInPage ? _this.dataTable.length : requestParams.limit;
                            params.successCallback(resp.parsersDetails, endPageRecordCount);
                            _this.showOrHideGridOverlay(_this.totalRecords); // This will be used to show No data available message.
                        });
                    }, 500);
                }
            };
        };
    }
    ListParsedDataComponent.prototype.ngOnInit = function () {
        var _this = this;
        var format = this.ccCommonService.getRegionalDateFormat();
        format = format.replace(/D/gi, 'd');
        this.regionalDateFormat = format.replace(/Y/gi, 'y');
        this.integrationService.getSuppliers().subscribe(function (resp) {
            _this.suppliersList = resp;
        });
        this.prepareListViewParams();
        this.setGridOptions();
    };
    ListParsedDataComponent.prototype.prepareListViewParams = function () {
        this.listViewParams = {
            pageSize: 5,
            titleText: this.translate.instant('Confirm.your.pending.supplier.invoices.receipts'),
            gridType: 'ag-grid',
            gridApi: this.gridApi,
            masterGridDataSource: this.getMasterGridDataSource()
        };
    };
    ListParsedDataComponent.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
        this.listViewParams.initGrid(params.api);
    };
    ListParsedDataComponent.prototype.showOrHideGridOverlay = function (recordCount) {
        if (recordCount) {
            this.gridApi.hideOverlay();
        }
        else {
            this.gridApi.showNoRowsOverlay();
        }
    };
    ListParsedDataComponent.prototype.onLayoutReady = function (params) {
        this.updateListViewParams(params);
    };
    ListParsedDataComponent.prototype.updateListViewParams = function (params) {
        this.currentPage = params.currentPage;
        this.pageSize = params.pageSize;
        this.listViewParams = params;
    };
    ListParsedDataComponent.prototype.search = function (event) {
        if (this.firstTimeSearch == false) {
            this.firstTimeSearch = true;
            this.currentPage = 1;
        }
        this.searchTerm = event.target.value;
        // Search by Purchase Order, Invoice Number, Supplier Name and Status
        this.gridApi.purgeServerSideCache();
    };
    ListParsedDataComponent.prototype.displayList = function (event) {
        if (this.searchTerm == null || this.searchTerm.length == 0) {
            this.searchTerm = null;
            this.gridApi.purgeServerSideCache();
            this.firstTimeSearch = false;
        }
    };
    ListParsedDataComponent.prototype.configure = function (id, type) {
        var index = this.dataTable.findIndex(function (value) { return value.id == parseInt(id); });
        if (index >= 0) {
            var item = this.dataTable[index];
            this.actionType = type;
            this.openDisplayPage = true;
            this.forDisplaySupplier = item;
            this.csFullModal.open();
        }
    };
    ListParsedDataComponent.prototype.onRowDelete = function (id) {
        var _this = this;
        this.confirmationBoxHelper
            .getConfirmation(this.translate.instant('This row has been saved, are you sure you want to delete it?'), this.ajsScope)
            .then(function () {
            _this.integrationService.deleteDocumentAIParser(id).subscribe(function (resp) {
                _this.toastBox.show(_this.translate.instant('Record.deleted.successfully'), 2000);
            }, function (error) {
                _this.toastBox.show("Error while deleting record", 2000);
            });
            _this.gridApi.purgeServerSideCache();
        });
    };
    ListParsedDataComponent.prototype.fillCreatedBySection = function (id) {
        var _thisRef = this;
        var index = this.dataTable.findIndex(function (value) { return value.id == parseInt(id); });
        if (index >= 0) {
            var item = this.dataTable[index];
            var createdBy = item.createdBy;
            var createdByName = item.createdName;
            var elementNode = _thisRef.renderer.createElement('span');
            var imgElement = _thisRef.renderer.createElement('img');
            _thisRef.renderer.setAttribute(imgElement, 'src', createdBy);
            _thisRef.renderer.addClass(imgElement, 'profileImg');
            var createdByNameElementNode = _thisRef.renderer.createElement('span');
            var txtNode = _thisRef.renderer.createText(this.capitalizePipe.transform(createdByName));
            _thisRef.renderer.appendChild(createdByNameElementNode, txtNode);
            _thisRef.renderer.appendChild(elementNode, imgElement);
            _thisRef.renderer.appendChild(elementNode, createdByNameElementNode);
            return elementNode;
        }
        else {
            return '-';
        }
    };
    ListParsedDataComponent.prototype.createActionSection = function (params) {
        var _this = this;
        var _thisRef = this;
        var index = this.dataTable.findIndex(function (value) { return value.id == parseInt(params.value); });
        if (index >= 0) {
            var item = this.dataTable[index];
            var elementNode = _thisRef.renderer.createElement('span');
            // Confirm
            var confirmElement = _thisRef.renderer.createElement('a');
            var confirmTextNode = _thisRef.renderer.createText(this.capitalizePipe.transform(this.translate.instant('Confirm')));
            _thisRef.renderer.appendChild(confirmElement, confirmTextNode);
            if (item) {
                if (item.status == 'Ready') {
                    _thisRef.renderer.addClass(confirmElement, 'actionLinks');
                    _thisRef.renderer.listen(confirmElement, 'click', function () {
                        _this.configure(params.value, 'confirm');
                    });
                }
                else {
                    _thisRef.renderer.setAttribute(confirmElement, 'disabled', 'disabled');
                    _thisRef.renderer.addClass(confirmElement, 'disabled-link');
                }
                // Edit
                var editElement = _thisRef.renderer.createElement('a');
                var editTextNode = _thisRef.renderer.createText(this.capitalizePipe.transform(this.translate.instant('Edit')));
                _thisRef.renderer.appendChild(editElement, editTextNode);
                if (item.status == 'Information Missing' || item.status == 'Ready') {
                    _thisRef.renderer.addClass(editElement, 'actionLinks');
                    _thisRef.renderer.listen(editElement, 'click', function () {
                        _this.configure(params.value, 'edit');
                    });
                }
                else {
                    _thisRef.renderer.setAttribute(confirmElement, 'disabled', 'disabled');
                    _thisRef.renderer.addClass(editElement, 'disabled-link');
                }
                // Delete
                var deleteElement = _thisRef.renderer.createElement('a');
                var deleteTextNode = _thisRef.renderer.createText(this.capitalizePipe.transform(this.translate.instant('Delete')));
                _thisRef.renderer.appendChild(deleteElement, deleteTextNode);
                _thisRef.renderer.addClass(deleteElement, 'actionLinks');
                _thisRef.renderer.listen(deleteElement, 'click', function () {
                    _this.onRowDelete(params.value);
                });
                _thisRef.renderer.appendChild(elementNode, confirmElement);
                _thisRef.renderer.appendChild(elementNode, editElement);
                _thisRef.renderer.appendChild(elementNode, deleteElement);
                _thisRef.renderer.addClass(elementNode, 'actionSection');
                return elementNode;
            }
        }
    };
    ListParsedDataComponent.prototype.setGridOptions = function () {
        var _this = this;
        var _thisRef = this;
        this.gridOptions = {
            frameworkComponents: {
                agGroupCellRenderer: CsAgGroupCellRendererComponent,
            },
            defaultColDef: {
                sortable: true,
                unSortIcon: true,
                flex: 1,
                suppressMenu: true
            },
            columnDefs: [
                {
                    field: 'status',
                    headerName: this.translate.instant('Pat.status'),
                    sortable: true,
                    sortingOrder: ['asc', 'desc'],
                    cellRendererFramework: CustomTooltipRendererComponent
                },
                {
                    field: 'supplierName',
                    headerName: this.translate.instant('Supplier'),
                    sortable: true,
                    sortingOrder: ['asc', 'desc'],
                },
                {
                    field: 'jobId',
                    headerName: this.translate.instant('Job.number'),
                    sortable: true,
                    sortingOrder: ['asc', 'desc'],
                    cellRenderer: function (params) {
                        if (params.value != "" && params.value != null) {
                            return params.value;
                        }
                        else {
                            return '-';
                        }
                    }
                },
                {
                    field: 'createdBy',
                    headerName: this.translate.instant('Created.By'),
                    sortable: true,
                    sortingOrder: ['asc', 'desc'],
                    cellRenderer: function (params) {
                        var elementNode = _this.fillCreatedBySection(params.data.id);
                        return elementNode;
                    }
                },
                {
                    field: 'invoiceDate',
                    headerName: this.translate.instant('Date'),
                    sortable: true,
                    sortingOrder: ['asc', 'desc'],
                    cellRenderer: function (params) {
                        if (params.data.invoiceDateObject != "" && params.data.invoiceDateObject != null) {
                            return new DatePipe('en-US').transform(params.data.invoiceDateObject.date, _this.regionalDateFormat);
                        }
                        else {
                            return '-';
                        }
                    }
                },
                {
                    field: 'nominalCodeDescription',
                    headerName: this.translate.instant('Nominal.Code'),
                    sortable: false,
                    cellRenderer: function (params) {
                        if (params.value != "" && params.value != null) {
                            return params.value;
                        }
                        else {
                            return '-';
                        }
                    }
                },
                {
                    field: 'amount',
                    headerName: this.translate.instant('Amount'),
                    sortable: false,
                    cellRenderer: function (params) {
                        if (params.value != "" && params.value != null) {
                            return params.data.currencySymbols + params.value;
                        }
                        else {
                            return '-';
                        }
                    }
                },
                {
                    field: 'id',
                    headerName: this.translate.instant('Actions'),
                    sortable: false,
                    cellRenderer: function (params) {
                        var elementNode = _this.createActionSection(params);
                        return elementNode;
                    }
                },
            ],
            rowModelType: 'serverSide',
            pagination: true,
            paginationPageSize: _thisRef.listViewParams.pageSize || 5,
            suppressPaginationPanel: true,
            suppressContextMenu: true,
            suppressRowHoverHighlight: true,
            animateRows: true,
            suppressCellSelection: true,
            masterDetail: true,
            suppressMovableColumns: true,
            suppressHorizontalScroll: true,
            localeText: {
                noRowsToShow: this.translate.instant('No.data.found')
            }
        };
    };
    ListParsedDataComponent.prototype.onShownFSModal = function () {
        $('body').addClass('modal-open');
    };
    ListParsedDataComponent.prototype.onHiddenFSModal = function () {
        $('body').removeClass('modal-open');
        this.openDisplayPage = false;
        this.gridApi.purgeServerSideCache();
    };
    ListParsedDataComponent.prototype.closeFSModal = function () {
        this.csFullModal.close();
    };
    return ListParsedDataComponent;
}());
export { ListParsedDataComponent };
