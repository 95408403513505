var StartSequenceBlockModel = /** @class */ (function () {
    function StartSequenceBlockModel(data) {
        if (data === void 0) { data = undefined; }
        this.templateId = '';
        this.templateName = '';
        this.contactId = '';
        if (data) {
            this.templateId = data['templateId'] ? data['templateId'] : '';
            this.templateName = data['templateName'] ? data['templateName'] : '';
            this.contactId = data['contactId'] ? data['contactId'] : '';
        }
    }
    return StartSequenceBlockModel;
}());
export { StartSequenceBlockModel };
