import * as tslib_1 from "tslib";
import { ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ModalContainerComponent } from "ngx-bootstrap/modal";
import { SidepanelOptions } from "@app/sidepanel/services/sidepanel-options";
import { TranslateService } from "@ngx-translate/core";
import { AddOpportunityService } from "@app/features/customers/add-opportunity/service/add-opportunity.service";
var CsFullscreenPanelComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CsFullscreenPanelComponent, _super);
    function CsFullscreenPanelComponent(options, element, confirmationBoxHelper, ajsScope, translate, opService, renderer) {
        var _this = _super.call(this, options, element, renderer) || this;
        _this.element = element;
        _this.confirmationBoxHelper = confirmationBoxHelper;
        _this.ajsScope = ajsScope;
        _this.translate = translate;
        _this.opService = opService;
        _this.renderer = renderer;
        _this._isModalHiding = false;
        return _this;
    }
    CsFullscreenPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        //super.ngOnInit();
        if (this.isAnimated) {
            this.renderer.addClass(this.element.nativeElement, 'fades');
        }
        this.renderer.setStyle(this.element.nativeElement, 'display', 'block');
        setTimeout(function () {
            _this.isShown = true;
            _this.renderer.addClass(_this.element.nativeElement, 'show');
        }, 300);
        if (document && document.body) {
            /* if (this.bsModalService.getModalsCount() === 1) {
                 this.bsModalService.checkScrollbar();
                 this.bsModalService.setScrollbar();
             }*/
            this.renderer.addClass(document.body, 'cs-fullscreen-modal-open');
        }
        if (this.element.nativeElement) {
            this.element.nativeElement.focus();
            var footerElement = this.element.nativeElement.querySelector('.modal-content > * > .modal-footer');
            if (footerElement) {
                this.renderer.addClass(this.element.nativeElement, 'foo' + 'ter' + '-' + 'en' + 'abl' + 'ed');
            }
        }
        if (this.config.size != '')
            this.renderer.addClass(this.element.nativeElement, this.config.size);
        if (this.bsModalService.getModalsCount()) {
            // this.renderer.addClass(this.element.nativeElement, 'modal-level-'+this.bsModalService.getModalsCount());
        }
        if (this.config.hasSubtitle) {
            this.renderer.addClass(this.element.nativeElement, 'cs-fullscreen-with-subtitle');
        }
    };
    CsFullscreenPanelComponent.prototype.hide = function () {
        var _this = this;
        if (this._isModalHiding || !this.isShown) {
            return;
        }
        this._isModalHiding = true;
        this.renderer.removeClass(this.element.nativeElement, 'show');
        this.renderer.addClass(this.element.nativeElement, 'hide');
        setTimeout(function () {
            _this.isShown = false;
            if (document &&
                document.body &&
                _this.bsModalService.getModalsCount() === 1) {
                _this.renderer.removeClass(document.body, 'cs-fullscreen-modal-open');
            }
            _this.bsModalService.hide(_this.level);
            _this._isModalHiding = false;
        }, 300);
    };
    CsFullscreenPanelComponent.prototype.onClick = function (event) {
    };
    return CsFullscreenPanelComponent;
}(ModalContainerComponent));
export { CsFullscreenPanelComponent };
