commusoftCommon.service('hasCheckPermission', function() {
    this.companyGeneralSettings = [{'featureName' : 'Companydetails','featureAccess' : 'updateaccess'},{'featureName' : 'Cispercentage','featureAccess' : 'updateaccess'},
        {'featureName' : 'Setupemail','featureAccess' : 'readaccess'},{'featureName' : 'Setupletterhead','featureAccess' : 'updateaccess'},
        {'featureName' : 'Setuplogocertificate','featureAccess' : 'writeaccess'},{'featureName' : 'Vatandcompanynumbers','featureAccess' : 'updateaccess'},
        {'featureName' : 'Bankdetails','featureAccess' : 'updateaccess'},{'featureName' : 'Chequesmadepayable','featureAccess' : 'updateaccess'},
        {'featureName' : 'Importingdata','featureAccess' : 'writeaccess'}, {'featureName': 'SetupBrandingColours', 'featureAccess': 'updateaccess'}];

    this.companyUsersAndVehicles = [{'featureName' : 'Users','featureAccess' : 'updateaccess'},{'featureName' : 'Vehicle','featureAccess' : 'readaccess'},{'featureName' : 'Setuptravelrate','featureAccess' : 'readaccess'}];

    this.companyAudits = [{'featureName' : 'Audits','featureAccess' : 'readaccess'}];

//        this.companyAccountAndBilling = [{'featureName' : 'CommusoftInvoices','featureAccess' : 'updateaccess'}];
    this.commusoftInvoices = [{'featureName' : 'Commusoftinvoices','featureAccess' : 'readaccess'}];

    this.companyIntegrations = [{'featureName' : 'Apikeys','featureAccess' : 'updateaccess'},{'featureName' : 'Setupaccountinginterface','featureAccess' : 'readaccess'},{'featureName' : 'Setupcreditcard','featureAccess' : 'updateaccess'},{'featureName' : 'Setupmailchimpintegration','featureAccess' : 'updateaccess'},{'featureName': 'Setupnaturalformintegration', 'featureAccess': 'updateaccess'},{'featureName': 'Setupjdpintegration', 'featureAccess': 'updateaccess'},{'featureName': 'Setupzapierintegration', 'featureAccess': 'updateaccess'}];
    this.backups = [{'featureName': 'Backups', 'featureAccess': 'writeaccess'}];
    this.integrations = [{'featureName': 'Setupmailchimpintegration', 'featureAccess': 'updateaccess'},{'featureName': 'Setupnaturalformintegration', 'featureAccess': 'updateaccess'},{'featureName': 'Setupdocparserintegration', 'featureAccess': 'updateaccess'},{'featureName': 'Setupjdpintegration', 'featureAccess': 'updateaccess'},{'featureName': 'Setupzapierintegration', 'featureAccess': 'updateaccess'}];

    this.systemGeneralSettings = [{'featureName' : 'Setupdiary','featureAccess' : 'updateaccess'},{'featureName' : 'Setuplocations','featureAccess' : 'writeaccess'},
        {'featureName' : 'Setupfueltypes','featureAccess' : 'writeaccess'},{'featureName' : 'Setupappliancegroups','featureAccess' : 'writeaccess'},
        {'featureName' : 'Setupappliancetypes','featureAccess' : 'writeaccess'},{'featureName' : 'Setupappliancemake','featureAccess' : 'writeaccess'},
        {'featureName' : 'Setupmodels','featureAccess' : 'writeaccess'},{'featureName' : 'Setuptechnicalreference','featureAccess' : 'writeaccess'},
        {'featureName' : 'Pricingitems','featureAccess' : 'writeaccess'},{'featureName' : 'Setupnominalgroups','featureAccess' : 'writeaccess'},
        {'featureName' : 'Setupnominalaccounts','featureAccess' : 'writeaccess'},{'featureName' : 'Setuptypeoftelephonenumbers','featureAccess' : 'writeaccess'},
        {'featureName' : 'Setupcustomertitles','featureAccess' : 'writeaccess'},{'featureName' : 'Setupvat','featureAccess' : 'writeaccess'},{'featureName' : 'Bccemails','featureAccess' : 'updateaccess'},
        {'featureName' : 'SetupEmailTemplates' , 'featureAccess' : 'readaccess'},
        {'featureName' : 'SetupSMSTemplates' , 'featureAccess' : 'readaccess'}];

    this.systemCustomerInformation = [{'featureName' : 'Setupfirstcustomeraccountnumber','featureAccess' : 'updateaccess'},{'featureName' : 'Setupcustomertypes','featureAccess' : 'writeaccess'},
        {'featureName' : 'SetupContractPlans','featureAccess' : 'writeaccess'},{'featureName' : 'Setupadvertising','featureAccess' : 'writeaccess'},
        {'featureName' : 'Setupcreditordays','featureAccess' : 'writeaccess'},{'featureName' : 'Setuptypesofbuildings','featureAccess' : 'writeaccess'},
        {'featureName' : 'Setupcustomersmstemplates','featureAccess' : 'writeaccess'}];

    this.systemEstimates = [{'featureName' : 'Setupfirstestimatenumber','featureAccess' : 'updateaccess'},{'featureName' : 'SetupEstimateTemplates','featureAccess' : 'writeaccess'},
        {'featureName' : 'Setupestimatenote','featureAccess' : 'updateaccess'},{'featureName' : 'Setupengineerestimatesheetemailmessage','featureAccess' : 'updateaccess'},
        {'featureName' : 'Setupengineerestimatesms','featureAccess' : 'updateaccess'},{'featureName' : 'Setupcustomerestimateconfirmation','featureAccess' : 'updateaccess'},
        {'featureName' : 'Setupworkaddressestimateconfirmation','featureAccess' : 'updateaccess'},{'featureName' : 'Setupworkaddressestimatereminder','featureAccess' : 'updateaccess'},
        {'featureName' : 'Setupestimatedefaultemailmessage','featureAccess' : 'updateaccess'},{'featureName' : 'Onlineestimateportal','featureAccess' : 'updateaccess'},
        {'featureName' : 'Setuptermsandconditionforestimate','featureAccess' : 'updateaccess'}];
    this.systemJobs = [{'featureName' : 'Setupfirstjobnumber','featureAccess' : 'updateaccess'},{'featureName' : 'Setupjobdescriptions','featureAccess' : 'writeaccess'},
        {'featureName' : 'Setupjobsheetdesign','featureAccess' : 'updateaccess'},{'featureName' : 'Setupjobsummarydesign','featureAccess' : 'updateaccess'},
        {'featureName' : 'Setupengineeremailjobsheetmessage','featureAccess' : 'updateaccess'},{'featureName' : 'Setupengineerjobsheetsms','featureAccess' : 'updateaccess'},
        {'featureName' : 'Setupcustomerjobconfirmation','featureAccess' : 'updateaccess'},
        {'featureName' : 'Setupworkaddressjobconfirmation','featureAccess' : 'updateaccess'},{'featureName' : 'Setupworkaddressjobreminder','featureAccess' : 'updateaccess'},
        {'featureName' : 'Setupfreeofchargereasons','featureAccess' : 'writeaccess'},{'featureName' : 'Setupabortreasons','featureAccess' : 'writeaccess'},
        {'featureName' : 'Setuprecallreason','featureAccess' : 'updateaccess'}];

    this.systemCertificates = [{'featureName' : 'Setupthecertificateemailmessage','featureAccess' : 'updateaccess'},{'featureName' : 'Setupthecertificateprintandpostletter','featureAccess' : 'writeaccess'},
        {'featureName' : 'Setupcustomcertificate','featureAccess' : 'writeaccess'}];

    this.systemServiceReminders = [{'featureName' : 'Setupservicereminders','featureAccess' : 'writeaccess'}];

    this.systemInvoicing = [{'featureName' : 'Configurethewayinvoicenumbersaregenerated','featureAccess' : 'updateaccess'},{'featureName' : 'Setuptheinvoicetemplatedesign','featureAccess' : 'writeaccess'},
        {'featureName' : 'Setupinvoicecategories','featureAccess' : 'writeaccess'},{'featureName' : 'Customiseinvoiceitemcategories','featureAccess' : 'writeaccess'},
        {'featureName' : 'Setuppaymentmethods','featureAccess' : 'writeaccess'},{'featureName' : 'Setupinvoicenote','featureAccess' : 'updateaccess'},
        {'featureName' : 'Setupinvoicetermsandconditions','featureAccess' : 'updateaccess'},{'featureName' : 'Setupaftersalescommunication','featureAccess' : 'updateaccess'},
        {'featureName' : 'Defaultstatementmessage','featureAccess' : 'updateaccess'},{'featureName' : 'Setuppaymentreceiptemailmessage','featureAccess' : 'updateaccess'}];

    this.systemSuppliers = [{'featureName' : 'Setuppurchaseorderemailmessage','featureAccess' : 'updateaccess'},
        {'featureName' : 'SetPoNumber','featureAccess' : 'updateaccess'}];

    this.systemPartsManagement = [{'featureName' : 'Setupindustries','featureAccess' : 'writeaccess'},{'featureName' : 'Setuppartscategories','featureAccess' : 'writeaccess'},
        {'featureName' : 'Setupsub-categories','featureAccess' : 'writeaccess'},{'featureName' : 'Setuppartsandprices','featureAccess' : 'writeaccess'},{'featureName' : 'Setuppartkit','featureAccess' : 'writeaccess'},{'featureName' : 'Supplierpartsquotationemailmessage','featureAccess' : 'updateaccess'}];

    this.systemStockControl = [{'featureName' : 'Setupstocklocations','featureAccess' : 'writeaccess'},{'featureName' : 'Setuppartcollectionworkflow','featureAccess' : 'updateaccess'}];

    this.systemEmployeeAndVehicles = [{'featureName' : 'Setupengineerqualifications','featureAccess' : 'writeaccess'},{'featureName' : 'Setupusergroups','featureAccess' : 'writeaccess'},
        {'featureName' : 'Vehiclesparameters','featureAccess' : 'writeaccess'}];

    this.systemMobileSettings = [{'featureName' : 'Setupestimatearrivesignaturemessage','featureAccess' : 'updateaccess'},{'featureName' : 'Setupestimateleavesignaturemessage','featureAccess' : 'updateaccess'},
        {'featureName' : 'Setupengineerontheirwaysmsforestimates','featureAccess' : 'updateaccess'},{'featureName' : 'Setupestimatearriveonsitequestions','featureAccess' : 'writeaccess'},
        {'featureName' : 'Setupleaveestimatequestions','featureAccess' : 'writeaccess'},{'featureName' : 'Customisenoaccessmessageforestimates','featureAccess' : 'updateaccess'},
        {'featureName' : 'Setupjobarrivesignaturemessage','featureAccess' : 'updateaccess'},{'featureName' : 'Setupjobleavesignaturemessage','featureAccess' : 'updateaccess'},
        {'featureName' : 'Setupengineerontheirwayforjobs','featureAccess' : 'updateaccess'},{'featureName' : 'Setupjobarriveonsitequestions','featureAccess' : 'writeaccess'},
        {'featureName' : 'Setupleavejobquestions','featureAccess' : 'writeaccess'},{'featureName' : 'Customisenoaccessmessageforjobs','featureAccess' : 'updateaccess'}];

    this.systemOnlinePortals = [{'featureName' : 'Setupminiappforwebsite','featureAccess' : 'updateaccess'},
        {'featureName' : 'SetupOpportunityTemplates',featureAccess: 'writeaccess'}];

    this.serviceSchedulingRulesPortalSettings = [{'featureName' : 'SetupJobbookingPortal','featureAccess' : 'updateaccess'},
        {'featureName' : 'SetupServiceReminderBookingPortal','featureAccess' : 'updateaccess'},
        {'featureName' : 'SetupRebookingPortal','featureAccess' : 'updateaccess'}];

    this.systemContractSettings = [
        {'featureName' : 'SetupContractTemplates', 'featureAccess' : 'readaccess'},
        {'featureName' : 'SetupPPM', 'featureAccess' : 'updateaccess'},
        {'featureName' : 'SetupSLA', 'featureAccess' : 'updateaccess'},
    ];

    this.estimateQuickLinks = [{'featureName' : 'Estimates','featureAccess' : 'writeaccess'},{'featureName' : 'Estimatereservelist','featureAccess' : 'writeaccess'},{'featureName' : 'Estimatedetails','featureAccess' : 'writeaccess'}];

    this.reportings = [{'featureName' : 'Basiccustomerreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Servicereminderreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Advancedcustomerreporting', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Basicservicereminderreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Advancedservicereminderreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Basicsupplierreports', 'featureAccess' : 'readaccess'},
        //{'featureName' : 'Advancedsupplierreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Basicjobreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Advancedjobreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Basiccertificatereports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Advancedcertificatereports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Basicestimatereports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Advancedestimatereports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Basicpartsreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Advancedpartsreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Advancedprojectreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Basicdiaryreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Advanceddiaryreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Basicsalesreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Advancedsalesreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Basicpurchaseorderreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Advancedpurchaseorderreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Basicexpensereports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Advancedexpensereports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Basicprofitreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Advancedstockcontrolreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Basicusersreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Basicdebtorsreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Basicofficetaskreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Basiccreditorsreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Vehiclereports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'customerAsset', 'featureAccess' : 'readaccess'},
        {'featureName' : 'SetupSLA', 'featureAccess' : 'readaccess'},];
    this.financialQuickLinks = [{'featureName' : 'Invoiceaddresses','featureAccess' : 'readaccess'},{'featureName' : 'Customerpayments','featureAccess' : 'writeaccess'},{'featureName' : 'CustomerCreditNote','featureAccess' : 'writeaccess'},{'featureName' : 'Customerpayments','featureAccess' : 'readaccess'}];
    this.serviceReminderQuickLinks = [{'featureName' : 'Workaddressremindersdue','featureAccess' : 'readaccess'}];
    this.customerSettingsQuickLinks = [{'featureName' : 'Customerinvoicesetup','featureAccess' : 'updateaccess'}];
    this.adminToolsQuickLinks = [{'featureName' : 'Customersandworkaddresses','featureAccess' : 'deleteaccess','exportCustomerData' : 'readaccess'}];
    this.jobGeneralQuickLinks = [{'featureName' : 'Jobsplacedonhold','featureAccess' : 'writeaccess'},{'featureName' : 'Jobsplacedonhold','featureAccess' : 'deleteaccess'},{'featureName' : 'Jobreservelist','featureAccess' : 'writeaccess'},{'featureName' : 'Jobreport','featureAccess' : 'readaccess'}];
    this.jobFinancialQuickLinks = [{'featureName' : 'Completejobasfreeofcharge','featureAccess' : 'writeaccess'}];
    this.jobAdminQuickLinks = [{'featureName' : 'Recalljob','featureAccess' : 'writeaccess'},{'featureName' : 'Completejobasaborted','featureAccess' : 'writeaccess'},{'featureName' : 'Jobs','featureAccess' : 'deleteaccess'}];
    this.customerReports = [{'featureName' : 'Basiccustomerreports', 'featureAccess' : 'readaccess'}];
    this.serviceReminderReports = [{'featureName' : 'Basicservicereminderreports', 'featureAccess' : 'readaccess'}];
    this.diaryReports = [{'featureName' : 'Basicdiaryreports', 'featureAccess' : 'readaccess'}];
    this.supplierReports = [{'featureName' : 'Basicsupplierreports', 'featureAccess' : 'readaccess'}];
    this.purchaseOrderReports = [{'featureName' : 'Basicpurchaseorderreports', 'featureAccess' : 'readaccess'}];
    this.expenseReports = [{'featureName' : 'Basicexpensereports', 'featureAccess' : 'readaccess'}];
    this.profitReports = [{'featureName' : 'Basicprofitreports', 'featureAccess' : 'readaccess'}];
    this.creditorsReports = [{'featureName' : 'Basiccreditorsreports', 'featureAccess' : 'readaccess'}];
    this.SLAJobsReports = [{'featureName' : 'SetupSLA', 'featureAccess' : 'readaccess'}];
    this.estimateReports = [{'featureName' : 'Basicestimatereports', 'featureAccess' : 'readaccess'}];
    this.jobReports = [{'featureName' : 'Basicjobreports', 'featureAccess' : 'readaccess'}];
    this.partReports = [{'featureName' : 'Basicpartsreports', 'featureAccess' : 'readaccess'}];
    this.salesReports = [{'featureName' : 'Basicsalesreports', 'featureAccess' : 'readaccess'}];
    this.debtorsReports = [{'featureName' : 'Basicdebtorsreports', 'featureAccess' : 'readaccess'}];
    this.stockControlReports = [{'featureName' : 'Advancedstockcontrolreports', 'featureAccess' : 'readaccess'}];
    this.userReports = [
        {'featureName' : 'Basicusersreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Basicofficetaskreports', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Vehiclereports', 'featureAccess' : 'readaccess'}
    ];
    this.systemAsset = [
        {'featureName' : 'setupAssetGroupAndTypes', 'featureAccess' : 'writeaccess'},
        {'featureName' : 'setupAssetDropDownList', 'featureAccess' : 'writeaccess'},
        {'featureName' : 'setupAssetFromConfiguration', 'featureAccess' : 'updateaccess'}
    ];

    this.CustomerAssetReports = [{'featureName' : 'customerAsset', 'featureAccess' : 'readaccess'}];
    this.SalesCRMReports = [{'featureName' : 'BasicReports','featureAccess' : 'readaccess'}];
    this.salesSettings = [{'featureName' : 'SetupFirstOpportunityNumber' , 'featureAccess' : 'updateaccess'},
        {'featureName' : 'SetupPipelines' , 'featureAccess' : 'readaccess'},
        {'featureName' : 'SetupOpportunityTemplates' , 'featureAccess' : 'readaccess'},
        {'featureName' : 'SetupProposalTemplates' , 'featureAccess' : 'readaccess'},
        {'featureName' : 'SetupOptionTemplates' , 'featureAccess' : 'readaccess'},
        {'featureName' : 'SetupReportingCategories' , 'featureAccess' : 'readaccess'},
        /*{'featureName' : 'SetupEmailTemplates' , 'featureAccess' : 'readaccess'},
        {'featureName' : 'SetupSMSTemplates' , 'featureAccess' : 'readaccess'},*/
        {'featureName' : 'SetupOpportunityRejectReasons' , 'featureAccess' : 'readaccess'},
        {'featureName' : 'SetupOpportunityCancelledReasons' , 'featureAccess' : 'readaccess'},
        {'featureName' : 'SetupAcceptanceTemplate' , 'featureAccess' : 'readaccess'},
        {'featureName' : 'SetupFinance' , 'featureAccess' : 'readaccess'},
        {'featureName' : 'SetupMobileArriveAndSurveyTemplates' , 'featureAccess' : 'readaccess'},
    ];
    this.systemWorkflow = [
        {'featureName' : 'WorkflowMarketplace','featureAccess' : 'updateaccess'},
        {'featureName' : 'WorkflowSetup','featureAccess' : 'deleteaccess'},
    ]
    this.FinanceRequest = [{'featureName' : 'SetupFinance', 'featureAccess' : 'readaccess'}] // Needs to be added if required

    this.Vehicles = [{'featureName' : 'Setupvehiclestatus', 'featureAccess' : 'readaccess' },
        {'featureName' : 'Setupvehicletype', 'featureAccess' : 'readaccess' },
        {'featureName' :'Setupvehiclemodels', 'featureAccess' : 'readaccess'},
        {'featureName' : 'Setupvehiclemakes', 'featureAccess' : 'readaccess'}]
});
