import { NgControl } from "@angular/forms";
var InputTrimDirective = /** @class */ (function () {
    function InputTrimDirective(ngControl) {
        this.ngControl = ngControl;
        trimValueAccessor(ngControl.valueAccessor);
    }
    return InputTrimDirective;
}());
export { InputTrimDirective };
function trimValueAccessor(valueAccessor) {
    var original = valueAccessor.registerOnChange;
    valueAccessor.registerOnChange = function (fn) {
        return original.call(valueAccessor, function (value) {
            return fn(typeof value === 'string' ? value.trim() : value);
        });
    };
}
