var SetSalesPersonBlockModel = /** @class */ (function () {
    function SetSalesPersonBlockModel(data) {
        if (data === void 0) { data = undefined; }
        this.assignTo = '';
        this.displayValue = '';
        if (data) {
            this.assignTo = data['assignTo'] ? data['assignTo'] : '';
            this.displayValue = data['displayValue'] ? data['displayValue'] : '';
        }
    }
    return SetSalesPersonBlockModel;
}());
export { SetSalesPersonBlockModel };
