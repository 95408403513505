import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { DagSourceModel } from "@app/workflow-common/services/DagSourceModel";
import { SmsBlockModel } from "@app/workflow-common/services/models/SmsBlockModel";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
import { from } from "rxjs";
import { map } from "rxjs/operators";
import { CommonDataSource } from "@app/workflow-common/services/CommonDataSource";
var SmsBlockWidgetComponent = /** @class */ (function () {
    function SmsBlockWidgetComponent(formBuilder, workflowService) {
        this.formBuilder = formBuilder;
        this.workflowService = workflowService;
        this.close = new EventEmitter();
        this.smsBlockData = {};
        this.isLoading = true;
        this.isFormSubmitted = false;
        this.usersList = [];
        this.smsTagsList = [];
        this.smsUsersList = [];
        this.smsCustomList = [];
        this.select2ConfigToSms = {};
        this.selectedMobileInputs = [];
        this.inputModel = undefined;
        this.showDeleteBtn = false;
    }
    SmsBlockWidgetComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var blockObject, modelData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.smsBlockData = this.dagSourceModel.getBlocksSource().getBlockByKey(this.blockObject.key);
                        this.smsTagsList = this.dagSourceModel.getSmsTagsByType(this.workflowType, this.objectName);
                        blockObject = tslib_1.__assign({}, this.blockObject);
                        modelData = blockObject['model'] ? blockObject['model'] : undefined;
                        if (modelData) {
                            this.showDeleteBtn = true;
                        }
                        this.inputModel = new SmsBlockModel(modelData);
                        this.makeMobileAddressDropdownData();
                        return [4 /*yield*/, this.getData()];
                    case 1:
                        _a.sent();
                        this.isLoading = false;
                        this.usersList.forEach(function (user) {
                            _this.smsUsersList.push({
                                id: parseInt(user.id),
                                text: user.username,
                                name: 'USERS'
                            });
                        });
                        this.setToEmailSelect2Config();
                        this.form = this.formBuilder.group({
                            templateId: new FormControl(this.inputModel.templateId, [Validators.required]),
                            toSmsTag: new FormControl(this.selectedMobileInputs, [Validators.required]),
                            message: new FormControl(this.inputModel.message),
                            title: new FormControl(this.inputModel.title),
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    SmsBlockWidgetComponent.prototype.getData = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.workflowService.getCommunicationTemplateConfig()
                .toPromise()
                .then(function (resp) {
                var data = resp.hasOwnProperty('data') ? resp['data'] : null;
                if (data) {
                    _this.usersList = data.users;
                    resolve(true);
                }
            }, function (err) {
                reject(err);
                console.error(err);
            });
        });
    };
    SmsBlockWidgetComponent.prototype.setToEmailSelect2Config = function () {
        var select2Default = {
            width: '100%',
            tags: true,
            createSearchChoice: function (term, data) {
                var exists = false;
                data.forEach(function (idText) {
                    if (idText.text == term)
                        exists = true;
                });
                return term.indexOf(' ') >= 0 || exists ? null : {
                    id: term + " (Create New)",
                    text: term + " (Create New)"
                };
            },
            formatSelection: function (item) {
                return item.text.replace(" (Create New)", '');
            },
            formatResult: function (item) {
                return item.text;
            }
        };
        this.select2ConfigToSms = tslib_1.__assign({}, select2Default);
        this.select2ConfigToSms['data'] = this.makeGroupSmsList();
        if (this.smsBlockData['groupId'] != 'internalCommunications') {
            this.select2ConfigToSms['formatNoMatches'] = function () {
                return 'Add a recipient\'s mobile number by entering...';
            };
        }
    };
    SmsBlockWidgetComponent.prototype.selectedMobileAddress = function (event) {
        if (event === void 0) { event = null; }
        this.form.get('toSmsTag').setValue(event);
    };
    SmsBlockWidgetComponent.prototype.setTemplateDetails = function (event, type) {
        this.form.get('templateId').setValue(event['templateId']);
        this.form.get('message').setValue(event['message']);
        this.form.get('title').setValue(event['templateName']);
        this.inputModel.message = event['message'];
        this.inputModel.title = event['templateName'];
    };
    SmsBlockWidgetComponent.prototype.makeGroupSmsList = function () {
        var allSmsTag = [];
        var newItems = [];
        if (this.smsBlockData['groupId'] == 'internalCommunications') {
            newItems.push(this.smsUsersList);
        }
        else if (this.smsBlockData['groupId'] == 'externalCommunications') {
            newItems.push(this.smsTagsList);
        }
        if (this.smsCustomList.length) {
            newItems.push(this.smsCustomList);
        }
        var source = from(newItems);
        var tags = source.pipe(map(function (groupItems) {
            var _item = { text: '', children: [] };
            var cnt = 0;
            groupItems.forEach(function (group) {
                if (cnt === 0) {
                    _item.text = group.name;
                }
                _item.children.push({
                    id: group.id,
                    text: group.text
                });
                cnt++;
            });
            return _item;
        }));
        var subscribe = tags.subscribe(function (val) { return allSmsTag.push(val); });
        subscribe.unsubscribe();
        return allSmsTag;
    };
    SmsBlockWidgetComponent.prototype.makeMobileAddressDropdownData = function () {
        var _this = this;
        var inputModel = this.inputModel;
        if (inputModel.toSmsTag && inputModel.toSmsTag.length > 0) {
            inputModel.toSmsTag.forEach(function (item) {
                if (item['type'] == 'normal') {
                    _this.smsCustomList.push({
                        id: item['value'] + ' (Create New)',
                        text: item['value'],
                        name: 'CUSTOM'
                    });
                    _this.selectedMobileInputs.push(item['value'] + ' (Create New)');
                }
                else {
                    _this.selectedMobileInputs.push(item['value']);
                }
            });
        }
    };
    SmsBlockWidgetComponent.prototype.save = function () {
        this.isFormSubmitted = true;
        var _a = this.form.value, templateId = _a.templateId, message = _a.message, title = _a.title, toSmsTag = _a.toSmsTag;
        var blockObject = tslib_1.__assign({}, this.blockObject);
        this.inputModel.templateId = templateId;
        this.inputModel.message = message;
        this.inputModel.title = title;
        this.inputModel.toSmsTag = CommonDataSource.formattingSmsAddress(toSmsTag, this.smsTagsList, this.usersList);
        blockObject['model'] = this.inputModel;
        this.close.emit({
            action: 'save_and_close',
            data: blockObject
        });
    };
    SmsBlockWidgetComponent.prototype.closeModal = function () {
        this.close.emit({
            action: 'close',
            data: null
        });
    };
    SmsBlockWidgetComponent.prototype.remove = function () {
        var blockObject = tslib_1.__assign({}, this.blockObject);
        blockObject.model = this.inputModel;
        this.close.emit({
            action: 'remove_block',
            data: blockObject
        });
    };
    return SmsBlockWidgetComponent;
}());
export { SmsBlockWidgetComponent };
