import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Subject, BehaviorSubject, Observable} from 'rxjs';
import {InterfaceService} from "@app/interfaces";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})

@Injectable()
export class WorkflowhttpService  extends InterfaceService {
    private workflowEventTrigger = new Subject();
    workflowEventTrigger$ = this.workflowEventTrigger.asObservable();
    apiBasePath = environment.apiHost + environment.apiBasePath;

    private updateCustomHeader = new Subject()
    updateCustomHeader$ = this.updateCustomHeader.asObservable();

    private sequenceViewTrigger = new Subject();
    sequenceViewTrigger$ = this.sequenceViewTrigger.asObservable();

    constructor(private http: HttpClient) {
        super();
    }

    triggerSequenceView(content:any = {}) {
        this.sequenceViewTrigger.next(content);
    }
    destroyWorkflowEventTrigger() {
        this.workflowEventTrigger.next();
        this.workflowEventTrigger.unsubscribe();
    }
    triggerEvent(content:any) {
        this.workflowEventTrigger.next(content);
    }

    triggerCustomHeaderEvent(content: any) {
        this.updateCustomHeader.next(content);
    }

    getWorkflowMarketplaceMetadata(params?: Object): Observable<any> {
        return this.http
            .get(this.getApiUrl(`system_settings/workflow_marketplace`, params));
    }

    enableDisabledAction(params?: Object, actionType?:string): Observable<any>  {
        return this.http
            .post(this.getApiUrl(`system_settings/workflow_marketplace/${params['workflowMarketplace']['id']}/${actionType}`), params);
    }

    getObjectFields(params?: Object, id?:any): Observable<any> {
        const queryParams={};
        if(id) {
            queryParams['id']=id;
        }
        return this.http
            .get(this.getApiUrl(`system_settings/workflow_setup/fields/${params['objectName']}`, queryParams));
    }

    getWorkflowSetupMetadata(params?: Object): Observable<any> {
        return this.http
            .get(this.getApiUrl(`system_settings/workflow_setup`, params));
    }

    addEditWorkflowSetup(data: object, id:number): Observable<any> {
        if(id===0) {
            return this.http.post(this.getApiUrl(`system_settings/workflow_setup/add`), JSON.stringify(data));
        }
        else {
            return this.http.post(this.getApiUrl(`system_settings/workflow_setup/edit/${id}`), JSON.stringify(data));
        }
    }

    updateXml(data: object, id:number): Observable<any> {
        return this.http.post(this.getApiUrl(`system_settings/workflow_setup/update/xml/${id}`), JSON.stringify(data));
    }

    begainDelete(id?: any, type?: any): Observable<any>  {
        return this.http
            .get(this.getApiUrl(`system_settings/workflow_setup/begin-delete/${id}?type=${type}`));
    }

    deleteWorkflowSetupRequest(id?: any, params?: any): Observable<any> {
        return this.http
            .post(this.getApiUrl(`system_settings/workflow_setup/delete/${id}`), params);
    }

    updateStatus(id?: any, params?: any): Observable<any> {
        return this.http
            .post(this.getApiUrl(`system_settings/workflow_setup/update-status/${id}`), params);
    }

    getDagrunsList(id?: any, params?: Object): Observable<any> {
        return this.http.get(this.getApiUrl(`system_settings/workflow_setup/${id}/dagruns`, params));
    }

    getDagrunTasksList(id?: any): Observable<any> {
        return this.http.get(this.getApiUrl(`system_settings/workflow_setup/dagrun/${id}/tasks/history`));
    }

    getUsersList(): Observable<any> {
        return this.http.get(this.getApiUrl(`get_users?___noPageloading=true`));
    }

    getHashTagsList(): Observable<any> {
        return this.http.get(this.getApiUrl(`get_hashTags?___noPageloading=true`));
    }

    getEmailTemplate(params?: Object): Observable<any> {
        return this.http.get(this.getApiUrl(`listSettingsEmailTemplates`, params));
    }

    getCommunicationEmailDetails(id?: any): Observable<any> {
        return this.http.get(this.getApiUrl(`getCommunicationEmailDetails/${id}`));
    }

    getJobDescriptions(params?: Object): Observable<any>{
        return this.http.get(this.getApiUrl(`list_JobDescriptions`, params));
    }

    getSMSTemplate(params?: Object): Observable<any> {
        return this.http.get(this.getApiUrl(`listSettingsSmsTemplates`, params));
    }

    getScheduledDagrunsList(id?: any, runId?: number, objectName?: string,  params?: Object): Observable<any> {
        return this.http.get(this.getApiUrl(`system_settings/workflow_setup/${id}/scheduled_dagruns/${runId}/${objectName}`, params));
    }

    actionTestRun(id?: any, params?: any): Observable<any> {
        return this.http
            .post(this.getApiUrl(`system_settings/workflow_setup/${id}/scheduled/testrun`), params);
    }

    getWorkflowSetupSequenceMetadata(params?: Object): Observable<any> {
        return this.http
            .get(this.getApiUrl(`system_settings/workflow_setup_sequence`, params));
    }

    addEditWorkflowSequenceSetup(data: object, id:number): Observable<any> {
        if(id===0) {
            return this.http.post(this.getApiUrl(`system_settings/workflow_sequence/add`), JSON.stringify(data));
        }
        else {
            return this.http.post(this.getApiUrl(`system_settings/workflow_sequence/edit/${id}`), JSON.stringify(data));
        }
    }

    getWorkflowSetupSequencesList(params?: Object): Observable<any> {
        return this.http
          .get(this.getApiUrl(`system_settings/workflow_sequences`, params));
    }

    getCustomContactsList(id?:number, type?:string, params?: Object): Observable<any> {
        return this.http
            .get(this.getApiUrl(`customers/${type}/${id}/contacts/view`, params));
    }

    getEmailSmsTemplatesByIds(params?: Object): Observable<any> {
        return this.http
            .get(this.getApiUrl(`system_settings/emailsms_templates`, params));
    }

    postAddContactEmail(data: object, contactId:number) {
        return this.http.post(this.getApiUrl(`workflow/sequence/add_email/${contactId}`), JSON.stringify(data));
    }

    postAddContactMobile(data: object, contactId:number) {
        return this.http.post(this.getApiUrl(`workflow/sequence/add_mobile/${contactId}`), JSON.stringify(data));
    }

    postSendSequenceRequest(data: object, objectName:string, objectId:number|string) {
        return this.http.post(`${this.apiBasePath}workflow/send_sequence/${objectName}/${objectId}`, data);
    }

    posSequenceDagRunCreateRequest(data: object, objectName:string, objectId:number|string) {
        return this.http.post(`${this.apiBasePath}workflow/dagrun_sequence/${objectName}/${objectId}`, data);
    }

    getSequenceRuns(objectName:string, objectId:number|string) {
        return this.http.get(`${this.apiBasePath}workflow/dagrun_sequence/${objectName}/${objectId}/list`);
    }

    postStopSequenceRequest(data, objectId:any) {
        return this.http.post(`${this.apiBasePath}workflow/stop/sequence/${objectId}`, data);
    }
    getDagrunnerStatus(objectId:number|string){
        return this.http.get(`${this.apiBasePath}workflow/${objectId}/has_active_dagrun_status`);
    }

    getTemplateById(templateId:number|string) {
        return this.http.get(`${this.apiBasePath}communication/settings/template/${templateId}`);
    }

    getCommunicationTemplateConfig() {
        return this.http.get(`${this.apiBasePath}workflow/communication/template/config`);
    }

    postExitConditionRequest(data, objectName:string,objectId:any){
        return this.http.post(`${this.apiBasePath}workflow/${objectName}/${objectId}/add_exit_condition`, data);
    }
    getExitConditions(objectName:string,objectId:any){
        return this.http.get(`${this.apiBasePath}workflow/${objectName}/${objectId}/get_exit_condition`)
    }
    deleteExitConditions(id:number,objectName:string,objectId:any){
        return this.http.delete(`${this.apiBasePath}workflow/${objectName}/${objectId}/delete_exit_condition/${id}`)
    }
    workflowAuditRecords(id:any, type:string, data){
        return this.http.post(`${this.apiBasePath}workflow/audit_records/${type}/${id}`, data);
    }
    getAuditDagsource(id:number){
        // return this.http.get(this.getApiUrl(`workflow/audit_records/${id}/dagsource`));
        return this.http.get(`${this.apiBasePath}workflow/audit_records/${id}`)
    }

    getAllPipelineAutomation(data, objectName:string) {
        return this.http.post(`${this.apiBasePath}workflow/settings/pipeline-automation/${objectName}/all`, data);
    }

    savePipelineAutomation(data, objectName:string): Observable<any> {
        return this.http.post(`${this.apiBasePath}workflow/settings/pipeline-automation/${objectName}/save`, data);
    }

    deletePipelineAutomationItem(id: number, objectName:string) {
        return this.http.delete(`${this.apiBasePath}workflow/settings/pipeline-automation/${objectName}/${id}/delete`);
    }

    updatePipelineAutomation(data, objectName:string) {
        return this.http.post(`${this.apiBasePath}workflow/settings/pipeline-automation/${objectName}/update`, data);
    }

    updateStatusPipelineAutomation(data: any, objectName:string, id:number) {
        return this.http.post(`${this.apiBasePath}workflow/settings/pipeline-automation/${objectName}/${id}/update_status`, data);
    }
}
