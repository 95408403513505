import { OnInit } from '@angular/core';
import { Subject } from "rxjs";
import { BsModalRef } from "ngx-bootstrap";
var TemplateViewComponent = /** @class */ (function () {
    function TemplateViewComponent(modalRef) {
        this.modalRef = modalRef;
    }
    TemplateViewComponent.prototype.ngOnInit = function () {
        this.onClose = new Subject();
    };
    return TemplateViewComponent;
}());
export { TemplateViewComponent };
