export interface StartSequenceBlockModelInterface {
    templateId: string;
    templateName: string;
    contactId: string;
}

export class StartSequenceBlockModel implements StartSequenceBlockModelInterface {
    templateId: string = '';
    templateName: string = '';
    contactId: string = ''

    constructor(data:{} = undefined) {
        if(data) {
            this.templateId = data['templateId'] ? data['templateId'] : '';
            this.templateName = data['templateName'] ? data['templateName'] : '';
            this.contactId = data['contactId'] ? data['contactId'] : '';
        }
    }
}
