import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { InterfaceService } from "@app/interfaces";
var ViewVehicleService = /** @class */ (function (_super) {
    tslib_1.__extends(ViewVehicleService, _super);
    function ViewVehicleService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.closeModal = new BehaviorSubject('');
        _this.closeModal$ = _this.closeModal.asObservable();
        _this.saveData = new BehaviorSubject('');
        _this.saveData$ = _this.saveData.asObservable();
        _this.sideBarRefresh = new BehaviorSubject('');
        _this.sideBarRefresh$ = _this.sideBarRefresh.asObservable();
        _this.fileTypeGroups = {
            image: ['jpg', 'png', 'gif', 'jpeg', 'PNG', 'JPEG', 'JPG', 'GIF'],
            pdf: ['pdf'],
            csv: ['csv'],
            word_document: ['doc', 'docx'],
            excel: ['xls', 'xlsx', 'ods', 'xlr'],
            audio: ['mp3', 'mpa', 'ogg', 'mid', 'midi'],
            video: ['avi', 'flv', 'mov', 'mp4', 'mpg', 'mpeg'],
            compressed: ['7z', 'rar', 'zip', 'tar.gz'],
            power_point: ['odp', 'pps', 'ppt', 'pptx'],
        };
        return _this;
    }
    ViewVehicleService.prototype.getVehicleDetails = function (id) {
        return this.http.get(this.getFleetApiUrl("" + id + "/getVehicleSidebarDetails"));
    };
    ViewVehicleService.prototype.postApiCallFilter = function (params) {
        return this.http.post(this.getFleetApiUrl("addTimelineFilter"), params);
    };
    // getVehicleTimeline(id) {
    //     return this.http.get(this.getFleetApiUrl(`${id}` + `/getVehicleTimeline`));
    // }
    ViewVehicleService.prototype.getTimelineFilter = function () {
        return this.http.get(this.getFleetApiUrl("/getVehicleTimelineFilters"));
    };
    ViewVehicleService.prototype.getVehicleMaintenance = function (vehicleId, type) {
        return this.http.get(this.getFleetApiUrl(vehicleId + "/listMaintenance?type=" + type));
    };
    ViewVehicleService.prototype.addOrEditVehicleViewMaintenance = function (params) {
        return this.http.post(this.getFleetApiUrl("addOrEditVehicleViewMaintenance"), params);
    };
    ViewVehicleService.prototype.deleteViewMaintenance = function (id, maintenanceId) {
        return this.http.delete(this.getFleetApiUrl(id + "/deleteMaintenance/" + maintenanceId));
    };
    ViewVehicleService.prototype.removeTracker = function (id) {
        return this.http.get(this.getFleetApiUrl("/" + id + "/vehicleRemoveTraccar"));
    };
    ViewVehicleService.prototype.addEditFileUpload = function (files, deleteIds, vehicleId) {
        var formData = new FormData();
        if (files != undefined) {
            for (var i = 0; i < files.length; i++) {
                formData.append("file" + i, files[i], files[i].name);
            }
        }
        if (deleteIds) {
            formData.append("deleteIds", deleteIds);
        }
        formData.append('vehicleId', vehicleId);
        var url = this.getFleetApiUrl("addOrEditVehicleFiles");
        return this.http.post(url, formData);
    };
    ViewVehicleService.prototype.deleteVehicleFile = function (id) {
        return this.http.delete(this.getFleetApiUrl(id + "/deleteVehicleFiles"));
    };
    ViewVehicleService.prototype.getTrackingDetails = function (data) {
        return this.http.post(this.getFleetApiUrl("getVehicleTrips"), data);
    };
    ViewVehicleService.prototype.getVehicleTripRoutes = function (data) {
        return this.http.post(this.getFleetApiUrl("getVehicleTripRoutes"), data);
    };
    ViewVehicleService.prototype.getVehicleFileFilter = function () {
        return this.http.get(this.getFleetApiUrl("/getVehicleFileFilters"));
    };
    ViewVehicleService.prototype.removeDriver = function (id, endMileage) {
        return this.http.get(this.getFleetApiUrl("/" + id + ("/vehicleRemoveDriver?endMileage=" + endMileage)));
    };
    ViewVehicleService.prototype.getDriverHistoryDetails = function (id) {
        return this.http.get(this.getFleetApiUrl("/" + id + "/viewDriverHistory"));
    };
    ViewVehicleService.prototype.getVehicleList = function () {
        return this.http.get(this.getFleetApiUrl("listVehiclesDashboard"));
    };
    ViewVehicleService.prototype.getLiveMapData = function () {
        return this.http.get(this.getFleetApiUrl("liveMapData"));
    };
    ViewVehicleService.prototype.getSavedLocations = function () {
        return this.http.get(this.getFleetApiUrl("listSavedLocations"));
    };
    ViewVehicleService.prototype.getMapPinDatas = function () {
        return this.http.get(this.getFleetApiUrl("getMapPinData"));
    };
    ViewVehicleService.prototype.deleteLocation = function (id) {
        return this.http.delete(this.getFleetApiUrl(id + "/deleteSavedLocation"));
    };
    ViewVehicleService.prototype.getRouteToJob = function (coordinates) {
        var params = {
            coordinates: coordinates
        };
        return this.http.post(this.getFleetApiUrl('getRouteToJob'), params);
    };
    ViewVehicleService.prototype.loadOldData = function (dateSelected, deviceId) {
        var params = {
            dateSelected: dateSelected, deviceId: deviceId
        };
        return this.http.post(this.getFleetApiUrl('loadOldData'), params);
    };
    ViewVehicleService.prototype.loadOldDataForAllDevices = function (dateSelected, start, interval) {
        if (interval === void 0) { interval = 3; }
        var params = {
            dateSelected: dateSelected, hour: start, interval: interval
        };
        return this.http.post(this.getFleetApiUrl('loadOldDataForAllDevices'), params);
    };
    ViewVehicleService.prototype.getDiaryUpdates = function (diaryId) {
        return this.http.get(this.getFleetApiUrl("/" + diaryId + "/getDiaryUpdates"));
    };
    ViewVehicleService.prototype.getIncidentDetailsFilter = function () {
        return this.http.get(this.getFleetApiUrl("/getDriverIncidentFilters"));
    };
    ViewVehicleService.prototype.getIncidentDetail = function (rowId) {
        return this.http.get(this.getFleetApiUrl("" + rowId + "/getIncidentRoute"));
    };
    ViewVehicleService.prototype.getLiveAddress = function (markLocationLatLon) {
        return this.http.post(this.getFleetApiUrl("getLiveMapAddress"), markLocationLatLon);
    };
    ViewVehicleService.prototype.getEmailAddressList = function (url) {
        return this.http.get(this.getFleetApiUrl(url));
    };
    ViewVehicleService.prototype.onSaveEmail = function (params) {
        return this.http.post(this.getFleetApiUrl('addVehicleActivityEmail'), params);
    };
    ViewVehicleService.prototype.getVehicleCheckDetails = function (id) {
        return this.http.get(this.getFleetApiUrl("/" + id + "/vehicleCheckDetails"));
    };
    ViewVehicleService.prototype.getBranchLocation = function () {
        return this.http.get(this.getFleetApiUrl('getBranchLocation'));
    };
    ViewVehicleService.prototype.listSmsTemplates = function (vehicleId) {
        var url = this.getFleetApiUrl("/" + vehicleId + "/getSmsTemplates");
        return this.http.get(url);
    };
    ViewVehicleService.prototype.updateAttachments = function (id, data) {
        return this.http.post(this.getFleetApiUrl("" + id + "/editVehicleFiles"), data);
    };
    ViewVehicleService.prototype.setSideBarRefresh = function (data) {
        this.sideBarRefresh.next(data);
    };
    return ViewVehicleService;
}(InterfaceService));
export { ViewVehicleService };
