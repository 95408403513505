import { Injectable, StaticProvider } from '@angular/core';

export interface ClassName {
    SCROLLBAR_MEASURER: string;
    BACKDROP: string;
    OPEN: string;
    FADE: string;
    IN: string;
    SHOW: string;
}

export interface Selector {
    DIALOG: string;
    DATA_TOGGLE: string;
    DATA_DISMISS: string;
    FIXED_CONTENT: string;
}

export interface TransitionDurations {
    MODAL: number;
    BACKDROP: number;
}

export interface DismissReasons {
    BACKRDOP: string;
    ESC: string;
}

@Injectable()
export class SidepanelOptions {
    backdrop?: boolean | 'static';
    keyboard?: boolean;
    focus?: boolean;
    show?: boolean;
    ignoreBackdropClick?: boolean;
    class?: string;
    animated?: boolean;
    initialState?: Object;
    providers?: StaticProvider[];
    ariaLabelledBy?: string;
    ariaDescribedby?: string;
    size?: string;
    closeConfirmation?: boolean;
    wrapperClass?: string;
    panelType?: string;
    hasSubtitle?: boolean;
}


export const modalConfigDefaults: SidepanelOptions = {
    backdrop: true,
    keyboard: false,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: '',
    animated: true,
    initialState: {},
    size: 'standard',
    closeConfirmation:true,
    wrapperClass: 'cs-dp-side-panel',
    panelType: 'sidePanel',
    hasSubtitle: false,
};

export const CLASS_NAME: ClassName = {
    SCROLLBAR_MEASURER: 'modal-scrollbar-measure',
    BACKDROP: 'modal-backdrop',
    OPEN: 'modal-open',
    FADE: 'fade',
    IN: 'in', // bs3
    SHOW: 'show' // bs4
};

export const SELECTOR: Selector = {
    DIALOG: '.modal-dialog',
    DATA_TOGGLE: '[data-toggle="modal"]',
    DATA_DISMISS: '[data-dismiss="modal"]',
    FIXED_CONTENT: '.navbar-fixed-top, .navbar-fixed-bottom, .is-fixed'
};

export const TRANSITION_DURATIONS: TransitionDurations = {
    MODAL: 300,
    BACKDROP: 150
};

export const DISMISS_REASONS: DismissReasons = {
    BACKRDOP: 'backdrop-click',
    ESC: 'esc'
};
