import { ElementRef, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { FolderService } from '../service/folder.service';
var AddEditFolderModalComponent = /** @class */ (function () {
    function AddEditFolderModalComponent(formBuilder, httpService, bsModalRef) {
        this.formBuilder = formBuilder;
        this.httpService = httpService;
        this.bsModalRef = bsModalRef;
        this.action = '';
        this.editId = '';
        this.uniqueName = false;
    }
    Object.defineProperty(AddEditFolderModalComponent.prototype, "f", {
        get: function () {
            return this.form.controls;
        },
        enumerable: true,
        configurable: true
    });
    AddEditFolderModalComponent.prototype.ngOnInit = function () {
        this.onClose = new Subject();
        this.form = this.formBuilder.group({
            'name': [this.name, [Validators.required, Validators.minLength(1)]],
        });
        if (this.name != '') {
            this.form.controls['name'].setValue(this.name);
        }
    };
    AddEditFolderModalComponent.prototype.checkUniqueName = function (value) {
        var _this = this;
        if (this.screen == 'content_library' || this.screen === 'custom_forms') {
            var text = value;
            var data = {
                title: text,
                screen: this.screen,
                isFolder: 1,
            };
            var checkName = false;
            if (this.editId) {
                if (this.name == value) {
                    checkName = false;
                }
                else {
                    checkName = true;
                }
            }
            else {
                checkName = true;
            }
            if (checkName) {
                if (this.checkIsFolderExistsMethod) {
                    data['tableType'] = this.tableType;
                    this.checkIsFolderExistsMethod(data).subscribe(function (res) { return _this.uniqueName = res; });
                    /*
                    Note: This has been implemented for avoiding the code duplication & patching as below.
                     - So, once this common-logic replaced for all, then the below code should be removed.
                     */
                    return;
                }
                this.httpService.checkUniqueFolderName(data).subscribe(function (resp) {
                    _this.uniqueName = resp;
                });
            }
        }
    };
    AddEditFolderModalComponent.prototype.save = function () {
        var _this = this;
        if (!this.form.valid) {
            this.action = '';
            return false;
        }
        this.action = 'saving';
        var folderDetails = {
            folderName: this.form.controls['name'].value,
            folderId: this.folderId,
            editId: this.editId
        };
        if (this.addOn) {
            folderDetails['addOn'] = this.addOn;
        }
        if (this.addOrEditMethod) {
            folderDetails['tableType'] = this.tableType;
            this.addOrEditMethod(folderDetails).subscribe(function (resp) {
                _this.action = '';
                _this.onClose.next(resp);
                _this.bsModalRef.hide();
            }, function (error) {
                _this.action = '';
                console.log('err', error);
            });
            /*
            Note: This has been implemented for avoiding the code duplication & patching as below.
             - So, once this common-logic replaced for all, then the below code should be removed.
             */
            return;
        }
        if (this.screen == 'vehicleForms') {
            this.httpService.postApiV5Call(this.url, folderDetails)
                .subscribe(function (resp) {
                _this.action = '';
                _this.onClose.next(resp);
                _this.bsModalRef.hide();
            }, function (error) {
                _this.action = '';
                console.log('err', error);
            });
        }
        else {
            this.httpService.postApiCall(this.url, folderDetails)
                .subscribe(function (resp) {
                _this.action = '';
                _this.onClose.next(resp);
                _this.bsModalRef.hide();
            }, function (error) {
                _this.action = '';
                console.log('err', error);
            });
        }
    };
    return AddEditFolderModalComponent;
}());
export { AddEditFolderModalComponent };
