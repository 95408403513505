export interface SendJobReportBlockModelInterface{
    templateId: string;
    toEmailTag: any[];
    fromEmail: string;
    subject: string;
    message: string;
    templateName: string;
    doAttachJobReport: boolean;

}
export class SendJobReportBlockModel{
    fromEmail: string = '';
    message: string = '';
    subject: string = '';
    templateId: string = '';
    templateName: string = '';
    toEmailTag: any[] = [];
    doAttachJobReport: boolean = true;
    constructor(data:{} = undefined) {
        if(data){
            this.templateId = data['templateId'] ? data['templateId'] : '';
            this.toEmailTag = data['toEmailTag'] ? data['toEmailTag'] : [];
            this.fromEmail = data['fromEmail'] ? data['fromEmail'] : '';
            this.subject = data['subject'] ? data['subject'] : '';
            this.templateName = data['templateName'] ? data['templateName'] : '';
            this.message = data['message'] ? data['message'] : '';
            this.doAttachJobReport = data.hasOwnProperty('doAttachJobReport') ? data['doAttachJobReport'] : true;
        }
    }
}
