import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { InterfaceService } from "@app/interfaces";

@Injectable()

export class ViewVehicleService extends InterfaceService {

    private closeModal = new BehaviorSubject('');
    closeModal$ = this.closeModal.asObservable()

    private saveData = new BehaviorSubject('');
    saveData$ = this.saveData.asObservable();

    private sideBarRefresh = new BehaviorSubject('');
    sideBarRefresh$ = this.sideBarRefresh.asObservable();

    fileTypeGroups: any = {
        image: ['jpg', 'png', 'gif', 'jpeg', 'PNG', 'JPEG', 'JPG', 'GIF'],
        pdf: ['pdf'],
        csv: ['csv'],
        word_document: ['doc', 'docx'],
        excel: ['xls', 'xlsx', 'ods', 'xlr'],
        audio: ['mp3', 'mpa', 'ogg', 'mid', 'midi'],
        video: ['avi', 'flv', 'mov', 'mp4', 'mpg', 'mpeg'],
        compressed: ['7z', 'rar', 'zip', 'tar.gz'],
        power_point: ['odp', 'pps', 'ppt', 'pptx'],
    };

    constructor(private http: HttpClient) {
        super();
    }

    getVehicleDetails(id) {
        return this.http.get(this.getFleetApiUrl(`${id}` + `/getVehicleSidebarDetails`));
    }

    postApiCallFilter(params) {
        return this.http.post(this.getFleetApiUrl(`addTimelineFilter`), params);
    }

    // getVehicleTimeline(id) {
    //     return this.http.get(this.getFleetApiUrl(`${id}` + `/getVehicleTimeline`));
    // }

    getTimelineFilter() {
        return this.http.get(this.getFleetApiUrl(`/getVehicleTimelineFilters`));
    }

    getVehicleMaintenance(vehicleId:any,type:any) {
        return this.http.get(this.getFleetApiUrl(`${vehicleId}/listMaintenance?type=${type}`));
    }

    addOrEditVehicleViewMaintenance(params?: any): Observable<any> {
        return this.http.post(this.getFleetApiUrl(`addOrEditVehicleViewMaintenance`), params);
    }

    deleteViewMaintenance(id: number, maintenanceId: any): Observable<any> {
        return this.http.delete(this.getFleetApiUrl(`${id}/deleteMaintenance/${maintenanceId}`));
    }

    removeTracker(id) {
        return this.http.get(this.getFleetApiUrl(`/${id}` + `/vehicleRemoveTraccar`));
    }

    addEditFileUpload(files, deleteIds, vehicleId): Observable<any> {
        const formData: any = new FormData();
        if (files != undefined) {
            for (var i = 0; i < files.length; i++) {
                formData.append("file" + i, files[i], files[i].name);
            }
        }
        if (deleteIds) {
            formData.append("deleteIds", deleteIds)
        }
        formData.append('vehicleId', vehicleId)
        let url = this.getFleetApiUrl(`addOrEditVehicleFiles`);

        return this.http.post(url, formData);
    }

    deleteVehicleFile(id: number): Observable<any> {
        return this.http.delete(this.getFleetApiUrl(`${id}/deleteVehicleFiles`));
    }

    getTrackingDetails(data) {
        return this.http.post(this.getFleetApiUrl(`getVehicleTrips`), data);
    }

    getVehicleTripRoutes(data) {
        return this.http.post(this.getFleetApiUrl(`getVehicleTripRoutes`), data);
    }

    getVehicleFileFilter(): Observable<any> {
        return this.http.get(this.getFleetApiUrl(`/getVehicleFileFilters`));
    }
    removeDriver(id: any, endMileage: any) {
        return this.http.get(this.getFleetApiUrl(`/${id}` + `/vehicleRemoveDriver?endMileage=${endMileage}`));
    }
    getDriverHistoryDetails(id) {
        return this.http.get(this.getFleetApiUrl(`/${id}` + `/viewDriverHistory`));
    }

    getVehicleList() {
        return this.http.get(this.getFleetApiUrl(`listVehiclesDashboard`));
    }

    getLiveMapData() {
        return this.http.get(this.getFleetApiUrl(`liveMapData`));
    }

    getSavedLocations() {
        return this.http.get(this.getFleetApiUrl(`listSavedLocations`));
    }

    getMapPinDatas() {
        return this.http.get(this.getFleetApiUrl(`getMapPinData`));
    }

    deleteLocation(id) {
        return this.http.delete(this.getFleetApiUrl(`${id}/deleteSavedLocation`));
    }

    getRouteToJob(coordinates: string) {
        let params = {
            coordinates: coordinates
        };
        return this.http.post(this.getFleetApiUrl('getRouteToJob'), params);
    }

    loadOldData(dateSelected: any, deviceId: number) {
        let params = {
            dateSelected: dateSelected, deviceId: deviceId
        };
        return this.http.post(this.getFleetApiUrl('loadOldData'), params);
    }

    loadOldDataForAllDevices(dateSelected: any, start: number, interval: number = 3) {
        let params = {
            dateSelected: dateSelected, hour: start, interval: interval
        };
        return this.http.post(this.getFleetApiUrl('loadOldDataForAllDevices'), params);
    }

    getDiaryUpdates(diaryId: number) {
        return this.http.get(this.getFleetApiUrl(`/${diaryId}` + `/getDiaryUpdates`));
    }

    getIncidentDetailsFilter() {
        return this.http.get(this.getFleetApiUrl(`/getDriverIncidentFilters`));
    }

    getIncidentDetail(rowId: number) : Observable<any> {
        return this.http.get(this.getFleetApiUrl(`${rowId}` + `/getIncidentRoute`));
    }

    getLiveAddress(markLocationLatLon): Observable<any> {
        return this.http.post(this.getFleetApiUrl(`getLiveMapAddress`), markLocationLatLon);
    }

    getEmailAddressList(url) {
        return this.http.get(this.getFleetApiUrl(url))
    }

    onSaveEmail(params) {
        return this.http.post(this.getFleetApiUrl('addVehicleActivityEmail'), params);
    }

    getVehicleCheckDetails(id:number){
        return this.http.get(this.getFleetApiUrl( `/${id}`+ `/vehicleCheckDetails`));
    }

    getBranchLocation(){
        return this.http.get(this.getFleetApiUrl('getBranchLocation'));
    }

    listSmsTemplates(vehicleId) {
        let url = this.getFleetApiUrl(`/${vehicleId}`+ `/getSmsTemplates`)
        return this.http.get(url);
    }

    updateAttachments(id: any, data : any){
        return this.http.post(this.getFleetApiUrl(`${id}`+ `/editVehicleFiles`), data);
    }

    setSideBarRefresh(data){
        this.sideBarRefresh.next(data)
    }
}
