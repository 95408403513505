export interface SmsBlockModelInterface {
    templateId: string;
    toSmsTag: any[];
    message: string;
    title: string;
}

export class SmsBlockModel implements SmsBlockModelInterface {
    message: string;
    templateId: string;
    title: string;
    toSmsTag: any[];

    constructor(data:{} = undefined) {
        if(data) {
            this.templateId = data['templateId'] ? data['templateId'] : '';
            this.toSmsTag = data['toSmsTag'] ? data['toSmsTag'] : undefined;
            this.title = data['title'] ? data['title'] : '';
            this.message = data['message'] ? data['message'] : '';
        }
    }

}
