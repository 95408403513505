/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./template-preview.component";
import * as i2 from "../../../sidepanel/services/sidepanel-modal.service";
var styles_TemplatePreviewComponent = ["[disabled][_ngcontent-%COMP%] {cursor: not-allowed}"];
var RenderType_TemplatePreviewComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_TemplatePreviewComponent, data: {} });
export { RenderType_TemplatePreviewComponent as RenderType_TemplatePreviewComponent };
export function View_TemplatePreviewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-link"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPreview() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabledBtn; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.linkTitle; _ck(_v, 1, 0, currVal_1); }); }
export function View_TemplatePreviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-template-preview", [], null, null, null, View_TemplatePreviewComponent_0, RenderType_TemplatePreviewComponent)), i0.ɵdid(1, 114688, null, 0, i1.TemplatePreviewComponent, [i2.SidepanelModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TemplatePreviewComponentNgFactory = i0.ɵccf("app-template-preview", i1.TemplatePreviewComponent, View_TemplatePreviewComponent_Host_0, { linkTitle: "linkTitle", type: "type", message: "message", subject: "subject" }, {}, []);
export { TemplatePreviewComponentNgFactory as TemplatePreviewComponentNgFactory };
