import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild, Input } from '@angular/core';
import { UploadDocumentService } from '@app/features/ai-document-parsing/services/upload-document.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FileUploader } from 'ng2-file-upload';
import { CsToastBoxService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
declare var _: any;
declare var jQuery;
declare var $: any;

@Component({
    selector: 'app-upload-document',
    templateUrl: './upload-document.component.html',
    styleUrls: ['./upload-document.component.css']
})
export class UploadDocumentComponent implements OnInit {
    
    documentUploader:FileUploader;
    docparserAllowedExtension = ['application/pdf', 'image/tiff'];
    mindeeAllowedExtension = ['application/pdf'];
    allowedFileType = ['pdf', 'tiff'];
    
    extensions = ['application/pdf', 'image/tiff', 'application/msword', 'applicaiton/vnd.openxmlformat', 'application/pdf', 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel'];
    
    selectedActions: any="existingTemplate" // Variable to store selected action in modal
    files: File[];

    suppliersList = [];

    selectedSupplier: any;

    uploadCount: any = 0;
    isFileUploaded: any = false;
    filesCount: number = 0;
    select2Options4Supplier = {};

    errorMsg: any = null; // To show error message for postDocParserDocument API
    successMsg: any = null;
    fileCountError: any = null;
    disableStartUpload: boolean = false; // To disable Start Upload Button
    progressValue: number = 0; // Set the initial progress value (0-100)
    showProgressBar: boolean = false;
    displayMessage: boolean = false;
    
    @ViewChild('closeModalTrigger') closeModalTrigger: ElementRef;

    @Input() jobid: any;
    @Input() supplierid: any;
    @Input() docparsertemplateid: any;
    @Input() docparsertemplates: any;

    isUploadedViaSuppliersPage: boolean = false;
    select2Options4DocparserTemplate = {
        minimumInputLength: 2,
        width: "100%",
        data: []
    };
    docparserTemplateList = [];
    selectedDocparserTemplateId: any;
    increment = 5; // Value increment per 100 milliseconds
    interval = 100; // Update every 100 milliseconds
    templateMessage = '';
    supplierDropdownPlaceholder = '';
    templateDropdownPlaceholder = '';
    docparserTemplateName = '';
    
    bsModalRef: BsModalRef;
    isFileDrop: boolean = false;
    isCustomDimensions: boolean = false;
    droppedFiles: File[] = [];
    navigate: boolean = false;

    constructor(
        private renderer: Renderer2,
        private el: ElementRef,
        private fileUploadingService: UploadDocumentService,
        private toastBox: CsToastBoxService,
        private translate: TranslateService
    ) { }
    
    ngOnInit() {
        this.supplierDropdownPlaceholder = this.translate.instant('Please.enter.supplier.name');
        this.templateDropdownPlaceholder = this.translate.instant('Please.enter.template.name');
        this.selectedActions = 'existingTemplate';

        this.fileUploadingService.getDocparserTemplate().subscribe((resp) => {
            if (resp.hasOwnProperty('parsers')) {
                this.docparserTemplateList = resp['parsers'].map(rec => {
                    return {
                        'id': rec.id,
                        'text': rec.name,
                    }
                })

                this.select2Options4DocparserTemplate['data'] = this.docparserTemplateList;
            }
        });

        this.select2Options4Supplier = {
            minimumInputLength: 2,
            width: "100%",
            initSelection: () => {},
            ajax: {
                url: environment.apiBasePath + 'get_all_suppliers',
                data: (searchText, page) => {
                    if (searchText == '') {
                        return false;
                    }
                    return {'q': encodeURIComponent(searchText)};
                },
                results: (data) => {
                    data['response'] = data;
                    if (Array.isArray(data.response)) {
                        for (var item of data.response) {
                            let index = this.suppliersList.find(el => el.id == item.id);
                            if (index == -1) {
                                this.suppliersList.push(item);
                            }
                        }
                    }
                    return {results: data.response}
                },
            },
        };
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event): void {
        this.getDimensions();
    }
   
    @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

    private getDimensions(): void {
        // Get the window dimensions
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        // Apply your custom logic to determine if the styles should be applied
        this.isCustomDimensions = windowWidth > 800 && windowHeight > 600; // Example: Apply styles if both width and height exceed certain values

        // Apply styles to the host element
        if (this.isCustomDimensions) {
            this.renderer.addClass(this.el.nativeElement, 'jobUploadSection');
        } else {
            this.renderer.removeClass(this.el.nativeElement, 'jobUploadSection');
        }
    }

    onDragOver(event: any) {
        event.preventDefault();
        event.stopPropagation();

        // Check if CSV files are being dragged
        this.filesCount = event.dataTransfer!.items.length;
        
        if (this.droppedFiles.length == 0) {
            let urlString = window.location.href;
            document.getElementById("fileUploadingModal").style.display = "inline-block";
            var automatedAIParsing = document.getElementById('automatedAIParsing')
            automatedAIParsing.style.border = "2px dashed var(--neutral-focus, #9ED1F3)";
            automatedAIParsing.style.position = "relative";
            automatedAIParsing.style.zIndex = "20000";
            automatedAIParsing.style.backgroundColor = "rgba(56, 131, 193, 0.12)";
            if (urlString.includes('supplier_details')) {
                this.isUploadedViaSuppliersPage = true;
                automatedAIParsing.style.minHeight = "calc(100% + 105px)";
            } else {
                this.isUploadedViaSuppliersPage = false;
                automatedAIParsing.style.minHeight = "calc(100% + 15px)";
            }
        }
    }

    onDragLeave(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();

        if (this.droppedFiles.length == 0 && this.isFileDrop == false) {
            var automatedAIParsing = document.getElementById('automatedAIParsing')
            automatedAIParsing.style.removeProperty('border');
            automatedAIParsing.style.removeProperty('minHeight');
            automatedAIParsing.style.removeProperty('position');
            automatedAIParsing.style.removeProperty('zIndex');
            automatedAIParsing.style.backgroundColor = "transparent";
            document.getElementById("hideZIndexProperty").style.zIndex = "0";
        } else {
            document.getElementById("hideZIndexProperty").style.zIndex = "10";
        }
        
    }


    onDrop(event: DragEvent): void {
        let urlString = window.location.href;
        if (urlString.includes('supplier_details')) {
            this.isUploadedViaSuppliersPage = true;
        } else {
            this.isUploadedViaSuppliersPage = false;
        }

        this.getSelectedOptions(this.selectedActions, true);
        event.preventDefault();
        event.stopPropagation();

        this.isFileDrop = true;

        if (this.droppedFiles.length == 0) {
            var automatedAIParsing = document.getElementById('automatedAIParsing')
            automatedAIParsing.style.removeProperty('border');
            automatedAIParsing.style.removeProperty('minHeight');
            automatedAIParsing.style.removeProperty('position');
            automatedAIParsing.style.removeProperty('zIndex');
            automatedAIParsing.style.backgroundColor = "transparent";
        }
       
        const files: FileList | null = event.dataTransfer!.files;

        if (files) {
            // Iterate over each file and add it to the droppedFiles array
            for (let i = 0; i < files.length; i++) {
                const file: File = files[i];
                if (!this.isFileInCollection(file)) {
                    this.droppedFiles.push(file);
                }
                // Additional logic for processing or handling each dropped file can be added here
            }
            // Additional logic for processing or handling all dropped files can be added here
            this.checkStartUpload();
        }
        this.fileUploadingService.sendFilesToUpload(this.droppedFiles)
    }

    onFileSelected(event: Event): void {
        const inputElement = this.fileInput.nativeElement as HTMLInputElement;
        const files = inputElement.files;

        if (files && files.length > 0) {
            
            this.handleFiles(files);
        }
        this.checkStartUpload();
    }

    private handleFiles(files: FileList): void {
        for (let i = 0; i < files.length; i++) {
            if (!this.isFileInCollection(files[i])) {
                this.droppedFiles.push(files[i]);
            }
        }
    }

    openModalBox() {
        const trigger=document.getElementById("uploadModalTrigger") as HTMLElement
        trigger.click()
    }
    
    // This function will be called when we will select the options
    getSelectedOptions(option: any, innerCall: boolean = false) {
        if (option == null) {
            this.selectedActions = 'existingTemplate';
        } else {
            this.selectedActions = option;
        }
        
        this.errorMsg = null;
        if (this.selectedActions == 'existingTemplate') {
            if (!this.isUploadedViaSuppliersPage) {
                this.fileUploadingService.getSuppliers().subscribe((resp) => {
                    this.suppliersList = resp;
                });
            } else {
                // Show the default template if present
                this.selectedDocparserTemplateId = this.docparsertemplateid;

                let templateId = parseInt(this.docparsertemplateid);

                if (isNaN(templateId)) {
                    this.docparsertemplateid = -1;
                } else {
                    this.docparsertemplateid = templateId;
                }

                let index = this.docparserTemplateList.findIndex((value: any) => value.id == parseInt(this.docparsertemplateid));
                if (index >= 0) {
                    this.templateMessage = this.translate.instant('The.automated.invoice.template.used.for.this.supplier.is');
                    this.docparserTemplateName = this.docparserTemplateList[index]['text'];
                } else {
                    this.templateMessage = this.translate.instant('Select.the.automated.invoice.template.to.assign.to.the.supplier');
                }
                this.checkStartUpload();
            }
        }

        if (!this.isUploadedViaSuppliersPage) {
            if (!innerCall) {
                this.supplierid = -1;
            }
        }

        this.checkStartUpload();
    }

    getBorderStyle(percentage: number): string {
        const borderColor = '#007bff'; // Blue color
        return `linear-gradient(90deg, ${borderColor} ${percentage}%, transparent 0%)`;
    }

    updateValue() {
        if (this.successMsg == null && this.errorMsg == null) {
            if (this.progressValue < 90) {
                this.progressValue += this.increment;
            } else {
                // Wait till response comes from postDocParserDocument
                this.progressValue = 95;
            }
        }
    }

    createInvoiceViaDocparser(data) {
        let stopTimer = setInterval(() => { this.updateValue(); }, this.interval);
        // Integrate postDocParserDocument API
        this.fileUploadingService.postDocParserDocument(data).subscribe(resp => {
            if (resp['error'] == false) {
                this.successMsg = "";
            } else {
                this.errorMsg = resp['error'];
            }
        }, error => {
            if (error.hasOwnProperty('errorMessage')) {
                this.errorMsg = error['errorMessage'];
            } else {
                this.errorMsg = this.translate.instant('Error.while.uploading.files');
            }
        }).add(() => {
            // Called when operation is complete (both success and error)
            clearInterval(stopTimer);
            if (this.successMsg != null) {
                this.progressValue = 100;
                setTimeout(() => { 
                    this.closeModal();
                }, 500);
            } else {
                this.progressValue = 0;
                this.disableStartUpload = false;
            }
        });
    }

    createInvoiceViaMindee(data) {
        // Integrate postDocumentAIParser API
        let stopTimer = setInterval(() => { this.updateValue(); }, this.interval);
        
        this.fileUploadingService.postDocumentAIParser(data).subscribe(resp => {
            if (resp['StatusCode'] == 200) {
                this.successMsg = resp['message'];
            } else {
                this.errorMsg = resp['error'];
            }
        }, error => {
            if (error.hasOwnProperty('errorMessage')) {
                this.errorMsg = error['errorMessage'];
            } else {
                this.errorMsg = this.translate.instant('Error.while.uploading.files');
            }
        }).add(() => {
            // Called when operation is complete (both success and error)
            clearInterval(stopTimer);
            if (this.successMsg != null) {
                this.progressValue = 100;
                setTimeout(() => { 
                    this.closeModal();
                }, 100);
            } else {
                this.progressValue = 0;
                this.disableStartUpload = false;
            }
        });
    }

    // You can add your code after submission here . You will get selected option in "selectedActions", selected suppliers in "selectedSupplier", files in "files" this is an array of objects
    startToUpload() {  
        this.showProgressBar = true;
        this.successMsg = null;
        this.errorMsg = null;
        this.progressValue = 5; // Set the initial progress value (0-100)

        let data = {
            'supplierid': this.supplierid,
            'jobid': this.jobid,
            'files': this.droppedFiles,
            'isUploadedViaSuppliersPage': this.isUploadedViaSuppliersPage
        }

        if (this.selectedActions == "existingTemplate") {
            if (this.isUploadedViaSuppliersPage) {
                // If docparser template is not selected of changed by user => Update the template
                if (this.docparsertemplateid != this.selectedDocparserTemplateId) {
                    this.fileUploadingService.updateDocparserTemplate(this.supplierid, this.selectedDocparserTemplateId).subscribe(resp => {
                        this.createInvoiceViaDocparser(data);
                    });
                } else {
                    this.createInvoiceViaDocparser(data);
                }
            } else {
                this.createInvoiceViaDocparser(data);
            }
        } else if (this.selectedActions == "newTemplate") {
            this.createInvoiceViaMindee(data);
        }
    }

    openFileInput() {
        this.fileInput.nativeElement.click();
    }

    handleFileSelections(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        const files = inputElement.files;
        // Handle the selected files
        this.addSelectedFiles(Array.from(files));
    }

    addSelectedFiles(selectedFiles: File[]) {
        for (const file of selectedFiles) {
            // Check if the file is not already in the filesCollection
            if (!this.isFileInCollection(file)) {
                // If not present, add it to the filesCollection
                this.droppedFiles.push(file);
            }
        }
    }

    // Function to check if a file is already in the filesCollection
    isFileInCollection(file: File): boolean {
        return this.droppedFiles.some(existingFile => {
                return existingFile.name === file.name && existingFile.size === file.size
            }
        );
    }

    removeFile(fileToRemove: any) {
        this.droppedFiles = this.droppedFiles.filter(file => file !== fileToRemove);
        this.checkStartUpload();
    }

    closeModal() {
        if (this.successMsg != null && this.selectedActions == 'existingTemplate') {
            this.toastBox.show(this.translate.instant('autoparsing.file.upload.success'), 3000);
        } else if (this.successMsg != null && this.selectedActions == 'newTemplate') {
            this.toastBox.show(this.translate.instant('file.successfully.submitted.to.commusoft.supplier.ai'), 3000);
        }

        this.isFileDrop = false;

        let count = this.droppedFiles.length;
        for (let i = 0; i < count; i++) {
            this.droppedFiles.pop();
        }

        this.selectedActions = null;
        this.errorMsg = null;
        this.successMsg = null;
        this.disableStartUpload = false;
        this.fileCountError = null;
        this.displayMessage = false;
        this.progressValue = 0;
        this.suppliersList = [];

        if (!this.isUploadedViaSuppliersPage) {
            this.supplierid = -1;
        }

        setTimeout(() => {
            document.getElementById("hideZIndexProperty").style.zIndex = "0";
            document.getElementById("fileUploadingModal").style.display = "none";
        }, 800);
    }

    selectSupplier(event) {
        this.errorMsg = null;
        this.supplierid = event;
        this.checkStartUpload();
    }

    selectDocparserTemplate(event) {
        this.errorMsg = null;
        this.selectedDocparserTemplateId = event;
        this.checkStartUpload();
    }

    // This function will be used to check whether to call API or not. If all validations are passed call the API 
    checkStartUpload() {
        this.disableStartUpload = false;
        this.progressValue = 0;
        this.errorMsg = null;
        this.successMsg = null;
        
        if (this.droppedFiles.length > 10) {
            this.fileCountError = this.translate.instant('maximum.file.limit', { fileLimit: 10 });
            this.disableStartUpload = false;
            return;
        } else {
            this.fileCountError = null;
        }

        if (this.selectedActions == 'existingTemplate') {
            if (!parseInt(this.supplierid)) {
                this.disableStartUpload = false;
                return;
            }
            
            if (this.isUploadedViaSuppliersPage && (!parseInt(this.selectedDocparserTemplateId) || parseInt(this.selectedDocparserTemplateId) < 0)) {
                this.disableStartUpload = false;
                return;
            }
        } 
        
        for (let i = 0; i < this.droppedFiles.length; i++) {
            let size = this.droppedFiles[i].size / (1024 * 1024);

            if ((this.selectedActions == 'existingTemplate' && this.docparserAllowedExtension.includes(this.droppedFiles[i].type) && size <= 5) || (this.selectedActions == 'newTemplate' && this.mindeeAllowedExtension.includes(this.droppedFiles[i].type) && size <= 5)) {
                this.disableStartUpload = true;
            } else {
                this.disableStartUpload = false;
                return;
            }
        } 

        if (this.droppedFiles.length == 0) {
            this.disableStartUpload = false;
            return;
        }
    }
}

