import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InterfaceService } from '@app/interfaces';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UploadDocumentService = /** @class */ (function (_super) {
    tslib_1.__extends(UploadDocumentService, _super);
    function UploadDocumentService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.filesCollection = new Subject();
        return _this;
    }
    UploadDocumentService.prototype.sendFilesToUpload = function (files) {
        this.filesCollection.next(files);
    };
    UploadDocumentService.prototype.getSuppliers = function (searchText) {
        if (searchText === void 0) { searchText = ''; }
        var options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        var url = '';
        if (searchText == '') {
            url = 'get_all_suppliers';
        }
        else {
            url = 'get_all_suppliers?q=' + searchText;
        }
        return this.http
            .get(this.getApiUrl(url));
    };
    UploadDocumentService.prototype.postDocParserDocument = function (params) {
        var formData = new FormData();
        for (var i = 0; i < params.files.length; i++) {
            formData.append('file[' + i + ']', params.files[i]);
        }
        formData.append('supplier[supplierid]', params.supplierid);
        formData.append('supplier[jobid]', params.jobid);
        return this.http.post(this.getApiUrl("postDocParserDocuments"), formData);
    };
    UploadDocumentService.prototype.postDocumentAIParser = function (params) {
        var formData = new FormData();
        for (var i = 0; i < params.files.length; i++) {
            formData.append('file[' + i + ']', params.files[i]);
        }
        formData.append('supplier[jobid]', params.jobid);
        formData.append('supplier[supplierid]', params.supplierid);
        formData.append('supplier[isUploadedViaSuppliersPage]', params.isUploadedViaSuppliersPage);
        return this.http.post(this.getApiUrl("postDocumentAIParser"), formData);
    };
    UploadDocumentService.prototype.getPostURL = function () {
        return this.getApiUrl("postDocParserDocuments");
    };
    UploadDocumentService.prototype.getListAutomatedInvoices = function (requestParams, sortModal, searchTerm) {
        var query = 'page=' + requestParams.page + '&limit=' + requestParams.limit;
        if (searchTerm != null && sortModal != null) {
            var sortByValue = sortModal.colId;
            var sortByOrder = sortModal.sort;
            var url = 'listAutomatedParseData?searchText=' + searchTerm + '&' + 'sortByValue=' + sortByValue + '&sortByOrder=' + sortByOrder + '&' + query;
            return this.http.get(this.getApiUrl(url));
        }
        else if (sortModal != null) {
            var sortByValue = sortModal.colId;
            var sortByOrder = sortModal.sort;
            var url = 'listAutomatedParseData?sortByValue=' + sortByValue + '&sortByOrder=' + sortByOrder + '&' + query;
            return this.http.get(this.getApiUrl(url));
        }
        else if (searchTerm != null) {
            var url = 'listAutomatedParseData?searchText=' + searchTerm + '&' + query;
            return this.http.get(this.getApiUrl(url));
        }
        else {
            return this.http.get(this.getApiUrl('listAutomatedParseData?' + query));
        }
    };
    UploadDocumentService.prototype.getAutomatedParseData = function (id, poNumber, supplierId) {
        var url = '';
        if (poNumber == "" || poNumber == null || typeof poNumber == 'undefined') {
            poNumber = 0;
        }
        if (supplierId == "" || supplierId == null || typeof supplierId == 'undefined') {
            supplierId = 0;
        }
        url = 'getAutomatedParseData/' + id + '?poNumber=' + poNumber + '&supplierId=' + supplierId;
        return this.http.get(this.getApiUrl(url));
    };
    UploadDocumentService.prototype.deleteAIParseDetails = function (id) {
        var url = 'deleteAIParseDetails/' + id;
        return this.http.delete(this.getApiUrl(url));
    };
    UploadDocumentService.prototype.saveAutomatedParseData = function (data, id) {
        var url = 'saveAutomatedParseData/' + id;
        return this.http.put(this.getApiUrl(url), data);
    };
    UploadDocumentService.prototype.deleteDocumentAIParser = function (id) {
        var url = 'deleteDocumentAIParser/' + id;
        return this.http.delete(this.getApiUrl(url));
    };
    UploadDocumentService.prototype.saveAutomatedDataMapping = function (data) {
        var url = 'saveAutomatedDataMapping';
        return this.http.put(this.getApiUrl(url), data);
    };
    UploadDocumentService.prototype.convertParsedDataToInvoice = function (id, part) {
        var url = 'convertParsedDataToInvoice/' + id;
        return this.http.post(this.getApiUrl(url + '?part=' + part), null);
    };
    UploadDocumentService.prototype.selectPartsOrItems = function (data) {
        var query = 'supplierId=' + data.supplierId + '&poNumber=' + data.poNumber + '&jobId=' + data.jobId + '&invoiceNumber=' + data.invoiceNumber + '&parsedDataId=' + data.parsedDataId;
        return this.http.get(this.getApiUrl('selectPartsOrItems?' + query));
    };
    UploadDocumentService.prototype.checkSupplierNominalCode = function (supplierId) {
        return this.http.get(this.getApiUrl('checkSupplierNominalCode?supplierId=' + supplierId));
    };
    UploadDocumentService.prototype.getDocparserTemplate = function () {
        return this.http.get(this.getApiUrl('listDocParsers'));
    };
    UploadDocumentService.prototype.updateDocparserTemplate = function (supplierId, docparserTemplateId) {
        var formData = new FormData();
        formData.append('supplier[id]', supplierId);
        formData.append('supplier[docparserTemplateId]', docparserTemplateId);
        return this.http.post(this.getApiUrl("updateDocparserTemplate"), formData);
    };
    UploadDocumentService.ngInjectableDef = i0.defineInjectable({ factory: function UploadDocumentService_Factory() { return new UploadDocumentService(i0.inject(i1.HttpClient)); }, token: UploadDocumentService, providedIn: "root" });
    return UploadDocumentService;
}(InterfaceService));
export { UploadDocumentService };
