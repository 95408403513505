import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DagSourceModel} from "@app/workflow-common/services/DagSourceModel";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";
import {AddJobBlockModel} from "@app/workflow-common/services/models/AddJobBlockModel";

@Component({
  selector: 'app-add-job-block-widget',
  templateUrl: './add-job-block-widget.component.html',
  styles: []
})
export class AddJobBlockWidgetComponent implements OnInit {
    @Input() workflowType: string;
    @Input() blockObject;
    @Input() dagSourceModel: DagSourceModel;
    @Output() close:EventEmitter<{}> = new EventEmitter<{}>();
    form: FormGroup;
  constructor(private workflowService: WorkflowhttpService,  private formBuilder: FormBuilder,) { }

    jobBlockData:{} = {};
    isLoading:boolean = false;
    inputModel: any;
    jobDescriptions:any=[];
    isFormSubmitted:boolean = false;
    selectedJobDescriptionInputs:{} = {};
  async ngOnInit() {
      this.jobBlockData = this.dagSourceModel.getBlocksSource().getBlockByKey(this.blockObject.key);
      const blockObject = {...this.blockObject};
      const modelData = blockObject['model'] ? blockObject['model'] : undefined;
      this.inputModel = new AddJobBlockModel(modelData);
      this.makeJobDescriptionDropdownData();
      this.resolveData();
      this.form = this.formBuilder.group({
          jobDescriptionId: new FormControl(this.selectedJobDescriptionInputs['jobDescriptionId'], [Validators.required]),
          displayValue: new FormControl(this.selectedJobDescriptionInputs['displayValue'])
      });
  }
  remove(){
      const blockObject = {...this.blockObject};
      blockObject.model = this.inputModel;
      this.close.emit({
          action: 'remove_block',
          data: blockObject
      });
  }
  closeModal(){
      this.close.emit({
          action: 'close',
          data: null
      });
  }
  async resolveData() {
      this.isLoading = true;
      await this.jobDescriptionList();
  }
  jobDescriptionList() {
      const promise = new Promise( (resolve) => {
          this.workflowService.getJobDescriptions({
              ___noPageloading:true,
              limit:9999
          })
              .subscribe(resp => {
                  this.isLoading = false;
                  //console.log(resp);
                  const jobDescriptions = resp['jobDescriptions'];
                  this.jobDescriptions = jobDescriptions.map( (item) => {
                      return { id: item.id, text: item.description }
                  });
                  resolve();
              });
      });
      return promise;
  }
    makeJobDescriptionDropdownData(){
      this.selectedJobDescriptionInputs['jobDescriptionId'] = this.inputModel.jobDescriptionId;
      if(this.inputModel.jobDescriptionId){
          this.selectedJobDescriptionInputs['displayValue'] = this.inputModel.displayValue;
      }
    }
  selectedDescription(event){
      this.form.get('jobDescriptionId').setValue(event);
      if(event){
          this.form.get('displayValue').setValue(this.getDisplayValue(event));
      }
  }
  getDisplayValue(jobDescId){
      const selectedUserData = this.jobDescriptions.find(x => x.id == jobDescId)
      console.log(selectedUserData);
      return selectedUserData['text'];
  }
  save(){
      this.isFormSubmitted = true;
      const blockObject = {...this.blockObject};
      blockObject.model = new AddJobBlockModel(this.form.value);
      this.close.emit({
          action: 'save_and_close',
          data: blockObject
      })
  }
}
