import {Component, Input, OnInit} from '@angular/core';
import {SidepanelModalService} from "@app/sidepanel/services/sidepanel-modal.service";
import {BsModalRef} from "ngx-bootstrap";
import {Subscription} from "rxjs";
import {TemplateViewComponent} from "@app/shared/cs-components/template-preview/template-view.component";
import {CommonDataSource} from "@app/workflow-common/services/CommonDataSource";

@Component({
  selector: 'app-template-preview',
  templateUrl: './template-preview.component.html',
  styles: [
      '[disabled] {cursor: not-allowed}'
  ]
})
export class TemplatePreviewComponent implements OnInit {

    @Input() linkTitle:string = 'Preview';
    @Input() type:string = 'email';
    @Input() message:string;
    @Input() subject:string;

    modalRef: BsModalRef;
    subscription: Subscription;
    title:string;
    disabledBtn: boolean = true;

    constructor(
        public modalService: SidepanelModalService
    ) { }

    async ngOnInit() {
        if (this.type == 'email') {
            this.title = 'Email template preview'
        }
        await CommonDataSource.getClientDetails();
        this.disabledBtn=false;
    }

    openPreview() {
        const {subject, message, type, title} = this;
        const initialState = {
            subject,
            message,
            type,
            title
        };
        const bsModalRef:BsModalRef = this.modalService.show(TemplateViewComponent, {
            initialState,
            'size': 'xl',
        });
    }
}
