export interface AddJobBlockModelInterface{
    jobDescriptionId:string;
    displayValue:string;
}
export class AddJobBlockModel implements AddJobBlockModelInterface{
    jobDescriptionId: string = '';
    displayValue:string = '';
    constructor(data:{} = undefined) {
        if(data){
            this.jobDescriptionId = data['jobDescriptionId'] ? data['jobDescriptionId'] : '';
            this.displayValue = data['displayValue'] ? data['displayValue'] : '';
        }
    }
}
