
export interface NotificationBlockModelInterface {
    assignTo: string;
    assignToDisplay: string;
    message: string;
    roles: any[];
    rolesDisplay: any[];
    textMessage: string;
    messageDelta: string
}

export class NotificationBlockModel implements NotificationBlockModelInterface {
    assignTo: string = '';
    assignToDisplay: string = '';
    message: string = '';
    textMessage: string = '';
    roles: any[] = undefined;
    rolesDisplay: any[] = undefined;
    messageDelta: string;

    constructor(data: {} = undefined) {
        if(data) {
            this.assignTo = data['assignTo'] ? data['assignTo'] : '';
            this.assignToDisplay = data['assignToDisplay'] ? data['assignToDisplay'] : '';
            this.message = data['message'] ? data['message'] : '';
            this.textMessage = data['textMessage'] ? data['textMessage'] : '';
            this.roles = data['roles'] ? data['roles'] : undefined;
            this.rolesDisplay = data['rolesDisplay'] ? data['rolesDisplay'] : undefined;
            this.messageDelta = data['messageDelta'] ? data['messageDelta'] : undefined;
        }
    }


}
