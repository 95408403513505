import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DagSourceModel} from "@app/workflow-common/services/DagSourceModel";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ChangeStageBlockModel} from "@app/workflow-common/services/models/ChangeStageBlockModel";
import {NotificationBlockModel} from "@app/workflow-common/services/models/NotificationBlockModel";
import {CommonDataSource} from "@app/workflow-common/services/CommonDataSource";

@Component({
  selector: 'app-change-stage-block-widget',
  templateUrl: './change-stage-block-widget.component.html',
  styles: []
})
export class ChangeStageBlockWidgetComponent implements OnInit {

    @Input() workflowType: string;
    @Input() blockObject;
    @Input() stageId: number;
    @Input() dagSourceModel: DagSourceModel;
    @Output() close:EventEmitter<{}> = new EventEmitter<{}>();

    form: FormGroup;
    inputModel: ChangeStageBlockModel = undefined;
    showDeleteBtn: boolean = false;
    changeStageBlockData:{} = {};
    isLoading: boolean = false;
    isFormSubmitted:boolean = false;
    stageLists: any[] = [];
    stageSelect2Config: {} = undefined;

    constructor(
        private formBuilder: FormBuilder,
    ) { }

    ngOnInit() {
        this.changeStageBlockData = this.dagSourceModel.getBlocksSource().getBlockByKey(this.blockObject.key);
        const blockObject = {...this.blockObject};

        this.setStageSelect2Config();
        const modelData = blockObject['model'] ? blockObject['model'] : undefined;
        if(modelData) {
            this.showDeleteBtn=true;
        }
        this.inputModel = new ChangeStageBlockModel(modelData);

        this.form = this.formBuilder.group({
            stageId: new FormControl(this.inputModel.stageId, [Validators.required]),
        });
    }

    setStageSelect2Config() {
        this.stageLists = CommonDataSource.stagesList.filter( stage => stage.id != this.stageId);
        this.stageSelect2Config = {
            minimumResultsForSearch: -1
        };
    }

    save() {
        this.isFormSubmitted=true;
        const { stageId } = this.form.value;
        this.inputModel.stageId = stageId;
        const selectedItem = this.stageLists.find( stage => stage.id == stageId);
        if(selectedItem) {
            this.inputModel.stageDisplayName = selectedItem['name'];
        }
        const blockObject = {...this.blockObject};
        blockObject['model'] = this.inputModel;
        this.close.emit({
            action: 'save_and_close',
            data: blockObject
        });
    }

    closeModal() {
        this.close.emit({
            action: 'close_all',
            data: null
        });
    }

    remove() {
        const blockObject = {...this.blockObject};
        blockObject.model = this.inputModel;
        this.close.emit({
            action: 'remove_block',
            data: blockObject
        });
    }

}
