import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { DagSourceModel } from "@app/workflow-common/services/DagSourceModel";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { SendProposalBlockModel } from "@app/workflow-common/services/models/SendProposalBlockModel";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
import { CommonDataSource } from "@app/workflow-common/services/CommonDataSource";
import { from } from "rxjs";
import { map } from "rxjs/operators";
var SendProposalBlockWidgetComponent = /** @class */ (function () {
    function SendProposalBlockWidgetComponent(formBuilder, workflowService) {
        this.formBuilder = formBuilder;
        this.workflowService = workflowService;
        this.close = new EventEmitter();
        this.showDeleteBtn = false;
        this.isLoading = true;
        this.isFormSubmitted = false;
        this.isSMTPEnabled = false;
        this.fromEmailAddressesList = [];
        this.sendProposalBlockData = {};
        this.usersList = [];
        this.emailTagsList = [];
        this.emailUsersList = [];
        this.emailCustomList = [];
        this.selectedEmailInputs = {};
        this.select2ConfigToEmail = {};
    }
    SendProposalBlockWidgetComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var blockObject, modelData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.sendProposalBlockData = this.dagSourceModel.getBlocksSource().getBlockByKey(this.blockObject.key);
                        this.emailTagsList = this.dagSourceModel.getEmailAddressTagsByType(this.workflowType, 'opportunity');
                        blockObject = tslib_1.__assign({}, this.blockObject);
                        modelData = blockObject['model'] ? blockObject['model'] : undefined;
                        if (modelData) {
                            this.showDeleteBtn = true;
                        }
                        this.inputModel = new SendProposalBlockModel(modelData);
                        this.makeEmailAddressDropdownData();
                        return [4 /*yield*/, CommonDataSource.getEmailCommunicationData()
                                .then(function (data) {
                                _this.isSMTPEnabled = data.smtpDetails.smtp;
                                if (_this.isSMTPEnabled) {
                                    _this.fromEmailAddressesList = data.smtpDetails.smtpList;
                                }
                                _this.usersList = data.users;
                            })];
                    case 1:
                        _a.sent();
                        this.isLoading = false;
                        this.usersList.forEach(function (user) {
                            _this.emailUsersList.push({
                                id: parseInt(user.id),
                                text: user.username,
                                name: 'USERS'
                            });
                        });
                        if (!this.isSMTPEnabled) {
                            this.inputModel.fromEmail = '';
                        }
                        this.setToEmailSelect2Config();
                        this.form = this.formBuilder.group({
                            fromEmail: new FormControl(this.inputModel.fromEmail),
                            templateId: new FormControl(this.inputModel.templateId, [Validators.required]),
                            toEmailTag: new FormControl(this.selectedEmailInputs['toEmailTag'], [Validators.required]),
                            subject: new FormControl(this.inputModel.subject),
                            message: new FormControl(this.inputModel.message),
                            templateName: new FormControl(this.inputModel.templateName),
                            doAttachProposal: new FormControl(this.inputModel.doAttachProposal),
                        });
                        if (this.isSMTPEnabled) {
                            this.form.get('fromEmail').setValidators(Validators.required);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SendProposalBlockWidgetComponent.prototype.makeEmailAddressDropdownData = function () {
        var _this = this;
        this.selectedEmailInputs['toEmailTag'] = [];
        //pushing dropdown value of selected email addresses
        if (this.inputModel.toEmailTag && this.inputModel.toEmailTag.length > 0) {
            this.inputModel.toEmailTag.forEach(function (item) {
                _this.selectedEmailInputs['toEmailTag'].push(item['value']);
                if (item['value'].indexOf('@') > 0) {
                    _this.emailCustomList.push({
                        id: item['value'],
                        text: item['value'],
                        name: 'CUSTOM'
                    });
                }
            });
        }
    };
    SendProposalBlockWidgetComponent.prototype.setTemplate = function (event, type) {
        this.form.get('templateId').setValue(event['templateId']);
        this.form.get('message').setValue(event['message']);
        this.form.get('subject').setValue(event['subject']);
        this.form.get('templateName').setValue(event['templateName']);
        this.inputModel.message = event['message'];
        this.inputModel.subject = event['subject'];
        this.inputModel.templateName = event['templateName'];
    };
    SendProposalBlockWidgetComponent.prototype.setToEmailSelect2Config = function () {
        var select2Default = CommonDataSource.select2EmailDefaultConfig;
        this.select2ConfigToEmail = tslib_1.__assign({}, select2Default);
        this.select2ConfigToEmail['data'] = this.makeGroupEmailList('toAddress');
        if (this.sendProposalBlockData['groupId'] != 'internalCommunications') {
            this.select2ConfigToEmail['formatNoMatches'] = function () {
                return 'Add a recipient\'s email by entering...';
            };
        }
    };
    SendProposalBlockWidgetComponent.prototype.selectedEmailAddress = function (event, type) {
        if (event === void 0) { event = null; }
        if (type === void 0) { type = 'to'; }
        if (type == 'to') {
            this.form.get('toEmailTag').setValue(event);
        }
    };
    SendProposalBlockWidgetComponent.prototype.makeGroupEmailList = function (emailType) {
        if (emailType === void 0) { emailType = 'toAddress'; }
        var allEmailTag = [];
        var newItems = [];
        if (emailType == 'toAddress' && this.sendProposalBlockData['groupId'] == 'externalCommunications') {
            // newItems.push(this.emailUsersList);
            newItems.push(this.emailTagsList);
        }
        if (this.emailCustomList.length) {
            newItems.push(this.emailCustomList);
        }
        var source = from(newItems);
        var tags = source.pipe(map(function (groupItems) {
            var _item = { text: '', children: [] };
            var cnt = 0;
            groupItems.forEach(function (group) {
                if (cnt === 0) {
                    _item.text = group.name;
                }
                _item.children.push({
                    id: group.id,
                    text: group.text
                });
                cnt++;
            });
            return _item;
        }));
        var subscribe = tags.subscribe(function (val) { return allEmailTag.push(val); });
        subscribe.unsubscribe();
        return allEmailTag;
    };
    SendProposalBlockWidgetComponent.prototype.save = function () {
        this.isFormSubmitted = true;
        this.inputModel = new SendProposalBlockModel(this.form.value);
        var toEmailTag = this.form.value.toEmailTag;
        this.inputModel.toEmailTag = CommonDataSource.formattingEmailAddress(toEmailTag, this.emailTagsList, this.usersList);
        var blockObject = tslib_1.__assign({}, this.blockObject);
        blockObject.model = this.inputModel;
        this.close.emit({
            action: 'save_and_close',
            data: blockObject
        });
    };
    SendProposalBlockWidgetComponent.prototype.closeModal = function () {
        this.close.emit({
            action: 'close',
            data: null
        });
    };
    SendProposalBlockWidgetComponent.prototype.remove = function () {
        var blockObject = tslib_1.__assign({}, this.blockObject);
        blockObject.model = this.inputModel;
        this.close.emit({
            action: 'remove_block',
            data: blockObject
        });
    };
    return SendProposalBlockWidgetComponent;
}());
export { SendProposalBlockWidgetComponent };
