import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { AuthService } from "@app/core/auth/auth.service";
import { HybridFactory } from '@app/core/hybrid-factory';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./auth.service";
import * as i3 from "../settings/settings.service";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, authService, settingsService) {
        this.router = router;
        this.authService = authService;
        this.settingsService = settingsService;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        var url = state.url;
        var isJobsPipelines = url.includes('jobs_pipelines');
        var isSalesPipelines = url.includes('set_up_pipelines');
        var clonedData = tslib_1.__assign({}, next.data);
        // Update featurePermissions based on the URL
        if (clonedData.hasOwnProperty('featurePermissions') && clonedData['featurePermissions'] instanceof Array) {
            if (isJobsPipelines) {
                clonedData['featurePermissions'] = ['SetupJobPipelines.readaccess'];
            }
            else if (isSalesPipelines) {
                clonedData['featurePermissions'] = ['SetupPipelines.readaccess'];
            }
        }
        // Checks is user authenticated
        if (!this.authService.authenticated()) {
            HybridFactory.navigate('/logout');
            return false;
        }
        // Checks user's feature access permissions
        if (clonedData.hasOwnProperty('featurePermissions') && clonedData['featurePermissions'] instanceof Array) {
            var validationResult = this.authService.permitted(clonedData['featurePermissions']);
            if (validationResult !== true) {
                HybridFactory.navigate('/logout', validationResult.length ? { errorMessage: "You don't have permission to " + validationResult[0][1].replace('access', '') + " " + validationResult[0][0] } : {});
                return false;
            }
            ;
        }
        // Check if its a AngularJS view but its come here from a bookmark
        // if ('clientsconfig' in this.settingsService.getAllPermissions()['accessDetails'] &&
        //     'NewPropertyView' in this.settingsService.getAllPermissions()['accessDetails'].clientsconfig
        // ) {
        //     let matchedCustomerRegexp = new RegExp('^/customers/(customer|customer_list|work_address)/([0-9]+)/view_v2');
        //     let matches = state.url.match(matchedCustomerRegexp);
        //     if(matches){
        //         HybridFactory.navigate('/customers/' + matches[1] + '/' + matches[2] + '/view_v2/property/view');
        //         event.preventDefault();
        //         return;
        //     }
        //
        // }
        return true;
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.Router), i0.inject(i2.AuthService), i0.inject(i3.SettingsService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
