import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DagSourceModel} from "@app/workflow-common/services/DagSourceModel";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ScheduledActivityBlockModel} from "@app/workflow-common/services/models/ScheduledActivityBlockModel";
import {CommonDataSource} from "@app/workflow-common/services/CommonDataSource";
import * as moment from "moment";

@Component({
  selector: 'app-scheduled-activity-block-widget',
  templateUrl: './scheduled-activity-block-widget.component.html',
  styles: [':host /deep/ .cs-timepicker { width:150px;}', ':host /deep/ .cs-timepicker select { margin-bottom: 0;}']
})
export class ScheduledActivityBlockWidgetComponent implements OnInit {

    @Input() workflowType: string;
    @Input() blockObject;
    @Input() dagSourceModel: DagSourceModel;
    @Output() close:EventEmitter<{}> = new EventEmitter<{}>();

    form: FormGroup;
    inputModel: ScheduledActivityBlockModel;

    usersList:any[] = [];
    scheduledActivityBlockData:{} = {};
    isLoading: boolean = true;
    isFormSubmitted: boolean = false;
    showDeleteBtn: boolean = false;
    activityTypes: any[] = [];
    iconClassNames: Object = {
        'Call': 'fa fa-phone',
        'Email': 'fa fa-envelope',
        'Meeting': 'fas fa-calendar-alt',
        'Task': 'fa fa-list-ul'
    };
    units: any[] = ['days', 'weeks', 'months'];
    timeModel:Date = null;
    select2AssigneeConfig: {};

    constructor(
        private formBuilder: FormBuilder,
    ) { }

    async ngOnInit() {
        this.scheduledActivityBlockData = this.dagSourceModel.getBlocksSource().getBlockByKey(this.blockObject.key);

        const blockObject = {...this.blockObject};
        const modelData = blockObject['model'] ? blockObject['model'] : undefined;
        this.inputModel = new ScheduledActivityBlockModel(modelData);
        this.activityTypes = CommonDataSource.activityTypes;
        if(modelData) {
            this.showDeleteBtn=true;
        }

        await CommonDataSource.getUserData().then( (userData) => {
            console.log('userData', userData);
            const users:any[] = [];
            const userRecords = userData.hasOwnProperty('userRecords') && Array.isArray(userData['userRecords']) ? userData['userRecords'] : [];
            userRecords.forEach( (user:any) => {
                users.push({
                    id: user.id,
                    text: user.name
                })
            });
            this.usersList = users;
            this.setSelect2AssigneeConfig();
        });
        this.isLoading = false;

        if (!this.inputModel.activityType) {
            this.inputModel.activityType = this.activityTypes[0].id;
        }
        if (!this.inputModel.unit) {
            this.inputModel.unit = this.units[0];
        }
        if (!this.inputModel.scheduleTime) {
            this.timeModel = moment('08:00:00', 'HH:mm:ss').toDate();
        } else {
            this.timeModel = moment(this.inputModel.scheduleTime, 'HH:mm:ss').toDate();
        }

        this.form = this.formBuilder.group({
            'description': new FormControl(this.inputModel.description, [Validators.required]),
            'activityType': new FormControl(this.inputModel.activityType),
            'scheduleInterval': new FormControl(this.inputModel.scheduleInterval),
            'unit': new FormControl(this.inputModel.unit),
            'scheduleTime': new FormControl(this.inputModel.scheduleTime),
            'timeModel': new FormControl(this.timeModel),
            'assigneeIds': new FormControl(this.inputModel.assigneeIds),
            'notes': new FormControl(this.inputModel.notes)
        })
    }

    onChangeTime(time: Date) {
        console.log('time', time);
    }

    selectedAssignee(event:any) {
        this.form.get('assigneeIds').setValue(event.map(i => parseInt(i)));
    }

    setSelect2AssigneeConfig() {
        this.select2AssigneeConfig = {
            width: '100%',
            data: this.usersList,
            tags: true,
            formatSelection:  (item: any) => {
                return item.text;
            },
            formatResult:  (item: any) => {
                return item.text;
            },
            multiple: true
        };
    }

    setAssignedUserDisplayName() {
        const {assigneeIds} = this.inputModel;
        if(assigneeIds.length == 0) {
            return [];
        }
        this.inputModel.assigneeDisplay = this.usersList.filter((user) => {
            return assigneeIds.includes(user.id);
        }).map(item => item.text);
    }

    setActivityTypeDisplay() {
        const {activityType} = this.inputModel;
        this.inputModel.activityTypeDisplay = this.activityTypes.find( i => i.id == activityType)['name'];
    }

    save() {
        this.isFormSubmitted=true;
        this.inputModel = new ScheduledActivityBlockModel(this.form.value);
        this.inputModel.scheduleTime = moment( this.form.value['timeModel']).format('HH:mm:ss');
        this.inputModel.displayTime = moment( this.form.value['timeModel']).format('HH:mm');
        this.setAssignedUserDisplayName();
        this.setActivityTypeDisplay();
        const blockObject = {...this.blockObject};
        blockObject.model = this.inputModel;
        this.close.emit({
            action: 'save_and_close',
            data: blockObject
        });
    }

    closeModal() {
        this.close.emit({
            action: 'close',
            data: null
        });
    }

    remove() {
        const blockObject = {...this.blockObject};
        blockObject.model = this.inputModel;
        this.close.emit({
            action: 'remove_block',
            data: blockObject
        });
    }

}
