/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./template-view-layout.component.ngfactory";
import * as i2 from "./template-view-layout.component";
import * as i3 from "./template-view.component";
import * as i4 from "ngx-bootstrap/modal";
var styles_TemplateViewComponent = [];
var RenderType_TemplateViewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TemplateViewComponent, data: {} });
export { RenderType_TemplateViewComponent as RenderType_TemplateViewComponent };
export function View_TemplateViewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-body"], ["style", "top:0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-template-view-layout", [], null, null, null, i1.View_TemplateViewLayoutComponent_0, i1.RenderType_TemplateViewLayoutComponent)), i0.ɵdid(2, 114688, null, 0, i2.TemplateViewLayoutComponent, [], { message: [0, "message"], subject: [1, "subject"], type: [2, "type"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "button", [["class", "btn btn-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; var currVal_1 = _co.subject; var currVal_2 = _co.type; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_TemplateViewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-template-view", [], null, null, null, View_TemplateViewComponent_0, RenderType_TemplateViewComponent)), i0.ɵdid(1, 114688, null, 0, i3.TemplateViewComponent, [i4.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TemplateViewComponentNgFactory = i0.ɵccf("app-template-view", i3.TemplateViewComponent, View_TemplateViewComponent_Host_0, {}, {}, []);
export { TemplateViewComponentNgFactory as TemplateViewComponentNgFactory };
