import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap";
import {Subject} from "rxjs/index";
import {FleetLiveMapService} from "@app/features/fleet-management/fleet-live-map/fleet-live-map.service";
import { ViewVehicleService } from '../../add-vehicle/services/view-vehicle-service';

@Component({
  selector: 'app-fleet-live-map-sidepanel',
  templateUrl: './fleet-live-map-sidepanel.component.html',
  styleUrls: ['./fleet-live-map-sidepanel.component.scss']
})
export class FleetLiveMapSidepanelComponent implements OnInit {
    title:string='';
    action:any;
    selectedIcon: any = '';
    selectedIconType;
    iconName:any;
    locationName: any = '';
    addressDetails:any;
    @ViewChild('dropdown') iconList;
    @Input() defaultIconsList;
    @Input() uploadedIconsListUrls = [];
    public onClose: Subject<any>;
    addressLine='';
  constructor(public bsModalRef: BsModalRef,private httpService: FleetLiveMapService,
  ) {
    
  }

  ngOnInit() {
    this.onClose = new Subject ();
   }

    setSelectedIcon(iconData) {
        this.selectedIconType = null;
        setTimeout(() => {
            if (iconData.category === 'FA') {
                this.selectedIconType = iconData.category;
                this.selectedIcon = iconData.icon.className;
                this.iconName = iconData.icon.label;

            }
        }, 200)
    }
    
    removeIcon(){
        this.selectedIcon = '';
        this.iconName = '';
        // this.formData.controls['viewIcon'].setValue('');
    }

    stopEventPropagation(eve){
        eve.stopPropagation();
    }

    save(){
        Object.values(this.addressDetails['address']).forEach((el:any) =>{
            if(!el.includes(['city','country','postcode','country_code'])){
                this.addressLine = (this.addressLine != '' ? this.addressLine + ',': '')  + el;
            }
        })
        let data = {
            latitude: this.addressDetails['lat'],
            longitude: this.addressDetails['lon'],
            name: this.locationName,
            addressLine1:this.addressLine,
            town:this.addressDetails['address']['city'],
            country:this.addressDetails['address']['country'],
            postcode:this.addressDetails['address']['postcode'],
            locationIcon: this.selectedIcon
        }
        this.httpService.saveCurrentSelectedLocation(data).subscribe((data) => {
            if(data){
                this.closePanel();
            }
        });
    }
    	closePanel() {
        this.bsModalRef.hide();
        this.onClose.next('close');
    }

}
