import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from "rxjs/index";
import { BsModalRef } from 'ngx-bootstrap';
import { VehicleMakesService } from '@app/features/system-settings/fleet-management/vehicle-makes/vehicle-makes.service';
export interface vehicleMakesFormData {
	id: any;
	name?: string,
}

@Component({
	selector: 'app-vehicle-makes-modal',
	styleUrls: ['./vehicle-make-add-edit.component.scss'],
	templateUrl: './vehicle-make-add-edit.component.html',
})

export class VehicleMakeAddEditComponent implements OnInit {

	public onClose: Subject<any>;
	vehicleMakesForm: FormGroup;
	isEdit: boolean = false;
	action: string = 'save';
	vehicleMakesFormData = {
		id: '',
		name: '',
	}
	uniqueName: boolean = false;
	saveDisabled: boolean = false;
	loading: boolean = true;
	name: any;
	id: any;
	screen:string;
	title: string;

	constructor(
		private formBuilder: FormBuilder,
		private makeService: VehicleMakesService,
		public bsModalRef: BsModalRef,
	) {
	}

	ngOnInit() {
		this.onClose = new Subject();
		if (this.id) {
			// Set values for edit screen
			this.isEdit = true;
			this.loading = false;
			this.vehicleMakesFormData.id = this.id ? this.id : '';
			this.vehicleMakesFormData.name = this.name ? this.name : '';
		}
		this.title = this.isEdit == true ? 'Fleet.edit.vehicle.make' : 'fleet.add.new.make';
		this.buildForm();
	}

	buildForm() {
		this.vehicleMakesForm = this.formBuilder.group({
			'id': [this.vehicleMakesFormData.id],
			'name': [this.vehicleMakesFormData.name,  [Validators.required, Validators.minLength(1)]],
		});
	}


	// Check status name already exists
	checkUniqueMakesName(value) {
		let data = {
			name: value,
		}
		if (this.isEdit) {
            data['id'] = this.id
        }
		let checkName = false;
		if (this.vehicleMakesForm.get('name').value === value) {
			checkName = true;
		} else {
			checkName = false;
		}
		if (checkName) {
			this.makeService.checkMakesName(data).subscribe(resp => {
				this.uniqueName = resp
			})
		}
	}

	// vehicle make create or update
	submitVehicleMake() {
		this.saveDisabled = true;
		this.makeService.addOrEditVehicleMakes(this.vehicleMakesForm.value)
			.subscribe(resp => {
				if (resp.hasOwnProperty('id')) {
					this.uniqueName = false;
					if(this.screen == 'addVehicle'){
						this.onClose.next({makeId:resp['id']});
					}else{
						this.onClose.next(true);
					}
					this.bsModalRef.hide();
				} else {
					this.uniqueName = true;
				}
			}, error => {
				console.log('err', error);
			});
	}

	// Getting control values for validation check
	get f() {
		return this.vehicleMakesForm.controls;
	}

	closeVehicleMakePanel() {
		this.bsModalRef.hide();
		this.onClose.next('close');
	}
}
